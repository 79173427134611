import FDVue from "@fd/lib/vue";
import { mapActions } from "vuex";
import errorHandling from "@fd/lib/vue/mixins/errorHandling";
import dialogSupport, { createDialog } from "@fd/lib/vue/mixins/dialogSupport";
import rules from "@fd/lib/vue/rules";
import { FDColumnDirective } from "@fd/lib/vue/utility/dataTable";
import QrScanner from "qr-scanner";
import * as DateUtil from "@fd/lib/client-util/datetime";
import {
  Environment,
  InspectionStatus,
  Language,
  QuestionWithAnswer,
  QuestionWithText,
  ScaffoldInformation,
  ScaffoldInspectionTimeRange,
  ScaffoldInspectionWithDetails,
  ScaffoldSearchResult,
  ScaffoldWithDetails,
  scaffoldInspectionService,
  scaffoldService
} from "../../../services";
import fileHandling, { FileData, confirmUniqueName } from "@fd/lib/vue/mixins/fileHandling";
import {
  GetSelectableTimeSegments,
  TimeSegment
} from "../SP.ScaffoldInspectionTimeSegmentSelect.vue";
import { GroupableSelectListOption } from "@fd/lib/vue/utility/select";

type InspectionResultType = "none" | "pass" | "fail";
type QuestionWithBooleanAnswer = QuestionWithAnswer & { value: boolean };
type ScaffoldInspectionTimeRangeWithDescription = ScaffoldInspectionTimeRange & {
  description: string;
};

const ScaffoldInspectionNewDialog = FDVue.extend({
  name: "fd-scaffold-inspection-new-dialog",

  mixins: [dialogSupport, errorHandling, rules, fileHandling],
  directives: {
    fdColumn: FDColumnDirective
  },

  components: {
    "fd-chip-selector": () => import("@fd/lib/vue/components/ChipItemSelector.vue"),
    "fd-async-search-box": () => import("@fd/lib/vue/components/AsyncSearchBox.vue"),
    "fd-add-file-button": () => import("@fd/lib/vue/components/AddFileButton.vue"),
    "sp-scaffold-inspection-time-segment-select": () =>
      import("../SP.ScaffoldInspectionTimeSegmentSelect.vue")
  },

  data: function() {
    return {
      saving: false,
      step: 1,
      // lastStep: 4,
      questionsPanels: 0,

      allowDateSelection: false,
      timeRanges: [] as ScaffoldInspectionTimeRangeWithDescription[],
      selectableTimeSegments: [] as GroupableSelectListOption<TimeSegment>[],
      scaffoldStepLabelKey: "inspections.new.steps.select-scaffold",
      // selectScaffoldStep: 1,
      // answerQuestionsStep: 2,
      // takePhotoStep: 3,
      // resultStep: 4,
      selectscaffolderror: false,
      selectdateerror: false,
      answerquestionserror: false,
      takephotoerror: false,
      resulterror: false,

      selectedTags: [] as any[],

      isScanningQR: false,
      isLoadingQRScaffold: false,
      canChangeScaffold: true,
      qrScanner: null as null | QrScanner,

      // For Mockup purposes
      inspection: {
        additionalInformation: "",
        questionAnswers: {},
        tagIDs: [] as string[],
        inspectionDidPass: undefined as boolean | undefined,
        inspectionDateTime: undefined
      } as ScaffoldInspectionWithDetails,
      selectedTimeSegment: {} as TimeSegment,

      // ** SCAFFOLDS **
      selectedScaffold: null as ScaffoldInformation | null,
      availableScaffolds: [] as ScaffoldInformation[],

      // *** QUESTIONS ***
      didSelectAnswerQuestionsStep: false,
      questions: [] as QuestionWithBooleanAnswer[],

      /*** FILES ***/
      touchedFileName: "",
      tablesearchfiles: "",
      files: [] as FileData[],

      /*** IMAGE EDIT ****/
      newFileData: undefined as FileData | undefined,
      editingFileData: undefined as FileData | undefined
    };
  },

  computed: {
    canAddHistoricalInspections(): boolean {
      return (this.$store.state.curEnvironment as Environment)
        .scaffoldInspectionCanBeCreatedHistorically!;
    },
    isPhotoRequired(): boolean {
      return (this.$store.state.curEnvironment as Environment).scaffoldInspectionRequiresPhoto!;
    },
    canPassInspection(): boolean {
      var allQuestionsPassed = this.questions.findIndex(x => !x.value) === -1;
      return (
        !(this.$store.state.curEnvironment as Environment).scaffoldInspectionRequiresAllQuestions ||
        allQuestionsPassed
      );
    },
    inspectionDate(): string {
      return DateUtil.localizedDateTimeString(this.inspection.inspectionDateTime);
    },
    selectedTimeRange: {
      get(): ScaffoldInspectionTimeRange | undefined {
        var inspectionTime = this.inspection.inspectionDateTime?.getTime() ?? 0;
        let selectedTimeRange = this.timeRanges.find(
          x => inspectionTime > x.start.getTime() && inspectionTime < x.start.getTime()
        );
        return selectedTimeRange;
      },
      set(val: ScaffoldInspectionTimeRange) {
        this.inspection.inspectionDateTime = new Date(val.end.getTime() - 1);
      }
    },
    selectScaffoldStep(): number {
      return 1;
    },
    selectDateStep(): number {
      return this.allowDateSelection ? 2 : -1;
    },
    answerQuestionsStep(): number {
      return this.allowDateSelection ? 3 : 2;
    },
    takePhotoStep(): number {
      return this.allowDateSelection ? 4 : 3;
    },
    resultStep(): number {
      return this.allowDateSelection ? 5 : 4;
    },
    lastStep(): number {
      return this.allowDateSelection ? 5 : 4;
    },
    languageslist(): Language[] {
      return this.$store.state.languages.fullList as Language[];
    },
    unwatchedMethodNames(): string[] {
      return [
        "open",
        "setGeoLocation",
        "getGeoLocation",
        "validate",
        "getQuestionText",
        "inspectionColorForStatus"
      ];
    },
    availableTags(): any[] {
      return this.$store.getters.sortedEnabledTags;
    },
    inspectionResult: {
      get(): InspectionResultType {
        if (this.inspection.inspectionDidPass == true) return "pass";
        else if (this.inspection.inspectionDidPass == false) return "fail";
        return "none";
      },
      set(val: InspectionResultType) {
        if (val == "pass") this.inspection.inspectionDidPass = true;
        else if (val == "fail") this.inspection.inspectionDidPass = false;
        else this.inspection.inspectionDidPass = undefined;

        this.resulterror = this.inspectionResult == "none";
      }
    },
    compliesWithAllRequirements: {
      get(): boolean {
        return this.questions.findIndex(x => !x.value) == -1;
      },
      set(val: boolean) {
        this.questions.forEach(x => (x.value = val));
        if (!!val) this.questionsPanels = -1;
        else this.questionsPanels = 0;
      }
    },
    hasAllQuestionsAnswered(): boolean {
      return this.didSelectAnswerQuestionsStep;
    },
    photoFiles(): FileData[] {
      return this.files.filter(x => x.isPhoto);
    },
    showShortMessages(): boolean {
      return this.$vuetify.breakpoint.smAndDown;
    },
    currentLanguageCode(): string {
      var languageNumber = this.$store.state.language;
      var selectedLanguage = this.languageslist.find(x => x.number == languageNumber);
      return selectedLanguage?.shortCode ?? "en";
    }
  },

  watch: {
    selectedTimeSegment(newValue) {
      if (!!this.selectedTimeSegment)
        this.inspection.inspectionDateTime = new Date(this.selectedTimeSegment.end.getTime() - 1);
    },
    questions: {
      deep: true,
      handler(newValue) {
        console.log(`question answer changed`);
        if (!!this.compliesWithAllRequirements) this.questionsPanels = -1;
        else this.questionsPanels = 0;

        if (!this.canPassInspection && this.inspectionResult == "pass") {
          this.inspectionResult = "none";
        }
      }
    },
    files() {
      this.takephotoerror = this.isPhotoRequired && !this.photoFiles.length;
    },
    selectedScaffold(newValue) {
      console.log(`scaffold selection changed, stopping scanner.`);
      this.selectscaffolderror = !this.selectedScaffold;
      this.isScanningQR = false;
      this.qrScanner?.stop();
      this.qrScanner?.destroy();
      this.qrScanner = null;
      this.reloadTimeRanges();
    },
    step(newValue) {
      console.log(`step changed, stopping scanner.`);
      this.isScanningQR = false;
      this.qrScanner?.stop();
      this.qrScanner?.destroy();
      this.qrScanner = null;
      if (newValue == this.answerQuestionsStep && !this.didSelectAnswerQuestionsStep)
        this.didSelectAnswerQuestionsStep = true;
    }
  },

  methods: {
    inspectionColorForStatus(inspectionStatus: InspectionStatus): string {
      if (inspectionStatus == InspectionStatus.Passed) return "fd-inspection-pass";
      else if (inspectionStatus == InspectionStatus.Failed) return "fd-inspection-fail";
      else return "fd-inspection-pending";
    },
    getQuestionText(question: QuestionWithText): string | undefined {
      if (!question.textByLanguageCode) return undefined;

      var languageCode = this.currentLanguageCode;

      var questionText = question.textByLanguageCode[languageCode];
      if (!questionText) {
        let key = Object.keys(question.textByLanguageCode)[0];
        questionText = question.textByLanguageCode[key];
      }
      return questionText;
    },
    async setGeoLocation() {
      this.optOutOfErrorHandling();
      try {
        var position = await this.getGeoLocation();
        this.inspection.latitude = position?.latitude;
        this.inspection.longitude = position?.longitude;
      } catch (error) {
        console.log(`getGeoLocation error: ${error}`);
      }
    },
    async loadData(scaffoldID: string | null | undefined) {
      this.processing = true;
      try {
        await Promise.all([this.loadTags(), this.loadQuestions()]);
        this.questions = (this.$store.state.questions.fullList as QuestionWithText[])
          .map(
            x =>
              ({
                ...x,
                value: false,
                answer: "",
                language: ""
              } as QuestionWithBooleanAnswer)
          )
          .sort((a, b) => (a.displayOrder ?? 0) - (b.displayOrder ?? 0));
        this.setGeoLocation();
        if (!!scaffoldID?.length) {
          await this.loadScaffold(scaffoldID);
        } else if (this.allowDateSelection) {
          var date = new Date(DateUtil.isoDateWithOffsetString(this.inspection.inspectionDateTime));
          let timeRanges = await scaffoldInspectionService.getScaffoldInspectionTimeRangesWithScaffoldInspectionStatus(
            scaffoldID ?? null,
            date
          );
          this.timeRanges = timeRanges.map(
            x =>
              ({
                ...x,
                description: !!x.name?.length
                  ? x.name
                  : `${DateUtil.stripDateFromLocalizedDateTime(
                      x.start
                    )} ~ ${DateUtil.stripDateFromLocalizedDateTime(x.end)}`
              } as ScaffoldInspectionTimeRangeWithDescription)
          );
          this.selectableTimeSegments = GetSelectableTimeSegments(timeRanges);
          this.selectedTimeSegment = (this.selectableTimeSegments.find(
            x =>
              !!(x as TimeSegment)?.start &&
              this.inspection.inspectionDateTime!.getTime() >= (x as TimeSegment).start.getTime() &&
              this.inspection.inspectionDateTime!.getTime() < (x as TimeSegment).end.getTime()
          ) ?? this.selectableTimeSegments[1]) as TimeSegment;
        }
      } catch (error) {
        console.log(`Error: ${error}`);
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    async open(
      scaffoldID: string | null | undefined,
      date: Date | string | null | undefined
    ): Promise<string | undefined> {
      this.optOutOfErrorHandling();

      // If a scaffold ID is provided by the opening screen, don't allow the user to change it.
      this.canChangeScaffold = !scaffoldID?.length;

      if (!!date && this.canAddHistoricalInspections) {
        this.inspection.inspectionDateTime = new Date(date);
        this.selectedTimeSegment = (this.selectableTimeSegments.find(
          x =>
            !!(x as TimeSegment)?.start &&
            this.inspection.inspectionDateTime!.getTime() >= (x as TimeSegment).start.getTime() &&
            this.inspection.inspectionDateTime!.getTime() < (x as TimeSegment).end.getTime()
        ) ?? this.selectableTimeSegments[1]) as TimeSegment;
        this.allowDateSelection = true;
        this.scaffoldStepLabelKey = "inspections.new.steps.confirm-scaffold";
      }

      this.loadData(scaffoldID);

      return this.showDialog!();
    },
    onSubmit(e: Event) {
      e.preventDefault();
      this.saveDialog();
    },
    preventSubmit(e: Event) {
      e.preventDefault();
      return false;
    },

    getGeoLocation(): Promise<GeolocationCoordinates | undefined> {
      return new Promise(resolve => {
        navigator.geolocation.getCurrentPosition(
          (position: GeolocationPosition) => {
            resolve(position.coords);
          },
          (err: GeolocationPositionError) => {
            console.log(`getGeoLocation Error: ${err.code}: ${err.message}`);
            resolve(undefined);
          },
          {
            enableHighAccuracy: true
          }
        );
      });
    },

    // Method used in conjunction with the Cancel dialog.
    cancelDialog() {
      this.closeDialog!(undefined);
    },

    clearScaffoldSelection() {
      this.availableScaffolds = [];
      this.selectedScaffold = null;
    },

    validate(): boolean {
      this.selectscaffolderror = !this.selectedScaffold;
      this.takephotoerror = this.isPhotoRequired && !this.photoFiles.length;
      this.resulterror = this.inspectionResult == "none";
      this.answerquestionserror = !this.hasAllQuestionsAnswered;
      return !(
        this.selectscaffolderror ||
        this.answerquestionserror ||
        this.takephotoerror ||
        this.resulterror
      );
    },

    //Method used in conjunction with new view dialog.
    async saveDialog() {
      // First reset the inline message if there are any.
      this.inlineMessage.message = "";

      if (!this.validate()) {
        this.inlineMessage.message = this.$t("inspections.new.missing-data-message");
        return;
      }

      this.processing = true;
      try {
        await this.setGeoLocation();

        this.inspection.scaffoldID = this.selectedScaffold?.id;
        var selectedTagIDs =
          this.selectedTags.length > 0 ? this.selectedTags.map(x => x.id!) : null;

        this.questions.forEach(x => {
          x.language = this.currentLanguageCode;
          x.answer = `${!!x.value}`;
        });

        var newInspectionID = await scaffoldInspectionService.addItem({
          ...this.inspection,
          questionAnswers: this.questions,
          tagIDs: selectedTagIDs
        });

        if (!!this.files.length) {
          for (let index = 0; index < this.files.length; index++) {
            const file = this.files[index];
            await scaffoldInspectionService.uploadScaffoldInspectionFile(
              newInspectionID,
              file.name,
              file.file as Blob
            );
          }
        }

        var snackbarPayload = {
          text: this.$t("inspections.new.save-success"),
          type: "success",
          undoCallback: null
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);

        this.closeDialog!(this.inspection.scaffoldID);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    calculateScanRegion(video: HTMLVideoElement): QrScanner.ScanRegion {
      var minSize = Math.min(video.width, video.height);
      var widthScale = minSize / video.width;
      var heightScale = minSize / video.height;
      return {
        x: 0,
        y: 0,
        width: video.width,
        height: video.height,
        downScaledWidth: video.width * widthScale,
        downScaledHeight: video.height * heightScale
      } as QrScanner.ScanRegion;
    },
    startQrScanner() {
      this.qrScanner?.stop();
      this.isScanningQR = true;
      this.isLoadingQRScaffold = false;
      this.$nextTick(() => {
        if (!this.qrScanner) {
          var videoElement = this.$refs.video as HTMLVideoElement;
          if (!!videoElement) {
            this.qrScanner = new QrScanner(videoElement, this.qrDecoded, {
              calculateScanRegion: this.calculateScanRegion,
              highlightScanRegion: true,
              highlightCodeOutline: true,
              onDecodeError: this.qrDecodeError
            });
          }
        }
        this.qrScanner?.start();
      });
    },
    qrDecoded(result: QrScanner.ScanResult) {
      // dd01943f-aa51-ee11-9937-6045bd5e7062
      if (!!result.data) {
        var regex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/;
        if (!regex.test(result.data)) {
          this.inlineMessage.message = this.$t("inspections.new.invalid-qr-code");
          return;
        }
        this.inlineMessage.message = "";
        this.qrScanner?.pause();
        this.loadScaffold(result.data);
      }
    },
    async reloadTimeRanges() {
      if (!this.allowDateSelection) return;

      var scaffoldID = this.selectedScaffold?.id;
      if (!scaffoldID) {
        this.timeRanges.forEach(x => {
          x.lastInspectionStatus = null;
        });
        return;
      }

      var date = new Date(DateUtil.isoDateWithOffsetString(this.inspection.inspectionDateTime));
      let timeRanges = await scaffoldInspectionService.getScaffoldInspectionTimeRangesWithScaffoldInspectionStatus(
        scaffoldID ?? null,
        date
      );
      this.timeRanges = timeRanges.map(
        x =>
          ({
            ...x,
            description: !!x.name?.length
              ? x.name
              : `${DateUtil.stripDateFromLocalizedDateTime(
                  x.start
                )} ~ ${DateUtil.stripDateFromLocalizedDateTime(x.end)}`
          } as ScaffoldInspectionTimeRangeWithDescription)
      );
      this.selectableTimeSegments = GetSelectableTimeSegments(timeRanges);
      this.selectedTimeSegment = (this.selectableTimeSegments.find(
        x =>
          !!(x as TimeSegment)?.start &&
          this.inspection.inspectionDateTime!.getTime() >= (x as TimeSegment).start.getTime() &&
          this.inspection.inspectionDateTime!.getTime() < (x as TimeSegment).end.getTime()
      ) ?? this.selectableTimeSegments[1]) as TimeSegment;
    },
    async loadScaffold(id: string) {
      this.inlineMessage.message = "";

      this.processing = true;
      this.isLoadingQRScaffold = true;
      try {
        let scaffold: ScaffoldInformation = await scaffoldService.getBasicScaffoldInfo(id);

        if (!!scaffold) {
          this.selectedScaffold = {
            ...scaffold,
            existingRequestNumber: scaffold.erectRequestNumber,
            description: this.getScaffoldDescription({
              legacyID: scaffold.tagNumber,
              existingRequestNumber: scaffold.erectRequestNumber
            }),
            details: this.getScaffoldDetails(scaffold.areaID, scaffold.subAreaID)
          } as ScaffoldInformation;
          this.availableScaffolds = [this.selectedScaffold!];
          this.isScanningQR = false;
          this.qrScanner?.stop();
        } else {
          // Scaffold not found, try again
          this.qrScanner?.start();
          this.inlineMessage.message = this.$t("inspections.new.scaffold-not-found-from-qr-code");
        }
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.isLoadingQRScaffold = false;
      }
    },
    qrDecodeError(error: string | Error) {
      console.log(`qrDecodeError: ${error}`);
    },
    // *** SCAFFOLDS ***
    getScaffoldDescription(scaffold: {
      legacyID: number | string | null | undefined;
      existingRequestNumber: string | null | undefined;
    }): string {
      let paddedTagNumber = `00000${scaffold.legacyID}`.slice(-5);
      let existingRequestString = "";
      if (!!scaffold.existingRequestNumber?.length) {
        existingRequestString = ` | R-${scaffold.existingRequestNumber}`;
      }
      return `T-${paddedTagNumber}${existingRequestString}`;
    },

    getScaffoldDetails(
      areaName: string | null | undefined,
      subAreaName: string | null | undefined
    ): string {
      // let areaName = (this as any).$lookup.location(areaID);
      // let subAreaName = (this as any).$lookup.location(subAreaID);
      // The sub area is more specific than the area, so if a subarea exists, display it
      // Otherwise, display the area
      let areaString = "";
      if (subAreaName && subAreaName.trim().length > 0) {
        areaString = `${subAreaName.trim()}`;
      }
      if (areaString.length == 0 && areaName && areaName.trim().length > 0) {
        areaString = `${areaName.trim()}`;
      }

      var detailsString = areaString.length > 0 ? `${areaString}` : "";
      return `${detailsString}`;
    },
    async loadScaffolds(searchString: string) {
      if (!searchString?.length) {
        this.availableScaffolds = [];
        return;
      }

      let scaffolds = await scaffoldService.searchAll(searchString, searchString.length >= 4);
      this.availableScaffolds = scaffolds.map(x => {
        return {
          ...x,
          tagNumber: `${x.legacyID}`,
          erectRequestNumber: x.existingRequestNumber,
          description: this.getScaffoldDescription(x),
          details: this.getScaffoldDetails(x.areaName, x.subAreaName)
        } as ScaffoldInformation & { description: string; details: string };
      });

      if (this.availableScaffolds.length == 1) {
        this.selectedScaffold = this.availableScaffolds[0];
      }
    },

    // *** FILES ***
    async selectNewFile(originalFile: any) {
      var fileData = await this.optimizedFileDataForUpload(originalFile, this.files);
      if (!fileData) return;

      if (fileData.isPreviewable) {
        this.newFileData = fileData;
        this.imageName = fileData.name;
        this.editImageSource = this.covertFileToDataURL(fileData.file);
      } else {
        this.files.push(fileData);
      }
    },
    editFile(fileData: FileData) {
      if (!fileData.isPreviewable) return;

      this.editingFileData = fileData;
      this.imageName = fileData.name;
      this.editImageSource = this.covertFileToDataURL(fileData.file);
    },
    async handleEdit(res: File, fileName: string | undefined) {
      this.editImageSource = undefined;
      this.imageName = "";

      if (!!this.newFileData) {
        this.newFileData.file = res;

        this.files.push(this.newFileData);

        this.newFileData = undefined;
      } else if (!!this.editingFileData) {
        var originalFileName = this.editingFileData.name;

        var allFilesWithoutEditedFileData = this.files.slice();
        allFilesWithoutEditedFileData.splice(
          allFilesWithoutEditedFileData.indexOf(this.editingFileData),
          1
        );
        var uniqueFileName = confirmUniqueName(
          fileName ?? originalFileName,
          allFilesWithoutEditedFileData
        );

        this.editingFileData.name = uniqueFileName;
        this.editingFileData.file = res;

        this.editingFileData = undefined;
      }
    },
    fileRowClicked(
      e: MouseEvent,
      data: {
        item: FileData;
      }
    ) {
      this.viewFile(data.item);
    },
    viewFile(fileData: FileData) {
      this.imageName = fileData.name;
      this.imageSource = this.covertFileToDataURL(fileData.file);
    },
    removeFile(fileData: FileData) {
      var fileIndex = this.files.indexOf(fileData);
      if (fileIndex == undefined) return;

      this.files.splice(fileIndex, 1);
    },
    ...mapActions({
      loadTags: "LOAD_TAGS",
      loadQuestions: "LOAD_QUESTIONS"
    })
  },

  beforeDestroy() {
    this.isScanningQR = false;
    this.qrScanner?.stop();
    this.qrScanner?.destroy();
    this.qrScanner = null;
  },

  created: async function() {}
});

export default ScaffoldInspectionNewDialog;

export async function createNewScaffoldInspection(
  scaffoldID: string | null | undefined,
  date: Date | string | null | undefined
): Promise<string | undefined> {
  let dialog = createDialog(ScaffoldInspectionNewDialog);
  dialog.optOutOfErrorHandling();
  return await dialog.open(scaffoldID, date);
}

