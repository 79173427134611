<template>
  <v-dialog v-model="dialog" width="800px">
    <v-card>
      <v-card-title class="grey">
        {{ $t("privacy.title") }}
      </v-card-title>
      <v-container class="fd-privacy-terms">
        <v-card-text class="pt-0">
          <h3 class="mb-3">{{ $t("privacy.last-updated", [lastUpdated]) }}</h3>
          <p>{{ $t("privacy.policy.line1") }}</p>
          <p>{{ $t("privacy.policy.line2") }}</p>
          <h1>{{ $t("privacy.policy.interpretations-and-definitions.header") }}</h1>
          <h2>{{ $t("privacy.policy.interpretations-and-definitions.interpretations.header") }}</h2>
          <p>{{ $t("privacy.policy.interpretations-and-definitions.interpretations.line1") }}</p>
          <h2>{{ $t("privacy.policy.interpretations-and-definitions.definitions.header") }}</h2>
          <p>{{ $t("privacy.policy.interpretations-and-definitions.definitions.line1") }}</p>
          <ul>
            <li>
              <p>
                <strong>{{
                  $t("privacy.policy.interpretations-and-definitions.definitions.definition1")
                }}</strong>
                {{
                  $t(
                    "privacy.policy.interpretations-and-definitions.definitions.definition1-explanation"
                  )
                }}
              </p>
            </li>
            <li>
              <p>
                <strong>{{
                  $t("privacy.policy.interpretations-and-definitions.definitions.definition2")
                }}</strong>
                {{
                  $t(
                    "privacy.policy.interpretations-and-definitions.definitions.definition2-explanation"
                  )
                }}
              </p>
            </li>
            <li>
              <p>
                <strong>{{
                  $t("privacy.policy.interpretations-and-definitions.definitions.definition3")
                }}</strong>
                {{
                  $t(
                    "privacy.policy.interpretations-and-definitions.definitions.definition3-explanation",
                    [platformName]
                  )
                }}
              </p>
            </li>
            <li>
              <p>
                <strong>{{
                  $t("privacy.policy.interpretations-and-definitions.definitions.definition4")
                }}</strong>
                {{
                  $t(
                    "privacy.policy.interpretations-and-definitions.definitions.definition4-explanation",
                    [companyName, companyAddress]
                  )
                }}
              </p>
            </li>
            <li>
              <p>
                <strong>{{
                  $t("privacy.policy.interpretations-and-definitions.definitions.definition5")
                }}</strong>
                {{
                  $t(
                    "privacy.policy.interpretations-and-definitions.definitions.definition5-explanation",
                    [companyCountry]
                  )
                }}
              </p>
            </li>
            <li>
              <p>
                <strong>{{
                  $t("privacy.policy.interpretations-and-definitions.definitions.definition6")
                }}</strong>
                {{
                  $t(
                    "privacy.policy.interpretations-and-definitions.definitions.definition6-explanation"
                  )
                }}
              </p>
            </li>
            <li>
              <p>
                <strong>{{
                  $t("privacy.policy.interpretations-and-definitions.definitions.definition7")
                }}</strong>
                {{
                  $t(
                    "privacy.policy.interpretations-and-definitions.definitions.definition7-explanation"
                  )
                }}
              </p>
            </li>
            <li>
              <p>
                <strong>{{
                  $t("privacy.policy.interpretations-and-definitions.definitions.definition8")
                }}</strong>
                {{
                  $t(
                    "privacy.policy.interpretations-and-definitions.definitions.definition8-explanation"
                  )
                }}
              </p>
            </li>
            <li>
              <p>
                <strong>{{
                  $t("privacy.policy.interpretations-and-definitions.definitions.definition9")
                }}</strong>
                {{
                  $t(
                    "privacy.policy.interpretations-and-definitions.definitions.definition9-explanation"
                  )
                }}
              </p>
            </li>
            <li>
              <p>
                <strong>{{
                  $t("privacy.policy.interpretations-and-definitions.definitions.definition10")
                }}</strong>
                {{
                  $t(
                    "privacy.policy.interpretations-and-definitions.definitions.definition10-explanation"
                  )
                }}
              </p>
            </li>
            <li>
              <p>
                <strong>{{
                  $t("privacy.policy.interpretations-and-definitions.definitions.definition11")
                }}</strong>
                {{
                  $t(
                    "privacy.policy.interpretations-and-definitions.definitions.definition11-explanation"
                  )
                }}
              </p>
            </li>
          </ul>
          <h1>{{ $t("privacy.policy.collecting-personal-data.header") }}</h1>
          <h2>{{ $t("privacy.policy.collecting-personal-data.types-of-data.header") }}</h2>
          <h3 class="pt-2">
            {{ $t("privacy.policy.collecting-personal-data.types-of-data.personal-data.header") }}
          </h3>
          <p>
            {{ $t("privacy.policy.collecting-personal-data.types-of-data.personal-data.line1") }}
          </p>
          <ul>
            <li>
              <p>
                {{
                  $t("privacy.policy.collecting-personal-data.types-of-data.personal-data.line2")
                }}
              </p>
            </li>
            <li>
              <p>
                {{
                  $t("privacy.policy.collecting-personal-data.types-of-data.personal-data.line3")
                }}
              </p>
            </li>
            <li>
              <p>
                {{
                  $t("privacy.policy.collecting-personal-data.types-of-data.personal-data.line4")
                }}
              </p>
            </li>
            <li>
              <p>
                {{
                  $t("privacy.policy.collecting-personal-data.types-of-data.personal-data.line5")
                }}
              </p>
            </li>
          </ul>
          <h3>
            {{
              $t(
                "privacy.policy.collecting-personal-data.types-of-data.personal-data.usage-data.header"
              )
            }}
          </h3>
          <p>
            {{
              $t(
                "privacy.policy.collecting-personal-data.types-of-data.personal-data.usage-data.line1"
              )
            }}
          </p>
          <p>
            {{
              $t(
                "privacy.policy.collecting-personal-data.types-of-data.personal-data.usage-data.line2"
              )
            }}
          </p>
          <p>
            {{
              $t(
                "privacy.policy.collecting-personal-data.types-of-data.personal-data.usage-data.line3"
              )
            }}
          </p>
          <p>
            {{
              $t(
                "privacy.policy.collecting-personal-data.types-of-data.personal-data.usage-data.line4"
              )
            }}
          </p>
          <h3>
            {{
              $t(
                "privacy.policy.collecting-personal-data.types-of-data.personal-data.information-collected.header"
              )
            }}
          </h3>
          <p>
            {{
              $t(
                "privacy.policy.collecting-personal-data.types-of-data.personal-data.information-collected.line1"
              )
            }}
          </p>
          <ul>
            <li>
              {{
                $t(
                  "privacy.policy.collecting-personal-data.types-of-data.personal-data.information-collected.line2"
                )
              }}
            </li>
          </ul>
          <p>
            {{
              $t(
                "privacy.policy.collecting-personal-data.types-of-data.personal-data.information-collected.line3"
              )
            }}
          </p>
          <p>
            {{
              $t(
                "privacy.policy.collecting-personal-data.types-of-data.personal-data.information-collected.line4"
              )
            }}
          </p>
          <h2>{{ $t("privacy.policy.collecting-personal-data.use-of-personal-data.header") }}</h2>
          <p>{{ $t("privacy.policy.collecting-personal-data.use-of-personal-data.line1") }}</p>
          <ul>
            <li>
              <p>
                <strong>{{
                  $t("privacy.policy.collecting-personal-data.use-of-personal-data.line2a")
                }}</strong
                >{{ $t("privacy.policy.collecting-personal-data.use-of-personal-data.line2b") }}
              </p>
            </li>
            <li>
              <p>
                <strong>{{
                  $t("privacy.policy.collecting-personal-data.use-of-personal-data.line3a")
                }}</strong
                >{{ $t("privacy.policy.collecting-personal-data.use-of-personal-data.line3b") }}
              </p>
            </li>
            <li>
              <p>
                <strong>{{
                  $t("privacy.policy.collecting-personal-data.use-of-personal-data.line4a")
                }}</strong
                >{{ $t("privacy.policy.collecting-personal-data.use-of-personal-data.line4b") }}
              </p>
            </li>
            <li>
              <p>
                <strong>{{
                  $t("privacy.policy.collecting-personal-data.use-of-personal-data.line5a")
                }}</strong
                >{{ $t("privacy.policy.collecting-personal-data.use-of-personal-data.line5b") }}
              </p>
            </li>
            <li>
              <p>
                <strong>{{
                  $t("privacy.policy.collecting-personal-data.use-of-personal-data.line6a")
                }}</strong
                >{{ $t("privacy.policy.collecting-personal-data.use-of-personal-data.line6b") }}
              </p>
            </li>
            <li>
              <p>
                <strong>{{
                  $t("privacy.policy.collecting-personal-data.use-of-personal-data.line7a")
                }}</strong
                >{{ $t("privacy.policy.collecting-personal-data.use-of-personal-data.line7b") }}
              </p>
            </li>
            <li>
              <p>
                <strong>{{
                  $t("privacy.policy.collecting-personal-data.use-of-personal-data.line8a")
                }}</strong
                >{{ $t("privacy.policy.collecting-personal-data.use-of-personal-data.line8b") }}
              </p>
            </li>
            <li>
              <p>
                <strong>{{
                  $t("privacy.policy.collecting-personal-data.use-of-personal-data.line9a")
                }}</strong
                >{{ $t("privacy.policy.collecting-personal-data.use-of-personal-data.line9b") }}
              </p>
            </li>
          </ul>
          <p>{{ $t("privacy.policy.collecting-personal-data.use-of-personal-data.line10") }}</p>
          <ul>
            <li>
              <strong>{{
                $t("privacy.policy.collecting-personal-data.use-of-personal-data.line11a")
              }}</strong
              >{{ $t("privacy.policy.collecting-personal-data.use-of-personal-data.line11b") }}
            </li>
            <li>
              <strong>{{
                $t("privacy.policy.collecting-personal-data.use-of-personal-data.line12a")
              }}</strong
              >{{ $t("privacy.policy.collecting-personal-data.use-of-personal-data.line12b") }}
            </li>
            <li>
              <strong>{{
                $t("privacy.policy.collecting-personal-data.use-of-personal-data.line13a")
              }}</strong
              >{{ $t("privacy.policy.collecting-personal-data.use-of-personal-data.line13b") }}
            </li>
            <li>
              <strong>{{
                $t("privacy.policy.collecting-personal-data.use-of-personal-data.line14a")
              }}</strong
              >{{ $t("privacy.policy.collecting-personal-data.use-of-personal-data.line14b") }}
            </li>
            <li>
              <strong>{{
                $t("privacy.policy.collecting-personal-data.use-of-personal-data.line15a")
              }}</strong
              >{{ $t("privacy.policy.collecting-personal-data.use-of-personal-data.line15b") }}
            </li>
            <li>
              <strong>{{
                $t("privacy.policy.collecting-personal-data.use-of-personal-data.line16a")
              }}</strong
              >{{ $t("privacy.policy.collecting-personal-data.use-of-personal-data.line16b") }}
            </li>
          </ul>
          <h2 class="pt-3">
            {{ $t("privacy.policy.collecting-personal-data.retention-of-data.header") }}
          </h2>
          <p>{{ $t("privacy.policy.collecting-personal-data.retention-of-data.line1") }}</p>
          <p>{{ $t("privacy.policy.collecting-personal-data.retention-of-data.line2") }}</p>
          <h2>{{ $t("privacy.policy.collecting-personal-data.transfer-of-data.header") }}</h2>
          <p>{{ $t("privacy.policy.collecting-personal-data.transfer-of-data.line1") }}</p>
          <p>{{ $t("privacy.policy.collecting-personal-data.transfer-of-data.line2") }}</p>
          <p>{{ $t("privacy.policy.collecting-personal-data.transfer-of-data.line3") }}</p>
          <h2>{{ $t("privacy.policy.collecting-personal-data.disclosure-of-data.header") }}</h2>
          <h3>
            {{
              $t(
                "privacy.policy.collecting-personal-data.disclosure-of-data.business-transactions.header"
              )
            }}
          </h3>
          <p>
            {{
              $t(
                "privacy.policy.collecting-personal-data.disclosure-of-data.business-transactions.line1"
              )
            }}
          </p>
          <h3>
            {{
              $t(
                "privacy.policy.collecting-personal-data.disclosure-of-data.law-enforcement.header"
              )
            }}
          </h3>
          <p>
            {{
              $t("privacy.policy.collecting-personal-data.disclosure-of-data.law-enforcement.line1")
            }}
          </p>
          <h3>
            {{
              $t(
                "privacy.policy.collecting-personal-data.disclosure-of-data.law-enforcement.other-legal.header"
              )
            }}
          </h3>
          <p>
            {{
              $t(
                "privacy.policy.collecting-personal-data.disclosure-of-data.law-enforcement.other-legal.line1"
              )
            }}
          </p>
          <ul>
            <li>
              {{
                $t(
                  "privacy.policy.collecting-personal-data.disclosure-of-data.law-enforcement.other-legal.line2"
                )
              }}
            </li>
            <li>
              {{
                $t(
                  "privacy.policy.collecting-personal-data.disclosure-of-data.law-enforcement.other-legal.line3"
                )
              }}
            </li>
            <li>
              {{
                $t(
                  "privacy.policy.collecting-personal-data.disclosure-of-data.law-enforcement.other-legal.line4"
                )
              }}
            </li>
            <li>
              {{
                $t(
                  "privacy.policy.collecting-personal-data.disclosure-of-data.law-enforcement.other-legal.line5"
                )
              }}
            </li>
            <li>
              {{
                $t(
                  "privacy.policy.collecting-personal-data.disclosure-of-data.law-enforcement.other-legal.line6"
                )
              }}
            </li>
          </ul>
          <h2 class="pt-4">
            {{ $t("privacy.policy.collecting-personal-data.security-of-personal-data.header") }}
          </h2>
          <p>{{ $t("privacy.policy.collecting-personal-data.security-of-personal-data.line1") }}</p>
          <h1>{{ $t("privacy.policy.childrens-privacy.header") }}</h1>
          <p>{{ $t("privacy.policy.childrens-privacy.line1") }}</p>
          <p>{{ $t("privacy.policy.childrens-privacy.line2") }}</p>
          <h1>{{ $t("privacy.policy.links-to-websites.header") }}</h1>
          <p>{{ $t("privacy.policy.links-to-websites.line1") }}</p>
          <p>{{ $t("privacy.policy.links-to-websites.line2") }}</p>
          <h1>{{ $t("privacy.policy.changes-to-policy.header") }}</h1>
          <p>{{ $t("privacy.policy.changes-to-policy.line1") }}</p>
          <p>{{ $t("privacy.policy.changes-to-policy.line2") }}</p>
          <p>{{ $t("privacy.policy.changes-to-policy.line3") }}</p>
          <h1>{{ $t("privacy.policy.contact-us.header") }}</h1>
          <p>{{ $t("privacy.policy.contact-us.line1") }}</p>
          <ul>
            <li>
              {{ $t("privacy.policy.contact-us.line2") }}
              <a :href="companyContactURL" rel="external nofollow noopener" target="_blank">{{
                companyContactURL
              }}</a>
            </li>
          </ul>
        </v-card-text>
      </v-container>
      <v-card-actions>
        <v-spacer />
        <v-btn text @click="dialog = false">{{ $t("common.close") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "PrivacyDialog",

  data: () => ({
    platformName: "Swift Plant",
    lastUpdated: "January 25, 2022",
    companyName: "MPC Solutions LLC",
    companyAddress: "1006 Lemke avenue, Rothschild, WI, 54474",
    companyCountry: "Wisconsin,  United States",
    companyContactURL: "https://mpcsolutionsllc.com/contact.html"
  }),

  computed: {
    dialog: {
      get() {
        return this.$store.state.dialogPrivacy;
      },
      set(val) {
        this.$store.commit("SET_PRIVACY_DIALOG", val);
      }
    }
  }
};
</script>
