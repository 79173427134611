import { mapMutations, mapState, mapActions } from "vuex";
import FDVue from "@fd/lib/vue";
import errorHandling from "@fd/lib/vue/mixins/errorHandling";
import fileHandling, {
  componentsFromFileName,
  confirmUniqueName,
  canOpenFileInNewWindow,
  isFilePreviewable,
  FileData,
  isFilePhoto
} from "@fd/lib/vue/mixins/fileHandling";

import rules from "@fd/lib/vue/rules";
import userAccess from "../dataMixins/userAccess";
import tabbedView, { Tab } from "@fd/lib/vue/mixins/tabbedView";
import { FDColumnDirective } from "../../../lib/vue/utility/dataTable";
import { TranslateResult } from "vue-i18n";
import {
  Language,
  QuestionWithAnswer,
  ScaffoldInspectionWithDetails,
  Tag,
  scaffoldInspectionService
} from "../services";
import downloadBlob from "../../../lib/client-util/downloadBlob";
import { showTextPromptDialog } from "../../../common/client/views/components/TextPromptDialog.vue";
import * as DateUtil from "@fd/lib/client-util/datetime";

type ScaffoldInspectionWithExtraDetails = ScaffoldInspectionWithDetails & {
  archived: boolean;
  daysStanding: number | undefined;
};
export default FDVue.extend({
  name: "fd-scaffold-inspection-existing",

  mixins: [errorHandling, rules, userAccess, tabbedView, fileHandling],
  directives: {
    fdColumn: FDColumnDirective
  },

  components: {
    "fd-add-file-button": () => import("@fd/lib/vue/components/AddFileButton.vue"),
    "fd-time-picker": () => import("@fd/lib/vue/components/TimePicker.vue"),
    "fd-chip-selector": () => import("@fd/lib/vue/components/ChipItemSelector.vue"),
    "fd-scaffold-inspection-location": () =>
      import("./components/data/scaffoldInspections/SP.ScaffoldInspectionLocation.vue")
  },

  data: function() {
    return {
      // The following will control whether or not the save button shows the processing/loading indicator
      saving: false,
      deleting: false,
      processing: false,

      detailsReadonly: true,

      slidein: false,

      selectedTags: [] as Tag[],

      pass: true,

      firstTabKey: `0`,
      detailsTab: {
        tabname: this.$t("inspections.existing.tabs.details"),
        key: "0",
        visible: true
      } as Tab,
      questionsTab: {
        tabname: this.$t("inspections.existing.tabs.questions"),
        key: "1",
        visible: true
      } as Tab,
      photosTab: {
        tabname: this.$t("inspections.existing.tabs.photos"),
        key: "2",
        visible: false
      } as Tab,
      locationTab: {
        tabname: this.$t("inspections.existing.tabs.location"),
        key: "3",
        visible: false
      } as Tab,

      scaffoldInspection: { questionAnswers: {} } as ScaffoldInspectionWithExtraDetails,

      // *** ATTACHMENTS ***
      touchedFileName: "",
      tablesearchfiles: "",
      allFiles: [] as FileData[],

      /*** IMAGE EDIT ****/
      newFileData: undefined as FileData | undefined,
      editingFileData: undefined as FileData | undefined
    };
  },

  computed: {
    languageslist(): Language[] {
      return this.$store.state.languages.fullList as Language[];
    },
    unwatchedMethodNames(): string[] {
      return ["getQuestionAnswer", "parseBooleanAnswer", "getQuestionText"];
    },
    tabDefinitions(): Tab[] {
      // Details is not included since it's the first tab and is always visible
      return [this.questionsTab, this.photosTab, this.locationTab] as Tab[];
    },
    availableTags() {
      return this.$store.getters.getSortedEnabledInUseTags(this.selectedTags);
    },
    photoFiles(): FileData[] {
      return this.allFiles.filter(x => x.isPhoto);
    }
  },

  methods: {
    onSubmit(e: Event) {
      e.preventDefault();
      this.save(false);
    },

    preventSubmit(e: Event) {
      e.preventDefault();
      return false;
    },
    // Method used in conjunction with the Save button.
    async save(closeOnComplete: boolean) {
      // First reset the inline message if there are any.
      this.inlineMessage.message = "";

      if (!(this.$refs.form as HTMLFormElement).validate()) {
        this.inlineMessage.message = this.$t("inspections.existing.details-error-message");
        this.inlineMessage.type = "error";
        return;
      }

      this.processing = true;
      this.saving = true;
      // try {
      //   if (!this.supplier.archived) {
      //     this.supplier.archivedDate = null;
      //   } else if (this.supplier.archived && !this.supplier.archivedDate) {
      //     this.supplier.archivedDate = new Date(new Date().toUTCString());
      //   }
      //   this.supplier.partIDs = this.selectedPartIDs;

      //   await this.updateSupplier({
      //     ...this.supplier,
      //     tagIDs: this.selectedTags.length > 0 ? this.selectedTags.map((x: any) => x.id) : null
      //   });
      //   if (closeOnComplete) {
      //     this.$router.push("/suppliers");
      //   }
      // } catch (error) {
      //   this.handleError(error as Error);
      // } finally {
      //   this.processing = false;
      //   this.saving = false;
      // }
    },
    // the following works with the delete "Action" button in the Datatable.
    async deleteItem() {
      this.inlineMessage.message = null;
      this.processing = true;
      this.deleting = true;
      try {
        // await this.deleteSupplier({ id: this.$route.params.id, name: this.supplier.name });
        // this.$router.push("/suppliers");
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.deleting = false;
      }
    },

    // Method used in conjunction with the Cancel button.
    cancel() {
      // TODO: Should this roll back state rather than rely on requerying?
      this.$router.push("/inspections");
    },
    ...mapMutations({
      notifyNewBreadcrumb: "NOTIFY_NEW_BREADCRUMB",
      setFilteringContext: "SET_FILTERING_CONTEXT"
    }),
    ...mapActions({
      loadTags: "LOAD_TAGS"
    }),

    // Files & Photos
    async loadFiles() {
      var fileNames = await scaffoldInspectionService.getScaffoldInspectionFileList(
        this.scaffoldInspection.id!
      );
      this.allFiles = fileNames.map(function(fileName) {
        return {
          name: fileName,
          isPreviewable: isFilePreviewable(fileName),
          isPhoto: isFilePhoto(fileName)
        } as FileData;
      });
    },
    async selectFile() {
      (this.$refs.addFileButton as any).click();
    },
    async selectNewFile(originalFile: File) {
      var fileData = await this.optimizedFileDataForUpload(originalFile, this.allFiles);
      if (!fileData) return;
      // GIF files with animations will lose their animation during this process
      // Both due to the quality compression done above (resizing the dimensions of an animated GIF does nothing), and also going through the edit image process
      // This is OK as we shouldn't need animations for any reason
      if (fileData.isPreviewable) {
        this.newFileData = fileData;
        this.imageName = fileData.name;
        this.editImageSource = this.covertFileToDataURL(fileData.file);
      } else {
        await this.saveNewFileData(fileData);
      }
    },
    async handleEdit(res: File, fileName: string | undefined) {
      this.editImageSource = undefined;
      this.imageName = "";

      if (!!this.newFileData) {
        this.newFileData.file = res;
        if (!!fileName) this.newFileData.name = confirmUniqueName(fileName, this.allFiles);

        await this.saveNewFileData(this.newFileData);

        this.newFileData = undefined;
      } else if (!!this.editingFileData) {
        var originalFileName = this.editingFileData.name;

        var allFilesWithoutEditedFileData = this.allFiles.slice();
        allFilesWithoutEditedFileData.splice(
          allFilesWithoutEditedFileData.indexOf(this.editingFileData),
          1
        );
        var uniqueFileName = confirmUniqueName(
          fileName ?? originalFileName,
          allFilesWithoutEditedFileData
        );

        this.editingFileData.name = uniqueFileName;
        this.editingFileData.file = res;

        this.saveEditedFileData(this.editingFileData, originalFileName);

        this.editingFileData = undefined;
      }
    },
    async saveEditedFileData(fileData: FileData, originalFileName: string) {
      if (!fileData) return;

      this.processing = true;
      try {
        if (!fileData.file) {
          // If we're only renaming the file, the data may not be downloaded yet
          let fileNameToDownload = originalFileName ?? fileData.name;
          fileData.file = await scaffoldInspectionService.downloadScaffoldInspectionFile(
            this.scaffoldInspection.id!,
            fileNameToDownload
          );
        }
        await scaffoldInspectionService.uploadScaffoldInspectionFile(
          this.scaffoldInspection.id!,
          fileData.name,
          fileData.file as Blob
        );

        if (!!originalFileName && originalFileName != fileData.name) {
          // File has been renamed.  The file in the list has already been updated with all relevant data, but we need to delete the file with the old name
          // We don't call the delete method here because we don't care about its data, an undo, or a delete snackbar
          await scaffoldInspectionService.deleteScaffoldInspectionFile(
            this.scaffoldInspection.id!,
            originalFileName
          );
        }
        let snackbarText = fileData.isPhoto
          ? this.$t("scaffold-requests.existing-scaffold-request.update-photo-success", [
              fileData.name
            ])
          : this.$t("scaffold-requests.existing-scaffold-request.update-file-success", [
              fileData.name
            ]);
        let snackbarPayload = {
          text: snackbarText,
          type: "success"
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
        this.touchedFileName = fileData.name;
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    async saveNewFileData(fileData: FileData | undefined) {
      if (!fileData) return;

      this.processing = true;
      try {
        await scaffoldInspectionService.uploadScaffoldInspectionFile(
          this.scaffoldInspection.id!,
          fileData.name,
          fileData.file as Blob
        );

        this.allFiles.push(fileData);

        let snackbarText = fileData.isPhoto
          ? this.$t("scaffold-requests.existing-scaffold-request.save-photo-success", [
              fileData.name
            ])
          : this.$t("scaffold-requests.existing-scaffold-request.save-file-success", [
              fileData.name
            ]);
        let snackbarPayload = {
          text: snackbarText,
          type: "success",
          undoCallback: null
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);

        this.touchedFileName = fileData.name;
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    async editNameForFile(fileData: FileData) {
      let components = componentsFromFileName(fileData.name);
      let newName = await showTextPromptDialog({
        title: this.$t("attachments.edit-file-name-title"),
        label: this.$t("common.name"),
        rules: [this.rules.required],
        text: components.name
      });
      if (!!newName?.length && newName.toLowerCase() != components.name.toLowerCase()) {
        let newFileName = `${newName}.${components.extension}`;
        var originalFileName = fileData.name;
        if (newFileName.toLowerCase() == originalFileName.toLowerCase()) return;

        var uniqueFileName = confirmUniqueName(newFileName, this.allFiles);

        fileData.name = uniqueFileName;
        this.saveEditedFileData(fileData, originalFileName);

        this.editingFileData = undefined;
      }
    },
    async editFile(fileData: FileData) {
      if (!fileData.isPhoto) return;

      this.editingFileData = fileData;
      this.imageName = fileData.name;
      if (!fileData.file) {
        // Cache the file data to avoid having to download it multiple times
        var file = await scaffoldInspectionService.downloadScaffoldInspectionFile(
          this.scaffoldInspection.id!,
          fileData.name
        );
        fileData.file = file;
      }
      if (!!fileData.file) {
        this.editImageSource = this.covertFileToDataURL(fileData.file);
      } else {
        this.editImageSource = `/services/FormidableDesigns.Services.V1.ScaffoldInspectionService.DownloadScaffoldInspectionFile?scaffoldInspectionId=${this.scaffoldInspection.id}&fileName=${fileData.name}`;
      }
    },
    async downloadFile(fileData: FileData) {
      if (!!fileData.file) {
        downloadBlob(fileData.file, fileData.name);
        return;
      }

      let fileName = fileData.name;
      this.processing = true;
      try {
        var file = await scaffoldInspectionService.downloadScaffoldInspectionFile(
          this.scaffoldInspection.id!,
          fileName
        );
        downloadBlob(file, fileName);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    async openFileInNewWindow(fileData: FileData) {
      let currentProcessing = this.processing;
      this.processing = true;
      if (!fileData.file) {
        // the data probably hasn't been downloaded yet
        fileData.file = await scaffoldInspectionService.downloadScaffoldInspectionFile(
          this.scaffoldInspection.id!,
          fileData.name
        );
      }
      let url = URL.createObjectURL(fileData.file);
      window.open(url, "_blank");
      this.processing = currentProcessing;
    },
    async viewFile(fileData: FileData) {
      if (!fileData.isPreviewable) return;

      this.imageName = fileData.name;
      if (!fileData.file) {
        // Cache the file data to avoid having to download it multiple times
        var file = await scaffoldInspectionService.downloadScaffoldInspectionFile(
          this.scaffoldInspection.id!,
          fileData.name
        );
        fileData.file = file;
      }
      if (!!fileData.file) {
        this.imageSource = this.covertFileToDataURL(fileData.file);
      } else {
        this.imageSource = `/services/FormidableDesigns.Services.V1.ScaffoldInspectionService.DownloadScaffoldInspectionFile?scaffoldInspectionId=${this.scaffoldInspection.id}&fileName=${fileData.name}`;
      }
    },
    async deleteFile(fileData: FileData) {
      this.processing = true;
      try {
        if (!fileData.file) {
          // When deleting from the table, the data probably hasn't been downloaded yet
          // So we can't do an undo unless we get the file data to re-save first
          fileData.file = await scaffoldInspectionService.downloadScaffoldInspectionFile(
            this.scaffoldInspection.id!,
            fileData.name
          );
        }
        await scaffoldInspectionService.deleteScaffoldInspectionFile(
          this.scaffoldInspection.id!,
          fileData.name
        );

        this.allFiles.splice(this.allFiles.indexOf(fileData), 1);

        let snackbarText = fileData.isPhoto
          ? this.$t("scaffold-requests.existing-scaffold-request.delete-photo-success", [
              fileData.name
            ])
          : this.$t("scaffold-requests.existing-scaffold-request.delete-file-success", [
              fileData.name
            ]);
        var snackbarPayload = {
          text: snackbarText,
          type: "info",
          undoCallback: async () => {
            await this.saveNewFileData(fileData);
          }
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
        this.touchedFileName = fileData.name;
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    getQuestionText(question: QuestionWithAnswer): string | undefined {
      if (!question.textByLanguageCode) return undefined;

      var languageNumber = this.$store.state.language;
      var selectedLanguage = this.languageslist.find(x => x.number == languageNumber);
      var languageCode = selectedLanguage?.shortCode ?? "en";

      var questionText = question.textByLanguageCode[languageCode];
      if (!questionText) {
        let key = Object.keys(question.textByLanguageCode)[0];
        questionText = question.textByLanguageCode[key];
      }
      return questionText;
    },
    parseBooleanAnswer(answer: String | undefined): boolean {
      let isTrue = !!answer?.length && answer.toLowerCase() == "true";
      return isTrue;
    }
  },

  watch: {
    scaffoldInspection(newValue) {
      console.log(`watch: ${this.$store.state.lastBreadcrumbs[0]?.to}`);
      if ((this.$store.state.lastBreadcrumbs[0]?.to || "") == "/scaffolds") {
      } else {
        if ((this.$store.state.lastBreadcrumbs[0]?.to || "") != "/inspections") {
          this.notifyNewBreadcrumb({
            text: this.$t("inspections.list.title"),
            to: "/inspections",
            resetHistory: true
          });
          // This is needed in order to salvage the "last breadcrumbs" in the store.
          this.$store.commit("NOTIFY_NAVIGATION_STARTED");
        }
      }
      let name = this.$t("inspections.existing.name-title", [
        this.scaffoldInspection.tagNumber,
        DateUtil.stripTimeFromLocalizedDateTime(this.scaffoldInspection.inspectionDateTime)
      ]);
      this.notifyNewBreadcrumb({
        text: name,
        to: `/inspections/${this.$route.params.id}`
      });
    }
  },

  created: async function() {
    // Add a small delay of time before the view comes in so that the "slide in" animation will be seen by the user.
    setInterval(() => {
      this.slidein = true;
    }, 100);

    console.log(`Created: ${this.$store.state.lastBreadcrumbs[0]?.to}`);
    var parentalContext = undefined;
    if ((this.$store.state.lastBreadcrumbs[0]?.to || "") == "/scaffolds") {
      parentalContext = "scaffolds-existing";
    } else {
      if ((this.$store.state.lastBreadcrumbs[0]?.to || "") != "/inspections") {
        this.notifyNewBreadcrumb({
          text: this.$t("inspections.list.title"),
          to: "/inspections",
          resetHistory: true
        });
        // This is needed in order to salvage the "last breadcrumbs" in the store.
        this.$store.commit("NOTIFY_NAVIGATION_STARTED");
      }
      parentalContext = "scaffoldInspections";

      // Only show loading if we're coming from the list or if there is no breadcrumb
      // Doing this clears the breadcrumbs for some reason breaking the history
      this.notifyNewBreadcrumb({
        text: this.$t("loading-dot-dot-dot"),
        disabled: true
      });
    }

    // Set the context for the User Filtering in the store so that if the user navigates to a screen that is
    // a sub screen of something that is currently filtered by their choices that those choices will be
    // preserved as they move between the two screens.
    this.setFilteringContext({
      context: "inspectionexisting",
      parentalContext: parentalContext,
      selectedTab: this.firstTabKey
    });

    this.processing = true;
    try {
      await Promise.all([this.loadTags()]);

      let scaffoldInspection = await scaffoldInspectionService.getByID(this.$route.params.id);
      this.scaffoldInspection = {
        ...scaffoldInspection
      } as ScaffoldInspectionWithExtraDetails;
      this.scaffoldInspection.archived = !!scaffoldInspection.archivedDate;
      this.scaffoldInspection.daysStanding = !!scaffoldInspection.scaffoldActualErectDate
        ? Math.round(
            ((!!scaffoldInspection.scaffoldActualDismantleDate
              ? scaffoldInspection.scaffoldActualDismantleDate
              : new Date()
            ).getTime() -
              scaffoldInspection.scaffoldActualErectDate.getTime()) /
              86400000
          )
        : undefined;

      if (this.scaffoldInspection.tagIDs) {
        this.selectedTags = this.scaffoldInspection.tagIDs
          .map((x: any) => this.$store.state.tags.fullList.find((y: any) => y.id == x))
          .filter((x: any) => x);
      } else {
        this.selectedTags = [];
      }
      await this.loadFiles();
    } catch (error) {
      this.handleError(error as Error);
    } finally {
      this.processing = false;
    }
  }
});


