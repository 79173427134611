var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _vm.inlineMessage.message
        ? _c(
            "v-alert",
            {
              staticClass: "mt-0 mb-3",
              attrs: { type: _vm.inlineMessage.type }
            },
            [
              _vm._v(
                "\n        " + _vm._s(_vm.inlineMessage.message) + "\n    "
              )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-card",
        [
          _c(
            "v-toolbar",
            {
              class: [
                _vm.processing
                  ? "view-card-top-color-processing"
                  : "view-card-top-color"
              ],
              scopedSlots: _vm._u([
                {
                  key: "extension",
                  fn: function() {
                    return [
                      _c(
                        "v-btn",
                        {
                          staticClass: "add-fab-button-primary",
                          attrs: {
                            dark: "",
                            absolute: "",
                            bottom: "",
                            left: "",
                            fab: ""
                          },
                          on: {
                            click: function($event) {
                              return _vm.openNewDialog()
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("mdi-plus")])],
                        1
                      )
                    ]
                  },
                  proxy: true
                }
              ])
            },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c("v-breadcrumbs", {
                        class: [
                          _vm.processing ? "breadcrumb-processing-opacity" : "",
                          "pl-0"
                        ],
                        attrs: {
                          items: _vm.$store.state.currentBreadcrumbs,
                          large: ""
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "divider",
                            fn: function() {
                              return [
                                _c("v-icon", [_vm._v("fa-chevron-right")])
                              ]
                            },
                            proxy: true
                          }
                        ])
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c(
                        "v-row",
                        { attrs: { justify: "end" } },
                        [
                          _c("v-combobox", {
                            class: [
                              _vm.tagsSelectedForFiltering.length > 0
                                ? "fd-combobox-control-item-selected"
                                : "fd-combobox-control-item-not-selected",
                              "pt-5",
                              "pr-3",
                              "fd-limit-combobox-size-to-content"
                            ],
                            attrs: {
                              items: _vm.tagsInUse,
                              label: _vm.$t("common.filter-by-tags"),
                              "item-text": "name",
                              "item-value": "id",
                              multiple: "",
                              "small-chips": "",
                              dense: ""
                            },
                            model: {
                              value: _vm.tagsSelectedForFiltering,
                              callback: function($$v) {
                                _vm.tagsSelectedForFiltering = $$v
                              },
                              expression: "tagsSelectedForFiltering"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-subtitle",
            { staticStyle: { "margin-top": "15px" } },
            [
              _c("v-text-field", {
                attrs: {
                  "append-icon": "search",
                  label: _vm.$t("common.search"),
                  "single-line": "",
                  "hide-details": ""
                },
                model: {
                  value: _vm.tablesearch,
                  callback: function($$v) {
                    _vm.tablesearch = $$v
                  },
                  expression: "tablesearch"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("v-data-table", {
            attrs: {
              headers: _vm.headers,
              items: _vm.mappings,
              search: _vm.tablesearch,
              loading: _vm.processing,
              "loading-text": _vm.$t("common.table-loading-message"),
              "sort-by": _vm.sortTableBy
            },
            scopedSlots: _vm._u([
              {
                key: "header.name",
                fn: function({ header }) {
                  return [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.$t("common." + header.value)) +
                        "\n            "
                    )
                  ]
                }
              },
              {
                key: "header.description",
                fn: function({ header }) {
                  return [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.$t("common." + header.value)) +
                        "\n            "
                    )
                  ]
                }
              },
              {
                key: "header.enabled",
                fn: function({ header }) {
                  return [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.$t("common." + header.value)) +
                        "\n            "
                    )
                  ]
                }
              },
              {
                key: "header.actions",
                fn: function({ header }) {
                  return [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.$t("common." + header.value)) +
                        "\n            "
                    )
                  ]
                }
              },
              {
                key: "item.enabled",
                fn: function({ item }) {
                  return [
                    _c("v-simple-checkbox", {
                      attrs: { value: item.enabled, disabled: _vm.processing },
                      on: {
                        input: function($event) {
                          return _vm.flipEnabled(item)
                        }
                      }
                    })
                  ]
                }
              },
              {
                key: "item.actions",
                fn: function({ item }) {
                  return [
                    _c(
                      "div",
                      { staticClass: "table-actions-column-min-width" },
                      [
                        _c(
                          "v-tooltip",
                          {
                            attrs: { left: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function({ on }) {
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            attrs: {
                                              icon: "",
                                              link: "",
                                              disabled: _vm.processing,
                                              to: `/mappings/${item.id}`
                                            }
                                          },
                                          on
                                        ),
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { small: "" } },
                                            [
                                              _vm._v(
                                                "\n                                    mdi-pencil\n                                "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [
                            _vm._v(" "),
                            _c("span", [_vm._v(_vm._s(_vm.$t("common.edit")))])
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "v-tooltip",
                          {
                            attrs: { right: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function({ on }) {
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            attrs: {
                                              icon: "",
                                              disabled: _vm.processing
                                            }
                                          },
                                          on
                                        ),
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                small: "",
                                                disabled: _vm.processing
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.deleteTableItem(
                                                    item
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                                    mdi-delete\n                                "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("common.remove")))
                            ])
                          ]
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("fd-mapping-new-dialog", {
        attrs: { show: _vm.showNewDialog },
        on: {
          "update:show": function($event) {
            _vm.showNewDialog = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }