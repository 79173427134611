import { ScaffoldBayWidth, scaffoldBayWidthService2 } from "../../services";
import { Record, BasicReferenceDataCrudService } from "../baseTypes";
import { createReferenceDataStore } from "./register";

// TODO: Revisit the fact that Contractor's ID field is undefined-able - this forces us to add additional qualifiers
// TODO: Revisit the fact that Contractor's name field is undefined-able

const scaffoldBayWidthDataStore = createReferenceDataStore<ScaffoldBayWidth & Record>(
  "scaffoldBayWidth",
  scaffoldBayWidthService2 as BasicReferenceDataCrudService<ScaffoldBayWidth & Record>,
  x => x.name!
);
export default scaffoldBayWidthDataStore;

