import { partService } from "../services/index";
import { createAutomaticCrudStoreModule } from "./automatic";

var storeModule = createAutomaticCrudStoreModule({
  crudService: partService,
  singularStoreName: "PART",
  pluralStoreName: "PARTS",
  localizationPrefix: "parts",
  consumerRelatedIDProperty: {
    name: "partID",
    type: "string"
  },
  storeExtensions: {
    actions: {
      async UPLOAD_PARTS(context, payload: Blob) {
        var response = await partService.uploadPartList(payload);
        await context.dispatch("LOAD_PARTS");
        return response;
      }
    }
  }
});

export default storeModule;
