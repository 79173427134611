var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { staticClass: "pa-0", attrs: { fluid: "" } },
    [
      _c(
        "v-dialog",
        {
          attrs: { width: "800px" },
          model: {
            value: _vm.showBCount,
            callback: function($$v) {
              _vm.showBCount = $$v
            },
            expression: "showBCount"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                {
                  class: [
                    _vm.processing
                      ? "dialog-new-top-color-processing"
                      : "dialog-new-top-color"
                  ]
                },
                [
                  _c(
                    "span",
                    {
                      class: [
                        _vm.processing ? "breadcrumb-processing-opacity" : ""
                      ]
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "scheduler.estimates.takeoff-panel.bcount-label"
                          )
                        )
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _vm.processing
                    ? _c("v-progress-circular", {
                        attrs: {
                          indeterminate: true,
                          rotate: 0,
                          size: 32,
                          width: 4,
                          color: "white"
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-container",
                [
                  _c(
                    "div",
                    {
                      staticClass: "pa-3",
                      staticStyle: {
                        width: "100%",
                        border: "1px dashed",
                        "border-radius": "4px",
                        overflow: "auto",
                        "max-height": "calc(90vh - 150px)"
                      }
                    },
                    [
                      _c(
                        "span",
                        { staticStyle: { "white-space": "pre-line" } },
                        [_vm._v(_vm._s(_vm.partsBCount))]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("fd-alert", {
                    attrs: {
                      type: "info",
                      label: _vm.$t(
                        "scheduler.estimates.takeoff-panel.bcount-copied-message"
                      ),
                      dismissible: ""
                    },
                    model: {
                      value: _vm.bCountCopied,
                      callback: function($$v) {
                        _vm.bCountCopied = $$v
                      },
                      expression: "bCountCopied"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-actions",
                { staticClass: "mx-0 px-3 pb-3" },
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { text: "" },
                      on: {
                        click: function($event) {
                          _vm.showBCount = false
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("common.close")))]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { outlined: "", color: "secondary" },
                      on: { click: _vm.copyBCount }
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "scheduler.estimates.takeoff-panel.copy-bcount-button-label"
                          )
                        )
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "v-card-title",
                [
                  _c(
                    "v-col",
                    { staticClass: "pl-0", attrs: { cols: "6" } },
                    [
                      _c(
                        "v-menu",
                        {
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function({ on }) {
                                return [
                                  _vm.showPrint
                                    ? _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            staticClass:
                                              "mr-3 d-none d-lg-flex",
                                            staticStyle: {
                                              "min-width": "110px"
                                            },
                                            attrs: {
                                              small: "",
                                              rounded: "",
                                              outlined: "",
                                              disabled: _vm.processing,
                                              color: "#7a7a7a"
                                            }
                                          },
                                          on
                                        ),
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                small: "",
                                                left:
                                                  _vm.$vuetify.breakpoint
                                                    .smAndUp
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                  mdi-printer\n                "
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _vm.$vuetify.breakpoint.smAndUp
                                            ? _c("span", [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(
                                                      _vm.$t("common.print")
                                                    ) +
                                                    "\n                "
                                                )
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c(
                                            "v-icon",
                                            {
                                              staticStyle: {
                                                "margin-left": "2px !important"
                                              },
                                              attrs: { right: "" }
                                            },
                                            [_vm._v("mdi-menu-down")]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ]
                              }
                            }
                          ])
                        },
                        [
                          _vm._v(" "),
                          _c(
                            "v-list",
                            [
                              _c(
                                "v-list-item",
                                {
                                  attrs: {
                                    disabled:
                                      _vm.processing || !_vm.canPrintTemplate
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.$emit("print:template")
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.$t("scheduler.printing.template")
                                      ) +
                                      "\n              "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-list-item",
                                {
                                  attrs: {
                                    disabled:
                                      _vm.processing || !_vm.takeoffParts.length
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.$emit("print:pdf")
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.$t("scheduler.printing.pdf")) +
                                      "\n              "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-list-item",
                                {
                                  attrs: {
                                    disabled:
                                      _vm.processing || !_vm.takeoffParts.length
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.$emit("print:xls")
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.$t("scheduler.printing.excel")
                                      ) +
                                      "\n              "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("v-spacer")
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c("v-text-field", {
                        class: [
                          _vm.$vuetify.breakpoint.sm
                            ? "fd-restrict-clearable-position"
                            : "",
                          "pt-0",
                          "mt-0",
                          "fd-table-search-input"
                        ],
                        attrs: {
                          "append-icon": "search",
                          label: _vm.$t("common.search"),
                          "single-line": "",
                          "hide-details": "",
                          clearable: ""
                        },
                        model: {
                          value: _vm.search,
                          callback: function($$v) {
                            _vm.search = $$v
                          },
                          expression: "search"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("v-data-table", {
                directives: [
                  {
                    name: "fd-column",
                    rawName: "v-fd-column:partName",
                    value: _vm.$t("scheduler.estimates.part-name-column-label"),
                    expression:
                      "$t('scheduler.estimates.part-name-column-label')",
                    arg: "partName"
                  },
                  {
                    name: "fd-column",
                    rawName: "v-fd-column:partPublicID.hide-when-extra-small",
                    value: _vm.$t(
                      "scheduler.estimates.part-public-id-column-label"
                    ),
                    expression:
                      "\n          $t('scheduler.estimates.part-public-id-column-label')\n        ",
                    arg: "partPublicID",
                    modifiers: { "hide-when-extra-small": true }
                  },
                  {
                    name: "fd-column",
                    rawName:
                      "v-fd-column:partDescription.hide-when-extra-small",
                    value: _vm.$t("common.description"),
                    expression: "$t('common.description')",
                    arg: "partDescription",
                    modifiers: { "hide-when-extra-small": true }
                  },
                  {
                    name: "fd-column",
                    rawName: "v-fd-column:quantity",
                    arg: "quantity"
                  },
                  {
                    name: "fd-column",
                    rawName: "v-fd-column:estimatedErectMinutes",
                    value: _vm.$t(
                      "scheduler.estimates.erect-mins-column-label"
                    ),
                    expression:
                      "$t('scheduler.estimates.erect-mins-column-label')",
                    arg: "estimatedErectMinutes"
                  },
                  {
                    name: "fd-column",
                    rawName: "v-fd-column:estimatedDismantleMinutes",
                    value: _vm.$t(
                      "scheduler.estimates.dismantle-mins-column-label"
                    ),
                    expression:
                      "\n          $t('scheduler.estimates.dismantle-mins-column-label')\n        ",
                    arg: "estimatedDismantleMinutes"
                  },
                  {
                    name: "fd-column",
                    rawName:
                      "v-fd-column:estimatedMobilizationMinutes.hide-when-small",
                    value: _vm.$t(
                      "scheduler.estimates.mob-demob-mins-column-label"
                    ),
                    expression:
                      "\n          $t('scheduler.estimates.mob-demob-mins-column-label')\n        ",
                    arg: "estimatedMobilizationMinutes",
                    modifiers: { "hide-when-small": true }
                  },
                  {
                    name: "fd-column",
                    rawName:
                      "v-fd-column:estimatedHoardingMinutes.hide-when-small",
                    value: _vm.$t(
                      "scheduler.estimates.total-hoarding-column-label"
                    ),
                    expression:
                      "\n          $t('scheduler.estimates.total-hoarding-column-label')\n        ",
                    arg: "estimatedHoardingMinutes",
                    modifiers: { "hide-when-small": true }
                  },
                  {
                    name: "fd-column",
                    rawName:
                      "v-fd-column:estimatedModifyMinutes.hide-when-small",
                    value: _vm.$t(
                      "scheduler.estimates.modify-mins-column-label"
                    ),
                    expression:
                      "\n          $t('scheduler.estimates.modify-mins-column-label')\n        ",
                    arg: "estimatedModifyMinutes",
                    modifiers: { "hide-when-small": true }
                  },
                  {
                    name: "fd-column",
                    rawName: "v-fd-column:totalWeight.hide-when-extra-small",
                    value: _vm.$t("scheduler.estimates.weight-column-label"),
                    expression:
                      "\n          $t('scheduler.estimates.weight-column-label')\n        ",
                    arg: "totalWeight",
                    modifiers: { "hide-when-extra-small": true }
                  }
                ],
                attrs: {
                  items: _vm.takeoffParts,
                  search: _vm.search,
                  loading: _vm.processing,
                  "loading-text": _vm.$t("common.table-loading-message"),
                  "sort-by": "partName",
                  "mobile-breakpoint": "0"
                },
                scopedSlots: _vm._u([
                  {
                    key: "item.quantity",
                    fn: function({ item }) {
                      return [
                        _c("fd-value-display", {
                          staticStyle: { width: "fit-content" },
                          attrs: {
                            value: _vm.formatNumber(item.quantity, 0),
                            "no-value-text": "0"
                          }
                        })
                      ]
                    }
                  },
                  {
                    key: "item.estimatedErectMinutes",
                    fn: function({ item }) {
                      return [
                        _c("fd-value-display", {
                          staticStyle: { width: "fit-content" },
                          attrs: {
                            value: _vm.formatNumber(item.estimatedErectMinutes),
                            "no-value-text": "0.00"
                          }
                        })
                      ]
                    }
                  },
                  {
                    key: "item.estimatedDismantleMinutes",
                    fn: function({ item }) {
                      return [
                        _c("fd-value-display", {
                          staticStyle: { width: "fit-content" },
                          attrs: {
                            value: _vm.formatNumber(
                              item.estimatedDismantleMinutes
                            ),
                            "no-value-text": "0.00"
                          }
                        })
                      ]
                    }
                  },
                  {
                    key: "item.estimatedMobilizationMinutes",
                    fn: function({ item }) {
                      return [
                        _c("fd-value-display", {
                          staticStyle: { width: "fit-content" },
                          attrs: {
                            value: _vm.formatNumber(
                              item.estimatedHoardingMinutes
                            ),
                            "no-value-text": "0.00"
                          }
                        })
                      ]
                    }
                  },
                  {
                    key: "item.estimatedHoardingMinutes",
                    fn: function({ item }) {
                      return [
                        _c("fd-value-display", {
                          staticStyle: { width: "fit-content" },
                          attrs: {
                            value: _vm.formatNumber(
                              item.estimatedHoardingMinutes
                            ),
                            "no-value-text": "0.00"
                          }
                        })
                      ]
                    }
                  },
                  {
                    key: "item.estimatedModifyMinutes",
                    fn: function({ item }) {
                      return [
                        _c("fd-value-display", {
                          staticStyle: { width: "fit-content" },
                          attrs: {
                            value: _vm.formatNumber(
                              item.estimatedModifyMinutes
                            ),
                            "no-value-text": "0.00"
                          }
                        })
                      ]
                    }
                  },
                  {
                    key: "item.totalWeight",
                    fn: function({ item }) {
                      return [
                        _c("fd-value-display", {
                          staticStyle: { width: "fit-content" },
                          attrs: {
                            value: _vm.formatNumber(item.totalWeight, 1),
                            "no-value-text": "0.0"
                          }
                        })
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-card-actions",
        { staticClass: "mx-0 px-3 pb-3" },
        [
          _c(
            "v-btn",
            {
              attrs: {
                link: "",
                disabled: _vm.processing || !_vm.canClearParts,
                color: "error",
                outlined: "",
                small: _vm.$vuetify.breakpoint.name == "xs"
              },
              on: {
                click: function($event) {
                  return _vm.$emit("click:clear")
                }
              }
            },
            [_vm._v(_vm._s(_vm.$t("common.clear")) + "\n    ")]
          ),
          _vm._v(" "),
          _c("v-spacer"),
          _vm._v(" "),
          _c(
            "v-btn",
            {
              attrs: {
                disabled:
                  _vm.processing ||
                  !_vm.takeoffParts ||
                  !_vm.takeoffParts.length,
                color: "secondary",
                outlined: "",
                small: _vm.$vuetify.breakpoint.name == "xs"
              },
              on: {
                click: function($event) {
                  _vm.showBCount = true
                }
              }
            },
            [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "scheduler.estimates.takeoff-panel.view-bcount-button-label"
                  )
                ) + "\n    "
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }