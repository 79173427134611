import FDVue from "@fd/lib/vue";
import { mapMutations, mapActions } from "vuex";
import errorHandling from "@fd/lib/vue/mixins/errorHandling";
import {
  Tag,
  Scaffold,
  scaffoldService,
  ScaffoldWithDetails,
  CountSheetWithParts,
  countSheetService,
  Part,
  PartWithTotal,
  scaffoldInspectionService,
  ScaffoldInspectionWithDetails,
  ScaffoldStatuses
} from "../services";
import tabbedView, { PageTab, Tab } from "@fd/lib/vue/mixins/tabbedView";
import { FDColumnDirective, FDRowNavigateDirective } from "@fd/lib/vue/utility/dataTable";
import userAccess from "../dataMixins/userAccess";
import * as DateUtil from "@fd/lib/client-util/datetime";
import { WorkPackageWithNameCode } from "../dataMixins/scaffoldRequest";
import { showScaffoldVLFHistoryDialog } from "./components/dialogs/ScaffoldVLFHistoryDialog.vue";
import QRCode from "qrcode";
import printImage from "@fd/lib/client-util/printImage";
import { DateRangePreset } from "@fd/lib/vue/components/DateRangePicker.vue";
import { TranslateResult } from "vue-i18n";
import { BasicSelectItem } from "../../../lib/vue/utility/select";
import locationDataStore from "../store/referenceData/projectLocation";
import contractorDataStore from "../store/referenceData/contractor";
import personDataStore from "../store/referenceData/person";
import personBySecurityIDDataStore from "../store/referenceData/personBySecurityID";

type Keyword = Tag;
type ScaffoldWithExtraDetails = ScaffoldWithDetails & {
  daysStanding: number | undefined;
  archived: boolean;
  tagNumber: string;
};
type FormattedCountSheet = CountSheetWithParts & {
  scaffoldNumber: string | undefined;
  legacyWorkOrderID: number | string | undefined;
  requestTypeName: string | undefined;
  workOrderStatusName: string | undefined;
  formattedReviewStatusChangeTime: string | undefined;
  // Transfer IDs only exist for approved count sheets
  transferIDsList?: string | undefined;
};

export default FDVue.extend({
  name: "fd-scaffold-existing",

  mixins: [tabbedView, userAccess, errorHandling],

  directives: {
    fdColumn: FDColumnDirective,
    fdRowNavigate: FDRowNavigateDirective
  },

  components: {
    "fd-work-order-details": () => import("./components/WorkOrderDetailsForm.vue"),
    "fd-chip-selector": () => import("@fd/lib/vue/components/ChipItemSelector.vue"),
    "fd-async-search-box": () => import("@fd/lib/vue/components/AsyncSearchBox.vue")
  },

  data: function() {
    return {
      saving: false,

      workOrderTableSearch: "",
      inventoryTableSearch: "",
      transactionsTableSearch: "",
      // *** GLOBAL ***
      slidein: false,

      firstTabKey: `1`,
      detailsTab: new PageTab({
        nameKey: "scaffolds.existing-scaffold.tabs.details",
        key: `1`,
        visible: true
      }),
      workOrdersTab: new PageTab({
        nameKey: "scaffolds.existing-scaffold.tabs.work-orders",
        key: `2`,
        visible: false
      }),
      inventoryTab: new PageTab({
        nameKey: "scaffolds.existing-scaffold.tabs.inventory",
        key: `3`,
        visible: false
      }),
      transactionsTab: new PageTab({
        nameKey: "scaffolds.existing-scaffold.tabs.transactions",
        key: `4`,
        visible: false
      }),
      countSheetsTab: new PageTab({
        nameKey: "scaffolds.existing-scaffold.tabs.count-sheets",
        key: `5`,
        visible: false
      }),
      materialTab: new PageTab({
        nameKey: "scaffolds.existing-scaffold.tabs.material",
        key: `6`,
        visible: false
      }),
      inspectionsTab: new PageTab({
        nameKey: "scaffolds.existing-scaffold.tabs.inspections",
        key: `7`,
        visible: false
      }),
      qrCodeTab: new PageTab({
        nameKey: "scaffolds.existing-scaffold.tabs.qrcode",
        key: "8",
        visible: false
      }),

      /*** KEYWORDS ***/
      selectedKeywords: [] as Keyword[],

      /*** IWPs ***/
      availableIWPs: [] as WorkPackageWithNameCode[],
      selectedIWPs: [] as WorkPackageWithNameCode[],

      // *** COUNT SHEETS ***
      countsheettablesearch: "",
      countSheets: [] as FormattedCountSheet[],

      // *** MATERIAL ***
      standingPartCounts: [] as PartWithTotal[],
      partstablesearch: "",
      // Table Footer page size options
      partsItemsPerPage: 25,
      partsItemsPerPageOptions: [5, 10, 15, 25, 50, -1],

      // *** INSPECTIONS ***
      minDate: undefined as Date | undefined,
      maxDate: undefined as Date | undefined,
      inspections: [] as ScaffoldInspectionWithDetails[],
      inspectionstablesearch: "",
      inspectionsItemsPerPage: 25,
      inspectionsItemsPerPageOptions: [5, 10, 15, 25, 50, -1],

      // Main entity
      loadedScaffold: { currentUserPermissions: {} } as ScaffoldWithExtraDetails,
      scaffold: { currentUserPermissions: {} } as ScaffoldWithExtraDetails,
      expanderColSpan: 10
    };
  },

  computed: {
    // *** PERMISSIONS ***
    canEditAnything(): boolean {
      return this.scaffold.currentUserPermissions?.canEditAnything ?? false;
    },
    canEditActualErectDate(): boolean {
      return this.scaffold.currentUserPermissions?.canEditScaffoldErectDate ?? false;
    },
    canEditActualDismantleDate(): boolean {
      return this.scaffold.currentUserPermissions?.canEditScaffoldDismantleDate ?? false;
    },
    canEditScaffoldStatus(): boolean {
      return this.scaffold.currentUserPermissions?.canEditScaffoldStatus ?? false;
    },
    // *** GLOBAL ***
    tabDefinitions(): Tab[] {
      // Details is not included since it's the first tab and is always visible
      let tabs = [
        this.workOrdersTab,
        this.inventoryTab,
        this.transactionsTab,
        this.countSheetsTab,
        this.materialTab
      ] as Tab[];
      if (this.$store.state.curEnvironment.enableScaffoldInspection) {
        tabs.push(...[this.inspectionsTab, this.qrCodeTab]);
      }
      return tabs;
    },
    scaffoldStatusName() {
      if (!this.scaffold?.scaffoldStatus) return "";
      return this.$t(`scaffolds.status.${this.scaffold.scaffoldStatus}`);
    },
    areaName() {
      if (!this.scaffold?.areaID) return "";
      return locationDataStore.lookupCaption(this.scaffold.areaID);
    },
    subAreaName() {
      if (!this.scaffold?.subAreaID) return "";
      return locationDataStore.lookupCaption(this.scaffold.subAreaID);
    },
    contractorName() {
      var contractorID: string | null | undefined =
        this.scaffold?.lastWorkOrderContractorID ?? this.scaffold?.contractorID;
      if (!contractorID) return "";
      return contractorDataStore.lookupCaption(contractorID);
    },
    selectableStatuses(): BasicSelectItem[] {
      var possibleStatuses = [
        ScaffoldStatuses.Designed,
        ScaffoldStatuses.Estimated,
        ScaffoldStatuses.Erected,
        ScaffoldStatuses.Dismantled,
        ScaffoldStatuses.Cancelled
      ];
      var selectableStatuses = [
        ScaffoldStatuses.Erected,
        ScaffoldStatuses.Dismantled,
        ScaffoldStatuses.Cancelled
      ];
      return possibleStatuses.map(x => {
        return {
          value: x,
          text: this.$t(`scaffolds.status.${x}`),
          disabled: !selectableStatuses.includes(x)
        };
      });
    },

    // *** DETAILS ***
    availableKeywords(): Keyword[] {
      return this.$store.getters.sortedEnabledTags;
    },

    // *** WORK ORDERS ***
    scaffoldWorkOrders() {
      return this.scaffold.workOrders?.map(x => ({
        ...x,
        requestTypeName: this.$t(`scaffold-requests.types.${x.scaffoldRequestType}`),
        requestSubTypeName: this.$t(`scaffold-requests.sub-types.${x.scaffoldRequestSubType}`),
        workOrderStatusName: this.$t(`workorders.status.${x.workOrderStatus}`),
        assignedContractorName: contractorDataStore.lookupCaption(x.assignedContractorID ?? ""),
        requestingContractorName: contractorDataStore.lookupCaption(x.requestingContractorID ?? ""),
        requestSubmitterName: personBySecurityIDDataStore.lookupCaption(
          x.requestSubmitterCredentialID ?? ""
        ),
        lastStatusChangedBy: personBySecurityIDDataStore.lookupCaption(
          x.lastStatusChangedByCredentialID ?? ""
        ),
        requestingEmployeeName: personDataStore.lookupCaption(x.requestingEmployeeID ?? ""),
        foremanName: personDataStore.lookupCaption(x.foremanID ?? ""),
        generalForemanName: personDataStore.lookupCaption(x.generalForemanID ?? ""),
        coordinatorName: personDataStore.lookupCaption(x.coordinatorID ?? ""),
        areaName: locationDataStore.lookupCaption(x.areaID ?? ""),
        subAreaName: locationDataStore.lookupCaption(x.subAreaID ?? ""),
        workPackageNames: x.workPackages?.map(x => (x.name ?? "") + " | " + (x.activityID ?? "")),
        formattedRequestDate: DateUtil.stripTimeFromLocalizedDateTime(x.requestDate),
        formattedCompletedDate: DateUtil.stripTimeFromLocalizedDateTime(x.completedDate),

        walkdown: {
          ...x.walkdown,
          submitterName: personBySecurityIDDataStore.lookupCaption(x.walkdown?.submittedBy ?? "")
        }
      }));
    },

    // *** MATERIAL ***
    standingParts(): (Part & { total: number; totalWeight: string | undefined })[] {
      let allParts = this.$store.state.parts.fullList as Part[];
      return this.standingPartCounts.map(x => {
        let part = allParts.find(p => x.partID == p.id!)!;
        return {
          ...part,
          total: x.total,
          totalWeight: !part.weight || !x.total ? undefined : (x.total * part.weight).toFixed(1)
        };
      });
    },
    scaffoldID(): string {
      return this.scaffold?.id ?? this.$route.params.id;
    },
    labelZPL(): string {
      return `^XA
^FO220,20
^BQN,,5,Q,7
^FD
HA,${this.scaffoldID}
^FS
^CFA,30
^FO250,240^FD
T-18078
^FS
^XZ`;
    },
    // Archived Props for Approved Count Sheets
    dateRangePresetOptions(): DateRangePreset[] {
      return [
        {
          fromDate: DateUtil.addDaysToDate(null, 0),
          toDate: DateUtil.addDaysToDate(null, 0),
          key: "today",
          labelKey: "fd-date-range-picker.preset-today-label"
        } as DateRangePreset,
        {
          fromDate: DateUtil.addDaysToDate(null, -6),
          toDate: DateUtil.addDaysToDate(null, 0),
          key: "previous-week",
          labelKey: "fd-date-range-picker.preset-previous-week-label"
        } as DateRangePreset,
        {
          fromDate: DateUtil.addDaysToDate(null, -13),
          toDate: DateUtil.addDaysToDate(null, 0),
          key: "previous-two-weeks",
          labelKey: "fd-date-range-picker.preset-previous-two-weeks-label"
        } as DateRangePreset,
        {
          fromDate: DateUtil.addMonthsToDate(null, -1),
          toDate: DateUtil.addDaysToDate(null, 0),
          key: "previous-month",
          labelKey: "fd-date-range-picker.preset-previous-month-label"
        } as DateRangePreset,
        {
          fromDate: DateUtil.addMonthsToDate(null, -2),
          toDate: DateUtil.addDaysToDate(null, 0),
          key: "previous-two-months",
          labelKey: "fd-date-range-picker.preset-previous-two-months-label"
        } as DateRangePreset
      ];
    },
    showArchivedMinDate(): Date | null {
      // If we have neither dates, or both dates, we're starting a new range so we don't need any restrictions
      if (
        (!this.showArchivedFromDate && !this.showArchivedToDate) ||
        (!!this.showArchivedFromDate && !!this.showArchivedToDate)
      )
        return null;

      var date = this.showArchivedFromDate ?? this.showArchivedToDate;
      let minDate = DateUtil.addMonthsToDate(date, -2);
      return minDate;
    },
    showArchivedMaxDate(): Date | null {
      // If we have neither dates, or both dates, we're starting a new range so we don't need any restrictions
      if (
        (!this.showArchivedFromDate && !this.showArchivedToDate) ||
        (!!this.showArchivedFromDate && !!this.showArchivedToDate)
      )
        return null;

      var date = this.showArchivedFromDate ?? this.showArchivedToDate;
      let maxDate = DateUtil.addMonthsToDate(date, 2);
      return maxDate;
    },
    showArchivedDateRange: {
      get(): Date[] {
        var dates = [];
        if (!!this.showArchivedFromDate) dates.push(this.showArchivedFromDate);
        if (!!this.showArchivedToDate) dates.push(this.showArchivedToDate);
        return dates;
      },
      async set(val: any[]) {
        if (val.length > 0) this.showArchivedFromDate = new Date(val[0]);
        else this.showArchivedFromDate = null;

        if (val.length > 1) {
          this.showArchivedToDate = new Date(val[1]);
          this.processing = true;
          try {
            await this.loadInspections();
          } catch (error) {
            this.handleError(error as Error);
          } finally {
            this.processing = false;
          }
        } else this.showArchivedToDate = null;
      }
    },
    showArchivedFromDate: {
      get(): Date | null {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.showArchivedForFilteringFromDate;
      },
      async set(val: Date | null) {
        this.$store.commit("SET_SHOW_ARCHIVED_FOR_FILTERING_FROM_DATE", val);
      }
    },
    showArchivedToDate: {
      get(): Date | null {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.showArchivedForFilteringToDate;
      },
      async set(val: Date | null) {
        this.$store.commit("SET_SHOW_ARCHIVED_FOR_FILTERING_TO_DATE", val);
      }
    }
  },

  methods: {
    tabSelectionCompleted(tab: Tab) {
      if (tab.key == this.qrCodeTab.key) {
        this.$nextTick(() => {
          this.generateQRCode();
        });
      }
    },
    generateQRCode() {
      console.log(`generateQRCode`);
      let canvas = this.$refs.qrcanvas as HTMLCanvasElement;
      console.log(`  Canvas: ${canvas}`);
      if (!!canvas) {
        QRCode.toCanvas(canvas, this.scaffoldID, { errorCorrectionLevel: "H" }, error => {
          if (!!error) console.log(`  QR Code Error: ${error}`);
          else console.log(`  QR Code Success`);
        });
      }
    },
    downloadQRCode() {
      console.log(`downloadQRCode`);
      QRCode.toDataURL(this.scaffoldID, { errorCorrectionLevel: "H" }, (error, url) => {
        if (!!error) throw error;

        console.log(`  QR Code Success`);

        var link = document.createElement("a");
        link.download = `QRCode_T${this.scaffold.tagNumber}.png`;
        link.href = url;
        link.click();
      });
    },
    printQRCode() {
      console.log(`printQRCode data: ${this.scaffoldID}`);
      QRCode.toDataURL(this.scaffoldID, { errorCorrectionLevel: "H" }, (error, url) => {
        if (!!error) throw error;

        console.log(`  QR Code Success`);

        printImage(url);
      });
    },
    printQRCodeLabel() {
      console.log(`printQRCodeLabel data: ${this.scaffoldID}`);
      QRCode.toDataURL(this.scaffoldID, { errorCorrectionLevel: "H" }, (error, src) => {
        if (!!error) throw error;

        console.log(`  QR Code Success`);

        var win = window.open("about:blank", "_new")!;
        win.document.open();
        // var body = this.labelZPL;
        var tagNumberString = !!this.scaffold?.tagNumber?.length
          ? `T-${this.scaffold.tagNumber}`
          : "";
        /**
         * Settings for Printer:
         * Layout: Portrait
         * Paper Size: 1.25 x 2.25 in
         * Scale: Actual Size
         * Quality: 300 dpi
         * Margins: None or Default
         */
        var body = `
<html>
<head>
<style>html { margin: 0; padding: 0; width: 182px; height: 1in; } @page { margin: 0; padding: 0; width: 182px; height: 1in; }</style>
</head>
<body style="display:flex;flex-wrap:wrap;flex-direction:column;align-items:center;" onload="window.print()" onafterprint="window.close()">
<img width="104px" height="104px" src="${src}" />
${tagNumberString}
</body>
</html>
`;
        win.document.write(body);
        win.document.close();
      });
    },
    formatDate(date: Date | string | undefined) {
      return DateUtil.localizedDateTimeString(date);
    },
    /*** GLOBAL ***/
    ...mapMutations({
      notifyNewBreadcrumb: "NOTIFY_NEW_BREADCRUMB",
      setFilteringContext: "SET_FILTERING_CONTEXT"
    }),

    ...mapActions({
      loadParts: "LOAD_PARTS"
    }),

    onSubmit(e: Event) {
      e.preventDefault();
      //this.save();
    },

    preventSubmit(e: Event) {
      e.preventDefault();
      return false;
    },

    parseScaffoldExtraDetails(scaffold: ScaffoldWithDetails): ScaffoldWithExtraDetails {
      return {
        ...scaffold,
        daysStanding: !!scaffold.actualErectDate
          ? Math.round(
              ((!!scaffold.actualDismantleDate
                ? scaffold.actualDismantleDate
                : new Date()
              ).getTime() -
                scaffold.actualErectDate.getTime()) /
                86400000
            )
          : undefined,
        archived: !!scaffold.archivedDate,
        tagNumber: `${scaffold.legacyID}`
      } as ScaffoldWithExtraDetails;
    },

    async loadScaffoldDetails() {
      var scaffold = await scaffoldService.getByID(this.$route.params.id);
      this.loadedScaffold = this.parseScaffoldExtraDetails(scaffold);
      this.scaffold = { ...this.loadedScaffold };
    },

    // Method used in conjunction with the Cancel button.
    cancel() {
      this.$router.push(this.$store.getters.backBreadcrumb?.to || "/scaffolds");
    },

    async save(closeOnComplete: boolean) {
      this.inlineMessage.message = null;

      this.processing = true;
      this.saving = true;
      try {
        var updatedScaffold = {} as ScaffoldWithDetails;

        if (this.loadedScaffold.actualErectDate != this.scaffold.actualErectDate)
          updatedScaffold.actualErectDate = this.scaffold.actualErectDate;

        if (this.loadedScaffold.actualDismantleDate != this.scaffold.actualDismantleDate)
          updatedScaffold.actualDismantleDate = this.scaffold.actualDismantleDate;

        if (this.loadedScaffold.scaffoldStatus != this.scaffold.scaffoldStatus)
          updatedScaffold.scaffoldStatus = this.scaffold.scaffoldStatus;

        var savedScaffold = await scaffoldService.updateItem(this.scaffold.id!, updatedScaffold);

        this.loadedScaffold = {
          ...this.loadedScaffold,
          ...savedScaffold,
          archived: !!savedScaffold.archivedDate
        };
        this.scaffold = {
          ...this.scaffold,
          ...savedScaffold,
          archived: !!savedScaffold.archivedDate
        };

        var scaffoldNumber = `T-${this.scaffold.legacyID}`;
        var snackbarPayload = {
          text: this.$t("scaffolds.snack-bar-updated-message", [scaffoldNumber]),
          type: "success",
          undoCallback: null
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);

        if (closeOnComplete) {
          this.$router.push("/scaffolds");
        }
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.saving = false;
      }
    },

    workOrderCustomSort(items: any[], sortBy: string[], sortDesc: boolean[], locale: string) {
      let desc = sortDesc[0] == true;
      let propName = sortBy[0];
      // Examine the property trying to be sorted, and switch it to the ACTUAL property name of the object
      // This prop name is the name of the item in the table, and therefore sometimes won't match the object property.
      if (propName === "formattedCompletedDate") {
        propName = "completedDate";
      } else if (propName === "formattedRequestDate") {
        propName = "requestDate";
      }

      items.sort((a: any, b: any) => {
        let val1 = (a as any)[propName];
        let val2 = (b as any)[propName];
        if (val1 < val2) {
          return desc ? 1 : -1;
        } else if (val1 > val2) {
          return desc == true ? -1 : 1;
        } else {
          return 0;
        }
      });
      return items;
    },

    async vlfHistoryClicked() {
      showScaffoldVLFHistoryDialog(this.scaffold.vlfHistory);
    },

    // *** COUNT SHEETS ***
    async loadCountSheets() {
      let countSheets = await countSheetService.getByScaffoldID(this.$route.params.id);
      let formattedCountSheets = countSheets.map(
        x =>
          ({
            ...x,
            scaffoldNumber: x.workOrder?.scaffoldNumber,
            legacyWorkOrderID: x.workOrder?.legacyID,
            requestTypeName: x.workOrder?.requestTypeName,
            workOrderStatusName: x.workOrder?.workOrderStatusName,
            formattedReviewStatusChangeTime: this.formatDate(x.reviewStatusChangeTime),
            transferIDsList: !!x.relatedLegacyTransferIDs
              ? x.relatedLegacyTransferIDs.join(", ")
              : undefined
          } as FormattedCountSheet)
      );
      this.countSheets = formattedCountSheets;
    },

    // *** MATERIAL
    async loadStandingPartCounts() {
      this.standingPartCounts = await scaffoldService.getStandingPartCountsForScaffoldID(
        this.$route.params.id,
        null,
        null
      );
    },

    // *** INSPECTIONS ***

    async loadInspections() {
      this.inspections = await scaffoldInspectionService.getByScaffoldID(
        this.$route.params.id,
        this.showArchivedFromDate!,
        this.showArchivedToDate!
      );
    },
    fromDateChanged(val: Date) {
      this.maxDate = DateUtil.addMonthsToDate(val, 2);
      let now = new Date();
      if (this.maxDate.getTime() > now.getTime()) this.maxDate = now;
      this.showArchivedMinDate;
    },
    toDateChanged(val: Date) {
      this.minDate = DateUtil.addMonthsToDate(val, -2);
    }
  },

  watch: {
    scaffold(newValue: ScaffoldWithDetails) {
      // Since we might be coming to this screen from anywhere in the system (via the "Profile" menu access from the Avatar button),
      // We may need to reset the breadcrumbs since they could be pointing "Back" to the wrong screen.
      if ((this.$store.state.lastBreadcrumbs[0]?.to || "") != "/scaffolds") {
        this.notifyNewBreadcrumb({
          text: this.$t("scaffolds.list-title"),
          to: "/scaffolds",
          resetHistory: true
        });
        // This is needed in order to salvage the "last breadcrumbs" in the store.
        this.$store.commit("NOTIFY_NAVIGATION_STARTED");
      }

      var breadcrumbText = `T-${newValue.legacyID}`;
      this.notifyNewBreadcrumb({
        text: breadcrumbText,
        to: `/scaffolds/${newValue.id}`
      });
    }
  },

  created: async function() {
    if ((this.$store.state.lastBreadcrumbs[0]?.to || "") != "/scaffolds") {
      this.notifyNewBreadcrumb({
        text: this.$t("scaffolds.list-title"),
        to: "/scaffolds",
        resetHistory: true
      });
      // This is needed in order to salvage the "last breadcrumbs" in the store.
      this.$store.commit("NOTIFY_NAVIGATION_STARTED");
      this.notifyNewBreadcrumb({
        text: "",
        to: `/countsheets/${this.$route.params.id}`
      });
    }

    // Add a small delay of time before the view comes in so that the "slide in" animation will be seen by the user.
    setInterval(() => {
      this.slidein = true;
    }, 100);

    // Set the context for the User Filtering in the store so that if the user navigates to a screen that is
    // a sub screen of something that is currently filtered by their choices that those choices will be
    // preserved as they move between the two screens.
    var toDate = DateUtil.addDaysToDate(null, 0);
    this.setFilteringContext({
      context: "scaffolds-existing",
      parentalContext: "scaffolds",
      searchStringForFiltering: "",
      showArchivedForFilteringFromDate: DateUtil.addMonthsToDate(toDate, -2),
      showArchivedForFilteringToDate: toDate,
      selectedTab: this.firstTabKey
    });

    this.processing = true;

    try {
      await Promise.all([this.loadParts()]);

      // processing has been set to false after the reference data loaded.
      this.processing = true;
      await this.loadScaffoldDetails();
      // Processing has automatically been set to false after this load happens
      this.processing = true;

      // The control displaying Work Packages NEEDS data to exist in its source in order to be displayed in the control
      // Therefore, we start by loading all referenced IWPs from the scaffold into the source data
      if (this.scaffold.workPackages?.length) {
        this.availableIWPs = this.scaffold.workPackages.map(
          x =>
            ({
              ...x,
              nameCode: `${x.name} | ${x.activityID}`
            } as WorkPackageWithNameCode)
        );
        this.selectedIWPs = this.availableIWPs;
      }

      this.processing = true;
      await Promise.all([
        this.loadInspections(),
        this.loadCountSheets(),
        this.loadStandingPartCounts()
      ]);
    } catch (error) {
      this.handleError(error as Error);
    } finally {
      this.processing = false;
    }
  }
});

