var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    {
      staticClass: "fd-scheduler-screen",
      attrs: { fluid: "", "data-cy": "workorderhistory" }
    },
    [
      _vm.inlineMessage.message
        ? _c(
            "v-alert",
            {
              staticClass: "mt-0 mb-3",
              attrs: { type: _vm.inlineMessage.type }
            },
            [_vm._v("\n    " + _vm._s(_vm.inlineMessage.message) + "\n  ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-card",
        { staticClass: "fd-view no-tag-searching" },
        [
          _c(
            "fd-toolbar",
            { attrs: { extended: "", loading: _vm.processing } },
            [
              _c(
                "v-row",
                { staticClass: "pb-5 pb-sm-0" },
                [
                  _c("v-col", { attrs: { cols: "12", sm: "4" } }, [
                    _c(
                      "div",
                      {
                        class: [
                          _vm.processing ? "breadcrumb-processing-opacity" : ""
                        ],
                        staticStyle: {
                          "font-size": "1.40rem !important",
                          position: "relative"
                        }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              position: "absolute",
                              top: "0px",
                              left: "0px"
                            }
                          },
                          [
                            _c(
                              "div",
                              { staticStyle: { position: "relative" } },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.$t("work-order-history.list-title")
                                    ) +
                                    "\n                "
                                ),
                                !!_vm.curUserAccess.contractorID
                                  ? _c("span", [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "hide-when-no-contractor-class"
                                        },
                                        [_vm._v("|")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "fd-view-header-contractor-qualifier-label"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "margin-left": "6px"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                      " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "contractors.contractor-select"
                                                    )
                                                  ) +
                                                  "\n                    "
                                              )
                                            ]
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "fd-view-header-contractor-qualifier-contractor-detail-positioning"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              class: [
                                                _vm.$vuetify.breakpoint.xs
                                                  ? "fd-view-header-contractor-qualifier-contractor-detail-xs-width-restrictor"
                                                  : "",
                                                "fd-view-header-contractor-qualifier-contractor-detail"
                                              ]
                                            },
                                            [
                                              _vm._v(
                                                "\n                      " +
                                                  _vm._s(
                                                    _vm.curUserContractorName
                                                  ) +
                                                  "\n                    "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ])
                                  : _vm._e()
                              ]
                            )
                          ]
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "0", sm: "8" } },
                    [
                      _c(
                        "v-row",
                        {
                          staticClass: "d-none d-sm-flex",
                          attrs: { justify: "end" }
                        },
                        [
                          _c("v-combobox", {
                            class: [
                              _vm.tagsSelectedForFiltering.length > 0
                                ? "fd-combobox-control-item-selected"
                                : "fd-combobox-control-item-not-selected",
                              "pr-3",
                              "fd-limit-combobox-size-to-content"
                            ],
                            attrs: {
                              items: _vm.tagsInUse,
                              label: _vm.$t("common.filter-by-tags"),
                              "item-text": "name",
                              "item-value": "id",
                              multiple: "",
                              "small-chips": "",
                              clearable: ""
                            },
                            model: {
                              value: _vm.tagsSelectedForFiltering,
                              callback: function($$v) {
                                _vm.tagsSelectedForFiltering = $$v
                              },
                              expression: "tagsSelectedForFiltering"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            {
              staticClass:
                "justify-end ml-0 mr-0 fd-inline-radio-button-qualifier-background"
            },
            [
              _c("v-switch", {
                staticClass: "pr-3",
                attrs: {
                  "data-cy": "mineswitch",
                  label: _vm.$t("scheduler.list-selection-type-mine")
                },
                model: {
                  value: _vm.requestorFilterIsMine,
                  callback: function($$v) {
                    _vm.requestorFilterIsMine = $$v
                  },
                  expression: "requestorFilterIsMine"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _vm.canFilterRequestType
            ? _c(
                "v-row",
                {
                  staticClass:
                    "ml-0 mr-0 fd-secondary-context-qualifier-background",
                  attrs: { align: "center", justify: "end" }
                },
                [
                  _vm.canFilterRequestType
                    ? _c("v-switch", {
                        staticClass: "pr-3",
                        attrs: {
                          "data-cy": "scaffoldSwitch",
                          label: _vm.$t("scaffold-requests.types.scaffold")
                        },
                        model: {
                          value: _vm.showScaffoldRequests,
                          callback: function($$v) {
                            _vm.showScaffoldRequests = $$v
                          },
                          expression: "showScaffoldRequests"
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.curUserAccess.canViewMaintenanceJobs
                    ? _c("v-switch", {
                        staticClass: "pr-3",
                        attrs: {
                          "data-cy": "maintenanceSwitch",
                          label: _vm.$t("scaffold-requests.types.maintenance")
                        },
                        model: {
                          value: _vm.showMaintenanceRequests,
                          callback: function($$v) {
                            _vm.showMaintenanceRequests = $$v
                          },
                          expression: "showMaintenanceRequests"
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.curUserAccess.canViewPaintJobs
                    ? _c("v-switch", {
                        staticClass: "pr-3",
                        attrs: {
                          "data-cy": "paintSwitch",
                          label: _vm.$t("scaffold-requests.types.paint")
                        },
                        model: {
                          value: _vm.showPaintRequests,
                          callback: function($$v) {
                            _vm.showPaintRequests = $$v
                          },
                          expression: "showPaintRequests"
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.curUserAccess.canViewInsulationJobs
                    ? _c("v-switch", {
                        staticClass: "pr-3",
                        attrs: {
                          "data-cy": "insulationSwitch",
                          label: _vm.$t("scaffold-requests.types.insulation")
                        },
                        model: {
                          value: _vm.showInsulationRequests,
                          callback: function($$v) {
                            _vm.showInsulationRequests = $$v
                          },
                          expression: "showInsulationRequests"
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "v-card-subtitle",
            { staticClass: "pb-sm-0" },
            [
              _c(
                "v-row",
                [
                  _vm.canViewContractorFilter || _vm.canViewDisciplinesFilter
                    ? _c(
                        "v-col",
                        {
                          staticClass: "pt-0 pb-0",
                          attrs: { cols: "12", sm: "6", md: "6" }
                        },
                        [
                          _vm.canViewContractorFilter
                            ? _c("v-select", {
                                staticClass: "pt-0 mt-0",
                                attrs: {
                                  label: _vm.$t(
                                    "scheduler.filter-by-contractor-label"
                                  ),
                                  disabled: _vm.processing,
                                  items: _vm.selectableContractors,
                                  "item-text": "name",
                                  "item-value": "id",
                                  multiple: "",
                                  "small-chips": "",
                                  clearable: "",
                                  dense: _vm.$vuetify.breakpoint.xsOnly
                                },
                                model: {
                                  value: _vm.selectedContractors,
                                  callback: function($$v) {
                                    _vm.selectedContractors = $$v
                                  },
                                  expression: "selectedContractors"
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.canViewContractorFilter || _vm.canViewDisciplinesFilter
                    ? _c(
                        "v-col",
                        {
                          staticClass: "pt-0 pb-0",
                          attrs: { cols: "12", sm: "6", md: "6" }
                        },
                        [
                          _vm.canViewDisciplinesFilter
                            ? _c("v-select", {
                                staticClass: "pt-0 mt-0",
                                attrs: {
                                  label: _vm.$t(
                                    "scheduler.filter-by-discipline-label"
                                  ),
                                  disabled: _vm.processing,
                                  items: _vm.selectableDisciplines,
                                  "item-text": "name",
                                  "item-value": "id",
                                  multiple: "",
                                  "small-chips": "",
                                  clearable: "",
                                  dense: _vm.$vuetify.breakpoint.xsOnly
                                },
                                model: {
                                  value: _vm.selectedDisciplines,
                                  callback: function($$v) {
                                    _vm.selectedDisciplines = $$v
                                  },
                                  expression: "selectedDisciplines"
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    {
                      staticClass: "pt-0 pb-0",
                      attrs: { cols: "12", sm: "6", md: "6" }
                    },
                    [
                      _c("v-select", {
                        staticClass: "pt-0 mt-0",
                        attrs: {
                          label: _vm.$t("scheduler.filter-by-area-label"),
                          disabled: _vm.processing,
                          items: _vm.selectableAreas,
                          "item-text": "name",
                          "item-value": "id",
                          multiple: "",
                          "small-chips": "",
                          clearable: "",
                          dense: _vm.$vuetify.breakpoint.xsOnly
                        },
                        model: {
                          value: _vm.selectedAreas,
                          callback: function($$v) {
                            _vm.selectedAreas = $$v
                          },
                          expression: "selectedAreas"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    {
                      staticClass: "pt-0 pb-0",
                      attrs: { cols: "12", sm: "6", md: "6" }
                    },
                    [
                      _c("v-select", {
                        staticClass: "pt-0 mt-0",
                        attrs: {
                          label: _vm.$t("scheduler.filter-by-sub-area-label"),
                          disabled:
                            !_vm.selectableSubAreas ||
                            _vm.selectableSubAreas.length == 0 ||
                            _vm.processing,
                          items: _vm.selectableSubAreas,
                          "item-text": "name",
                          "item-value": "id",
                          multiple: "",
                          "small-chips": "",
                          clearable: "",
                          dense: _vm.$vuetify.breakpoint.xsOnly
                        },
                        model: {
                          value: _vm.selectedSubAreas,
                          callback: function($$v) {
                            _vm.selectedSubAreas = $$v
                          },
                          expression: "selectedSubAreas"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    {
                      staticClass: "pt-0 pb-0",
                      attrs: { cols: "12", sm: "6", md: "6" }
                    },
                    [
                      _c("v-select", {
                        staticClass: "pt-0 mt-0",
                        attrs: {
                          label: _vm.$t(
                            "scheduler.filter-by-general-foreman-label"
                          ),
                          disabled: _vm.processing,
                          items: _vm.selectableGeneralForemen,
                          "item-text": "name",
                          "item-value": "id",
                          multiple: "",
                          "small-chips": "",
                          clearable: "",
                          dense: _vm.$vuetify.breakpoint.xsOnly
                        },
                        model: {
                          value: _vm.selectedGeneralForemanIDs,
                          callback: function($$v) {
                            _vm.selectedGeneralForemanIDs = $$v
                          },
                          expression: "selectedGeneralForemanIDs"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    {
                      staticClass: "pt-0 pb-0",
                      attrs: { cols: "12", sm: "6", md: "6" }
                    },
                    [
                      _c("v-select", {
                        staticClass: "pt-0 mt-0",
                        attrs: {
                          label: _vm.$t("scheduler.filter-by-foreman-label"),
                          disabled: _vm.processing,
                          items: _vm.selectableForemen,
                          "item-text": "name",
                          "item-value": "id",
                          multiple: "",
                          "small-chips": "",
                          clearable: "",
                          dense: _vm.$vuetify.breakpoint.xsOnly
                        },
                        model: {
                          value: _vm.selectedForemanIDs,
                          callback: function($$v) {
                            _vm.selectedForemanIDs = $$v
                          },
                          expression: "selectedForemanIDs"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    {
                      staticClass: "pt-0 pb-0 order-sm-1 order-2",
                      attrs: { cols: "12", sm: "6" }
                    },
                    [
                      _c(
                        "v-row",
                        {
                          staticClass: "pa-0 ma-0",
                          attrs: { align: "center", justify: "center" }
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "mr-5",
                              attrs: {
                                small: "",
                                rounded: "",
                                outlined: "",
                                disabled: _vm.processing,
                                color: "#7a7a7a"
                              },
                              on: { click: _vm.reloadTableData }
                            },
                            [
                              !_vm.$vuetify.breakpoint.sm
                                ? _c(
                                    "v-icon",
                                    {
                                      attrs: {
                                        small: "",
                                        left: _vm.$vuetify.breakpoint.smAndUp
                                      }
                                    },
                                    [_vm._v("mdi-reload")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.$vuetify.breakpoint.smAndUp
                                ? _c("span", [
                                    _vm._v(_vm._s(_vm.$t("common.reload")))
                                  ])
                                : _vm._e()
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-text-field", {
                            class: [
                              _vm.$vuetify.breakpoint.sm
                                ? "fd-restrict-clearable-position"
                                : "",
                              "pt-0",
                              "mt-0",
                              "fd-table-search-input"
                            ],
                            attrs: {
                              "data-cy": "tablesearch",
                              "append-icon": "search",
                              label: _vm.$t("common.search"),
                              "single-line": "",
                              "hide-details": "",
                              clearable: "",
                              disabled: _vm.processing
                            },
                            model: {
                              value: _vm.tablesearch,
                              callback: function($$v) {
                                _vm.tablesearch = $$v
                              },
                              expression: "tablesearch"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    {
                      staticClass: "pt-0 pb-0 order-sm-2 order-1",
                      attrs: { cols: "12", sm: "6", md: "6" }
                    },
                    [
                      _c("fd-date-range-picker", {
                        attrs: {
                          dense: "",
                          "text-field-class": "fd-filter-date-picker-margins",
                          min: _vm.minDate,
                          max: _vm.maxDate,
                          loading: _vm.processing,
                          disabled: _vm.processing,
                          "hide-details": "",
                          outlined: false,
                          presets: _vm.dateRangePresetOptions
                        },
                        on: {
                          "input:fromDate": _vm.fromDateChanged,
                          "input:toDate": _vm.toDateChanged
                        },
                        model: {
                          value: _vm.showArchivedDateRange,
                          callback: function($$v) {
                            _vm.showArchivedDateRange = $$v
                          },
                          expression: "showArchivedDateRange"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("v-data-table", {
            directives: [
              {
                name: "fd-column",
                rawName:
                  "v-fd-column:requestNumber.hide-when-header-text-empty",
                value: "",
                expression: "''",
                arg: "requestNumber",
                modifiers: { "hide-when-header-text-empty": true }
              },
              {
                name: "fd-column",
                rawName:
                  "v-fd-column:legacyID.hide-when-medium.fd-scaffold-number",
                value: _vm.$vuetify.breakpoint.lgAndUp
                  ? _vm.$t("scheduler.legacy-wo-id-column-header")
                  : _vm.$t("scheduler.legacy-wo-id-column-header-short"),
                expression:
                  "\n        $vuetify.breakpoint.lgAndUp\n          ? $t('scheduler.legacy-wo-id-column-header')\n          : $t('scheduler.legacy-wo-id-column-header-short')\n      ",
                arg: "legacyID",
                modifiers: {
                  "hide-when-medium": true,
                  "fd-scaffold-number": true
                }
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:scaffoldNumber",
                value: _vm.$vuetify.breakpoint.lgAndUp
                  ? _vm.$t("scheduler.scaffold-number")
                  : _vm.$t("scheduler.scaffold-number-short"),
                expression:
                  "\n        $vuetify.breakpoint.lgAndUp\n          ? $t('scheduler.scaffold-number')\n          : $t('scheduler.scaffold-number-short')\n      ",
                arg: "scaffoldNumber"
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:requestTypeName.hide-when-medium",
                value: _vm.$vuetify.breakpoint.lgAndUp
                  ? _vm.$t("scheduler.request-type-column-header")
                  : _vm.$t("scheduler.request-type-column-header-short"),
                expression:
                  "\n        $vuetify.breakpoint.lgAndUp\n          ? $t('scheduler.request-type-column-header')\n          : $t('scheduler.request-type-column-header-short')\n      ",
                arg: "requestTypeName",
                modifiers: { "hide-when-medium": true }
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:workOrderStatusName",
                value: _vm.$t("scheduler.status"),
                expression: "$t('scheduler.status')",
                arg: "workOrderStatusName"
              },
              {
                name: "fd-column",
                rawName:
                  "v-fd-column:formattedRequiredDate.hide-when-extra-small",
                value: _vm.$vuetify.breakpoint.lgAndUp
                  ? _vm.$t("scheduler.required-date-column-header")
                  : _vm.$t("scheduler.required-date-column-header-short"),
                expression:
                  "\n        $vuetify.breakpoint.lgAndUp\n          ? $t('scheduler.required-date-column-header')\n          : $t('scheduler.required-date-column-header-short')\n      ",
                arg: "formattedRequiredDate",
                modifiers: { "hide-when-extra-small": true }
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:coordinatorName.hide-when-small",
                value: _vm.$t("scheduler.coordinator"),
                expression: "$t('scheduler.coordinator')",
                arg: "coordinatorName",
                modifiers: { "hide-when-small": true }
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:generalForemanName.hide-when-extra-small",
                value: _vm.$vuetify.breakpoint.lgAndUp
                  ? _vm.$t("scheduler.general-foreman-short")
                  : _vm.$t("scheduler.general-foreman-short"),
                expression:
                  "\n        $vuetify.breakpoint.lgAndUp\n          ? $t('scheduler.general-foreman-short')\n          : $t('scheduler.general-foreman-short')\n      ",
                arg: "generalForemanName",
                modifiers: { "hide-when-extra-small": true }
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:foremanName.hide-when-extra-small",
                value: _vm.$t("scheduler.foreman-short"),
                expression: "$t('scheduler.foreman-short')",
                arg: "foremanName",
                modifiers: { "hide-when-extra-small": true }
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:assignedContractorName.hide-when-medium",
                value: _vm.$t("scheduler.assigned-contractor"),
                expression: "$t('scheduler.assigned-contractor')",
                arg: "assignedContractorName",
                modifiers: { "hide-when-medium": true }
              },
              {
                name: "fd-column",
                rawName:
                  "v-fd-column:action.no-sort.hide-when-extra-small.class_fd-action-cell",
                arg: "action",
                modifiers: {
                  "no-sort": true,
                  "hide-when-extra-small": true,
                  "class_fd-action-cell": true
                }
              },
              {
                name: "fd-row-navigate",
                rawName: "v-fd-row-navigate.no-sort.show-when-extra-small",
                value: item => _vm.$router.push(`/workorderhistory/${item.id}`),
                expression:
                  "\n        item => $router.push(`/workorderhistory/${item.id}`)\n      ",
                modifiers: { "no-sort": true, "show-when-extra-small": true }
              }
            ],
            ref: "datatable",
            class: ["fd-expanding-table", "fd-scheduler-list"],
            attrs: {
              "data-cy": "list",
              "custom-sort": _vm.customSort,
              "items-per-page": _vm.itemsPerPage,
              "footer-props": {
                "items-per-page-options": _vm.itemsPerPageOptions
              },
              items: _vm.filteredWorkOrders,
              search: _vm.tablesearch,
              loading: _vm.processing,
              "loading-text": _vm.$t("common.table-loading-message"),
              "sort-by": ["legacyID"],
              "sort-desc": true,
              "mobile-breakpoint": "0",
              "show-expand": ""
            },
            scopedSlots: _vm._u(
              [
                _vm.$vuetify.breakpoint.mdAndUp
                  ? {
                      key: "item.data-table-expand",
                      fn: function({ isExpanded, expand, item }) {
                        return [
                          item.isClientWorkOrder ||
                          item.isChangeOrder ||
                          item.isRework
                            ? _c(
                                "v-row",
                                {
                                  staticClass:
                                    "fd-inline-table-request-type-badge-container",
                                  attrs: { justify: "center", "no-gutters": "" }
                                },
                                [
                                  item.isClientWorkOrder
                                    ? _c(
                                        "v-col",
                                        { attrs: { cols: "4" } },
                                        [
                                          item.isClientWorkOrder
                                            ? _c("v-badge", {
                                                staticClass:
                                                  "fd-inline-table-request-type-badge",
                                                attrs: { color: "#bd8202" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "badge",
                                                      fn: function() {
                                                        return [
                                                          _c("v-icon", {
                                                            attrs: {
                                                              "x-small": ""
                                                            },
                                                            domProps: {
                                                              textContent: _vm._s(
                                                                "fas fa-star-of-life"
                                                              )
                                                            }
                                                          })
                                                        ]
                                                      },
                                                      proxy: true
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  item.isChangeOrder
                                    ? _c(
                                        "v-col",
                                        { attrs: { cols: "4" } },
                                        [
                                          item.isChangeOrder
                                            ? _c("v-badge", {
                                                staticClass:
                                                  "fd-inline-table-request-type-badge",
                                                attrs: { color: "#6f2da8" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "badge",
                                                      fn: function() {
                                                        return [
                                                          _c("v-icon", {
                                                            attrs: {
                                                              "x-small": ""
                                                            },
                                                            domProps: {
                                                              textContent: _vm._s(
                                                                "fas fa-hammer-crash"
                                                              )
                                                            }
                                                          })
                                                        ]
                                                      },
                                                      proxy: true
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  item.isRework
                                    ? _c(
                                        "v-col",
                                        { attrs: { cols: "4" } },
                                        [
                                          item.isRework
                                            ? _c("v-badge", {
                                                staticClass:
                                                  "fd-inline-table-request-type-badge",
                                                attrs: { color: "#267D98" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "badge",
                                                      fn: function() {
                                                        return [
                                                          _c("v-icon", {
                                                            attrs: {
                                                              "x-small": ""
                                                            },
                                                            domProps: {
                                                              textContent: _vm._s(
                                                                "fas fa-repeat"
                                                              )
                                                            }
                                                          })
                                                        ]
                                                      },
                                                      proxy: true
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            { attrs: { justify: "center", "no-gutters": "" } },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "v-data-table__expand-icon",
                                  class: {
                                    "v-data-table__expand-icon--active": isExpanded
                                  },
                                  on: {
                                    click: function($event) {
                                      return expand(!isExpanded)
                                    }
                                  }
                                },
                                [_vm._v("mdi-chevron-down")]
                              )
                            ],
                            1
                          )
                        ]
                      }
                    }
                  : null,
                {
                  key: "item.scaffoldNumber",
                  fn: function({ item }) {
                    return [
                      _c("fd-scaffold-number-with-badges", {
                        attrs: { value: item.scaffoldNumber }
                      })
                    ]
                  }
                },
                {
                  key: "item.workOrderStatusName",
                  fn: function({ item }) {
                    return [
                      _c(
                        "v-row",
                        {
                          staticClass: "mx-0 fd-status-column",
                          staticStyle: { position: "relative" }
                        },
                        [
                          _vm.workOrderIsOnHold(item)
                            ? _c(
                                "v-icon",
                                {
                                  staticClass:
                                    "fd-table-cell-icon pr-0 pb-1 fd-status-icon-placement-readonly"
                                },
                                [
                                  _vm._v(
                                    "\n            fas fa-exclamation-circle\n          "
                                  )
                                ]
                              )
                            : _vm.workOrderIsCancelled(item)
                            ? _c(
                                "v-icon",
                                {
                                  staticClass:
                                    "fd-table-cell-error-icon pr-0 pb-1 fd-status-icon-placement-readonly"
                                },
                                [
                                  _vm._v(
                                    "\n            fas fa-times-octagon\n          "
                                  )
                                ]
                              )
                            : _vm.workOrderIsInScheduling(item)
                            ? _c(
                                "v-icon",
                                {
                                  staticClass:
                                    "fd-table-cell-inscheduling-icon pr-0 pb-1 fd-status-icon-placement-readonly"
                                },
                                [
                                  _vm._v(
                                    "\n            fas fa-circle\n          "
                                  )
                                ]
                              )
                            : _vm.workOrderIsStarted(item)
                            ? _c(
                                "v-icon",
                                {
                                  staticClass:
                                    "fd-table-cell-started-icon pr-0 pb-1 fd-status-icon-placement-readonly"
                                },
                                [
                                  _vm._v(
                                    "\n            fas fa-circle\n          "
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(
                            "\n          " +
                              _vm._s(item.workOrderStatusName) +
                              "\n        "
                          )
                        ],
                        1
                      )
                    ]
                  }
                },
                {
                  key: "expanded-item",
                  fn: function({ item }) {
                    return [
                      _c(
                        "td",
                        { attrs: { colspan: _vm.expanderColSpan } },
                        [
                          _c("fd-work-order-details", {
                            staticClass:
                              "fd-expanding-table-single-details-section",
                            attrs: {
                              workOrder: item,
                              walkdown: item.walkdown,
                              "show-edit-controls": true,
                              "edit-controls-disabled": true
                            }
                          })
                        ],
                        1
                      )
                    ]
                  }
                },
                {
                  key: "item.action",
                  fn: function({ item }) {
                    return [
                      _c(
                        "div",
                        { staticClass: "table-1-action-column-min-width" },
                        [
                          _c(
                            "v-tooltip",
                            {
                              attrs: { top: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function({ on }) {
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            {
                                              attrs: {
                                                icon: "",
                                                link: "",
                                                disabled: _vm.processing,
                                                to: `/workorderhistory/${item.id}`
                                              }
                                            },
                                            on
                                          ),
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { small: "" } },
                                              [
                                                _vm._v(
                                                  "\n                  mdi-eye\n                "
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            },
                            [
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("common.view")))
                              ])
                            ]
                          )
                        ],
                        1
                      )
                    ]
                  }
                }
              ],
              null,
              true
            )
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }