var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      attrs: { width: "960px", persistent: "" },
      model: {
        value: _vm.twoWayProps.show,
        callback: function($$v) {
          _vm.$set(_vm.twoWayProps, "show", $$v)
        },
        expression: "twoWayProps.show"
      }
    },
    [
      _c("fd-image-viewer", {
        attrs: { name: _vm.imageName },
        model: {
          value: _vm.imageSource,
          callback: function($$v) {
            _vm.imageSource = $$v
          },
          expression: "imageSource"
        }
      }),
      _vm._v(" "),
      _c(
        "v-card",
        { attrs: { "data-cy": "personnew" } },
        [
          _c(
            "v-card-title",
            {
              class: [
                _vm.processing
                  ? "dialog-new-top-color-processing"
                  : "dialog-new-top-color"
              ]
            },
            [
              _c(
                "span",
                {
                  class: [_vm.processing ? "breadcrumb-processing-opacity" : ""]
                },
                [_vm._v(_vm._s(_vm.$t("users.new-dialog-title")))]
              ),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _vm.processing
                ? _c("v-progress-circular", {
                    attrs: {
                      indeterminate: true,
                      rotate: 0,
                      size: 32,
                      width: 4,
                      color: "white"
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-stepper",
            {
              attrs: { "non-linear": "" },
              model: {
                value: _vm.step,
                callback: function($$v) {
                  _vm.step = $$v
                },
                expression: "step"
              }
            },
            [
              _c(
                "v-stepper-header",
                { staticClass: "fd-responsive-stepper" },
                [
                  _c(
                    "v-stepper-step",
                    {
                      attrs: {
                        "data-cy": "details",
                        complete: _vm.step > _vm.detailsStep,
                        step: _vm.detailsStep,
                        editable: ""
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("users.new-user.steps.details")) +
                          "\n          "
                      ),
                      _vm.detailserror
                        ? _c("small", { staticClass: "mt-1" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "users.new-user-details-step-error-message"
                                )
                              )
                            )
                          ])
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  _c("v-divider"),
                  _vm._v(" "),
                  _vm.currentUserCanConfigureSettings
                    ? _c(
                        "v-stepper-step",
                        {
                          attrs: {
                            "data-cy": "contractorstep",
                            complete: _vm.step > _vm.contractorStep,
                            step: _vm.contractorStep,
                            editable: ""
                          }
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.$t(
                                  "users.new-user.steps.contractor-selection"
                                )
                              ) +
                              "\n          "
                          ),
                          _vm.contractorerror
                            ? _c("small", { staticClass: "mt-1" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "users.new-user-contractor-step-error-message"
                                    )
                                  )
                                )
                              ])
                            : _vm._e()
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.currentUserCanConfigureSettings
                    ? _c("v-divider")
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.currentUserCanConfigureSettings
                    ? _c(
                        "v-stepper-step",
                        {
                          attrs: {
                            complete: _vm.step > _vm.projectsStep,
                            step: _vm.projectsStep,
                            editable: ""
                          }
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.$t(
                                  "users.new-user.steps.projects-selection"
                                )
                              ) +
                              "\n          "
                          ),
                          _vm.projectserror
                            ? _c("small", { staticClass: "mt-1" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "users.new-user-projects-step-error-message"
                                    )
                                  )
                                )
                              ])
                            : _vm._e()
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.currentUserCanConfigureSettings
                    ? _c("v-divider")
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.currentUserCanConfigureSettings
                    ? _c(
                        "v-stepper-step",
                        {
                          attrs: {
                            "data-cy": "security",
                            complete: _vm.step > _vm.securityStep,
                            step: _vm.securityStep,
                            editable: ""
                          }
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("users.new-user.steps.security")) +
                              "\n          "
                          ),
                          _vm.permissionserror
                            ? _c("small", { staticClass: "mt-1" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "users.new-user-security-step-error-message"
                                    )
                                  )
                                )
                              ])
                            : _vm._e()
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.currentUserCanConfigureSettings
                    ? _c("v-divider")
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "v-stepper-step",
                    {
                      attrs: {
                        complete: _vm.step > _vm.filesStep,
                        step: _vm.filesStep,
                        editable: ""
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("users.new-user.steps.files")) +
                          "\n          "
                      ),
                      _vm.fileserror
                        ? _c("small", { staticClass: "mt-1" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "users.new-user-files-step-error-message"
                                )
                              )
                            )
                          ])
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  _c("v-progress-linear", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.processing || _vm.saving,
                        expression: "processing || saving"
                      }
                    ],
                    class: {
                      "stepper-progress-indicator-compensation-when-additional-step-details":
                        _vm.simulatederror
                    },
                    attrs: { indeterminate: _vm.processing || _vm.saving }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-stepper-items",
                [
                  _c(
                    "v-stepper-content",
                    {
                      staticClass: "pl-0 pr-0 pb-0",
                      staticStyle: { "padding-top": "2px" },
                      attrs: { step: "1" }
                    },
                    [
                      _c(
                        "v-form",
                        {
                          ref: "detailsform",
                          on: { submit: _vm.preventSubmit }
                        },
                        [
                          _c(
                            "v-card",
                            [
                              _c(
                                "v-row",
                                {
                                  staticClass:
                                    "mx-0 px-2 mb-3 fd-inline-request-tags-qualifier",
                                  attrs: { align: "center" }
                                },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "py-0",
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _c("fd-chip-selector", {
                                        attrs: {
                                          cy: "keywords",
                                          "available-items":
                                            _vm.userValues.tags,
                                          "selected-items": _vm.user.tags,
                                          "item-label": "name",
                                          disabled: _vm.processing,
                                          "control-label": _vm.$t(
                                            "common.add-tags"
                                          ),
                                          "chip-class":
                                            "keywords-selection-chip-colors",
                                          outlined: false
                                        },
                                        on: {
                                          "update:selectedItems": function(
                                            $event
                                          ) {
                                            return _vm.$set(
                                              _vm.user,
                                              "tags",
                                              $event
                                            )
                                          },
                                          "update:selected-items": function(
                                            $event
                                          ) {
                                            return _vm.$set(
                                              _vm.user,
                                              "tags",
                                              $event
                                            )
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-row",
                                {
                                  staticClass: "mx-2",
                                  attrs: { align: "center" }
                                },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "avatar-cropper-container",
                                      attrs: {
                                        align: "center",
                                        cols: "12",
                                        sm: "12",
                                        md: "6"
                                      }
                                    },
                                    [
                                      _c(
                                        "slim-cropper",
                                        {
                                          staticClass: "profile-image-new",
                                          attrs: {
                                            image: _vm.userPhoto,
                                            label: _vm.$t(
                                              "common.drop-avatar-image-here"
                                            )
                                          },
                                          on: {
                                            "update:image": function($event) {
                                              _vm.userPhoto = $event
                                            }
                                          }
                                        },
                                        [
                                          _c("input", {
                                            attrs: {
                                              type: "file",
                                              "data-cy": "userphoto",
                                              name: "slim"
                                            }
                                          })
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "justify-space-between",
                                      attrs: {
                                        align: "center",
                                        cols: "12",
                                        sm: "12",
                                        md: "6"
                                      }
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          staticClass: "mr-0 ml-0",
                                          attrs: { align: "center" }
                                        },
                                        [
                                          _c("fd-text-field", {
                                            attrs: {
                                              cy: "firstname",
                                              label: _vm.$t("common.firstname"),
                                              disabled: _vm.processing,
                                              rules: _vm.userRules.firstName
                                            },
                                            model: {
                                              value: _vm.user.firstName,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.user,
                                                  "firstName",
                                                  $$v
                                                )
                                              },
                                              expression: "user.firstName"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-row",
                                        {
                                          staticClass: "mr-0 ml-0",
                                          attrs: { align: "center" }
                                        },
                                        [
                                          _c("fd-text-field", {
                                            attrs: {
                                              cy: "lastname",
                                              label: _vm.$t("common.lastname"),
                                              disabled: _vm.processing,
                                              rules: _vm.userRules.lastName
                                            },
                                            model: {
                                              value: _vm.user.lastName,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.user,
                                                  "lastName",
                                                  $$v
                                                )
                                              },
                                              expression: "user.lastName"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-row",
                                        {
                                          staticClass: "mr-0 ml-0",
                                          attrs: { align: "center" }
                                        },
                                        [
                                          _c("fd-text-field", {
                                            attrs: {
                                              cy: "employeecode",
                                              label: _vm.$t(
                                                "users.employee-code"
                                              ),
                                              disabled: _vm.processing,
                                              rules: _vm.userRules.employeeCode
                                            },
                                            model: {
                                              value: _vm.user.employeeCode,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.user,
                                                  "employeeCode",
                                                  $$v
                                                )
                                              },
                                              expression: "user.employeeCode"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      !_vm.currentUserCanConfigureSettings
                                        ? _c(
                                            "v-row",
                                            {
                                              staticClass: "mr-0 ml-0",
                                              attrs: { align: "center" }
                                            },
                                            [
                                              _c("v-select", {
                                                attrs: {
                                                  outlined: "",
                                                  disabled: _vm.processing,
                                                  rules:
                                                    _vm.userRules
                                                      .classificationID,
                                                  label: _vm.$t(
                                                    "people.existing-person.contractors.classification-select"
                                                  ),
                                                  items:
                                                    _vm.classificationsForPerson,
                                                  "item-text": "name",
                                                  "item-value": "id",
                                                  hint: _vm.$t(
                                                    "people.existing-person.contractors.classification-select-hint"
                                                  ),
                                                  "persistent-hint": "",
                                                  dense:
                                                    _vm.$vuetify.breakpoint
                                                      .xsOnly
                                                },
                                                model: {
                                                  value:
                                                    _vm.user.classificationID,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.user,
                                                      "classificationID",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "user.classificationID"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _vm.currentUserCanConfigureSettings
                                    ? _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "align-center justify-space-between",
                                          attrs: {
                                            cols: "12",
                                            sm: "12",
                                            md: "6"
                                          }
                                        },
                                        [
                                          _c(
                                            "v-row",
                                            {
                                              staticClass: "mr-0 ml-0",
                                              attrs: { align: "center" }
                                            },
                                            [
                                              _c("fd-text-field", {
                                                attrs: {
                                                  cy: "email",
                                                  label: _vm.$t("common.email"),
                                                  disabled: _vm.processing,
                                                  rules:
                                                    _vm.userRules.emailAddress
                                                },
                                                model: {
                                                  value: _vm.userEmailAddress,
                                                  callback: function($$v) {
                                                    _vm.userEmailAddress = $$v
                                                  },
                                                  expression: "userEmailAddress"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.currentUserCanConfigureSettings
                                    ? _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "align-center justify-space-between",
                                          attrs: {
                                            cols: "12",
                                            sm: "12",
                                            md: "6"
                                          }
                                        },
                                        [
                                          _c(
                                            "v-row",
                                            {
                                              staticClass: "mr-0 ml-0",
                                              attrs: { align: "center" }
                                            },
                                            [
                                              _c("fd-text-field", {
                                                attrs: {
                                                  cy: "phone",
                                                  label: _vm.$t("common.phone"),
                                                  disabled: _vm.processing,
                                                  rules:
                                                    _vm.userRules.phoneNumber,
                                                  "persistent-hint": "",
                                                  hint: _vm.$t(
                                                    "users.phone-number-formatting"
                                                  )
                                                },
                                                model: {
                                                  value: _vm.userPhoneNumber,
                                                  callback: function($$v) {
                                                    _vm.userPhoneNumber = $$v
                                                  },
                                                  expression: "userPhoneNumber"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.currentUserCanConfigureSettings
                                    ? _c(
                                        "v-col",
                                        {
                                          attrs: {
                                            cols: "12",
                                            sm: "12",
                                            md: "6"
                                          }
                                        },
                                        [
                                          _c("v-select", {
                                            attrs: {
                                              outlined: "",
                                              "data-cy": "language",
                                              "item-text": "name",
                                              "item-value": "id",
                                              items: _vm.userValues.languageID,
                                              label: _vm.$t(
                                                "languages.preferred-language-select"
                                              ),
                                              disabled: _vm.processing,
                                              clearable: "",
                                              "persistent-hint": "",
                                              hint: _vm.$t(
                                                "languages.preferred-language-inline-info"
                                              )
                                            },
                                            model: {
                                              value: _vm.user.languageID,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.user,
                                                  "languageID",
                                                  $$v
                                                )
                                              },
                                              expression: "user.languageID"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.currentUserCanConfigureSettings
                                    ? _c("v-col", {
                                        staticClass: "align-start",
                                        attrs: { cols: "12", sm: "12", md: "6" }
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  !_vm.currentUserCanConfigureSettings && false
                                    ? _c(
                                        "v-col",
                                        {
                                          staticClass: "align-start",
                                          attrs: {
                                            cols: "12",
                                            sm: "12",
                                            md: "6"
                                          }
                                        },
                                        [
                                          _c("v-select", {
                                            attrs: {
                                              outlined: "",
                                              disabled: _vm.processing,
                                              rules:
                                                _vm.userRules.classificationID,
                                              label: _vm.$t(
                                                "people.existing-person.contractors.classification-select"
                                              ),
                                              items:
                                                _vm.classificationsForPerson,
                                              "item-text": "name",
                                              "item-value": "id",
                                              hint: _vm.$t(
                                                "people.existing-person.contractors.classification-select-hint"
                                              ),
                                              "persistent-hint": "",
                                              dense:
                                                _vm.$vuetify.breakpoint.xsOnly
                                            },
                                            model: {
                                              value: _vm.user.classificationID,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.user,
                                                  "classificationID",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "user.classificationID"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  !_vm.currentUserCanConfigureSettings && false
                                    ? _c("v-col", {
                                        staticClass: "align-start",
                                        attrs: { cols: "12", sm: "12", md: "6" }
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.currentUserCanConfigureSettings
                                    ? _c(
                                        "v-col",
                                        {
                                          staticClass: "align-start",
                                          attrs: {
                                            cols: "12",
                                            sm: "12",
                                            md: "12"
                                          }
                                        },
                                        [
                                          _c("v-switch", {
                                            staticClass: "pt-0",
                                            attrs: {
                                              label: _vm.$t(
                                                "people.existing-person.can-request-scaffolds"
                                              ),
                                              disabled: _vm.processing,
                                              "persistent-hint": "",
                                              hint: _vm.$t(
                                                "people.existing-person.can-request-scaffolds-hint"
                                              )
                                            },
                                            model: {
                                              value:
                                                _vm.user.canRequestScaffolds,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.user,
                                                  "canRequestScaffolds",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "user.canRequestScaffolds"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              ),
                              _vm._v(" "),
                              !_vm.currentUserCanConfigureSettings
                                ? _c(
                                    "v-row",
                                    { staticClass: "mx-2 ml-5 mt-0" },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "mt-0 mb-0 pb-0",
                                          attrs: { cols: "12" }
                                        },
                                        [
                                          _c(
                                            "v-subheader",
                                            { staticClass: "px-0" },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("common.role"))
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("v-divider")
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              !_vm.currentUserCanConfigureSettings
                                ? _c(
                                    "v-row",
                                    {
                                      staticClass:
                                        "mx-2 ml-5 fd-inline-radio-button-qualifier"
                                    },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          attrs: {
                                            cols: "12",
                                            sm: "6",
                                            md: "4",
                                            lg: "4"
                                          }
                                        },
                                        [
                                          _c("v-switch", {
                                            attrs: {
                                              label: _vm.$t(
                                                "people.security.is-foreman"
                                              ),
                                              disabled: _vm.processing,
                                              "persistent-hint": ""
                                            },
                                            model: {
                                              value: _vm.user.isForeman,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.user,
                                                  "isForeman",
                                                  $$v
                                                )
                                              },
                                              expression: "user.isForeman"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        {
                                          attrs: {
                                            cols: "12",
                                            sm: "6",
                                            md: "4",
                                            lg: "4"
                                          }
                                        },
                                        [
                                          _c("v-switch", {
                                            attrs: {
                                              label: _vm.$t(
                                                "people.security.is-general-foreman"
                                              ),
                                              disabled: _vm.processing,
                                              "persistent-hint": ""
                                            },
                                            model: {
                                              value: _vm.user.isGeneralForeman,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.user,
                                                  "isGeneralForeman",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "user.isGeneralForeman"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        {
                                          attrs: {
                                            cols: "12",
                                            sm: "6",
                                            md: "4",
                                            lg: "4"
                                          }
                                        },
                                        [
                                          _c("v-switch", {
                                            attrs: {
                                              label: _vm.$t(
                                                "people.security.is-coordinator"
                                              ),
                                              disabled: _vm.processing,
                                              "persistent-hint": ""
                                            },
                                            model: {
                                              value: _vm.user.isCoordinator,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.user,
                                                  "isCoordinator",
                                                  $$v
                                                )
                                              },
                                              expression: "user.isCoordinator"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.currentUserCanConfigureSettings
                    ? _c(
                        "v-stepper-content",
                        {
                          staticClass: "pt-1 pl-0 pr-0 pb-0",
                          attrs: { step: "2" }
                        },
                        [
                          _c(
                            "v-form",
                            {
                              ref: "contractorform",
                              on: { submit: _vm.preventSubmit }
                            },
                            [
                              _c(
                                "v-card",
                                [
                                  _c(
                                    "v-row",
                                    { staticClass: "mx-2" },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12", md: "6" } },
                                        [
                                          _c("v-select", {
                                            class: {
                                              "fd-readonly":
                                                _vm.contractorIDLocked
                                            },
                                            attrs: {
                                              "data-cy": "contractors",
                                              outlined: "",
                                              "item-text": "name",
                                              "item-value": "id",
                                              items:
                                                _vm.userValues.contractorID,
                                              label: _vm.$t(
                                                "contractors.contractor-select"
                                              ),
                                              disabled:
                                                _vm.processing ||
                                                _vm.contractorIDLocked,
                                              rules: _vm.userRules.contractorID,
                                              clearable: "",
                                              "persistent-hint": "",
                                              hint: _vm.$t(
                                                "users.user-contractor-selection-hint"
                                              )
                                            },
                                            model: {
                                              value: _vm.user.contractorID,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.user,
                                                  "contractorID",
                                                  $$v
                                                )
                                              },
                                              expression: "user.contractorID"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12", md: "6" } },
                                        [
                                          _c("v-select", {
                                            attrs: {
                                              outlined: "",
                                              disabled: _vm.processing,
                                              rules:
                                                _vm.userRules.classificationID,
                                              label: _vm.$t(
                                                "people.existing-person.contractors.classification-select"
                                              ),
                                              items:
                                                _vm.classificationsForPerson,
                                              "item-text": "name",
                                              "item-value": "id",
                                              hint: _vm.$t(
                                                "people.existing-person.contractors.classification-select-hint"
                                              ),
                                              "persistent-hint": "",
                                              dense:
                                                _vm.$vuetify.breakpoint.xsOnly
                                            },
                                            model: {
                                              value: _vm.user.classificationID,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.user,
                                                  "classificationID",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "user.classificationID"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.currentUserCanConfigureSettings
                    ? _c(
                        "v-stepper-content",
                        {
                          staticClass: "pl-0 pr-0 pb-0",
                          staticStyle: { "padding-top": "2px" },
                          attrs: { step: "3" }
                        },
                        [
                          _c(
                            "v-form",
                            {
                              ref: "projectsform",
                              on: { submit: _vm.preventSubmit }
                            },
                            [
                              _c(
                                "v-card",
                                { attrs: { flat: "", tile: "" } },
                                [
                                  _c(
                                    "v-row",
                                    {
                                      staticClass: "pl-9",
                                      staticStyle: {
                                        background: "#EAEAEA",
                                        "margin-top": "3px"
                                      }
                                    },
                                    [
                                      _c(
                                        "v-radio-group",
                                        {
                                          attrs: { row: "" },
                                          model: {
                                            value: _vm.projectUsageType,
                                            callback: function($$v) {
                                              _vm.projectUsageType = $$v
                                            },
                                            expression: "projectUsageType"
                                          }
                                        },
                                        [
                                          _c("v-radio", {
                                            attrs: {
                                              label: _vm.$t(
                                                "projects.projects-selection-type-all-projects"
                                              ),
                                              value: "entire"
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("v-radio", {
                                            staticClass: "ml-4",
                                            attrs: {
                                              label: _vm.$t(
                                                "projects.projects-selection-type-individual-projects"
                                              ),
                                              value: "selection"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-expand-transition",
                                    [
                                      _c(
                                        "v-card",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                _vm.projectUsageType ==
                                                "selection",
                                              expression:
                                                "projectUsageType == 'selection'"
                                            }
                                          ],
                                          staticClass: "new-project",
                                          attrs: { flat: "", tile: "" }
                                        },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pa-0",
                                              attrs: { cols: "12" }
                                            },
                                            [
                                              _c(
                                                "v-row",
                                                { staticClass: "ml-0 mr-0" },
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "12",
                                                        sm: "12"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-card-title",
                                                        {
                                                          staticClass:
                                                            "pl-3 pr-0 pt-0 pb-0"
                                                        },
                                                        [
                                                          _c("v-switch", {
                                                            attrs: {
                                                              label: _vm.$t(
                                                                "projects.only-included-projects-label"
                                                              )
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.showOnlyIncludedProjects,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.showOnlyIncludedProjects = $$v
                                                              },
                                                              expression:
                                                                "showOnlyIncludedProjects"
                                                            }
                                                          }),
                                                          _vm._v(" "),
                                                          _c(
                                                            "v-tooltip",
                                                            {
                                                              attrs: {
                                                                top: ""
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "activator",
                                                                    fn: function({
                                                                      on
                                                                    }) {
                                                                      return [
                                                                        _c(
                                                                          "v-icon",
                                                                          _vm._g(
                                                                            {
                                                                              staticClass:
                                                                                "ml-4",
                                                                              attrs: {
                                                                                color:
                                                                                  "black",
                                                                                dark:
                                                                                  ""
                                                                              }
                                                                            },
                                                                            on
                                                                          ),
                                                                          [
                                                                            _vm._v(
                                                                              "info"
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    }
                                                                  }
                                                                ],
                                                                null,
                                                                false,
                                                                583222350
                                                              )
                                                            },
                                                            [
                                                              _vm._v(" "),
                                                              _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "projects.projects-selection-sub-menu-information"
                                                                    )
                                                                  )
                                                                )
                                                              ])
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-row",
                                                { staticClass: "mr-0 ml-0" },
                                                [
                                                  _c(
                                                    "v-col",
                                                    { attrs: { cols: "12" } },
                                                    [
                                                      _c("v-text-field", {
                                                        attrs: {
                                                          "append-icon":
                                                            "search",
                                                          label: _vm.$t(
                                                            "common.search"
                                                          ),
                                                          "single-line": "",
                                                          "hide-details": "",
                                                          clearable: ""
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.tablesearchprojects,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.tablesearchprojects = $$v
                                                          },
                                                          expression:
                                                            "tablesearchprojects"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c("v-data-table", {
                                            directives: [
                                              {
                                                name: "fd-column",
                                                rawName: "v-fd-column:name",
                                                value: _vm.$t("common.name"),
                                                expression: "$t('common.name')",
                                                arg: "name"
                                              },
                                              {
                                                name: "fd-column",
                                                rawName:
                                                  "v-fd-column:description.hide-when-extra-small",
                                                value: _vm.$t(
                                                  "common.description"
                                                ),
                                                expression:
                                                  "$t('common.description')",
                                                arg: "description",
                                                modifiers: {
                                                  "hide-when-extra-small": true
                                                }
                                              },
                                              {
                                                name: "fd-column",
                                                rawName:
                                                  "v-fd-column:include.no-sort",
                                                value: _vm.$t("common.include"),
                                                expression:
                                                  "$t('common.include')",
                                                arg: "include",
                                                modifiers: { "no-sort": true }
                                              }
                                            ],
                                            ref: "projectsDataTable",
                                            staticClass:
                                              "fixed-header v-table__overflow",
                                            staticStyle: {
                                              "max-height":
                                                "calc(50vh - 100px)",
                                              "backface-visibility": "hidden"
                                            },
                                            attrs: {
                                              items: _vm.projects,
                                              search: _vm.tablesearchprojects,
                                              loading: _vm.processing,
                                              "loading-text": _vm.$t(
                                                "common.table-loading-message"
                                              ),
                                              "sort-by": ["name"],
                                              "mobile-breakpoint": "0"
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "header.include",
                                                  fn: function({ header }) {
                                                    return [
                                                      _c("v-checkbox", {
                                                        attrs: {
                                                          value:
                                                            _vm.allSearchedProjectsSelected,
                                                          indeterminate:
                                                            _vm.someSearchedProjectsSelected,
                                                          label: _vm.$t(
                                                            "common." +
                                                              header.value
                                                          )
                                                        },
                                                        on: {
                                                          change: function(
                                                            $event
                                                          ) {
                                                            return _vm.flipSearchedProjectselected()
                                                          }
                                                        }
                                                      })
                                                    ]
                                                  }
                                                },
                                                {
                                                  key: "item.include",
                                                  fn: function({ item }) {
                                                    return [
                                                      _c("v-simple-checkbox", {
                                                        attrs: {
                                                          value: item.selected,
                                                          disabled:
                                                            _vm.processing
                                                        },
                                                        on: {
                                                          input: function(
                                                            $event
                                                          ) {
                                                            return _vm.flipProjectSelected(
                                                              item
                                                            )
                                                          }
                                                        }
                                                      })
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              false,
                                              645394704
                                            )
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.currentUserCanConfigureSettings
                    ? _c(
                        "v-stepper-content",
                        {
                          staticClass: "pl-0 pr-0 pb-0",
                          staticStyle: { "padding-top": "2px" },
                          attrs: { step: "4" }
                        },
                        [
                          _c(
                            "v-form",
                            {
                              ref: "securityform",
                              on: { submit: _vm.preventSubmit }
                            },
                            [
                              _c(
                                "v-card",
                                { attrs: { flat: "", tile: "" } },
                                [
                                  _c(
                                    "v-row",
                                    {
                                      staticClass:
                                        "d-flex align-center justify-start fd-inline-radio-button-qualifier fd-inline-radio-button-qualifier-background ml-0 mr-0 pt-5 pb-5"
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass:
                                            "fd-security-tab-extra-info d-none d-sm-flex"
                                        },
                                        [_vm._v("fas fa-user-shield")]
                                      ),
                                      _vm._v(" "),
                                      _c("v-switch", {
                                        staticClass:
                                          "fd-security-tab-switch-placement",
                                        attrs: {
                                          label: _vm.$t(
                                            "users.can-sign-in-label"
                                          ),
                                          disabled: _vm.processing,
                                          "persistent-hint": "",
                                          hint: _vm.$t("users.can-sign-in-hint")
                                        },
                                        model: {
                                          value: _vm.user.isLoginActive,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.user,
                                              "isLoginActive",
                                              $$v
                                            )
                                          },
                                          expression: "user.isLoginActive"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-row",
                                    { staticClass: "mx-2 ml-5" },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          attrs: {
                                            cols: "12",
                                            sm: "6",
                                            md: "4"
                                          }
                                        },
                                        [
                                          _c("v-switch", {
                                            attrs: {
                                              label: _vm.$t(
                                                "people.security.force-readonly-access"
                                              ),
                                              disabled:
                                                !_vm.user.isLoginActive ||
                                                _vm.processing,
                                              "persistent-hint": "",
                                              hint: _vm.$t(
                                                "people.security.force-readonly-access-hint"
                                              )
                                            },
                                            model: {
                                              value:
                                                _vm.user.forceReadonlyAccess,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.user,
                                                  "forceReadonlyAccess",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "user.forceReadonlyAccess"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        {
                                          attrs: {
                                            cols: "12",
                                            sm: "6",
                                            md: "4"
                                          }
                                        },
                                        [
                                          _c("v-switch", {
                                            attrs: {
                                              label: _vm.$t(
                                                "users.user-security-import-work-orders"
                                              ),
                                              disabled:
                                                !_vm.user.isLoginActive ||
                                                _vm.user.forceReadonlyAccess ||
                                                _vm.processing,
                                              "persistent-hint": "",
                                              hint: _vm.$t(
                                                "users.user-security-import-work-orders-hint"
                                              )
                                            },
                                            model: {
                                              value:
                                                _vm.user.canImportWorkOrders,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.user,
                                                  "canImportWorkOrders",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "user.canImportWorkOrders"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        {
                                          attrs: {
                                            cols: "12",
                                            sm: "6",
                                            md: "4"
                                          }
                                        },
                                        [
                                          _c("v-switch", {
                                            attrs: {
                                              label: _vm.$t(
                                                "users.user-security-submit-scaffold-requests"
                                              ),
                                              disabled:
                                                !_vm.user.isLoginActive ||
                                                _vm.user.forceReadonlyAccess ||
                                                _vm.processing,
                                              "persistent-hint": "",
                                              hint: _vm.$t(
                                                "users.user-security-submit-scaffold-requests-hint"
                                              )
                                            },
                                            model: {
                                              value:
                                                _vm.user
                                                  .canSubmitScaffoldRequests,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.user,
                                                  "canSubmitScaffoldRequests",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "user.canSubmitScaffoldRequests"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "order-1 order-sm-2 order-md-3",
                                          attrs: {
                                            cols: "12",
                                            sm: "6",
                                            md: "4"
                                          }
                                        },
                                        [
                                          _c("v-switch", {
                                            attrs: {
                                              label: _vm.$t(
                                                "users.user-security-approve-scaffold-requests"
                                              ),
                                              disabled:
                                                !_vm.user.isLoginActive ||
                                                _vm.user.isPlanner ||
                                                _vm.user.forceReadonlyAccess ||
                                                _vm.processing,
                                              "persistent-hint": "",
                                              hint: _vm.$t(
                                                "users.user-security-approve-scaffold-requests-hint"
                                              )
                                            },
                                            model: {
                                              value:
                                                _vm.user
                                                  .canApproveScaffoldRequests,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.user,
                                                  "canApproveScaffoldRequests",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "user.canApproveScaffoldRequests"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "order-2 order-sm-1",
                                          attrs: {
                                            cols: "12",
                                            sm: "6",
                                            md: "4"
                                          }
                                        },
                                        [
                                          _c("v-switch", {
                                            attrs: {
                                              label: _vm.$t(
                                                "users.user-security-manage-to-do-lists"
                                              ),
                                              disabled:
                                                !_vm.user.isLoginActive ||
                                                _vm.user.isPlanner ||
                                                _vm.user.forceReadonlyAccess ||
                                                _vm.processing,
                                              "persistent-hint": "",
                                              hint: _vm.$t(
                                                "users.user-security-manage-to-do-lists-hint"
                                              )
                                            },
                                            model: {
                                              value:
                                                _vm.user
                                                  .canEditWorkOrderPlannedWorkDate,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.user,
                                                  "canEditWorkOrderPlannedWorkDate",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "user.canEditWorkOrderPlannedWorkDate"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "order-3 order-md-2",
                                          attrs: {
                                            cols: "12",
                                            sm: "6",
                                            md: "4"
                                          }
                                        },
                                        [
                                          _c("v-switch", {
                                            attrs: {
                                              label: _vm.$t(
                                                "users.user-security-enter-historical-data"
                                              ),
                                              disabled:
                                                !_vm.user.isPlanner ||
                                                !_vm.user.isLoginActive ||
                                                _vm.processing,
                                              "persistent-hint": "",
                                              hint: _vm.$t(
                                                "users.user-security-enter-historical-data-hint"
                                              )
                                            },
                                            model: {
                                              value:
                                                _vm.user.canEnterHistoricalData,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.user,
                                                  "canEnterHistoricalData",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "user.canEnterHistoricalData"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "order-4 order-md-4",
                                          attrs: {
                                            cols: "12",
                                            sm: "6",
                                            md: "4"
                                          }
                                        },
                                        [
                                          _c("v-switch", {
                                            attrs: {
                                              label: _vm.$t(
                                                "users.user-security-configure-settings"
                                              ),
                                              disabled:
                                                !_vm.user.isLoginActive ||
                                                _vm.processing,
                                              "persistent-hint": "",
                                              hint: _vm.$t(
                                                "users.user-security-configure-settings-hint"
                                              )
                                            },
                                            model: {
                                              value:
                                                _vm.user.canConfigureSettings,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.user,
                                                  "canConfigureSettings",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "user.canConfigureSettings"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-row",
                                    { staticClass: "mx-2 ml-5" },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "mt-4 mb-0 pb-0",
                                          attrs: { cols: "12" }
                                        },
                                        [
                                          _c(
                                            "v-subheader",
                                            { staticClass: "px-0" },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("common.role"))
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("v-divider")
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-row",
                                    {
                                      staticClass:
                                        "mx-2 ml-5 fd-inline-radio-button-qualifier"
                                    },
                                    [
                                      _vm.user.isLoginActive &&
                                      _vm.user.forceReadonlyAccess
                                        ? _c(
                                            "v-col",
                                            {
                                              staticClass: "my-0 pb-0",
                                              attrs: { cols: "12" }
                                            },
                                            [
                                              _c("fd-alert", {
                                                attrs: {
                                                  type: "warning",
                                                  label: _vm.$t(
                                                    "people.security.read-only-access-role-warning"
                                                  )
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        {
                                          attrs: {
                                            cols: "12",
                                            sm: "6",
                                            md: "3"
                                          }
                                        },
                                        [
                                          _c("v-switch", {
                                            attrs: {
                                              label: _vm.$t(
                                                "people.security.is-foreman"
                                              ),
                                              disabled: _vm.processing,
                                              "persistent-hint": "",
                                              hint: _vm.$t(
                                                "people.security.is-foreman-hint"
                                              )
                                            },
                                            model: {
                                              value: _vm.user.isForeman,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.user,
                                                  "isForeman",
                                                  $$v
                                                )
                                              },
                                              expression: "user.isForeman"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        {
                                          attrs: {
                                            cols: "12",
                                            sm: "6",
                                            md: "3"
                                          }
                                        },
                                        [
                                          _c("v-switch", {
                                            attrs: {
                                              label: _vm.$t(
                                                "people.security.is-general-foreman"
                                              ),
                                              disabled: _vm.processing,
                                              "persistent-hint": "",
                                              hint: _vm.$t(
                                                "people.security.is-general-foreman-hint"
                                              )
                                            },
                                            model: {
                                              value: _vm.user.isGeneralForeman,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.user,
                                                  "isGeneralForeman",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "user.isGeneralForeman"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        {
                                          attrs: {
                                            cols: "12",
                                            sm: "6",
                                            md: "3"
                                          }
                                        },
                                        [
                                          _c("v-switch", {
                                            attrs: {
                                              label: _vm.$t(
                                                "people.security.is-coordinator"
                                              ),
                                              disabled: _vm.processing,
                                              "persistent-hint": "",
                                              hint: _vm.$t(
                                                "people.security.is-coordinator-hint"
                                              )
                                            },
                                            model: {
                                              value: _vm.user.isCoordinator,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.user,
                                                  "isCoordinator",
                                                  $$v
                                                )
                                              },
                                              expression: "user.isCoordinator"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        {
                                          attrs: {
                                            cols: "12",
                                            sm: "6",
                                            md: "3"
                                          }
                                        },
                                        [
                                          _c("v-switch", {
                                            attrs: {
                                              label: _vm.$t(
                                                "people.security.is-planner"
                                              ),
                                              disabled:
                                                !_vm.user.isLoginActive ||
                                                _vm.processing,
                                              "persistent-hint": "",
                                              hint: _vm.$t(
                                                "people.security.is-planner-hint"
                                              )
                                            },
                                            model: {
                                              value: _vm.user.isPlanner,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.user,
                                                  "isPlanner",
                                                  $$v
                                                )
                                              },
                                              expression: "user.isPlanner"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        {
                                          attrs: {
                                            cols: "12",
                                            sm: "6",
                                            md: "3"
                                          }
                                        },
                                        [
                                          _c("v-switch", {
                                            attrs: {
                                              label: _vm.$t(
                                                "people.security.is-designer"
                                              ),
                                              disabled:
                                                !_vm.user.isLoginActive ||
                                                !_vm.$store.state.curEnvironment
                                                  .enableScaffoldDesign ||
                                                _vm.processing,
                                              "persistent-hint": "",
                                              hint: _vm.$t(
                                                "people.security.is-designer-hint"
                                              )
                                            },
                                            model: {
                                              value: _vm.user.isDesigner,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.user,
                                                  "isDesigner",
                                                  $$v
                                                )
                                              },
                                              expression: "user.isDesigner"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        {
                                          attrs: {
                                            cols: "12",
                                            sm: "6",
                                            md: "3"
                                          }
                                        },
                                        [
                                          _c("v-switch", {
                                            attrs: {
                                              label: _vm.$t(
                                                "people.security.is-design-manager"
                                              ),
                                              disabled:
                                                !_vm.user.isLoginActive ||
                                                !_vm.$store.state.curEnvironment
                                                  .enableScaffoldDesign ||
                                                _vm.processing,
                                              "persistent-hint": "",
                                              hint: _vm.$t(
                                                "people.security.is-design-manager-hint"
                                              )
                                            },
                                            model: {
                                              value: _vm.user.isDesignManager,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.user,
                                                  "isDesignManager",
                                                  $$v
                                                )
                                              },
                                              expression: "user.isDesignManager"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        {
                                          attrs: {
                                            cols: "12",
                                            sm: "6",
                                            md: "4",
                                            lg: "3"
                                          }
                                        },
                                        [
                                          _c("v-switch", {
                                            attrs: {
                                              label: _vm.$t(
                                                "people.security.is-inspector"
                                              ),
                                              disabled:
                                                !_vm.user.isLoginActive ||
                                                !_vm.$store.state.curEnvironment
                                                  .enableScaffoldInspection ||
                                                _vm.processing,
                                              "persistent-hint": "",
                                              hint: _vm.$t(
                                                "people.security.is-inspector-hint"
                                              )
                                            },
                                            model: {
                                              value: _vm.user.isInspector,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.user,
                                                  "isInspector",
                                                  $$v
                                                )
                                              },
                                              expression: "user.isInspector"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "v-stepper-content",
                    {
                      staticClass: "pt-1 pl-0 pr-0 pb-0",
                      attrs: { step: _vm.filesStep }
                    },
                    [
                      _c(
                        "v-form",
                        { ref: "filesform", on: { submit: _vm.preventSubmit } },
                        [
                          _c(
                            "v-card",
                            [
                              _c(
                                "v-row",
                                {
                                  staticClass: "mx-2",
                                  attrs: { align: "center" }
                                },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pa-0",
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _c(
                                        "v-card-title",
                                        [
                                          _c("fd-add-file-button", {
                                            attrs: { disabled: _vm.processing },
                                            on: { change: _vm.addFile }
                                          }),
                                          _vm._v(" "),
                                          _c("v-divider", {
                                            staticClass: "mx-4",
                                            attrs: { inset: "", vertical: "" }
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: { top: "" },
                                              scopedSlots: _vm._u([
                                                {
                                                  key: "activator",
                                                  fn: function({ on }) {
                                                    return [
                                                      _c(
                                                        "v-icon",
                                                        _vm._g(
                                                          {
                                                            staticClass: "ml-1",
                                                            attrs: {
                                                              color: "black",
                                                              dark: ""
                                                            }
                                                          },
                                                          on
                                                        ),
                                                        [_vm._v("info")]
                                                      )
                                                    ]
                                                  }
                                                }
                                              ])
                                            },
                                            [
                                              _vm._v(" "),
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "users.files-table-listing-tooltip"
                                                    )
                                                  )
                                                )
                                              ])
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("v-spacer"),
                                          _vm._v(" "),
                                          _c("v-text-field", {
                                            staticClass: "pl-0",
                                            attrs: {
                                              "append-icon": "search",
                                              label: _vm.$t("common.search"),
                                              "single-line": "",
                                              "hide-details": "",
                                              clearable: ""
                                            },
                                            model: {
                                              value: _vm.tablesearchfiles,
                                              callback: function($$v) {
                                                _vm.tablesearchfiles = $$v
                                              },
                                              expression: "tablesearchfiles"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("v-data-table", {
                                        directives: [
                                          {
                                            name: "fd-column",
                                            rawName: "v-fd-column:name",
                                            value: _vm.$t("common.name"),
                                            expression: "$t('common.name')",
                                            arg: "name"
                                          },
                                          {
                                            name: "fd-column",
                                            rawName:
                                              "v-fd-column:actions.no-sort.class_fd-actions-cell",
                                            arg: "actions",
                                            modifiers: {
                                              "no-sort": true,
                                              "class_fd-actions-cell": true
                                            }
                                          }
                                        ],
                                        staticClass: "fd-actions-table",
                                        attrs: {
                                          items: _vm.files,
                                          search: _vm.tablesearchfiles,
                                          loading: _vm.processing,
                                          "loading-text": _vm.$t(
                                            "common.table-loading-message"
                                          ),
                                          "sort-by": ["name"],
                                          "mobile-breakpoint": "0"
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "item.actions",
                                            fn: function({ item }) {
                                              return [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "table-actions-column-min-width"
                                                  },
                                                  [
                                                    _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: { left: "" },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function({
                                                                on
                                                              }) {
                                                                return [
                                                                  _c(
                                                                    "v-btn",
                                                                    _vm._g(
                                                                      {
                                                                        attrs: {
                                                                          icon:
                                                                            "",
                                                                          disabled:
                                                                            !item.isPreviewable ||
                                                                            _vm.processing
                                                                        }
                                                                      },
                                                                      on
                                                                    ),
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        {
                                                                          attrs: {
                                                                            small:
                                                                              ""
                                                                          },
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.viewFile(
                                                                                item
                                                                              )
                                                                            }
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                                mdi-eye\n                              "
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ]
                                                              }
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        )
                                                      },
                                                      [
                                                        _vm._v(" "),
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "common.view"
                                                              )
                                                            )
                                                          )
                                                        ])
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: { top: "" },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function({
                                                                on
                                                              }) {
                                                                return [
                                                                  _c(
                                                                    "v-btn",
                                                                    _vm._g(
                                                                      {
                                                                        attrs: {
                                                                          icon:
                                                                            "",
                                                                          disabled:
                                                                            _vm.processing
                                                                        }
                                                                      },
                                                                      on
                                                                    ),
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        {
                                                                          attrs: {
                                                                            small:
                                                                              ""
                                                                          },
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.removeFile(
                                                                                item
                                                                              )
                                                                            }
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                                mdi-delete\n                              "
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ]
                                                              }
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        )
                                                      },
                                                      [
                                                        _vm._v(" "),
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "common.remove"
                                                              )
                                                            )
                                                          )
                                                        ])
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ])
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-form",
                { ref: "form", on: { submit: _vm.onSubmit } },
                [
                  _vm.inlineMessage.message
                    ? _c(
                        "v-row",
                        { staticClass: "mx-2", attrs: { align: "center" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _vm.inlineMessage.message
                                ? _c(
                                    "v-alert",
                                    { attrs: { type: _vm.inlineMessage.type } },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "white-space": "pre-line"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.inlineMessage.message)
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.showUserWillNotBeNotifiedNotice
                    ? _c(
                        "v-row",
                        { staticClass: "mx-2", attrs: { align: "center" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _vm.showUserWillNotBeNotifiedNotice
                                ? _c(
                                    "v-alert",
                                    { attrs: { type: "warning" } },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "white-space": "pre-line"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "people.user-will-not-be-notified-warning"
                                              )
                                            )
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    {
                      staticClass:
                        "mx-2 mb-3 d-flex d-sm-none justify-end align-center"
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            "data-cy": "xs-save",
                            outlined: _vm.step != _vm.lastStep,
                            color: "primary",
                            loading: _vm.processing,
                            disabled: _vm.processing,
                            small: _vm.$vuetify.breakpoint.xsOnly
                          },
                          on: { click: _vm.saveDialog }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("common.save-and-close")) +
                              "\n          "
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          staticClass: "d-none d-sm-flex",
                          attrs: {
                            "data-cy": "save",
                            outlined: _vm.step != _vm.lastStep,
                            color: "primary",
                            loading: _vm.processing,
                            disabled: _vm.processing,
                            small: _vm.$vuetify.breakpoint.xsOnly
                          },
                          on: { click: _vm.saveDialog }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("common.save-and-close")) +
                              "\n          "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "ml-4 mr-3 fd-button-context-separator-hide-when-mobile"
                        },
                        [_vm._v("|")]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            text: "",
                            "data-cy": "cancel",
                            disabled: _vm.saving,
                            small: _vm.$vuetify.breakpoint.xsOnly
                          },
                          on: { click: _vm.cancelDialog }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("common.cancel")) +
                              "\n          "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            "data-cy": "continue",
                            disabled:
                              _vm.step == _vm.lastStep ||
                              _vm.processing ||
                              _vm.saving,
                            color: "primary",
                            small: _vm.$vuetify.breakpoint.xsOnly
                          },
                          on: {
                            click: function($event) {
                              _vm.step = Number(_vm.step) + 1
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("common.continue")) +
                              "\n          "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }