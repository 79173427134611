var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "fieldset",
    {
      staticClass: "fp-grouped-control-fieldset",
      class: { "fp-disabled": _vm.disabled }
    },
    [
      _c("legend", { staticClass: "fp-grouped-control-fieldset--legend" }, [
        _c(
          "div",
          { staticClass: "fp-grouped-control-fieldset--legend-label" },
          [
            _vm._v("\n      " + _vm._s(_vm.label) + "\n      "),
            _c(
              "v-tooltip",
              {
                attrs: { top: "" },
                scopedSlots: _vm._u([
                  {
                    key: "activator",
                    fn: function({ on }) {
                      return [
                        _vm.showInfo
                          ? _c(
                              "v-icon",
                              _vm._g(
                                {
                                  staticClass:
                                    "fp-grouped-control-fieldset--legend-info-icon",
                                  attrs: { color: "black", dark: "", small: "" }
                                },
                                on
                              ),
                              [_vm._v("info")]
                            )
                          : _vm._e()
                      ]
                    }
                  }
                ])
              },
              [_vm._v(" "), _c("span", [_vm._v(_vm._s(_vm.info))])]
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "v-row",
        {
          staticClass: "fp-grouped-control-fieldset--contents",
          attrs: { "data-cy": !!_vm.cy ? _vm.cy : _vm.dataCy }
        },
        [
          _c(
            "v-col",
            { attrs: { cols: "3" } },
            [
              _c("fd-text-field", {
                ref: "before",
                attrs: {
                  name: "before",
                  "data-cy": "before",
                  "hide-details": true,
                  dense: "",
                  readonly: true,
                  disabled: _vm.disabled,
                  label: _vm.beforeLabel,
                  value: _vm.beforeValue
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "3" } },
            [
              _c("fd-text-field", {
                ref: "add",
                attrs: {
                  name: "add",
                  "data-cy": "add",
                  "hide-details": true,
                  dense: "",
                  numeric: "",
                  type: "number",
                  "hide-spin-buttons": "",
                  min: "0",
                  "highlight-on-focus": "",
                  rules: [_vm.validateAdd],
                  readonly: _vm.readonly,
                  disabled: _vm.disabled || !_vm.allowAdd,
                  label: _vm.addLabel,
                  value: _vm.addValue,
                  error: _vm.addError
                },
                on: { input: _vm.addValueChanged }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "3" } },
            [
              _c("fd-text-field", {
                ref: "remove",
                attrs: {
                  name: "remove",
                  "data-cy": "remove",
                  "hide-details": true,
                  dense: "",
                  numeric: "",
                  type: "number",
                  "hide-spin-buttons": "",
                  min: "0",
                  "highlight-on-focus": "",
                  max: _vm.sanitizeNumber(_vm.beforeValue),
                  rules: [
                    _vm.getMaxNumberValueRule(
                      _vm.sanitizeNumber(_vm.beforeValue)
                    ),
                    _vm.validateRemove
                  ],
                  readonly: _vm.readonly,
                  disabled: _vm.disabled || !_vm.allowRemove,
                  label: _vm.removeLabel,
                  value: _vm.removeValue,
                  error: _vm.removeError
                },
                on: { input: _vm.removeValueChanged }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "3" } },
            [
              _c("fd-text-field", {
                ref: "after",
                attrs: {
                  name: "after",
                  "data-cy": "after",
                  "hide-details": true,
                  dense: "",
                  readonly: true,
                  disabled: _vm.disabled,
                  label: _vm.afterLabel,
                  value: _vm.afterValue
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }