var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { staticClass: "pa-0", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        {
          staticClass: "mx-2 fd-subheader-with-control",
          attrs: { align: "center" }
        },
        [
          _c(
            "v-subheader",
            { staticStyle: { "font-size": "1em", "margin-top": "10px" } },
            [_vm._v(_vm._s(_vm.$t("work-order-estimates.modifiers.label")))]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { staticClass: "mx-2", attrs: { align: "center" } },
        [_c("v-divider", { staticClass: "mx-3 mt-0" })],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { staticClass: "mx-2 pl-0 pr-0 pb-0 pt-5", attrs: { align: "center" } },
        [
          !_vm.scaffoldIsHardBarricade
            ? _c(
                "v-col",
                {
                  staticClass: "justify-space-between pt-1 pb-1",
                  attrs: { align: "center", cols: "12", sm: "4" }
                },
                [
                  _c("v-select", {
                    attrs: {
                      outlined: "",
                      label: _vm.$t(
                        "work-order-estimates.modifiers.scaffold-type"
                      ),
                      value: _vm.scaffoldTypeModifierID,
                      disabled: _vm.processing,
                      items: _vm.allScaffoldTypes,
                      "item-text": "name",
                      "item-value": "id"
                    },
                    on: {
                      input: v => _vm.$emit("update:scaffoldTypeModifierID", v)
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.scaffoldIsHardBarricade
            ? _c(
                "v-col",
                {
                  staticClass: "justify-space-between pt-1 pb-1",
                  attrs: { align: "center", cols: "12", sm: "4" }
                },
                [
                  _c("v-select", {
                    attrs: {
                      outlined: "",
                      label: _vm.$t(
                        "work-order-estimates.modifiers.scaffold-distance"
                      ),
                      value: _vm.scaffoldDistanceModifierID,
                      disabled: _vm.processing,
                      items: _vm.allScaffoldDistances,
                      "item-text": "name",
                      "item-value": "id",
                      clearable: ""
                    },
                    on: {
                      input: v =>
                        _vm.$emit("update:scaffoldDistanceModifierID", v)
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.scaffoldIsHardBarricade
            ? _c(
                "v-col",
                {
                  staticClass: "justify-space-between pt-1 pb-1",
                  attrs: { align: "center", cols: "12", sm: "4" }
                },
                [
                  _c("v-select", {
                    attrs: {
                      outlined: "",
                      label: _vm.$t(
                        "work-order-estimates.modifiers.scaffold-elevation"
                      ),
                      value: _vm.scaffoldElevationModifierID,
                      disabled: _vm.processing,
                      items: _vm.allScaffoldElevations,
                      "item-text": "name",
                      "item-value": "id"
                    },
                    on: {
                      input: v =>
                        _vm.$emit("update:scaffoldElevationModifierID", v)
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.scaffoldIsHardBarricade
            ? _c(
                "v-col",
                {
                  staticClass: "justify-space-between pt-1 pb-1",
                  attrs: { align: "center", cols: "12", sm: "4" }
                },
                [
                  _c("v-select", {
                    attrs: {
                      outlined: "",
                      label: _vm.$t(
                        "work-order-estimates.modifiers.scaffold-height"
                      ),
                      value: _vm.scaffoldHeightModifierID,
                      disabled: _vm.processing,
                      items: _vm.allScaffoldHeights,
                      "item-text": "name",
                      "item-value": "id",
                      hint: _vm.$t(
                        "work-order-estimates.modifiers.scaffold-height-hint"
                      ),
                      "persistent-hint": ""
                    },
                    on: {
                      input: v =>
                        _vm.$emit("update:scaffoldHeightModifierID", v)
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.scaffoldIsHardBarricade
            ? _c(
                "v-col",
                {
                  staticClass: "justify-space-between pt-1 pb-1",
                  attrs: { align: "center", cols: "12", sm: "4" }
                },
                [
                  _c("v-select", {
                    attrs: {
                      outlined: "",
                      label: _vm.$t(
                        "work-order-estimates.modifiers.build-dismantle-ratio"
                      ),
                      value: _vm.buildDismantleRatioID,
                      disabled: _vm.processing,
                      items: _vm.allBuildDismantleRatios,
                      "item-text": "ratio",
                      "item-value": "id",
                      clearable: ""
                    },
                    on: {
                      input: v => _vm.$emit("update:buildDismantleRatioID", v)
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "v-col",
            {
              staticClass: "justify-space-between pt-1 pb-1",
              attrs: { align: "center", cols: "12", sm: "4" }
            },
            [
              _c("v-select", {
                attrs: {
                  outlined: "",
                  label: _vm.$t(
                    "work-order-estimates.modifiers.congestion-factor"
                  ),
                  value: _vm.scaffoldCongestionFactorID,
                  disabled: _vm.processing,
                  items: _vm.allScaffoldCongestionFactors,
                  "item-text": "name",
                  "item-value": "id"
                },
                on: {
                  input: v => _vm.$emit("update:scaffoldCongestionFactorID", v)
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          !_vm.scaffoldIsHardBarricade
            ? _c(
                "v-col",
                {
                  staticClass: "justify-space-between pt-1 pb-1",
                  attrs: { align: "center", cols: "12", sm: "4" }
                },
                [
                  _c("v-select", {
                    attrs: {
                      outlined: "",
                      label: _vm.$t("work-order-estimates.modifiers.internal"),
                      value: _vm.internalModifierID,
                      disabled: _vm.processing,
                      items: _vm.allInternalModifiers,
                      "item-text": "name",
                      "item-value": "id"
                    },
                    on: {
                      input: v => _vm.$emit("update:internalModifierID", v)
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.scaffoldIsHardBarricade
            ? _c(
                "v-col",
                {
                  staticClass: "justify-space-between pt-1 pb-1",
                  attrs: { align: "center", cols: "12", sm: "4" }
                },
                [
                  _c("v-select", {
                    attrs: {
                      outlined: "",
                      label: _vm.$t("work-order-estimates.modifiers.hoarding"),
                      value: _vm.hoardingModifierID,
                      disabled: _vm.processing,
                      items: _vm.allHoardingModifiers,
                      "item-text": "name",
                      "item-value": "id"
                    },
                    on: {
                      input: v => _vm.$emit("update:hoardingModifierID", v)
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }