var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.value !== undefined || _vm.loading
    ? _c(
        "v-row",
        { staticClass: "mx-0 px-0" },
        [
          _c("v-col", { attrs: { cols: _vm.labelCols, sm: _vm.labelSmCols } }, [
            _c("strong", [_vm._v(_vm._s(_vm.label))])
          ]),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: _vm.valueCols, sm: _vm.valueSmCols } },
            [
              _vm.value
                ? _c(
                    "v-chip",
                    { attrs: { "x-small": "", color: "#6f2da8", dark: "" } },
                    [
                      _vm._v(
                        "\n      " + _vm._s(_vm.$t("common.yes")) + "\n    "
                      )
                    ]
                  )
                : _c("fd-value-display", {
                    attrs: {
                      valueStyle: _vm.valueStyle,
                      valueClass: _vm.valueClass,
                      loading: _vm.loading,
                      hideNoValuePlaceholder: _vm.hideNoValuePlaceholder,
                      noValueKey: _vm.noValueKey,
                      noValueStyle: _vm.noValueStyle,
                      noValueClass: _vm.noValueClass
                    }
                  })
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }