import { ScaffoldHeightModifier, scaffoldHeightModifierService2 } from "../../services";
import { Record, BasicReferenceDataCrudService } from "../baseTypes";
import { createReferenceDataStore } from "./register";

// TODO: Revisit the fact that Contractor's ID field is undefined-able - this forces us to add additional qualifiers
// TODO: Revisit the fact that Contractor's name field is undefined-able

const scaffoldHeightModifierDataStore = createReferenceDataStore<ScaffoldHeightModifier & Record>(
  "scaffoldHeightModifier",
  scaffoldHeightModifierService2 as BasicReferenceDataCrudService<ScaffoldHeightModifier & Record>,
  x => x.name!
);
export default scaffoldHeightModifierDataStore;

