import { Discipline, disciplineService2 } from "../../services";
import { Record, BasicReferenceDataCrudService } from "../baseTypes";
import { createReferenceDataStore } from "./register";

// TODO: Revisit the fact that discipline's ID field is undefined-able - this forces us to add additional qualifiers
// TODO: Revisit the fact that discipline's name field is undefined-able

const disciplineDataStore = createReferenceDataStore<Discipline & Record>(
  "discipline",
  disciplineService2 as BasicReferenceDataCrudService<Discipline & Record>,
  x => x.name!
);
export default disciplineDataStore;
