import { ScaffoldTypeModifier, scaffoldTypeModifierService2 } from "../../services";
import { Record, BasicReferenceDataCrudService } from "../baseTypes";
import { createReferenceDataStore } from "./register";

// TODO: Revisit the fact that Contractor's ID field is undefined-able - this forces us to add additional qualifiers
// TODO: Revisit the fact that Contractor's name field is undefined-able

const scaffoldTypeModifierDataStore = createReferenceDataStore<ScaffoldTypeModifier & Record>(
  "scaffoldTypeModifier",
  scaffoldTypeModifierService2 as BasicReferenceDataCrudService<ScaffoldTypeModifier & Record>,
  x => x.name!
);
export default scaffoldTypeModifierDataStore;

