import FDVue from "@fd/lib/vue";
import { TranslateResult } from "vue-i18n";
import { AccessInformation } from "../services";

export type FormattedAccessInformation = AccessInformation & {
  canViewRequestsGroup: boolean;
  canViewWorkOrdersGroup: boolean;
  canViewMaterialGroup: boolean;
  canViewLabourGroup: boolean;
  canViewAnyNonScaffoldJobs: boolean;
};
export function FormatAccessInformation(accessInfo: AccessInformation) {
  return {
    ...accessInfo,
    canViewRequestsGroup:
      accessInfo.canViewScaffoldRequestApprovals || accessInfo.canSubmitScaffoldRequests,
    canViewWorkOrdersGroup:
      accessInfo.canViewWorkOrderEstimates ||
      accessInfo.canViewWorkOrderSchedule ||
      accessInfo.canViewToDoList,
    canViewMaterialGroup:
      accessInfo.canViewMaterialApproval ||
      accessInfo.canViewTransfers ||
      accessInfo.canViewMaterialOrders,
    canViewLabourGroup:
      accessInfo.canViewTimesheets ||
      accessInfo.canViewTimesheetApprovals ||
      accessInfo.canViewLEMs,
    canViewAnyNonScaffoldJobs:
      accessInfo.canViewMaintenanceJobs ||
      accessInfo.canViewPaintJobs ||
      accessInfo.canViewInsulationJobs
  } as FormattedAccessInformation;
}
export default FDVue.extend({
  computed: {
    curUserID(): string {
      return this.$store.state.curUserID;
    },
    curUserFullName(): string {
      return this.$store.state.curUserFullName;
    },
    curUserAccess(): AccessInformation {
      return this.$store.state.curUserAccess;
    },
    curUserHomeContractorName(): string | TranslateResult {
      return this.curUserAccess?.homeContractorName ?? this.$t("common.unknown");
    },
    curUserCanViewAllContractors(): boolean {
      return !this.curUserAccess?.contractorIDs || !!this.curUserAccess?.includesAllContractors;
    },
    curUserContractorIDs(): string[] {
      return this.curUserAccess?.contractorIDs ?? [];
    },
    curUserAccessibleKeys(): string[] {
      return Object.keys(this.curUserAccess).filter(
        accessKey => accessKey.startsWith("can") && (this.curUserAccess as any)[accessKey] == true
      );
    },

    currentUserCanConfigureSettings(): boolean {
      return this.curUserAccess?.canConfigureSettings ?? false;
    },
    currentUserCanConfigurePrivateSettings(): boolean {
      return this.curUserAccess?.canConfigurePrivateSettings ?? false;
    },
    currentUserCanEnterHistoricalData(): boolean {
      return this.curUserAccess?.canEnterHistoricalData ?? false;
    },

    currentUserCanViewScaffoldInspections(): boolean {
      return this.curUserAccess?.canViewScaffoldInspections ?? false;
    },
    currentUserCanCreateScaffoldInspections(): boolean {
      return this.curUserAccess?.canCreateScaffoldInspections ?? false;
    },

    currentUserCanViewScaffoldDesigns(): boolean {
      return this.curUserAccess?.canViewScaffoldDesigns ?? false;
    },
    currentUserCanCreateScaffoldDesigns(): boolean {
      return this.curUserAccess?.canCreateScaffoldDesigns ?? false;
    },

    currentUserCanSubmitScaffoldRequests(): boolean {
      return this.curUserAccess?.canSubmitScaffoldRequests ?? false;
    },

    currentUserCanImportWorkOrders(): boolean {
      return this.curUserAccess?.canImportWorkOrders ?? false;
    },

    currentUserCanViewScaffoldRequestApprovals(): boolean {
      return this.curUserAccess?.canViewScaffoldRequestApprovals ?? false;
    },
    currentUserCanViewWorkOrders(): boolean {
      return this.curUserAccess?.canViewWorkOrders ?? false;
    },
    currentUserCanViewWorkOrderEstimates(): boolean {
      return this.curUserAccess?.canViewWorkOrderEstimates ?? false;
    },
    currentUserCanViewWorkOrderSchedule(): boolean {
      return this.curUserAccess?.canViewWorkOrderSchedule ?? false;
    },
    currentUserCanEditWorkOrderSchedule(): boolean {
      return this.curUserAccess?.canEditWorkOrderSchedule ?? false;
    },
    currentUserCanViewToDoList(): boolean {
      return this.curUserAccess?.canViewToDoList ?? false;
    },
    currentUserCanViewScaffolds(): boolean {
      return this.curUserAccess?.canViewScaffolds ?? false;
    },
    currentUserCanViewMaterialApproval(): boolean {
      return this.curUserAccess?.canViewMaterialApproval ?? false;
    },
    currentUserCanViewTransfers(): boolean {
      return this.curUserAccess?.canViewTransfers ?? false;
    },
    currentUserCanViewMaterialOrders(): boolean {
      return this.curUserAccess?.canViewMaterialOrders ?? false;
    },
    currentUserCanSubmitMaterialOrders(): boolean {
      return this.curUserAccess?.canSubmitMaterialOrders ?? false;
    },
    currentUserCanOwnDirectTimesheets(): boolean {
      return this.curUserAccess?.canOwnDirectTimesheets ?? false;
    },
    currentUserCanCreateIndirectTimesheets(): boolean {
      return this.curUserAccess?.canCreateIndirectTimesheets ?? false;
    },
    currentUserCanCorrectApprovedTimesheets(): boolean {
      return this.curUserAccess?.canCorrectApprovedTimesheets ?? false;
    },
    currentUserCanViewTimesheets(): boolean {
      return this.curUserAccess?.canViewTimesheets ?? false;
    },
    currentUserCanViewTimesheetApprovals(): boolean {
      return this.curUserAccess?.canViewTimesheetApprovals ?? false;
    },
    currentUserCanViewLEMs(): boolean {
      return this.curUserAccess?.canViewLEMs ?? false;
    },
    currentUserCanCreateTransfers(): boolean {
      return this.curUserAccess?.canCreateTransfers ?? false;
    }
  }
});

