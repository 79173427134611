import Vue, { ComponentOptions } from "vue";
import { VueConstructor } from "vue/types/umd";

import FDVue from "../";
import twoWayProps from "./twoWayProps";
import errorHandling from "./errorHandling";

export default FDVue.extend({
  mixins: [twoWayProps, errorHandling],

  // TODO: Implement show property as mixin? Figure this out as we explore other optimizations
  props: ["show"],
  twoWayProps: ["show"],

  data: () => ({
    closePromiseCallback: null as ((result?: any) => void) | null,
    failPromiseCallback: null as ((reason: Error) => void) | null,
    dialogCloseHandled: false // If the dialog is not persistent, it may close automatically
  }),

  computed: {
    unwatchedMethodNames(): string[] {
      // Because we're using the errorHandling mixin, we want to avoid the dialog open/close methods from being wrapped in error handling
      // The error handling automatically starts/stops the loading indicators, which we don't want when opening a dialog as they won't go away until the dialog is closed
      return ["showDialog", "openDialog", "closeDialog", "failDialog"];
    }
  },

  methods: {
    async showDialog(mountPoint?: string | Element | undefined): Promise<any> {
      this.optOutOfErrorHandling();
      this.$mount(mountPoint);
      let returnValue = await this.openDialog();
      this.$el.remove();
      this.$destroy();
      return returnValue;
    },

    openDialog(): Promise<any> {
      this.optOutOfErrorHandling();
      return new Promise((resolve, reject) => {
        // If this gets opened twice, raise an error; the close and fail promise callbacks
        // should be null at this point
        if (this.failPromiseCallback) {
          this.failPromiseCallback(
            new Error("Dialogs must be closed before they can be re-opened.")
          );
        }

        // Reset the handled state tracker so that if the dialog is closed automatically we can cleanup properly
        this.dialogCloseHandled = false;
        // Store the callback for when the close button gets called
        this.closePromiseCallback = resolve;
        this.failPromiseCallback = reject;
        this.twoWayProps!.show = true;
      });
    },

    closeDialog(result?: any) {
      this.dialogCloseHandled = true;
      this.twoWayProps!.show = false;
      this.closePromiseCallback!(result);
      this.closePromiseCallback = null;
      this.failPromiseCallback = null;
      if ((this as any).onDialogClosed) {
        (this as any).onDialogClosed({ result });
      }
    },

    failDialog(reason: Error) {
      this.dialogCloseHandled = true;
      this.twoWayProps!.show = false;
      this.failPromiseCallback!(reason);
      this.closePromiseCallback = null;
      this.failPromiseCallback = null;
      if ((this as any).onDialogClosed) {
        (this as any).onDialogClosed({ reason });
      }
    }
  },

  watch: {
    "twoWayProps.show": function(newValue) {
      if (!newValue && !this.dialogCloseHandled) {
        this.closeDialog();
      }
    }
  }
});

// TODO: We can't just create dialogConfig with ComponentOptions<Vue> | VueConstructor<Vue> - why?
export function createDialog<T extends Vue>(dialogConfig: ComponentOptions<T>): T;
export function createDialog<T extends Vue>(dialogConfig: VueConstructor<T>): T;
export function createDialog(dialogConfig: any): Vue {
  let dialogClass = Vue.extend(dialogConfig);
  let dialog = new dialogClass(FDVue.options);
  return dialog;
}

