<template>
    <v-container fluid>
        <v-alert v-if="inlineMessage.message" v-bind:type="inlineMessage.type" class="mt-0 mb-3">
            {{ inlineMessage.message }}
        </v-alert>
        <v-card>
            <v-toolbar v-bind:class="[processing ? 'view-card-top-color-processing' : 'view-card-top-color']">
                <!--View Title-->
                <v-row>
                    <v-col cols="6">
                        <v-breadcrumbs
                                :items="$store.state.currentBreadcrumbs"
                                large
                                v-bind:class="[processing ? 'breadcrumb-processing-opacity' : '', 'pl-0']"
                        >
                            <template v-slot:divider>
                                <v-icon>fa-chevron-right</v-icon>
                            </template>
                        </v-breadcrumbs>
                    </v-col>

                    <v-col cols="6">
                        <v-row justify="end">
                            <!--Tags Filtering control-->
                            <v-combobox
                                    v-model="tagsSelectedForFiltering"
                                    :items="tagsInUse"
                                    :label="$t('common.filter-by-tags')"
                                    item-text="name"
                                    item-value="id"
                                    multiple
                                    small-chips
                                    dense
                                    v-bind:class="[tagsSelectedForFiltering.length > 0 ? 'fd-combobox-control-item-selected' : 'fd-combobox-control-item-not-selected', 'pt-5', 'pr-3', 'fd-limit-combobox-size-to-content']"
                            />
                        </v-row>
                    </v-col>
                </v-row>
                <!--Add Button-->
                <template v-slot:extension>
                    <v-btn
                            dark
                            absolute
                            bottom
                            left
                            fab
                            @click="openNewDialog()"
                            class="add-fab-button-primary"
                    >
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                </template>
            </v-toolbar>

            <!--Search bar for the view table-->
            <v-card-subtitle style="margin-top: 15px;">
                <v-text-field
                        v-model="tablesearch"
                        append-icon="search"
                        :label="$t('common.search')"
                        single-line
                        hide-details
                />
            </v-card-subtitle>

            <!--Datatable for the view-->
            <v-data-table
                    :headers="headers"
                    :items="mappings"
                    :search="tablesearch"
                    :loading="processing"
                    :loading-text="$t('common.table-loading-message')"
                    :sort-by="sortTableBy"
            >
                <!--    The following will change the headers for the table so they are internationalized.-->
                <template v-slot:header.name="{ header }">
                    {{ $t('common.' + header.value) }}
                </template>
                <template v-slot:header.description="{ header }">
                    {{ $t('common.' + header.value) }}
                </template>
                <template v-slot:header.enabled="{ header }">
                    {{ $t('common.' + header.value) }}
                </template>
                <template v-slot:header.actions="{ header }">
                    {{ $t('common.' + header.value) }}
                </template>
                <!-- Template for the simple check box on screen that will control whether the record is available.-->
                <template v-slot:item.enabled="{ item }">
                    <v-simple-checkbox
                            v-bind:value="item.enabled"
                            v-bind:disabled="processing"
                            v-on:input="flipEnabled(item)"
                    />
                </template>
                <!--Action buttons for the datatable-->
                <template v-slot:item.actions="{ item }">
                    <div class="table-actions-column-min-width">
                        <v-tooltip left>
                            <template v-slot:activator="{ on }">
                                <v-btn
                                        icon
                                        link
                                        v-bind:disabled="processing"
                                        v-bind:to="`/mappings/${item.id}`"
                                        v-on="on"
                                >
                                    <v-icon
                                            small
                                    >
                                        mdi-pencil
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('common.edit') }}</span>
                        </v-tooltip>
                        <v-tooltip right>
                            <template v-slot:activator="{ on }">
                                <v-btn
                                        icon
                                        v-bind:disabled="processing"
                                        v-on="on"
                                >
                                    <v-icon
                                            small
                                            v-bind:disabled="processing"
                                            @click="deleteTableItem(item)"
                                    >
                                        mdi-delete
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('common.remove') }}</span>
                        </v-tooltip>
                    </div>
                </template>
            </v-data-table>
        </v-card>
        <fd-mapping-new-dialog v-bind:show.sync="showNewDialog"/>
    </v-container>
</template>

<script>
    import {mapMutations, mapState, mapActions} from "vuex";
    import {filterByTags, getTagsInUse} from "../services/taggableItems";

    export default {

        name: 'fd-Mappings',

        components: {
            "fd-mapping-new-dialog": () => import('./components/MappingNewDialog.vue'),
        },

        data: () => ({
            showNewDialog: false,
            showDisableInsteadDialog: false,
            itemToDelete: null,


            // The following is responsible for the inline messages that may be presented to the user.
            inlineMessage: {
                message: null,
                type: 'error',
            },

            // The following will control whether the controls on screen are disabled while we are processing.
            processing: false,

            // The following is responsible for tracking how the table within the view is currently being sorted by.
            sortTableBy: ['name'],

            // The following is used to define the breadcrumbs navigation for the view.
            breadcrumbs: [
                {
                    text: 'Mappings',
                    disabled: true,
                },
            ],

            // These objects are used for the view specific "Tags" filtering.
            tagsSelectedForFiltering: [],

            // Datatable related objects
            tablesearch: '',
            headers: [
                { text: 'Name', value: 'name' },
                { text: 'Description', value: 'description' },
                { text: 'Enabled', value: 'enabled' },
                { text: 'Actions', value: 'actions', sortable: false },
            ],

        }),

        computed: {
            mappings () { return filterByTags(this.tagsSelectedForFiltering, this.$store.state.mappings.fullList); },
            tagsInUse () { return getTagsInUse(this.$store.state.tags.fullList, this.$store.state.mappings.fullList); }
        },

        methods: {
            openNewDialog () {
                this.showNewDialog = true;
            },

            // the following works with the delete "Action" button in the Datatable.
            async deleteTableItem (item) {
                this.inlineMessage.message = null;

                this.processing = true
                try {
                    await this.deleteMapping({ id: item.id, name: item.name });
                } catch (error) {
                    this.inlineMessage.message = this.$t("unexpected-network-error");
                    this.inlineMessage.type = "error";
                } finally {
                    this.processing = false;
                }
            },
            async flipEnabled (item) {
                this.inlineMessage.message = null;
                this.processing = true
                try {
                    await this.updateMapping({ id: item.id, enabled: !item.enabled, name: item.name });
                } catch (error) {
                    this.inlineMessage.message = this.$t("unexpected-network-error");
                    this.inlineMessage.type = "error";
                } finally {
                    this.processing = false;
                }
            },
            ...mapMutations({
                notifyNewBreadcrumb: 'NOTIFY_NEW_BREADCRUMB'
            }),
            ...mapActions({
                loadMappings: 'LOAD_MAPPINGS',
                updateMapping: 'UPDATE_MAPPING',
                deleteMapping: 'DELETE_MAPPING',
                loadTags: 'LOAD_TAGS',
            })
        },

        created: async function () {
            this.notifyNewBreadcrumb({
                text: "Mappings",
                to: "/mappings",
                resetHistory: true
            });
            this.processing = true;
            try {
                await Promise.all([
                    this.loadMappings(),
                    this.loadTags()
                ]);
            } catch (error) {
                this.inlineMessage.message = this.$t("unexpected-network-error");
                this.inlineMessage.type = "error";
            } finally {
                this.processing = false;
            }
        }
    }
</script>
