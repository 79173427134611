import FDVue from "@fd/lib/vue";
import { PropType } from "vue";
import {
  EstimateTotalTimes,
  PartGenerationType,
  WorkOrderActualWorkedTimeTotals,
  WorkOrderActualsEstimatedTimeTotals,
  WorkOrderEstimateWithLatest
} from "../../services";
import { TranslateResult } from "vue-i18n";

type WorkOrderEstimateWithDetails = WorkOrderEstimateWithLatest & {
  generationTypeName: string;
  generationMethodName: string;
  dateString: string;
  bayLengthName: string;
  lengthBayCount: number;
  bayWidthName: string;
  widthBayCount: number;
  bayHeightName: string;

  estimatedTotalTime: number;
  estimatedErectMPP: number;
  estimatedDismantleMPP: number;

  scaffoldTypeModifierName: string;
  scaffoldDistanceModifierName: string;
  scaffoldElevationModifierName: string;
  scaffoldHeightModifierName: string;
  scaffoldCongestionFactorName: string;
  buildDismantleRatioName: string;
  internalModifierName: string;
  hoardingModifierName: string;
};

export default FDVue.extend({
  props: {
    currentEstimate: { type: Object as PropType<WorkOrderEstimateWithDetails | undefined> },
    workOrderActualWorkedTimes: {
      type: Object as PropType<WorkOrderActualWorkedTimeTotals>
    },
    workOrderActualsEstimatedTimes: {
      type: Object as PropType<WorkOrderActualsEstimatedTimeTotals>
    },
    currentEstimateTimeTotals: {
      type: Object as PropType<EstimateTotalTimes>
    },
    loading: { type: Boolean, default: false }
  },
  data: function() {
    return {
      summaryPanelTimeUnitDivider: 1
    };
  },
  computed: {
    summaryPanelTimeUnit(): string | TranslateResult {
      if (this.summaryPanelTimeUnitDivider == 60) return this.$t("common.hours");

      return this.$t("common.minutes-short");
    },
    currentEstimateIsLWH(): boolean {
      return this.currentEstimate?.generationTypeID == PartGenerationType.LWH;
    },
    currentEstimateLWHSummary(): string {
      var parts = [] as string[];
      parts.push((this.currentEstimate?.length ?? 0).toString());
      parts.push((this.currentEstimate?.width ?? 0).toString());
      parts.push((this.currentEstimate?.height ?? 0).toString());
      return parts.join(" x ");
    }
  },
  methods: {
    getTimeDisplay(
      minutes: string | number | undefined | null,
      digits: number = 2,
      allowZero: boolean = false
    ) {
      if (minutes == undefined || minutes == null) return undefined;
      let val = Number(minutes);
      if (isNaN(val)) return undefined;

      let time = val / this.summaryPanelTimeUnitDivider;
      return this.formatNumber(time, digits, allowZero);
    },
    formatNumber(
      number: string | number | undefined | null,
      digits: number = 2,
      allowZero: boolean = false
    ): string | undefined {
      if (number == undefined || number == null) return undefined;
      let val = Number(number);
      if (isNaN(val)) return undefined;
      if (!allowZero && val == 0) return undefined;
      return val.toFixed(digits);
    }
  }
});

