var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("fd-privacy-dialog"),
      _vm._v(" "),
      _c("fd-terms-dialog"),
      _vm._v(" "),
      _c("div", { staticClass: "sign-in-panel-overall-back-panel" }),
      _vm._v(" "),
      _c("fd-login-terms-and-conditions-form", {
        attrs: {
          show: _vm.showTermsAndConditions,
          smallishMobileDevice: _vm.smallishMobileDevice,
          smallWidthBrowser: _vm.smallWidthBrowser,
          loading: _vm.processingTermsAndConditionsApproval,
          disabled: _vm.processing
        },
        on: {
          done: _vm.processTermsAndConditionsAcceptance,
          openTermsDialog: function($event) {
            return _vm.setPersistentTermsDialog(!_vm.dialogTerms)
          }
        },
        model: {
          value: _vm.acceptTermsAndConditions,
          callback: function($$v) {
            _vm.acceptTermsAndConditions = $$v
          },
          expression: "acceptTermsAndConditions"
        }
      }),
      _vm._v(" "),
      _c("fd-login-allow-notifications-form", {
        attrs: {
          show: _vm.showAllowNotifications,
          contactMethods: _vm.verifiedContactMethods,
          smallishMobileDevice: _vm.smallishMobileDevice,
          smallWidthBrowser: _vm.smallWidthBrowser,
          loading: _vm.processingAllowNotifications,
          disabled: _vm.processing
        },
        on: { done: _vm.processAllowNotifications }
      }),
      _vm._v(" "),
      _c("fd-login-terms-and-conditions-accepted-form", {
        attrs: {
          show: _vm.showTermsAndConditionsAcceptedSuccessfully,
          value: _vm.signincountdown,
          smallishMobileDevice: _vm.smallishMobileDevice,
          smallWidthBrowser: _vm.smallWidthBrowser
        }
      }),
      _vm._v(" "),
      _c("fd-login-select-different-contact-method-form", {
        attrs: {
          show: _vm.showAdditionalContactMethods,
          disabled: _vm.processing,
          loading: _vm.processingSignInRequest,
          contactMethods: _vm.verifiedContactMethods,
          smallishMobileDevice: _vm.smallishMobileDevice,
          smallWidthBrowser: _vm.smallWidthBrowser
        },
        on: { done: _vm.contactMethodSelected },
        model: {
          value: _vm.selectedContactMethodID,
          callback: function($$v) {
            _vm.selectedContactMethodID = $$v
          },
          expression: "selectedContactMethodID"
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          class: [
            {
              "presto-pop": _vm.showPasswordUpdatedSuccessfullyResponse,
              "disappear-nicely": !_vm.showPasswordUpdatedSuccessfullyResponse,
              "login-screen-floating-placement-mobile-override":
                _vm.smallishMobileDevice || _vm.smallWidthBrowser
            },
            "sign-in-controls-container",
            "login-screen-floating-placement"
          ]
        },
        [
          !_vm.smallishMobileDevice
            ? _c(
                "v-row",
                { attrs: { justify: "center" } },
                [
                  _c(
                    "v-icon",
                    {
                      class: {
                        "presto-pop":
                          _vm.showPasswordUpdatedSuccessfullyResponse
                      },
                      staticStyle: { height: "100px", width: "100px" },
                      attrs: { color: "green", size: "100" }
                    },
                    [_vm._v("fa-check-circle")]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.smallishMobileDevice
            ? _c("div", [
                _vm.landscapeOrientation
                  ? _c(
                      "div",
                      [
                        _c(
                          "v-row",
                          { staticClass: "ml-0 mr-0", attrs: { align: "end" } },
                          [
                            _c(
                              "v-icon",
                              {
                                class: {
                                  "presto-pop":
                                    _vm.showPasswordUpdatedSuccessfullyResponse
                                },
                                staticStyle: {
                                  height: "40px",
                                  width: "40px",
                                  "padding-bottom": "10px"
                                },
                                attrs: { color: "green", size: "40" }
                              },
                              [_vm._v("fa-check-circle")]
                            ),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                class: {
                                  "presto-pop":
                                    _vm.showPasswordUpdatedSuccessfullyResponse
                                },
                                staticStyle: {
                                  "font-weight": "Bold",
                                  "font-size": "2.0em",
                                  "padding-left": "10px"
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "password.update-password-success-title"
                                    )
                                  )
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                class: {
                                  "presto-pop":
                                    _vm.showPasswordUpdatedSuccessfullyResponse
                                },
                                staticStyle: {
                                  "font-weight": "Bold",
                                  "font-size": "1.0em",
                                  "margin-bottom": "5px",
                                  "margin-left": "10px"
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "password.update-password-success-inline-text-1"
                                    )
                                  )
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                !_vm.landscapeOrientation
                  ? _c(
                      "div",
                      [
                        _c(
                          "v-row",
                          { staticClass: "ml-0 mr-0" },
                          [
                            _c(
                              "v-icon",
                              {
                                class: {
                                  "presto-pop":
                                    _vm.showPasswordUpdatedSuccessfullyResponse
                                },
                                staticStyle: {
                                  height: "40px",
                                  width: "40px",
                                  "padding-top": "7px"
                                },
                                attrs: { color: "green", size: "40" }
                              },
                              [_vm._v("fa-check-circle")]
                            ),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                class: {
                                  "presto-pop":
                                    _vm.showPasswordUpdatedSuccessfullyResponse
                                },
                                staticStyle: {
                                  "font-weight": "Bold",
                                  "font-size": "2.0em",
                                  "padding-left": "10px"
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "password.update-password-success-title"
                                    )
                                  )
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("v-row", { staticClass: "ml-0 mr-0" }, [
                          _c(
                            "span",
                            {
                              class: {
                                "presto-pop":
                                  _vm.showPasswordUpdatedSuccessfullyResponse
                              },
                              staticStyle: {
                                "font-weight": "Bold",
                                "font-size": "1.0em"
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "password.update-password-success-inline-text-1"
                                  )
                                )
                              )
                            ]
                          )
                        ])
                      ],
                      1
                    )
                  : _vm._e()
              ])
            : _vm._e(),
          _vm._v(" "),
          !_vm.smallishMobileDevice
            ? _c(
                "div",
                [
                  _c("v-row", { attrs: { justify: "center" } }, [
                    _c(
                      "span",
                      {
                        class: {
                          "presto-pop":
                            _vm.showPasswordUpdatedSuccessfullyResponse
                        },
                        staticStyle: {
                          "font-weight": "Bold",
                          "font-size": "2.0em"
                        }
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$t("password.update-password-success-title")
                          )
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("v-row", { attrs: { justify: "center" } }, [
                    _c(
                      "span",
                      {
                        class: {
                          "presto-pop":
                            _vm.showPasswordUpdatedSuccessfullyResponse
                        },
                        staticStyle: {
                          "font-weight": "Bold",
                          "font-size": "1.0em"
                        }
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "password.update-password-success-inline-text-1"
                            )
                          )
                        )
                      ]
                    )
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("v-row", { staticClass: "mt-5 mr-0 ml-0" }, [
            _c(
              "span",
              {
                class: {
                  "appear-nicely": _vm.showPasswordUpdatedSuccessfullyResponse
                },
                staticStyle: { "font-size": "1.0em", opacity: "0" }
              },
              [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "password.update-password-info-about-password-change-1"
                    )
                  ) + "\n        "
                ),
                _c(
                  "span",
                  { staticStyle: { "font-weight": "bold", color: "#FF4D00" } },
                  [_vm._v(_vm._s(_vm.emailAddressOrPhoneNumber))]
                ),
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.$t(
                        "password.update-password-info-about-password-change-2"
                      )
                    )
                )
              ]
            )
          ]),
          _vm._v(" "),
          _c("v-row", { staticClass: "mt-5 mr-0 ml-0" }, [
            _c(
              "span",
              {
                class: {
                  "appear-nicely": _vm.showPasswordUpdatedSuccessfullyResponse
                },
                staticStyle: { "font-size": "1.0em", opacity: "0" }
              },
              [
                _vm._v(
                  _vm._s(
                    _vm.$t("password.update-password-you-will-be-signed-in-in")
                  ) + "\n        "
                ),
                _c(
                  "span",
                  {
                    staticStyle: {
                      "font-weight": "bold",
                      color: "#FF4D00",
                      "font-size": "1.5em"
                    }
                  },
                  [_vm._v("\n          " + _vm._s(_vm.signincountdown))]
                )
              ]
            )
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          class: [
            {
              "appear-nicely": _vm.showUpdatePasswordEntry,
              "disappear-nicely": !_vm.showUpdatePasswordEntry,
              "login-screen-floating-placement-mobile-override":
                _vm.smallishMobileDevice || _vm.smallWidthBrowser
            },
            "login-screen-floating-placement",
            "sign-in-controls-container"
          ],
          staticStyle: { opacity: "0", "pointer-events": "none" }
        },
        [
          _c(
            "v-form",
            { ref: "changePasswordForm" },
            [
              _c(
                "v-row",
                { staticClass: "ml-0" },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "pl-0 pb-0",
                      attrs: { cols: "10", md: "12" }
                    },
                    [
                      _c(
                        "v-row",
                        { staticClass: "ml-0", attrs: { justify: "start" } },
                        [
                          _c(
                            "span",
                            {
                              class: {
                                "update-password-title-text-regular":
                                  !_vm.smallishMobileDevice &&
                                  !_vm.smallWidthBrowser,
                                "update-password-title-text-smaller":
                                  _vm.smallishMobileDevice ||
                                  _vm.smallWidthBrowser
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("password.update-password-title"))
                              )
                            ]
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    {
                      class: [
                        {
                          hidden:
                            !_vm.smallishMobileDevice && !_vm.smallWidthBrowser
                        },
                        {
                          "pb-0":
                            _vm.smallishMobileDevice && _vm.landscapeOrientation
                        }
                      ],
                      attrs: { cols: "2", md: "0" }
                    },
                    [
                      _c(
                        "v-row",
                        { attrs: { justify: "end" } },
                        [
                          _c(
                            "v-menu",
                            {
                              attrs: {
                                bottom: "",
                                left: "",
                                "offset-y": "",
                                origin: "top right",
                                transition: "scale-transition"
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function({ attrs, on }) {
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              attrs: {
                                                "min-width": "0",
                                                text: ""
                                              }
                                            },
                                            "v-btn",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [
                                          _c("v-icon", [
                                            _vm._v("mdi-translate")
                                          ])
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ])
                            },
                            [
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "appbar-menu" },
                                [
                                  _c(
                                    "v-list",
                                    { attrs: { tile: false, nav: "" } },
                                    [
                                      _c(
                                        "v-list-item-group",
                                        {
                                          model: {
                                            value: _vm.language,
                                            callback: function($$v) {
                                              _vm.language = $$v
                                            },
                                            expression: "language"
                                          }
                                        },
                                        _vm._l(_vm.languageslist, function(
                                          languagechoice
                                        ) {
                                          return _c(
                                            "v-list-item",
                                            { key: languagechoice.name },
                                            [
                                              _c(
                                                "v-list-item-content",
                                                [
                                                  _c("v-list-item-title", [
                                                    _vm._v(
                                                      _vm._s(
                                                        languagechoice.name
                                                      )
                                                    )
                                                  ])
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-list-item-action",
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      class: [
                                                        _vm.language ==
                                                        languagechoice.number
                                                          ? "selected-menu-icon-color"
                                                          : "unselected-menu-icon-color"
                                                      ]
                                                    },
                                                    [_vm._v("fas fa-language")]
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        }),
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _vm.inlineMessage.message &&
              !_vm.smallishMobileDevice &&
              !_vm.smallWidthBrowser
                ? _c(
                    "v-alert",
                    {
                      staticClass: "mt-0 mb-6 ml-0 mr-0",
                      attrs: { type: _vm.inlineMessage.type }
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.inlineMessage.message) +
                          "\n      "
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.inlineMessage.message &&
              (_vm.smallishMobileDevice || _vm.smallWidthBrowser)
                ? _c(
                    "v-alert",
                    {
                      staticClass: "mt-0 mb-5 ml-0 mr-0",
                      attrs: { dense: "", type: _vm.inlineMessage.type }
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.inlineMessage.message) +
                          "\n      "
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.processingSharedSecret
                ? _c(
                    "v-row",
                    { staticClass: "mt-3 mb-5", attrs: { justify: "center" } },
                    [
                      _c(
                        "div",
                        { staticClass: "text-center" },
                        [
                          _c("v-progress-circular", {
                            attrs: { indeterminate: "", color: "primary" }
                          })
                        ],
                        1
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.inlineMessage.message && !_vm.processingSharedSecret
                ? _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "pt-0 pb-0", attrs: { cols: "12" } },
                        [
                          _c(
                            "span",
                            {
                              class: {
                                "update-password-inline-text-info-font-size-regular":
                                  !_vm.smallishMobileDevice &&
                                  !_vm.smallWidthBrowser,
                                "update-password-inline-text-info-font-size-smaller":
                                  _vm.smallishMobileDevice ||
                                  _vm.smallWidthBrowser
                              }
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "update-password-call-out-text-style"
                                },
                                [_vm._v(_vm._s(_vm.emailAddressOrPhoneNumber))]
                              ),
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.$t(
                                      "password.update-password-rules-inline-text-1"
                                    )
                                  ) +
                                  "\n          "
                              )
                            ]
                          )
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.inlineMessage.message && !_vm.processingSharedSecret
                ? _c(
                    "v-row",
                    [
                      !_vm.patterns.characterLimitPattern.test(_vm.newpassword)
                        ? _c(
                            "v-col",
                            {
                              class: {
                                "update-password-rule-col-padding-smaller":
                                  _vm.smallishMobileDevice ||
                                  _vm.smallWidthBrowser
                              },
                              attrs: { cols: "6" }
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  class: {
                                    "update-password-rule-icon-style-regular":
                                      !_vm.smallishMobileDevice &&
                                      !_vm.smallWidthBrowser,
                                    "update-password-rule-icon-style-smaller":
                                      _vm.smallishMobileDevice ||
                                      _vm.smallWidthBrowser
                                  },
                                  attrs: { color: "red" }
                                },
                                [_vm._v("mdi-close-circle")]
                              ),
                              _c(
                                "span",
                                {
                                  class: {
                                    "update-password-rule-text-style-regular":
                                      !_vm.smallishMobileDevice &&
                                      !_vm.smallWidthBrowser,
                                    "update-password-rule-text-style-smaller":
                                      _vm.smallishMobileDevice ||
                                      _vm.smallWidthBrowser
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "password.update-password-rules-minimum-characters-length"
                                      )
                                    )
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.patterns.characterLimitPattern.test(_vm.newpassword)
                        ? _c(
                            "v-col",
                            {
                              class: {
                                "update-password-rule-col-padding-smaller":
                                  _vm.smallishMobileDevice ||
                                  _vm.smallWidthBrowser
                              },
                              attrs: { cols: "6" }
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  class: {
                                    "update-password-rule-icon-style-regular":
                                      !_vm.smallishMobileDevice &&
                                      !_vm.smallWidthBrowser,
                                    "update-password-rule-icon-style-smaller":
                                      _vm.smallishMobileDevice ||
                                      _vm.smallWidthBrowser
                                  },
                                  attrs: { color: "green" }
                                },
                                [_vm._v("mdi-check-circle")]
                              ),
                              _c(
                                "span",
                                {
                                  class: {
                                    "update-password-rule-text-style-regular":
                                      !_vm.smallishMobileDevice &&
                                      !_vm.smallWidthBrowser,
                                    "update-password-rule-text-style-smaller":
                                      _vm.smallishMobileDevice ||
                                      _vm.smallWidthBrowser
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "password.update-password-rules-minimum-characters-length"
                                      )
                                    )
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !(
                        _vm.patterns.lowercasePattern.test(_vm.newpassword) &&
                        _vm.patterns.uppercasePattern.test(_vm.newpassword)
                      )
                        ? _c(
                            "v-col",
                            {
                              class: {
                                "update-password-rule-col-padding-smaller":
                                  _vm.smallishMobileDevice ||
                                  _vm.smallWidthBrowser
                              },
                              attrs: { cols: "6" }
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  class: {
                                    "update-password-rule-icon-style-regular":
                                      !_vm.smallishMobileDevice &&
                                      !_vm.smallWidthBrowser,
                                    "update-password-rule-icon-style-smaller":
                                      _vm.smallishMobileDevice ||
                                      _vm.smallWidthBrowser
                                  },
                                  attrs: { color: "red" }
                                },
                                [_vm._v("mdi-close-circle")]
                              ),
                              _c(
                                "span",
                                {
                                  class: {
                                    "update-password-rule-text-style-regular":
                                      !_vm.smallishMobileDevice &&
                                      !_vm.smallWidthBrowser,
                                    "update-password-rule-text-style-smaller":
                                      _vm.smallishMobileDevice ||
                                      _vm.smallWidthBrowser
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "password.update-password-rules-upper-and-lower-case"
                                      )
                                    )
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.patterns.lowercasePattern.test(_vm.newpassword) &&
                      _vm.patterns.uppercasePattern.test(_vm.newpassword)
                        ? _c(
                            "v-col",
                            {
                              class: {
                                "update-password-rule-col-padding-smaller":
                                  _vm.smallishMobileDevice ||
                                  _vm.smallWidthBrowser
                              },
                              attrs: { cols: "6" }
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  class: {
                                    "update-password-rule-icon-style-regular":
                                      !_vm.smallishMobileDevice &&
                                      !_vm.smallWidthBrowser,
                                    "update-password-rule-icon-style-smaller":
                                      _vm.smallishMobileDevice ||
                                      _vm.smallWidthBrowser
                                  },
                                  attrs: { color: "green" }
                                },
                                [_vm._v("mdi-check-circle")]
                              ),
                              _c(
                                "span",
                                {
                                  class: {
                                    "update-password-rule-text-style-regular":
                                      !_vm.smallishMobileDevice &&
                                      !_vm.smallWidthBrowser,
                                    "update-password-rule-text-style-smaller":
                                      _vm.smallishMobileDevice ||
                                      _vm.smallWidthBrowser
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "password.update-password-rules-upper-and-lower-case"
                                      )
                                    )
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.inlineMessage.message && !_vm.processingSharedSecret
                ? _c(
                    "v-row",
                    [
                      !_vm.patterns.numberPresentPattern.test(_vm.newpassword)
                        ? _c(
                            "v-col",
                            {
                              class: {
                                "update-password-rule-col-padding-smaller":
                                  _vm.smallishMobileDevice ||
                                  _vm.smallWidthBrowser,
                                "pt-0":
                                  !_vm.smallishMobileDevice &&
                                  !_vm.smallWidthBrowser
                              },
                              attrs: { cols: "6" }
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  class: {
                                    "update-password-rule-icon-style-regular":
                                      !_vm.smallishMobileDevice &&
                                      !_vm.smallWidthBrowser,
                                    "update-password-rule-icon-style-smaller":
                                      _vm.smallishMobileDevice ||
                                      _vm.smallWidthBrowser
                                  },
                                  attrs: { color: "red" }
                                },
                                [_vm._v("mdi-close-circle")]
                              ),
                              _c(
                                "span",
                                {
                                  class: {
                                    "update-password-rule-text-style-regular":
                                      !_vm.smallishMobileDevice &&
                                      !_vm.smallWidthBrowser,
                                    "update-password-rule-text-style-smaller":
                                      _vm.smallishMobileDevice ||
                                      _vm.smallWidthBrowser
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "password.update-password-rules-number"
                                      )
                                    ) + "\n          "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.patterns.numberPresentPattern.test(_vm.newpassword)
                        ? _c(
                            "v-col",
                            {
                              class: {
                                "update-password-rule-col-padding-smaller":
                                  _vm.smallishMobileDevice ||
                                  _vm.smallWidthBrowser,
                                "pt-0":
                                  !_vm.smallishMobileDevice &&
                                  !_vm.smallWidthBrowser
                              },
                              attrs: { cols: "6" }
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  class: {
                                    "update-password-rule-icon-style-regular":
                                      !_vm.smallishMobileDevice &&
                                      !_vm.smallWidthBrowser,
                                    "update-password-rule-icon-style-smaller":
                                      _vm.smallishMobileDevice ||
                                      _vm.smallWidthBrowser
                                  },
                                  attrs: { color: "green" }
                                },
                                [_vm._v("mdi-check-circle")]
                              ),
                              _c(
                                "span",
                                {
                                  class: {
                                    "update-password-rule-text-style-regular":
                                      !_vm.smallishMobileDevice &&
                                      !_vm.smallWidthBrowser,
                                    "update-password-rule-text-style-smaller":
                                      _vm.smallishMobileDevice ||
                                      _vm.smallWidthBrowser
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "password.update-password-rules-number"
                                      )
                                    ) + "\n          "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.patterns.specialCharacterPattern.test(
                        _vm.newpassword
                      )
                        ? _c(
                            "v-col",
                            {
                              class: {
                                "update-password-rule-col-padding-smaller":
                                  _vm.smallishMobileDevice ||
                                  _vm.smallWidthBrowser,
                                "pt-0":
                                  !_vm.smallishMobileDevice &&
                                  !_vm.smallWidthBrowser
                              }
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  class: {
                                    "update-password-rule-icon-style-regular":
                                      !_vm.smallishMobileDevice &&
                                      !_vm.smallWidthBrowser,
                                    "update-password-rule-icon-style-smaller":
                                      _vm.smallishMobileDevice ||
                                      _vm.smallWidthBrowser
                                  },
                                  attrs: { color: "red" }
                                },
                                [_vm._v("mdi-close-circle")]
                              ),
                              _c(
                                "span",
                                {
                                  class: {
                                    "update-password-rule-text-style-regular":
                                      !_vm.smallishMobileDevice &&
                                      !_vm.smallWidthBrowser,
                                    "update-password-rule-text-style-smaller":
                                      _vm.smallishMobileDevice ||
                                      _vm.smallWidthBrowser
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "password.update-password-rules-special-characters"
                                      )
                                    ) + "\n          "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.patterns.specialCharacterPattern.test(_vm.newpassword)
                        ? _c(
                            "v-col",
                            {
                              class: {
                                "update-password-rule-col-padding-smaller":
                                  _vm.smallishMobileDevice ||
                                  _vm.smallWidthBrowser,
                                "pt-0":
                                  !_vm.smallishMobileDevice &&
                                  !_vm.smallWidthBrowser
                              },
                              attrs: { cols: "6" }
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  class: {
                                    "update-password-rule-icon-style-regular":
                                      !_vm.smallishMobileDevice &&
                                      !_vm.smallWidthBrowser,
                                    "update-password-rule-icon-style-smaller":
                                      _vm.smallishMobileDevice ||
                                      _vm.smallWidthBrowser
                                  },
                                  attrs: { color: "green" }
                                },
                                [_vm._v("mdi-check-circle")]
                              ),
                              _c(
                                "span",
                                {
                                  class: {
                                    "update-password-rule-text-style-regular":
                                      !_vm.smallishMobileDevice &&
                                      !_vm.smallWidthBrowser,
                                    "update-password-rule-text-style-smaller":
                                      _vm.smallishMobileDevice ||
                                      _vm.smallWidthBrowser
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "password.update-password-rules-special-characters"
                                      )
                                    ) + "\n          "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("v-text-field", {
                staticStyle: { display: "none" },
                attrs: {
                  type: "email",
                  autocomplete: "username",
                  value: _vm.emailAddressOrPhoneNumber,
                  disabled: "",
                  hidden: ""
                }
              }),
              _vm._v(" "),
              _c("v-text-field", {
                ref: "firstupdatepasswordinput",
                staticClass: "mt-3",
                attrs: {
                  label: _vm.$t(
                    "password.update-password-enter-password-label"
                  ),
                  "append-icon": _vm.showPassword ? "mdi-eye" : "mdi-eye-off",
                  type: _vm.showPassword ? "text" : "password",
                  autocomplete: "new-password",
                  hidden: !_vm.showUpdatePasswordEntry,
                  outlined: "",
                  dense: _vm.smallishMobileDevice || _vm.smallWidthBrowser,
                  disabled: _vm.processing || !_vm.showUpdatePasswordEntry,
                  rules: [
                    _vm.rules.required,
                    _vm.rules.lowercaseLetter,
                    _vm.rules.uppercaseLetter,
                    _vm.rules.numberPresent,
                    _vm.rules.specialCharacter,
                    _vm.rules.atLeast8Characters
                  ]
                },
                on: {
                  "click:append": function($event) {
                    _vm.showPassword = !_vm.showPassword
                  }
                },
                model: {
                  value: _vm.newpassword,
                  callback: function($$v) {
                    _vm.newpassword = $$v
                  },
                  expression: "newpassword"
                }
              }),
              _vm._v(" "),
              _c("v-text-field", {
                staticClass: "mt-0",
                attrs: {
                  label: _vm.$t(
                    "password.update-password-confirm-password-label"
                  ),
                  "append-icon": _vm.showPassword ? "mdi-eye" : "mdi-eye-off",
                  type: _vm.showPassword ? "text" : "password",
                  autocomplete: "new-password",
                  hidden: !_vm.showUpdatePasswordEntry,
                  outlined: "",
                  dense: _vm.smallishMobileDevice || _vm.smallWidthBrowser,
                  disabled: _vm.processing || !_vm.showUpdatePasswordEntry,
                  rules: [
                    _vm.rules.required,
                    _vm.rules.lowercaseLetter,
                    _vm.rules.uppercaseLetter,
                    _vm.rules.numberPresent,
                    _vm.rules.specialCharacter,
                    _vm.rules.atLeast8Characters
                  ]
                },
                on: {
                  "click:append": function($event) {
                    _vm.showPassword = !_vm.showPassword
                  }
                },
                model: {
                  value: _vm.confirmnewpassword,
                  callback: function($$v) {
                    _vm.confirmnewpassword = $$v
                  },
                  expression: "confirmnewpassword"
                }
              }),
              _vm._v(" "),
              !_vm.smallishMobileDevice && !_vm.smallWidthBrowser
                ? _c(
                    "div",
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass:
                            "marketing-main-color-button-style width-100",
                          attrs: {
                            loading: _vm.processingUpdatePassword,
                            disabled: _vm.processing,
                            depressed: "",
                            "x-large": ""
                          },
                          on: { click: _vm.updatePassword }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t("password.update-password-button-label")
                            )
                          )
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              (_vm.smallishMobileDevice || _vm.smallWidthBrowser) &&
              !_vm.landscapeOrientation
                ? _c(
                    "div",
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass:
                            "marketing-main-color-button-style width-100 mt-0",
                          attrs: {
                            loading: _vm.processingUpdatePassword,
                            disabled: _vm.processing,
                            depressed: ""
                          },
                          on: { click: _vm.updatePassword }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t("password.update-password-button-label")
                            )
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        { staticClass: "pt-6", attrs: { justify: "end" } },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "pl-0 pr-0 pt-0",
                              attrs: { align: "start", cols: "6" }
                            },
                            [
                              _c(
                                "v-row",
                                { staticClass: "pl-1 pt-0" },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pt-0 pb-0 pr-0",
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: { text: "", small: "" },
                                          on: {
                                            click:
                                              _vm.startOverFromForgotPassword
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("common.start-over"))
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { staticClass: "pt-0", attrs: { cols: "6" } },
                            [
                              _c(
                                "v-row",
                                {
                                  staticClass: "pr-3",
                                  attrs: { justify: "end" }
                                },
                                [
                                  _c("v-img", {
                                    attrs: {
                                      src: _vm.logoSource,
                                      "max-height": _vm.brandImageHeight,
                                      height: _vm.brandImageHeight,
                                      "max-width": _vm.brandImageWidth,
                                      width: _vm.brandImageWidth
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.smallishMobileDevice && _vm.landscapeOrientation
                ? _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "pt-0", attrs: { cols: "8" } },
                        [
                          _c(
                            "v-btn",
                            {
                              class: [
                                { "mt-0": _vm.smallishMobileDevice },
                                "marketing-main-color-button-style",
                                "width-100"
                              ],
                              attrs: {
                                loading: _vm.processingUpdatePassword,
                                disabled: _vm.processing,
                                depressed: "",
                                "x-large": !_vm.smallishMobileDevice
                              },
                              on: { click: _vm.updatePassword }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "password.update-password-button-label"
                                  )
                                )
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              staticStyle: {
                                "margin-left": "-10px",
                                "margin-top": "10px"
                              },
                              attrs: { text: "", small: "" },
                              on: { click: _vm.startOverFromForgotPassword }
                            },
                            [_vm._v(_vm._s(_vm.$t("common.start-over")))]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { staticClass: "pt-0", attrs: { cols: "4" } },
                        [
                          _c(
                            "v-row",
                            {
                              staticClass: "height-100",
                              attrs: { justify: "center", align: "start" }
                            },
                            [
                              _c("v-img", {
                                attrs: {
                                  src: _vm.logoSource,
                                  "max-height": _vm.brandImageHeightSmaller,
                                  height: _vm.brandImageHeightSmaller,
                                  "max-width": _vm.brandImageWidthSmaller,
                                  width: _vm.brandImageWidthSmaller
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          class: [
            {
              "appear-nicely": _vm.showAccessCodeEntry,
              "disappear-nicely": !_vm.showAccessCodeEntry,
              "login-screen-floating-placement-mobile-override":
                _vm.smallishMobileDevice || _vm.smallWidthBrowser
            },
            "login-screen-floating-placement",
            "sign-in-controls-container"
          ],
          staticStyle: { opacity: "0", "pointer-events": "none" }
        },
        [
          _c(
            "v-form",
            { ref: "accessCodeEntry", on: { submit: _vm.processAccessCode } },
            [
              _vm.inlineMessage.message
                ? _c(
                    "v-alert",
                    {
                      staticClass: "mt-0 mb-5 ml-0 mr-0",
                      attrs: { type: _vm.inlineMessage.type }
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.inlineMessage.message) +
                          "\n      "
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "span",
                {
                  class: {
                    "login-panel-title-text-regular":
                      !_vm.smallishMobileDevice && !_vm.smallWidthBrowser,
                    "login-panel-title-text-smaller":
                      _vm.smallishMobileDevice || _vm.smallWidthBrowser
                  }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.$t("login.quick-access-code-request-response-title")
                      ) +
                      "\n      "
                  )
                ]
              ),
              _vm._v(" "),
              _vm.showAccessCodeEntryForForgotPassword
                ? _c(
                    "v-row",
                    {
                      class: [
                        {
                          "mt-5": !_vm.landscapeOrientation
                        },
                        "ml-0",
                        "mr-0"
                      ]
                    },
                    [
                      _c(
                        "span",
                        {
                          class: {
                            "login-panel-sub-title-text-regular":
                              !_vm.smallishMobileDevice &&
                              !_vm.smallWidthBrowser,
                            "login-panel-sub-title-text-smaller":
                              _vm.smallishMobileDevice || _vm.smallWidthBrowser
                          }
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.$t(
                                  "login.quick-access-code-forgot-password-explanation"
                                )
                              ) +
                              "\n        "
                          )
                        ]
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.showAccessCodeEntryForActivation
                ? _c(
                    "v-row",
                    {
                      class: [
                        {
                          "mt-5": !_vm.landscapeOrientation
                        },
                        "ml-0",
                        "mr-0"
                      ]
                    },
                    [
                      _c(
                        "span",
                        {
                          class: {
                            "login-panel-sub-title-text-regular":
                              !_vm.smallishMobileDevice &&
                              !_vm.smallWidthBrowser,
                            "login-panel-sub-title-text-smaller":
                              _vm.smallishMobileDevice || _vm.smallWidthBrowser
                          }
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.$t(
                                  "login.quick-access-code-communication-activation-explanation"
                                )
                              ) +
                              "\n        "
                          )
                        ]
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              !!_vm.accessCodeChallenge &&
              _vm.accessCodeChallenge.sentToEmailAddresses.length > 0
                ? _c(
                    "v-row",
                    {
                      class: [
                        {
                          "mt-5": !_vm.landscapeOrientation,
                          "mt-1": _vm.landscapeOrientation
                        },
                        "ml-0",
                        "mr-0"
                      ]
                    },
                    [
                      !!_vm.accessCodeChallenge &&
                      _vm.accessCodeChallenge.sentToEmailAddresses.length == 1
                        ? _c(
                            "span",
                            { staticStyle: { "font-size": "1.0em" } },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "login.quick-access-code-request-response-inline-email-statement"
                                  )
                                ) + "\n          "
                              ),
                              _c(
                                "span",
                                { staticClass: "login-call-out-text-style" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "fd-restrict-contact-text-width"
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            !!_vm.accessCodeChallenge &&
                                              _vm.accessCodeChallenge
                                                .sentToEmailAddress
                                          ) +
                                          "\n            "
                                      )
                                    ]
                                  )
                                ]
                              )
                            ]
                          )
                        : _c(
                            "span",
                            { staticStyle: { "font-size": "1.0em" } },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    _vm.$t(
                                      "login.quick-access-code-request-response-inline-multiple-emails-statement"
                                    )
                                  ) +
                                  "\n        "
                              )
                            ]
                          )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              !!_vm.accessCodeChallenge &&
              _vm.accessCodeChallenge.sentToPhoneNumbers.length > 0
                ? _c(
                    "v-row",
                    {
                      class: [
                        { "mt-5": !_vm.landscapeOrientation },
                        "ml-0",
                        "mr-0"
                      ]
                    },
                    [
                      !!_vm.accessCodeChallenge &&
                      _vm.accessCodeChallenge.sentToPhoneNumbers.length == 1
                        ? _c(
                            "span",
                            { staticStyle: { "font-size": "1.0em" } },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    _vm.$t(
                                      "login.quick-access-code-request-response-inline-phone-statement"
                                    )
                                  ) +
                                  "\n          "
                              ),
                              _c(
                                "span",
                                { staticClass: "login-call-out-text-style" },
                                [
                                  _vm._v(
                                    "***-***-*" +
                                      _vm._s(
                                        !!_vm.accessCodeChallenge &&
                                          _vm.accessCodeChallenge
                                            .sentToPhoneNumberTail
                                      )
                                  )
                                ]
                              ),
                              _vm._v(".\n        ")
                            ]
                          )
                        : _c(
                            "span",
                            { staticStyle: { "font-size": "1.0em" } },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    _vm.$t(
                                      "login.quick-access-code-request-response-inline-multiple-phones-statement"
                                    )
                                  ) +
                                  "\n        "
                              )
                            ]
                          )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("fd-code-entry", {
                ref: "accesscodeentry",
                class: ["pt-5"],
                staticStyle: { "margin-left": "-6px" },
                attrs: {
                  disabled: _vm.processing,
                  value: _vm.accesscode,
                  autocomplete: "one-time-code"
                },
                on: { codeEntered: _vm.codeEntered }
              }),
              _vm._v(" "),
              _c(
                "v-row",
                { staticClass: "ml-0 mr-0 mt-1" },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "pb-0",
                      staticStyle: { "margin-left": "-25px" },
                      attrs: { cols: "12" }
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            text: "",
                            small: "",
                            disabled:
                              _vm.processing || _vm.processingSignInRequest
                          },
                          on: { click: _vm.startOverFromAccessCodeEntry }
                        },
                        [_vm._v(_vm._s(_vm.$t("common.start-over")))]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    {
                      staticClass: "pb-0",
                      staticStyle: { "margin-left": "-25px" },
                      attrs: { cols: "12" }
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            disabled:
                              _vm.processing || _vm.processingSignInRequest,
                            text: "",
                            small: ""
                          },
                          on: { click: _vm.resendAccessCode }
                        },
                        [_vm._v(_vm._s(_vm.$t("login.resend-code-label")))]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.hasAdditionalLoginMethods
                    ? _c(
                        "v-col",
                        {
                          staticClass: "pb-0",
                          staticStyle: { "margin-left": "-25px" },
                          attrs: { cols: "12" }
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                disabled:
                                  _vm.processing || _vm.processingSignInRequest,
                                text: "",
                                small: ""
                              },
                              on: {
                                click:
                                  _vm.selectDifferentContactMethodForAccessCode
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "login.change-access-code-destination-button-label"
                                  )
                                )
                              )
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          class: [
            {
              "email-entry-hide-transition": !_vm.showEmailEntry,
              "credential-entry-appear": _vm.reshowEmailEntry,
              "login-screen-floating-placement-mobile-override":
                _vm.smallishMobileDevice || _vm.smallWidthBrowser
            },
            "login-screen-floating-placement",
            "sign-in-controls-container"
          ]
        },
        [
          _c(
            "v-form",
            {
              ref: "enterEmailAddressForm",
              on: { submit: _vm.typeYourPassword }
            },
            [
              _vm.inlineMessage.message &&
              !_vm.smallishMobileDevice &&
              !_vm.smallWidthBrowser
                ? _c(
                    "v-alert",
                    {
                      staticClass: "mt-0 mb-5 ml-0 mr-0",
                      attrs: { type: _vm.inlineMessage.type }
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.inlineMessage.message) +
                          "\n      "
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.inlineMessage.message &&
              (_vm.smallishMobileDevice || _vm.smallWidthBrowser)
                ? _c(
                    "v-alert",
                    {
                      staticClass: "mt-0 mb-2 ml-0 mr-0",
                      attrs: { dense: "", type: _vm.inlineMessage.type }
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.inlineMessage.message) +
                          "\n      "
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "v-row",
                { staticClass: "ml-0" },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "pl-0 pb-0",
                      attrs: { cols: "6", md: "12" }
                    },
                    [
                      _c(
                        "v-row",
                        { staticClass: "ml-0", attrs: { justify: "start" } },
                        [
                          _c(
                            "span",
                            {
                              class: {
                                "login-panel-title-text-regular":
                                  !_vm.smallishMobileDevice &&
                                  !_vm.smallWidthBrowser,
                                "login-panel-title-text-smaller":
                                  _vm.smallishMobileDevice ||
                                  _vm.smallWidthBrowser
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("login.sign-in-label")))]
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    {
                      class: {
                        hidden:
                          !_vm.smallishMobileDevice && !_vm.smallWidthBrowser,
                        "pb-0": _vm.landscapeOrientation
                      },
                      attrs: { cols: "6", md: "0" }
                    },
                    [
                      _c(
                        "v-row",
                        { staticClass: "pt-0 pb-0", attrs: { justify: "end" } },
                        [
                          _c(
                            "v-col",
                            { staticClass: "pt-0 pb-0", attrs: { cols: "12" } },
                            [
                              _c(
                                "v-row",
                                {
                                  staticClass: "pt-0 pb-0",
                                  attrs: { justify: "end" }
                                },
                                [
                                  _c(
                                    "v-menu",
                                    {
                                      attrs: {
                                        bottom: "",
                                        left: "",
                                        "offset-y": "",
                                        origin: "top right",
                                        transition: "scale-transition",
                                        disabled: _vm.processing
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "activator",
                                          fn: function({ attrs, on }) {
                                            return [
                                              _c(
                                                "v-btn",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      attrs: {
                                                        "min-width": "0",
                                                        text: ""
                                                      }
                                                    },
                                                    "v-btn",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [
                                                  _c("v-icon", [
                                                    _vm._v("mdi-translate")
                                                  ])
                                                ],
                                                1
                                              )
                                            ]
                                          }
                                        }
                                      ])
                                    },
                                    [
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "appbar-menu" },
                                        [
                                          _c(
                                            "v-list",
                                            { attrs: { tile: false, nav: "" } },
                                            [
                                              _c(
                                                "v-list-item-group",
                                                {
                                                  model: {
                                                    value: _vm.language,
                                                    callback: function($$v) {
                                                      _vm.language = $$v
                                                    },
                                                    expression: "language"
                                                  }
                                                },
                                                _vm._l(
                                                  _vm.languageslist,
                                                  function(languagechoice) {
                                                    return _c(
                                                      "v-list-item",
                                                      {
                                                        key: languagechoice.name
                                                      },
                                                      [
                                                        _c(
                                                          "v-list-item-content",
                                                          [
                                                            _c(
                                                              "v-list-item-title",
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    languagechoice.name
                                                                  )
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "v-list-item-action",
                                                          [
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                class: [
                                                                  _vm.language ==
                                                                  languagechoice.number
                                                                    ? "selected-menu-icon-color"
                                                                    : "unselected-menu-icon-color"
                                                                ]
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "fas fa-language"
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm.landscapeOrientation
                                    ? _c(
                                        "v-btn",
                                        {
                                          staticClass: "pt-2",
                                          attrs: {
                                            disabled: _vm.processing,
                                            text: "",
                                            small: ""
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.setPrivacyDialog(
                                                !_vm.dialogPrivacy
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("privacy.link-label"))
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.landscapeOrientation
                                    ? _c(
                                        "v-btn",
                                        {
                                          staticClass: "pt-2",
                                          attrs: {
                                            disabled: _vm.processing,
                                            loading:
                                              _vm.processingForgotPasswordRequest,
                                            text: "",
                                            small: ""
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.setTermsDialog(
                                                !_vm.dialogTerms
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("terms.link-label"))
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("v-text-field", {
                ref: "usernameinput",
                staticClass: "mt-0 mt-sm-3 mb-0",
                attrs: {
                  type: "email",
                  autocomplete: "username",
                  label: _vm.$t("login.email-or-phone-input-label"),
                  "data-cy": "emailAddressOrPhoneNumber",
                  outlined: "",
                  disabled: _vm.processing || !_vm.showEmailEntry,
                  hidden: !_vm.showEmailEntry,
                  dense: _vm.smallishMobileDevice || _vm.smallWidthBrowser,
                  rules: [
                    _vm.rules.required,
                    _vm.rules.validEmailOrPhoneNumberOrIdentifier
                  ]
                },
                model: {
                  value: _vm.emailAddressOrPhoneNumber,
                  callback: function($$v) {
                    _vm.emailAddressOrPhoneNumber = $$v
                  },
                  expression: "emailAddressOrPhoneNumber"
                }
              }),
              _vm._v(" "),
              !_vm.smallishMobileDevice && !_vm.smallWidthBrowser
                ? _c(
                    "div",
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass:
                            "marketing-main-color-button-style width-100",
                          attrs: {
                            loading: _vm.processingEmailEntry,
                            disabled: _vm.processing,
                            type: "submit",
                            depressed: "",
                            "x-large": ""
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("common.next")))]
                      ),
                      _vm._v(" "),
                      _vm.allowSelfSignUp
                        ? _c(
                            "v-row",
                            {
                              class: {
                                "pt-7": !_vm.tabletMobileDevice,
                                "pt-3": _vm.tabletMobileDevice
                              }
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    disabled: _vm.processing,
                                    text: "",
                                    small: "",
                                    to: "/signup/personal"
                                  }
                                },
                                [_vm._v(_vm._s(_vm.$t("login.sign-up-link")))]
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              (_vm.smallishMobileDevice || _vm.smallWidthBrowser) &&
              !_vm.landscapeOrientation
                ? _c(
                    "div",
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass:
                            "marketing-main-color-button-style width-100",
                          attrs: {
                            loading: _vm.processingEmailEntry,
                            disabled: _vm.processing,
                            type: "submit",
                            depressed: ""
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("common.next")))]
                      ),
                      _vm._v(" "),
                      false
                        ? _c(
                            "v-btn",
                            {
                              staticClass:
                                "marketing-main-color-outlined-button-style mt-4 width-100",
                              attrs: {
                                loading: _vm.processingFederatedIdentityRequest,
                                disabled: _vm.processing,
                                depressed: "",
                                outlined: ""
                              },
                              on: { click: _vm.typeYourPassword }
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("login.use-federated-identity"))
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        { staticClass: "pt-6", attrs: { justify: "end" } },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "pl-0 pr-0 pt-0",
                              attrs: { cols: "6" }
                            },
                            [
                              _c(
                                "v-row",
                                { staticClass: "pl-1 pt-0" },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pb-0 pt-0",
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            disabled: _vm.processing,
                                            text: "",
                                            small: ""
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.setPrivacyDialog(
                                                !_vm.dialogPrivacy
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("privacy.link-label"))
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pt-1",
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            disabled: _vm.processing,
                                            loading:
                                              _vm.processingForgotPasswordRequest,
                                            text: "",
                                            small: ""
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.setTermsDialog(
                                                !_vm.dialogTerms
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("terms.link-label"))
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { staticClass: "pt-0", attrs: { cols: "6" } },
                            [
                              _c(
                                "v-row",
                                {
                                  staticClass: "pr-3",
                                  attrs: { justify: "end" }
                                },
                                [
                                  _c("v-img", {
                                    attrs: {
                                      src: _vm.logoSource,
                                      "max-height": _vm.brandImageHeight,
                                      height: _vm.brandImageHeight,
                                      "max-width": _vm.brandImageWidth,
                                      width: _vm.brandImageWidth
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.allowSelfSignUp
                        ? _c(
                            "v-row",
                            {
                              class: {
                                "pt-7": !_vm.tabletMobileDevice,
                                "pt-3": _vm.tabletMobileDevice
                              }
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    disabled: _vm.processing,
                                    text: "",
                                    small: "",
                                    to: "/signup/personal"
                                  }
                                },
                                [_vm._v(_vm._s(_vm.$t("login.sign-up-link")))]
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.smallishMobileDevice && _vm.landscapeOrientation
                ? _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "pt-0", attrs: { cols: "7" } },
                        [
                          _c(
                            "v-btn",
                            {
                              class: [
                                "marketing-main-color-button-style",
                                "width-100"
                              ],
                              attrs: {
                                loading: _vm.processingEmailEntry,
                                disabled: _vm.processing,
                                type: "submit",
                                depressed: "",
                                large: "",
                                "x-large": !_vm.smallishMobileDevice
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("common.next")))]
                          ),
                          _vm._v(" "),
                          false
                            ? _c(
                                "v-btn",
                                {
                                  staticClass:
                                    "marketing-main-color-outlined-button-style mt-3 width-100",
                                  attrs: {
                                    loading:
                                      _vm.processingFederatedIdentityRequest,
                                    disabled: _vm.processing,
                                    depressed: "",
                                    outlined: "",
                                    large: ""
                                  },
                                  on: { click: _vm.typeYourPassword }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("login.use-federated-identity")
                                    )
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { staticClass: "pt-0", attrs: { cols: "5" } },
                        [
                          _c(
                            "v-row",
                            {
                              staticClass: "height-100",
                              attrs: { justify: "center", align: "start" }
                            },
                            [
                              _c("v-img", {
                                attrs: {
                                  src: _vm.logoSource,
                                  "max-height": _vm.brandImageHeight,
                                  height: _vm.brandImageHeight,
                                  "max-width": _vm.brandImageWidth,
                                  width: _vm.brandImageWidth
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.allowSelfSignUp &&
              _vm.smallishMobileDevice &&
              _vm.landscapeOrientation
                ? _c(
                    "v-row",
                    {
                      class: [
                        {
                          "pt-7": !_vm.tabletMobileDevice,
                          "pt-3": _vm.tabletMobileDevice
                        },
                        "d-flex",
                        "flex-row-reverse"
                      ]
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            disabled: _vm.processing,
                            text: "",
                            small: "",
                            to: "/signup/personal"
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("login.sign-up-link")))]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          class: [
            {
              "password-entry-show-transition": _vm.showPasswordEntry,
              "disappear-nicely": !_vm.showPasswordEntry,
              "login-screen-floating-placement-mobile-override":
                _vm.smallishMobileDevice || _vm.smallWidthBrowser
            },
            "login-screen-floating-placement",
            "sign-in-controls-container"
          ],
          staticStyle: { opacity: "0", "pointer-events": "none" }
        },
        [
          _c(
            "v-form",
            { ref: "enterPasswordForm", on: { submit: _vm.signin } },
            [
              _vm.inlineMessage.message &&
              !_vm.smallishMobileDevice &&
              !_vm.smallWidthBrowser
                ? _c(
                    "v-alert",
                    {
                      staticClass: "mt-0 mb-5 ml-0 mr-0",
                      attrs: { type: _vm.inlineMessage.type }
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.inlineMessage.message) +
                          "\n      "
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.inlineMessage.message &&
              (_vm.smallishMobileDevice || _vm.smallWidthBrowser)
                ? _c(
                    "v-alert",
                    {
                      staticClass: "mt-0 mb-2 ml-0 mr-0",
                      attrs: { dense: "", type: _vm.inlineMessage.type }
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.inlineMessage.message) +
                          "\n      "
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticStyle: { "padding-top": "15px" } }, [
                _c(
                  "span",
                  {
                    staticStyle: { "font-weight": "Bold", "font-size": "1.0em" }
                  },
                  [_vm._v(_vm._s(_vm.emailAddressOrPhoneNumber))]
                )
              ]),
              _vm._v(" "),
              _c("v-text-field", {
                staticStyle: { display: "none" },
                attrs: {
                  type: "email",
                  autocomplete: "username",
                  value: _vm.emailAddressOrPhoneNumber,
                  disabled: "",
                  hidden: ""
                }
              }),
              _vm._v(" "),
              _c("v-text-field", {
                ref: "passwordinput",
                staticClass: "mt-3",
                attrs: {
                  label: _vm.$t("login.password-label"),
                  "data-cy": "password",
                  "append-icon": _vm.showPassword ? "mdi-eye" : "mdi-eye-off",
                  type: _vm.showPassword ? "text" : "password",
                  autocomplete: "current-password",
                  outlined: "",
                  dense: _vm.smallishMobileDevice || _vm.smallWidthBrowser,
                  disabled: _vm.processing || !_vm.showPasswordEntry,
                  rules: [_vm.rules.required]
                },
                on: {
                  "click:append": function($event) {
                    _vm.showPassword = !_vm.showPassword
                  }
                },
                model: {
                  value: _vm.password,
                  callback: function($$v) {
                    _vm.password = $$v
                  },
                  expression: "password"
                }
              }),
              _vm._v(" "),
              !_vm.landscapeOrientation
                ? _c(
                    "div",
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass:
                            "marketing-main-color-button-style width-100",
                          attrs: {
                            disabled: _vm.processing,
                            loading: _vm.processingSignInRequest,
                            type: "submit",
                            depressed: "",
                            "x-large":
                              !_vm.smallishMobileDevice &&
                              !_vm.smallWidthBrowser,
                            dense:
                              _vm.smallishMobileDevice || _vm.smallWidthBrowser
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("login.sign-in-label")))]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          staticClass:
                            "marketing-main-color-outlined-button-style mt-4 width-100",
                          attrs: {
                            loading: _vm.processingAccessCodeRequest,
                            disabled:
                              _vm.processing ||
                              !_vm.loginChallenge ||
                              !_vm.loginChallenge.canReceiveAccessCodes,
                            depressed: "",
                            outlined: ""
                          },
                          on: {
                            click: function($event) {
                              return _vm.sendAccessCode(false)
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("login.get-quick-access-code")))]
                      ),
                      _vm._v(" "),
                      !!_vm.loginChallenge &&
                      !_vm.loginChallenge.canReceiveAccessCodes
                        ? _c(
                            "v-alert",
                            { staticClass: "mt-3", attrs: { type: "info" } },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    _vm.$t(
                                      "access-code-prompt.phone-number-unsubscribed-explanation",
                                      [
                                        _vm.formatPhoneNumberForDisplay(
                                          _vm.smsAccessCodePhoneNumber
                                        ),
                                        _vm.formatPhoneNumberForDisplay(
                                          _vm.emailAddressOrPhoneNumber
                                        )
                                      ]
                                    )
                                  ) +
                                  "\n        "
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.landscapeOrientation
                ? _c(
                    "v-row",
                    { staticClass: "pt-6", attrs: { justify: "end" } },
                    [
                      _c(
                        "v-col",
                        { staticClass: "pl-0 pr-0 pt-0", attrs: { cols: "6" } },
                        [
                          _c(
                            "v-row",
                            { staticClass: "pl-1" },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "pb-0 pt-0",
                                  attrs: { cols: "12" }
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        disabled: _vm.processing,
                                        text: "",
                                        small: ""
                                      },
                                      on: {
                                        click: _vm.startOverFromTypePassword
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("common.start-over"))
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { staticClass: "pt-1", attrs: { cols: "12" } },
                                [
                                  _vm.smallishMobileDevice &&
                                  _vm.smallWidthBrowser
                                    ? _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            disabled: _vm.processing,
                                            loading:
                                              _vm.processingForgotPasswordRequest,
                                            text: "",
                                            small: ""
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.sendAccessCode(true)
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "login.forgot-password-label-short"
                                              )
                                            )
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  !_vm.smallishMobileDevice &&
                                  !_vm.smallWidthBrowser
                                    ? _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            disabled: _vm.processing,
                                            loading:
                                              _vm.processingForgotPasswordRequest,
                                            text: "",
                                            small: ""
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.sendAccessCode(true)
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "login.forgot-password-label"
                                              )
                                            )
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { staticClass: "pt-0", attrs: { cols: "6" } },
                        [
                          _vm.smallishMobileDevice || _vm.smallWidthBrowser
                            ? _c(
                                "v-row",
                                {
                                  staticClass: "pr-3",
                                  attrs: { justify: "end" }
                                },
                                [
                                  _c("v-img", {
                                    attrs: {
                                      src: _vm.logoSource,
                                      "max-height": _vm.brandImageHeight,
                                      height: _vm.brandImageHeight,
                                      "max-width": _vm.brandImageWidth,
                                      width: _vm.brandImageWidth
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.smallishMobileDevice && _vm.landscapeOrientation
                ? _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "pt-0", attrs: { cols: "7" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass:
                                "marketing-main-color-button-style width-100",
                              attrs: {
                                disabled: _vm.processing,
                                loading: _vm.processingSignInRequest,
                                type: "submit",
                                depressed: "",
                                dense: ""
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("login.sign-in-label")))]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              staticClass:
                                "marketing-main-color-outlined-button-style mt-2 width-100",
                              attrs: {
                                disabled: _vm.processing,
                                loading: _vm.processingAccessCodeRequest,
                                depressed: "",
                                dense: "",
                                outlined: ""
                              },
                              on: {
                                click: function($event) {
                                  return _vm.sendAccessCode(false)
                                }
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("login.get-quick-access-code"))
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            { staticClass: "mt-1" },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    disabled: _vm.processing,
                                    text: "",
                                    small: ""
                                  },
                                  on: { click: _vm.startOverFromTypePassword }
                                },
                                [_vm._v(_vm._s(_vm.$t("common.start-over")))]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "pl-5",
                                  attrs: {
                                    disabled: _vm.processing,
                                    loading:
                                      _vm.processingForgotPasswordRequest,
                                    text: "",
                                    small: ""
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.sendAccessCode(true)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("login.forgot-password-label")
                                    )
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { staticClass: "pt-0", attrs: { cols: "5" } },
                        [
                          _c(
                            "v-row",
                            {
                              staticStyle: { height: "100%" },
                              attrs: { justify: "center", align: "start" }
                            },
                            [
                              _c("v-img", {
                                attrs: {
                                  src: _vm.logoSource,
                                  "max-height": _vm.brandImageHeight,
                                  height: _vm.brandImageHeight,
                                  "max-width": _vm.brandImageWidth,
                                  width: _vm.brandImageWidth
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "wedge-cover hide-when-medium" }),
      _vm._v(" "),
      !_vm.smallishMobileDevice && !_vm.smallWidthBrowser
        ? _c("div", { staticClass: "sign-in-accent-panel" })
        : _vm._e(),
      _vm._v(" "),
      !_vm.smallishMobileDevice && !_vm.smallWidthBrowser
        ? _c(
            "div",
            {
              staticClass: "sign-in-accent-panel-logo-placement",
              style: "top:" + _vm.logobrandImageTopValue + "px;"
            },
            [
              _c(
                "v-row",
                { attrs: { align: "end", justify: "end" } },
                [
                  _c(
                    "v-menu",
                    {
                      attrs: {
                        top: "",
                        right: "",
                        "offset-y": "",
                        origin: "bottom left",
                        transition: "scale-transition",
                        disabled: _vm.processing
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function({ attrs, on }) {
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    _vm._b(
                                      { attrs: { "min-width": "0", text: "" } },
                                      "v-btn",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        staticClass:
                                          "login-panel-language-button-style",
                                        attrs: { color: "white" }
                                      },
                                      [_vm._v("mdi-translate")]
                                    )
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        770119138
                      )
                    },
                    [
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "appbar-menu" },
                        [
                          _c(
                            "v-list",
                            { attrs: { tile: false, nav: "" } },
                            [
                              _c(
                                "v-list-item-group",
                                {
                                  model: {
                                    value: _vm.language,
                                    callback: function($$v) {
                                      _vm.language = $$v
                                    },
                                    expression: "language"
                                  }
                                },
                                _vm._l(_vm.languageslist, function(
                                  languagechoice
                                ) {
                                  return _c(
                                    "v-list-item",
                                    { key: languagechoice.name },
                                    [
                                      _c(
                                        "v-list-item-content",
                                        [
                                          _c("v-list-item-title", [
                                            _vm._v(_vm._s(languagechoice.name))
                                          ])
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-list-item-action",
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              class: [
                                                _vm.language ==
                                                languagechoice.number
                                                  ? "selected-menu-icon-color"
                                                  : "unselected-menu-icon-color"
                                              ]
                                            },
                                            [_vm._v("fas fa-language")]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      staticClass:
                        "login-panel-full-size-privacy-terms-button-style",
                      attrs: { text: "" },
                      on: {
                        click: function($event) {
                          return _vm.setPrivacyDialog(!_vm.dialogPrivacy)
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("privacy.link-label")) +
                          "\n      "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      staticClass:
                        "mr-5 login-panel-full-size-privacy-terms-button-style",
                      attrs: { text: "" },
                      on: {
                        click: function($event) {
                          return _vm.setTermsDialog(!_vm.dialogTerms)
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("terms.link-label")) +
                          "\n      "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("v-img", {
                    attrs: {
                      src: _vm.logoReversedSource,
                      "max-height": _vm.brandImageHeight,
                      height: _vm.brandImageHeight,
                      "max-width": _vm.brandImageWidth,
                      width: _vm.brandImageWidth
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }