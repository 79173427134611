var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { fluid: "", "data-cy": "materialOrderexisting" } },
    [
      _c(
        "v-form",
        { ref: "form", on: { submit: _vm.onSubmit } },
        [
          _c(
            "v-slide-x-reverse-transition",
            [
              _c(
                "v-card",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.slidein,
                      expression: "slidein"
                    }
                  ]
                },
                [
                  _c(
                    "v-toolbar",
                    {
                      class: [
                        _vm.processing
                          ? "view-card-top-color-processing"
                          : "view-card-top-color"
                      ]
                    },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c(
                                "v-row",
                                { attrs: { align: "center" } },
                                [
                                  _c("v-breadcrumbs", {
                                    staticClass: "d-none d-sm-flex",
                                    class: [
                                      _vm.processing
                                        ? "breadcrumb-processing-opacity"
                                        : "",
                                      "pl-3"
                                    ],
                                    attrs: {
                                      items:
                                        _vm.$store.state.currentBreadcrumbs,
                                      large: ""
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "divider",
                                        fn: function() {
                                          return [
                                            _c("v-icon", [
                                              _vm._v("fa-chevron-right")
                                            ])
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ])
                                  }),
                                  _vm._v(" "),
                                  _c("fd-back-button", {
                                    attrs: {
                                      route: item =>
                                        _vm.$router.push(
                                          _vm.$store.getters.backBreadcrumb
                                            .to || `/materialorders/`
                                        )
                                    }
                                  }),
                                  _vm._v(" "),
                                  !!_vm.materialOrder.id
                                    ? _c(
                                        "div",
                                        {
                                          class: [
                                            {
                                              "ml-2":
                                                _vm.$vuetify.breakpoint.xsOnly
                                            },
                                            "py-1",
                                            "px-3",
                                            "fd-status-indicator"
                                          ]
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.$t(
                                                  `material-orders.statuses.${_vm.materialOrder.orderStatusID}`
                                                )
                                              ) +
                                              "\n                "
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("v-spacer"),
                                  _vm._v(" "),
                                  _vm.processing
                                    ? _c("v-progress-circular", {
                                        staticClass: "mr-3",
                                        attrs: {
                                          indeterminate: true,
                                          rotate: 0,
                                          size: 32,
                                          width: 4,
                                          color: "white"
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-container",
                    { staticClass: "pb-0 px-0", attrs: { fluid: "" } },
                    [
                      _c(
                        "v-tabs",
                        {
                          ref: "tabs",
                          attrs: {
                            "icons-and-text": "",
                            "show-arrows": _vm.$vuetify.breakpoint.smAndUp,
                            "next-icon": "fa-arrow-circle-right",
                            "prev-icon": "fa-arrow-circle-left"
                          },
                          model: {
                            value: _vm.active_tab,
                            callback: function($$v) {
                              _vm.active_tab = $$v
                            },
                            expression: "active_tab"
                          }
                        },
                        [
                          _c(
                            "v-tab",
                            {
                              key: _vm.detailsTab.key,
                              ref: "tab",
                              attrs: { href: `#tab-${_vm.detailsTab.key}` },
                              on: {
                                click: function($event) {
                                  return _vm.tabSelected(_vm.detailsTab)
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.detailsTab.tabname) +
                                  "\n              "
                              ),
                              _c("v-icon", [_vm._v("fas fa-edit")])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-tab",
                            {
                              key: _vm.transfersTab.key,
                              ref: "tab",
                              attrs: { href: `#tab-${_vm.transfersTab.key}` },
                              on: {
                                click: function($event) {
                                  return _vm.tabSelected(_vm.transfersTab)
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.transfersTab.tabname) +
                                  "\n              "
                              ),
                              _c("v-icon", [_vm._v("fas fa-exchange")])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-tabs-items",
                        {
                          model: {
                            value: _vm.active_tab,
                            callback: function($$v) {
                              _vm.active_tab = $$v
                            },
                            expression: "active_tab"
                          }
                        },
                        [
                          _c(
                            "v-tab-item",
                            {
                              key: _vm.detailsTab.key,
                              attrs: { value: `tab-${_vm.detailsTab.key}` }
                            },
                            [
                              _vm.orderIsDraft
                                ? _c("fd-alert", {
                                    staticClass: "mx-3",
                                    attrs: {
                                      type: "info",
                                      label: _vm.$t(
                                        "material-orders.existing.draft-explanation-message"
                                      )
                                    }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.orderIsApproved
                                ? _c("fd-alert", {
                                    staticClass: "mx-3",
                                    attrs: {
                                      type: "info",
                                      label: _vm.$t(
                                        "material-orders.existing.approved-explanation-message"
                                      )
                                    }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.orderIsDeclined
                                ? _c("fd-alert", {
                                    staticClass: "mx-3",
                                    attrs: {
                                      type: "error",
                                      label: _vm.orderHasDeclineReason
                                        ? _vm.$t(
                                            "material-orders.existing.decline-message-extended"
                                          )
                                        : _vm.$t(
                                            "material-orders.existing.decline-message"
                                          ),
                                      comments: _vm.orderHasDeclineReason
                                        ? _vm.materialOrder.reviewDeclineReason
                                        : ""
                                    }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.orderIsPartiallyFilled
                                ? _c("fd-alert", {
                                    staticClass: "mx-3",
                                    attrs: {
                                      type: "info",
                                      label: _vm.$t(
                                        "material-orders.existing.partially-fulfilled-explanation-message"
                                      )
                                    }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.orderIsFilled
                                ? _c("fd-alert", {
                                    staticClass: "mx-3",
                                    attrs: {
                                      type: "info",
                                      label: _vm.$t(
                                        "material-orders.existing.fulfilled-explanation-message"
                                      )
                                    }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.orderIsCancelled
                                ? _c("fd-alert", {
                                    staticClass: "mx-3",
                                    attrs: {
                                      type: "info",
                                      label: _vm.$t(
                                        "material-orders.existing.cancelled-explanation-message"
                                      )
                                    }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.orderIsCancelled
                                ? _c("fd-alert", {
                                    staticClass: "mx-3",
                                    attrs: {
                                      type: "error",
                                      label: _vm.orderHasCancelReason
                                        ? _vm.$t(
                                            "material-orders.existing.cancellation-message-extended"
                                          )
                                        : _vm.$t(
                                            "material-orders.existing.cancellation-message"
                                          ),
                                      comments: _vm.orderHasCancelReason
                                        ? _vm.materialOrder.cancelReason
                                        : ""
                                    }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.orderIsAbandoned
                                ? _c("fd-alert", {
                                    staticClass: "mx-3",
                                    attrs: {
                                      type: "info",
                                      label: _vm.$t(
                                        "material-orders.existing.abandoned-explanation-message"
                                      )
                                    }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.orderIsAbandoned
                                ? _c("fd-alert", {
                                    staticClass: "mx-3",
                                    attrs: {
                                      type: "error",
                                      label: _vm.orderHasCancelReason
                                        ? _vm.$t(
                                            "material-orders.existing.abandon-message-extended"
                                          )
                                        : _vm.$t(
                                            "material-orders.existing.abandon-message"
                                          ),
                                      comments: _vm.orderHasCancelReason
                                        ? _vm.materialOrder.cancelReason
                                        : ""
                                    }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "v-row",
                                {
                                  staticClass: "mx-0 pt-1",
                                  attrs: { align: "center" }
                                },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "justify-space-between pt-5 pb-1",
                                      attrs: {
                                        align: "center",
                                        cols: "12",
                                        sm: "6"
                                      }
                                    },
                                    [
                                      !_vm.canEdit
                                        ? _c("fd-text-field", {
                                            staticClass: "fd-remove-messages",
                                            attrs: {
                                              dense: "",
                                              label: _vm.$t(
                                                "material-orders.order-date-label"
                                              ),
                                              value:
                                                _vm.materialOrder
                                                  .formattedOrderDate,
                                              disabled: _vm.processing,
                                              readonly: true
                                            }
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.canEdit
                                        ? _c("fd-date-picker", {
                                            staticClass: "fd-remove-messages",
                                            attrs: {
                                              label: _vm.$t(
                                                "material-orders.order-date-label"
                                              ),
                                              disabled: _vm.processing,
                                              rules: [_vm.rules.required],
                                              "hide-details": ""
                                            },
                                            model: {
                                              value:
                                                _vm.materialOrder.orderDate,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.materialOrder,
                                                  "orderDate",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "materialOrder.orderDate"
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "justify-space-between pt-5 pb-1",
                                      attrs: {
                                        align: "center",
                                        cols: "12",
                                        sm: "6"
                                      }
                                    },
                                    [
                                      !_vm.canEdit
                                        ? _c("fd-text-field", {
                                            staticClass: "fd-remove-messages",
                                            attrs: {
                                              dense: "",
                                              label: _vm.$t(
                                                "material-orders.expected-date-label"
                                              ),
                                              value:
                                                _vm.materialOrder
                                                  .formattedExpectedDate,
                                              disabled: _vm.processing,
                                              readonly: true
                                            }
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.canEdit
                                        ? _c("fd-date-picker", {
                                            staticClass: "fd-remove-messages",
                                            attrs: {
                                              label: _vm.$t(
                                                "material-orders.expected-date-label"
                                              ),
                                              disabled: _vm.processing,
                                              "hide-details": ""
                                            },
                                            model: {
                                              value:
                                                _vm.materialOrder.expectedDate,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.materialOrder,
                                                  "expectedDate",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "materialOrder.expectedDate"
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "justify-space-between pt-5 pb-1",
                                      attrs: {
                                        align: "center",
                                        cols: "12",
                                        sm: "6"
                                      }
                                    },
                                    [
                                      _c("fd-text-field", {
                                        staticClass: "fd-remove-messages",
                                        attrs: {
                                          dense: !_vm.canEdit,
                                          label: _vm.$t(
                                            "material-orders.reference-number-label"
                                          ),
                                          disabled: _vm.processing,
                                          readonly: !_vm.canEdit,
                                          "hide-details": ""
                                        },
                                        model: {
                                          value:
                                            _vm.materialOrder.referenceNumber,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.materialOrder,
                                              "referenceNumber",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "materialOrder.referenceNumber"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "justify-space-between pt-5 pb-1",
                                      attrs: {
                                        align: "center",
                                        cols: "12",
                                        sm: "6"
                                      }
                                    },
                                    [
                                      _vm.directionIsMaterialRequest
                                        ? _c("fd-text-field", {
                                            class: {
                                              "fd-remove-messages": true
                                            },
                                            attrs: {
                                              dense: !_vm.canEdit,
                                              label: _vm.$t(
                                                "material-orders.from-supplier-label"
                                              ),
                                              value: _vm.$lookup.supplier(
                                                _vm.materialOrder.fromSupplierID
                                              ),
                                              disabled: _vm.processing,
                                              readonly: true
                                            }
                                          })
                                        : _vm.directionIsMaterialReturn
                                        ? _c("fd-text-field", {
                                            class: {
                                              "fd-remove-messages": true
                                            },
                                            attrs: {
                                              dense: !_vm.canEdit,
                                              label: _vm.$t(
                                                "material-orders.to-supplier-label"
                                              ),
                                              value: _vm.$lookup.supplier(
                                                _vm.materialOrder.toSupplierID
                                              ),
                                              disabled: _vm.processing,
                                              readonly: true
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "justify-space-between pt-5 px-3",
                                      attrs: { align: "center", cols: "12" }
                                    },
                                    [
                                      _c("fd-textarea", {
                                        attrs: {
                                          outlined: "",
                                          dense: !_vm.canEdit,
                                          readonly: !_vm.canEdit,
                                          cy: "contactperson",
                                          label: _vm.$t(
                                            "material-orders.contact-person-label"
                                          ),
                                          disabled: _vm.processing,
                                          rows: "1",
                                          "auto-grow": "",
                                          "hide-details": ""
                                        },
                                        model: {
                                          value:
                                            _vm.materialOrder.contactPerson,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.materialOrder,
                                              "contactPerson",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "materialOrder.contactPerson"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "justify-space-between pt-5 px-3",
                                      attrs: { align: "center", cols: "12" }
                                    },
                                    [
                                      _c("fd-textarea", {
                                        attrs: {
                                          outlined: "",
                                          dense: !_vm.canEdit,
                                          readonly: !_vm.canEdit,
                                          cy: "instructions",
                                          label: _vm.$t(
                                            "material-orders.instructions-label"
                                          ),
                                          disabled: _vm.processing,
                                          rows: "1",
                                          "auto-grow": "",
                                          "hide-details": ""
                                        },
                                        model: {
                                          value: _vm.materialOrder.instructions,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.materialOrder,
                                              "instructions",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "materialOrder.instructions"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-expansion-panels",
                                {
                                  staticStyle: { "border-radius": "0" },
                                  attrs: { multiple: "", accordion: "" },
                                  model: {
                                    value: _vm.openPanels,
                                    callback: function($$v) {
                                      _vm.openPanels = $$v
                                    },
                                    expression: "openPanels"
                                  }
                                },
                                [
                                  _c(
                                    "v-expansion-panel",
                                    {
                                      staticClass:
                                        "fd-count-sheet-group-panel-summary"
                                    },
                                    [
                                      _c(
                                        "v-expansion-panel-header",
                                        {
                                          staticStyle: {
                                            "padding-left": "24px !important"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                _vm.$t(
                                                  "countsheet.dialog.summary-panel-title"
                                                )
                                              )
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-expansion-panel-content",
                                        [
                                          !_vm.partsForSummary ||
                                          _vm.partsForSummary.length == 0
                                            ? _c(
                                                "v-row",
                                                { attrs: { "no-gutters": "" } },
                                                [
                                                  _c(
                                                    "v-col",
                                                    { attrs: { cols: "12" } },
                                                    [
                                                      _c(
                                                        "v-row",
                                                        {
                                                          staticStyle: {
                                                            "justify-content":
                                                              "center"
                                                          },
                                                          attrs: {
                                                            "no-gutters": ""
                                                          }
                                                        },
                                                        [
                                                          _c("fd-alert", {
                                                            attrs: {
                                                              label: _vm.$t(
                                                                "material-orders.summary-has-no-parts-message"
                                                              ),
                                                              type: "hint"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm._l(_vm.partsForSummary, function(
                                            part
                                          ) {
                                            return _c(
                                              "v-row",
                                              {
                                                key: part.id,
                                                staticClass: "fd-part-row",
                                                class: {
                                                  "fd-overridden-part":
                                                    part.overridden
                                                },
                                                attrs: { "no-gutters": "" }
                                              },
                                              [
                                                _c(
                                                  "v-row",
                                                  {
                                                    staticClass:
                                                      "fd-group-part-content"
                                                  },
                                                  [
                                                    _c(
                                                      "v-col",
                                                      {
                                                        staticClass: "py-1",
                                                        attrs: { cols: "8" }
                                                      },
                                                      [
                                                        _c(
                                                          "v-row",
                                                          {
                                                            attrs: {
                                                              "no-gutters": ""
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "12"
                                                                }
                                                              },
                                                              [
                                                                part.overridden
                                                                  ? _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "fd-count-sheet-part-status-badge pr-2",
                                                                        staticStyle: {
                                                                          display:
                                                                            "table-cell"
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "fd-progress-badge",
                                                                          {
                                                                            attrs: {
                                                                              edited:
                                                                                part.overridden
                                                                            }
                                                                          }
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  : _vm._e(),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticStyle: {
                                                                      display:
                                                                        "table-cell"
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "fd-count-sheet-part-code-label"
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                                  " +
                                                                            _vm._s(
                                                                              part.publicID
                                                                            ) +
                                                                            "\n                                "
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "fd-count-sheet-part-description-label"
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                                  " +
                                                                            _vm._s(
                                                                              part.description
                                                                            ) +
                                                                            "\n                                "
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _vm._v(" "),
                                                                    !!part.groupName
                                                                      ? _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "fd-count-sheet-part-group-label"
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                                  " +
                                                                                _vm._s(
                                                                                  part.groupName.toUpperCase()
                                                                                ) +
                                                                                "\n                                "
                                                                            )
                                                                          ]
                                                                        )
                                                                      : _vm._e()
                                                                  ]
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "v-col",
                                                      {
                                                        staticClass:
                                                          "pl-0 py-1",
                                                        attrs: { cols: "4" }
                                                      },
                                                      [
                                                        _c(
                                                          "v-row",
                                                          {
                                                            attrs: {
                                                              "no-gutters": ""
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "v-col",
                                                              {
                                                                staticClass:
                                                                  "pl-1",
                                                                attrs: {
                                                                  cols: "4"
                                                                }
                                                              },
                                                              [
                                                                !part.overridden
                                                                  ? _c(
                                                                      "fd-text-field",
                                                                      {
                                                                        staticClass:
                                                                          "fd-count-sheet-text-field",
                                                                        attrs: {
                                                                          "data-cy":
                                                                            "summarycount",
                                                                          dense:
                                                                            "",
                                                                          "hide-details":
                                                                            "",
                                                                          disabled:
                                                                            _vm.processing,
                                                                          readonly: !_vm.canEdit,
                                                                          label: _vm.$t(
                                                                            "material-orders.count-label"
                                                                          ),
                                                                          numeric:
                                                                            "",
                                                                          "highlight-on-focus":
                                                                            ""
                                                                        },
                                                                        on: {
                                                                          change: function(
                                                                            $event
                                                                          ) {
                                                                            return _vm.partCountValueChanged(
                                                                              part
                                                                            )
                                                                          }
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            part.count,
                                                                          callback: function(
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              part,
                                                                              "count",
                                                                              $$v
                                                                            )
                                                                          },
                                                                          expression:
                                                                            "part.count"
                                                                        }
                                                                      }
                                                                    )
                                                                  : _vm._e(),
                                                                _vm._v(" "),
                                                                part.overridden
                                                                  ? _c(
                                                                      "fd-text-field",
                                                                      {
                                                                        staticClass:
                                                                          "fd-count-sheet-overridden-text-field",
                                                                        attrs: {
                                                                          "data-cy":
                                                                            "summarycountoverride",
                                                                          dense:
                                                                            "",
                                                                          hint: _vm.$t(
                                                                            "material-orders.original-value",
                                                                            [
                                                                              part.originalCount
                                                                            ]
                                                                          ),
                                                                          "persistent-hint":
                                                                            "",
                                                                          disabled:
                                                                            _vm.processing,
                                                                          readonly: !_vm.canEdit,
                                                                          label: _vm.$t(
                                                                            "material-orders.count-override-label"
                                                                          ),
                                                                          numeric:
                                                                            "",
                                                                          "highlight-on-focus":
                                                                            ""
                                                                        },
                                                                        on: {
                                                                          change: function(
                                                                            $event
                                                                          ) {
                                                                            return _vm.partCountValueChanged(
                                                                              part
                                                                            )
                                                                          }
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            part.count,
                                                                          callback: function(
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              part,
                                                                              "count",
                                                                              $$v
                                                                            )
                                                                          },
                                                                          expression:
                                                                            "part.count"
                                                                        }
                                                                      }
                                                                    )
                                                                  : _vm._e()
                                                              ],
                                                              1
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                staticClass:
                                                                  "pl-1",
                                                                attrs: {
                                                                  cols: "4"
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "fd-text-field",
                                                                  {
                                                                    staticClass:
                                                                      "fd-count-sheet-text-field",
                                                                    attrs: {
                                                                      "data-cy":
                                                                        "summaryassigned",
                                                                      dense: "",
                                                                      "hide-details":
                                                                        "",
                                                                      disabled:
                                                                        _vm.processing,
                                                                      readonly: true,
                                                                      label: _vm.$t(
                                                                        "material-orders.assigned-label"
                                                                      ),
                                                                      numeric:
                                                                        "",
                                                                      "highlight-on-focus":
                                                                        ""
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        part.assigned,
                                                                      callback: function(
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          part,
                                                                          "assigned",
                                                                          $$v
                                                                        )
                                                                      },
                                                                      expression:
                                                                        "part.assigned"
                                                                    }
                                                                  }
                                                                )
                                                              ],
                                                              1
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                staticClass:
                                                                  "pl-1",
                                                                attrs: {
                                                                  cols: "4"
                                                                }
                                                              },
                                                              [
                                                                !part.overridden
                                                                  ? _c(
                                                                      "fd-text-field",
                                                                      {
                                                                        staticClass:
                                                                          "fd-count-sheet-text-field",
                                                                        attrs: {
                                                                          "data-cy":
                                                                            "summarytotal",
                                                                          value:
                                                                            part.total,
                                                                          dense:
                                                                            "",
                                                                          "hide-details":
                                                                            "",
                                                                          disabled:
                                                                            _vm.processing,
                                                                          readonly: true,
                                                                          label: _vm.$t(
                                                                            "material-orders.total-label"
                                                                          ),
                                                                          numeric:
                                                                            "",
                                                                          "highlight-on-focus":
                                                                            ""
                                                                        }
                                                                      }
                                                                    )
                                                                  : _vm._e(),
                                                                _vm._v(" "),
                                                                part.overridden
                                                                  ? _c(
                                                                      "fd-text-field",
                                                                      {
                                                                        staticClass:
                                                                          "fd-count-sheet-text-field",
                                                                        attrs: {
                                                                          dense:
                                                                            "",
                                                                          value:
                                                                            part.total,
                                                                          hint: _vm.$t(
                                                                            "material-orders.original-value",
                                                                            [
                                                                              part.originalTotal
                                                                            ]
                                                                          ),
                                                                          "persistent-hint":
                                                                            "",
                                                                          label: _vm.$t(
                                                                            "material-orders.total-override-label"
                                                                          ),
                                                                          readonly: true
                                                                        }
                                                                      }
                                                                    )
                                                                  : _vm._e()
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          })
                                        ],
                                        2
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _vm._l(_vm.visibleCountSheetGroups, function(
                                    group,
                                    index
                                  ) {
                                    return _c(
                                      "v-expansion-panel",
                                      {
                                        key: index,
                                        staticClass:
                                          "fd-count-sheet-group-panel"
                                      },
                                      [
                                        _c("v-expansion-panel-header", [
                                          _vm._v(" " + _vm._s(group.name))
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "v-expansion-panel-content",
                                          [
                                            !group.parts ||
                                            group.parts.length == 0
                                              ? _c(
                                                  "v-row",
                                                  {
                                                    attrs: { "no-gutters": "" }
                                                  },
                                                  [
                                                    _c(
                                                      "v-col",
                                                      { attrs: { cols: "12" } },
                                                      [
                                                        _c(
                                                          "v-row",
                                                          {
                                                            staticStyle: {
                                                              "justify-content":
                                                                "center"
                                                            },
                                                            attrs: {
                                                              "no-gutters": ""
                                                            }
                                                          },
                                                          [
                                                            _c("fd-alert", {
                                                              attrs: {
                                                                label: _vm.$t(
                                                                  "countsheet.dialog.group-has-no-parts-message"
                                                                ),
                                                                type: "hint"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _vm._l(group.sortedParts, function(
                                              part
                                            ) {
                                              return _c(
                                                "v-row",
                                                {
                                                  key: part.id,
                                                  staticClass: "fd-part-row",
                                                  attrs: { "no-gutters": "" }
                                                },
                                                [
                                                  _c(
                                                    "v-row",
                                                    {
                                                      staticClass:
                                                        "fd-group-part-content"
                                                    },
                                                    [
                                                      _c(
                                                        "v-col",
                                                        {
                                                          staticClass: "py-1",
                                                          attrs: { cols: "8" }
                                                        },
                                                        [
                                                          _c(
                                                            "v-row",
                                                            {
                                                              attrs: {
                                                                "no-gutters": ""
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-col",
                                                                {
                                                                  attrs: {
                                                                    cols: "12"
                                                                  }
                                                                },
                                                                [
                                                                  part.overridden
                                                                    ? _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "fd-count-sheet-part-status-badge pr-2",
                                                                          staticStyle: {
                                                                            display:
                                                                              "table-cell"
                                                                          }
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "fd-progress-badge",
                                                                            {
                                                                              attrs: {
                                                                                edited:
                                                                                  part.overridden
                                                                              }
                                                                            }
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    : _vm._e(),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticStyle: {
                                                                        display:
                                                                          "table-cell"
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "fd-count-sheet-part-code-label"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                                  " +
                                                                              _vm._s(
                                                                                part.publicID
                                                                              ) +
                                                                              "\n                                "
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "fd-count-sheet-part-description-label"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                                  " +
                                                                              _vm._s(
                                                                                part.description
                                                                              ) +
                                                                              "\n                                "
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          staticClass:
                                                            "pl-0 py-1",
                                                          attrs: { cols: "4" }
                                                        },
                                                        [
                                                          _c(
                                                            "v-row",
                                                            {
                                                              attrs: {
                                                                "no-gutters": ""
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-col",
                                                                {
                                                                  staticClass:
                                                                    "pl-1",
                                                                  attrs: {
                                                                    cols: "4"
                                                                  }
                                                                },
                                                                [
                                                                  !part.overridden
                                                                    ? _c(
                                                                        "fd-text-field",
                                                                        {
                                                                          ref: _vm.fieldRefForPart(
                                                                            "count",
                                                                            part
                                                                          ),
                                                                          refInFor: true,
                                                                          staticClass:
                                                                            "fd-count-sheet-text-field",
                                                                          attrs: {
                                                                            "data-cy":
                                                                              "count",
                                                                            dense:
                                                                              "",
                                                                            "hide-details":
                                                                              "",
                                                                            disabled:
                                                                              _vm.processing,
                                                                            readonly: !_vm.canEdit,
                                                                            label: _vm.$t(
                                                                              "material-orders.count-label"
                                                                            ),
                                                                            numeric:
                                                                              "",
                                                                            "highlight-on-focus":
                                                                              ""
                                                                          },
                                                                          on: {
                                                                            keydown: [
                                                                              function(
                                                                                $event
                                                                              ) {
                                                                                if (
                                                                                  !$event.type.indexOf(
                                                                                    "key"
                                                                                  ) &&
                                                                                  _vm._k(
                                                                                    $event.keyCode,
                                                                                    "up",
                                                                                    38,
                                                                                    $event.key,
                                                                                    [
                                                                                      "Up",
                                                                                      "ArrowUp"
                                                                                    ]
                                                                                  )
                                                                                )
                                                                                  return null
                                                                                return (e =>
                                                                                  _vm.selectPreviousField(
                                                                                    e,
                                                                                    "count",
                                                                                    part
                                                                                  )).apply(
                                                                                  null,
                                                                                  arguments
                                                                                )
                                                                              },
                                                                              function(
                                                                                $event
                                                                              ) {
                                                                                if (
                                                                                  !$event.type.indexOf(
                                                                                    "key"
                                                                                  ) &&
                                                                                  _vm._k(
                                                                                    $event.keyCode,
                                                                                    "down",
                                                                                    40,
                                                                                    $event.key,
                                                                                    [
                                                                                      "Down",
                                                                                      "ArrowDown"
                                                                                    ]
                                                                                  )
                                                                                )
                                                                                  return null
                                                                                return (e =>
                                                                                  _vm.selectNextField(
                                                                                    e,
                                                                                    "count",
                                                                                    part
                                                                                  )).apply(
                                                                                  null,
                                                                                  arguments
                                                                                )
                                                                              },
                                                                              function(
                                                                                $event
                                                                              ) {
                                                                                if (
                                                                                  !$event.type.indexOf(
                                                                                    "key"
                                                                                  ) &&
                                                                                  _vm._k(
                                                                                    $event.keyCode,
                                                                                    "enter",
                                                                                    13,
                                                                                    $event.key,
                                                                                    "Enter"
                                                                                  )
                                                                                )
                                                                                  return null
                                                                                return (e =>
                                                                                  _vm.enterPressed(
                                                                                    e,
                                                                                    "count",
                                                                                    part
                                                                                  )).apply(
                                                                                  null,
                                                                                  arguments
                                                                                )
                                                                              }
                                                                            ],
                                                                            change: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.partCountValueChanged(
                                                                                part
                                                                              )
                                                                            }
                                                                          },
                                                                          model: {
                                                                            value:
                                                                              part.count,
                                                                            callback: function(
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                part,
                                                                                "count",
                                                                                $$v
                                                                              )
                                                                            },
                                                                            expression:
                                                                              "part.count"
                                                                          }
                                                                        }
                                                                      )
                                                                    : _vm._e(),
                                                                  _vm._v(" "),
                                                                  part.overridden
                                                                    ? _c(
                                                                        "fd-text-field",
                                                                        {
                                                                          ref: _vm.fieldRefForPart(
                                                                            "count",
                                                                            part
                                                                          ),
                                                                          refInFor: true,
                                                                          staticClass:
                                                                            "fd-count-sheet-overridden-text-field",
                                                                          attrs: {
                                                                            "data-cy":
                                                                              "countoverride",
                                                                            dense:
                                                                              "",
                                                                            hint: _vm.$t(
                                                                              "material-orders.original-value",
                                                                              [
                                                                                part.originalCount
                                                                              ]
                                                                            ),
                                                                            "persistent-hint":
                                                                              "",
                                                                            disabled:
                                                                              _vm.processing,
                                                                            readonly: !_vm.canEdit,
                                                                            label: _vm.$t(
                                                                              "material-orders.count-override-label"
                                                                            ),
                                                                            numeric:
                                                                              "",
                                                                            "highlight-on-focus":
                                                                              ""
                                                                          },
                                                                          on: {
                                                                            keydown: [
                                                                              function(
                                                                                $event
                                                                              ) {
                                                                                if (
                                                                                  !$event.type.indexOf(
                                                                                    "key"
                                                                                  ) &&
                                                                                  _vm._k(
                                                                                    $event.keyCode,
                                                                                    "up",
                                                                                    38,
                                                                                    $event.key,
                                                                                    [
                                                                                      "Up",
                                                                                      "ArrowUp"
                                                                                    ]
                                                                                  )
                                                                                )
                                                                                  return null
                                                                                return (e =>
                                                                                  _vm.selectPreviousField(
                                                                                    e,
                                                                                    "count",
                                                                                    part
                                                                                  )).apply(
                                                                                  null,
                                                                                  arguments
                                                                                )
                                                                              },
                                                                              function(
                                                                                $event
                                                                              ) {
                                                                                if (
                                                                                  !$event.type.indexOf(
                                                                                    "key"
                                                                                  ) &&
                                                                                  _vm._k(
                                                                                    $event.keyCode,
                                                                                    "down",
                                                                                    40,
                                                                                    $event.key,
                                                                                    [
                                                                                      "Down",
                                                                                      "ArrowDown"
                                                                                    ]
                                                                                  )
                                                                                )
                                                                                  return null
                                                                                return (e =>
                                                                                  _vm.selectNextField(
                                                                                    e,
                                                                                    "count",
                                                                                    part
                                                                                  )).apply(
                                                                                  null,
                                                                                  arguments
                                                                                )
                                                                              },
                                                                              function(
                                                                                $event
                                                                              ) {
                                                                                if (
                                                                                  !$event.type.indexOf(
                                                                                    "key"
                                                                                  ) &&
                                                                                  _vm._k(
                                                                                    $event.keyCode,
                                                                                    "enter",
                                                                                    13,
                                                                                    $event.key,
                                                                                    "Enter"
                                                                                  )
                                                                                )
                                                                                  return null
                                                                                return (e =>
                                                                                  _vm.enterPressed(
                                                                                    e,
                                                                                    "count",
                                                                                    part
                                                                                  )).apply(
                                                                                  null,
                                                                                  arguments
                                                                                )
                                                                              }
                                                                            ],
                                                                            change: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.partCountValueChanged(
                                                                                part
                                                                              )
                                                                            }
                                                                          },
                                                                          model: {
                                                                            value:
                                                                              part.count,
                                                                            callback: function(
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                part,
                                                                                "count",
                                                                                $$v
                                                                              )
                                                                            },
                                                                            expression:
                                                                              "part.count"
                                                                          }
                                                                        }
                                                                      )
                                                                    : _vm._e()
                                                                ],
                                                                1
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "v-col",
                                                                {
                                                                  staticClass:
                                                                    "pl-1",
                                                                  attrs: {
                                                                    cols: "4"
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "fd-text-field",
                                                                    {
                                                                      staticClass:
                                                                        "fd-count-sheet-text-field",
                                                                      attrs: {
                                                                        "data-cy":
                                                                          "assigned",
                                                                        dense:
                                                                          "",
                                                                        "hide-details":
                                                                          "",
                                                                        disabled:
                                                                          _vm.processing,
                                                                        readonly: true,
                                                                        label: _vm.$t(
                                                                          "material-orders.assigned-label"
                                                                        ),
                                                                        numeric:
                                                                          "",
                                                                        "highlight-on-focus":
                                                                          ""
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          part.assigned,
                                                                        callback: function(
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            part,
                                                                            "assigned",
                                                                            $$v
                                                                          )
                                                                        },
                                                                        expression:
                                                                          "part.assigned"
                                                                      }
                                                                    }
                                                                  )
                                                                ],
                                                                1
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "v-col",
                                                                {
                                                                  staticClass:
                                                                    "pl-1",
                                                                  attrs: {
                                                                    cols: "4"
                                                                  }
                                                                },
                                                                [
                                                                  !part.overridden
                                                                    ? _c(
                                                                        "fd-text-field",
                                                                        {
                                                                          staticClass:
                                                                            "fd-count-sheet-text-field",
                                                                          attrs: {
                                                                            "data-cy":
                                                                              "total",
                                                                            dense:
                                                                              "",
                                                                            "hide-details":
                                                                              "",
                                                                            disabled:
                                                                              _vm.processing,
                                                                            readonly: true,
                                                                            label: _vm.$t(
                                                                              "material-orders.total-label"
                                                                            ),
                                                                            numeric:
                                                                              "",
                                                                            "highlight-on-focus":
                                                                              ""
                                                                          },
                                                                          model: {
                                                                            value:
                                                                              part.total,
                                                                            callback: function(
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                part,
                                                                                "total",
                                                                                $$v
                                                                              )
                                                                            },
                                                                            expression:
                                                                              "part.total"
                                                                          }
                                                                        }
                                                                      )
                                                                    : _vm._e(),
                                                                  _vm._v(" "),
                                                                  part.overridden
                                                                    ? _c(
                                                                        "fd-text-field",
                                                                        {
                                                                          staticClass:
                                                                            "fd-count-sheet-text-field",
                                                                          attrs: {
                                                                            "data-cy":
                                                                              "totaloverride",
                                                                            dense:
                                                                              "",
                                                                            value:
                                                                              part.total,
                                                                            hint: _vm.$t(
                                                                              "material-orders.original-value",
                                                                              [
                                                                                part.originalTotal
                                                                              ]
                                                                            ),
                                                                            "persistent-hint":
                                                                              "",
                                                                            label: _vm.$t(
                                                                              "material-orders.total-override-label"
                                                                            ),
                                                                            readonly: true
                                                                          }
                                                                        }
                                                                      )
                                                                    : _vm._e()
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            })
                                          ],
                                          2
                                        )
                                      ],
                                      1
                                    )
                                  })
                                ],
                                2
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-tab-item",
                            {
                              key: _vm.transfersTab.key,
                              attrs: { value: `tab-${_vm.transfersTab.key}` }
                            },
                            [
                              _c("v-data-table", {
                                directives: [
                                  {
                                    name: "fd-column",
                                    rawName: "v-fd-column:legacyID",
                                    value: _vm.$t(
                                      "transfers.list.legacy-id-column-label"
                                    ),
                                    expression:
                                      "$t('transfers.list.legacy-id-column-label')",
                                    arg: "legacyID"
                                  },
                                  {
                                    name: "fd-column",
                                    rawName: "v-fd-column:transferDate",
                                    value: _vm.$t(
                                      "transfers.list.transfer-date-column-label"
                                    ),
                                    expression:
                                      "$t('transfers.list.transfer-date-column-label')",
                                    arg: "transferDate"
                                  },
                                  {
                                    name: "fd-column",
                                    rawName:
                                      "v-fd-column:fromYardName.hide-when-header-text-empty",
                                    value: _vm.directionIsMaterialReturn
                                      ? _vm.$t(
                                          "transfers.list.from-yard-column-label"
                                        )
                                      : "",
                                    expression:
                                      "\n                  directionIsMaterialReturn ? $t('transfers.list.from-yard-column-label') : ''\n                ",
                                    arg: "fromYardName",
                                    modifiers: {
                                      "hide-when-header-text-empty": true
                                    }
                                  },
                                  {
                                    name: "fd-column",
                                    rawName:
                                      "v-fd-column:fromSupplierName.hide-when-header-text-empty",
                                    value: _vm.directionIsMaterialRequest
                                      ? _vm.$t(
                                          "transfers.list.from-supplier-column-label"
                                        )
                                      : "",
                                    expression:
                                      "\n                  directionIsMaterialRequest\n                    ? $t('transfers.list.from-supplier-column-label')\n                    : ''\n                ",
                                    arg: "fromSupplierName",
                                    modifiers: {
                                      "hide-when-header-text-empty": true
                                    }
                                  },
                                  {
                                    name: "fd-column",
                                    rawName:
                                      "v-fd-column:toYardName.hide-when-header-text-empty",
                                    value: _vm.directionIsMaterialRequest
                                      ? _vm.$t(
                                          "transfers.list.to-yard-column-label"
                                        )
                                      : "",
                                    expression:
                                      "\n                  directionIsMaterialRequest ? $t('transfers.list.to-yard-column-label') : ''\n                ",
                                    arg: "toYardName",
                                    modifiers: {
                                      "hide-when-header-text-empty": true
                                    }
                                  },
                                  {
                                    name: "fd-column",
                                    rawName:
                                      "v-fd-column:toSupplierName.hide-when-header-text-empty",
                                    value: _vm.directionIsMaterialReturn
                                      ? _vm.$t(
                                          "transfers.list.to-supplier-column-label"
                                        )
                                      : "",
                                    expression:
                                      "\n                  directionIsMaterialReturn ? $t('transfers.list.to-supplier-column-label') : ''\n                ",
                                    arg: "toSupplierName",
                                    modifiers: {
                                      "hide-when-header-text-empty": true
                                    }
                                  },
                                  {
                                    name: "fd-column",
                                    rawName:
                                      "v-fd-column:transactionNumber.hide-when-small",
                                    value: _vm.$t(
                                      "transfers.list.transaction-number-column-label"
                                    ),
                                    expression:
                                      "\n                  $t('transfers.list.transaction-number-column-label')\n                ",
                                    arg: "transactionNumber",
                                    modifiers: { "hide-when-small": true }
                                  },
                                  {
                                    name: "fd-column",
                                    rawName: "v-fd-column:hasPhotos",
                                    value: _vm.$t(
                                      "transfers.list.has-photos-column-label"
                                    ),
                                    expression:
                                      "$t('transfers.list.has-photos-column-label')",
                                    arg: "hasPhotos"
                                  },
                                  {
                                    name: "fd-column",
                                    rawName:
                                      "v-fd-column:actions.no-sort.hide-when-extra-small.class_fd-actions-cell",
                                    arg: "actions",
                                    modifiers: {
                                      "no-sort": true,
                                      "hide-when-extra-small": true,
                                      "class_fd-actions-cell": true
                                    }
                                  },
                                  {
                                    name: "fd-row-navigate",
                                    rawName:
                                      "v-fd-row-navigate.show-when-extra-small",
                                    value: item =>
                                      _vm.$router.push(`/transfers/${item.id}`),
                                    expression:
                                      "\n                  item => $router.push(`/transfers/${item.id}`)\n                ",
                                    modifiers: { "show-when-extra-small": true }
                                  }
                                ],
                                ref: "datatable",
                                attrs: {
                                  "data-cy": "transfers",
                                  "mobile-breakpoint": "0",
                                  loading: _vm.processing,
                                  "loading-text": _vm.$t(
                                    "common.table-loading-message"
                                  ),
                                  "sort-by": "transferDate",
                                  "sort-desc": "",
                                  items: _vm.transfers
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "item.transferDate",
                                    fn: function({ item }) {
                                      return [
                                        _vm._v(
                                          _vm._s(
                                            _vm.formatDate(item.transferDate)
                                          )
                                        )
                                      ]
                                    }
                                  },
                                  {
                                    key: "item.hasPhotos",
                                    fn: function({ item }) {
                                      return [
                                        _c("fd-checkbox", {
                                          attrs: {
                                            value:
                                              !!item.fileNames &&
                                              !!item.fileNames.length,
                                            readonly: true
                                          }
                                        })
                                      ]
                                    }
                                  },
                                  {
                                    key: "item.transactionNumber",
                                    fn: function({ item }) {
                                      return [
                                        _c("fd-value-display", {
                                          attrs: {
                                            value: item.transactionNumber
                                          }
                                        })
                                      ]
                                    }
                                  },
                                  {
                                    key: "item.actions",
                                    fn: function({ item }) {
                                      return [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "table-2-actions-column-min-width"
                                          },
                                          [
                                            _c(
                                              "v-tooltip",
                                              {
                                                attrs: { top: "" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function({ on }) {
                                                        return [
                                                          _c(
                                                            "v-btn",
                                                            _vm._g(
                                                              {
                                                                attrs: {
                                                                  icon: "",
                                                                  link: "",
                                                                  disabled:
                                                                    _vm.processing ||
                                                                    !item.fileNames ||
                                                                    item
                                                                      .fileNames
                                                                      .length ==
                                                                      0
                                                                }
                                                              },
                                                              on
                                                            ),
                                                            [
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  attrs: {
                                                                    small: ""
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                            fas fa-camera\n                          "
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              },
                                              [
                                                _vm._v(" "),
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t("View Images")
                                                    )
                                                  )
                                                ])
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "v-tooltip",
                                              {
                                                attrs: { top: "" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function({ on }) {
                                                        return [
                                                          _c(
                                                            "v-btn",
                                                            _vm._g(
                                                              {
                                                                attrs: {
                                                                  icon: "",
                                                                  link: "",
                                                                  disabled:
                                                                    _vm.processing,
                                                                  to: `/transfers/${item.id}`
                                                                }
                                                              },
                                                              on
                                                            ),
                                                            [
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  attrs: {
                                                                    small: ""
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                            mdi-eye\n                          "
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              },
                                              [
                                                _vm._v(" "),
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t("Transfer Details")
                                                    )
                                                  )
                                                ])
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ])
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.inlineMessage.message
                        ? _c(
                            "v-row",
                            { staticClass: "mx-0", attrs: { align: "center" } },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _vm.inlineMessage.message
                                    ? _c(
                                        "v-alert",
                                        {
                                          attrs: {
                                            type: _vm.inlineMessage.type
                                          }
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "white-space": "pre-line"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.inlineMessage.message
                                                )
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "v-card-actions",
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "d-none d-md-flex",
                              attrs: {
                                text: "",
                                link: "",
                                color: "error",
                                "data-cy": "cancel",
                                loading: _vm.cancelling,
                                disabled: !_vm.canCancelOrder || _vm.processing,
                                small: _vm.$vuetify.breakpoint.xsOnly
                              },
                              on: { click: _vm.cancelOrder }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.$t(
                                      "material-orders.existing.actions-cancel-order"
                                    )
                                  ) +
                                  "\n            "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              staticClass: "d-none d-md-flex",
                              attrs: {
                                text: "",
                                link: "",
                                color: "error",
                                "data-cy": "abandon",
                                loading: _vm.cancelling,
                                disabled:
                                  !_vm.canAbandonOrder || _vm.processing,
                                small: _vm.$vuetify.breakpoint.xsOnly
                              },
                              on: { click: _vm.abandonOrder }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.$t(
                                      "material-orders.existing.actions-abandon-order"
                                    )
                                  ) +
                                  "\n            "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                outlined: "",
                                loading: _vm.printing,
                                disabled: _vm.processing,
                                icon: _vm.$vuetify.breakpoint.xsOnly
                              },
                              on: {
                                click: function($event) {
                                  return _vm.downloadAndPrintReport("pdf")
                                }
                              }
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  attrs: {
                                    small: "",
                                    left: _vm.$vuetify.breakpoint.smAndUp
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                mdi-printer\n              "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _vm.$vuetify.breakpoint.smAndUp
                                ? _c("span", [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.$t("common.print")) +
                                        "\n              "
                                    )
                                  ])
                                : _vm._e()
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-spacer"),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                outlined: "",
                                color: "primary",
                                disabled: _vm.processing || !_vm.canFulfillOrder
                              },
                              on: { click: _vm.fulfillOrder }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "material-orders.existing.fulfill-button"
                                  )
                                )
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              staticClass: "d-none d-md-flex",
                              attrs: {
                                outlined: "",
                                color: "success",
                                "data-cy": "approve",
                                loading: _vm.approving,
                                disabled: _vm.processing || !_vm.canApprove,
                                small: _vm.$vuetify.breakpoint.xsOnly
                              },
                              on: { click: _vm.approveOrder }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$t("common.approve")) +
                                  "\n            "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              staticClass: "d-none d-md-flex",
                              attrs: {
                                "data-cy": "decline",
                                outlined: "",
                                color: "error",
                                loading: _vm.declining,
                                disabled: _vm.processing || !_vm.canDecline,
                                small: _vm.$vuetify.breakpoint.xsOnly
                              },
                              on: { click: _vm.declineOrder }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$t("common.decline")) +
                                  "\n            "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              staticClass: "d-none d-md-flex",
                              attrs: {
                                "data-cy": "submit",
                                outlined: "",
                                color: "primary",
                                loading: _vm.submitting,
                                disabled: _vm.processing || !_vm.canSubmit,
                                small: _vm.$vuetify.breakpoint.xsOnly
                              },
                              on: { click: _vm.submitOrder }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$t("common.save-and-submit")) +
                                  "\n            "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "ml-4 mr-3 d-none d-md-flex fd-button-context-separator-hide-when-mobile"
                            },
                            [_vm._v("\n              |\n            ")]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              staticClass: "mr-2",
                              attrs: {
                                "data-cy": "close",
                                text: "",
                                link: "",
                                disabled: _vm.processing,
                                small: _vm.$vuetify.breakpoint.xsOnly
                              },
                              on: { click: _vm.cancel }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$t("common.cancel-edits")) +
                                  "\n            "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c("fd-menu-save", {
                            attrs: {
                              disabled: _vm.processing || !_vm.canEdit,
                              loading: _vm.saving,
                              small: _vm.$vuetify.breakpoint.xsOnly
                            },
                            on: {
                              "click:save": function($event) {
                                return _vm.save(false)
                              },
                              "click:save-and-close": function($event) {
                                return _vm.save(true)
                              }
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }