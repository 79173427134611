import FDVue from "@fd/lib/vue";
import { mapMutations, mapActions } from "vuex";
import {
  FDColumnDirective,
  FDHiddenArgumentName,
  FDRowNavigateDirective
} from "@fd/lib/vue/utility/dataTable";
import { createNewPerson } from "./components/dialogs/PersonNewDialog.vue";
import peopleList, { PersonWithExtraDetails } from "../dataMixins/peopleList";
import userAccess from "../dataMixins/userAccess";
import { PersonWithDetails } from "../services";

export default FDVue.extend({
  mixins: [peopleList, userAccess],

  directives: {
    fdColumn: FDColumnDirective,
    fdRowNavigate: FDRowNavigateDirective
  },

  components: {
    "fd-people-list": () => import("./components/PeopleListTable.vue")
  },

  data: () => ({}),

  computed: {
    iconColumnArgument(): string {
      return this.anyInactiveUsers ? "inactive" : FDHiddenArgumentName;
    },
    anyInactiveUsers(): boolean {
      // Users are inactive if the person is not archived, and the loginState is inactive
      return !!this.users.find(x => x.isActiveWithUnusedLogin);
    }
  },

  methods: {
    openNewDialog() {
      createNewPerson();
    },

    // the following works with the delete "Action" button in the Datatable.
    async deleteTableItem(item: any) {
      await this.deleteUser(item);
      await this.loadUsers();
    },

    async flipArchived(item: PersonWithExtraDetails) {
      this.inlineMessage.message = null;
      this.processing = true;
      try {
        // We want to use the opposite value for archived, since we're flipping it
        var archivedDate = item.isArchived ? null : new Date(new Date().toUTCString());
        await this.updateUser({
          id: item.id,
          archivedDate: archivedDate,
          firstName: item.firstName,
          lastName: item.lastName
        } as PersonWithDetails);
        await this.refreshUserList();
      } catch (error) {
        this.handleError(error as Error, "users.save-network-error");
      } finally {
        this.processing = false;
      }
    },

    async flipCanLogIn(item: PersonWithExtraDetails) {
      this.inlineMessage.message = null;
      this.processing = true;
      try {
        // We want to use the opposite value for disabled, since we're flipping it
        var loginDisabledDate = item.isLoginActive ? new Date(new Date().toUTCString()) : null;
        await this.updateUser({
          id: item.id,
          loginDisabledDate: loginDisabledDate,
          firstName: item.firstName,
          lastName: item.lastName
        } as PersonWithDetails);
        await this.refreshUserList();
      } catch (error) {
        this.handleError(error as Error, "users.save-network-error");
      } finally {
        this.processing = false;
      }
    },
    ...mapMutations({
      notifyNewBreadcrumb: "NOTIFY_NEW_BREADCRUMB",
      setFilteringContext: "SET_FILTERING_CONTEXT"
    }),
    ...mapActions({
      loadContractors: "LOAD_CONTRACTORS",
      loadTags: "LOAD_TAGS",
      updateUser: "UPDATE_USER"
    })
  },

  mounted: function() {},

  created: async function() {
    this.notifyNewBreadcrumb({
      text: this.$t("users.menu-title"),
      to: "/people",
      resetHistory: true
    });
  }
});

