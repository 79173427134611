import { estimateService, EstimateWithParts } from "../services/index";
import { createAutomaticCrudStoreModule } from "./automatic";
import i18n from "../i18n";

var storeModule = createAutomaticCrudStoreModule({
  crudService: estimateService,
  singularStoreName: "ESTIMATE",
  pluralStoreName: "ESTIMATES",
  localizationPrefix: "estimates",
  consumerRelatedIDProperty: {
    name: "estimateID",
    type: "string"
  },
  storeExtensions: {
    mutations: {
      // REPLACE_ESTIMATES_FOR_CLIENT(state, payload: { estimates: EstimateWithParts[]; clientID: string }) {
      //   let estimates = state.fullList.filter(x => x.clientID != payload.clientID);
      //   estimates = estimates.concat(payload.estimates);
      //   state.fullList = estimates;
      // },
      UPDATE_ESTIMATE_PARTS(
        state,
        payload: {
          estimateID: string;
          partCounts: Map<string, number>;
        }
      ) {
        let estimate = state.fullList.find(x => x.id == payload.estimateID);
        if (!estimate || !estimate.partCounts) return;
        // TODO: Convert this to use a map instead of an object given the computational expense of property manipulation
        for (let partID of payload.partCounts.keys()) {
          let count = payload.partCounts.get(partID)!;
          if (count) {
            estimate.partCounts[partID] = count;
          } else {
            delete estimate.partCounts[partID];
          }
        }
      }
    },
    actions: {
      async UPDATE_ESTIMATE_PARTS(
        context,
        payload: {
          estimateID: string;
          partCounts: Map<string, number>;
          included: boolean;
        }
      ) {
        // TODO: We should try to get the service to return us maps instead of objects
        let newPartCounts: { [partID: string]: number } = {};
        for (let partCount of payload.partCounts) {
          newPartCounts[partCount[0]] = newPartCounts[1];
        }
        // await estimateService.updateEstimateParts(
        //   payload.estimateID,
        //   newPartCounts,
        //   payload.included
        // );
        context.commit("UPDATE_ESTIMATE_PARTS", payload);
        context.dispatch("SHOW_SNACKBAR", {
          text: i18n.t("estimates.estimate-parts-updated"),
          type: "success"
        });
      },
      async DELETE_ESTIMATE(context, payload): Promise<boolean> {
        // For this one, we need to do a full load before deleting because we don't have a
        // transaction-based undo yet and we need to make sure we have all child records
        // (namely selected parts) so we can properly restore
        await context.dispatch("LOAD_ESTIMATE", payload.id);

        let deletedItem = context.state.fullList.find(x => x.id == payload.id)!;
        if (await estimateService.deleteItem(payload.id)) {
          context.commit("DELETE_ESTIMATE", payload);
          context.dispatch("SHOW_SNACKBAR", {
            text: i18n.t("estimates.snack-bar-delete-message", [payload.name]),
            type: "info",
            undoCallback: async function() {
              context.dispatch("ADD_ESTIMATE", deletedItem);
            }
          });
          return true;
        } else {
          // TODO: We need a better confirmation here since you can't disable an estimate
          // TODO: Is it always possible to delete an estimate?
          // if (await showDisableInsteadConfirmation(false)) {
          //   await context.dispatch("UPDATE_ESTIMATE", {
          //     id: deletedItem.id,
          //     enabled: false,
          //     name: deletedItem.name
          //   });
          // }
          return false;
        }
      }
    }
  }
});

export default storeModule;
