var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-form",
        { ref: "form", on: { submit: _vm.onSubmit } },
        [
          _c(
            "v-slide-x-reverse-transition",
            [
              _c(
                "v-card",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.slidein,
                      expression: "slidein"
                    }
                  ]
                },
                [
                  _c(
                    "v-toolbar",
                    {
                      class: [
                        _vm.processing
                          ? "view-card-top-color-processing"
                          : "view-card-top-color"
                      ]
                    },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c(
                                "v-row",
                                { attrs: { align: "center" } },
                                [
                                  _c("v-breadcrumbs", {
                                    class: [
                                      _vm.processing
                                        ? "breadcrumb-processing-opacity"
                                        : "",
                                      "pl-3"
                                    ],
                                    attrs: {
                                      items:
                                        _vm.$store.state.currentBreadcrumbs,
                                      large: ""
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "divider",
                                        fn: function() {
                                          return [
                                            _c("v-icon", [
                                              _vm._v("fa-chevron-right")
                                            ])
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ])
                                  }),
                                  _vm._v(" "),
                                  _c("v-spacer"),
                                  _vm._v(" "),
                                  _vm.processing
                                    ? _c("v-progress-circular", {
                                        staticClass: "mr-3",
                                        attrs: {
                                          indeterminate: true,
                                          rotate: 0,
                                          size: 32,
                                          width: 4,
                                          color: "white"
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-container",
                    { attrs: { fluid: "" } },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "hide-when-medium",
                              attrs: { cols: "12", sm: "12", md: "12", lg: "3" }
                            },
                            [
                              _c(
                                "v-card",
                                { staticClass: "estimate-summation" },
                                [
                                  _c(
                                    "v-card-title",
                                    {
                                      staticClass: "subheading font-weight-bold"
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("estimates.summation-title")
                                        )
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("v-divider"),
                                  _vm._v(" "),
                                  _vm._l(_vm.estimatesummation, function(item) {
                                    return _c(
                                      "v-row",
                                      { key: item.name },
                                      [
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "8" } },
                                          [
                                            _c("v-row", [
                                              _vm._v(
                                                "\n                                            " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "estimates." + item.name
                                                    )
                                                  ) +
                                                  "\n                                        "
                                              )
                                            ])
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "4" } },
                                          [
                                            _c(
                                              "v-row",
                                              { attrs: { justify: "end" } },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    class: {
                                                      "card-positive-highlight-coloring":
                                                        item.hightlight
                                                    }
                                                  },
                                                  [_vm._v(_vm._s(item.value))]
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  })
                                ],
                                2
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            {
                              attrs: { cols: "12", sm: "12", md: "12", lg: "9" }
                            },
                            [
                              _c(
                                "v-expansion-panels",
                                { attrs: { multiple: "" } },
                                [
                                  _c(
                                    "v-expansion-panel",
                                    { staticClass: "hide-when-large" },
                                    [
                                      _c("v-expansion-panel-header", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("estimates.summation-title")
                                          )
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "v-expansion-panel-content",
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pt-0 pb-0 pl-7",
                                              attrs: { cols: "12" }
                                            },
                                            _vm._l(
                                              _vm.estimatesummation,
                                              function(item) {
                                                return _c(
                                                  "v-row",
                                                  { key: item.name },
                                                  [
                                                    _c(
                                                      "v-col",
                                                      { attrs: { cols: "8" } },
                                                      [
                                                        _c("v-row", [
                                                          _vm._v(
                                                            "\n                                                        " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "estimates." +
                                                                    item.name
                                                                )
                                                              ) +
                                                              "\n                                                    "
                                                          )
                                                        ])
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "v-col",
                                                      { attrs: { cols: "4" } },
                                                      [
                                                        _c(
                                                          "v-row",
                                                          {
                                                            attrs: {
                                                              justify: "end"
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                class: {
                                                                  "card-positive-highlight-coloring":
                                                                    item.hightlight
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.value
                                                                  )
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              }
                                            ),
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-expansion-panel",
                                    { staticClass: "mt-lg-0" },
                                    [
                                      _c(
                                        "v-expansion-panel-header",
                                        {
                                          attrs: {
                                            "disable-icon-rotate":
                                              _vm.simulatederror
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "actions",
                                              fn: function() {
                                                return [
                                                  _vm.simulatederror
                                                    ? _c(
                                                        "v-icon",
                                                        {
                                                          attrs: {
                                                            color: "error"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "mdi-alert-circle"
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e()
                                                ]
                                              },
                                              proxy: true
                                            }
                                          ])
                                        },
                                        [
                                          _vm._v(
                                            "\n                                        " +
                                              _vm._s(
                                                _vm.$t(
                                                  "estimates.enter-details"
                                                )
                                              ) +
                                              "\n                                    "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-expansion-panel-content",
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pt-0",
                                              attrs: { cols: "12" }
                                            },
                                            [
                                              _c(
                                                "v-row",
                                                { staticClass: "pt-1 pb-0" },
                                                [
                                                  _c(
                                                    "v-col",
                                                    { attrs: { cols: "12" } },
                                                    [
                                                      _c("fd-chip-selector", {
                                                        attrs: {
                                                          "available-items":
                                                            _vm.availableTagsForOverallEstimate,
                                                          "selected-items":
                                                            _vm.selectedTagsForOverallEstimate,
                                                          "item-label": "name",
                                                          disabled:
                                                            _vm.processing,
                                                          "control-label": _vm.$t(
                                                            "common.add-tags"
                                                          ),
                                                          "chip-class":
                                                            "keywords-selection-chip-colors"
                                                        },
                                                        on: {
                                                          "update:selectedItems": function(
                                                            $event
                                                          ) {
                                                            _vm.selectedTagsForOverallEstimate = $event
                                                          },
                                                          "update:selected-items": function(
                                                            $event
                                                          ) {
                                                            _vm.selectedTagsForOverallEstimate = $event
                                                          }
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-row",
                                                {
                                                  staticClass:
                                                    "pt-0 pb-0 pl-0 pr-0"
                                                },
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "12",
                                                        sm: "12",
                                                        md: "6"
                                                      }
                                                    },
                                                    [
                                                      _c("v-text-field", {
                                                        attrs: {
                                                          label: _vm.$t(
                                                            "estimates.tagnumberlong"
                                                          ),
                                                          disabled:
                                                            _vm.processing,
                                                          rules: [
                                                            _vm.rules.required
                                                          ]
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.estimate
                                                              .tagnumber,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.estimate,
                                                              "tagnumber",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "estimate.tagnumber"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "12",
                                                        sm: "12",
                                                        md: "6"
                                                      }
                                                    },
                                                    [
                                                      _c("v-text-field", {
                                                        attrs: {
                                                          label: _vm.$t(
                                                            "estimates.estimated-by"
                                                          ),
                                                          disabled:
                                                            _vm.processing
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.estimate
                                                              .estimatedby,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.estimate,
                                                              "estimatedby",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "estimate.estimatedby"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-row",
                                                { staticClass: "pt-0" },
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass: "pt-0",
                                                      attrs: {
                                                        cols: "12",
                                                        sm: "12",
                                                        md: "6"
                                                      }
                                                    },
                                                    [
                                                      _c("v-select", {
                                                        attrs: {
                                                          "item-text": "name",
                                                          "item-value": "id",
                                                          items: _vm.clients,
                                                          label: _vm.$t(
                                                            "estimates.client"
                                                          ),
                                                          disabled:
                                                            _vm.processing,
                                                          rules: [
                                                            _vm.rules.required
                                                          ]
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.estimate
                                                              .clientID,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.estimate,
                                                              "clientID",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "estimate.clientID"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass: "pt-0",
                                                      attrs: {
                                                        cols: "12",
                                                        sm: "12",
                                                        md: "6"
                                                      }
                                                    },
                                                    [
                                                      _c("v-select", {
                                                        attrs: {
                                                          "item-text": "name",
                                                          "item-value": "id",
                                                          items: _vm.projects,
                                                          label: _vm.$t(
                                                            "estimates.project"
                                                          ),
                                                          disabled:
                                                            _vm.processing,
                                                          rules: [
                                                            _vm.rules.required
                                                          ]
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.estimate
                                                              .projectID,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.estimate,
                                                              "projectID",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "estimate.projectID"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-row",
                                                { staticClass: "pt-0" },
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass: "pt-0",
                                                      attrs: {
                                                        cols: "12",
                                                        sm: "12",
                                                        md: "6"
                                                      }
                                                    },
                                                    [
                                                      _c("v-select", {
                                                        attrs: {
                                                          "item-text": "name",
                                                          "item-value": "id",
                                                          items: _vm.areas,
                                                          label: _vm.$t(
                                                            "estimates.area"
                                                          ),
                                                          disabled:
                                                            _vm.processing
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.estimate.areaID,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.estimate,
                                                              "areaID",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "estimate.areaID"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass: "pt-0",
                                                      attrs: {
                                                        cols: "12",
                                                        sm: "12",
                                                        md: "6"
                                                      }
                                                    },
                                                    [
                                                      _c("v-select", {
                                                        attrs: {
                                                          "item-text": "name",
                                                          "item-value": "id",
                                                          items: _vm.subareas,
                                                          label: _vm.$t(
                                                            "estimates.subarea"
                                                          ),
                                                          disabled:
                                                            _vm.processing
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.estimate
                                                              .subareaID,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.estimate,
                                                              "subareaID",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "estimate.subareaID"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-row",
                                                { staticClass: "pt-0" },
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass: "pt-0",
                                                      attrs: {
                                                        cols: "12",
                                                        sm: "12",
                                                        md: "6"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-menu",
                                                        {
                                                          attrs: {
                                                            "close-on-content-click": false,
                                                            "nudge-right": 40,
                                                            transition:
                                                              "scale-transition",
                                                            "offset-y": "",
                                                            "min-width": "290px"
                                                          },
                                                          scopedSlots: _vm._u([
                                                            {
                                                              key: "activator",
                                                              fn: function({
                                                                on
                                                              }) {
                                                                return [
                                                                  _c(
                                                                    "v-text-field",
                                                                    _vm._g(
                                                                      {
                                                                        attrs: {
                                                                          label: _vm.$t(
                                                                            "estimates.validfrom"
                                                                          ),
                                                                          value:
                                                                            _vm.formatedValidFromDate,
                                                                          "prepend-icon":
                                                                            "event",
                                                                          readonly:
                                                                            ""
                                                                        }
                                                                      },
                                                                      on
                                                                    )
                                                                  )
                                                                ]
                                                              }
                                                            }
                                                          ]),
                                                          model: {
                                                            value:
                                                              _vm.validfrommenu,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.validfrommenu = $$v
                                                            },
                                                            expression:
                                                              "validfrommenu"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(" "),
                                                          _c("v-date-picker", {
                                                            attrs: {
                                                              locale:
                                                                _vm.i18nLocale
                                                            },
                                                            on: {
                                                              input: function(
                                                                $event
                                                              ) {
                                                                _vm.validfrommenu = false
                                                              }
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.estimate
                                                                  .validfrom,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.estimate,
                                                                  "validfrom",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "estimate.validfrom"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass: "pt-0",
                                                      attrs: {
                                                        cols: "12",
                                                        sm: "12",
                                                        md: "6"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-menu",
                                                        {
                                                          attrs: {
                                                            "close-on-content-click": false,
                                                            "nudge-right": 40,
                                                            transition:
                                                              "scale-transition",
                                                            "offset-y": "",
                                                            "min-width": "290px"
                                                          },
                                                          scopedSlots: _vm._u([
                                                            {
                                                              key: "activator",
                                                              fn: function({
                                                                on
                                                              }) {
                                                                return [
                                                                  _c(
                                                                    "v-text-field",
                                                                    _vm._g(
                                                                      {
                                                                        attrs: {
                                                                          label: _vm.$t(
                                                                            "estimates.validuntil"
                                                                          ),
                                                                          value:
                                                                            _vm.formatedValidUntilDate,
                                                                          "prepend-icon":
                                                                            "event",
                                                                          readonly:
                                                                            ""
                                                                        }
                                                                      },
                                                                      on
                                                                    )
                                                                  )
                                                                ]
                                                              }
                                                            }
                                                          ]),
                                                          model: {
                                                            value:
                                                              _vm.validuntilmenu,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.validuntilmenu = $$v
                                                            },
                                                            expression:
                                                              "validuntilmenu"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(" "),
                                                          _c("v-date-picker", {
                                                            attrs: {
                                                              locale:
                                                                _vm.i18nLocale
                                                            },
                                                            on: {
                                                              input: function(
                                                                $event
                                                              ) {
                                                                _vm.validuntilmenu = false
                                                              }
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.estimate
                                                                  .validuntil,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.estimate,
                                                                  "validuntil",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "estimate.validuntil"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-row",
                                                { staticClass: "pt-0" },
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass: "pt-0",
                                                      attrs: {
                                                        cols: "12",
                                                        sm: "12",
                                                        md: "6"
                                                      }
                                                    },
                                                    [
                                                      _c("v-select", {
                                                        attrs: {
                                                          "item-text": "name",
                                                          "item-value": "id",
                                                          items: _vm.regions,
                                                          label: _vm.$t(
                                                            "clients.projects.region"
                                                          ),
                                                          disabled:
                                                            _vm.processing,
                                                          clearable: ""
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.estimate
                                                              .regionID,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.estimate,
                                                              "regionID",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "estimate.regionID"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-row",
                                                { staticClass: "pt-0" },
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass: "pt-0",
                                                      attrs: {
                                                        cols: "12",
                                                        sm: "12",
                                                        md: "6"
                                                      }
                                                    },
                                                    [
                                                      _c("v-text-field", {
                                                        attrs: {
                                                          label: _vm.$t(
                                                            "clients.projects.productivity"
                                                          ),
                                                          disabled:
                                                            _vm.processing,
                                                          rules: [
                                                            _vm.rules
                                                              .numbersonly
                                                          ]
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.estimate
                                                              .productivity,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.estimate,
                                                              "productivity",
                                                              _vm._n($$v)
                                                            )
                                                          },
                                                          expression:
                                                            "estimate.productivity"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass: "pt-0",
                                                      attrs: {
                                                        cols: "12",
                                                        sm: "12",
                                                        md: "6"
                                                      }
                                                    },
                                                    [
                                                      _c("v-text-field", {
                                                        attrs: {
                                                          label: _vm.$t(
                                                            "clients.projects.labourrate"
                                                          ),
                                                          disabled:
                                                            _vm.processing,
                                                          rules: [
                                                            _vm.rules
                                                              .numbersonly
                                                          ]
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.estimate
                                                              .labourrate,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.estimate,
                                                              "labourrate",
                                                              _vm._n($$v)
                                                            )
                                                          },
                                                          expression:
                                                            "estimate.labourrate"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-expansion-panel",
                                    [
                                      _c(
                                        "v-expansion-panel-header",
                                        {
                                          attrs: {
                                            "disable-icon-rotate":
                                              _vm.simulatederror
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "actions",
                                              fn: function() {
                                                return [
                                                  _vm.simulatederror
                                                    ? _c(
                                                        "v-icon",
                                                        {
                                                          attrs: {
                                                            color: "error"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "mdi-alert-circle"
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e()
                                                ]
                                              },
                                              proxy: true
                                            }
                                          ])
                                        },
                                        [
                                          _vm._v(
                                            "\n                                        " +
                                              _vm._s(
                                                _vm.$t(
                                                  "estimates.parts-selection"
                                                )
                                              ) +
                                              "\n                                        "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-expansion-panel-content",
                                        {
                                          staticClass:
                                            "v-expansion-panel-padding-override"
                                        },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "secondary-stepper-section pt-5",
                                              attrs: { cols: "12" }
                                            },
                                            [
                                              _c("v-textarea", {
                                                attrs: {
                                                  label: _vm.$t(
                                                    "estimates.parts-selection-bcounts-paste-placeholder"
                                                  ),
                                                  outlined: "",
                                                  rows: "1",
                                                  "persistent-hint": "",
                                                  hint: _vm.$t(
                                                    "estimates.parts-selection-bcounts-paste-hint"
                                                  ),
                                                  disabled: _vm.processing
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _vm.simulatederror &&
                                          !_vm.allErroredPartsThatRemainHaveBeenIgnored &&
                                          _vm.erroredparts.length > 0
                                            ? _c(
                                                "v-col",
                                                {
                                                  staticClass:
                                                    "secondary-stepper-section pb-1",
                                                  attrs: { cols: "12" }
                                                },
                                                [
                                                  _c(
                                                    "v-alert",
                                                    {
                                                      attrs: { type: "error" }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                                    " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "estimates.parts-selection-inline-error-message"
                                                            )
                                                          ) +
                                                          "\n                                                "
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.simulatederror &&
                                          _vm.allErroredPartsThatRemainHaveBeenIgnored
                                            ? _c(
                                                "v-col",
                                                {
                                                  staticClass:
                                                    "secondary-stepper-section pb-1",
                                                  attrs: { cols: "12" }
                                                },
                                                [
                                                  _c(
                                                    "v-alert",
                                                    { attrs: { type: "info" } },
                                                    [
                                                      _vm._v(
                                                        "\n                                                    " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "estimates.parts-selection-inline-all-remaining-parts-ignored-message"
                                                            )
                                                          ) +
                                                          "\n                                                "
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.simulatederror &&
                                          _vm.erroredparts.length == 0
                                            ? _c(
                                                "v-col",
                                                {
                                                  staticClass:
                                                    "secondary-stepper-section pb-1",
                                                  attrs: { cols: "12" }
                                                },
                                                [
                                                  _c(
                                                    "v-alert",
                                                    {
                                                      attrs: { type: "success" }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                                    " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "estimates.parts-selection-inline-all-errored-parts-added-to-catalog"
                                                            )
                                                          ) +
                                                          "\n                                                "
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.simulatederror &&
                                          _vm.erroredparts.length > 0
                                            ? _c(
                                                "v-col",
                                                {
                                                  staticClass:
                                                    "secondary-stepper-section pb-7 pt-0",
                                                  attrs: { cols: "12" }
                                                },
                                                [
                                                  _c("v-data-table", {
                                                    staticClass:
                                                      "fd-actions-table fd-warnings-table",
                                                    attrs: {
                                                      headers:
                                                        _vm.computedHeadersForErroredParts,
                                                      items: _vm.erroredparts,
                                                      search: _vm.tablesearch,
                                                      "single-expand":
                                                        _vm.singleExpand,
                                                      expanded: _vm.expanded,
                                                      "item-key": "name",
                                                      "show-expand":
                                                        _vm.showExpand,
                                                      "footer-props": {
                                                        "items-per-page-options": [
                                                          1,
                                                          5
                                                        ]
                                                      },
                                                      "items-per-page": 5,
                                                      "mobile-breakpoint": "0"
                                                    },
                                                    on: {
                                                      "update:expanded": function(
                                                        $event
                                                      ) {
                                                        _vm.expanded = $event
                                                      }
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "header.name",
                                                          fn: function({
                                                            header
                                                          }) {
                                                            return [
                                                              _vm._v(
                                                                "\n                                                        " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "common." +
                                                                        header.value
                                                                    )
                                                                  ) +
                                                                  "\n                                                    "
                                                              )
                                                            ]
                                                          }
                                                        },
                                                        {
                                                          key:
                                                            "header.quantity",
                                                          fn: function({
                                                            header
                                                          }) {
                                                            return [
                                                              _vm._v(
                                                                "\n                                                        " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "common." +
                                                                        header.value
                                                                    )
                                                                  ) +
                                                                  "\n                                                    "
                                                              )
                                                            ]
                                                          }
                                                        },
                                                        {
                                                          key:
                                                            "header.data-table-expand",
                                                          fn: function() {
                                                            return [
                                                              _vm._v(
                                                                "\n                                                        " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "common.actions"
                                                                    )
                                                                  ) +
                                                                  "\n                                                    "
                                                              )
                                                            ]
                                                          },
                                                          proxy: true
                                                        },
                                                        {
                                                          key:
                                                            "item.data-table-expand",
                                                          fn: function({
                                                            item,
                                                            isExpanded,
                                                            expand
                                                          }) {
                                                            return [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "table-actions-column-min-width"
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-tooltip",
                                                                    {
                                                                      attrs: {
                                                                        top: ""
                                                                      },
                                                                      scopedSlots: _vm._u(
                                                                        [
                                                                          {
                                                                            key:
                                                                              "activator",
                                                                            fn: function({
                                                                              on
                                                                            }) {
                                                                              return [
                                                                                _c(
                                                                                  "v-btn",
                                                                                  _vm._g(
                                                                                    {
                                                                                      attrs: {
                                                                                        icon:
                                                                                          "",
                                                                                        link:
                                                                                          "",
                                                                                        loading:
                                                                                          _vm.saving,
                                                                                        disabled:
                                                                                          _vm.processing
                                                                                      },
                                                                                      on: {
                                                                                        click: function(
                                                                                          $event
                                                                                        ) {
                                                                                          return expand(
                                                                                            !isExpanded
                                                                                          )
                                                                                        }
                                                                                      }
                                                                                    },
                                                                                    on
                                                                                  ),
                                                                                  [
                                                                                    !isExpanded
                                                                                      ? _c(
                                                                                          "v-icon",
                                                                                          {
                                                                                            attrs: {
                                                                                              small:
                                                                                                ""
                                                                                            }
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              "\n                                                                            fa-plus-circle\n                                                                        "
                                                                                            )
                                                                                          ]
                                                                                        )
                                                                                      : _c(
                                                                                          "v-icon",
                                                                                          {
                                                                                            attrs: {
                                                                                              small:
                                                                                                ""
                                                                                            }
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              "\n                                                                            fa-times-circle\n                                                                        "
                                                                                            )
                                                                                          ]
                                                                                        )
                                                                                  ],
                                                                                  1
                                                                                )
                                                                              ]
                                                                            }
                                                                          }
                                                                        ],
                                                                        null,
                                                                        true
                                                                      )
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      !isExpanded
                                                                        ? _c(
                                                                            "span",
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "estimates.parts-selection-individual-part-actions-add-part-to-catalog"
                                                                                  )
                                                                                )
                                                                              )
                                                                            ]
                                                                          )
                                                                        : _c(
                                                                            "span",
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "estimates.parts-selection-individual-part-actions-cancel-adding-part-to-catalog"
                                                                                  )
                                                                                )
                                                                              )
                                                                            ]
                                                                          )
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "v-tooltip",
                                                                    {
                                                                      attrs: {
                                                                        top: ""
                                                                      },
                                                                      scopedSlots: _vm._u(
                                                                        [
                                                                          {
                                                                            key:
                                                                              "activator",
                                                                            fn: function({
                                                                              on
                                                                            }) {
                                                                              return [
                                                                                _c(
                                                                                  "v-btn",
                                                                                  _vm._g(
                                                                                    {
                                                                                      attrs: {
                                                                                        icon:
                                                                                          "",
                                                                                        disabled:
                                                                                          _vm.processing
                                                                                      }
                                                                                    },
                                                                                    on
                                                                                  ),
                                                                                  [
                                                                                    _c(
                                                                                      "v-icon",
                                                                                      {
                                                                                        attrs: {
                                                                                          small:
                                                                                            ""
                                                                                        },
                                                                                        on: {
                                                                                          click: function(
                                                                                            $event
                                                                                          ) {
                                                                                            return _vm.ignorePart(
                                                                                              item
                                                                                            )
                                                                                          }
                                                                                        }
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          "\n                                                                            fa-ban\n                                                                        "
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ],
                                                                                  1
                                                                                )
                                                                              ]
                                                                            }
                                                                          }
                                                                        ],
                                                                        null,
                                                                        true
                                                                      )
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.$t(
                                                                                "estimates.parts-selection-individual-part-actions-ignore-part"
                                                                              )
                                                                            )
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ]
                                                          }
                                                        },
                                                        {
                                                          key: "expanded-item",
                                                          fn: function({
                                                            headers,
                                                            item
                                                          }) {
                                                            return [
                                                              _c(
                                                                "td",
                                                                {
                                                                  staticClass:
                                                                    "table-expanded-row-background-color",
                                                                  attrs: {
                                                                    colspan:
                                                                      headers.length
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-container",
                                                                    {
                                                                      staticStyle: {
                                                                        padding:
                                                                          "0px"
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-row",
                                                                        [
                                                                          _c(
                                                                            "v-col",
                                                                            {
                                                                              staticClass:
                                                                                "align-center justify-space-between",
                                                                              attrs: {
                                                                                cols:
                                                                                  "12"
                                                                              }
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-row",
                                                                                {
                                                                                  staticClass:
                                                                                    "mr-0 ml-0 mt-3",
                                                                                  attrs: {
                                                                                    align:
                                                                                      "center"
                                                                                  }
                                                                                }
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "fd-chip-selector",
                                                                                {
                                                                                  attrs: {
                                                                                    "available-items":
                                                                                      _vm.availableTagsForPartsSelection,
                                                                                    "selected-items":
                                                                                      _vm.selectedTagsForPartsSelection,
                                                                                    "item-label":
                                                                                      "name",
                                                                                    disabled:
                                                                                      _vm.processing,
                                                                                    "control-label": _vm.$t(
                                                                                      "common.add-tags"
                                                                                    ),
                                                                                    "chip-class":
                                                                                      "keywords-selection-chip-colors"
                                                                                  },
                                                                                  on: {
                                                                                    "update:selectedItems": function(
                                                                                      $event
                                                                                    ) {
                                                                                      _vm.selectedTagsForPartsSelection = $event
                                                                                    },
                                                                                    "update:selected-items": function(
                                                                                      $event
                                                                                    ) {
                                                                                      _vm.selectedTagsForPartsSelection = $event
                                                                                    }
                                                                                  }
                                                                                }
                                                                              )
                                                                            ],
                                                                            1
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "v-col",
                                                                            {
                                                                              attrs: {
                                                                                cols:
                                                                                  "12"
                                                                              }
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-row",
                                                                                {
                                                                                  staticClass:
                                                                                    "mr-0 ml-0",
                                                                                  attrs: {
                                                                                    align:
                                                                                      "center"
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "fd-chip-selector",
                                                                                    {
                                                                                      attrs: {
                                                                                        "available-items":
                                                                                          _vm.availableSuppliersForPartsSelection,
                                                                                        "selected-items":
                                                                                          _vm.selectedSuppliersForPartsSelection,
                                                                                        "item-label":
                                                                                          "alias",
                                                                                        disabled:
                                                                                          _vm.processing,
                                                                                        "control-label": _vm.$t(
                                                                                          "common.add-suppliers"
                                                                                        ),
                                                                                        "chip-class":
                                                                                          "keywords-selection-chip-colors-secondary"
                                                                                      },
                                                                                      on: {
                                                                                        "update:selectedItems": function(
                                                                                          $event
                                                                                        ) {
                                                                                          _vm.selectedSuppliersForPartsSelection = $event
                                                                                        },
                                                                                        "update:selected-items": function(
                                                                                          $event
                                                                                        ) {
                                                                                          _vm.selectedSuppliersForPartsSelection = $event
                                                                                        }
                                                                                      }
                                                                                    }
                                                                                  )
                                                                                ],
                                                                                1
                                                                              )
                                                                            ],
                                                                            1
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "v-col",
                                                                            {
                                                                              staticClass:
                                                                                "align-center justify-space-between",
                                                                              attrs: {
                                                                                cols:
                                                                                  "12",
                                                                                sm:
                                                                                  "12",
                                                                                md:
                                                                                  "6"
                                                                              }
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-row",
                                                                                {
                                                                                  staticClass:
                                                                                    "mr-0 ml-0",
                                                                                  attrs: {
                                                                                    align:
                                                                                      "center"
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "v-text-field",
                                                                                    {
                                                                                      attrs: {
                                                                                        label: _vm.$t(
                                                                                          "common.name"
                                                                                        ),
                                                                                        rules: [
                                                                                          _vm
                                                                                            .rules
                                                                                            .required
                                                                                        ],
                                                                                        disabled:
                                                                                          _vm.processing
                                                                                      },
                                                                                      model: {
                                                                                        value:
                                                                                          _vm
                                                                                            .part
                                                                                            .name,
                                                                                        callback: function(
                                                                                          $$v
                                                                                        ) {
                                                                                          _vm.$set(
                                                                                            _vm.part,
                                                                                            "name",
                                                                                            $$v
                                                                                          )
                                                                                        },
                                                                                        expression:
                                                                                          "part.name"
                                                                                      }
                                                                                    }
                                                                                  )
                                                                                ],
                                                                                1
                                                                              )
                                                                            ],
                                                                            1
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "v-col",
                                                                            {
                                                                              attrs: {
                                                                                cols:
                                                                                  "12",
                                                                                sm:
                                                                                  "12",
                                                                                md:
                                                                                  "6"
                                                                              }
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-row",
                                                                                {
                                                                                  staticClass:
                                                                                    "mr-0 ml-0",
                                                                                  attrs: {
                                                                                    align:
                                                                                      "center"
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "v-text-field",
                                                                                    {
                                                                                      attrs: {
                                                                                        label: _vm.$t(
                                                                                          "common.description"
                                                                                        ),
                                                                                        disabled:
                                                                                          _vm.processing,
                                                                                        counter:
                                                                                          "",
                                                                                        maxlength:
                                                                                          "30"
                                                                                      },
                                                                                      model: {
                                                                                        value:
                                                                                          _vm
                                                                                            .part
                                                                                            .description,
                                                                                        callback: function(
                                                                                          $$v
                                                                                        ) {
                                                                                          _vm.$set(
                                                                                            _vm.part,
                                                                                            "description",
                                                                                            $$v
                                                                                          )
                                                                                        },
                                                                                        expression:
                                                                                          "part.description"
                                                                                      }
                                                                                    }
                                                                                  )
                                                                                ],
                                                                                1
                                                                              )
                                                                            ],
                                                                            1
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "v-col",
                                                                            {
                                                                              staticClass:
                                                                                "align-center justify-space-between",
                                                                              attrs: {
                                                                                cols:
                                                                                  "12",
                                                                                sm:
                                                                                  "12",
                                                                                md:
                                                                                  "6"
                                                                              }
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-row",
                                                                                {
                                                                                  staticClass:
                                                                                    "mr-0 ml-0",
                                                                                  attrs: {
                                                                                    align:
                                                                                      "center"
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "v-text-field",
                                                                                    {
                                                                                      attrs: {
                                                                                        label: _vm.$t(
                                                                                          "parts.mpp"
                                                                                        ),
                                                                                        rules: [
                                                                                          _vm
                                                                                            .rules
                                                                                            .required,
                                                                                          _vm
                                                                                            .rules
                                                                                            .numbersonly
                                                                                        ],
                                                                                        disabled:
                                                                                          _vm.processing
                                                                                      },
                                                                                      model: {
                                                                                        value:
                                                                                          _vm
                                                                                            .part
                                                                                            .mpp,
                                                                                        callback: function(
                                                                                          $$v
                                                                                        ) {
                                                                                          _vm.$set(
                                                                                            _vm.part,
                                                                                            "mpp",
                                                                                            _vm._n(
                                                                                              $$v
                                                                                            )
                                                                                          )
                                                                                        },
                                                                                        expression:
                                                                                          "part.mpp"
                                                                                      }
                                                                                    }
                                                                                  )
                                                                                ],
                                                                                1
                                                                              )
                                                                            ],
                                                                            1
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "v-col",
                                                                            {
                                                                              attrs: {
                                                                                cols:
                                                                                  "12",
                                                                                sm:
                                                                                  "12",
                                                                                md:
                                                                                  "6"
                                                                              }
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-row",
                                                                                {
                                                                                  staticClass:
                                                                                    "mr-0 ml-0",
                                                                                  attrs: {
                                                                                    align:
                                                                                      "center"
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "v-text-field",
                                                                                    {
                                                                                      attrs: {
                                                                                        label: _vm.$t(
                                                                                          "parts.weightkg"
                                                                                        ),
                                                                                        rules: [
                                                                                          _vm
                                                                                            .rules
                                                                                            .required,
                                                                                          _vm
                                                                                            .rules
                                                                                            .numbersonly
                                                                                        ],
                                                                                        disabled:
                                                                                          _vm.processing
                                                                                      },
                                                                                      model: {
                                                                                        value:
                                                                                          _vm
                                                                                            .part
                                                                                            .weight,
                                                                                        callback: function(
                                                                                          $$v
                                                                                        ) {
                                                                                          _vm.$set(
                                                                                            _vm.part,
                                                                                            "weight",
                                                                                            _vm._n(
                                                                                              $$v
                                                                                            )
                                                                                          )
                                                                                        },
                                                                                        expression:
                                                                                          "part.weight"
                                                                                      }
                                                                                    }
                                                                                  )
                                                                                ],
                                                                                1
                                                                              )
                                                                            ],
                                                                            1
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "v-col",
                                                                            {
                                                                              staticClass:
                                                                                "align-center justify-space-between",
                                                                              attrs: {
                                                                                cols:
                                                                                  "12",
                                                                                sm:
                                                                                  "12",
                                                                                md:
                                                                                  "3"
                                                                              }
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-row",
                                                                                {
                                                                                  staticClass:
                                                                                    "mr-0 ml-0",
                                                                                  attrs: {
                                                                                    align:
                                                                                      "center"
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "v-text-field",
                                                                                    {
                                                                                      attrs: {
                                                                                        label: _vm.$t(
                                                                                          "parts.cleating"
                                                                                        ),
                                                                                        rules: [
                                                                                          _vm
                                                                                            .rules
                                                                                            .numbersonly
                                                                                        ],
                                                                                        disabled:
                                                                                          _vm.processing
                                                                                      },
                                                                                      model: {
                                                                                        value:
                                                                                          _vm
                                                                                            .part
                                                                                            .cleatingMPP,
                                                                                        callback: function(
                                                                                          $$v
                                                                                        ) {
                                                                                          _vm.$set(
                                                                                            _vm.part,
                                                                                            "cleatingMPP",
                                                                                            _vm._n(
                                                                                              $$v
                                                                                            )
                                                                                          )
                                                                                        },
                                                                                        expression:
                                                                                          "part.cleatingMPP"
                                                                                      }
                                                                                    }
                                                                                  )
                                                                                ],
                                                                                1
                                                                              )
                                                                            ],
                                                                            1
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "v-col",
                                                                            {
                                                                              staticClass:
                                                                                "align-center justify-space-between",
                                                                              attrs: {
                                                                                cols:
                                                                                  "12",
                                                                                sm:
                                                                                  "12",
                                                                                md:
                                                                                  "3"
                                                                              }
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-row",
                                                                                {
                                                                                  staticClass:
                                                                                    "mr-0 ml-0",
                                                                                  attrs: {
                                                                                    align:
                                                                                      "center"
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "v-text-field",
                                                                                    {
                                                                                      attrs: {
                                                                                        label: _vm.$t(
                                                                                          "parts.lashing"
                                                                                        ),
                                                                                        rules: [
                                                                                          _vm
                                                                                            .rules
                                                                                            .numbersonly
                                                                                        ],
                                                                                        disabled:
                                                                                          _vm.processing
                                                                                      },
                                                                                      model: {
                                                                                        value:
                                                                                          _vm
                                                                                            .part
                                                                                            .lashingMPP,
                                                                                        callback: function(
                                                                                          $$v
                                                                                        ) {
                                                                                          _vm.$set(
                                                                                            _vm.part,
                                                                                            "lashingMPP",
                                                                                            _vm._n(
                                                                                              $$v
                                                                                            )
                                                                                          )
                                                                                        },
                                                                                        expression:
                                                                                          "part.lashingMPP"
                                                                                      }
                                                                                    }
                                                                                  )
                                                                                ],
                                                                                1
                                                                              )
                                                                            ],
                                                                            1
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "v-col",
                                                                            {
                                                                              staticClass:
                                                                                "align-center justify-space-between",
                                                                              attrs: {
                                                                                cols:
                                                                                  "12",
                                                                                sm:
                                                                                  "12",
                                                                                md:
                                                                                  "3"
                                                                              }
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-row",
                                                                                {
                                                                                  staticClass:
                                                                                    "mr-0 ml-0",
                                                                                  attrs: {
                                                                                    align:
                                                                                      "center"
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "v-text-field",
                                                                                    {
                                                                                      attrs: {
                                                                                        label: _vm.$t(
                                                                                          "parts.carpentry"
                                                                                        ),
                                                                                        rules: [
                                                                                          _vm
                                                                                            .rules
                                                                                            .numbersonly
                                                                                        ],
                                                                                        disabled:
                                                                                          _vm.processing
                                                                                      },
                                                                                      model: {
                                                                                        value:
                                                                                          _vm
                                                                                            .part
                                                                                            .carpentryMPP,
                                                                                        callback: function(
                                                                                          $$v
                                                                                        ) {
                                                                                          _vm.$set(
                                                                                            _vm.part,
                                                                                            "carpentryMPP",
                                                                                            _vm._n(
                                                                                              $$v
                                                                                            )
                                                                                          )
                                                                                        },
                                                                                        expression:
                                                                                          "part.carpentryMPP"
                                                                                      }
                                                                                    }
                                                                                  )
                                                                                ],
                                                                                1
                                                                              )
                                                                            ],
                                                                            1
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "v-col",
                                                                            {
                                                                              staticClass:
                                                                                "align-center justify-space-between",
                                                                              attrs: {
                                                                                cols:
                                                                                  "12",
                                                                                sm:
                                                                                  "12",
                                                                                md:
                                                                                  "3"
                                                                              }
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-row",
                                                                                {
                                                                                  staticClass:
                                                                                    "mr-0 ml-0",
                                                                                  attrs: {
                                                                                    align:
                                                                                      "center"
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "v-text-field",
                                                                                    {
                                                                                      attrs: {
                                                                                        label: _vm.$t(
                                                                                          "parts.other"
                                                                                        ),
                                                                                        rules: [
                                                                                          _vm
                                                                                            .rules
                                                                                            .numbersonly
                                                                                        ],
                                                                                        disabled:
                                                                                          _vm.processing
                                                                                      },
                                                                                      model: {
                                                                                        value:
                                                                                          _vm
                                                                                            .part
                                                                                            .otherMPP,
                                                                                        callback: function(
                                                                                          $$v
                                                                                        ) {
                                                                                          _vm.$set(
                                                                                            _vm.part,
                                                                                            "otherMPP",
                                                                                            _vm._n(
                                                                                              $$v
                                                                                            )
                                                                                          )
                                                                                        },
                                                                                        expression:
                                                                                          "part.otherMPP"
                                                                                      }
                                                                                    }
                                                                                  )
                                                                                ],
                                                                                1
                                                                              )
                                                                            ],
                                                                            1
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "v-col",
                                                                            {
                                                                              attrs: {
                                                                                cols:
                                                                                  "12"
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm
                                                                                .inlineMessage
                                                                                .message
                                                                                ? _c(
                                                                                    "v-alert",
                                                                                    {
                                                                                      attrs: {
                                                                                        type:
                                                                                          _vm
                                                                                            .inlineMessage
                                                                                            .type
                                                                                      }
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "\n                                                                            " +
                                                                                          _vm._s(
                                                                                            _vm
                                                                                              .inlineMessage
                                                                                              .message
                                                                                          ) +
                                                                                          "\n                                                                        "
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                : _vm._e()
                                                                            ],
                                                                            1
                                                                          )
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "v-row",
                                                                        {
                                                                          attrs: {
                                                                            justify:
                                                                              "end"
                                                                          }
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "pr-3 pb-3"
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-btn",
                                                                                {
                                                                                  attrs: {
                                                                                    text:
                                                                                      "",
                                                                                    link:
                                                                                      "",
                                                                                    loading:
                                                                                      _vm.processing,
                                                                                    disabled:
                                                                                      _vm.processing
                                                                                  },
                                                                                  on: {
                                                                                    click: function(
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.cancelItemAdd(
                                                                                        item
                                                                                      )
                                                                                    }
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      _vm.$t(
                                                                                        "common.cancel"
                                                                                      )
                                                                                    )
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "v-btn",
                                                                                {
                                                                                  attrs: {
                                                                                    color:
                                                                                      "primary",
                                                                                    loading:
                                                                                      _vm.processing,
                                                                                    disabled:
                                                                                      _vm.processing
                                                                                  },
                                                                                  on: {
                                                                                    click: function(
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.mockSavePartToCatalog(
                                                                                        item
                                                                                      )
                                                                                    }
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      _vm.$t(
                                                                                        "common.save"
                                                                                      )
                                                                                    )
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ],
                                                                            1
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ]
                                                          }
                                                        },
                                                        {
                                                          key: "item.ignored",
                                                          fn: function({
                                                            item
                                                          }) {
                                                            return [
                                                              !item.ignored
                                                                ? _c(
                                                                    "div",
                                                                    [
                                                                      _c(
                                                                        "v-tooltip",
                                                                        {
                                                                          attrs: {
                                                                            top:
                                                                              ""
                                                                          },
                                                                          scopedSlots: _vm._u(
                                                                            [
                                                                              {
                                                                                key:
                                                                                  "activator",
                                                                                fn: function({
                                                                                  on
                                                                                }) {
                                                                                  return [
                                                                                    _c(
                                                                                      "v-icon",
                                                                                      _vm._g(
                                                                                        {
                                                                                          attrs: {
                                                                                            color:
                                                                                              "red",
                                                                                            dark:
                                                                                              ""
                                                                                          }
                                                                                        },
                                                                                        on
                                                                                      ),
                                                                                      [
                                                                                        _vm._v(
                                                                                          "fa-exclamation-triangle"
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ]
                                                                                }
                                                                              }
                                                                            ],
                                                                            null,
                                                                            true
                                                                          )
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "span",
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "estimates.parts-selection-individual-part-needs-to-be-addressed"
                                                                                  )
                                                                                )
                                                                              )
                                                                            ]
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                : _c(
                                                                    "div",
                                                                    [
                                                                      _c(
                                                                        "v-tooltip",
                                                                        {
                                                                          attrs: {
                                                                            top:
                                                                              ""
                                                                          },
                                                                          scopedSlots: _vm._u(
                                                                            [
                                                                              {
                                                                                key:
                                                                                  "activator",
                                                                                fn: function({
                                                                                  on
                                                                                }) {
                                                                                  return [
                                                                                    _c(
                                                                                      "v-icon",
                                                                                      _vm._g(
                                                                                        {
                                                                                          attrs: {
                                                                                            color:
                                                                                              "blue",
                                                                                            dark:
                                                                                              ""
                                                                                          }
                                                                                        },
                                                                                        on
                                                                                      ),
                                                                                      [
                                                                                        _vm._v(
                                                                                          "fa-ban"
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ]
                                                                                }
                                                                              }
                                                                            ],
                                                                            null,
                                                                            true
                                                                          )
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "span",
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "estimates.parts-selection-individual-part-ignored"
                                                                                  )
                                                                                )
                                                                              )
                                                                            ]
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      false,
                                                      2334794442
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c(
                                            "v-card",
                                            { attrs: { flat: "", tile: "" } },
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "pa-0",
                                                  attrs: { cols: "12" }
                                                },
                                                [
                                                  _c(
                                                    "v-row",
                                                    {
                                                      staticClass: "ml-0 mr-0"
                                                    },
                                                    [
                                                      _c(
                                                        "v-col",
                                                        {
                                                          staticClass:
                                                            "pb-sm-0",
                                                          attrs: {
                                                            cols: "12",
                                                            sm: "12",
                                                            md: "7"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "v-card-title",
                                                            {
                                                              staticClass:
                                                                "pa-0"
                                                            },
                                                            [
                                                              _c("v-switch", {
                                                                attrs: {
                                                                  label: _vm.$t(
                                                                    "clients.projects.only-included-label"
                                                                  )
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.showOnlyIncluded,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.showOnlyIncluded = $$v
                                                                  },
                                                                  expression:
                                                                    "showOnlyIncluded"
                                                                }
                                                              }),
                                                              _vm._v(" "),
                                                              _c(
                                                                "v-tooltip",
                                                                {
                                                                  attrs: {
                                                                    top: ""
                                                                  },
                                                                  scopedSlots: _vm._u(
                                                                    [
                                                                      {
                                                                        key:
                                                                          "activator",
                                                                        fn: function({
                                                                          on
                                                                        }) {
                                                                          return [
                                                                            _c(
                                                                              "v-icon",
                                                                              _vm._g(
                                                                                {
                                                                                  staticClass:
                                                                                    "ml-4",
                                                                                  attrs: {
                                                                                    color:
                                                                                      "black",
                                                                                    dark:
                                                                                      ""
                                                                                  }
                                                                                },
                                                                                on
                                                                              ),
                                                                              [
                                                                                _vm._v(
                                                                                  "info"
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        }
                                                                      }
                                                                    ]
                                                                  )
                                                                },
                                                                [
                                                                  _vm._v(" "),
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "clients.projects.parts-selection-sub-menu-information"
                                                                        )
                                                                      )
                                                                    )
                                                                  ])
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          staticClass:
                                                            "pt-sm-1 pt-md-3 pb-0",
                                                          attrs: {
                                                            cols: "12",
                                                            sm: "12",
                                                            md: "5"
                                                          }
                                                        },
                                                        [
                                                          _c("v-combobox", {
                                                            class: [
                                                              _vm
                                                                .tagsSelectedForFiltering
                                                                .length > 0
                                                                ? "fd-combobox-control-item-selected-small"
                                                                : "fd-combobox-control-item-not-selected"
                                                            ],
                                                            attrs: {
                                                              items:
                                                                _vm.tagsInUse,
                                                              label: _vm.$t(
                                                                "common.filter-by-tags"
                                                              ),
                                                              "item-text":
                                                                "name",
                                                              "item-value":
                                                                "id",
                                                              multiple: "",
                                                              "small-chips": "",
                                                              clearable: ""
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.tagsSelectedForFiltering,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.tagsSelectedForFiltering = $$v
                                                              },
                                                              expression:
                                                                "tagsSelectedForFiltering"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-row",
                                                    {
                                                      staticClass: "mr-0 ml-0"
                                                    },
                                                    [
                                                      _c(
                                                        "v-col",
                                                        {
                                                          staticClass:
                                                            "order-md-1 order-0 pb-0",
                                                          attrs: {
                                                            cols: "12",
                                                            sm: "12",
                                                            md: "5"
                                                          }
                                                        },
                                                        [
                                                          _c("v-select", {
                                                            class: [
                                                              _vm
                                                                .suppliersSelectedForFiltering
                                                                .length > 0
                                                                ? "fd-select-control-item-selected"
                                                                : "",
                                                              "pt-3"
                                                            ],
                                                            attrs: {
                                                              "item-text":
                                                                "alias",
                                                              "item-value":
                                                                "id",
                                                              items:
                                                                _vm.suppliersInUse,
                                                              label: _vm.$t(
                                                                "clients.projects.parts-selection-supplier-filter-label"
                                                              ),
                                                              multiple: "",
                                                              "small-chips": "",
                                                              clearable: ""
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.suppliersSelectedForFiltering,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.suppliersSelectedForFiltering = $$v
                                                              },
                                                              expression:
                                                                "suppliersSelectedForFiltering"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          staticClass:
                                                            "order-0",
                                                          attrs: {
                                                            cols: "12",
                                                            sm: "12",
                                                            md: "7"
                                                          }
                                                        },
                                                        [
                                                          _c("v-text-field", {
                                                            attrs: {
                                                              "append-icon":
                                                                "search",
                                                              label: "Search",
                                                              "single-line": "",
                                                              "hide-details":
                                                                "",
                                                              clearable: ""
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.tablesearchparts,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.tablesearchparts = $$v
                                                              },
                                                              expression:
                                                                "tablesearchparts"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c("v-data-table", {
                                                    staticClass:
                                                      "fixed-header v-table__overflow",
                                                    staticStyle: {
                                                      "max-height":
                                                        "calc(60vh - 10px)",
                                                      "backface-visibility":
                                                        "hidden"
                                                    },
                                                    attrs: {
                                                      headers:
                                                        _vm.computedPartsHeaders,
                                                      items:
                                                        _vm.mockPartsSelection,
                                                      search:
                                                        _vm.tablesearchparts,
                                                      loading: _vm.processing,
                                                      "loading-text": _vm.$t(
                                                        "common.table-loading-message"
                                                      ),
                                                      "sort-by":
                                                        _vm.sortPartsTableBy
                                                    },
                                                    scopedSlots: _vm._u([
                                                      {
                                                        key: "header.name",
                                                        fn: function({
                                                          header
                                                        }) {
                                                          return [
                                                            _vm._v(
                                                              "\n                                                            " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "common." +
                                                                      header.value
                                                                  )
                                                                ) +
                                                                "\n                                                        "
                                                            )
                                                          ]
                                                        }
                                                      },
                                                      {
                                                        key:
                                                          "header.description",
                                                        fn: function({
                                                          header
                                                        }) {
                                                          return [
                                                            _vm._v(
                                                              "\n                                                            " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "common." +
                                                                      header.value
                                                                  )
                                                                ) +
                                                                "\n                                                        "
                                                            )
                                                          ]
                                                        }
                                                      },
                                                      {
                                                        key: "header.quantity",
                                                        fn: function({
                                                          header
                                                        }) {
                                                          return [
                                                            _vm._v(
                                                              "\n                                                            " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "common." +
                                                                      header.value
                                                                  )
                                                                ) +
                                                                "\n                                                        "
                                                            )
                                                          ]
                                                        }
                                                      },
                                                      {
                                                        key: "item.quantity",
                                                        fn: function({ item }) {
                                                          return [
                                                            _c(
                                                              "fd-inline-edit-dialog",
                                                              {
                                                                attrs: {
                                                                  value:
                                                                    item.quantity !=
                                                                    null
                                                                      ? item.quantity
                                                                      : "0",
                                                                  rules: [
                                                                    _vm.rules
                                                                      .numbersonly
                                                                  ],
                                                                  label: "Edit",
                                                                  numeric: ""
                                                                },
                                                                on: {
                                                                  "update:value": value =>
                                                                    _vm.saveQuantity(
                                                                      item,
                                                                      "quantity",
                                                                      value
                                                                    )
                                                                }
                                                              }
                                                            )
                                                          ]
                                                        }
                                                      }
                                                    ])
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-card",
                                            {
                                              staticClass:
                                                "ignored-section-override",
                                              attrs: { flat: "", tile: "" }
                                            },
                                            [
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "12" } },
                                                [
                                                  _c(
                                                    "v-row",
                                                    [
                                                      _c(
                                                        "v-col",
                                                        {
                                                          attrs: {
                                                            cols: "12",
                                                            sm: "12",
                                                            md: "7"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "v-card-title",
                                                            {
                                                              staticClass:
                                                                "pt-2 pl-sm-0 pl-md-4"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                                                                " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "estimates.parts-selection-existing-ignored-parts-title"
                                                                    )
                                                                  ) +
                                                                  "\n                                                                "
                                                              ),
                                                              _c(
                                                                "v-tooltip",
                                                                {
                                                                  attrs: {
                                                                    top: ""
                                                                  },
                                                                  scopedSlots: _vm._u(
                                                                    [
                                                                      {
                                                                        key:
                                                                          "activator",
                                                                        fn: function({
                                                                          on
                                                                        }) {
                                                                          return [
                                                                            _c(
                                                                              "v-icon",
                                                                              _vm._g(
                                                                                {
                                                                                  staticClass:
                                                                                    "ml-4",
                                                                                  attrs: {
                                                                                    color:
                                                                                      "black",
                                                                                    dark:
                                                                                      ""
                                                                                  }
                                                                                },
                                                                                on
                                                                              ),
                                                                              [
                                                                                _vm._v(
                                                                                  "info"
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        }
                                                                      }
                                                                    ]
                                                                  )
                                                                },
                                                                [
                                                                  _vm._v(" "),
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "estimates.parts-selection-existing-ignored-parts-info-text"
                                                                        )
                                                                      )
                                                                    )
                                                                  ])
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-row",
                                                    [
                                                      _c(
                                                        "v-col",
                                                        {
                                                          attrs: { cols: "12" }
                                                        },
                                                        [
                                                          _c(
                                                            "v-row",
                                                            {
                                                              staticClass:
                                                                "pl-3 pr-3"
                                                            },
                                                            [
                                                              _c(
                                                                "v-text-field",
                                                                {
                                                                  attrs: {
                                                                    "append-icon":
                                                                      "search",
                                                                    label:
                                                                      "Search",
                                                                    "single-line":
                                                                      "",
                                                                    "hide-details":
                                                                      ""
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm.tablesearchIgnoredParts,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.tablesearchIgnoredParts = $$v
                                                                    },
                                                                    expression:
                                                                      "tablesearchIgnoredParts"
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c("v-data-table", {
                                                    staticClass:
                                                      "fd-actions-table",
                                                    attrs: {
                                                      headers:
                                                        _vm.headersIgnoredParts,
                                                      items: _vm.ignoredparts,
                                                      search:
                                                        _vm.tablesearchIgnoredParts,
                                                      "single-expand":
                                                        _vm.singleExpand,
                                                      expanded:
                                                        _vm.expandedIgnoredParts,
                                                      "item-key": "name",
                                                      "show-expand":
                                                        _vm.showExpand,
                                                      "footer-props": {
                                                        "items-per-page-options": [
                                                          1,
                                                          5
                                                        ]
                                                      },
                                                      "items-per-page": 5
                                                    },
                                                    on: {
                                                      "update:expanded": function(
                                                        $event
                                                      ) {
                                                        _vm.expandedIgnoredParts = $event
                                                      }
                                                    },
                                                    scopedSlots: _vm._u([
                                                      {
                                                        key: "header.name",
                                                        fn: function({
                                                          header
                                                        }) {
                                                          return [
                                                            _vm._v(
                                                              "\n                                                            " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "common." +
                                                                      header.value
                                                                  )
                                                                ) +
                                                                "\n                                                        "
                                                            )
                                                          ]
                                                        }
                                                      },
                                                      {
                                                        key: "header.quantity",
                                                        fn: function({
                                                          header
                                                        }) {
                                                          return [
                                                            _vm._v(
                                                              "\n                                                            " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "common." +
                                                                      header.value
                                                                  )
                                                                ) +
                                                                "\n                                                        "
                                                            )
                                                          ]
                                                        }
                                                      },
                                                      {
                                                        key:
                                                          "header.data-table-expand",
                                                        fn: function() {
                                                          return [
                                                            _vm._v(
                                                              "\n                                                            " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "common.actions"
                                                                  )
                                                                ) +
                                                                "\n                                                        "
                                                            )
                                                          ]
                                                        },
                                                        proxy: true
                                                      },
                                                      {
                                                        key:
                                                          "item.data-table-expand",
                                                        fn: function({
                                                          item,
                                                          isExpanded,
                                                          expand
                                                        }) {
                                                          return [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "table-actions-column-min-width"
                                                              },
                                                              [
                                                                _c(
                                                                  "v-tooltip",
                                                                  {
                                                                    attrs: {
                                                                      top: ""
                                                                    },
                                                                    scopedSlots: _vm._u(
                                                                      [
                                                                        {
                                                                          key:
                                                                            "activator",
                                                                          fn: function({
                                                                            on
                                                                          }) {
                                                                            return [
                                                                              _c(
                                                                                "v-btn",
                                                                                _vm._g(
                                                                                  {
                                                                                    attrs: {
                                                                                      icon:
                                                                                        "",
                                                                                      link:
                                                                                        "",
                                                                                      loading:
                                                                                        _vm.saving,
                                                                                      disabled:
                                                                                        _vm.processing
                                                                                    },
                                                                                    on: {
                                                                                      click: function(
                                                                                        $event
                                                                                      ) {
                                                                                        return expand(
                                                                                          !isExpanded
                                                                                        )
                                                                                      }
                                                                                    }
                                                                                  },
                                                                                  on
                                                                                ),
                                                                                [
                                                                                  !isExpanded
                                                                                    ? _c(
                                                                                        "v-icon",
                                                                                        {
                                                                                          attrs: {
                                                                                            small:
                                                                                              ""
                                                                                          }
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            "\n                                                                                fa-plus-circle\n                                                                            "
                                                                                          )
                                                                                        ]
                                                                                      )
                                                                                    : _c(
                                                                                        "v-icon",
                                                                                        {
                                                                                          attrs: {
                                                                                            small:
                                                                                              ""
                                                                                          }
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            "\n                                                                                fa-times-circle\n                                                                            "
                                                                                          )
                                                                                        ]
                                                                                      )
                                                                                ],
                                                                                1
                                                                              )
                                                                            ]
                                                                          }
                                                                        }
                                                                      ],
                                                                      null,
                                                                      true
                                                                    )
                                                                  },
                                                                  [
                                                                    _vm._v(" "),
                                                                    !isExpanded
                                                                      ? _c(
                                                                          "span",
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                _vm.$t(
                                                                                  "estimates.parts-selection-individual-part-actions-add-part-to-catalog"
                                                                                )
                                                                              )
                                                                            )
                                                                          ]
                                                                        )
                                                                      : _c(
                                                                          "span",
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                _vm.$t(
                                                                                  "estimates.parts-selection-individual-part-actions-cancel-adding-part-to-catalog"
                                                                                )
                                                                              )
                                                                            )
                                                                          ]
                                                                        )
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "v-tooltip",
                                                                  {
                                                                    attrs: {
                                                                      top: ""
                                                                    },
                                                                    scopedSlots: _vm._u(
                                                                      [
                                                                        {
                                                                          key:
                                                                            "activator",
                                                                          fn: function({
                                                                            on
                                                                          }) {
                                                                            return [
                                                                              _c(
                                                                                "v-btn",
                                                                                _vm._g(
                                                                                  {
                                                                                    attrs: {
                                                                                      icon:
                                                                                        "",
                                                                                      disabled:
                                                                                        _vm.processing
                                                                                    }
                                                                                  },
                                                                                  on
                                                                                ),
                                                                                [
                                                                                  _c(
                                                                                    "v-icon",
                                                                                    {
                                                                                      attrs: {
                                                                                        small:
                                                                                          ""
                                                                                      },
                                                                                      on: {
                                                                                        click: function(
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.ignorePart(
                                                                                            item
                                                                                          )
                                                                                        }
                                                                                      }
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "\n                                                                                mdi-delete\n                                                                            "
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ],
                                                                                1
                                                                              )
                                                                            ]
                                                                          }
                                                                        }
                                                                      ],
                                                                      null,
                                                                      true
                                                                    )
                                                                  },
                                                                  [
                                                                    _vm._v(" "),
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            "common.delete"
                                                                          )
                                                                        )
                                                                      )
                                                                    ])
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ]
                                                        }
                                                      },
                                                      {
                                                        key: "expanded-item",
                                                        fn: function({
                                                          headers,
                                                          item
                                                        }) {
                                                          return [
                                                            _c(
                                                              "td",
                                                              {
                                                                staticClass:
                                                                  "table-expanded-row-background-color",
                                                                attrs: {
                                                                  colspan:
                                                                    headers.length
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "v-container",
                                                                  {
                                                                    staticStyle: {
                                                                      padding:
                                                                        "0px"
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-row",
                                                                      [
                                                                        _c(
                                                                          "v-col",
                                                                          {
                                                                            staticClass:
                                                                              "align-center justify-space-between",
                                                                            attrs: {
                                                                              cols:
                                                                                "12"
                                                                            }
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "v-row",
                                                                              {
                                                                                staticClass:
                                                                                  "mr-0 ml-0 mt-3",
                                                                                attrs: {
                                                                                  align:
                                                                                    "center"
                                                                                }
                                                                              }
                                                                            ),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "fd-chip-selector",
                                                                              {
                                                                                attrs: {
                                                                                  "available-items":
                                                                                    _vm.availableTagsForPartsSelection,
                                                                                  "selected-items":
                                                                                    _vm.selectedTagsForPartsSelection,
                                                                                  "item-label":
                                                                                    "name",
                                                                                  disabled:
                                                                                    _vm.processing,
                                                                                  "control-label": _vm.$t(
                                                                                    "common.add-tags"
                                                                                  ),
                                                                                  "chip-class":
                                                                                    "keywords-selection-chip-colors"
                                                                                },
                                                                                on: {
                                                                                  "update:selectedItems": function(
                                                                                    $event
                                                                                  ) {
                                                                                    _vm.selectedTagsForPartsSelection = $event
                                                                                  },
                                                                                  "update:selected-items": function(
                                                                                    $event
                                                                                  ) {
                                                                                    _vm.selectedTagsForPartsSelection = $event
                                                                                  }
                                                                                }
                                                                              }
                                                                            )
                                                                          ],
                                                                          1
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "v-col",
                                                                          {
                                                                            attrs: {
                                                                              cols:
                                                                                "12"
                                                                            }
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "v-row",
                                                                              {
                                                                                staticClass:
                                                                                  "mr-0 ml-0",
                                                                                attrs: {
                                                                                  align:
                                                                                    "center"
                                                                                }
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "fd-chip-selector",
                                                                                  {
                                                                                    attrs: {
                                                                                      "available-items":
                                                                                        _vm.availableSuppliersForPartsSelection,
                                                                                      "selected-items":
                                                                                        _vm.selectedSuppliersForPartsSelection,
                                                                                      "item-label":
                                                                                        "alias",
                                                                                      disabled:
                                                                                        _vm.processing,
                                                                                      "control-label": _vm.$t(
                                                                                        "common.add-suppliers"
                                                                                      ),
                                                                                      "chip-class":
                                                                                        "keywords-selection-chip-colors-secondary"
                                                                                    },
                                                                                    on: {
                                                                                      "update:selectedItems": function(
                                                                                        $event
                                                                                      ) {
                                                                                        _vm.selectedSuppliersForPartsSelection = $event
                                                                                      },
                                                                                      "update:selected-items": function(
                                                                                        $event
                                                                                      ) {
                                                                                        _vm.selectedSuppliersForPartsSelection = $event
                                                                                      }
                                                                                    }
                                                                                  }
                                                                                )
                                                                              ],
                                                                              1
                                                                            )
                                                                          ],
                                                                          1
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "v-col",
                                                                          {
                                                                            staticClass:
                                                                              "align-center justify-space-between",
                                                                            attrs: {
                                                                              cols:
                                                                                "6"
                                                                            }
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "v-row",
                                                                              {
                                                                                staticClass:
                                                                                  "mr-0 ml-0",
                                                                                attrs: {
                                                                                  align:
                                                                                    "center"
                                                                                }
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "v-text-field",
                                                                                  {
                                                                                    attrs: {
                                                                                      label: _vm.$t(
                                                                                        "common.name"
                                                                                      ),
                                                                                      rules: [
                                                                                        _vm
                                                                                          .rules
                                                                                          .required
                                                                                      ],
                                                                                      disabled:
                                                                                        _vm.processing
                                                                                    },
                                                                                    model: {
                                                                                      value:
                                                                                        _vm
                                                                                          .part
                                                                                          .name,
                                                                                      callback: function(
                                                                                        $$v
                                                                                      ) {
                                                                                        _vm.$set(
                                                                                          _vm.part,
                                                                                          "name",
                                                                                          $$v
                                                                                        )
                                                                                      },
                                                                                      expression:
                                                                                        "part.name"
                                                                                    }
                                                                                  }
                                                                                )
                                                                              ],
                                                                              1
                                                                            )
                                                                          ],
                                                                          1
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "v-col",
                                                                          {
                                                                            attrs: {
                                                                              cols:
                                                                                "6"
                                                                            }
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "v-row",
                                                                              {
                                                                                staticClass:
                                                                                  "mr-0 ml-0",
                                                                                attrs: {
                                                                                  align:
                                                                                    "center"
                                                                                }
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "v-text-field",
                                                                                  {
                                                                                    attrs: {
                                                                                      label: _vm.$t(
                                                                                        "common.description"
                                                                                      ),
                                                                                      disabled:
                                                                                        _vm.processing,
                                                                                      counter:
                                                                                        "",
                                                                                      maxlength:
                                                                                        "30"
                                                                                    },
                                                                                    model: {
                                                                                      value:
                                                                                        _vm
                                                                                          .part
                                                                                          .description,
                                                                                      callback: function(
                                                                                        $$v
                                                                                      ) {
                                                                                        _vm.$set(
                                                                                          _vm.part,
                                                                                          "description",
                                                                                          $$v
                                                                                        )
                                                                                      },
                                                                                      expression:
                                                                                        "part.description"
                                                                                    }
                                                                                  }
                                                                                )
                                                                              ],
                                                                              1
                                                                            )
                                                                          ],
                                                                          1
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "v-col",
                                                                          {
                                                                            attrs: {
                                                                              cols:
                                                                                "12"
                                                                            }
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "v-row",
                                                                              {
                                                                                staticClass:
                                                                                  "mr-0 ml-0",
                                                                                attrs: {
                                                                                  align:
                                                                                    "center"
                                                                                }
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "v-col",
                                                                                  {
                                                                                    staticClass:
                                                                                      "pl-0",
                                                                                    attrs: {
                                                                                      cols:
                                                                                        "6"
                                                                                    }
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "v-text-field",
                                                                                      {
                                                                                        attrs: {
                                                                                          label: _vm.$t(
                                                                                            "parts.mpp"
                                                                                          ),
                                                                                          rules: [
                                                                                            _vm
                                                                                              .rules
                                                                                              .required,
                                                                                            _vm
                                                                                              .rules
                                                                                              .numbersonly
                                                                                          ],
                                                                                          disabled:
                                                                                            _vm.processing
                                                                                        },
                                                                                        model: {
                                                                                          value:
                                                                                            _vm
                                                                                              .part
                                                                                              .mpp,
                                                                                          callback: function(
                                                                                            $$v
                                                                                          ) {
                                                                                            _vm.$set(
                                                                                              _vm.part,
                                                                                              "mpp",
                                                                                              _vm._n(
                                                                                                $$v
                                                                                              )
                                                                                            )
                                                                                          },
                                                                                          expression:
                                                                                            "part.mpp"
                                                                                        }
                                                                                      }
                                                                                    )
                                                                                  ],
                                                                                  1
                                                                                ),
                                                                                _vm._v(
                                                                                  " "
                                                                                ),
                                                                                _c(
                                                                                  "v-col",
                                                                                  {
                                                                                    staticClass:
                                                                                      "align-center justify-space-between",
                                                                                    attrs: {
                                                                                      cols:
                                                                                        "6"
                                                                                    }
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "v-row",
                                                                                      {
                                                                                        staticClass:
                                                                                          "mr-0 ml-0",
                                                                                        attrs: {
                                                                                          align:
                                                                                            "center"
                                                                                        }
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "v-text-field",
                                                                                          {
                                                                                            attrs: {
                                                                                              label: _vm.$t(
                                                                                                "parts.weightkg"
                                                                                              ),
                                                                                              rules: [
                                                                                                _vm
                                                                                                  .rules
                                                                                                  .required,
                                                                                                _vm
                                                                                                  .rules
                                                                                                  .numbersonly
                                                                                              ],
                                                                                              disabled:
                                                                                                _vm.processing
                                                                                            },
                                                                                            model: {
                                                                                              value:
                                                                                                _vm
                                                                                                  .part
                                                                                                  .weight,
                                                                                              callback: function(
                                                                                                $$v
                                                                                              ) {
                                                                                                _vm.$set(
                                                                                                  _vm.part,
                                                                                                  "weight",
                                                                                                  _vm._n(
                                                                                                    $$v
                                                                                                  )
                                                                                                )
                                                                                              },
                                                                                              expression:
                                                                                                "part.weight"
                                                                                            }
                                                                                          }
                                                                                        )
                                                                                      ],
                                                                                      1
                                                                                    )
                                                                                  ],
                                                                                  1
                                                                                )
                                                                              ],
                                                                              1
                                                                            )
                                                                          ],
                                                                          1
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "v-col",
                                                                          {
                                                                            staticClass:
                                                                              "align-center justify-space-between",
                                                                            attrs: {
                                                                              cols:
                                                                                "3"
                                                                            }
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "v-row",
                                                                              {
                                                                                staticClass:
                                                                                  "mr-0 ml-0",
                                                                                attrs: {
                                                                                  align:
                                                                                    "center"
                                                                                }
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "v-text-field",
                                                                                  {
                                                                                    attrs: {
                                                                                      label: _vm.$t(
                                                                                        "parts.cleating"
                                                                                      ),
                                                                                      rules: [
                                                                                        _vm
                                                                                          .rules
                                                                                          .numbersonly
                                                                                      ],
                                                                                      disabled:
                                                                                        _vm.processing
                                                                                    },
                                                                                    model: {
                                                                                      value:
                                                                                        _vm
                                                                                          .part
                                                                                          .cleatingMPP,
                                                                                      callback: function(
                                                                                        $$v
                                                                                      ) {
                                                                                        _vm.$set(
                                                                                          _vm.part,
                                                                                          "cleatingMPP",
                                                                                          _vm._n(
                                                                                            $$v
                                                                                          )
                                                                                        )
                                                                                      },
                                                                                      expression:
                                                                                        "part.cleatingMPP"
                                                                                    }
                                                                                  }
                                                                                )
                                                                              ],
                                                                              1
                                                                            )
                                                                          ],
                                                                          1
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "v-col",
                                                                          {
                                                                            staticClass:
                                                                              "align-center justify-space-between",
                                                                            attrs: {
                                                                              cols:
                                                                                "3"
                                                                            }
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "v-row",
                                                                              {
                                                                                staticClass:
                                                                                  "mr-0 ml-0",
                                                                                attrs: {
                                                                                  align:
                                                                                    "center"
                                                                                }
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "v-text-field",
                                                                                  {
                                                                                    attrs: {
                                                                                      label: _vm.$t(
                                                                                        "parts.lashing"
                                                                                      ),
                                                                                      rules: [
                                                                                        _vm
                                                                                          .rules
                                                                                          .numbersonly
                                                                                      ],
                                                                                      disabled:
                                                                                        _vm.processing
                                                                                    },
                                                                                    model: {
                                                                                      value:
                                                                                        _vm
                                                                                          .part
                                                                                          .lashingMPP,
                                                                                      callback: function(
                                                                                        $$v
                                                                                      ) {
                                                                                        _vm.$set(
                                                                                          _vm.part,
                                                                                          "lashingMPP",
                                                                                          _vm._n(
                                                                                            $$v
                                                                                          )
                                                                                        )
                                                                                      },
                                                                                      expression:
                                                                                        "part.lashingMPP"
                                                                                    }
                                                                                  }
                                                                                )
                                                                              ],
                                                                              1
                                                                            )
                                                                          ],
                                                                          1
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "v-col",
                                                                          {
                                                                            staticClass:
                                                                              "align-center justify-space-between",
                                                                            attrs: {
                                                                              cols:
                                                                                "3"
                                                                            }
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "v-row",
                                                                              {
                                                                                staticClass:
                                                                                  "mr-0 ml-0",
                                                                                attrs: {
                                                                                  align:
                                                                                    "center"
                                                                                }
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "v-text-field",
                                                                                  {
                                                                                    attrs: {
                                                                                      label: _vm.$t(
                                                                                        "parts.carpentry"
                                                                                      ),
                                                                                      rules: [
                                                                                        _vm
                                                                                          .rules
                                                                                          .numbersonly
                                                                                      ],
                                                                                      disabled:
                                                                                        _vm.processing
                                                                                    },
                                                                                    model: {
                                                                                      value:
                                                                                        _vm
                                                                                          .part
                                                                                          .carpentryMPP,
                                                                                      callback: function(
                                                                                        $$v
                                                                                      ) {
                                                                                        _vm.$set(
                                                                                          _vm.part,
                                                                                          "carpentryMPP",
                                                                                          _vm._n(
                                                                                            $$v
                                                                                          )
                                                                                        )
                                                                                      },
                                                                                      expression:
                                                                                        "part.carpentryMPP"
                                                                                    }
                                                                                  }
                                                                                )
                                                                              ],
                                                                              1
                                                                            )
                                                                          ],
                                                                          1
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "v-col",
                                                                          {
                                                                            staticClass:
                                                                              "align-center justify-space-between",
                                                                            attrs: {
                                                                              cols:
                                                                                "3"
                                                                            }
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "v-row",
                                                                              {
                                                                                staticClass:
                                                                                  "mr-0 ml-0",
                                                                                attrs: {
                                                                                  align:
                                                                                    "center"
                                                                                }
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "v-text-field",
                                                                                  {
                                                                                    attrs: {
                                                                                      label: _vm.$t(
                                                                                        "parts.other"
                                                                                      ),
                                                                                      rules: [
                                                                                        _vm
                                                                                          .rules
                                                                                          .numbersonly
                                                                                      ],
                                                                                      disabled:
                                                                                        _vm.processing
                                                                                    },
                                                                                    model: {
                                                                                      value:
                                                                                        _vm
                                                                                          .part
                                                                                          .otherMPP,
                                                                                      callback: function(
                                                                                        $$v
                                                                                      ) {
                                                                                        _vm.$set(
                                                                                          _vm.part,
                                                                                          "otherMPP",
                                                                                          _vm._n(
                                                                                            $$v
                                                                                          )
                                                                                        )
                                                                                      },
                                                                                      expression:
                                                                                        "part.otherMPP"
                                                                                    }
                                                                                  }
                                                                                )
                                                                              ],
                                                                              1
                                                                            )
                                                                          ],
                                                                          1
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "v-col",
                                                                          {
                                                                            attrs: {
                                                                              cols:
                                                                                "12"
                                                                            }
                                                                          },
                                                                          [
                                                                            _vm
                                                                              .inlineMessage
                                                                              .message
                                                                              ? _c(
                                                                                  "v-alert",
                                                                                  {
                                                                                    attrs: {
                                                                                      type:
                                                                                        _vm
                                                                                          .inlineMessage
                                                                                          .type
                                                                                    }
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "\n                                                                                " +
                                                                                        _vm._s(
                                                                                          _vm
                                                                                            .inlineMessage
                                                                                            .message
                                                                                        ) +
                                                                                        "\n                                                                            "
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              : _vm._e()
                                                                          ],
                                                                          1
                                                                        )
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "v-row",
                                                                      {
                                                                        attrs: {
                                                                          justify:
                                                                            "end"
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "pr-3 pb-3"
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "v-btn",
                                                                              {
                                                                                attrs: {
                                                                                  text:
                                                                                    "",
                                                                                  link:
                                                                                    "",
                                                                                  loading:
                                                                                    _vm.processing,
                                                                                  disabled:
                                                                                    _vm.processing
                                                                                },
                                                                                on: {
                                                                                  click: function(
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.cancelItemAddIgnoreParts(
                                                                                      item
                                                                                    )
                                                                                  }
                                                                                }
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    _vm.$t(
                                                                                      "common.cancel"
                                                                                    )
                                                                                  )
                                                                                )
                                                                              ]
                                                                            ),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "v-btn",
                                                                              {
                                                                                attrs: {
                                                                                  color:
                                                                                    "primary",
                                                                                  loading:
                                                                                    _vm.processing,
                                                                                  disabled:
                                                                                    _vm.processing
                                                                                },
                                                                                on: {
                                                                                  click: function(
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.mockIgnorePart(
                                                                                      item
                                                                                    )
                                                                                  }
                                                                                }
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    _vm.$t(
                                                                                      "common.save"
                                                                                    )
                                                                                  )
                                                                                )
                                                                              ]
                                                                            )
                                                                          ],
                                                                          1
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ]
                                                        }
                                                      }
                                                    ])
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-expansion-panel",
                                    [
                                      _c(
                                        "v-expansion-panel-header",
                                        {
                                          attrs: {
                                            "disable-icon-rotate":
                                              _vm.simulatederror
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "actions",
                                              fn: function() {
                                                return [
                                                  _vm.simulatederror
                                                    ? _c(
                                                        "v-icon",
                                                        {
                                                          attrs: {
                                                            color: "error"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "mdi-alert-circle"
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e()
                                                ]
                                              },
                                              proxy: true
                                            }
                                          ])
                                        },
                                        [
                                          _vm._v(
                                            "\n                                        " +
                                              _vm._s(
                                                _vm.$t(
                                                  "estimates.add-modifiers"
                                                )
                                              ) +
                                              "\n                                        "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-expansion-panel-content",
                                        _vm._l(_vm.modifiers, function(
                                          modifier
                                        ) {
                                          return _c(
                                            "v-row",
                                            {
                                              key: modifier,
                                              staticClass: "pl-3 pr-3 pt-0"
                                            },
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "pt-0",
                                                  attrs: {
                                                    cols: "12",
                                                    sm: "12"
                                                  }
                                                },
                                                [
                                                  _c("v-select", {
                                                    attrs: {
                                                      "item-text": "name",
                                                      "item-value": "id",
                                                      items: _vm.modifiervalues,
                                                      label: modifier.name,
                                                      disabled: _vm.processing
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.estimate.clientID,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.estimate,
                                                          "clientID",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "estimate.clientID"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        }),
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-expansion-panel",
                                    [
                                      _c(
                                        "v-expansion-panel-header",
                                        {
                                          attrs: {
                                            "disable-icon-rotate":
                                              _vm.simulatederror
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "actions",
                                              fn: function() {
                                                return [
                                                  _vm.simulatederror
                                                    ? _c(
                                                        "v-icon",
                                                        {
                                                          attrs: {
                                                            color: "error"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "mdi-alert-circle"
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e()
                                                ]
                                              },
                                              proxy: true
                                            }
                                          ])
                                        },
                                        [
                                          _vm._v(
                                            "\n                                        " +
                                              _vm._s(
                                                _vm.$t(
                                                  "estimates.make-adjustments"
                                                )
                                              ) +
                                              "\n                                        "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-expansion-panel-content",
                                        [
                                          _c(
                                            "v-row",
                                            { staticClass: "pl-3 pr-3 pt-0" },
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "pt-0",
                                                  attrs: { cols: "12" }
                                                },
                                                [
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      label: _vm.$t(
                                                        "estimates.adjustments-risk-adder"
                                                      ),
                                                      disabled: _vm.processing
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.estimate.riskadder,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.estimate,
                                                          "riskadder",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "estimate.riskadder"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "pt-0",
                                                  attrs: { cols: "12" }
                                                },
                                                [
                                                  _c("v-switch", {
                                                    attrs: {
                                                      label: _vm.$t(
                                                        "estimates.adjustments-lashing"
                                                      ),
                                                      disabled: _vm.processing,
                                                      "persistent-hint": "",
                                                      hint: _vm.$t(
                                                        "estimates.adjustments-lashing-hint"
                                                      )
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.estimate.uselashing,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.estimate,
                                                          "uselashing",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "estimate.uselashing"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "12" } },
                                                [
                                                  _c("v-switch", {
                                                    attrs: {
                                                      label: _vm.$t(
                                                        "estimates.adjustments-cleating"
                                                      ),
                                                      disabled: _vm.processing,
                                                      "persistent-hint": "",
                                                      hint: _vm.$t(
                                                        "estimates.adjustments-cleating-hint"
                                                      )
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.estimate
                                                          .usecleating,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.estimate,
                                                          "usecleating",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "estimate.usecleating"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "12" } },
                                                [
                                                  _c("v-switch", {
                                                    attrs: {
                                                      label: _vm.$t(
                                                        "estimates.adjustments-carpentry"
                                                      ),
                                                      disabled: _vm.processing,
                                                      "persistent-hint": "",
                                                      hint: _vm.$t(
                                                        "estimates.adjustments-carpentry-hint"
                                                      )
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.estimate
                                                          .usecarpentry,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.estimate,
                                                          "usecarpentry",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "estimate.usecarpentry"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "12" } },
                                                [
                                                  _c("v-switch", {
                                                    attrs: {
                                                      label: _vm.$t(
                                                        "estimates.adjustments-other"
                                                      ),
                                                      disabled: _vm.processing,
                                                      "persistent-hint": "",
                                                      hint: _vm.$t(
                                                        "estimates.adjustments-other-hint"
                                                      )
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.estimate
                                                          .usecarpentry,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.estimate,
                                                          "usecarpentry",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "estimate.usecarpentry"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-expansion-panel",
                                    [
                                      _c(
                                        "v-expansion-panel-header",
                                        {
                                          attrs: {
                                            "disable-icon-rotate":
                                              _vm.simulatederror
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "actions",
                                              fn: function() {
                                                return [
                                                  _vm.simulatederror
                                                    ? _c(
                                                        "v-icon",
                                                        {
                                                          attrs: {
                                                            color: "error"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "mdi-alert-circle"
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e()
                                                ]
                                              },
                                              proxy: true
                                            }
                                          ])
                                        },
                                        [
                                          _vm._v(
                                            "\n                                        " +
                                              _vm._s(
                                                _vm.$t(
                                                  "estimates.add-attachments"
                                                )
                                              ) +
                                              "\n                                        "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("v-expansion-panel-content")
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { staticClass: "pb-0", attrs: { cols: "12" } },
                            [
                              _vm.inlineMessage.message
                                ? _c(
                                    "v-alert",
                                    {
                                      staticClass: "mr-0",
                                      attrs: { type: _vm.inlineMessage.type }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(_vm.inlineMessage.message) +
                                          "\n                            "
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "v-row",
                                { staticClass: "ml-0 mr-0 pb-0" },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        link: "",
                                        disabled: _vm.processing,
                                        color: "error",
                                        outlined: ""
                                      },
                                      on: { click: _vm.deleteItem }
                                    },
                                    [_vm._v(_vm._s(_vm.$t("common.delete")))]
                                  ),
                                  _vm._v(" "),
                                  _c("v-checkbox", {
                                    staticClass: "ml-4 mt-0",
                                    attrs: { label: "Simulate Error" },
                                    model: {
                                      value: _vm.simulatederror,
                                      callback: function($$v) {
                                        _vm.simulatederror = $$v
                                      },
                                      expression: "simulatederror"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("v-spacer"),
                                  _vm._v(" "),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        text: "",
                                        link: "",
                                        disabled: _vm.processing
                                      },
                                      on: { click: _vm.cancel }
                                    },
                                    [_vm._v(_vm._s(_vm.$t("common.cancel")))]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        color: "primary",
                                        loading: _vm.saving,
                                        disabled: _vm.processing
                                      },
                                      on: { click: _vm.save }
                                    },
                                    [_vm._v(_vm._s(_vm.$t("common.save")))]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }