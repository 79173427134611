import FDVue from "..";

export default FDVue.extend({
  props: {
    value: { type: [String, Object, Number], default: "" },
    valueStyle: { type: String, default: "" },
    valueClass: { type: [String, Array], default: () => [] },
    loading: { type: Boolean, default: false },
    loadingColor: { type: String, default: "#7a7a7a" },

    hideNoValuePlaceholder: { type: Boolean, default: false },
    noValueKey: { type: String, default: "common.not-available" },
    noValueText: {
      type: String,
      default: function() {
        return this.$t(this.noValueKey);
      }
    },
    noValueStyle: { type: String, default: "" },
    noValueClass: { type: [String, Array], default: () => [] }
  }
});

