import FDVue from "../";

export default FDVue.extend({
  name: "fd-code-entry",

  props: {
    disabled: Boolean,
    cy: { type: String, default: "fd-code-entry" }
  },

  data: function() {
    return {
      char1: "",
      char2: "",
      char3: "",
      char4: "",
      char5: "",
      char6: ""
    };
  },

  methods: {
    focus() {
      // console.log("CodeEntry.focus");
      this.$nextTick(() => {
        this.currentField.focus();
      });
    },
    clear() {
      this.char1 = "";
      this.char2 = "";
      this.char3 = "";
      this.char4 = "";
      this.char5 = "";
      this.char6 = "";
    },
    fieldFocused(e: FocusEvent) {
      // console.log(`fieldFocused - e: ${e}`);
      if (e.target != this.currentField.$refs.input) {
        e.preventDefault();
        this.currentField.focus();
      }
    },

    fieldKeyDown(e: KeyboardEvent) {
      // console.log(`fieldKeyDown - e.key: ${e.key}`);
      if (e.key == "Backspace") {
        if (e.target == (this.$refs.codeEntry6 as any).$refs.input) {
          if (this.char6.length) this.char6 = "";
          else this.char5 = "";
        } else if (e.target == (this.$refs.codeEntry5 as any).$refs.input) {
          if (this.char5.length) this.char5 = "";
          else this.char4 = "";
        } else if (e.target == (this.$refs.codeEntry4 as any).$refs.input) {
          if (this.char4.length) this.char4 = "";
          else this.char3 = "";
        } else if (e.target == (this.$refs.codeEntry3 as any).$refs.input) {
          if (this.char3.length) this.char3 = "";
          else this.char2 = "";
        } else if (e.target == (this.$refs.codeEntry2 as any).$refs.input) {
          if (this.char2.length) this.char2 = "";
          else this.char1 = "";
        } else if (e.target == (this.$refs.codeEntry1 as any).$refs.input) {
          this.char2 = "";
        }
        this.focus();
      }
      return true;
    },
    valuePasted(e: ClipboardEvent) {
      // console.log(`valuePasted - e: ${e}`);
      let pastedData = e.clipboardData?.getData("text") ?? "";
      pastedData = pastedData.replace("-", "");
      if (pastedData.length == 6 && !isNaN(+pastedData)) {
        this.char1 = pastedData[0];
        this.char2 = pastedData[1];
        this.char3 = pastedData[2];
        this.char4 = pastedData[3];
        this.char5 = pastedData[4];
        this.char6 = pastedData[5];
      } else {
        e.preventDefault();
      }
    },
    codeEntryComplete() {
      // console.log("codeEntryComplete");
      this.$emit("codeEntered", this.codeString);
    }
  },

  computed: {
    codeString(): string {
      return `${this.char1}${this.char2}${this.char3}-${this.char4}${this.char5}${this.char6}`;
    },
    currentField(): any {
      if (!this.char1.length) return this.$refs.codeEntry1;
      else if (!this.char2.length) return this.$refs.codeEntry2;
      else if (!this.char3.length) return this.$refs.codeEntry3;
      else if (!this.char4.length) return this.$refs.codeEntry4;
      else if (!this.char5.length) return this.$refs.codeEntry5;
      else if (!this.char6.length) return this.$refs.codeEntry6;
    },
    previousField(): any {
      if (!this.char1.length) null;
      else if (!this.char2.length) return this.$refs.codeEntry1;
      else if (!this.char3.length) return this.$refs.codeEntry2;
      else if (!this.char4.length) return this.$refs.codeEntry3;
      else if (!this.char5.length) return this.$refs.codeEntry4;
      else if (!this.char6.length) return this.$refs.codeEntry5;
    }
  },

  watch: {
    char1(val) {
      // console.log(`char1 Changed to ${val}`);
      if (val == "" || isNaN(val)) this.char1 = "";
      this.focus();
    },
    char2(val) {
      // console.log(`char2 Changed to ${val}`);
      if (val == "" || isNaN(val)) this.char2 = "";
      this.focus();
    },
    char3(val) {
      // console.log(`char3 Changed to ${val}`);
      if (val == "" || isNaN(val)) this.char3 = "";
      this.focus();
    },
    char4(val) {
      // console.log(`char4 Changed to ${val}`);
      if (val == "" || isNaN(val)) this.char4 = "";
      this.focus();
    },
    char5(val) {
      // console.log(`char5 Changed to ${val}`);
      if (val == "" || isNaN(val)) this.char5 = "";
      this.focus();
    },
    char6(val) {
      // console.log(`char6 Changed to ${val}`);
      // if (val == "" || isNaN(val)) this.char6 = "";

      if (val != "" && !isNaN(val)) {
        // console.log(`Valid Number`);
        this.codeEntryComplete();
        this.$nextTick(() => {
          (this.$refs.codeEntry6 as any).blur();
        });
      } else {
        // console.log(`NOT a Number`);
        this.$nextTick(() => {
          this.char6 = "";
          (this.$refs.codeEntry6 as any).focus();
        });
      }
    }
  }
});
