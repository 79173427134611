import FDVue from "@fd/lib/vue";
import { mapActions, mapMutations } from "vuex";
import serviceErrorHandling from "@fd/lib/vue/mixins/serviceErrorHandling";
import { FDColumnDirective, FDRowNavigateDirective } from "@fd/lib/vue/utility/dataTable";
import {
  scaffoldInspectionService,
  Tag,
  ProjectLocation,
  projectLocationService,
  ContractorWithTags,
  ScaffoldWithInspectionStatus,
  InspectionStatus,
  ScaffoldInspectionTimeRange,
  reportService,
  ScaffoldWithInspectionDetails
} from "../services";
import userAccess from "../dataMixins/userAccess";
import { filterByTags } from "../services/taggableItems";
import * as DateUtil from "@fd/lib/client-util/datetime";
import { valueInArray } from "@fd/lib/client-util/array";
import { createNewScaffoldInspection } from "./components/dialogs/SP.ScaffoldInspectionNewDialog.vue";
import { showScaffoldInspectionHistoryDialog } from "./components/dialogs/SP.ScaffoldInspectionHistoryDialog.vue";
import { GroupableSelectListOption } from "../../../lib/vue/utility/select";
import {
  GetSelectableTimeSegments,
  TimeSegment
} from "./components/SP.ScaffoldInspectionTimeSegmentSelect.vue";
import downloadBlob from "../../../lib/client-util/downloadBlob";
import printBlob from "../../../lib/client-util/printBlob";
import { TranslateResult } from "vue-i18n";

type ScaffoldWithExtraDetails = ScaffoldWithInspectionStatus & {
  tagNumber: string;
  daysStanding: number | undefined;
};

export default FDVue.extend({
  name: "fd-scaffoldInspections-inspections-list",

  mixins: [serviceErrorHandling, userAccess],

  directives: {
    fdColumn: FDColumnDirective,
    fdRowNavigate: FDRowNavigateDirective
  },

  components: {
    "sp-scaffold-inspection-time-segment-select": () =>
      import("./components/SP.ScaffoldInspectionTimeSegmentSelect.vue")
  },

  data: function() {
    return {
      // Used to track the the auto-reload for the table data
      reloadTimer: null as NodeJS.Timeout | null,
      dataReloadMinutes: 5,

      showPassed: true,
      showFailed: true,
      showPending: true,
      selectedTimeRange: {} as TimeSegment,

      // allContractors: [] as ContractorWithTags[],

      submitting: false,
      approving: false,
      declining: false,
      archivedLoading: false,

      allAreas: [] as ProjectLocation[],
      allSubAreas: [] as ProjectLocation[],

      allScaffoldsWithInspections: [] as ScaffoldWithExtraDetails[],
      selectableTimeSegments: [] as GroupableSelectListOption<TimeSegment>[],

      // Table Footer page size options
      itemsPerPage: 25,
      itemsPerPageOptions: [5, 10, 15, 25, 50, -1]
    };
  },

  computed: {
    selectedSegmentIsCurrent(): boolean {
      let now = new Date().getTime();
      return (
        now >= this.selectedTimeRange.start.getTime() && now < this.selectedTimeRange.end.getTime()
      );
    },
    canAddHistoricalInspections(): boolean {
      return this.$store.state.curEnvironment.scaffoldInspectionCanBeCreatedHistorically!;
    },
    // selectableTimeSegments(): GroupableSelectListOption<TimeSegment>[] {
    //   return GetSelectableTimeSegments(this.timeRanges);
    // },
    unwatchedMethodNames(): string[] {
      return [
        "customSort",
        "formatDate",
        "openNewDialog",
        "inspectionColorForScaffold",
        "latestInspectionCreatedAtDifferentTime"
      ];
    },
    expanderColSpan(): number {
      if (this.$vuetify.breakpoint.xs) {
        return 4;
      } else if (this.$vuetify.breakpoint.sm) {
        return 10;
      } else if (this.$vuetify.breakpoint.md) {
        return 13;
      }

      return 25;
    },

    canViewContractorFilter(): boolean {
      let allSelectableContractorIDs = this.allScaffoldsWithInspections.map(x => x.contractorID);
      let distinctSelectableContractorID = [...new Set(allSelectableContractorIDs)];
      return distinctSelectableContractorID.length > 0;
    },

    tablesearch: {
      get() {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.searchStringForFiltering;
      },
      set(val) {
        this.$store.commit("SET_SEARCH_STRING_FOR_FILTERING", val);
      }
    },

    selectableAreas(): any[] {
      let selectableAreaIDs = this.allScaffoldsWithInspections
        .filter(x => !!x.areaID)
        .map(x => x.areaID!);
      selectableAreaIDs = [...new Set(selectableAreaIDs)];
      return this.allAreas.filter(x => selectableAreaIDs.includes(x.id!));
    },

    selectedAreas: {
      get() {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.areasForFiltering;
      },
      set(val) {
        this.$store.commit("SET_AREAS_FOR_FILTERING", val);
      }
    },

    selectableSubAreas(): any[] {
      let selectableSubAreaIDs = this.allScaffoldsWithInspections
        .filter(x => !!x.subAreaID)
        .map(x => x.subAreaID!);
      selectableSubAreaIDs = [...new Set(selectableSubAreaIDs)];

      let selectedAreaIDs = this.selectedAreas;
      return this.allSubAreas.filter(
        x =>
          selectableSubAreaIDs.includes(x.id!) &&
          !!selectedAreaIDs.length &&
          selectedAreaIDs.includes(x.parentLocationID)
      );
    },

    selectedSubAreas: {
      get() {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.subAreasForFiltering;
      },
      set(val) {
        this.$store.commit("SET_SUB_AREAS_FOR_FILTERING", val);
      }
    },

    selectedContractorIDs: {
      get(): string[] {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.contractorsForFiltering;
      },
      set(val: string[]) {
        this.$store.commit("SET_CONTRACTORS_FOR_FILTERING", val);
      }
    },

    selectableContractors(): ContractorWithTags[] {
      var contractors = this.$store.state.contractors.fullList as ContractorWithTags[];
      var usedContractorID = this.allScaffoldsWithInspections.map(x => x.contractorID);
      contractors = contractors.filter(x => usedContractorID.includes(x.id!));
      return contractors;
    },

    tagsInUse(): Tag[] {
      return this.$store.getters.getSortedInUseTags(this.allScaffoldsWithInspections);
    },

    tagsSelectedForFiltering: {
      get() {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.tagsForFiltering;
      },
      set(val) {
        this.$store.commit("SET_TAGS_FOR_FILTERING", val);
      }
    },
    scaffoldsWithInspections(): ScaffoldWithExtraDetails[] {
      var selectedScaffoldInspections = this.allScaffoldsWithInspections;

      if (this.selectedContractorIDs.length) {
        selectedScaffoldInspections = selectedScaffoldInspections.filter(x =>
          valueInArray(x.contractorID, this.selectedContractorIDs)
        );
      }
      if (this.selectedAreas.length) {
        selectedScaffoldInspections = selectedScaffoldInspections.filter(x =>
          valueInArray(x.areaID, this.selectedAreas)
        );
      }
      if (this.selectedSubAreas.length) {
        selectedScaffoldInspections = selectedScaffoldInspections.filter(x =>
          valueInArray(x.subAreaID, this.selectedSubAreas)
        );
      }

      if (!this.showPassed) {
        selectedScaffoldInspections = selectedScaffoldInspections.filter(
          x => x.inspectionStatus != InspectionStatus.Passed
        );
      }
      if (!this.showFailed) {
        selectedScaffoldInspections = selectedScaffoldInspections.filter(
          x => x.inspectionStatus != InspectionStatus.Failed
        );
      }
      if (!this.showPending) {
        selectedScaffoldInspections = selectedScaffoldInspections.filter(
          x => x.inspectionStatus != InspectionStatus.Pending
        );
      }

      selectedScaffoldInspections = filterByTags<ScaffoldWithExtraDetails>(
        this.tagsSelectedForFiltering,
        selectedScaffoldInspections
      );

      return selectedScaffoldInspections;
    },
    canSelectNextSegment(): boolean {
      // let index = this.selectableTimeSegments.indexOf(this.selectedTimeRange);
      let index = this.selectableTimeSegments.findIndex(
        x =>
          !!(x as TimeSegment)?.start &&
          this.selectedTimeRange.start.getTime() == (x as TimeSegment).start.getTime()
      );
      index -= 1;
      if (index < 0) return false;

      var nextSegment = this.selectableTimeSegments[index];
      if (!!nextSegment.header) {
        index -= 1;
        if (index < 0) return false;
      }

      return true;
    }
  },

  watch: {
    selectedTimeRange(newValue) {
      console.log(`selectedTimeRange changed`);
      this.reloadTableData();
    }
  },

  methods: {
    async downloadAndPrintReport(reportType: string) {
      this.inlineMessage.message = "";
      this.processing = true;
      try {
        var pass = this.$t("inspections.inspection-result-pass-label");
        var fail = this.$t("inspections.inspection-result-fail-label");
        var pending = this.$t("inspections.inspection-result-pending-label");

        var inspectionStatusName = function(status: InspectionStatus) {
          if (status == InspectionStatus.Passed) return pass;
          if (status == InspectionStatus.Failed) return fail;
          else return pending;
        };
        let inspections = this.allScaffoldsWithInspections.map(x => {
          return {
            id: x.id,
            daysStanding: x.daysStanding,
            areaID: x.areaID,
            subAreaID: x.subAreaID,
            contractorID: x.contractorID,
            specificWorkLocation: x.specificWorkLocation,
            tagNumber: x.tagNumber,
            lastInspectionDateTime: x.lastInspectionDateTime,
            lastInspectionResult: inspectionStatusName(x.inspectionStatus)
          } as ScaffoldWithInspectionDetails;
        });
        if (inspections.length == 0) {
          this.$store.dispatch("SHOW_SNACKBAR", {
            text: this.$t("inspections.printing.no-data-message"),
            type: "info"
          });
          return;
        }
        let timeSegment = this.selectedTimeRange;
        let day = timeSegment.start.toDateString();
        let blob = await reportService.getStandingScaffoldInspectionStatusPrintoutWithData(
          inspections,
          reportType,
          DateUtil.localizedDateTimeString(new Date()),
          day,
          timeSegment.text
        );
        if (reportType == "xls") {
          downloadBlob(blob, "standing-scaffold-inspection-status.xlsx");
        } else {
          printBlob(blob, "standing-scaffold-inspection-status.pdf", "application/pdf");
        }
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    chipClicked(item: ScaffoldWithExtraDetails) {
      if (
        item.inspectionStatus == InspectionStatus.Passed ||
        item.inspectionStatus == InspectionStatus.Failed
      ) {
        this.$router.push(`/inspections/${item.currentSegmentInspection.id}`);
      } else {
        this.openNewInspectionForScaffoldDialog(item);
      }
    },
    formatDate(item: Date | string | undefined | null): string {
      return DateUtil.stripTimeFromLocalizedDateTime(item);
    },
    canAddNewInspection(item: ScaffoldWithExtraDetails): boolean {
      return item.inspectionStatus == InspectionStatus.Pending;
    },
    canReinspect(item: ScaffoldWithExtraDetails): boolean {
      return item.inspectionStatus == InspectionStatus.Failed;
    },
    latestInspectionCreatedAtDifferentTime(item: ScaffoldWithExtraDetails): boolean {
      if (!item.lastInspectionDateTime || !item.lastInspectionCreated) return false;

      return (
        DateUtil.localizedDateTimeString(item.lastInspectionDateTime) !=
        DateUtil.localizedDateTimeString(item.lastInspectionCreated)
      );
    },
    async selectPreviousSegment(e: Event) {
      e.stopPropagation();
      e.preventDefault();

      // let index = this.selectableTimeSegments.indexOf(this.selectedTimeRange);
      let index = this.selectableTimeSegments.findIndex(
        x =>
          !!(x as TimeSegment)?.start &&
          this.selectedTimeRange.start.getTime() == (x as TimeSegment).start.getTime()
      );

      index += 1;
      var nextSegment = this.selectableTimeSegments[index];
      if (!!nextSegment.header) {
        index += 1;
        nextSegment = this.selectableTimeSegments[index];
      }
      this.selectedTimeRange = nextSegment as TimeSegment;

      return false;
    },
    async selectNextSegment(e: Event) {
      e.stopPropagation();
      e.preventDefault();

      // let index = this.selectableTimeSegments.indexOf(this.selectedTimeRange);
      let index = this.selectableTimeSegments.findIndex(
        x =>
          !!(x as TimeSegment)?.start &&
          this.selectedTimeRange.start.getTime() == (x as TimeSegment).start.getTime()
      );

      index -= 1;
      var nextSegment = this.selectableTimeSegments[index];
      if (!!nextSegment.header) {
        index -= 1;
        nextSegment = this.selectableTimeSegments[index];
      }
      this.selectedTimeRange = nextSegment as TimeSegment;

      return false;
    },

    async openNewDialog() {
      var inspectedScaffoldID = await createNewScaffoldInspection(
        undefined,
        this.selectedSegmentIsCurrent
          ? undefined
          : new Date(this.selectedTimeRange.end.getTime() - 1)
      );

      if (!!inspectedScaffoldID?.length) {
        this.updateScaffoldInspectionInfo(inspectedScaffoldID);
      }
    },
    async openNewInspectionForScaffoldDialog(item: ScaffoldWithExtraDetails) {
      var inspectedScaffoldID = await createNewScaffoldInspection(
        item.id,
        this.selectedSegmentIsCurrent
          ? undefined
          : new Date(this.selectedTimeRange.end.getTime() - 1)
      );

      if (!!inspectedScaffoldID?.length) {
        this.updateScaffoldInspectionInfo(inspectedScaffoldID);
      }
    },
    async openHistoryDialog(item: ScaffoldWithExtraDetails) {
      await showScaffoldInspectionHistoryDialog(item.id!, item.tagNumber ?? item.legacyID);
    },

    inspectionColorForScaffold(item: ScaffoldWithInspectionStatus): string {
      if (item.inspectionStatus == InspectionStatus.Passed) return "fd-inspection-pass";
      else if (item.inspectionStatus == InspectionStatus.Failed) return "fd-inspection-fail";
      else return "fd-inspection-pending";
    },

    /*** GLOBAL ***/
    ...mapMutations({
      notifyNewBreadcrumb: "NOTIFY_NEW_BREADCRUMB",
      setFilteringContext: "SET_FILTERING_CONTEXT"
    }),
    ...mapActions({
      loadContractors: "LOAD_CONTRACTORS"
    }),

    customSort(
      items: ScaffoldWithExtraDetails[],
      sortBy: string[],
      sortDesc: boolean[],
      locale: string
    ) {
      let desc = sortDesc[0] == true;
      let temp = {} as ScaffoldWithExtraDetails;
      let propName = sortBy[0];
      // Examine the property trying to be sorted, and switch it to the ACTUAL property name of the object
      // This prop name is the name of the item in the table, and therefore sometimes won't match the object property.

      items.sort((a: ScaffoldWithExtraDetails, b: ScaffoldWithExtraDetails) => {
        let val1 = (a as any)[propName];
        let val2 = (b as any)[propName];
        if (val1 < val2) {
          return desc ? 1 : -1;
        } else if (val1 > val2) {
          return desc == true ? -1 : 1;
        } else {
          return 0;
        }
      });
      return items;
    },
    async updateScaffoldInspectionInfo(scaffoldID: string) {
      this.processing = true;
      try {
        let newInspectionInfo = await scaffoldInspectionService.getScaffoldInspectionStatusesForScaffoldForTimeRange(
          scaffoldID,
          this.selectedTimeRange.start,
          this.selectedTimeRange.end
        );
        let scaffold = this.scaffoldsWithInspections.find(x => x.id == scaffoldID);
        if (!!scaffold) {
          scaffold.lastInspectedBy = newInspectionInfo.lastInspectedBy;
          scaffold.lastInspectionCreated = newInspectionInfo.lastInspectionCreated;
          scaffold.lastInspectionDateTime = newInspectionInfo.lastInspectionDateTime;
          scaffold.inspectionStatus = newInspectionInfo.inspectionStatus;
          scaffold.inspections = newInspectionInfo.inspections;
          scaffold.currentSegmentInspection = newInspectionInfo.currentSegmentInspection;
        } else {
          this.scaffoldsWithInspections.push({
            ...newInspectionInfo,
            tagNumber: `${newInspectionInfo.legacyID}`,
            daysStanding: !!newInspectionInfo.actualErectDate
              ? Math.round(
                  ((!!newInspectionInfo.actualDismantleDate
                    ? newInspectionInfo.actualDismantleDate
                    : new Date()
                  ).getTime() -
                    newInspectionInfo.actualErectDate.getTime()) /
                    86400000
                )
              : undefined
          } as ScaffoldWithExtraDetails);
        }
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },

    async reloadTableData() {
      this.processing = true;
      try {
        await this.loadScaffoldInspections();
        this.inlineMessage.message = "";
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },

    async loadScaffoldInspections() {
      if (this.reloadTimer) {
        clearTimeout(this.reloadTimer);
      }

      // The goal here is to send midnight (morning) of the selected day, in the current timezone
      // let scaffoldsWithInspections = await scaffoldInspectionService.getStandingScaffoldInspectionStatusesForDay(
      //   this.startOfDay
      // );
      let scaffoldsWithInspections = await scaffoldInspectionService.getStandingScaffoldInspectionStatusesForTimeRange(
        this.selectedTimeRange.start,
        this.selectedTimeRange.end
      );
      this.allScaffoldsWithInspections = scaffoldsWithInspections.map(x => {
        return {
          ...x,
          tagNumber: `${x.legacyID}`,
          daysStanding: !!x.actualErectDate
            ? Math.round(
                ((!!x.actualDismantleDate ? x.actualDismantleDate : new Date()).getTime() -
                  x.actualErectDate.getTime()) /
                  86400000
              )
            : undefined
        } as ScaffoldWithExtraDetails;
      });
      let _this = this;
      this.reloadTimer = setTimeout(async function() {
        _this.reloadTableData();
      }, _this.dataReloadMinutes * 60 * 1000);
    },

    // DOES NOT manage processing or error message logic
    async loadAreas(): Promise<void> {
      let areas = await projectLocationService.getVisibleAreas();
      this.allAreas = areas;
    },

    // DOES NOT manage processing or error message logic
    async loadSubAreas(): Promise<void> {
      let subAreas = await projectLocationService.getVisibleSubAreas();
      this.allSubAreas = subAreas;
    },

    async loadTimeRanges(): Promise<void> {
      const timeRanges = await scaffoldInspectionService.getScaffoldInspectionTimeRanges();
      this.selectableTimeSegments = GetSelectableTimeSegments(timeRanges);
      this.selectedTimeRange = this.selectableTimeSegments[1] as TimeSegment;
    }
  },

  beforeDestroy() {
    if (this.reloadTimer) {
      clearTimeout(this.reloadTimer);
    }
  },

  beforeCreate: async function() {},

  created: async function() {
    // Set the context for the User Filtering in the store so that if the user navigates to a screen that is
    // a sub screen of something that is currently filtered by their choices that those choices will be
    // preserved as they move between the two screens.

    // Archived date range is being set in the context here for the sake of the history dialog, this way the history dialog doesn't need to override the current context when it appears.
    var toDate = DateUtil.addDaysToDate(null, 0);
    this.setFilteringContext({
      context: "scaffoldInspections",
      parentalContext: null,
      searchStringForFiltering: "",
      tagsForFiltering: [],
      statusesForFiltering: [],
      contractorsForFiltering: [],
      areasForFiltering: [],
      subAreasForFiltering: [],
      showArchivedForFilteringFromDate: DateUtil.addMonthsToDate(toDate, -2),
      showArchivedForFilteringToDate: toDate
    });

    // Set the bottom navigation bar as appropriate.
    this.$store.commit("SET_CUR_BOTTOMBARVALUE", "inspections");

    this.notifyNewBreadcrumb({
      text: this.$t("inspections.list.title"),
      to: "/inspections",
      resetHistory: true
    });
    this.processing = true;
    try {
      await Promise.all([
        this.loadTimeRanges(),
        this.loadContractors(),
        this.loadAreas(),
        this.loadSubAreas()
      ]);
      this.processing = true;
      await this.loadScaffoldInspections();
      // debugger;
    } catch (error) {
      if ((error as any).statusCode == 403) {
        this.inlineMessage.message = "";
      } else {
        this.handleError(error as Error);
      }
    } finally {
      this.processing = false;
    }
  }
});

