var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      attrs: { width: "1000px", persistent: "" },
      model: {
        value: _vm.twoWayProps.show,
        callback: function($$v) {
          _vm.$set(_vm.twoWayProps, "show", $$v)
        },
        expression: "twoWayProps.show"
      }
    },
    [
      _c(
        "v-form",
        { ref: "form" },
        [
          _c(
            "v-stepper",
            {
              attrs: { "non-linear": "" },
              model: {
                value: _vm.step,
                callback: function($$v) {
                  _vm.step = $$v
                },
                expression: "step"
              }
            },
            [
              _c(
                "v-stepper-header",
                [
                  _c(
                    "v-stepper-step",
                    {
                      attrs: {
                        rules: [() => !_vm.detailserror],
                        complete: _vm.step > 1,
                        step: "1",
                        editable: ""
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("estimates.enter-details")) +
                          "\n          "
                      ),
                      _vm.detailserror
                        ? _c("small", { staticClass: "mt-1" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "estimates.parts-selection-stepper-error-message"
                                )
                              )
                            )
                          ])
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  _c("v-divider"),
                  _vm._v(" "),
                  _c(
                    "v-stepper-step",
                    {
                      attrs: {
                        complete: _vm.step > 2,
                        rules: [() => !_vm.partserror],
                        step: "2",
                        editable: ""
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("estimates.parts-selection")) +
                          "\n          "
                      ),
                      _vm.partserror
                        ? _c("small", { staticClass: "mt-1" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "estimates.parts-selection-stepper-error-message"
                                )
                              )
                            )
                          ])
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  _c("v-divider"),
                  _vm._v(" "),
                  _c(
                    "v-stepper-step",
                    {
                      attrs: { complete: _vm.step > 3, step: "3", editable: "" }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("estimates.add-modifiers")) +
                          "\n        "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("v-divider"),
                  _vm._v(" "),
                  _c(
                    "v-stepper-step",
                    {
                      attrs: { complete: _vm.step > 4, step: "4", editable: "" }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("estimates.make-adjustments")) +
                          "\n        "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("v-divider"),
                  _vm._v(" "),
                  _c("v-stepper-step", { attrs: { step: "5", editable: "" } }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("estimates.add-attachments")) +
                        "\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c("v-progress-linear", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.processing || _vm.saving,
                        expression: "processing || saving"
                      }
                    ],
                    class: {
                      "stepper-progress-indicator-compensation-when-additional-step-details":
                        _vm.simulatederror
                    },
                    attrs: { indeterminate: _vm.processing || _vm.saving }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-stepper-items",
                [
                  _c(
                    "v-stepper-content",
                    { attrs: { step: "1" } },
                    [
                      _c(
                        "v-card",
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { staticClass: "pt-0", attrs: { cols: "12" } },
                                [
                                  _c(
                                    "v-row",
                                    { staticClass: "pt-1 pb-0 pl-3 pr-3" },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("fd-chip-selector", {
                                            attrs: {
                                              "available-items":
                                                _vm.availableTags,
                                              "selected-items":
                                                _vm.selectedTags,
                                              "item-label": "name",
                                              disabled: _vm.processing,
                                              "control-label": _vm.$t(
                                                "common.add-tags"
                                              ),
                                              "chip-class":
                                                "keywords-selection-chip-colors"
                                            },
                                            on: {
                                              "update:selectedItems": function(
                                                $event
                                              ) {
                                                _vm.selectedTags = $event
                                              },
                                              "update:selected-items": function(
                                                $event
                                              ) {
                                                _vm.selectedTags = $event
                                              }
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-row",
                                    { staticClass: "pt-0 pb-0 pl-3 pr-3" },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          attrs: {
                                            cols: "12",
                                            sm: "12",
                                            md: "6"
                                          }
                                        },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: _vm.$t(
                                                "estimates.tagnumberlong"
                                              ),
                                              disabled: _vm.processing,
                                              rules: [_vm.rules.required]
                                            },
                                            model: {
                                              value:
                                                _vm.estimate.scaffoldNumber,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.estimate,
                                                  "scaffoldNumber",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "estimate.scaffoldNumber"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        {
                                          attrs: {
                                            cols: "12",
                                            sm: "12",
                                            md: "6"
                                          }
                                        },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: _vm.$t(
                                                "estimates.estimated-by"
                                              ),
                                              disabled: _vm.processing
                                            },
                                            model: {
                                              value: _vm.estimate.estimatedBy,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.estimate,
                                                  "estimatedBy",
                                                  $$v
                                                )
                                              },
                                              expression: "estimate.estimatedBy"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-row",
                                    { staticClass: "pl-3 pr-3 pt-0" },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "pt-0",
                                          attrs: {
                                            cols: "12",
                                            sm: "12",
                                            md: "6"
                                          }
                                        },
                                        [
                                          _c("v-select", {
                                            attrs: {
                                              "item-text": "name",
                                              "item-value": "id",
                                              items: _vm.clients,
                                              label: _vm.$t("estimates.client"),
                                              disabled: _vm.processing,
                                              rules: [_vm.rules.required]
                                            },
                                            model: {
                                              value: _vm.estimate.clientID,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.estimate,
                                                  "clientID",
                                                  $$v
                                                )
                                              },
                                              expression: "estimate.clientID"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "pt-0",
                                          attrs: {
                                            cols: "12",
                                            sm: "12",
                                            md: "6"
                                          }
                                        },
                                        [
                                          _c("v-select", {
                                            attrs: {
                                              "item-text": "name",
                                              "item-value": "id",
                                              items: _vm.projects,
                                              label: _vm.$t(
                                                "estimates.project"
                                              ),
                                              disabled: _vm.processing,
                                              rules: [_vm.rules.required]
                                            },
                                            model: {
                                              value: _vm.estimate.projectID,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.estimate,
                                                  "projectID",
                                                  $$v
                                                )
                                              },
                                              expression: "estimate.projectID"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-row",
                                    { staticClass: "pl-3 pr-3 pt-0" },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "pt-0",
                                          attrs: {
                                            cols: "12",
                                            sm: "12",
                                            md: "6"
                                          }
                                        },
                                        [
                                          _c("v-select", {
                                            attrs: {
                                              "item-text": "name",
                                              "item-value": "id",
                                              items: _vm.areas,
                                              label: _vm.$t("estimates.area"),
                                              disabled: _vm.processing
                                            },
                                            model: {
                                              value: _vm.estimate.areaID,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.estimate,
                                                  "areaID",
                                                  $$v
                                                )
                                              },
                                              expression: "estimate.areaID"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "pt-0",
                                          attrs: {
                                            cols: "12",
                                            sm: "12",
                                            md: "6"
                                          }
                                        },
                                        [
                                          _c("v-select", {
                                            attrs: {
                                              "item-text": "name",
                                              "item-value": "id",
                                              items: _vm.subareas,
                                              label: _vm.$t(
                                                "estimates.subarea"
                                              ),
                                              disabled: _vm.processing
                                            },
                                            model: {
                                              value: _vm.estimate.subareaID,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.estimate,
                                                  "subareaID",
                                                  $$v
                                                )
                                              },
                                              expression: "estimate.subareaID"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-row",
                                    { staticClass: "pl-3 pr-3 pt-0" },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "pt-0",
                                          attrs: {
                                            cols: "12",
                                            sm: "12",
                                            md: "6"
                                          }
                                        },
                                        [
                                          _c("fd-date-picker", {
                                            attrs: {
                                              label: _vm.$t(
                                                "estimates.validfrom"
                                              )
                                            },
                                            model: {
                                              value: _vm.estimate.validFrom,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.estimate,
                                                  "validFrom",
                                                  $$v
                                                )
                                              },
                                              expression: "estimate.validFrom"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "pt-0",
                                          attrs: {
                                            cols: "12",
                                            sm: "12",
                                            md: "6"
                                          }
                                        },
                                        [
                                          _c("fd-date-picker", {
                                            attrs: {
                                              label: _vm.$t(
                                                "estimates.validuntil"
                                              )
                                            },
                                            model: {
                                              value: _vm.estimate.validTo,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.estimate,
                                                  "validTo",
                                                  $$v
                                                )
                                              },
                                              expression: "estimate.validTo"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-row",
                                    { staticClass: "pl-3 pr-3 pt-0" },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "pt-0",
                                          attrs: {
                                            cols: "12",
                                            sm: "12",
                                            md: "6"
                                          }
                                        },
                                        [
                                          _c("v-select", {
                                            attrs: {
                                              "item-text": "name",
                                              "item-value": "id",
                                              items: _vm.regions,
                                              label: _vm.$t(
                                                "clients.projects.region"
                                              ),
                                              disabled: _vm.processing,
                                              clearable: ""
                                            },
                                            model: {
                                              value: _vm.estimate.regionID,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.estimate,
                                                  "regionID",
                                                  $$v
                                                )
                                              },
                                              expression: "estimate.regionID"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-row",
                                    { staticClass: "pl-3 pr-3 pt-0" },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "pt-0",
                                          attrs: {
                                            cols: "12",
                                            sm: "12",
                                            md: "6"
                                          }
                                        },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: _vm.$t(
                                                "clients.projects.productivity"
                                              ),
                                              disabled: _vm.processing,
                                              rules: [_vm.rules.numbersonly]
                                            },
                                            model: {
                                              value: _vm.estimate.productivity,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.estimate,
                                                  "productivity",
                                                  _vm._n($$v)
                                                )
                                              },
                                              expression:
                                                "estimate.productivity"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "pt-0",
                                          attrs: {
                                            cols: "12",
                                            sm: "12",
                                            md: "6"
                                          }
                                        },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: _vm.$t(
                                                "clients.projects.labourrate"
                                              ),
                                              disabled: _vm.processing,
                                              rules: [_vm.rules.numbersonly]
                                            },
                                            model: {
                                              value: _vm.estimate.labourRate,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.estimate,
                                                  "labourRate",
                                                  _vm._n($$v)
                                                )
                                              },
                                              expression: "estimate.labourRate"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-card-actions",
                            [
                              _c("v-spacer"),
                              _vm._v(" "),
                              _c(
                                "v-btn",
                                {
                                  attrs: { text: "" },
                                  on: { click: _vm.cancelDialog }
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.$t("common.cancel")) +
                                      "\n              "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-btn",
                                {
                                  attrs: { color: "primary" },
                                  on: {
                                    click: function($event) {
                                      _vm.step = 2
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.$t("common.continue")) +
                                      "\n              "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-stepper-content",
                    {
                      staticClass: "pt-1 pl-0 pr-0 pb-0",
                      attrs: { step: "2" }
                    },
                    [
                      _c(
                        "v-card",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "secondary-stepper-section pt-4",
                              attrs: { cols: "12" }
                            },
                            [
                              _c("v-textarea", {
                                attrs: {
                                  label: _vm.$t(
                                    "estimates.parts-selection-bcounts-paste-placeholder"
                                  ),
                                  outlined: "",
                                  rows: "1",
                                  "persistent-hint": "",
                                  hint: _vm.$t(
                                    "estimates.parts-selection-bcounts-paste-hint"
                                  ),
                                  disabled: _vm.processing
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.unaddressedPartErrorsExist
                            ? _c(
                                "v-col",
                                {
                                  staticClass: "secondary-stepper-section pb-1",
                                  attrs: { cols: "12" }
                                },
                                [
                                  _c("v-alert", { attrs: { type: "error" } }, [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          _vm.$t(
                                            "estimates.parts-selection-inline-error-message"
                                          )
                                        ) +
                                        "\n              "
                                    )
                                  ])
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.allPartErrorsIgnored
                            ? _c(
                                "v-col",
                                {
                                  staticClass: "secondary-stepper-section pb-1",
                                  attrs: { cols: "12" }
                                },
                                [
                                  _c("v-alert", { attrs: { type: "info" } }, [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          _vm.$t(
                                            "estimates.parts-selection-inline-all-remaining-parts-ignored-message"
                                          )
                                        ) +
                                        "\n              "
                                    )
                                  ])
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.allPartErrorsResolved
                            ? _c(
                                "v-col",
                                {
                                  staticClass: "secondary-stepper-section pb-1",
                                  attrs: { cols: "12" }
                                },
                                [
                                  _c(
                                    "v-alert",
                                    { attrs: { type: "success" } },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.$t(
                                              "estimates.parts-selection-inline-all-errored-parts-added-to-catalog"
                                            )
                                          ) +
                                          "\n              "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.erroredParts.length
                            ? _c(
                                "v-col",
                                {
                                  staticClass:
                                    "secondary-stepper-section pb-7 pt-0",
                                  attrs: { cols: "12" }
                                },
                                [
                                  _c("v-data-table", {
                                    directives: [
                                      {
                                        name: "fd-column",
                                        rawName: "v-fd-column:ignored",
                                        value: "",
                                        expression: "''",
                                        arg: "ignored"
                                      },
                                      {
                                        name: "fd-column",
                                        rawName: "v-fd-column:name",
                                        arg: "name"
                                      },
                                      {
                                        name: "fd-column",
                                        rawName:
                                          "v-fd-column:quantity.hide-when-small",
                                        arg: "quantity",
                                        modifiers: { "hide-when-small": true }
                                      },
                                      {
                                        name: "fd-column",
                                        rawName:
                                          "v-fd-column:data-table-expand.no-sort",
                                        value: _vm.$t("common.actions"),
                                        expression: "$t('common.actions')",
                                        arg: "data-table-expand",
                                        modifiers: { "no-sort": true }
                                      }
                                    ],
                                    staticClass:
                                      "fd-actions-table fd-warnings-table",
                                    attrs: {
                                      items: _vm.erroredParts,
                                      "single-expand": "",
                                      expanded: _vm.expanded,
                                      "item-key": "name",
                                      "show-expand": "",
                                      "footer-props": {
                                        "items-per-page-options": [1, 5]
                                      },
                                      "items-per-page": 5,
                                      "mobile-breakpoint": "0"
                                    },
                                    on: {
                                      "update:expanded": function($event) {
                                        _vm.expanded = $event
                                      }
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "item.data-table-expand",
                                          fn: function({
                                            item,
                                            isExpanded,
                                            expand
                                          }) {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "table-actions-column-min-width"
                                                },
                                                [
                                                  _c(
                                                    "v-tooltip",
                                                    {
                                                      attrs: { top: "" },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function({
                                                              on
                                                            }) {
                                                              return [
                                                                _c(
                                                                  "v-btn",
                                                                  _vm._g(
                                                                    {
                                                                      attrs: {
                                                                        icon:
                                                                          "",
                                                                        link:
                                                                          "",
                                                                        loading:
                                                                          _vm.saving,
                                                                        disabled:
                                                                          _vm.processing
                                                                      },
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          return expand(
                                                                            !isExpanded
                                                                          )
                                                                        }
                                                                      }
                                                                    },
                                                                    on
                                                                  ),
                                                                  [
                                                                    !isExpanded
                                                                      ? _c(
                                                                          "v-icon",
                                                                          {
                                                                            attrs: {
                                                                              small:
                                                                                ""
                                                                            }
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                            fa-plus-circle\n                          "
                                                                            )
                                                                          ]
                                                                        )
                                                                      : _c(
                                                                          "v-icon",
                                                                          {
                                                                            attrs: {
                                                                              small:
                                                                                ""
                                                                            }
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                            fa-times-circle\n                          "
                                                                            )
                                                                          ]
                                                                        )
                                                                  ],
                                                                  1
                                                                )
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        true
                                                      )
                                                    },
                                                    [
                                                      _vm._v(" "),
                                                      !isExpanded
                                                        ? _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "estimates.parts-selection-individual-part-actions-add-part-to-catalog"
                                                                )
                                                              )
                                                            )
                                                          ])
                                                        : _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "estimates.parts-selection-individual-part-actions-cancel-adding-part-to-catalog"
                                                                )
                                                              )
                                                            )
                                                          ])
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-tooltip",
                                                    {
                                                      attrs: { top: "" },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function({
                                                              on
                                                            }) {
                                                              return [
                                                                _c(
                                                                  "v-btn",
                                                                  _vm._g(
                                                                    {
                                                                      attrs: {
                                                                        icon:
                                                                          "",
                                                                        disabled:
                                                                          _vm.processing
                                                                      }
                                                                    },
                                                                    on
                                                                  ),
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      {
                                                                        attrs: {
                                                                          small:
                                                                            ""
                                                                        },
                                                                        on: {
                                                                          click: function(
                                                                            $event
                                                                          ) {
                                                                            return _vm.ignorePart(
                                                                              item
                                                                            )
                                                                          }
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                            fa-ban\n                          "
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        true
                                                      )
                                                    },
                                                    [
                                                      _vm._v(" "),
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "estimates.parts-selection-individual-part-actions-ignore-part"
                                                            )
                                                          )
                                                        )
                                                      ])
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          }
                                        },
                                        {
                                          key: "expanded-item",
                                          fn: function({ headers, item }) {
                                            return [
                                              _c(
                                                "td",
                                                {
                                                  staticClass:
                                                    "table-expanded-row-background-color",
                                                  attrs: {
                                                    colspan: headers.length
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-container",
                                                    {
                                                      staticStyle: {
                                                        padding: "0px"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-row",
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              staticClass:
                                                                "align-center justify-space-between",
                                                              attrs: {
                                                                cols: "12"
                                                              }
                                                            },
                                                            [
                                                              _c("v-row", {
                                                                staticClass:
                                                                  "mr-0 ml-0 mt-3",
                                                                attrs: {
                                                                  align:
                                                                    "center"
                                                                }
                                                              }),
                                                              _vm._v(" "),
                                                              _c(
                                                                "fd-chip-selector",
                                                                {
                                                                  attrs: {
                                                                    "available-items":
                                                                      _vm.availableTags,
                                                                    "selected-items":
                                                                      _vm.selectedTags,
                                                                    "item-label":
                                                                      "name",
                                                                    disabled:
                                                                      _vm.processing,
                                                                    "control-label": _vm.$t(
                                                                      "common.add-tags"
                                                                    ),
                                                                    "chip-class":
                                                                      "keywords-selection-chip-colors"
                                                                  },
                                                                  on: {
                                                                    "update:selectedItems": function(
                                                                      $event
                                                                    ) {
                                                                      _vm.selectedTags = $event
                                                                    },
                                                                    "update:selected-items": function(
                                                                      $event
                                                                    ) {
                                                                      _vm.selectedTags = $event
                                                                    }
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "12"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-row",
                                                                {
                                                                  staticClass:
                                                                    "mr-0 ml-0",
                                                                  attrs: {
                                                                    align:
                                                                      "center"
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "fd-chip-selector",
                                                                    {
                                                                      attrs: {
                                                                        "available-items":
                                                                          _vm.availableSuppliers,
                                                                        "selected-items":
                                                                          _vm.selectedSuppliers,
                                                                        "item-label":
                                                                          "alias",
                                                                        disabled:
                                                                          _vm.processing,
                                                                        "control-label": _vm.$t(
                                                                          "common.add-suppliers"
                                                                        ),
                                                                        "chip-class":
                                                                          "keywords-selection-chip-colors-secondary"
                                                                      },
                                                                      on: {
                                                                        "update:selectedItems": function(
                                                                          $event
                                                                        ) {
                                                                          _vm.selectedSuppliers = $event
                                                                        },
                                                                        "update:selected-items": function(
                                                                          $event
                                                                        ) {
                                                                          _vm.selectedSuppliers = $event
                                                                        }
                                                                      }
                                                                    }
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              staticClass:
                                                                "align-center justify-space-between",
                                                              attrs: {
                                                                cols: "12",
                                                                sm: "12",
                                                                md: "6"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-row",
                                                                {
                                                                  staticClass:
                                                                    "mr-0 ml-0",
                                                                  attrs: {
                                                                    align:
                                                                      "center"
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-text-field",
                                                                    {
                                                                      attrs: {
                                                                        label: _vm.$t(
                                                                          "common.name"
                                                                        ),
                                                                        rules: [
                                                                          _vm
                                                                            .rules
                                                                            .required
                                                                        ],
                                                                        disabled:
                                                                          _vm.processing
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .part
                                                                            .name,
                                                                        callback: function(
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.part,
                                                                            "name",
                                                                            $$v
                                                                          )
                                                                        },
                                                                        expression:
                                                                          "part.name"
                                                                      }
                                                                    }
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "12",
                                                                sm: "12",
                                                                md: "6"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-row",
                                                                {
                                                                  staticClass:
                                                                    "mr-0 ml-0",
                                                                  attrs: {
                                                                    align:
                                                                      "center"
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-text-field",
                                                                    {
                                                                      attrs: {
                                                                        label: _vm.$t(
                                                                          "common.description"
                                                                        ),
                                                                        disabled:
                                                                          _vm.processing,
                                                                        counter:
                                                                          "",
                                                                        maxlength:
                                                                          "30"
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .part
                                                                            .description,
                                                                        callback: function(
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.part,
                                                                            "description",
                                                                            $$v
                                                                          )
                                                                        },
                                                                        expression:
                                                                          "part.description"
                                                                      }
                                                                    }
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              staticClass:
                                                                "align-center justify-space-between",
                                                              attrs: {
                                                                cols: "12",
                                                                sm: "12",
                                                                md: "6"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-row",
                                                                {
                                                                  staticClass:
                                                                    "mr-0 ml-0",
                                                                  attrs: {
                                                                    align:
                                                                      "center"
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-text-field",
                                                                    {
                                                                      attrs: {
                                                                        label: _vm.$t(
                                                                          "parts.mpp"
                                                                        ),
                                                                        rules: [
                                                                          _vm
                                                                            .rules
                                                                            .required,
                                                                          _vm
                                                                            .rules
                                                                            .numbersonly
                                                                        ],
                                                                        disabled:
                                                                          _vm.processing
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .part
                                                                            .mpp,
                                                                        callback: function(
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.part,
                                                                            "mpp",
                                                                            _vm._n(
                                                                              $$v
                                                                            )
                                                                          )
                                                                        },
                                                                        expression:
                                                                          "part.mpp"
                                                                      }
                                                                    }
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "12",
                                                                sm: "12",
                                                                md: "6"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-row",
                                                                {
                                                                  staticClass:
                                                                    "mr-0 ml-0",
                                                                  attrs: {
                                                                    align:
                                                                      "center"
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-text-field",
                                                                    {
                                                                      attrs: {
                                                                        label: _vm.$t(
                                                                          "parts.weightkg"
                                                                        ),
                                                                        rules: [
                                                                          _vm
                                                                            .rules
                                                                            .required,
                                                                          _vm
                                                                            .rules
                                                                            .numbersonly
                                                                        ],
                                                                        disabled:
                                                                          _vm.processing
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .part
                                                                            .weight,
                                                                        callback: function(
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.part,
                                                                            "weight",
                                                                            _vm._n(
                                                                              $$v
                                                                            )
                                                                          )
                                                                        },
                                                                        expression:
                                                                          "part.weight"
                                                                      }
                                                                    }
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              staticClass:
                                                                "align-center justify-space-between",
                                                              attrs: {
                                                                cols: "12",
                                                                sm: "12",
                                                                md: "3"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-row",
                                                                {
                                                                  staticClass:
                                                                    "mr-0 ml-0",
                                                                  attrs: {
                                                                    align:
                                                                      "center"
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-text-field",
                                                                    {
                                                                      attrs: {
                                                                        label: _vm.$t(
                                                                          "parts.cleating"
                                                                        ),
                                                                        rules: [
                                                                          _vm
                                                                            .rules
                                                                            .numbersonly
                                                                        ],
                                                                        disabled:
                                                                          _vm.processing
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .part
                                                                            .cleatingMPP,
                                                                        callback: function(
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.part,
                                                                            "cleatingMPP",
                                                                            _vm._n(
                                                                              $$v
                                                                            )
                                                                          )
                                                                        },
                                                                        expression:
                                                                          "part.cleatingMPP"
                                                                      }
                                                                    }
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              staticClass:
                                                                "align-center justify-space-between",
                                                              attrs: {
                                                                cols: "12",
                                                                sm: "12",
                                                                md: "3"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-row",
                                                                {
                                                                  staticClass:
                                                                    "mr-0 ml-0",
                                                                  attrs: {
                                                                    align:
                                                                      "center"
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-text-field",
                                                                    {
                                                                      attrs: {
                                                                        label: _vm.$t(
                                                                          "parts.lashing"
                                                                        ),
                                                                        rules: [
                                                                          _vm
                                                                            .rules
                                                                            .numbersonly
                                                                        ],
                                                                        disabled:
                                                                          _vm.processing
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .part
                                                                            .lashingMPP,
                                                                        callback: function(
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.part,
                                                                            "lashingMPP",
                                                                            _vm._n(
                                                                              $$v
                                                                            )
                                                                          )
                                                                        },
                                                                        expression:
                                                                          "part.lashingMPP"
                                                                      }
                                                                    }
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              staticClass:
                                                                "align-center justify-space-between",
                                                              attrs: {
                                                                cols: "12",
                                                                sm: "12",
                                                                md: "3"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-row",
                                                                {
                                                                  staticClass:
                                                                    "mr-0 ml-0",
                                                                  attrs: {
                                                                    align:
                                                                      "center"
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-text-field",
                                                                    {
                                                                      attrs: {
                                                                        label: _vm.$t(
                                                                          "parts.carpentry"
                                                                        ),
                                                                        rules: [
                                                                          _vm
                                                                            .rules
                                                                            .numbersonly
                                                                        ],
                                                                        disabled:
                                                                          _vm.processing
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .part
                                                                            .carpentryMPP,
                                                                        callback: function(
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.part,
                                                                            "carpentryMPP",
                                                                            _vm._n(
                                                                              $$v
                                                                            )
                                                                          )
                                                                        },
                                                                        expression:
                                                                          "part.carpentryMPP"
                                                                      }
                                                                    }
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              staticClass:
                                                                "align-center justify-space-between",
                                                              attrs: {
                                                                cols: "12",
                                                                sm: "12",
                                                                md: "3"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-row",
                                                                {
                                                                  staticClass:
                                                                    "mr-0 ml-0",
                                                                  attrs: {
                                                                    align:
                                                                      "center"
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-text-field",
                                                                    {
                                                                      attrs: {
                                                                        label: _vm.$t(
                                                                          "parts.other"
                                                                        ),
                                                                        rules: [
                                                                          _vm
                                                                            .rules
                                                                            .numbersonly
                                                                        ],
                                                                        disabled:
                                                                          _vm.processing
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .part
                                                                            .otherMPP,
                                                                        callback: function(
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.part,
                                                                            "otherMPP",
                                                                            _vm._n(
                                                                              $$v
                                                                            )
                                                                          )
                                                                        },
                                                                        expression:
                                                                          "part.otherMPP"
                                                                      }
                                                                    }
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "12"
                                                              }
                                                            },
                                                            [
                                                              _vm.inlineMessage
                                                                .message
                                                                ? _c(
                                                                    "v-alert",
                                                                    {
                                                                      attrs: {
                                                                        type:
                                                                          _vm
                                                                            .inlineMessage
                                                                            .type
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                            " +
                                                                          _vm._s(
                                                                            _vm
                                                                              .inlineMessage
                                                                              .message
                                                                          ) +
                                                                          "\n                          "
                                                                      )
                                                                    ]
                                                                  )
                                                                : _vm._e()
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-row",
                                                        {
                                                          attrs: {
                                                            justify: "end"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "pr-3 pb-3"
                                                            },
                                                            [
                                                              _c(
                                                                "v-btn",
                                                                {
                                                                  attrs: {
                                                                    text: "",
                                                                    link: "",
                                                                    loading:
                                                                      _vm.processing,
                                                                    disabled:
                                                                      _vm.processing
                                                                  },
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.cancelItemAdd(
                                                                        item
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "common.cancel"
                                                                      )
                                                                    )
                                                                  )
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "v-btn",
                                                                {
                                                                  attrs: {
                                                                    color:
                                                                      "primary",
                                                                    loading:
                                                                      _vm.processing,
                                                                    disabled:
                                                                      _vm.processing
                                                                  },
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.mockSavePartToCatalog(
                                                                        item
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "common.save"
                                                                      )
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          }
                                        },
                                        {
                                          key: "item.ignored",
                                          fn: function({ item }) {
                                            return [
                                              !item.ignored
                                                ? _c(
                                                    "div",
                                                    [
                                                      _c(
                                                        "v-tooltip",
                                                        {
                                                          attrs: { top: "" },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key:
                                                                  "activator",
                                                                fn: function({
                                                                  on
                                                                }) {
                                                                  return [
                                                                    _c(
                                                                      "v-icon",
                                                                      _vm._g(
                                                                        {
                                                                          attrs: {
                                                                            color:
                                                                              "red",
                                                                            dark:
                                                                              ""
                                                                          }
                                                                        },
                                                                        on
                                                                      ),
                                                                      [
                                                                        _vm._v(
                                                                          "fa-exclamation-triangle"
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                }
                                                              }
                                                            ],
                                                            null,
                                                            true
                                                          )
                                                        },
                                                        [
                                                          _vm._v(" "),
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "estimates.parts-selection-individual-part-needs-to-be-addressed"
                                                                )
                                                              )
                                                            )
                                                          ])
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _c(
                                                    "div",
                                                    [
                                                      _c(
                                                        "v-tooltip",
                                                        {
                                                          attrs: { top: "" },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key:
                                                                  "activator",
                                                                fn: function({
                                                                  on
                                                                }) {
                                                                  return [
                                                                    _c(
                                                                      "v-icon",
                                                                      _vm._g(
                                                                        {
                                                                          attrs: {
                                                                            color:
                                                                              "blue",
                                                                            dark:
                                                                              ""
                                                                          }
                                                                        },
                                                                        on
                                                                      ),
                                                                      [
                                                                        _vm._v(
                                                                          "fa-ban"
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                }
                                                              }
                                                            ],
                                                            null,
                                                            true
                                                          )
                                                        },
                                                        [
                                                          _vm._v(" "),
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "estimates.parts-selection-individual-part-ignored"
                                                                )
                                                              )
                                                            )
                                                          ])
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      1747921033
                                    )
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "v-card",
                            { attrs: { flat: "", tile: "" } },
                            [
                              _c(
                                "v-col",
                                { staticClass: "pa-0", attrs: { cols: "12" } },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c(
                                        "v-row",
                                        { staticClass: "ml-0 mr-0" },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pb-sm-0",
                                              attrs: {
                                                cols: "12",
                                                sm: "12",
                                                md: "7"
                                              }
                                            },
                                            [
                                              _c(
                                                "v-card-title",
                                                { staticClass: "pa-0" },
                                                [
                                                  _c("v-switch", {
                                                    attrs: {
                                                      label: _vm.$t(
                                                        "clients.projects.only-included-label"
                                                      )
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.showOnlyIncluded,
                                                      callback: function($$v) {
                                                        _vm.showOnlyIncluded = $$v
                                                      },
                                                      expression:
                                                        "showOnlyIncluded"
                                                    }
                                                  }),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-tooltip",
                                                    {
                                                      attrs: { top: "" },
                                                      scopedSlots: _vm._u([
                                                        {
                                                          key: "activator",
                                                          fn: function({ on }) {
                                                            return [
                                                              _c(
                                                                "v-icon",
                                                                _vm._g(
                                                                  {
                                                                    staticClass:
                                                                      "ml-4",
                                                                    attrs: {
                                                                      color:
                                                                        "black",
                                                                      dark: ""
                                                                    }
                                                                  },
                                                                  on
                                                                ),
                                                                [_vm._v("info")]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ])
                                                    },
                                                    [
                                                      _vm._v(" "),
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "clients.projects.parts-selection-sub-menu-information"
                                                            )
                                                          )
                                                        )
                                                      ])
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "pt-sm-1 pt-md-3 pb-0",
                                              attrs: {
                                                cols: "12",
                                                sm: "12",
                                                md: "5"
                                              }
                                            },
                                            [
                                              _c("v-combobox", {
                                                class: [
                                                  _vm.tagsSelectedForFiltering
                                                    .length > 0
                                                    ? "fd-combobox-control-item-selected-small"
                                                    : "fd-combobox-control-item-not-selected"
                                                ],
                                                attrs: {
                                                  items: _vm.tagsInUse,
                                                  label: _vm.$t(
                                                    "common.filter-by-tags"
                                                  ),
                                                  "item-text": "name",
                                                  "item-value": "id",
                                                  multiple: "",
                                                  "small-chips": "",
                                                  clearable: ""
                                                },
                                                model: {
                                                  value:
                                                    _vm.tagsSelectedForFiltering,
                                                  callback: function($$v) {
                                                    _vm.tagsSelectedForFiltering = $$v
                                                  },
                                                  expression:
                                                    "tagsSelectedForFiltering"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-row",
                                        { staticClass: "mr-0 ml-0" },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "order-md-1 order-0 pb-0",
                                              attrs: {
                                                cols: "12",
                                                sm: "12",
                                                md: "5"
                                              }
                                            },
                                            [
                                              _c("v-select", {
                                                class: [
                                                  _vm
                                                    .suppliersSelectedForFiltering
                                                    .length > 0
                                                    ? "fd-select-control-item-selected"
                                                    : "",
                                                  "pt-3"
                                                ],
                                                attrs: {
                                                  "item-text": "alias",
                                                  "item-value": "id",
                                                  items: _vm.suppliersInUse,
                                                  label: _vm.$t(
                                                    "clients.projects.parts-selection-supplier-filter-label"
                                                  ),
                                                  multiple: "",
                                                  "small-chips": "",
                                                  clearable: ""
                                                },
                                                model: {
                                                  value:
                                                    _vm.suppliersSelectedForFiltering,
                                                  callback: function($$v) {
                                                    _vm.suppliersSelectedForFiltering = $$v
                                                  },
                                                  expression:
                                                    "suppliersSelectedForFiltering"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "order-0",
                                              attrs: {
                                                cols: "12",
                                                sm: "12",
                                                md: "7"
                                              }
                                            },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  "append-icon": "search",
                                                  label: _vm.$t(
                                                    "common.search"
                                                  ),
                                                  "single-line": "",
                                                  "hide-details": "",
                                                  clearable: ""
                                                },
                                                model: {
                                                  value: _vm.tablesearchparts,
                                                  callback: function($$v) {
                                                    _vm.tablesearchparts = $$v
                                                  },
                                                  expression: "tablesearchparts"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("v-data-table", {
                                    directives: [
                                      {
                                        name: "fd-column",
                                        rawName: "v-fd-column:name",
                                        arg: "name"
                                      },
                                      {
                                        name: "fd-column",
                                        rawName:
                                          "v-fd-column:description.hide-when-small",
                                        arg: "description",
                                        modifiers: { "hide-when-small": true }
                                      },
                                      {
                                        name: "fd-column",
                                        rawName: "v-fd-column:quantity.no-sort",
                                        arg: "quantity",
                                        modifiers: { "no-sort": true }
                                      }
                                    ],
                                    staticClass:
                                      "fixed-header v-table__overflow",
                                    staticStyle: {
                                      "max-height": "calc(40vh - 10px)",
                                      "backface-visibility": "hidden"
                                    },
                                    attrs: {
                                      items: _vm.mockPartsSelection,
                                      search: _vm.tablesearchparts,
                                      loading: _vm.processing,
                                      "loading-text": _vm.$t(
                                        "common.table-loading-message"
                                      ),
                                      "sort-by": _vm.sortPartsTableBy
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "item.quantity",
                                        fn: function({ item }) {
                                          return [
                                            _c("fd-inline-edit-dialog", {
                                              attrs: {
                                                value:
                                                  item.quantity != null
                                                    ? item.quantity
                                                    : "0",
                                                rules: [_vm.rules.numbersonly],
                                                label: "Edit",
                                                numeric: ""
                                              },
                                              on: {
                                                "update:value": value =>
                                                  _vm.saveQuantity(
                                                    item,
                                                    "quantity",
                                                    value
                                                  )
                                              }
                                            })
                                          ]
                                        }
                                      }
                                    ])
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-card-actions",
                            {
                              staticClass:
                                "section-stepper-under-secondary-stepper-section"
                            },
                            [
                              _c("v-spacer"),
                              _vm._v(" "),
                              _c(
                                "v-btn",
                                {
                                  attrs: { text: "" },
                                  on: { click: _vm.cancelDialog }
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.$t("common.cancel")) +
                                      "\n              "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-btn",
                                {
                                  attrs: { color: "primary" },
                                  on: {
                                    click: function($event) {
                                      _vm.step = 3
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.$t("common.continue")) +
                                      "\n              "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-stepper-content",
                    { attrs: { step: "3" } },
                    [
                      _c(
                        "v-card",
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                {
                                  staticStyle: { padding: "15px" },
                                  attrs: { cols: "4" }
                                },
                                [
                                  _c(
                                    "v-card",
                                    { staticClass: "estimate-summation" },
                                    [
                                      _c(
                                        "v-card-title",
                                        {
                                          staticClass:
                                            "subheading font-weight-bold"
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "estimates.summation-title"
                                              )
                                            )
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("v-divider"),
                                      _vm._v(" "),
                                      _vm._l(_vm.estimatesummation, function(
                                        item
                                      ) {
                                        return _c(
                                          "v-row",
                                          { key: item.name },
                                          [
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "8" } },
                                              [
                                                _c("v-row", [
                                                  _vm._v(
                                                    "\n                        " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "estimates." +
                                                            item.name
                                                        )
                                                      ) +
                                                      "\n                      "
                                                  )
                                                ])
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "4" } },
                                              [
                                                _c(
                                                  "v-row",
                                                  { attrs: { justify: "end" } },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        class: {
                                                          "card-positive-highlight-coloring":
                                                            item.hightlight
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(item.value)
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      })
                                    ],
                                    2
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass: "padding-top: 15px;",
                                  attrs: { cols: "8" }
                                },
                                _vm._l(_vm.modifiers, function(modifier) {
                                  return _c(
                                    "v-row",
                                    {
                                      key: modifier,
                                      staticClass: "pl-3 pr-3 pt-0"
                                    },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "pt-0",
                                          attrs: { cols: "12", sm: "12" }
                                        },
                                        [
                                          _c("v-select", {
                                            attrs: {
                                              "item-text": "name",
                                              "item-value": "id",
                                              items: _vm.modifiervalues,
                                              label: modifier.name,
                                              disabled: _vm.processing
                                            },
                                            model: {
                                              value: _vm.estimate.clientID,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.estimate,
                                                  "clientID",
                                                  $$v
                                                )
                                              },
                                              expression: "estimate.clientID"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                }),
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-card-actions",
                            [
                              _c("v-spacer"),
                              _vm._v(" "),
                              _c(
                                "v-btn",
                                {
                                  attrs: { text: "" },
                                  on: { click: _vm.cancelDialog }
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.$t("common.cancel")) +
                                      "\n              "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-btn",
                                {
                                  attrs: { color: "primary" },
                                  on: {
                                    click: function($event) {
                                      _vm.step = 4
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.$t("common.continue")) +
                                      "\n              "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-stepper-content",
                    { attrs: { step: "4" } },
                    [
                      _c(
                        "v-card",
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                {
                                  staticStyle: { padding: "15px" },
                                  attrs: { cols: "4" }
                                },
                                [
                                  _c(
                                    "v-card",
                                    { staticClass: "estimate-summation" },
                                    [
                                      _c(
                                        "v-card-title",
                                        {
                                          staticClass:
                                            "subheading font-weight-bold"
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "estimates.summation-title"
                                              )
                                            )
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("v-divider"),
                                      _vm._v(" "),
                                      _vm._l(_vm.estimatesummation, function(
                                        item
                                      ) {
                                        return _c(
                                          "v-row",
                                          { key: item.name },
                                          [
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "8" } },
                                              [
                                                _c("v-row", [
                                                  _vm._v(
                                                    "\n                        " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "estimates." +
                                                            item.name
                                                        )
                                                      ) +
                                                      "\n                      "
                                                  )
                                                ])
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "4" } },
                                              [
                                                _c(
                                                  "v-row",
                                                  { attrs: { justify: "end" } },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        class: {
                                                          "card-positive-highlight-coloring":
                                                            item.hightlight
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(item.value)
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      })
                                    ],
                                    2
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass: "padding-top: 15px;",
                                  attrs: { cols: "8" }
                                },
                                [
                                  _c(
                                    "v-row",
                                    { staticClass: "pl-3 pr-3 pt-0" },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "pt-0",
                                          attrs: { cols: "12" }
                                        },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: _vm.$t(
                                                "estimates.adjustments-risk-adder"
                                              ),
                                              disabled: _vm.processing
                                            },
                                            model: {
                                              value: _vm.estimate.riskadder,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.estimate,
                                                  "riskadder",
                                                  $$v
                                                )
                                              },
                                              expression: "estimate.riskadder"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "pt-0",
                                          attrs: { cols: "12" }
                                        },
                                        [
                                          _c("v-switch", {
                                            attrs: {
                                              label: _vm.$t(
                                                "estimates.adjustments-lashing"
                                              ),
                                              disabled: _vm.processing,
                                              "persistent-hint": "",
                                              hint: _vm.$t(
                                                "estimates.adjustments-lashing-hint"
                                              )
                                            },
                                            model: {
                                              value: _vm.estimate.uselashing,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.estimate,
                                                  "uselashing",
                                                  $$v
                                                )
                                              },
                                              expression: "estimate.uselashing"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-switch", {
                                            attrs: {
                                              label: _vm.$t(
                                                "estimates.adjustments-cleating"
                                              ),
                                              disabled: _vm.processing,
                                              "persistent-hint": "",
                                              hint: _vm.$t(
                                                "estimates.adjustments-cleating-hint"
                                              )
                                            },
                                            model: {
                                              value: _vm.estimate.usecleating,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.estimate,
                                                  "usecleating",
                                                  $$v
                                                )
                                              },
                                              expression: "estimate.usecleating"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-switch", {
                                            attrs: {
                                              label: _vm.$t(
                                                "estimates.adjustments-carpentry"
                                              ),
                                              disabled: _vm.processing,
                                              "persistent-hint": "",
                                              hint: _vm.$t(
                                                "estimates.adjustments-carpentry-hint"
                                              )
                                            },
                                            model: {
                                              value: _vm.estimate.usecarpentry,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.estimate,
                                                  "usecarpentry",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "estimate.usecarpentry"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-switch", {
                                            attrs: {
                                              label: _vm.$t(
                                                "estimates.adjustments-other"
                                              ),
                                              disabled: _vm.processing,
                                              "persistent-hint": "",
                                              hint: _vm.$t(
                                                "estimates.adjustments-other-hint"
                                              )
                                            },
                                            model: {
                                              value: _vm.estimate.usecarpentry,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.estimate,
                                                  "usecarpentry",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "estimate.usecarpentry"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-card-actions",
                            [
                              _c("v-spacer"),
                              _vm._v(" "),
                              _c(
                                "v-btn",
                                {
                                  attrs: { text: "" },
                                  on: { click: _vm.cancelDialog }
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.$t("common.cancel")) +
                                      "\n              "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-btn",
                                {
                                  attrs: { color: "primary" },
                                  on: { click: _vm.saveDialog }
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.$t("common.continue")) +
                                      "\n              "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }