var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { fluid: "", "data-cy": "todolist" } },
    [
      !_vm.currentUserCanViewToDoList
        ? _c("v-alert", { staticClass: "mt-0 mb-3", attrs: { type: "info" } }, [
            _vm._v(
              "\n    " +
                _vm._s(_vm.$t("to-do-list.no-permission-to-view")) +
                "\n  "
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("fd-image-viewer", {
        attrs: {
          imageList: _vm.imageList,
          "info-message": _vm.imageViewerInfoMessage,
          "allow-add-image": _vm.allowAddImage,
          loading: _vm.processing
        },
        on: {
          "update:infoMessage": function($event) {
            _vm.imageViewerInfoMessage = $event
          },
          "update:info-message": function($event) {
            _vm.imageViewerInfoMessage = $event
          },
          "add:image": _vm.addImage,
          input: v => (_vm.imageList = v)
        }
      }),
      _vm._v(" "),
      _c("input", {
        ref: "addFileButton",
        staticClass: "d-none",
        attrs: { type: "file", onclick: "this.value=null;" },
        on: { change: v => _vm.selectNewFile(v.target.files[0]) }
      }),
      _vm._v(" "),
      _vm.inlineMessage.message
        ? _c(
            "v-alert",
            {
              staticClass: "mt-0 mb-3",
              attrs: { type: _vm.inlineMessage.type }
            },
            [_vm._v("\n    " + _vm._s(_vm.inlineMessage.message) + "\n  ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-card",
        { staticClass: "fd-view no-tag-searching" },
        [
          _c(
            "fd-toolbar",
            { attrs: { extended: "", loading: _vm.processing } },
            [
              _c(
                "v-row",
                { staticClass: "pb-5 pb-sm-0" },
                [
                  _c("v-col", { attrs: { cols: "12", sm: "4" } }, [
                    _c(
                      "div",
                      {
                        class: [
                          _vm.processing ? "breadcrumb-processing-opacity" : ""
                        ],
                        staticStyle: {
                          "font-size": "1.40rem !important",
                          position: "relative"
                        }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              position: "absolute",
                              top: "0px",
                              left: "0px"
                            }
                          },
                          [
                            _c(
                              "div",
                              { staticStyle: { position: "relative" } },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.$t("to-do-list.title")) +
                                    "\n                "
                                ),
                                !!_vm.curUserAccess.contractorID
                                  ? _c("span", [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "hide-when-no-contractor-class"
                                        },
                                        [_vm._v("|")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "fd-view-header-contractor-qualifier-label"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "margin-left": "6px"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                      " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "contractors.contractor-select"
                                                    )
                                                  ) +
                                                  "\n                    "
                                              )
                                            ]
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "fd-view-header-contractor-qualifier-contractor-detail-positioning"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              class: [
                                                _vm.$vuetify.breakpoint.xs
                                                  ? "fd-view-header-contractor-qualifier-contractor-detail-xs-width-restrictor"
                                                  : "",
                                                "fd-view-header-contractor-qualifier-contractor-detail"
                                              ]
                                            },
                                            [
                                              _vm._v(
                                                "\n                      " +
                                                  _vm._s(
                                                    _vm.curUserContractorName
                                                  ) +
                                                  "\n                    "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ])
                                  : _vm._e()
                              ]
                            )
                          ]
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "0", sm: "8" } },
                    [
                      _c(
                        "v-row",
                        {
                          staticClass: "d-none d-sm-flex",
                          attrs: { justify: "end" }
                        },
                        [
                          _c("v-combobox", {
                            class: [
                              _vm.tagsSelectedForFiltering.length > 0
                                ? "fd-combobox-control-item-selected"
                                : "fd-combobox-control-item-not-selected",
                              "pr-3",
                              "fd-limit-combobox-size-to-content"
                            ],
                            attrs: {
                              items: _vm.tagsInUse,
                              label: _vm.$t("common.filter-by-tags"),
                              "item-text": "name",
                              "item-value": "id",
                              multiple: "",
                              "small-chips": "",
                              clearable: ""
                            },
                            model: {
                              value: _vm.tagsSelectedForFiltering,
                              callback: function($$v) {
                                _vm.tagsSelectedForFiltering = $$v
                              },
                              expression: "tagsSelectedForFiltering"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            {
              staticClass:
                "d-flex align-center justify-end ml-0 mr-0 fd-inline-radio-button-qualifier-background"
            },
            [
              _c(
                "v-radio-group",
                {
                  staticClass: "fd-badge-radio-group",
                  attrs: { row: "" },
                  model: {
                    value: _vm.selectedWorkItemType,
                    callback: function($$v) {
                      _vm.selectedWorkItemType = $$v
                    },
                    expression: "selectedWorkItemType"
                  }
                },
                [
                  _c(
                    "v-badge",
                    { attrs: { color: "primary", content: _vm.walkdownCount } },
                    [
                      _c("v-radio", {
                        attrs: {
                          label: _vm.$t("to-do-list.walk-downs-radio"),
                          value: "walkdown",
                          "data-cy": "radiowalkdown"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-badge",
                    {
                      attrs: { color: "primary", content: _vm.workOrderCount }
                    },
                    [
                      _c("v-radio", {
                        attrs: {
                          label: _vm.$t("to-do-list.work-orders-radio"),
                          value: "workOrder",
                          "data-cy": "radioworkorder"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-subtitle",
            { staticClass: "pb-sm-0" },
            [
              _c(
                "v-row",
                [
                  _vm.$vuetify.breakpoint.smAndUp &&
                  ((!_vm.canViewContractorFilter &&
                    _vm.canViewDisciplinesFilter &&
                    _vm.canViewPersonFilter) ||
                    (_vm.canViewContractorFilter &&
                      !_vm.canViewDisciplinesFilter &&
                      _vm.canViewPersonFilter))
                    ? _c("v-col", {
                        class: ["pt-0", "pb-0"],
                        attrs: { cols: "12", xs: "12", sm: "6", md: "6" }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.canViewContractorFilter
                    ? _c(
                        "v-col",
                        {
                          class: [
                            "pt-0",
                            "pb-0",
                            {
                              "order-sm-6":
                                _vm.canViewContractorFilter &&
                                !_vm.canViewDisciplinesFilter &&
                                !_vm.canViewPersonFilter
                            },
                            {
                              "pt-sm-0":
                                _vm.canViewPersonFilter ||
                                (_vm.canViewContractorFilter &&
                                  _vm.canViewDisciplinesFilter)
                            }
                          ],
                          attrs: { cols: "12", xs: "12", sm: "6", md: "6" }
                        },
                        [
                          _c("v-select", {
                            staticClass: "pt-0 mt-0",
                            attrs: {
                              "data-cy": "contractorfilter",
                              label: _vm.$t(
                                "scheduler.filter-by-contractor-label"
                              ),
                              disabled: _vm.processing,
                              items: _vm.selectableContractors,
                              "item-text": "name",
                              "item-value": "id",
                              "small-chips": "",
                              clearable: ""
                            },
                            model: {
                              value: _vm.selectedContractors,
                              callback: function($$v) {
                                _vm.selectedContractors = $$v
                              },
                              expression: "selectedContractors"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.canViewDisciplinesFilter
                    ? _c(
                        "v-col",
                        {
                          class: [
                            "pt-0",
                            "pb-0",
                            {
                              "order-sm-6":
                                !_vm.canViewContractorFilter &&
                                _vm.canViewDisciplinesFilter &&
                                !_vm.canViewPersonFilter
                            },
                            {
                              "pt-sm-0":
                                _vm.canViewPersonFilter ||
                                (_vm.canViewContractorFilter &&
                                  _vm.canViewDisciplinesFilter)
                            }
                          ],
                          attrs: { cols: "12", xs: "12", sm: "6", md: "6" }
                        },
                        [
                          _c("v-select", {
                            staticClass: "pt-0 mt-0",
                            attrs: {
                              label: _vm.$t(
                                "scaffold-requests.filter-by-discipline-label"
                              ),
                              disabled: _vm.processing,
                              items: _vm.selectableDisciplines,
                              "item-text": "name",
                              "item-value": "id",
                              multiple: "",
                              "small-chips": "",
                              clearable: ""
                            },
                            model: {
                              value: _vm.selectedDisciplines,
                              callback: function($$v) {
                                _vm.selectedDisciplines = $$v
                              },
                              expression: "selectedDisciplines"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    {
                      staticClass: "pt-0 pb-0",
                      attrs: { cols: "12", sm: "6" }
                    },
                    [
                      _c("v-select", {
                        staticClass: "pt-0 mt-0",
                        attrs: {
                          label: _vm.$t("scheduler.filter-by-area-label"),
                          disabled: _vm.processing,
                          items: _vm.selectableAreas,
                          "item-text": "name",
                          "item-value": "id",
                          multiple: "",
                          "small-chips": "",
                          clearable: ""
                        },
                        model: {
                          value: _vm.selectedAreas,
                          callback: function($$v) {
                            _vm.selectedAreas = $$v
                          },
                          expression: "selectedAreas"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    {
                      staticClass: "pt-0 pb-0",
                      attrs: { cols: "12", sm: "6" }
                    },
                    [
                      _c("v-select", {
                        staticClass: "pt-0 mt-0",
                        attrs: {
                          label: _vm.$t("scheduler.filter-by-sub-area-label"),
                          disabled:
                            !_vm.selectableSubAreas.length || _vm.processing,
                          items: _vm.selectableSubAreas,
                          "item-text": "name",
                          "item-value": "id",
                          multiple: "",
                          "small-chips": "",
                          clearable: ""
                        },
                        model: {
                          value: _vm.selectedSubAreas,
                          callback: function($$v) {
                            _vm.selectedSubAreas = $$v
                          },
                          expression: "selectedSubAreas"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    {
                      staticClass: "pt-0 pb-0 pb-sm-5 order-sm-1 order-2",
                      attrs: {
                        cols: "12",
                        sm:
                          _vm.canViewPersonFilter ||
                          (_vm.canViewDisciplinesFilter &&
                            !_vm.canViewContractorFilter) ||
                          (_vm.canViewContractorFilter &&
                            !_vm.canViewDisciplinesFilter)
                            ? 6
                            : 12
                      }
                    },
                    [
                      _c(
                        "v-row",
                        {
                          attrs: {
                            "no-gutters": "",
                            align: "center",
                            justify: "center"
                          }
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "mr-5",
                              attrs: {
                                small: "",
                                rounded: "",
                                outlined: "",
                                color: "#7a7a7a"
                              },
                              on: { click: _vm.reloadTableData }
                            },
                            [
                              !_vm.$vuetify.breakpoint.sm ||
                              (_vm.$vuetify.breakpoint.sm &&
                                !_vm.canViewPersonFilter &&
                                !_vm.canViewContractorFilter &&
                                !_vm.canViewDisciplinesFilter)
                                ? _c(
                                    "v-icon",
                                    {
                                      attrs: {
                                        small: "",
                                        left: _vm.$vuetify.breakpoint.smAndUp
                                      }
                                    },
                                    [_vm._v("mdi-reload")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.$vuetify.breakpoint.smAndUp
                                ? _c("span", [
                                    _vm._v(_vm._s(_vm.$t("common.reload")))
                                  ])
                                : _vm._e()
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-text-field", {
                            class: [
                              _vm.$vuetify.breakpoint.sm
                                ? "fd-restrict-clearable-position"
                                : "",
                              "pt-0",
                              "mt-0",
                              "fd-table-search-input"
                            ],
                            attrs: {
                              "data-cy": "tablesearch",
                              "append-icon": "search",
                              label: _vm.$t("common.search"),
                              "single-line": "",
                              "hide-details": "",
                              clearable: ""
                            },
                            model: {
                              value: _vm.tablesearch,
                              callback: function($$v) {
                                _vm.tablesearch = $$v
                              },
                              expression: "tablesearch"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.canViewPersonFilter
                    ? _c(
                        "v-col",
                        {
                          staticClass: "pt-0 pb-0 order-sm-2 order-1 d-flex",
                          staticStyle: { flex: "1 0" },
                          attrs: { cols: "12", sm: "6" }
                        },
                        [
                          _c("v-autocomplete", {
                            staticClass: "pt-0 mt-0",
                            attrs: {
                              "data-cy": "personfilter",
                              label: _vm.$t(
                                "to-do-list.filter-by-person-label"
                              ),
                              disabled: _vm.processing,
                              items: _vm.visibleForemen,
                              "item-text": "name",
                              "item-value": "id",
                              "small-chips": ""
                            },
                            model: {
                              value: _vm.selectedForemanID,
                              callback: function($$v) {
                                _vm.selectedForemanID = $$v
                              },
                              expression: "selectedForemanID"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            {
              staticClass: "justify-space-between fd-draggable px-3",
              attrs: { "data-cy": "kanban" }
            },
            [
              _vm.processing
                ? _c(
                    "v-col",
                    { staticClass: "px-0 pb-0", attrs: { cols: "12" } },
                    [_c("v-progress-linear", { attrs: { indeterminate: "" } })],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "v-col",
                {
                  staticClass: "align-stretch fd-todo-column pl-0 pr-2 pt-0",
                  attrs: { cols: "4" }
                },
                [
                  _c(
                    "div",
                    { staticClass: "fd-kanban-column" },
                    [
                      _c("div", { staticClass: "drag-column-header" }, [
                        _vm._v(_vm._s(_vm.$t("to-do-list.to-do-column")))
                      ]),
                      _vm._v(" "),
                      _c(
                        "draggable",
                        _vm._b(
                          {
                            ref: "todocolumn",
                            attrs: { group: "kanban", tag: "ul" },
                            on: {
                              start: function($event) {
                                _vm.drag = true
                              },
                              end: function($event) {
                                _vm.drag = false
                              }
                            },
                            model: {
                              value: _vm.todo,
                              callback: function($$v) {
                                _vm.todo = $$v
                              },
                              expression: "todo"
                            }
                          },
                          "draggable",
                          _vm.dragOptions,
                          false
                        ),
                        _vm._l(_vm.todo, function(item) {
                          return _c("fd-kanban-item", {
                            key: item.id,
                            attrs: {
                              item: item,
                              loading: _vm.processing,
                              disabled: _vm.processing,
                              notesCount: _vm.notesCountForItem(item),
                              "prevent-labour-entry":
                                !_vm.currentUserCanViewTimesheets ||
                                !_vm.isLabourEntryEnabled
                            },
                            on: {
                              viewNotesForItem: function($event) {
                                return _vm.viewNotesForItem(item)
                              },
                              viewImagesForItem: function($event) {
                                return _vm.viewImagesForItem(item)
                              },
                              viewAttachmentsForItem: function($event) {
                                return _vm.viewAttachmentsForItem(item)
                              },
                              showItemSummary: function($event) {
                                return _vm.showItemSummary(item)
                              },
                              openWalkdownDialog: function($event) {
                                return _vm.openWalkdownDialog(item)
                              },
                              saveWorkOrderField: _vm.saveWorkOrderField,
                              toggleWorkOrderOnHold: _vm.toggleWorkOrderOnHold,
                              updateWorkOrderProgress:
                                _vm.updateWorkOrderProgress,
                              showWorkOrderCountSheetDialog:
                                _vm.showWorkOrderCountSheetDialog,
                              showLabourEntryDialog: _vm.showLabourEntryDialog
                            }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "4 fd-inprogress-column pl-1 pr-1 pt-0" } },
                [
                  _c(
                    "div",
                    { staticClass: "fd-kanban-column" },
                    [
                      _c("div", { staticClass: "drag-column-header" }, [
                        _vm._v(_vm._s(_vm.$t("to-do-list.started-column")))
                      ]),
                      _vm._v(" "),
                      _c(
                        "draggable",
                        _vm._b(
                          {
                            ref: "startedcolumn",
                            attrs: { group: "kanban", tag: "ul" },
                            on: {
                              start: function($event) {
                                _vm.drag = true
                              },
                              end: function($event) {
                                _vm.drag = false
                              }
                            },
                            model: {
                              value: _vm.started,
                              callback: function($$v) {
                                _vm.started = $$v
                              },
                              expression: "started"
                            }
                          },
                          "draggable",
                          _vm.dragOptions,
                          false
                        ),
                        _vm._l(_vm.started, function(item) {
                          return _c("fd-kanban-item", {
                            key: item.id,
                            attrs: {
                              item: item,
                              loading: _vm.processing,
                              disabled: _vm.processing,
                              "in-progress": "",
                              notesCount: _vm.notesCountForItem(item),
                              "prevent-labour-entry":
                                !_vm.currentUserCanViewTimesheets ||
                                !_vm.isLabourEntryEnabled
                            },
                            on: {
                              viewNotesForItem: function($event) {
                                return _vm.viewNotesForItem(item)
                              },
                              viewImagesForItem: function($event) {
                                return _vm.viewImagesForItem(item)
                              },
                              viewAttachmentsForItem: function($event) {
                                return _vm.viewAttachmentsForItem(item)
                              },
                              showItemSummary: function($event) {
                                return _vm.showItemSummary(item)
                              },
                              openWalkdownDialog: function($event) {
                                return _vm.openWalkdownDialog(item)
                              },
                              saveWorkOrderField: _vm.saveWorkOrderField,
                              toggleWorkOrderOnHold: _vm.toggleWorkOrderOnHold,
                              updateWorkOrderProgress:
                                _vm.updateWorkOrderProgress,
                              showWorkOrderCountSheetDialog:
                                _vm.showWorkOrderCountSheetDialog,
                              showLabourEntryDialog: _vm.showLabourEntryDialog
                            }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "4 fd-done-column pl-2 pr-0 pt-0" } },
                [
                  _c(
                    "div",
                    { staticClass: "fd-kanban-column" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "drag-column-header fd-position-relative"
                        },
                        [
                          _c("div", { staticStyle: { "flex-grow": "1" } }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.selectedWorkItemType == "workOrder"
                                    ? _vm.$t(
                                        "to-do-list.work-order-completed-column"
                                      )
                                    : _vm.$t(
                                        "to-do-list.walk-down-completed-column"
                                      )
                                ) +
                                "\n            "
                            )
                          ]),
                          _vm._v(" "),
                          _vm.showTimesheetActionButton
                            ? _c(
                                "v-btn",
                                {
                                  class: [
                                    "white--text",
                                    {
                                      "fd-small-timesheet":
                                        _vm.useSmallActionButtons,
                                      "mr-1": _vm.useSmallActionButtons,
                                      "mr-2": !_vm.useSmallActionButtons
                                    }
                                  ],
                                  attrs: { color: "blue-grey", small: "" },
                                  on: { click: _vm.showForemanTimesheetDialog }
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      class: [
                                        { "mr-0": !_vm.showActionButtonText }
                                      ],
                                      attrs: { left: "" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                mdi-clock\n              "
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm.showActionButtonText
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.$t("common.timesheet"))
                                        )
                                      ])
                                    : _vm._e()
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.showWorkOrderHistoryActionButton
                            ? _c(
                                "v-btn",
                                {
                                  class: [
                                    "white--text",
                                    {
                                      "fd-small-history":
                                        _vm.useSmallActionButtons
                                    }
                                  ],
                                  attrs: { color: "blue-grey", small: "" },
                                  on: { click: _vm.openWorkOrderHistoryDialog }
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      class: [
                                        { "mr-0": !_vm.showActionButtonText }
                                      ],
                                      attrs: { left: "" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                mdi-history\n              "
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm.showActionButtonText
                                    ? _c("span", [
                                        _vm._v(_vm._s(_vm.$t("common.history")))
                                      ])
                                    : _vm._e()
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "draggable",
                        _vm._b(
                          {
                            ref: "completedecolumn",
                            attrs: { group: "kanban", tag: "ul" },
                            model: {
                              value: _vm.completed,
                              callback: function($$v) {
                                _vm.completed = $$v
                              },
                              expression: "completed"
                            }
                          },
                          "draggable",
                          _vm.dragOptions,
                          false
                        ),
                        _vm._l(_vm.completed, function(item) {
                          return _c("fd-kanban-item", {
                            key: item.id,
                            attrs: {
                              item: item,
                              loading: _vm.processing,
                              disabled: _vm.processing,
                              notesCount: _vm.notesCountForItem(item),
                              "prevent-labour-entry":
                                !_vm.currentUserCanViewTimesheets ||
                                !_vm.isLabourEntryEnabled
                            },
                            on: {
                              viewNotesForItem: function($event) {
                                return _vm.viewNotesForItem(item)
                              },
                              viewImagesForItem: function($event) {
                                return _vm.viewImagesForItem(item)
                              },
                              viewAttachmentsForItem: function($event) {
                                return _vm.viewAttachmentsForItem(item)
                              },
                              showItemSummary: function($event) {
                                return _vm.showItemSummary(item)
                              },
                              openWalkdownDialog: function($event) {
                                return _vm.openWalkdownDialog(item)
                              },
                              saveWorkOrderField: _vm.saveWorkOrderField,
                              toggleWorkOrderOnHold: _vm.toggleWorkOrderOnHold,
                              updateWorkOrderProgress:
                                _vm.updateWorkOrderProgress,
                              showWorkOrderCountSheetDialog:
                                _vm.showWorkOrderCountSheetDialog,
                              showLabourEntryDialog: _vm.showLabourEntryDialog
                            }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ]
              ),
              _vm._v(" "),
              !_vm.selectedForemanID
                ? _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "v-row",
                        { staticStyle: { "justify-content": "center" } },
                        [
                          _c("fd-alert", {
                            staticClass: "mb-5",
                            staticStyle: { "border-width": "0px !important" },
                            attrs: {
                              label: _vm.$t("to-do-list.select-foreman"),
                              outlined: "",
                              color: "primary"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }