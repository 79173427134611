import FDVue from "@fd/lib/vue";
import userAccess from "../dataMixins/userAccess";
import serviceErrorHandling from "@fd/lib/vue/mixins/serviceErrorHandling";
import { mapActions, mapMutations } from "vuex";
import tabbedView, { PageTab, Tab } from "@fd/lib/vue/mixins/tabbedView";
import rules from "@fd/lib/vue/rules";
import {
  BuildDismantleRatio,
  contractorService,
  ContractorWithTags,
  Environment,
  environmentService,
  InspectionTime,
  personService,
  ProjectCostCode,
  ScaffoldDistanceModifier,
  ScaffoldElevationModifier,
  ScaffoldHeightModifier,
  ScaffoldTypeModifier,
  Yard
} from "../services";
import { PersonWithName, GetPersonName, SortItemsWithName } from "../utils/person";
import { GroupableSelectListOption, SelectListOption } from "@fd/lib/vue/utility/select";
import {
  getNameOfDayFromNumber,
  isoDateString,
  isoTimeString,
  isoTimeWithOffsetString,
  stripDateFromLocalizedDateTime
} from "../../../lib/client-util/datetime";
import { FDColumnDirective } from "../../../lib/vue/utility/dataTable";
import { openNewScaffoldInspectionTimeDialog } from "./components/dialogs/SP.ScaffoldInspectionTimeDialog.vue";

export default FDVue.extend({
  name: "fd-environment-configuration",
  mixins: [userAccess, serviceErrorHandling, rules, tabbedView],
  components: {
    "fd-time-picker": () => import("@fd/lib/vue/components/TimePicker.vue")
  },
  directives: {
    fdColumn: FDColumnDirective
  },
  data: function() {
    return {
      environment: {} as Environment,
      saving: false,
      styles: ["sunrise", "day", "sunset", "night"],

      // Form data errors
      detailsTabError: false,
      workflowTabError: false,

      firstTabKey: `0`,
      detailsTab: {
        tabname: this.$t("configuration.tabs.site-info"),
        key: "0",
        visible: true
      } as Tab,
      notificationsTab: {
        tabname: this.$t("configuration.tabs.notifications"),
        key: "1",
        visible: false
      } as Tab,
      workflowTab: {
        tabname: this.$t("configuration.tabs.workflow"),
        key: "2",
        visible: false
      } as Tab,
      defaultsTab: {
        tabname: this.$t("configuration.tabs.defaults"),
        key: "3",
        visible: false
      } as Tab,
      labourTab: {
        tabname: this.$t("configuration.tabs.labour"),
        key: "4",
        visible: false
      } as Tab,
      inspectionsTab: new PageTab({
        nameKey: "configuration.tabs.inspections",
        key: "5",
        visible: false
      }),

      // *** DETAILS ***
      siteProductivityOptions: Array.from(Array(11).keys()).map(k => {
        let value = k * 0.05 + 0.5;
        let percent = `${(value * 100).toFixed(0)}%`;
        return {
          text: percent,
          value: value
        };
      }),

      // *** DEFAULTS ***
      allContractors: [] as ContractorWithTags[],
      scaffoldContractors: [] as ContractorWithTags[],
      maintenanceContractors: [] as ContractorWithTags[],
      paintContractors: [] as ContractorWithTags[],
      insulationContractors: [] as ContractorWithTags[],
      allCoordinators: [] as PersonWithName[],
      allForemen: [] as PersonWithName[],
      allGeneralForemen: [] as PersonWithName[],
      inspectionTimes: [] as InspectionTime[]
    };
  },
  computed: {
    canAddInspectionTime(): boolean {
      return this.inspectionTimes.length < 4;
    },
    weekendingDayOptions(): any[] {
      return Array.from(Array(7).keys()).map(x => ({
        text: getNameOfDayFromNumber(x),
        value: x
      }));
    },
    tabDefinitions(): Tab[] {
      // Details is not included since it's the first tab and is always visible
      return [
        this.notificationsTab,
        this.workflowTab,
        this.defaultsTab,
        this.labourTab,
        this.inspectionsTab
      ] as Tab[];
    },
    configurationRules(): any {
      return {
        // SCAFFOLD ASSIGNMENTS
        defaultWorkOrderAssignedContractorID: this.environment.automaticallyApproveScaffoldRequests
          ? [this.rules.required]
          : [],
        defaultWorkOrderAssignedCoordinatorID: this.environment.automaticallyApproveScaffoldRequests
          ? [this.rules.required]
          : [],
        defaultWorkOrderAssignedGeneralForemanID: this.environment
          .automaticallyApproveScaffoldRequests
          ? [this.rules.required]
          : [],
        defaultWorkOrderAssignedForemanID: this.environment.automaticallyApproveScaffoldRequests
          ? [this.rules.required]
          : [],
        // MAINTENANCE ASSIGNMENTS
        defaultMaintenanceWorkOrderAssignedContractorID: this.environment
          .automaticallyApproveMaintenanceRequests
          ? [this.rules.required]
          : [],
        defaultMaintenanceWorkOrderAssignedCoordinatorID: this.environment
          .automaticallyApproveMaintenanceRequests
          ? [this.rules.required]
          : [],
        defaultMaintenanceWorkOrderAssignedGeneralForemanID: this.environment
          .automaticallyApproveMaintenanceRequests
          ? [this.rules.required]
          : [],
        defaultMaintenanceWorkOrderAssignedForemanID: this.environment
          .automaticallyApproveMaintenanceRequests
          ? [this.rules.required]
          : [],
        // PAINT ASSIGNMENTS
        defaultPaintWorkOrderAssignedContractorID: this.environment
          .automaticallyApprovePaintRequests
          ? [this.rules.required]
          : [],
        defaultPaintWorkOrderAssignedCoordinatorID: this.environment
          .automaticallyApprovePaintRequests
          ? [this.rules.required]
          : [],
        defaultPaintWorkOrderAssignedGeneralForemanID: this.environment
          .automaticallyApprovePaintRequests
          ? [this.rules.required]
          : [],
        defaultPaintWorkOrderAssignedForemanID: this.environment.automaticallyApprovePaintRequests
          ? [this.rules.required]
          : [],
        // INSULATION ASSIGNMENTS
        defaultInsulationWorkOrderAssignedContractorID: this.environment
          .automaticallyApproveInsulationRequests
          ? [this.rules.required]
          : [],
        defaultInsulationWorkOrderAssignedCoordinatorID: this.environment
          .automaticallyApproveInsulationRequests
          ? [this.rules.required]
          : [],
        defaultInsulationWorkOrderAssignedGeneralForemanID: this.environment
          .automaticallyApproveInsulationRequests
          ? [this.rules.required]
          : [],
        defaultInsulationWorkOrderAssignedForemanID: this.environment
          .automaticallyApproveInsulationRequests
          ? [this.rules.required]
          : [],
        // COUNT SHEETS
        defaultCountSheetFromYardID: this.environment.automaticallyApproveCountSheets
          ? [this.rules.required]
          : [],
        defaultCountSheetToYardID: []
      };
    },

    allYards(): Yard[] {
      return (this.$store.state.yards.fullList as Yard[]).filter(x => !x.isSystemYard);
    },

    allCostCodes(): ProjectCostCode[] {
      return this.$store.state.projectCostCodes.fullList as ProjectCostCode[];
    },

    allScaffoldTypeModifiers(): ScaffoldTypeModifier[] {
      return (this.$store.state.scaffoldTypeModifiers.fullList as ScaffoldTypeModifier[]).sort(
        (a, b) => (a.legacyID ?? 0) - (b.legacyID ?? 0)
      );
    },
    allScaffoldDistanceModifiers(): ScaffoldDistanceModifier[] {
      return (this.$store.state.scaffoldDistanceModifiers
        .fullList as ScaffoldDistanceModifier[]).sort(
        (a, b) => (a.legacyID ?? 0) - (b.legacyID ?? 0)
      );
    },
    allScaffoldElevationModifiers(): ScaffoldElevationModifier[] {
      return (this.$store.state.scaffoldElevationModifiers
        .fullList as ScaffoldElevationModifier[]).sort(
        (a, b) => (a.legacyID ?? 0) - (b.legacyID ?? 0)
      );
    },
    allScaffoldHeightModifiers(): ScaffoldHeightModifier[] {
      return (this.$store.state.scaffoldHeightModifiers.fullList as ScaffoldHeightModifier[]).sort(
        (a, b) => (a.legacyID ?? 0) - (b.legacyID ?? 0)
      );
    },
    allBuildDismantleRatios(): BuildDismantleRatio[] {
      return (this.$store.state.buildDismantleRatios.fullList as BuildDismantleRatio[]).sort(
        (a, b) => (a.legacyID ?? 0) - (b.legacyID ?? 0)
      );
    }
  },
  watch: {
    "environment.trackScaffoldVLF": function() {
      if (!this.environment.trackScaffoldVLF) {
        this.environment.walkdownVLFRequired = false;
        this.environment.workOrderActualVLFRequired = false;
      }
    },
    // SCAFFOLD ASSIGNMENTS
    "environment.automaticallyApproveScaffoldRequests": function() {
      // In case the flag is enabled with previous default data saved, confirm the contractor that was already selected still is selectable
      this.verifySelectedScaffoldAssignmentDefaults();
    },
    "environment.defaultWorkOrderAssignedContractorID": function() {
      this.verifySelectedScaffoldAssignmentDefaults();
    },
    // MAINTENANCE ASSIGNMENTS
    "environment.automaticallyApproveMaintenanceRequests": function() {
      // In case the flag is enabled with previous default data saved, confirm the contractor that was already selected still is selectable
      this.verifySelectedMaintenanceAssignmentDefaults();
    },
    "environment.defaultMaintenanceWorkOrderAssignedContractorID": function() {
      this.verifySelectedMaintenanceAssignmentDefaults();
    },
    // PAINT ASSIGNMENTS
    "environment.automaticallyApprovePaintRequests": function() {
      // In case the flag is enabled with previous default data saved, confirm the contractor that was already selected still is selectable
      this.verifySelectedPaintAssignmentDefaults();
    },
    "environment.defaultPaintWorkOrderAssignedContractorID": function() {
      this.verifySelectedPaintAssignmentDefaults();
    },
    // INSULATION ASSIGNMENTS
    "environment.automaticallyApproveInsulationRequests": function() {
      // In case the flag is enabled with previous default data saved, confirm the contractor that was already selected still is selectable
      this.verifySelectedInsulationAssignmentDefaults();
    },
    "environment.defaultInsulationWorkOrderAssignedContractorID": function() {
      this.verifySelectedInsulationAssignmentDefaults();
    },
    // COUNT SHEETS
    "environment.automaticallyApproveCountSheets": function() {
      // In case the flag is enabled with previous default data saved, confirm the contractor that was already selected still is selectable
      this.verifySelectedCountSheetDefault();
    }
  },
  methods: {
    selectableCoordinators(
      contractorID: string | undefined | null
    ): GroupableSelectListOption<PersonWithName>[] {
      var coordinatorsForSelectedContractor = !!contractorID
        ? this.coordinatorsInContractor(contractorID)
        : [];

      var allProxyCoordinators: GroupableSelectListOption<PersonWithName>[] = [];
      var proxyContractors = this.allContractors.filter(
        x => x.id != contractorID && x.canActAsProxy == true
      );
      if (proxyContractors.length > 0) {
        proxyContractors.forEach(x => {
          var proxyCoordinatorsForContractor = this.proxyCoordinatorsForContractor(
            contractorID!,
            x.id!
          );
          if (proxyCoordinatorsForContractor.length == 0) return;
          if (allProxyCoordinators.length > 0) {
            allProxyCoordinators.push({
              divider: true
            });
          }
          allProxyCoordinators.push({
            header: x.name ?? ""
          });
          allProxyCoordinators = allProxyCoordinators.concat(proxyCoordinatorsForContractor);
        });
        if (!!coordinatorsForSelectedContractor?.length && !!allProxyCoordinators?.length) {
          allProxyCoordinators.push({ divider: true });
          let selectedContractor = this.allContractors.find(x => x.id == contractorID);
          var header =
            selectedContractor?.name ??
            `${this.$t("configuration.defaults.selected-default-contractor")}`;
          allProxyCoordinators.push({
            header: header
          });
        }
      }

      var selectableCoordinators = allProxyCoordinators.concat(coordinatorsForSelectedContractor);

      return selectableCoordinators;
    },

    selectableGeneralForemen(
      contractorID: string | undefined | null
    ): SelectListOption<PersonWithName>[] {
      var generalForemenPeopleForContractor = SortItemsWithName(
        this.allGeneralForemen
          // TODO: Remove the restriction where this person has to be mapped to a legacy employee
          .filter(
            x =>
              !!x.legacyID &&
              ((this.curUserCanViewAllContractors && !x.contractorID) ||
                x.contractorID == contractorID)
          )
      );
      return generalForemenPeopleForContractor;
    },

    selectableForemen(contractorID: string | undefined | null): SelectListOption<PersonWithName>[] {
      var foremenPeopleForContractor = SortItemsWithName(
        this.allForemen
          // TODO: Remove the restriction where this person has to be mapped to a legacy employee
          .filter(
            x =>
              !!x.legacyID &&
              ((this.curUserCanViewAllContractors && !x.contractorID) ||
                x.contractorID == contractorID)
          )
      );
      return foremenPeopleForContractor;
    },
    selectableStyles(currentStyle: string): SelectListOption<any> {
      return this.styles.map(x => ({
        value: x,
        text: x,
        disabled: x != currentStyle && this.inspectionTimes.findIndex(i => i.style == x) !== -1
      }));
    },
    ...mapMutations({
      notifyNewBreadcrumb: "NOTIFY_NEW_BREADCRUMB",
      setFilteringContext: "SET_FILTERING_CONTEXT"
    }),
    ...mapActions({
      loadYards: "LOAD_YARDS",
      loadCostCodes: "LOAD_PROJECT_COST_CODES",
      loadScaffoldTypeModifiers: "LOAD_SCAFFOLD_TYPE_MODIFIERS",
      loadScaffoldDistanceModifiers: "LOAD_SCAFFOLD_DISTANCE_MODIFIERS",
      loadScaffoldElevationModifiers: "LOAD_SCAFFOLD_ELEVATION_MODIFIERS",
      loadScaffoldHeightModifiers: "LOAD_SCAFFOLD_HEIGHT_MODIFIERS",
      loadBuildDismantleRatios: "LOAD_BUILD_DISMANTLE_RATIOS"
    }),
    // *** GLOBAL ***
    onSubmit(e: Event) {
      e.preventDefault();
      this.save();
    },

    preventSubmit(e: Event) {
      e.preventDefault();
      return false;
    },

    validate(): boolean {
      this.detailsTabError = !((this.$refs.detailsform as HTMLFormElement)?.validate() ?? false);
      this.workflowTabError = !((this.$refs.workflowform as HTMLFormElement)?.validate() ?? true);
      return !(this.detailsTabError || this.workflowTabError);
    },

    async save() {
      this.inlineMessage.message = null;
      if (!this.validate()) {
        var message = this.$t("configuration.error-message");
        if (this.detailsTabError) message += "\n\t- " + this.detailsTab.tabname;
        if (this.workflowTabError) message += "\n\t- " + this.workflowTab.tabname;

        this.inlineMessage.message = message;
        this.inlineMessage.type = "error";

        return false;
      }

      this.processing = true;
      this.saving = true;
      try {
        if (!!this.environment.blendedLabourRate)
          this.environment.blendedLabourRate = +this.environment.blendedLabourRate;
        if (!!this.environment.factor1) this.environment.factor1 = +this.environment.factor1;
        if (!!this.environment.factor2) this.environment.factor2 = +this.environment.factor2;

        if (!!this.environment.defaultCrewSize)
          this.environment.defaultCrewSize = +this.environment.defaultCrewSize;
        if (!!this.environment.defaultFactor1)
          this.environment.defaultFactor1 = +this.environment.defaultFactor1;
        if (!!this.environment.defaultFactor2)
          this.environment.defaultFactor2 = +this.environment.defaultFactor2;

        if (!!this.environment.defaultEmployeeOvertimeHoursThreshold)
          this.environment.defaultEmployeeOvertimeHoursThreshold = +this.environment
            .defaultEmployeeOvertimeHoursThreshold;
        await environmentService.updateItemWithLegacySync(this.environment.id!, this.environment);
        this.$store.commit("SET_CUR_ENVIRONMENT", this.environment);

        await environmentService.saveEnvironmentDefaultInspectionTimes(this.inspectionTimes);

        var snackbarPayload = {
          text: this.$t("configuration.snack-bar-updated-message"),
          type: "success",
          undoCallback: null
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.saving = false;
        this.processing = false;
      }
    },

    // *** WORKFLOW ***
    // DOES NOT manage processing or error message logic
    async loadDefaultsReferenceData() {
      await Promise.all([
        this.loadContractors(),
        this.loadCoordinators(),
        this.loadGeneralForemen(),
        this.loadForemen(),
        this.loadYards(),
        this.loadCostCodes(),
        this.loadScaffoldTypeModifiers(),
        this.loadScaffoldHeightModifiers(),
        this.loadScaffoldElevationModifiers(),
        this.loadScaffoldDistanceModifiers(),
        this.loadBuildDismantleRatios()
      ]);
      this.inspectionTimes = (await environmentService.getEnvironmentDefaultInspectionTimes())
        .map(x => ({
          ...x,
          time: new Date(`${isoDateString(new Date())}T${isoTimeWithOffsetString(x.time)}`)
        }))
        .sort();
    },
    async loadContractors(): Promise<void> {
      let allContractors = await contractorService.getAll(false, null, null);

      this.allContractors = allContractors;
      this.scaffoldContractors = allContractors.filter(x => !!x.isScaffoldCompany);
      this.paintContractors = allContractors.filter(x => !!x.isPaintCompany);
      this.insulationContractors = allContractors.filter(x => !!x.isInsulationCompany);
      this.maintenanceContractors = allContractors.filter(x => !!x.isMaintenanceCompany);
    },

    // DOES NOT manage processing or error message logic
    async loadCoordinators(): Promise<void> {
      let coordinators = await personService.getAllCoordinators();
      this.allCoordinators = coordinators.map(x => {
        return {
          ...x,
          name: GetPersonName(x)
        };
      });
    },

    // DOES NOT manage processing or error message logic
    async loadGeneralForemen(): Promise<void> {
      let generalForemen = await personService.getAllGeneralForemen();
      this.allGeneralForemen = generalForemen.map(x => {
        return {
          ...x,
          name: GetPersonName(x)
        };
      });
    },

    // DOES NOT manage processing or error message logic
    async loadForemen(): Promise<void> {
      let foremen = await personService.getAllForemen();
      this.allForemen = foremen.map(x => {
        return {
          ...x,
          name: GetPersonName(x)
        };
      });
    },

    coordinatorsInContractor(contractorID: string) {
      var coordinatorsForContractor = SortItemsWithName(
        this.allCoordinators.filter(x => !!x.contractorID && x.contractorID == contractorID)
      );
      return coordinatorsForContractor;
    },

    proxyCoordinatorsForContractor(assignedContractorID: string, homeContractorID: string): any[] {
      // Since a proxy contractor is a proxy for all contractors, we don't need to restrict proxy contractors
      var proxyCoordinatorsInContractor = this.coordinatorsInContractor(homeContractorID);
      if (!proxyCoordinatorsInContractor?.length) return [];

      let proxyCoordinatorsForContractor = [] as any[];
      proxyCoordinatorsInContractor.forEach(coordinator => {
        if (!!coordinator.includesAllContractors && coordinator.includesAllContractors == true) {
          proxyCoordinatorsForContractor.push(coordinator);
          return;
        }
        if (!coordinator.contractorIDJson) return;

        let coordinatorVisibleContractorIDs = JSON.parse(coordinator.contractorIDJson) as string[];
        if (!coordinatorVisibleContractorIDs?.length) return;
        if (!coordinatorVisibleContractorIDs.includes(assignedContractorID)) return;

        proxyCoordinatorsForContractor.push(coordinator);
      });

      return proxyCoordinatorsForContractor;
    },

    verifySelectedScaffoldAssignmentDefaults() {
      let existingContractor = this.allContractors.find(
        x => x.id == this.environment.defaultWorkOrderAssignedContractorID
      );
      if (!existingContractor) this.environment.defaultWorkOrderAssignedContractorID = null;

      let existingCoordinator = this.selectableCoordinators(
        this.environment.defaultWorkOrderAssignedContractorID
      ).find(
        x => (x as PersonWithName)?.id == this.environment.defaultWorkOrderAssignedCoordinatorID
      );
      if (!existingCoordinator) this.environment.defaultWorkOrderAssignedCoordinatorID = null;

      let existingGeneralForeman = this.selectableGeneralForemen(
        this.environment.defaultWorkOrderAssignedContractorID
      ).find(
        x => (x as PersonWithName)?.id == this.environment.defaultWorkOrderAssignedGeneralForemanID
      );
      if (!existingGeneralForeman) this.environment.defaultWorkOrderAssignedGeneralForemanID = null;

      let existingForeman = this.selectableForemen(
        this.environment.defaultWorkOrderAssignedContractorID
      ).find(x => (x as PersonWithName)?.id == this.environment.defaultWorkOrderAssignedForemanID);
      if (!existingForeman) this.environment.defaultWorkOrderAssignedForemanID = null;
    },
    verifySelectedMaintenanceAssignmentDefaults() {
      let existingContractor = this.allContractors.find(
        x => x.id == this.environment.defaultMaintenanceWorkOrderAssignedContractorID
      );
      if (!existingContractor)
        this.environment.defaultMaintenanceWorkOrderAssignedContractorID = null;

      let existingCoordinator = this.selectableCoordinators(
        this.environment.defaultMaintenanceWorkOrderAssignedContractorID
      ).find(
        x =>
          (x as PersonWithName)?.id ==
          this.environment.defaultMaintenanceWorkOrderAssignedCoordinatorID
      );
      if (!existingCoordinator)
        this.environment.defaultMaintenanceWorkOrderAssignedCoordinatorID = null;

      let existingGeneralForeman = this.selectableGeneralForemen(
        this.environment.defaultMaintenanceWorkOrderAssignedContractorID
      ).find(
        x =>
          (x as PersonWithName)?.id ==
          this.environment.defaultMaintenanceWorkOrderAssignedGeneralForemanID
      );
      if (!existingGeneralForeman)
        this.environment.defaultMaintenanceWorkOrderAssignedGeneralForemanID = null;

      let existingForeman = this.selectableForemen(
        this.environment.defaultMaintenanceWorkOrderAssignedContractorID
      ).find(
        x =>
          (x as PersonWithName)?.id == this.environment.defaultMaintenanceWorkOrderAssignedForemanID
      );
      if (!existingForeman) this.environment.defaultMaintenanceWorkOrderAssignedForemanID = null;
    },
    verifySelectedPaintAssignmentDefaults() {
      let existingContractor = this.allContractors.find(
        x => x.id == this.environment.defaultPaintWorkOrderAssignedContractorID
      );
      if (!existingContractor) this.environment.defaultPaintWorkOrderAssignedContractorID = null;

      let existingCoordinator = this.selectableCoordinators(
        this.environment.defaultPaintWorkOrderAssignedContractorID
      ).find(
        x =>
          (x as PersonWithName)?.id == this.environment.defaultPaintWorkOrderAssignedCoordinatorID
      );
      if (!existingCoordinator) this.environment.defaultPaintWorkOrderAssignedCoordinatorID = null;

      let existingGeneralForeman = this.selectableGeneralForemen(
        this.environment.defaultPaintWorkOrderAssignedContractorID
      ).find(
        x =>
          (x as PersonWithName)?.id ==
          this.environment.defaultPaintWorkOrderAssignedGeneralForemanID
      );
      if (!existingGeneralForeman)
        this.environment.defaultPaintWorkOrderAssignedGeneralForemanID = null;

      let existingForeman = this.selectableForemen(
        this.environment.defaultPaintWorkOrderAssignedContractorID
      ).find(
        x => (x as PersonWithName)?.id == this.environment.defaultPaintWorkOrderAssignedForemanID
      );
      if (!existingForeman) this.environment.defaultPaintWorkOrderAssignedForemanID = null;
    },
    verifySelectedInsulationAssignmentDefaults() {
      let existingContractor = this.allContractors.find(
        x => x.id == this.environment.defaultInsulationWorkOrderAssignedContractorID
      );
      if (!existingContractor)
        this.environment.defaultInsulationWorkOrderAssignedContractorID = null;

      let existingCoordinator = this.selectableCoordinators(
        this.environment.defaultInsulationWorkOrderAssignedContractorID
      ).find(
        x =>
          (x as PersonWithName)?.id ==
          this.environment.defaultInsulationWorkOrderAssignedCoordinatorID
      );
      if (!existingCoordinator)
        this.environment.defaultInsulationWorkOrderAssignedCoordinatorID = null;

      let existingGeneralForeman = this.selectableGeneralForemen(
        this.environment.defaultInsulationWorkOrderAssignedContractorID
      ).find(
        x =>
          (x as PersonWithName)?.id ==
          this.environment.defaultInsulationWorkOrderAssignedGeneralForemanID
      );
      if (!existingGeneralForeman)
        this.environment.defaultInsulationWorkOrderAssignedGeneralForemanID = null;

      let existingForeman = this.selectableForemen(
        this.environment.defaultInsulationWorkOrderAssignedContractorID
      ).find(
        x =>
          (x as PersonWithName)?.id == this.environment.defaultInsulationWorkOrderAssignedForemanID
      );
      if (!existingForeman) this.environment.defaultInsulationWorkOrderAssignedForemanID = null;
    },
    verifySelectedCountSheetDefault() {
      let existingFromYard = this.allYards.find(
        x => x.id == this.environment.defaultCountSheetFromYardID
      );
      if (!existingFromYard) this.environment.defaultCountSheetFromYardID = null;
      let existingToYard = this.allYards.find(
        x => x.id == this.environment.defaultCountSheetToYardID
      );
      if (!existingToYard) this.environment.defaultCountSheetToYardID = null;
    },
    getTimeRangeSummaryForTime(item: InspectionTime) {
      var sortedTimes = this.inspectionTimes
        .slice()
        .sort((a, b) => a.time!.getTime() - b.time!.getTime());
      const startTime = stripDateFromLocalizedDateTime(item.time);

      // The end time of this range is the next start time.  If the item is the last start time, the index will be beyond the bounds of the array.  Use the first start time as its end time.
      let index = sortedTimes.indexOf(item) + 1;
      if (index >= sortedTimes.length) index = 0;
      const endTime = stripDateFromLocalizedDateTime(sortedTimes[index].time);
      return this.$t("configuration.inspections.inspection-time-range-summary", [
        startTime,
        endTime,
        Intl.DateTimeFormat().resolvedOptions().timeZone
      ]);
    },
    removeInspectionTime(item: InspectionTime) {
      const index = this.inspectionTimes.indexOf(item);
      if (index < 0) {
        return;
      }
      this.inspectionTimes.splice(index, 1);
    },
    async addInspectionTime() {
      let allowedStyles = this.selectableStyles("")
        .filter((x: SelectListOption<any>) => !x.disabled)
        .map((x: SelectListOption<any>) => x.value);
      let newTime = await openNewScaffoldInspectionTimeDialog(allowedStyles);
      if (!!newTime) {
        this.inspectionTimes.push(newTime);
      }
      // this.inspectionTimes.push({
      //   environmentID: this.environment.id,
      //   style:
      //     this.selectableStyles("").find((x: SelectListOption<any>) => !x.disabled)?.value ?? "",
      //   time: new Date(`${isoDateString(new Date())}T${isoTimeWithOffsetString(new Date())}`)
      // } as InspectionTime);
    }
  },

  mounted: function() {
    // The following is used to compensate for the apparent bug in vuetify that is preventing the underline from appearing
    // on the initial tab that is in focus within the view. This kicks it in the butt to cause it to render appropriately.
    // Without it the line under the initial tab that has focus will NOT show up until you resize the screen which then
    // causes it to re-render and show up.
    const initial = (this.$refs.tab as any).$el.offsetWidth;
    const interval = setInterval(() => {
      if (this.$refs.tab) {
        if ((this.$refs.tab as any).$el.offsetWidth !== initial) {
          clearInterval(interval);
          (this.$refs.tabs as any).callSlider();
        }
      }
    }, 100);
  },

  created: async function() {
    this.notifyNewBreadcrumb({
      text: this.$t("configuration.title"),
      to: "/configuration",
      resetHistory: true
    });

    // Set the context for the User Filtering in the store so that if the user navigates to a screen that is
    // a sub screen of something that is currently filtered by their choices that those choices will be
    // preserved as they move between the two screens.
    this.setFilteringContext({
      context: this.$t("configuration.title"),
      parentalContext: null,
      selectedTab: this.firstTabKey
    });

    this.processing = true;
    try {
      await this.loadDefaultsReferenceData();

      let environment = this.$store.state.curEnvironment;
      if (!!environment) {
        this.environment = {
          ...environment,
          created: undefined,
          createdBy: undefined,
          updated: undefined,
          updatedBy: undefined
        };
      }
    } catch (error) {
      this.handleError(error as Error);
    } finally {
      this.processing = false;
    }
  }
});

