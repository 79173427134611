import { VueConstructor } from "vue";

import {
  localizedDateTimeString,
  stripDateFromLocalizedDateTime,
  stripTimeFromLocalizedDateTime
} from "../../client-util/datetime";

export class FormatPluginController {
  public uppercase(value: any): string | null | undefined {
    switch (typeof value) {
      case "string":
        return value.toLocaleUpperCase();
      case "object":
        if (value != null) {
          return Object.toString().toLocaleUpperCase();
        } else {
          return null;
        }
      case "undefined":
        return undefined;
      default:
        return value.toString().toLocaleUpperCase();
    }
  }
  public date(value: Date | string | null | undefined, format?: string): string {
    return stripTimeFromLocalizedDateTime(value, format);
  }
  public time(value: Date | string | null | undefined, format?: string): string {
    return stripDateFromLocalizedDateTime(value, format);
  }
  public datetime(value: Date | string | null | undefined, format?: string): string {
    return localizedDateTimeString(value, format);
  }
}

export function FormatPlugin(Vue: VueConstructor<Vue>) {
  Vue.prototype.$format = new FormatPluginController();
}

