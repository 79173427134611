import FDVue from "@fd/lib/vue";
import dialogSupport, { createDialog } from "@fd/lib/vue/mixins/dialogSupport";
import { mapActions } from "vuex";
import {
  walkdownReferenceDataService,
  WorkOrderEstimate,
  BuildDismantleRatio,
  HoardingModifier,
  InternalModifier,
  ScaffoldCongestionFactor,
  ScaffoldDistanceModifier,
  ScaffoldElevationModifier,
  ScaffoldHeightModifier,
  ScaffoldTypeModifier,
  workOrderEstimateService,
  WorkOrderWithLegacyDetails
} from "../../../services";

const WorkOrderEstimateLWHNewDialog = FDVue.extend({
  name: "sp-work-order-estimate-new-dialog",

  components: {
    "sp-lwh-estimate-form": () => import("../forms/SP.EstimateLWHForm.vue"),
    "sp-estimate-modifiers-form": () => import("../forms/SP.EstimateModifiersForm.vue")
  },
  mixins: [dialogSupport],
  data: function() {
    return {
      saving: false,
      // Variable to hold which expansion panel is currently open
      step: 1,
      lastStep: 2,
      estimateStep: {
        name: this.$t("work-order-estimates.new-estimate.steps.estimate"),
        number: 1,
        error: false
      },
      modifiersStep: {
        name: this.$t("work-order-estimates.new-estimate.steps.modifiers"),
        number: 2,
        error: false
      },

      workOrder: {} as WorkOrderWithLegacyDetails,
      estimate: {} as WorkOrderEstimate,

      allScaffoldDistances: [] as ScaffoldDistanceModifier[],
      allScaffoldElevations: [] as ScaffoldElevationModifier[],
      allScaffoldHeights: [] as ScaffoldHeightModifier[],
      allBuildDismantleRatios: [] as BuildDismantleRatio[],
      allScaffoldCongestionFactors: [] as ScaffoldCongestionFactor[],
      allInternalModifiers: [] as InternalModifier[],
      allHoardingModifiers: [] as HoardingModifier[]
    };
  },
  computed: {
    allScaffoldTypes(): ScaffoldTypeModifier[] {
      return (this.$store.state.scaffoldTypeModifiers.fullList as ScaffoldTypeModifier[]).sort(
        (a, b) => (a.legacyID ?? 0) - (b.legacyID ?? 0)
      );
    },
    // Data is stored in the SP1 db with a value called "ScaffoldType"
    // Possible values are:
    // - 0 = regular (volume) scaffold
    // - 1 = hard barricade
    scaffoldIsHardBarricade: {
      get(): boolean {
        return this.estimate.isHardBarricade ?? false;
      },
      set(val: boolean) {
        // Set scaffold type to hard barricade
        this.estimate.isHardBarricade = val;
      }
    }
  },
  methods: {
    ...mapActions({
      loadScaffoldTypeModifiers: "LOAD_SCAFFOLD_TYPE_MODIFIERS"
    }),
    async loadData() {
      await Promise.all([
        this.loadScaffoldTypeModifiers(),
        this.loadScaffoldHeights(),
        this.loadScaffoldElevations(),
        this.loadScaffoldDistances(),
        this.loadScaffoldCongestionFactors(),
        this.loadBuildDismantleRatios(),
        this.loadInternalModifiers(),
        this.loadHoardingModifiers()
      ]);
    },
    async open(workOrder: WorkOrderWithLegacyDetails, estimate: WorkOrderEstimate | undefined) {
      this.workOrder = workOrder;
      this.estimate = {
        ...estimate,
        isHardBarricade: estimate?.isHardBarricade ?? false,
        walkdownID: null
      } as WorkOrderEstimate;

      this.optOutOfErrorHandling();

      this.loadData();

      return await this.showDialog!();
    },
    sanitizeNumber(value: number | string | undefined | null): number | null {
      if (!value) return null;

      let number = Number(value);
      if (isNaN(number)) return null;
      return number;
    },

    cancelDialog() {
      this.closeDialog(false);
    },
    async saveDialog() {
      if (!(this.$refs.lwhform as any).validate()) {
        this.inlineMessage.message = this.$t("work-order-estimates.error-message");
        return;
      }

      this.processing = true;
      this.saving = true;
      try {
        var estimateToSave = {
          ...this.estimate,
          length: this.sanitizeNumber(this.estimate.length),
          width: this.sanitizeNumber(this.estimate.width),
          height: this.sanitizeNumber(this.estimate.height),
          deckLevels: this.sanitizeNumber(this.estimate.deckLevels),
          barricadeGates: this.sanitizeNumber(this.estimate.barricadeGates),
          crewSize: this.sanitizeNumber(this.estimate.crewSize)
        };
        if (this.workOrder.isModifyRequest) {
          await workOrderEstimateService.generateNewModifyEstimateForWorkOrderWithID(
            this.workOrder.id!,
            estimateToSave,
            null
          );
        } else {
          await workOrderEstimateService.generateNewLWHEstimateForWorkOrderWithID(
            this.workOrder.id!,
            estimateToSave,
            null
          );
        }
        this.closeDialog(true);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.saving = false;
      }
    },

    // DOES NOT manage processing or error message logic
    async loadScaffoldDistances(): Promise<void> {
      this.allScaffoldDistances = (
        await walkdownReferenceDataService.getAllScaffoldDistanceModifiers()
      ).sort((a, b) => (a.legacyID ?? 0) - (b.legacyID ?? 0));
    },

    // DOES NOT manage processing or error message logic
    async loadScaffoldHeights(): Promise<void> {
      this.allScaffoldHeights = (
        await walkdownReferenceDataService.getAllScaffoldHeightModifiers()
      ).sort((a, b) => (a.legacyID ?? 0) - (b.legacyID ?? 0));
    },

    // DOES NOT manage processing or error message logic
    async loadScaffoldElevations(): Promise<void> {
      this.allScaffoldElevations = (
        await walkdownReferenceDataService.getAllScaffoldElevationModifiers()
      ).sort((a, b) => (a.legacyID ?? 0) - (b.legacyID ?? 0));
    },

    // DOES NOT manage processing or error message logic
    async loadScaffoldCongestionFactors(): Promise<void> {
      this.allScaffoldCongestionFactors = (
        await walkdownReferenceDataService.getAllScaffoldCongestionFactors()
      ).sort((a, b) => (a.legacyID ?? 0) - (b.legacyID ?? 0));
    },

    // DOES NOT manage processing or error message logic
    async loadBuildDismantleRatios(): Promise<void> {
      this.allBuildDismantleRatios = (
        await walkdownReferenceDataService.getAllBuildDismantleRatios()
      ).sort((a, b) => (a.legacyID ?? 0) - (b.legacyID ?? 0));
    },

    // DOES NOT manage processing or error message logic
    async loadInternalModifiers(): Promise<void> {
      this.allInternalModifiers = (
        await walkdownReferenceDataService.getAllInternalModifiers()
      ).sort((a, b) => (a.legacyID ?? 0) - (b.legacyID ?? 0));
    },

    // DOES NOT manage processing or error message logic
    async loadHoardingModifiers(): Promise<void> {
      this.allHoardingModifiers = (
        await walkdownReferenceDataService.getAllHoardingModifiers()
      ).sort((a, b) => (a.legacyID ?? 0) - (b.legacyID ?? 0));
    }
  }
});

export default WorkOrderEstimateLWHNewDialog;

export async function createWorkOrderEstimateLWHNewDialog(
  workOrder: WorkOrderWithLegacyDetails,
  lastLWHEstimateDetails: WorkOrderEstimate | undefined
): Promise<boolean> {
  console.log(
    `createWorkOrderEstimateLWHNewDialog scaffoldTypeModifierID: ${lastLWHEstimateDetails?.scaffoldTypeModifierID}`
  );
  let dialog = createDialog(WorkOrderEstimateLWHNewDialog);
  dialog.optOutOfErrorHandling();
  return await dialog.open(workOrder, lastLWHEstimateDetails);
}

