var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      attrs: { width: "960px", persistent: "" },
      model: {
        value: _vm.twoWayProps.show,
        callback: function($$v) {
          _vm.$set(_vm.twoWayProps, "show", $$v)
        },
        expression: "twoWayProps.show"
      }
    },
    [
      _c(
        "v-card",
        { attrs: { "data-cy": "crewdetails" } },
        [
          _c(
            "v-card-title",
            {
              class: [
                _vm.processing
                  ? "dialog-new-top-color-processing"
                  : "dialog-new-top-color"
              ]
            },
            [
              _c(
                "span",
                {
                  class: [_vm.processing ? "breadcrumb-processing-opacity" : ""]
                },
                [
                  _vm._v(
                    _vm._s(
                      !_vm.crew.id
                        ? _vm.$t("contractors.crews.new-crew.new-dialog-title")
                        : _vm.$t(
                            "contractors.crews.new-crew.edit-dialog-title",
                            [_vm.crew.name]
                          )
                    )
                  )
                ]
              ),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _vm.processing
                ? _c("v-progress-circular", {
                    attrs: {
                      indeterminate: true,
                      rotate: 0,
                      size: 32,
                      width: 4,
                      color: "white"
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-stepper",
            {
              attrs: { "non-linear": "" },
              model: {
                value: _vm.step,
                callback: function($$v) {
                  _vm.step = $$v
                },
                expression: "step"
              }
            },
            [
              _c(
                "v-stepper-header",
                { staticClass: "fd-responsive-stepper" },
                [
                  _c(
                    "v-stepper-step",
                    {
                      attrs: {
                        "data-cy": "details",
                        complete: _vm.step > _vm.detailsStep,
                        step: _vm.detailsStep,
                        editable: ""
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.$t("contractors.crews.new-crew.steps.details")
                          ) +
                          "\n          "
                      ),
                      _vm.detailserror
                        ? _c("small", { staticClass: "mt-1" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "contractors.crews.new-crew.new-crew-details-step-error-message"
                                )
                              )
                            )
                          ])
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  _c("v-divider"),
                  _vm._v(" "),
                  _c(
                    "v-stepper-step",
                    {
                      attrs: {
                        complete: _vm.step > _vm.employeesStep,
                        step: _vm.employeesStep,
                        editable: ""
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.$t("contractors.crews.new-crew.steps.employees")
                          ) +
                          "\n          "
                      ),
                      _vm.employeeserror
                        ? _c("small", { staticClass: "mt-1" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "contractors.crews.new-crew.new-crew-employees-step-error-message"
                                )
                              )
                            )
                          ])
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  _c("v-divider"),
                  _vm._v(" "),
                  _c(
                    "v-stepper-step",
                    {
                      attrs: {
                        complete: _vm.step > _vm.employeeOrderingStep,
                        step: _vm.employeeOrderingStep,
                        editable: ""
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.$t(
                              "contractors.crews.new-crew.steps.employee-ordering"
                            )
                          ) +
                          "\n          "
                      ),
                      _vm.employeeordererror
                        ? _c("small", { staticClass: "mt-1" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "contractors.crews.new-crew.steps.employee-ordering-error-message"
                                )
                              )
                            )
                          ])
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  _c("v-progress-linear", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.processing || _vm.saving,
                        expression: "processing || saving"
                      }
                    ],
                    attrs: { indeterminate: _vm.processing || _vm.saving }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-stepper-items",
                [
                  _c(
                    "v-stepper-content",
                    {
                      staticClass: "pl-0 pr-0 pb-0",
                      staticStyle: { "padding-top": "2px" },
                      attrs: { step: "1" }
                    },
                    [
                      _c(
                        "v-form",
                        {
                          ref: "detailsform",
                          on: { submit: _vm.preventSubmit }
                        },
                        [
                          _c(
                            "v-card",
                            [
                              _c(
                                "v-row",
                                { staticClass: "pl-3 pr-3 pt-3" },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pb-1 pb-md-0 pt-5",
                                      attrs: { cols: "12", sm: "12", md: "6" }
                                    },
                                    [
                                      _c("fd-text-field", {
                                        attrs: {
                                          label: _vm.$t("common.name"),
                                          disabled: _vm.processing,
                                          rules: [_vm.rules.required]
                                        },
                                        model: {
                                          value: _vm.crew.name,
                                          callback: function($$v) {
                                            _vm.$set(_vm.crew, "name", $$v)
                                          },
                                          expression: "crew.name"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pb-1 pb-md-0 pt-5",
                                      attrs: { cols: "12", sm: "6", md: "6" }
                                    },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          outlined: "",
                                          "data-cy": "ownerselect",
                                          label: _vm.$t(
                                            "contractors.crews.owner"
                                          ),
                                          items: _vm.visiblePeople,
                                          "item-text": "name",
                                          "item-value": "id",
                                          disabled:
                                            _vm.processing ||
                                            !_vm.canChangeOwner,
                                          clearable: ""
                                        },
                                        model: {
                                          value: _vm.crew.ownerID,
                                          callback: function($$v) {
                                            _vm.$set(_vm.crew, "ownerID", $$v)
                                          },
                                          expression: "crew.ownerID"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c("fd-rich-textarea", {
                                        attrs: {
                                          disabled: _vm.processing,
                                          label: _vm.$t("common.description"),
                                          allowImages: false
                                        },
                                        model: {
                                          value: _vm.crew.description,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.crew,
                                              "description",
                                              $$v
                                            )
                                          },
                                          expression: "crew.description"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-stepper-content",
                    {
                      staticClass: "pl-0 pr-0 pb-0",
                      staticStyle: { "padding-top": "2px" },
                      attrs: { step: "2" }
                    },
                    [
                      _c(
                        "v-form",
                        {
                          ref: "employeesform",
                          on: { submit: _vm.preventSubmit }
                        },
                        [
                          _c(
                            "v-card",
                            { attrs: { flat: "", tile: "" } },
                            [
                              _c(
                                "v-col",
                                { staticClass: "pa-0", attrs: { cols: "12" } },
                                [
                                  _c(
                                    "v-row",
                                    { staticClass: "ml-0 mr-0" },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12", sm: "12" } },
                                        [
                                          _c(
                                            "v-card-title",
                                            {
                                              staticClass: "pl-3 pr-0 pt-0 pb-0"
                                            },
                                            [
                                              _c("v-switch", {
                                                attrs: {
                                                  label: _vm.$t(
                                                    "contractors.crews.employees.only-included-employees-label"
                                                  )
                                                },
                                                model: {
                                                  value:
                                                    _vm.showOnlyIncludedEmployees,
                                                  callback: function($$v) {
                                                    _vm.showOnlyIncludedEmployees = $$v
                                                  },
                                                  expression:
                                                    "showOnlyIncludedEmployees"
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "v-tooltip",
                                                {
                                                  attrs: { top: "" },
                                                  scopedSlots: _vm._u([
                                                    {
                                                      key: "activator",
                                                      fn: function({ on }) {
                                                        return [
                                                          _c(
                                                            "v-icon",
                                                            _vm._g(
                                                              {
                                                                staticClass:
                                                                  "ml-4",
                                                                attrs: {
                                                                  color:
                                                                    "black",
                                                                  dark: ""
                                                                }
                                                              },
                                                              on
                                                            ),
                                                            [_vm._v("info")]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ])
                                                },
                                                [
                                                  _vm._v(" "),
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "contractors.crews.employees.employees-selection-sub-menu-information"
                                                        )
                                                      )
                                                    )
                                                  ])
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-row",
                                    { staticClass: "mr-0 ml-0" },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              "append-icon": "search",
                                              label: _vm.$t("common.search"),
                                              "single-line": "",
                                              "hide-details": "",
                                              clearable: ""
                                            },
                                            model: {
                                              value: _vm.tablesearchemployees,
                                              callback: function($$v) {
                                                _vm.tablesearchemployees = $$v
                                              },
                                              expression: "tablesearchemployees"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("v-data-table", {
                                directives: [
                                  {
                                    name: "fd-column",
                                    rawName: "v-fd-column:name.hidden",
                                    arg: "name",
                                    modifiers: { hidden: true }
                                  },
                                  {
                                    name: "fd-column",
                                    rawName: "v-fd-column:order",
                                    arg: "order"
                                  },
                                  {
                                    name: "fd-column",
                                    rawName: "v-fd-column:firstName",
                                    value: _vm.$t("common.firstname"),
                                    expression: "$t('common.firstname')",
                                    arg: "firstName"
                                  },
                                  {
                                    name: "fd-column",
                                    rawName: "v-fd-column:lastName",
                                    value: _vm.$t("common.lastname"),
                                    expression: "$t('common.lastname')",
                                    arg: "lastName"
                                  },
                                  {
                                    name: "fd-column",
                                    rawName:
                                      "v-fd-column:employeeCode.hide-when-small",
                                    value: _vm.$t("users.employee-code"),
                                    expression: "$t('users.employee-code')",
                                    arg: "employeeCode",
                                    modifiers: { "hide-when-small": true }
                                  },
                                  {
                                    name: "fd-column",
                                    rawName: "v-fd-column:include.no-sort",
                                    value: _vm.$t("common.include"),
                                    expression: "$t('common.include')",
                                    arg: "include",
                                    modifiers: { "no-sort": true }
                                  }
                                ],
                                ref: "employeesDataTable",
                                staticClass: "fixed-header v-table__overflow",
                                staticStyle: {
                                  "max-height": "calc(50vh - 100px)",
                                  "backface-visibility": "hidden"
                                },
                                attrs: {
                                  "custom-filter": _vm.splitFilter,
                                  items: _vm.employees,
                                  search: _vm.tablesearchemployees,
                                  loading: _vm.processing,
                                  "loading-text": _vm.$t(
                                    "common.table-loading-message"
                                  ),
                                  "sort-by": ["lastName"],
                                  "mobile-breakpoint": "0"
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "item.order",
                                    fn: function({ item }) {
                                      return [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(_vm.orderForItem(item)) +
                                            "\n                "
                                        )
                                      ]
                                    }
                                  },
                                  {
                                    key: "header.include",
                                    fn: function({ header }) {
                                      return [
                                        _c("v-checkbox", {
                                          attrs: {
                                            value:
                                              _vm.allSearchedEmployeesSelected,
                                            indeterminate:
                                              _vm.someSearchedEmployeesSelected,
                                            label: _vm.$t(
                                              "common." + header.value
                                            )
                                          },
                                          on: {
                                            change: function($event) {
                                              return _vm.flipSearchedEmployeeselected()
                                            }
                                          }
                                        })
                                      ]
                                    }
                                  },
                                  {
                                    key: "item.include",
                                    fn: function({ item }) {
                                      return [
                                        _c("v-simple-checkbox", {
                                          attrs: {
                                            value: _vm.itemIsSelected(item),
                                            disabled: _vm.processing
                                          },
                                          on: {
                                            input: function($event) {
                                              return _vm.flipEmployeeSelected(
                                                item
                                              )
                                            }
                                          }
                                        })
                                      ]
                                    }
                                  }
                                ])
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-stepper-content",
                    {
                      staticClass: "pl-0 pr-0 pb-0",
                      staticStyle: { "padding-top": "2px" },
                      attrs: { step: _vm.employeeOrderingStep }
                    },
                    [
                      _c("v-data-table", {
                        directives: [
                          {
                            name: "fd-table-sortable",
                            rawName: "v-fd-table-sortable"
                          },
                          {
                            name: "fd-column",
                            rawName: "v-fd-column:name",
                            arg: "name"
                          },
                          {
                            name: "fd-column",
                            rawName: "v-fd-column:employeeCode",
                            value: _vm.$t("users.employee-code"),
                            expression: "$t('users.employee-code')",
                            arg: "employeeCode"
                          },
                          {
                            name: "fd-column",
                            rawName: "v-fd-column:classificationName",
                            value: _vm.$t("timesheets.classification-label"),
                            expression: "$t('timesheets.classification-label')",
                            arg: "classificationName"
                          }
                        ],
                        attrs: {
                          items: _vm.selectedEmployees,
                          loading: _vm.processing,
                          "loading-text": _vm.$t(
                            "common.table-loading-message"
                          ),
                          "mobile-breakpoint": "0",
                          "disable-sort": "",
                          "disable-pagination": true,
                          "hide-default-footer": true
                        },
                        on: { "sort:end": _vm.dragEnded },
                        scopedSlots: _vm._u([
                          {
                            key: "item.fd-drag",
                            fn: function() {
                              return [
                                _c("v-icon", { attrs: { small: "" } }, [
                                  _vm._v("far fa-grip-lines")
                                ])
                              ]
                            },
                            proxy: true
                          }
                        ])
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-form",
                { ref: "form", on: { submit: _vm.onSubmit } },
                [
                  _c(
                    "v-row",
                    { staticClass: "mx-2", attrs: { align: "center" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _vm.inlineMessage.message
                            ? _c(
                                "v-alert",
                                { attrs: { type: _vm.inlineMessage.type } },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: { "white-space": "pre-line" }
                                    },
                                    [_vm._v(_vm._s(_vm.inlineMessage.message))]
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    {
                      staticClass:
                        "mx-2 mb-3 d-flex d-sm-none justify-end align-center"
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            "data-cy": "xs-save",
                            outlined: _vm.step != _vm.lastStep,
                            color: "primary",
                            loading: _vm.processing,
                            disabled: _vm.processing,
                            small: _vm.$vuetify.breakpoint.xsOnly
                          },
                          on: { click: _vm.saveDialog }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("common.save-and-close")) +
                              "\n          "
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          staticClass: "d-none d-sm-flex",
                          attrs: {
                            "data-cy": "save",
                            outlined: _vm.step != _vm.lastStep,
                            color: "primary",
                            loading: _vm.processing,
                            disabled: _vm.processing,
                            small: _vm.$vuetify.breakpoint.xsOnly
                          },
                          on: { click: _vm.saveDialog }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("common.save-and-close")) +
                              "\n          "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "ml-4 mr-3 fd-button-context-separator-hide-when-mobile"
                        },
                        [_vm._v("|")]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            text: "",
                            "data-cy": "cancel",
                            disabled: _vm.saving,
                            small: _vm.$vuetify.breakpoint.xsOnly
                          },
                          on: { click: _vm.cancelDialog }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("common.cancel")) +
                              "\n          "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            "data-cy": "continue",
                            disabled:
                              _vm.step == _vm.lastStep ||
                              _vm.processing ||
                              _vm.saving,
                            color: "primary",
                            small: _vm.$vuetify.breakpoint.xsOnly
                          },
                          on: {
                            click: function($event) {
                              _vm.step = Number(_vm.step) + 1
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("common.continue")) +
                              "\n          "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }