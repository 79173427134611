var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-form",
        { ref: "form", attrs: { "data-cy": "foremantimesheetexisting" } },
        [
          _c(
            "v-slide-x-reverse-transition",
            [
              _c(
                "v-card",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.slidein,
                      expression: "slidein"
                    }
                  ]
                },
                [
                  _c(
                    "v-toolbar",
                    {
                      class: [
                        _vm.processing
                          ? "view-card-top-color-processing"
                          : "view-card-top-color"
                      ]
                    },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c(
                                "v-row",
                                { attrs: { align: "center" } },
                                [
                                  _c("v-breadcrumbs", {
                                    staticClass: "d-none d-sm-flex",
                                    class: [
                                      _vm.processing
                                        ? "breadcrumb-processing-opacity"
                                        : "",
                                      "pl-3"
                                    ],
                                    attrs: {
                                      items:
                                        _vm.$store.state.currentBreadcrumbs,
                                      large: ""
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "divider",
                                        fn: function() {
                                          return [
                                            _c("v-icon", [
                                              _vm._v("fa-chevron-right")
                                            ])
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ])
                                  }),
                                  _vm._v(" "),
                                  _c("fd-back-button", {
                                    attrs: {
                                      route: item =>
                                        _vm.$router.push(
                                          _vm.$store.getters.backBreadcrumb
                                            .to || `/foremantimesheets`
                                        )
                                    }
                                  }),
                                  _vm._v(" "),
                                  !!_vm.timesheet.id &&
                                  _vm.$vuetify.breakpoint.xsOnly
                                    ? _c(
                                        "div",
                                        {
                                          class: [
                                            {
                                              "ml-2":
                                                _vm.$vuetify.breakpoint.xsOnly
                                            },
                                            "py-1",
                                            "px-3",
                                            "fd-status-indicator"
                                          ]
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(_vm.formattedDay) +
                                              "\n                "
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  !!_vm.timesheet.id
                                    ? _c(
                                        "div",
                                        {
                                          class: [
                                            "py-1",
                                            "px-3",
                                            "fd-status-indicator",
                                            {
                                              "ml-1":
                                                _vm.$vuetify.breakpoint.xsOnly
                                            }
                                          ]
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.$t(
                                                  `timesheets.status.${_vm.timesheet.timesheetStatusID}`
                                                )
                                              ) +
                                              "\n                "
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("v-spacer"),
                                  _vm._v(" "),
                                  _vm.processing
                                    ? _c("v-progress-circular", {
                                        staticClass: "mr-3",
                                        attrs: {
                                          indeterminate: true,
                                          rotate: 0,
                                          size: 32,
                                          width: 4,
                                          color: "white"
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.timesheetIsCancelled
                    ? _c("fd-alert", {
                        staticClass: "mx-3 mt-5",
                        attrs: {
                          type: "error",
                          label: _vm.$t(
                            "timesheets.existing.cancellation-reason-extended"
                          ),
                          comments: _vm.timesheet.reviewCancelReason,
                          "persistent-comments": ""
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("fd-foreman-timesheet-form", {
                    ref: "timesheetform",
                    attrs: {
                      "parent-context": "timesheets-existing",
                      "employee-time-summaries": _vm.timeSummaries,
                      timesheet: _vm.timesheet,
                      "read-only": _vm.isReadonly,
                      "make-corrections": _vm.makeCorrections
                    },
                    on: {
                      "rows-added": _vm.rowsAdded,
                      "click:add-crew": _vm.addCrew,
                      "click:edit-crew": _vm.editCrew,
                      "click:delete-crew": _vm.deleteCrew
                    }
                  }),
                  _vm._v(" "),
                  _vm.inlineMessage.message
                    ? _c(
                        "v-row",
                        { staticClass: "mx-0", attrs: { align: "center" } },
                        [
                          _c(
                            "v-col",
                            { staticClass: "mx-0", attrs: { cols: "12" } },
                            [
                              _vm.inlineMessage.message
                                ? _c("fd-alert", {
                                    attrs: {
                                      type: _vm.inlineMessage.type,
                                      label: _vm.inlineMessage.message,
                                      "label-style": "white-space: pre-line;"
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isReadonly && !_vm.makeCorrections
                    ? _c(
                        "v-row",
                        { staticClass: "mx-0", attrs: { align: "center" } },
                        [
                          _c(
                            "v-col",
                            { staticClass: "mx-0", attrs: { cols: "12" } },
                            [
                              _vm.timesheetIsSubmitted
                                ? _c("fd-alert", {
                                    attrs: {
                                      type: "info",
                                      label: _vm.$t(
                                        "timesheets.existing.cannot-be-edited-submitted"
                                      )
                                    }
                                  })
                                : _vm.timesheetIsApproved
                                ? _c("fd-alert", {
                                    attrs: {
                                      type: "info",
                                      label: _vm.$t(
                                        "timesheets.existing.cannot-be-edited-approved"
                                      )
                                    }
                                  })
                                : _vm.timesheetIsCancelled
                                ? _c("fd-alert", {
                                    attrs: {
                                      type: "info",
                                      label: _vm.$t(
                                        "timesheets.existing.cannot-be-edited-cancelled"
                                      )
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    {
                      staticClass:
                        "mx-1 mb-3 d-flex d-md-none justify-end align-center v-card__actions"
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            "data-cy": "history-small",
                            outlined: "",
                            disabled: _vm.processing,
                            small: _vm.$vuetify.breakpoint.xsOnly
                          },
                          on: { click: _vm.showStatusLogDialog }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("common.history")) +
                              "\n          "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            "data-cy": "submit-small",
                            outlined: "",
                            color: "primary",
                            loading: _vm.submitting,
                            disabled: _vm.isReadonly || _vm.processing,
                            small: _vm.$vuetify.breakpoint.xsOnly
                          },
                          on: { click: _vm.submitTimesheet }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("common.submit")) +
                              "\n          "
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    { staticClass: "mx-1" },
                    [
                      _vm.timesheet.isLocked &&
                      _vm.canMakeCorrections &&
                      !_vm.makeCorrections
                        ? _c(
                            "v-btn",
                            {
                              attrs: {
                                link: "",
                                disabled: _vm.processing,
                                color: "primary",
                                outlined: "",
                                small: _vm.$vuetify.breakpoint.xsOnly
                              },
                              on: {
                                click: function($event) {
                                  _vm.makeCorrections = true
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.$t(
                                      "timesheets.existing.make-corrections-label"
                                    )
                                  ) +
                                  "\n          "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      false
                        ? _c(
                            "v-btn",
                            {
                              attrs: {
                                link: "",
                                disabled:
                                  _vm.timesheet.isLocked || _vm.processing,
                                color: "error",
                                outlined: "",
                                small: _vm.$vuetify.breakpoint.name == "xs"
                              },
                              on: { click: _vm.deleteTimesheet }
                            },
                            [_vm._v(_vm._s(_vm.$t("common.delete")))]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.loadAsCorrection
                        ? _c(
                            "v-btn",
                            {
                              attrs: {
                                link: "",
                                disabled:
                                  _vm.timesheet.isLocked || _vm.processing,
                                color: "error",
                                outlined: "",
                                small: _vm.$vuetify.breakpoint.name == "xs"
                              },
                              on: { click: _vm.editTimesheetDay }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("timesheets.existing.edit-date-label")
                                )
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("v-spacer"),
                      _vm._v(" "),
                      !_vm.loadAsCorrection
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "d-none d-md-flex",
                              attrs: {
                                text: "",
                                link: "",
                                "data-cy": "cancel",
                                loading: _vm.cancelling,
                                disabled:
                                  _vm.isReadonly ||
                                  !_vm.timesheetCanBeCancelled ||
                                  _vm.processing,
                                small: _vm.$vuetify.breakpoint.xsOnly
                              },
                              on: { click: _vm.cancelTimesheet }
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.$t(
                                      "timesheets.existing.actions-cancel-timesheet"
                                    )
                                  ) +
                                  "\n          "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          staticClass: "d-none d-md-flex",
                          attrs: {
                            "data-cy": "history",
                            outlined: "",
                            disabled: _vm.processing,
                            small: _vm.$vuetify.breakpoint.xsOnly
                          },
                          on: { click: _vm.showStatusLogDialog }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("common.history")) +
                              "\n          "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      !_vm.loadAsCorrection
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "d-none d-md-flex",
                              attrs: {
                                "data-cy": "submit",
                                outlined: "",
                                color: "primary",
                                loading: _vm.submitting,
                                disabled:
                                  _vm.processing ||
                                  !_vm.timesheetCanBeSubmitted,
                                small: _vm.$vuetify.breakpoint.xsOnly
                              },
                              on: { click: _vm.submitTimesheet }
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.$t("common.save-and-submit")) +
                                  "\n          "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "ml-4 mr-3 d-none d-md-flex fd-button-context-separator-hide-when-mobile"
                        },
                        [_vm._v("\n            |\n          ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-2",
                          attrs: {
                            text: "",
                            small: _vm.$vuetify.breakpoint.xsOnly
                          },
                          on: { click: _vm.cancel }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("common.cancel-edits")) +
                              "\n          "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c("fd-menu-save", {
                        attrs: {
                          disabled:
                            _vm.processing ||
                            (_vm.isReadonly && !_vm.makeCorrections) ||
                            !_vm.timesheet.currentUserPermissions
                              .canEditExistingEntries,
                          loading: _vm.processing,
                          small: _vm.$vuetify.breakpoint.xsOnly
                        },
                        on: {
                          "click:save": function($event) {
                            return _vm.save(false)
                          },
                          "click:save-and-close": function($event) {
                            return _vm.save(true)
                          }
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }