var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { fluid: "", "data-cy": "configuration" } },
    [
      !_vm.currentUserCanConfigureSettings
        ? _c("v-alert", { staticClass: "mt-0 mb-3", attrs: { type: "info" } }, [
            _vm._v(
              "\n    " + _vm._s(_vm.$t("configuration.no-permission")) + "\n  "
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-card",
        { staticClass: "fd-view no-tag-searching" },
        [
          _c(
            "fd-toolbar",
            { attrs: { extended: "", loading: _vm.processing } },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "v-row",
                        { attrs: { align: "center", "no-gutters": "" } },
                        [
                          _c("v-breadcrumbs", {
                            class: [
                              _vm.processing
                                ? "breadcrumb-processing-opacity"
                                : "",
                              "pl-0"
                            ],
                            attrs: {
                              items: _vm.$store.state.currentBreadcrumbs,
                              large: ""
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "divider",
                                fn: function() {
                                  return [
                                    _c("v-icon", [_vm._v("fa-chevron-right")])
                                  ]
                                },
                                proxy: true
                              }
                            ])
                          }),
                          _vm._v(" "),
                          _c("v-spacer"),
                          _vm._v(" "),
                          _vm.processing
                            ? _c("v-progress-circular", {
                                staticClass: "mr-3",
                                attrs: {
                                  indeterminate: true,
                                  rotate: 0,
                                  size: 32,
                                  width: 4,
                                  color: "white"
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-container",
            { staticClass: "pa-0", attrs: { fluid: "" } },
            [
              _c(
                "v-tabs",
                {
                  ref: "tabs",
                  staticClass: "mt-3",
                  attrs: {
                    "icons-and-text": "",
                    "show-arrows": !_vm.$vuetify.breakpoint.xsOnly,
                    "next-icon": "fa-arrow-circle-right",
                    "prev-icon": "fa-arrow-circle-left"
                  },
                  model: {
                    value: _vm.active_tab,
                    callback: function($$v) {
                      _vm.active_tab = $$v
                    },
                    expression: "active_tab"
                  }
                },
                [
                  _c(
                    "v-tab",
                    {
                      key: _vm.detailsTab.key,
                      ref: "tab",
                      attrs: {
                        "data-cy": "details",
                        href: `#tab-${_vm.detailsTab.key}`
                      },
                      on: {
                        click: function($event) {
                          return _vm.tabSelected(_vm.detailsTab)
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.detailsTab.tabname) +
                          "\n          "
                      ),
                      _c("v-icon", [_vm._v("fas fa-edit")])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-tab",
                    {
                      key: _vm.notificationsTab.key,
                      ref: "tab",
                      attrs: {
                        "data-cy": "notifications",
                        href: `#tab-${_vm.notificationsTab.key}`
                      },
                      on: {
                        click: function($event) {
                          return _vm.tabSelected(_vm.notificationsTab)
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.notificationsTab.tabname) +
                          "\n          "
                      ),
                      _c("v-icon", [_vm._v("fas fa-bell")])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.workflowTab.visible || _vm.$vuetify.breakpoint.smAndUp
                    ? _c(
                        "v-tab",
                        {
                          key: _vm.workflowTab.key,
                          attrs: {
                            "data-cy": "workflow",
                            href: `#tab-${_vm.workflowTab.key}`
                          },
                          on: {
                            click: function($event) {
                              return _vm.tabSelected(_vm.workflowTab)
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.workflowTab.tabname) +
                              "\n          "
                          ),
                          _c("v-icon", [_vm._v("fa-arrows-turn-to-dots")])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.defaultsTab.visible || _vm.$vuetify.breakpoint.smAndUp
                    ? _c(
                        "v-tab",
                        {
                          key: _vm.defaultsTab.key,
                          attrs: {
                            "data-cy": "defaults",
                            href: `#tab-${_vm.defaultsTab.key}`
                          },
                          on: {
                            click: function($event) {
                              return _vm.tabSelected(_vm.defaultsTab)
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.defaultsTab.tabname) +
                              "\n          "
                          ),
                          _c("v-icon", [_vm._v("fa-pen-field")])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.labourTab.visible || _vm.$vuetify.breakpoint.smAndUp
                    ? _c(
                        "v-tab",
                        {
                          key: _vm.labourTab.key,
                          attrs: {
                            "data-cy": "defaults",
                            href: `#tab-${_vm.labourTab.key}`
                          },
                          on: {
                            click: function($event) {
                              return _vm.tabSelected(_vm.labourTab)
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.labourTab.tabname) +
                              "\n          "
                          ),
                          _c("v-icon", [_vm._v("fa-user-hard-hat")])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.inspectionsTab.visible || _vm.$vuetify.breakpoint.smAndUp
                    ? _c(
                        "v-tab",
                        {
                          key: _vm.inspectionsTab.key,
                          attrs: {
                            "data-cy": "defaults",
                            href: `#tab-${_vm.inspectionsTab.key}`
                          },
                          on: {
                            click: function($event) {
                              return _vm.tabSelected(_vm.inspectionsTab)
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.inspectionsTab.tabname) +
                              "\n          "
                          ),
                          _c("v-icon", [
                            _vm._v(
                              _vm._s(_vm.$store.getters.icon("inspections"))
                            )
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "v-menu",
                    {
                      attrs: { bottom: "", left: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function({ on, attrs }) {
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  _vm._b(
                                    {
                                      staticClass:
                                        "align-self-center mr-4 d-flex d-sm-none more-tab-button",
                                      staticStyle: { position: "relative" },
                                      attrs: { text: "" }
                                    },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.$t("common.more")) +
                                      "\n\n              "
                                  ),
                                  _c(
                                    "div",
                                    [
                                      _c("v-icon", [
                                        _vm._v("fas fa-ellipsis-h")
                                      ])
                                    ],
                                    1
                                  )
                                ]
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [
                      _vm._v(" "),
                      _c(
                        "v-list",
                        { staticClass: "grey lighten-3" },
                        _vm._l(_vm.hiddenTabDefinitions, function(item) {
                          return _c(
                            "v-list-item",
                            {
                              key: item.tabname,
                              on: {
                                click: function($event) {
                                  return _vm.showNewTabFromMoreMenu(item)
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(item.tabname) +
                                  "\n            "
                              )
                            ]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-tabs-items",
                {
                  model: {
                    value: _vm.active_tab,
                    callback: function($$v) {
                      _vm.active_tab = $$v
                    },
                    expression: "active_tab"
                  }
                },
                [
                  _c(
                    "v-tab-item",
                    {
                      key: _vm.detailsTab.key,
                      attrs: { value: `tab-${_vm.detailsTab.key}` }
                    },
                    [
                      _c(
                        "v-form",
                        {
                          ref: "detailsform",
                          on: { submit: _vm.preventSubmit }
                        },
                        [
                          _c(
                            "v-row",
                            { staticClass: "pa-3", attrs: { align: "center" } },
                            [
                              _c(
                                "v-col",
                                {
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "6",
                                    md: "6",
                                    lg: "6"
                                  }
                                },
                                [
                                  _c("fd-text-field", {
                                    attrs: {
                                      label: _vm.$t(
                                        "configuration.site-info.site-name"
                                      ),
                                      disabled: _vm.processing,
                                      rules: [_vm.rules.required]
                                    },
                                    model: {
                                      value: _vm.environment.name,
                                      callback: function($$v) {
                                        _vm.$set(_vm.environment, "name", $$v)
                                      },
                                      expression: "environment.name"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "6",
                                    md: "6",
                                    lg: "6"
                                  }
                                },
                                [
                                  _c("fd-text-field", {
                                    attrs: {
                                      label: _vm.$t(
                                        "configuration.site-info.site-identifier"
                                      ),
                                      disabled: _vm.processing
                                    },
                                    model: {
                                      value: _vm.environment.identifier,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "identifier",
                                          $$v
                                        )
                                      },
                                      expression: "environment.identifier"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { staticClass: "pt-1", attrs: { cols: "12" } },
                                [
                                  _c("fd-rich-textarea", {
                                    attrs: {
                                      disabled: _vm.processing,
                                      label: _vm.$t("common.description"),
                                      allowImages: false
                                    },
                                    model: {
                                      value: _vm.environment.description,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "description",
                                          $$v
                                        )
                                      },
                                      expression: "environment.description"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-subheader", { staticClass: "mt-3" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "configuration.site-info.shipping-address-subheader"
                                )
                              )
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            { staticClass: "pa-3", attrs: { align: "center" } },
                            [
                              _c(
                                "v-col",
                                {
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "12",
                                    md: "12",
                                    lg: "12"
                                  }
                                },
                                [
                                  _c("fd-text-field", {
                                    attrs: {
                                      label: _vm.$t(
                                        "configuration.site-info.shipping-address-line-1"
                                      ),
                                      disabled: _vm.processing,
                                      "hide-details": ""
                                    },
                                    model: {
                                      value:
                                        _vm.environment.shippingAddressLine1,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "shippingAddressLine1",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "environment.shippingAddressLine1"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "12",
                                    md: "12",
                                    lg: "12"
                                  }
                                },
                                [
                                  _c("fd-text-field", {
                                    attrs: {
                                      label: _vm.$t(
                                        "configuration.site-info.shipping-address-line-2"
                                      ),
                                      disabled: _vm.processing,
                                      "hide-details": ""
                                    },
                                    model: {
                                      value:
                                        _vm.environment.shippingAddressLine2,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "shippingAddressLine2",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "environment.shippingAddressLine2"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "12",
                                    md: "12",
                                    lg: "12"
                                  }
                                },
                                [
                                  _c("fd-text-field", {
                                    attrs: {
                                      label: _vm.$t(
                                        "configuration.site-info.shipping-address-line-3"
                                      ),
                                      disabled: _vm.processing,
                                      "hide-details": ""
                                    },
                                    model: {
                                      value:
                                        _vm.environment.shippingAddressLine3,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "shippingAddressLine3",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "environment.shippingAddressLine3"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-subheader", { staticClass: "mt-3" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "configuration.site-info.site-calculation-settings-subheader"
                                )
                              )
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            { staticClass: "pa-3", attrs: { align: "center" } },
                            [
                              _c(
                                "v-col",
                                {
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "6",
                                    md: "6",
                                    lg: "6"
                                  }
                                },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      outlined: "",
                                      label: _vm.$t(
                                        "configuration.site-info.site-productivity"
                                      ),
                                      disabled: _vm.processing,
                                      items: _vm.siteProductivityOptions,
                                      "hide-details": ""
                                    },
                                    model: {
                                      value: _vm.environment.siteProductivity,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "siteProductivity",
                                          $$v
                                        )
                                      },
                                      expression: "environment.siteProductivity"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "6",
                                    md: "6",
                                    lg: "6"
                                  }
                                },
                                [
                                  _c("fd-text-field", {
                                    attrs: {
                                      outlined: "",
                                      label: _vm.$t(
                                        "configuration.site-info.site-blended-labour-rate"
                                      ),
                                      disabled: _vm.processing,
                                      "hide-details": ""
                                    },
                                    model: {
                                      value: _vm.environment.blendedLabourRate,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "blendedLabourRate",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "environment.blendedLabourRate"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "6",
                                    md: "6",
                                    lg: "6"
                                  }
                                },
                                [
                                  _c("fd-text-field", {
                                    attrs: {
                                      outlined: "",
                                      label: _vm.$t(
                                        "configuration.site-info.site-factor-1"
                                      ),
                                      disabled: _vm.processing,
                                      "hide-details": ""
                                    },
                                    model: {
                                      value: _vm.environment.factor1,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "factor1",
                                          $$v
                                        )
                                      },
                                      expression: "environment.factor1"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "6",
                                    md: "6",
                                    lg: "6"
                                  }
                                },
                                [
                                  _c("fd-text-field", {
                                    attrs: {
                                      outlined: "",
                                      label: _vm.$t(
                                        "configuration.site-info.site-factor-2"
                                      ),
                                      disabled: _vm.processing,
                                      "hide-details": ""
                                    },
                                    model: {
                                      value: _vm.environment.factor2,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "factor2",
                                          $$v
                                        )
                                      },
                                      expression: "environment.factor2"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { attrs: { align: "center", cols: "12" } },
                                [
                                  _c("v-switch", {
                                    attrs: {
                                      label: _vm.$t(
                                        "configuration.site-info.site-uses-secondary-deck-planks"
                                      ),
                                      disabled: _vm.processing,
                                      "hide-details": ""
                                    },
                                    model: {
                                      value:
                                        _vm.environment
                                          .usesSecondaryTypeDeckPlanks,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "usesSecondaryTypeDeckPlanks",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "environment.usesSecondaryTypeDeckPlanks"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-subheader", { staticClass: "mt-3" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "configuration.site-info.site-vlf-subheader"
                                )
                              )
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            { staticClass: "pa-3", attrs: { align: "center" } },
                            [
                              _c(
                                "v-col",
                                {
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "6",
                                    md: "6",
                                    lg: "6"
                                  }
                                },
                                [
                                  _c("v-switch", {
                                    attrs: {
                                      label: _vm.$t(
                                        "configuration.site-info.track-scaffold-vlf"
                                      ),
                                      disabled: _vm.processing
                                    },
                                    model: {
                                      value: _vm.environment.trackScaffoldVLF,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "trackScaffoldVLF",
                                          $$v
                                        )
                                      },
                                      expression: "environment.trackScaffoldVLF"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "6",
                                    md: "6",
                                    lg: "6"
                                  }
                                },
                                [
                                  _c("v-switch", {
                                    attrs: {
                                      label: _vm.$t(
                                        "configuration.site-info.walkdown-vlf-required"
                                      ),
                                      disabled:
                                        _vm.processing ||
                                        !_vm.environment.trackScaffoldVLF,
                                      hint: !_vm.environment.trackScaffoldVLF
                                        ? _vm.$t(
                                            "configuration.site-info.walkdown-vlf-disabled-explanation"
                                          )
                                        : "",
                                      "persistent-hint": !_vm.environment
                                        .trackScaffoldVLF
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "message",
                                        fn: function({ message }) {
                                          return [
                                            !_vm.environment.trackScaffoldVLF
                                              ? _c("fp-item-disabled-message", {
                                                  attrs: { message: message }
                                                })
                                              : _vm._e()
                                          ]
                                        }
                                      }
                                    ]),
                                    model: {
                                      value:
                                        _vm.environment.walkdownVLFRequired,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "walkdownVLFRequired",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "environment.walkdownVLFRequired"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-tab-item",
                    {
                      key: _vm.notificationsTab.key,
                      attrs: { value: `tab-${_vm.notificationsTab.key}` }
                    },
                    [
                      _c(
                        "v-row",
                        {
                          staticClass: "mx-2 pt-3 fd-subheader-with-control",
                          attrs: { align: "center" }
                        },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "pa-0",
                              attrs: { align: "center", cols: "12", sm: "8" }
                            },
                            [
                              _c(
                                "v-subheader",
                                {
                                  staticStyle: {
                                    "font-size": "1em",
                                    "margin-top": "10px"
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "configuration.notifications.enable-notifications"
                                      )
                                    )
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            {
                              staticClass: "pt-1 pb-1",
                              attrs: { align: "center", cols: "12", sm: "4" }
                            },
                            [
                              _c(
                                "v-row",
                                { staticClass: "pr-3 d-flex justify-sm-end" },
                                [
                                  _c("v-switch", {
                                    staticClass:
                                      "fd-remove-messages pl-3 pl-sm-0",
                                    attrs: {
                                      label: _vm.$t("common.enabled"),
                                      disabled: _vm.processing
                                    },
                                    model: {
                                      value:
                                        _vm.environment.enableNotifications,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "enableNotifications",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "environment.enableNotifications"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        { staticClass: "mx-2", attrs: { align: "center" } },
                        [_c("v-divider", { staticClass: "mx-3 mt-0" })],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-tab-item",
                    {
                      key: _vm.workflowTab.key,
                      attrs: { value: `tab-${_vm.workflowTab.key}`, eager: "" }
                    },
                    [
                      _c(
                        "v-form",
                        {
                          ref: "workflowform",
                          on: { submit: _vm.preventSubmit }
                        },
                        [
                          _c(
                            "v-row",
                            { staticClass: "mx-2 pt-3" },
                            [
                              _c(
                                "v-col",
                                {
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    md: "6"
                                  }
                                },
                                [
                                  _c("v-switch", {
                                    attrs: {
                                      label: _vm.$t(
                                        "configuration.workflow.requests-require-walkdown"
                                      ),
                                      disabled: _vm.processing
                                    },
                                    model: {
                                      value:
                                        _vm.environment
                                          .defaultScaffoldRequestRequiresWalkDown,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "defaultScaffoldRequestRequiresWalkDown",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "environment.defaultScaffoldRequestRequiresWalkDown"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            {
                              staticClass:
                                "mx-2 pt-3 fd-subheader-with-control",
                              attrs: { align: "center" }
                            },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "pa-0",
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "8"
                                  }
                                },
                                [
                                  _c(
                                    "v-subheader",
                                    {
                                      staticStyle: {
                                        "font-size": "1em",
                                        "margin-top": "10px"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "configuration.workflow.auto-request-approval-subheader"
                                          )
                                        )
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass: "pt-1 pb-1",
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "4"
                                  }
                                },
                                [
                                  _c(
                                    "v-row",
                                    {
                                      staticClass: "pr-3 d-flex justify-sm-end"
                                    },
                                    [
                                      _c("v-switch", {
                                        staticClass:
                                          "fd-remove-messages pl-3 pl-sm-0",
                                        attrs: {
                                          label: _vm.$t("common.enabled"),
                                          disabled: _vm.processing
                                        },
                                        model: {
                                          value:
                                            _vm.environment
                                              .automaticallyApproveScaffoldRequests,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.environment,
                                              "automaticallyApproveScaffoldRequests",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "environment.automaticallyApproveScaffoldRequests"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            { staticClass: "mx-2", attrs: { align: "center" } },
                            [_c("v-divider", { staticClass: "mx-3 mt-0" })],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            {
                              staticClass: "mx-2 pl-0 pr-0 pb-0 pt-5",
                              attrs: { align: "center" }
                            },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "justify-space-between pt-1 pb-1",
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "6"
                                  }
                                },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      outlined: "",
                                      clearable: "",
                                      items: _vm.scaffoldContractors,
                                      "item-text": "name",
                                      "item-value": "id",
                                      label: _vm.$t(
                                        "configuration.workflow.default-assigned-contractor-label"
                                      ),
                                      disabled:
                                        _vm.processing ||
                                        !_vm.environment
                                          .automaticallyApproveScaffoldRequests,
                                      rules:
                                        _vm.configurationRules
                                          .defaultWorkOrderAssignedContractorID
                                    },
                                    model: {
                                      value:
                                        _vm.environment
                                          .defaultWorkOrderAssignedContractorID,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "defaultWorkOrderAssignedContractorID",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "environment.defaultWorkOrderAssignedContractorID"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "justify-space-between pt-1 pb-1",
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "6"
                                  }
                                },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      outlined: "",
                                      clearable: "",
                                      items: _vm.selectableCoordinators(
                                        _vm.environment
                                          .defaultWorkOrderAssignedContractorID
                                      ),
                                      "item-text": "name",
                                      "item-value": "id",
                                      label: _vm.$t(
                                        "configuration.workflow.default-assigned-coordinator-label"
                                      ),
                                      disabled:
                                        _vm.processing ||
                                        !_vm.environment
                                          .automaticallyApproveScaffoldRequests,
                                      rules:
                                        _vm.configurationRules
                                          .defaultWorkOrderAssignedCoordinatorID
                                    },
                                    model: {
                                      value:
                                        _vm.environment
                                          .defaultWorkOrderAssignedCoordinatorID,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "defaultWorkOrderAssignedCoordinatorID",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "environment.defaultWorkOrderAssignedCoordinatorID"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "justify-space-between pt-1 pb-1",
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "6"
                                  }
                                },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      outlined: "",
                                      clearable: "",
                                      items: _vm.selectableGeneralForemen(
                                        _vm.environment
                                          .defaultWorkOrderAssignedContractorID
                                      ),
                                      "item-text": "name",
                                      "item-value": "id",
                                      label: _vm.$t(
                                        "configuration.workflow.default-assigned-general-foreman-label"
                                      ),
                                      disabled:
                                        _vm.processing ||
                                        !_vm.environment
                                          .automaticallyApproveScaffoldRequests,
                                      rules:
                                        _vm.configurationRules
                                          .defaultWorkOrderAssignedGeneralForemanID
                                    },
                                    model: {
                                      value:
                                        _vm.environment
                                          .defaultWorkOrderAssignedGeneralForemanID,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "defaultWorkOrderAssignedGeneralForemanID",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "environment.defaultWorkOrderAssignedGeneralForemanID"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "justify-space-between pt-1 pb-1",
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "6"
                                  }
                                },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      outlined: "",
                                      clearable: "",
                                      items: _vm.selectableForemen(
                                        _vm.environment
                                          .defaultWorkOrderAssignedContractorID
                                      ),
                                      "item-text": "name",
                                      "item-value": "id",
                                      label: _vm.$t(
                                        "configuration.workflow.default-assigned-foreman-label"
                                      ),
                                      disabled:
                                        _vm.processing ||
                                        !_vm.environment
                                          .automaticallyApproveScaffoldRequests,
                                      rules:
                                        _vm.configurationRules
                                          .defaultWorkOrderAssignedForemanID
                                    },
                                    model: {
                                      value:
                                        _vm.environment
                                          .defaultWorkOrderAssignedForemanID,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "defaultWorkOrderAssignedForemanID",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "environment.defaultWorkOrderAssignedForemanID"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            {
                              staticClass:
                                "mx-2 pt-3 fd-subheader-with-control",
                              attrs: { align: "center" }
                            },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "pa-0",
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "8"
                                  }
                                },
                                [
                                  _c(
                                    "v-subheader",
                                    {
                                      staticStyle: {
                                        "font-size": "1em",
                                        "margin-top": "10px"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "configuration.workflow.auto-maintenance-request-approval-subheader"
                                          )
                                        )
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass: "pt-1 pb-1",
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "4"
                                  }
                                },
                                [
                                  _c(
                                    "v-row",
                                    {
                                      staticClass: "pr-3 d-flex justify-sm-end"
                                    },
                                    [
                                      _c("v-switch", {
                                        staticClass:
                                          "fd-remove-messages pl-3 pl-sm-0",
                                        attrs: {
                                          label: _vm.$t("common.enabled"),
                                          disabled: _vm.processing
                                        },
                                        model: {
                                          value:
                                            _vm.environment
                                              .automaticallyApproveMaintenanceRequests,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.environment,
                                              "automaticallyApproveMaintenanceRequests",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "environment.automaticallyApproveMaintenanceRequests"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            { staticClass: "mx-2", attrs: { align: "center" } },
                            [_c("v-divider", { staticClass: "mx-3 mt-0" })],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            {
                              staticClass: "mx-2 pl-0 pr-0 pb-0 pt-5",
                              attrs: { align: "center" }
                            },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "justify-space-between pt-1 pb-1",
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "6"
                                  }
                                },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      outlined: "",
                                      clearable: "",
                                      items: _vm.maintenanceContractors,
                                      "item-text": "name",
                                      "item-value": "id",
                                      label: _vm.$t(
                                        "configuration.workflow.default-assigned-contractor-label"
                                      ),
                                      disabled:
                                        _vm.processing ||
                                        !_vm.environment
                                          .automaticallyApproveMaintenanceRequests,
                                      rules:
                                        _vm.configurationRules
                                          .defaultMaintenanceWorkOrderAssignedContractorID
                                    },
                                    model: {
                                      value:
                                        _vm.environment
                                          .defaultMaintenanceWorkOrderAssignedContractorID,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "defaultMaintenanceWorkOrderAssignedContractorID",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "environment.defaultMaintenanceWorkOrderAssignedContractorID"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "justify-space-between pt-1 pb-1",
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "6"
                                  }
                                },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      outlined: "",
                                      clearable: "",
                                      items: _vm.selectableCoordinators(
                                        _vm.environment
                                          .defaultMaintenanceWorkOrderAssignedContractorID
                                      ),
                                      "item-text": "name",
                                      "item-value": "id",
                                      label: _vm.$t(
                                        "configuration.workflow.default-assigned-coordinator-label"
                                      ),
                                      disabled:
                                        _vm.processing ||
                                        !_vm.environment
                                          .automaticallyApproveMaintenanceRequests,
                                      rules:
                                        _vm.configurationRules
                                          .defaultMaintenanceWorkOrderAssignedCoordinatorID
                                    },
                                    model: {
                                      value:
                                        _vm.environment
                                          .defaultMaintenanceWorkOrderAssignedCoordinatorID,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "defaultMaintenanceWorkOrderAssignedCoordinatorID",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "environment.defaultMaintenanceWorkOrderAssignedCoordinatorID"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "justify-space-between pt-1 pb-1",
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "6"
                                  }
                                },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      outlined: "",
                                      clearable: "",
                                      items: _vm.selectableGeneralForemen(
                                        _vm.environment
                                          .defaultMaintenanceWorkOrderAssignedContractorID
                                      ),
                                      "item-text": "name",
                                      "item-value": "id",
                                      label: _vm.$t(
                                        "configuration.workflow.default-assigned-general-foreman-label"
                                      ),
                                      disabled:
                                        _vm.processing ||
                                        !_vm.environment
                                          .automaticallyApproveMaintenanceRequests,
                                      rules:
                                        _vm.configurationRules
                                          .defaultMaintenanceWorkOrderAssignedGeneralForemanID
                                    },
                                    model: {
                                      value:
                                        _vm.environment
                                          .defaultMaintenanceWorkOrderAssignedGeneralForemanID,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "defaultMaintenanceWorkOrderAssignedGeneralForemanID",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "environment.defaultMaintenanceWorkOrderAssignedGeneralForemanID"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "justify-space-between pt-1 pb-1",
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "6"
                                  }
                                },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      outlined: "",
                                      clearable: "",
                                      items: _vm.selectableForemen(
                                        _vm.environment
                                          .defaultMaintenanceWorkOrderAssignedContractorID
                                      ),
                                      "item-text": "name",
                                      "item-value": "id",
                                      label: _vm.$t(
                                        "configuration.workflow.default-assigned-foreman-label"
                                      ),
                                      disabled:
                                        _vm.processing ||
                                        !_vm.environment
                                          .automaticallyApproveMaintenanceRequests,
                                      rules:
                                        _vm.configurationRules
                                          .defaultMaintenanceWorkOrderAssignedForemanID
                                    },
                                    model: {
                                      value:
                                        _vm.environment
                                          .defaultMaintenanceWorkOrderAssignedForemanID,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "defaultMaintenanceWorkOrderAssignedForemanID",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "environment.defaultMaintenanceWorkOrderAssignedForemanID"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            {
                              staticClass:
                                "mx-2 pt-3 fd-subheader-with-control",
                              attrs: { align: "center" }
                            },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "pa-0",
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "8"
                                  }
                                },
                                [
                                  _c(
                                    "v-subheader",
                                    {
                                      staticStyle: {
                                        "font-size": "1em",
                                        "margin-top": "10px"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "configuration.workflow.auto-paint-request-approval-subheader"
                                          )
                                        )
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass: "pt-1 pb-1",
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "4"
                                  }
                                },
                                [
                                  _c(
                                    "v-row",
                                    {
                                      staticClass: "pr-3 d-flex justify-sm-end"
                                    },
                                    [
                                      _c("v-switch", {
                                        staticClass:
                                          "fd-remove-messages pl-3 pl-sm-0",
                                        attrs: {
                                          label: _vm.$t("common.enabled"),
                                          disabled: _vm.processing
                                        },
                                        model: {
                                          value:
                                            _vm.environment
                                              .automaticallyApprovePaintRequests,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.environment,
                                              "automaticallyApprovePaintRequests",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "environment.automaticallyApprovePaintRequests"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            { staticClass: "mx-2", attrs: { align: "center" } },
                            [_c("v-divider", { staticClass: "mx-3 mt-0" })],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            {
                              staticClass: "mx-2 pl-0 pr-0 pb-0 pt-5",
                              attrs: { align: "center" }
                            },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "justify-space-between pt-1 pb-1",
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "6"
                                  }
                                },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      outlined: "",
                                      clearable: "",
                                      items: _vm.paintContractors,
                                      "item-text": "name",
                                      "item-value": "id",
                                      label: _vm.$t(
                                        "configuration.workflow.default-assigned-contractor-label"
                                      ),
                                      disabled:
                                        _vm.processing ||
                                        !_vm.environment
                                          .automaticallyApprovePaintRequests,
                                      rules:
                                        _vm.configurationRules
                                          .defaultPaintWorkOrderAssignedContractorID
                                    },
                                    model: {
                                      value:
                                        _vm.environment
                                          .defaultPaintWorkOrderAssignedContractorID,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "defaultPaintWorkOrderAssignedContractorID",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "environment.defaultPaintWorkOrderAssignedContractorID"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "justify-space-between pt-1 pb-1",
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "6"
                                  }
                                },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      outlined: "",
                                      clearable: "",
                                      items: _vm.selectableCoordinators(
                                        _vm.environment
                                          .defaultPaintWorkOrderAssignedContractorID
                                      ),
                                      "item-text": "name",
                                      "item-value": "id",
                                      label: _vm.$t(
                                        "configuration.workflow.default-assigned-coordinator-label"
                                      ),
                                      disabled:
                                        _vm.processing ||
                                        !_vm.environment
                                          .automaticallyApprovePaintRequests,
                                      rules:
                                        _vm.configurationRules
                                          .defaultPaintWorkOrderAssignedCoordinatorID
                                    },
                                    model: {
                                      value:
                                        _vm.environment
                                          .defaultPaintWorkOrderAssignedCoordinatorID,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "defaultPaintWorkOrderAssignedCoordinatorID",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "environment.defaultPaintWorkOrderAssignedCoordinatorID"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "justify-space-between pt-1 pb-1",
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "6"
                                  }
                                },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      outlined: "",
                                      clearable: "",
                                      items: _vm.selectableGeneralForemen(
                                        _vm.environment
                                          .defaultPaintWorkOrderAssignedContractorID
                                      ),
                                      "item-text": "name",
                                      "item-value": "id",
                                      label: _vm.$t(
                                        "configuration.workflow.default-assigned-general-foreman-label"
                                      ),
                                      disabled:
                                        _vm.processing ||
                                        !_vm.environment
                                          .automaticallyApprovePaintRequests,
                                      rules:
                                        _vm.configurationRules
                                          .defaultPaintWorkOrderAssignedGeneralForemanID
                                    },
                                    model: {
                                      value:
                                        _vm.environment
                                          .defaultPaintWorkOrderAssignedGeneralForemanID,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "defaultPaintWorkOrderAssignedGeneralForemanID",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "environment.defaultPaintWorkOrderAssignedGeneralForemanID"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "justify-space-between pt-1 pb-1",
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "6"
                                  }
                                },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      outlined: "",
                                      clearable: "",
                                      items: _vm.selectableForemen(
                                        _vm.environment
                                          .defaultPaintWorkOrderAssignedContractorID
                                      ),
                                      "item-text": "name",
                                      "item-value": "id",
                                      label: _vm.$t(
                                        "configuration.workflow.default-assigned-foreman-label"
                                      ),
                                      disabled:
                                        _vm.processing ||
                                        !_vm.environment
                                          .automaticallyApprovePaintRequests,
                                      rules:
                                        _vm.configurationRules
                                          .defaultPaintWorkOrderAssignedForemanID
                                    },
                                    model: {
                                      value:
                                        _vm.environment
                                          .defaultPaintWorkOrderAssignedForemanID,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "defaultPaintWorkOrderAssignedForemanID",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "environment.defaultPaintWorkOrderAssignedForemanID"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            {
                              staticClass:
                                "mx-2 pt-3 fd-subheader-with-control",
                              attrs: { align: "center" }
                            },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "pa-0",
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "8"
                                  }
                                },
                                [
                                  _c(
                                    "v-subheader",
                                    {
                                      staticStyle: {
                                        "font-size": "1em",
                                        "margin-top": "10px"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "configuration.workflow.auto-insulation-request-approval-subheader"
                                          )
                                        )
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass: "pt-1 pb-1",
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "4"
                                  }
                                },
                                [
                                  _c(
                                    "v-row",
                                    {
                                      staticClass: "pr-3 d-flex justify-sm-end"
                                    },
                                    [
                                      _c("v-switch", {
                                        staticClass:
                                          "fd-remove-messages pl-3 pl-sm-0",
                                        attrs: {
                                          label: _vm.$t("common.enabled"),
                                          disabled: _vm.processing
                                        },
                                        model: {
                                          value:
                                            _vm.environment
                                              .automaticallyApproveInsulationRequests,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.environment,
                                              "automaticallyApproveInsulationRequests",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "environment.automaticallyApproveInsulationRequests"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            { staticClass: "mx-2", attrs: { align: "center" } },
                            [_c("v-divider", { staticClass: "mx-3 mt-0" })],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            {
                              staticClass: "mx-2 pl-0 pr-0 pb-0 pt-5",
                              attrs: { align: "center" }
                            },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "justify-space-between pt-1 pb-1",
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "6"
                                  }
                                },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      outlined: "",
                                      clearable: "",
                                      items: _vm.insulationContractors,
                                      "item-text": "name",
                                      "item-value": "id",
                                      label: _vm.$t(
                                        "configuration.workflow.default-assigned-contractor-label"
                                      ),
                                      disabled:
                                        _vm.processing ||
                                        !_vm.environment
                                          .automaticallyApproveInsulationRequests,
                                      rules:
                                        _vm.configurationRules
                                          .defaultInsulationWorkOrderAssignedContractorID
                                    },
                                    model: {
                                      value:
                                        _vm.environment
                                          .defaultInsulationWorkOrderAssignedContractorID,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "defaultInsulationWorkOrderAssignedContractorID",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "environment.defaultInsulationWorkOrderAssignedContractorID"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "justify-space-between pt-1 pb-1",
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "6"
                                  }
                                },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      outlined: "",
                                      clearable: "",
                                      items: _vm.selectableCoordinators(
                                        _vm.environment
                                          .defaultInsulationWorkOrderAssignedContractorID
                                      ),
                                      "item-text": "name",
                                      "item-value": "id",
                                      label: _vm.$t(
                                        "configuration.workflow.default-assigned-coordinator-label"
                                      ),
                                      disabled:
                                        _vm.processing ||
                                        !_vm.environment
                                          .automaticallyApproveInsulationRequests,
                                      rules:
                                        _vm.configurationRules
                                          .defaultInsulationWorkOrderAssignedCoordinatorID
                                    },
                                    model: {
                                      value:
                                        _vm.environment
                                          .defaultInsulationWorkOrderAssignedCoordinatorID,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "defaultInsulationWorkOrderAssignedCoordinatorID",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "environment.defaultInsulationWorkOrderAssignedCoordinatorID"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "justify-space-between pt-1 pb-1",
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "6"
                                  }
                                },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      outlined: "",
                                      clearable: "",
                                      items: _vm.selectableGeneralForemen(
                                        _vm.environment
                                          .defaultInsulationWorkOrderAssignedContractorID
                                      ),
                                      "item-text": "name",
                                      "item-value": "id",
                                      label: _vm.$t(
                                        "configuration.workflow.default-assigned-general-foreman-label"
                                      ),
                                      disabled:
                                        _vm.processing ||
                                        !_vm.environment
                                          .automaticallyApproveInsulationRequests,
                                      rules:
                                        _vm.configurationRules
                                          .defaultInsulationWorkOrderAssignedGeneralForemanID
                                    },
                                    model: {
                                      value:
                                        _vm.environment
                                          .defaultInsulationWorkOrderAssignedGeneralForemanID,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "defaultInsulationWorkOrderAssignedGeneralForemanID",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "environment.defaultInsulationWorkOrderAssignedGeneralForemanID"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "justify-space-between pt-1 pb-1",
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "6"
                                  }
                                },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      outlined: "",
                                      clearable: "",
                                      items: _vm.selectableForemen(
                                        _vm.environment
                                          .defaultInsulationWorkOrderAssignedContractorID
                                      ),
                                      "item-text": "name",
                                      "item-value": "id",
                                      label: _vm.$t(
                                        "configuration.workflow.default-assigned-foreman-label"
                                      ),
                                      disabled:
                                        _vm.processing ||
                                        !_vm.environment
                                          .automaticallyApproveInsulationRequests,
                                      rules:
                                        _vm.configurationRules
                                          .defaultInsulationWorkOrderAssignedForemanID
                                    },
                                    model: {
                                      value:
                                        _vm.environment
                                          .defaultInsulationWorkOrderAssignedForemanID,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "defaultInsulationWorkOrderAssignedForemanID",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "environment.defaultInsulationWorkOrderAssignedForemanID"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            {
                              staticClass:
                                "mx-2 pt-3 fd-subheader-with-control",
                              attrs: { align: "center" }
                            },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "pa-0",
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "8"
                                  }
                                },
                                [
                                  _c(
                                    "v-subheader",
                                    {
                                      staticStyle: {
                                        "font-size": "1em",
                                        "margin-top": "10px"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "configuration.workflow.auto-count-sheet-approval-subheader"
                                          )
                                        )
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass: "pt-1 pb-1",
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "4"
                                  }
                                },
                                [
                                  _c(
                                    "v-row",
                                    {
                                      staticClass: "pr-3 d-flex justify-sm-end"
                                    },
                                    [
                                      _c("v-switch", {
                                        staticClass:
                                          "fd-remove-messages pl-3 pl-sm-0",
                                        attrs: {
                                          label: _vm.$t("common.enabled"),
                                          disabled: _vm.processing
                                        },
                                        model: {
                                          value:
                                            _vm.environment
                                              .automaticallyApproveCountSheets,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.environment,
                                              "automaticallyApproveCountSheets",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "environment.automaticallyApproveCountSheets"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            { staticClass: "mx-2", attrs: { align: "center" } },
                            [_c("v-divider", { staticClass: "mx-3 mt-0" })],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            {
                              staticClass: "mx-2 pl-0 pr-0 pb-0 pt-5",
                              attrs: { align: "center" }
                            },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "justify-space-between pt-1 pb-1",
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "6"
                                  }
                                },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      outlined: "",
                                      clearable: "",
                                      items: _vm.allYards,
                                      "item-text": "name",
                                      "item-value": "id",
                                      label: _vm.$t(
                                        "configuration.workflow.default-from-yard-label"
                                      ),
                                      disabled:
                                        _vm.processing ||
                                        !_vm.environment
                                          .automaticallyApproveCountSheets,
                                      rules:
                                        _vm.configurationRules
                                          .defaultCountSheetFromYardID
                                    },
                                    model: {
                                      value:
                                        _vm.environment
                                          .defaultCountSheetFromYardID,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "defaultCountSheetFromYardID",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "environment.defaultCountSheetFromYardID"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "justify-space-between pt-1 pb-1",
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "6"
                                  }
                                },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      outlined: "",
                                      clearable: "",
                                      items: _vm.allYards,
                                      "item-text": "name",
                                      "item-value": "id",
                                      label: _vm.$t(
                                        "configuration.workflow.default-to-yard-label"
                                      ),
                                      disabled:
                                        _vm.processing ||
                                        !_vm.environment
                                          .automaticallyApproveCountSheets,
                                      rules:
                                        _vm.configurationRules
                                          .defaultCountSheetToYardID,
                                      hint: _vm.$t(
                                        "configuration.workflow.default-to-yard-hint"
                                      ),
                                      "persistent-hint":
                                        _vm.environment
                                          .automaticallyApproveCountSheets &&
                                        !_vm.environment
                                          .defaultCountSheetToYardID
                                    },
                                    model: {
                                      value:
                                        _vm.environment
                                          .defaultCountSheetToYardID,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "defaultCountSheetToYardID",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "environment.defaultCountSheetToYardID"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-tab-item",
                    {
                      key: _vm.defaultsTab.key,
                      attrs: { value: `tab-${_vm.defaultsTab.key}` }
                    },
                    [
                      _c(
                        "v-form",
                        {
                          ref: "defaultsform",
                          on: { submit: _vm.preventSubmit }
                        },
                        [
                          _c("v-subheader", { staticClass: "mt-3" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "configuration.defaults.request-default-cost-code-subheader"
                                )
                              )
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            {
                              staticClass: "mx-2 pl-0 pr-0 pb-0 pt-5",
                              attrs: { align: "center" }
                            },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "justify-space-between pt-1 pb-1",
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "12",
                                    md: "12",
                                    lg: "6"
                                  }
                                },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      outlined: "",
                                      clearable: "",
                                      items: _vm.allCostCodes,
                                      "item-text": "name",
                                      "item-value": "id",
                                      label: _vm.$t(
                                        "configuration.defaults.default-erect-cost-code-label"
                                      ),
                                      disabled: _vm.processing,
                                      rules:
                                        _vm.configurationRules
                                          .defaultErectRequestCostCodeID
                                    },
                                    model: {
                                      value:
                                        _vm.environment
                                          .defaultErectRequestCostCodeID,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "defaultErectRequestCostCodeID",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "environment.defaultErectRequestCostCodeID"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("v-col", {
                                staticClass:
                                  "justify-space-between pt-1 pb-1 d-none d-lg-flex",
                                attrs: { align: "center", cols: "0", sm: "6" }
                              }),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "justify-space-between pt-1 pb-1",
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "12",
                                    md: "12",
                                    lg: "6"
                                  }
                                },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      outlined: "",
                                      clearable: "",
                                      items: _vm.allCostCodes,
                                      "item-text": "name",
                                      "item-value": "id",
                                      label: _vm.$t(
                                        "configuration.defaults.default-modify-cost-code-label"
                                      ),
                                      disabled: _vm.processing,
                                      rules:
                                        _vm.configurationRules
                                          .defaultModifyRequestCostCodeID
                                    },
                                    model: {
                                      value:
                                        _vm.environment
                                          .defaultModifyRequestCostCodeID,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "defaultModifyRequestCostCodeID",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "environment.defaultModifyRequestCostCodeID"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("v-col", {
                                staticClass:
                                  "justify-space-between pt-1 pb-1 d-none d-lg-flex",
                                attrs: { align: "center", cols: "0", sm: "6" }
                              }),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "justify-space-between pt-1 pb-1",
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "12",
                                    md: "12",
                                    lg: "6"
                                  }
                                },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      outlined: "",
                                      clearable: "",
                                      items: _vm.allCostCodes,
                                      "item-text": "name",
                                      "item-value": "id",
                                      label: _vm.$t(
                                        "configuration.defaults.default-dismantle-cost-code-label"
                                      ),
                                      disabled: _vm.processing,
                                      rules:
                                        _vm.configurationRules
                                          .defaultDismantleRequestCostCodeID
                                    },
                                    model: {
                                      value:
                                        _vm.environment
                                          .defaultDismantleRequestCostCodeID,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "defaultDismantleRequestCostCodeID",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "environment.defaultDismantleRequestCostCodeID"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-subheader", { staticClass: "mt-3" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "configuration.defaults.scaffold-defaults-subheader"
                                )
                              )
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            {
                              staticClass: "mx-2 pl-0 pr-0 pb-0 pt-5",
                              attrs: { align: "center" }
                            },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "justify-space-between pt-1 pb-1",
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "12",
                                    md: "12",
                                    lg: "6"
                                  }
                                },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      outlined: "",
                                      clearable: "",
                                      items: _vm.allScaffoldTypeModifiers,
                                      "item-text": "name",
                                      "item-value": "id",
                                      label: _vm.$t(
                                        "configuration.defaults.default-scaffold-type-modifier-label"
                                      ),
                                      disabled: _vm.processing,
                                      "hide-details": ""
                                    },
                                    model: {
                                      value:
                                        _vm.environment
                                          .defaultScaffoldTypeModifierID,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "defaultScaffoldTypeModifierID",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "environment.defaultScaffoldTypeModifierID"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "justify-space-between pt-1 pb-1",
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "12",
                                    md: "12",
                                    lg: "6"
                                  }
                                },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      outlined: "",
                                      clearable: "",
                                      items: _vm.allScaffoldDistanceModifiers,
                                      "item-text": "name",
                                      "item-value": "id",
                                      label: _vm.$t(
                                        "configuration.defaults.default-scaffold-distance-modifier-label"
                                      ),
                                      disabled: _vm.processing,
                                      "hide-details": ""
                                    },
                                    model: {
                                      value:
                                        _vm.environment
                                          .defaultScaffoldDistanceModifierID,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "defaultScaffoldDistanceModifierID",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "environment.defaultScaffoldDistanceModifierID"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "justify-space-between pt-1 pb-1",
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "12",
                                    md: "12",
                                    lg: "6"
                                  }
                                },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      outlined: "",
                                      clearable: "",
                                      items: _vm.allScaffoldElevationModifiers,
                                      "item-text": "name",
                                      "item-value": "id",
                                      label: _vm.$t(
                                        "configuration.defaults.default-scaffold-elevation-modifier-label"
                                      ),
                                      disabled: _vm.processing,
                                      "hide-details": ""
                                    },
                                    model: {
                                      value:
                                        _vm.environment
                                          .defaultScaffoldElevationModifierID,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "defaultScaffoldElevationModifierID",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "environment.defaultScaffoldElevationModifierID"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "justify-space-between pt-1 pb-1",
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "12",
                                    md: "12",
                                    lg: "6"
                                  }
                                },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      outlined: "",
                                      clearable: "",
                                      items: _vm.allScaffoldHeightModifiers,
                                      "item-text": "name",
                                      "item-value": "id",
                                      label: _vm.$t(
                                        "configuration.defaults.default-scaffold-height-modifier-label"
                                      ),
                                      disabled: _vm.processing,
                                      "hide-details": ""
                                    },
                                    model: {
                                      value:
                                        _vm.environment
                                          .defaultScaffoldHeightModifierID,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "defaultScaffoldHeightModifierID",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "environment.defaultScaffoldHeightModifierID"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "justify-space-between pt-1 pb-1",
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "12",
                                    md: "12",
                                    lg: "6"
                                  }
                                },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      outlined: "",
                                      clearable: "",
                                      items: _vm.allBuildDismantleRatios,
                                      "item-text": "ratio",
                                      "item-value": "id",
                                      label: _vm.$t(
                                        "configuration.defaults.default-build-dismantle-ratio-label"
                                      ),
                                      disabled: _vm.processing,
                                      "hide-details": ""
                                    },
                                    model: {
                                      value:
                                        _vm.environment
                                          .defaultBuildDismantleRatioID,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "defaultBuildDismantleRatioID",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "environment.defaultBuildDismantleRatioID"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "6",
                                    md: "6",
                                    lg: "6"
                                  }
                                },
                                [
                                  _c("fd-text-field", {
                                    attrs: {
                                      outlined: "",
                                      label: _vm.$t(
                                        "configuration.defaults.default-crew-size-label"
                                      ),
                                      disabled: _vm.processing,
                                      "hide-details": "",
                                      type: "number",
                                      min: "0"
                                    },
                                    model: {
                                      value: _vm.environment.defaultCrewSize,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "defaultCrewSize",
                                          $$v
                                        )
                                      },
                                      expression: "environment.defaultCrewSize"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "6",
                                    md: "6",
                                    lg: "6"
                                  }
                                },
                                [
                                  _c("fd-text-field", {
                                    attrs: {
                                      outlined: "",
                                      label: _vm.$t(
                                        "configuration.defaults.default-factor-1-label"
                                      ),
                                      disabled: _vm.processing,
                                      "hide-details": "",
                                      type: "number",
                                      min: "0",
                                      "hide-spin-buttons": ""
                                    },
                                    model: {
                                      value: _vm.environment.defaultFactor1,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "defaultFactor1",
                                          $$v
                                        )
                                      },
                                      expression: "environment.defaultFactor1"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "6",
                                    md: "6",
                                    lg: "6"
                                  }
                                },
                                [
                                  _c("fd-text-field", {
                                    attrs: {
                                      outlined: "",
                                      label: _vm.$t(
                                        "configuration.defaults.default-factor-2-label"
                                      ),
                                      disabled: _vm.processing,
                                      "hide-details": "",
                                      type: "number",
                                      min: "0",
                                      "hide-spin-buttons": ""
                                    },
                                    model: {
                                      value: _vm.environment.defaultFactor2,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "defaultFactor2",
                                          $$v
                                        )
                                      },
                                      expression: "environment.defaultFactor2"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-tab-item",
                    {
                      key: _vm.labourTab.key,
                      attrs: { value: `tab-${_vm.labourTab.key}` }
                    },
                    [
                      _c(
                        "v-form",
                        {
                          ref: "labourform",
                          on: { submit: _vm.preventSubmit }
                        },
                        [
                          _c(
                            "v-row",
                            { staticClass: "pa-3", attrs: { align: "center" } },
                            [
                              _c(
                                "v-col",
                                {
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "12",
                                    md: "12",
                                    lg: "6"
                                  }
                                },
                                [
                                  _c("fd-text-field", {
                                    attrs: {
                                      label: _vm.$t(
                                        "configuration.labour.default-overtime-threshold-label"
                                      ),
                                      disabled: _vm.processing,
                                      type: "number",
                                      "hide-spin-buttons": ""
                                    },
                                    model: {
                                      value:
                                        _vm.environment
                                          .defaultEmployeeOvertimeHoursThreshold,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "defaultEmployeeOvertimeHoursThreshold",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "environment.defaultEmployeeOvertimeHoursThreshold"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "12",
                                    md: "12",
                                    lg: "6"
                                  }
                                },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      outlined: "",
                                      label: _vm.$t(
                                        "configuration.labour.weekending-day-label"
                                      ),
                                      disabled: _vm.processing,
                                      items: _vm.weekendingDayOptions
                                    },
                                    model: {
                                      value: _vm.environment.weekEndingDay,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "weekEndingDay",
                                          $$v
                                        )
                                      },
                                      expression: "environment.weekEndingDay"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-tab-item",
                    {
                      key: _vm.inspectionsTab.key,
                      attrs: { value: `tab-${_vm.inspectionsTab.key}` }
                    },
                    [
                      _c(
                        "v-form",
                        {
                          ref: "inspectionsform",
                          on: { submit: _vm.preventSubmit }
                        },
                        [
                          _c(
                            "v-row",
                            { staticClass: "pa-3", attrs: { align: "center" } },
                            [
                              _c(
                                "v-col",
                                { attrs: { align: "center", cols: "12" } },
                                [
                                  _c("v-switch", {
                                    attrs: {
                                      label: _vm.$t(
                                        "configuration.inspections.can-be-created-historically-label"
                                      ),
                                      disabled:
                                        !_vm.$store.state.curEnvironment
                                          .enableScaffoldInspection ||
                                        _vm.processing,
                                      "hide-details": ""
                                    },
                                    model: {
                                      value:
                                        _vm.environment
                                          .scaffoldInspectionCanBeCreatedHistorically,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "scaffoldInspectionCanBeCreatedHistorically",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "environment.scaffoldInspectionCanBeCreatedHistorically"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { attrs: { align: "center", cols: "12" } },
                                [
                                  _c("v-switch", {
                                    attrs: {
                                      label: _vm.$t(
                                        "configuration.inspections.requires-photo-label"
                                      ),
                                      disabled:
                                        !_vm.$store.state.curEnvironment
                                          .enableScaffoldInspection ||
                                        _vm.processing,
                                      "hide-details": ""
                                    },
                                    model: {
                                      value:
                                        _vm.environment
                                          .scaffoldInspectionRequiresPhoto,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "scaffoldInspectionRequiresPhoto",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "environment.scaffoldInspectionRequiresPhoto"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { attrs: { align: "center", cols: "12" } },
                                [
                                  _c("v-switch", {
                                    attrs: {
                                      label: _vm.$t(
                                        "configuration.inspections.requires-all-questions-label"
                                      ),
                                      disabled:
                                        !_vm.$store.state.curEnvironment
                                          .enableScaffoldInspection ||
                                        _vm.processing,
                                      "hide-details": ""
                                    },
                                    model: {
                                      value:
                                        _vm.environment
                                          .scaffoldInspectionRequiresAllQuestions,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "scaffoldInspectionRequiresAllQuestions",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "environment.scaffoldInspectionRequiresAllQuestions"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-subheader", { staticClass: "mt-3" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "configuration.inspections.inspection-times-subheader"
                                )
                              ) + "\n            "
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "v-card-title",
                            [
                              _c("fd-inline-add-button", {
                                attrs: {
                                  disabled:
                                    !_vm.$store.state.curEnvironment
                                      .enableScaffoldInspection ||
                                    !_vm.canAddInspectionTime ||
                                    _vm.processing
                                },
                                on: { click: _vm.addInspectionTime }
                              }),
                              _vm._v(" "),
                              _c("v-divider", {
                                staticClass: "mx-4",
                                attrs: { inset: "", vertical: "" }
                              }),
                              _vm._v(" "),
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { top: "" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "activator",
                                      fn: function({ on }) {
                                        return [
                                          _c(
                                            "v-icon",
                                            _vm._g(
                                              {
                                                staticClass: "ml-1 mr-3",
                                                attrs: {
                                                  color: "black",
                                                  dark: ""
                                                }
                                              },
                                              on
                                            ),
                                            [_vm._v("info")]
                                          )
                                        ]
                                      }
                                    }
                                  ])
                                },
                                [
                                  _vm._v(" "),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "configuration.inspections.table-listing-tooltip"
                                        )
                                      )
                                    )
                                  ])
                                ]
                              ),
                              _vm._v(" "),
                              _c("v-spacer"),
                              _vm._v(" "),
                              !_vm.canAddInspectionTime
                                ? _c("fp-item-disabled-message", {
                                    staticClass: "ml-2",
                                    staticStyle: { "align-content": "center" },
                                    attrs: {
                                      message: _vm.$t(
                                        "configuration.inspections.max-4-inspection-times-message"
                                      )
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-data-table", {
                            directives: [
                              {
                                name: "fd-column",
                                rawName: "v-fd-column:style.no-sort",
                                value: _vm.$t(
                                  "configuration.inspections.style-column-header"
                                ),
                                expression:
                                  "$t('configuration.inspections.style-column-header')",
                                arg: "style",
                                modifiers: { "no-sort": true }
                              },
                              {
                                name: "fd-column",
                                rawName: "v-fd-column:name.no-sort",
                                value: _vm.$t(
                                  "configuration.inspections.segment-name-column-header"
                                ),
                                expression:
                                  "\n                $t('configuration.inspections.segment-name-column-header')\n              ",
                                arg: "name",
                                modifiers: { "no-sort": true }
                              },
                              {
                                name: "fd-column",
                                rawName: "v-fd-column:time.no-sort",
                                value: _vm.$t(
                                  "configuration.inspections.inspection-time-column-header"
                                ),
                                expression:
                                  "\n                $t('configuration.inspections.inspection-time-column-header')\n              ",
                                arg: "time",
                                modifiers: { "no-sort": true }
                              },
                              {
                                name: "fd-column",
                                rawName: "v-fd-column:summary.no-sort",
                                value: _vm.$t(
                                  "configuration.inspections.summary-column-header"
                                ),
                                expression:
                                  "$t('configuration.inspections.summary-column-header')",
                                arg: "summary",
                                modifiers: { "no-sort": true }
                              },
                              {
                                name: "fd-column",
                                rawName:
                                  "v-fd-column:action.no-sort.class_fd-action-cell",
                                arg: "action",
                                modifiers: {
                                  "no-sort": true,
                                  "class_fd-action-cell": true
                                }
                              }
                            ],
                            staticClass: "fd-actions-table",
                            attrs: {
                              items: _vm.inspectionTimes,
                              loading: _vm.processing,
                              "loading-text": _vm.$t(
                                "common.table-loading-message"
                              ),
                              "sort-by": ["time"],
                              "mobile-breakpoint": "0",
                              "no-data-text": _vm.$t(
                                "configuration.inspections.no-inspection-times"
                              ),
                              "items-per-page": -1,
                              "footer-props": {
                                "items-per-page-options": [-1]
                              },
                              "hide-default-footer": ""
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "item.style",
                                fn: function({ item }) {
                                  return [
                                    _c(
                                      "v-row",
                                      { attrs: { "no-gutters": "" } },
                                      [
                                        _c("v-select", {
                                          attrs: {
                                            outlined: "",
                                            items: _vm.selectableStyles(
                                              item.style
                                            ),
                                            dense: "",
                                            "hide-details": "",
                                            clearable: "",
                                            disabled:
                                              !_vm.$store.state.curEnvironment
                                                .enableScaffoldInspection ||
                                              _vm.processing
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "selection",
                                                fn: function({ item: style }) {
                                                  return [
                                                    _c(
                                                      "v-chip",
                                                      {
                                                        staticClass:
                                                          "fd-inspection-time-chip fd-selected-time-segment",
                                                        class: style.value
                                                      },
                                                      [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            staticClass:
                                                              "fd-inspection-time-chip-icon",
                                                            attrs: { small: "" }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                          " +
                                                                _vm._s(
                                                                  _vm.$store.getters.icon(
                                                                    `inspection-time-${style.value}`
                                                                  )
                                                                ) +
                                                                "\n                        "
                                                            )
                                                          ]
                                                        ),
                                                        _vm._v(
                                                          "\n                        " +
                                                            _vm._s(
                                                              !!item.name
                                                                ? item.name
                                                                : _vm.$t(
                                                                    `inspections.times.${style.value}-label`
                                                                  )
                                                            )
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                }
                                              },
                                              {
                                                key: "item",
                                                fn: function({
                                                  item: style,
                                                  on,
                                                  attrs
                                                }) {
                                                  return [
                                                    _c(
                                                      "v-list-item",
                                                      _vm._g(
                                                        _vm._b(
                                                          {},
                                                          "v-list-item",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      ),
                                                      [
                                                        _c(
                                                          "v-list-item-content",
                                                          [
                                                            _c(
                                                              "v-row",
                                                              {
                                                                attrs: {
                                                                  "no-gutters":
                                                                    ""
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "v-chip",
                                                                  {
                                                                    staticClass:
                                                                      "fd-inspection-time-chip",
                                                                    class: {
                                                                      [style.value]: !style.disabled
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      {
                                                                        staticClass:
                                                                          "fd-inspection-time-chip-icon"
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.$store.getters.icon(
                                                                              `inspection-time-${style.value}`
                                                                            )
                                                                          )
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _vm._v(
                                                                      "\n                              " +
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            `inspections.times.${style.value}-label`
                                                                          )
                                                                        )
                                                                    )
                                                                  ],
                                                                  1
                                                                ),
                                                                _vm._v(" "),
                                                                style.disabled
                                                                  ? _c(
                                                                      "fp-item-disabled-message",
                                                                      {
                                                                        staticClass:
                                                                          "ml-2",
                                                                        staticStyle: {
                                                                          "align-content":
                                                                            "center"
                                                                        },
                                                                        attrs: {
                                                                          message: _vm.$t(
                                                                            "configuration.inspections.time-style-already-selected-message"
                                                                          )
                                                                        }
                                                                      }
                                                                    )
                                                                  : _vm._e()
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            true
                                          ),
                                          model: {
                                            value: item.style,
                                            callback: function($$v) {
                                              _vm.$set(item, "style", $$v)
                                            },
                                            expression: "item.style"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                }
                              },
                              {
                                key: "item.name",
                                fn: function({ item }) {
                                  return [
                                    _c("fd-text-field", {
                                      attrs: {
                                        outlined: false,
                                        disabled:
                                          !_vm.$store.state.curEnvironment
                                            .enableScaffoldInspection ||
                                          _vm.processing,
                                        "hide-details": ""
                                      },
                                      model: {
                                        value: item.name,
                                        callback: function($$v) {
                                          _vm.$set(item, "name", $$v)
                                        },
                                        expression: "item.name"
                                      }
                                    })
                                  ]
                                }
                              },
                              {
                                key: "item.time",
                                fn: function({ item }) {
                                  return [
                                    _c("fd-time-picker", {
                                      attrs: {
                                        outlined: false,
                                        value: item.time,
                                        disabled:
                                          !_vm.$store.state.curEnvironment
                                            .enableScaffoldInspection ||
                                          _vm.processing,
                                        "hide-details": ""
                                      },
                                      on: {
                                        "update:value": function($event) {
                                          return _vm.$set(item, "time", $event)
                                        }
                                      }
                                    })
                                  ]
                                }
                              },
                              {
                                key: "item.summary",
                                fn: function({ item }) {
                                  return [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          _vm.getTimeRangeSummaryForTime(item)
                                        ) +
                                        "\n              "
                                    )
                                  ]
                                }
                              },
                              {
                                key: "item.action",
                                fn: function({ item }) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "table-1-action-column-min-width"
                                      },
                                      [
                                        _c(
                                          "v-tooltip",
                                          {
                                            attrs: { top: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function({ on }) {
                                                    return [
                                                      _c(
                                                        "v-btn",
                                                        _vm._g(
                                                          {
                                                            attrs: {
                                                              icon: "",
                                                              disabled:
                                                                !_vm.$store
                                                                  .state
                                                                  .curEnvironment
                                                                  .enableScaffoldInspection ||
                                                                _vm.processing
                                                            }
                                                          },
                                                          on
                                                        ),
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                small: ""
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.removeInspectionTime(
                                                                    item
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                          mdi-delete\n                        "
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          },
                                          [
                                            _vm._v(" "),
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(_vm.$t("common.remove"))
                                              )
                                            ])
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-form",
                { ref: "form", on: { submit: _vm.onSubmit } },
                [
                  _c(
                    "v-row",
                    { staticClass: "mx-2", attrs: { align: "center" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _vm.inlineMessage.message
                            ? _c(
                                "v-alert",
                                {
                                  staticClass: "mr-0",
                                  attrs: { type: _vm.inlineMessage.type }
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: { "white-space": "pre-line" }
                                    },
                                    [_vm._v(_vm._s(_vm.inlineMessage.message))]
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "primary",
                            loading: _vm.saving,
                            disabled:
                              _vm.processing ||
                              !_vm.currentUserCanConfigureSettings
                          },
                          on: { click: _vm.save }
                        },
                        [_vm._v(_vm._s(_vm.$t("common.save")))]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }