var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-form",
    { ref: "lwhform" },
    [
      _c(
        "v-container",
        { staticClass: "pa-0", attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            {
              staticClass: "mx-2 fd-subheader-with-control",
              attrs: { align: "center" }
            },
            [
              _c(
                "v-col",
                {
                  staticClass: "pa-0",
                  attrs: { align: "center", cols: "12", sm: "8" }
                },
                [
                  _c(
                    "v-subheader",
                    {
                      staticStyle: { "font-size": "1em", "margin-top": "10px" }
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "walkdowns.existing-walkdown.estimate-subheader"
                          )
                        )
                      )
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                {
                  staticClass: "pt-1 pb-1",
                  attrs: { align: "center", cols: "12", sm: "4" }
                },
                [
                  _c(
                    "v-row",
                    { staticClass: "pr-3 d-flex justify-sm-end" },
                    [
                      _c("v-switch", {
                        staticClass: "fd-remove-messages pl-3 pl-sm-0",
                        attrs: {
                          label: _vm.$t(
                            "walkdowns.existing-walkdown.hard-barricade"
                          )
                        },
                        model: {
                          value: _vm.scaffoldIsHardBarricade,
                          callback: function($$v) {
                            _vm.scaffoldIsHardBarricade = $$v
                          },
                          expression: "scaffoldIsHardBarricade"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            { staticClass: "mx-2", attrs: { align: "center" } },
            [_c("v-divider", { staticClass: "mx-3 mt-0" })],
            1
          ),
          _vm._v(" "),
          _vm.isModifyRequest
            ? _c(
                "span",
                [
                  _c(
                    "v-row",
                    {
                      staticClass: "mx-2 pl-0 pr-0 pb-0 pt-5",
                      attrs: { align: "center" }
                    },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "justify-space-between pt-1 pb-1",
                          attrs: { align: "center", cols: "12", sm: "3" }
                        },
                        [
                          _c("v-autocomplete", {
                            attrs: {
                              "data-cy": "crewsize",
                              outlined: "",
                              items: _vm.crewSizeOptions,
                              label: _vm.$t(
                                "walkdowns.existing-walkdown.crew-size"
                              ),
                              rules: [_vm.getGreaterThanNumberValueRule(0)]
                            },
                            model: {
                              value: _vm.walkdown.crewSize,
                              callback: function($$v) {
                                _vm.$set(_vm.walkdown, "crewSize", $$v)
                              },
                              expression: "walkdown.crewSize"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        {
                          staticClass: "justify-space-between pt-1 pb-1",
                          attrs: { align: "center", cols: "12", sm: "3" }
                        },
                        [
                          _c("v-autocomplete", {
                            attrs: {
                              outlined: "",
                              items: _vm.workDayOptions,
                              label: _vm.$t(
                                "walkdowns.existing-walkdown.work-days"
                              ),
                              rules: [_vm.getGreaterThanNumberValueRule(0)]
                            },
                            model: {
                              value: _vm.walkdown.workDays,
                              callback: function($$v) {
                                _vm.$set(_vm.walkdown, "workDays", $$v)
                              },
                              expression: "walkdown.workDays"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        {
                          staticClass: "justify-space-between pt-1 pb-1",
                          attrs: { align: "center", cols: "12", sm: "3" }
                        },
                        [
                          _c("fd-duration-picker", {
                            attrs: {
                              label: _vm.$t(
                                "walkdowns.existing-walkdown.modify-man-hours"
                              ),
                              "hour-options": [
                                0,
                                1,
                                2,
                                3,
                                4,
                                5,
                                6,
                                7,
                                8,
                                9,
                                10,
                                11,
                                12,
                                13,
                                14,
                                15
                              ]
                            },
                            model: {
                              value: _vm.workTimePerDay,
                              callback: function($$v) {
                                _vm.workTimePerDay = $$v
                              },
                              expression: "workTimePerDay"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        {
                          staticClass: "justify-space-between pt-1 pb-1",
                          attrs: { align: "center", cols: "12", sm: "3" }
                        },
                        [
                          _c("fd-text-field", {
                            attrs: {
                              label: _vm.$t(
                                "walkdowns.existing-walkdown.modify-total-time"
                              ),
                              readonly: true,
                              value: _vm.walkdown.modificationHours,
                              rules: [_vm.getGreaterThanNumberValueRule(0)]
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _c(
                "span",
                [
                  _c(
                    "v-row",
                    {
                      staticClass: "mx-2 pl-0 pr-0 pb-0 pt-5",
                      attrs: { align: "center" }
                    },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "justify-space-between pt-1 pb-1",
                          attrs: { align: "center", cols: "12", sm: "4" }
                        },
                        [
                          _c("fd-text-field", {
                            attrs: {
                              cy: "length",
                              outlined: "",
                              label: _vm.$t(
                                "walkdowns.existing-walkdown.length"
                              ),
                              "persistent-hint": "",
                              hint: _vm.$t("common.feet-shorthand-hint"),
                              rules: [
                                _vm.rules.required,
                                _vm.getGreaterThanNumberValueRule(0)
                              ]
                            },
                            model: {
                              value: _vm.walkdown.length,
                              callback: function($$v) {
                                _vm.$set(_vm.walkdown, "length", $$v)
                              },
                              expression: "walkdown.length"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      !_vm.scaffoldIsHardBarricade
                        ? _c(
                            "v-col",
                            {
                              staticClass:
                                "justify-space-between pt-1 pb-1 pt-sm-1",
                              attrs: { align: "center", cols: "12", sm: "4" }
                            },
                            [
                              _c("fd-text-field", {
                                attrs: {
                                  cy: "width",
                                  outlined: "",
                                  label: _vm.$t(
                                    "walkdowns.existing-walkdown.width"
                                  ),
                                  disabled: _vm.scaffoldIsHardBarricade,
                                  "persistent-hint": "",
                                  hint: _vm.$t("common.feet-shorthand-hint"),
                                  rules: [
                                    _vm.rules.required,
                                    _vm.getGreaterThanNumberValueRule(0)
                                  ]
                                },
                                model: {
                                  value: _vm.walkdown.width,
                                  callback: function($$v) {
                                    _vm.$set(_vm.walkdown, "width", $$v)
                                  },
                                  expression: "walkdown.width"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.scaffoldIsHardBarricade
                        ? _c(
                            "v-col",
                            {
                              staticClass:
                                "justify-space-between pt-1 pb-1 pt-sm-1",
                              attrs: { align: "center", cols: "12", sm: "4" }
                            },
                            [
                              _c("fd-text-field", {
                                attrs: {
                                  cy: "height",
                                  outlined: "",
                                  label: _vm.$t(
                                    "walkdowns.existing-walkdown.height"
                                  ),
                                  disabled: _vm.scaffoldIsHardBarricade,
                                  "persistent-hint": "",
                                  hint: _vm.$t("common.feet-shorthand-hint"),
                                  rules: _vm.scaffoldIsHardBarricade
                                    ? []
                                    : [
                                        _vm.rules.required,
                                        _vm.getGreaterThanNumberValueRule(0)
                                      ]
                                },
                                model: {
                                  value: _vm.walkdown.height,
                                  callback: function($$v) {
                                    _vm.$set(_vm.walkdown, "height", $$v)
                                  },
                                  expression: "walkdown.height"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        {
                          staticClass: "justify-space-between pt-1 pb-1",
                          attrs: { align: "center", cols: "12", sm: "4" }
                        },
                        [
                          _c("v-select", {
                            attrs: {
                              "data-cy": "baylength",
                              outlined: "",
                              label: _vm.$t(
                                "walkdowns.existing-walkdown.typical-bay-length"
                              ),
                              items: _vm.selectableScaffoldBayLengths,
                              "item-text": "name",
                              "item-value": "id",
                              rules: [_vm.rules.required]
                            },
                            model: {
                              value: _vm.walkdown.scaffoldBayLengthID,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.walkdown,
                                  "scaffoldBayLengthID",
                                  $$v
                                )
                              },
                              expression: "walkdown.scaffoldBayLengthID"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        {
                          staticClass: "justify-space-between pt-1 pb-1",
                          attrs: { align: "center", cols: "12", sm: "4" }
                        },
                        [
                          _c("v-select", {
                            attrs: {
                              "data-cy": "baywidth",
                              outlined: "",
                              label: _vm.$t(
                                "walkdowns.existing-walkdown.typical-bay-width"
                              ),
                              items: _vm.selectableScaffoldBayWidths,
                              "item-text": "name",
                              "item-value": "id",
                              rules: [_vm.rules.required]
                            },
                            model: {
                              value: _vm.walkdown.scaffoldBayWidthID,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.walkdown,
                                  "scaffoldBayWidthID",
                                  $$v
                                )
                              },
                              expression: "walkdown.scaffoldBayWidthID"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      !_vm.scaffoldIsHardBarricade
                        ? _c(
                            "v-col",
                            {
                              staticClass: "justify-space-between pt-1 pb-1",
                              attrs: { align: "center", cols: "12", sm: "4" }
                            },
                            [
                              _c("v-select", {
                                attrs: {
                                  "data-cy": "bayheight",
                                  outlined: "",
                                  label: _vm.$t(
                                    "walkdowns.existing-walkdown.typical-bay-height"
                                  ),
                                  disabled: _vm.scaffoldIsHardBarricade,
                                  items: _vm.selectableScaffoldBayHeights,
                                  "item-text": "name",
                                  "item-value": "id",
                                  rules: _vm.scaffoldIsHardBarricade
                                    ? []
                                    : [_vm.rules.required]
                                },
                                model: {
                                  value: _vm.walkdown.scaffoldBayHeightID,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.walkdown,
                                      "scaffoldBayHeightID",
                                      $$v
                                    )
                                  },
                                  expression: "walkdown.scaffoldBayHeightID"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        {
                          staticClass: "justify-space-between pt-1 pb-1",
                          attrs: { align: "center", cols: "12", sm: "4" }
                        },
                        [
                          _c("fd-text-field", {
                            attrs: {
                              label: _vm.$t(
                                "walkdowns.existing-walkdown.number-of-length-bays"
                              ),
                              value: _vm.numberOfLengthBays,
                              disabled: "",
                              readonly: true
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      !_vm.scaffoldIsHardBarricade
                        ? _c(
                            "v-col",
                            {
                              staticClass: "justify-space-between pt-1 pb-1",
                              attrs: { align: "center", cols: "12", sm: "4" }
                            },
                            [
                              _c("fd-text-field", {
                                attrs: {
                                  label: _vm.$t(
                                    "walkdowns.existing-walkdown.number-of-width-bays"
                                  ),
                                  value: _vm.numberOfWidthBays,
                                  disabled: "",
                                  readonly: true
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.scaffoldIsHardBarricade
                        ? _c(
                            "v-col",
                            {
                              staticClass: "justify-space-between pt-1 pb-1",
                              attrs: { align: "center", cols: "12", sm: "4" }
                            },
                            [
                              _c("fd-text-field", {
                                attrs: {
                                  cy: "decklevels",
                                  label: _vm.$t(
                                    "walkdowns.existing-walkdown.deck-levels"
                                  ),
                                  disabled: _vm.scaffoldIsHardBarricade,
                                  rules: [_vm.rules.numeric]
                                },
                                model: {
                                  value: _vm.walkdown.deckLevels,
                                  callback: function($$v) {
                                    _vm.$set(_vm.walkdown, "deckLevels", $$v)
                                  },
                                  expression: "walkdown.deckLevels"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.scaffoldIsHardBarricade
                        ? _c(
                            "v-col",
                            {
                              staticClass: "justify-space-between pt-1 pb-1",
                              attrs: { align: "center", cols: "12", sm: "4" }
                            },
                            [
                              _c("v-select", {
                                attrs: {
                                  "data-cy": "accesstype",
                                  outlined: "",
                                  label: _vm.$t(
                                    "walkdowns.existing-walkdown.access-type"
                                  ),
                                  disabled: _vm.scaffoldIsHardBarricade,
                                  items: _vm.accessTypes,
                                  "item-text": "name",
                                  "item-value": "id"
                                },
                                model: {
                                  value: _vm.walkdown.accessType,
                                  callback: function($$v) {
                                    _vm.$set(_vm.walkdown, "accessType", $$v)
                                  },
                                  expression: "walkdown.accessType"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.scaffoldIsHardBarricade
                        ? _c(
                            "v-col",
                            {
                              staticClass: "justify-space-between pt-1 pb-1",
                              attrs: { align: "center", cols: "12", sm: "4" }
                            },
                            [
                              _c("fd-text-field", {
                                attrs: {
                                  label: _vm.$t(
                                    "walkdowns.existing-walkdown.barricade-gates"
                                  ),
                                  disabled: !_vm.scaffoldIsHardBarricade,
                                  rules: [_vm.rules.numeric]
                                },
                                model: {
                                  value: _vm.walkdown.barricadeGates,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.walkdown,
                                      "barricadeGates",
                                      $$v
                                    )
                                  },
                                  expression: "walkdown.barricadeGates"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        {
                          staticClass: "justify-space-between pt-1 pb-1",
                          attrs: { align: "center", cols: "12", sm: "4" }
                        },
                        [
                          _c("v-select", {
                            attrs: {
                              "data-cy": "crewsize",
                              outlined: "",
                              items: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
                              label: _vm.$t(
                                "walkdowns.existing-walkdown.crew-size"
                              ),
                              rules: [
                                _vm.rules.required,
                                _vm.getGreaterThanNumberValueRule(0)
                              ]
                            },
                            model: {
                              value: _vm.walkdown.crewSize,
                              callback: function($$v) {
                                _vm.$set(_vm.walkdown, "crewSize", $$v)
                              },
                              expression: "walkdown.crewSize"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }