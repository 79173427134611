var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      attrs: { width: "800px" },
      model: {
        value: _vm.twoWayProps.show,
        callback: function($$v) {
          _vm.$set(_vm.twoWayProps, "show", $$v)
        },
        expression: "twoWayProps.show"
      }
    },
    [
      _c(
        "v-form",
        { ref: "form", on: { submit: _vm.onSubmit } },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                {
                  class: [
                    _vm.processing
                      ? "dialog-new-top-color-processing"
                      : "dialog-new-top-color"
                  ]
                },
                [
                  _c(
                    "span",
                    {
                      class: [
                        _vm.processing ? "breadcrumb-processing-opacity" : ""
                      ]
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t("inspections.history-dialog-title", [
                            _vm.tagNumber
                          ])
                        )
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _vm.processing
                    ? _c("v-progress-circular", {
                        attrs: {
                          indeterminate: true,
                          rotate: 0,
                          size: 32,
                          width: 4,
                          color: "white"
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-title",
                [
                  _c(
                    "v-row",
                    {
                      attrs: {
                        "no-gutters": "",
                        align: "center",
                        justify: "center"
                      }
                    },
                    [
                      _vm.$vuetify.breakpoint.lgAndUp
                        ? _c(
                            "v-menu",
                            {
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function({ on }) {
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            {
                                              staticClass: "mr-3",
                                              staticStyle: {
                                                "min-width": "110px"
                                              },
                                              attrs: {
                                                small: "",
                                                rounded: "",
                                                outlined: "",
                                                disabled: _vm.processing,
                                                color: "#7a7a7a"
                                              }
                                            },
                                            on
                                          ),
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                attrs: {
                                                  small: "",
                                                  left:
                                                    _vm.$vuetify.breakpoint
                                                      .smAndUp
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                  mdi-printer\n                "
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _vm.$vuetify.breakpoint.smAndUp
                                              ? _c("span", [
                                                  _vm._v(
                                                    "\n                  " +
                                                      _vm._s(
                                                        _vm.$t("common.print")
                                                      ) +
                                                      "\n                "
                                                  )
                                                ])
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _c(
                                              "v-icon",
                                              {
                                                staticStyle: {
                                                  "margin-left":
                                                    "2px !important"
                                                },
                                                attrs: { right: "" }
                                              },
                                              [_vm._v("mdi-menu-down")]
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                3478291602
                              )
                            },
                            [
                              _vm._v(" "),
                              _c(
                                "v-list",
                                { attrs: { subheader: "" } },
                                [
                                  _c(
                                    "v-subheader",
                                    {
                                      staticClass: "fd-drop-down-button-badges"
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.$t(
                                              "timesheets.summary.printing.all-timesheet-rows"
                                            )
                                          ) +
                                          "\n              "
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-list-item-group",
                                    { staticClass: "fd-drop-down-item-group" },
                                    [
                                      _c(
                                        "v-list-item",
                                        {
                                          on: {
                                            click: function($event) {
                                              return _vm.downloadAndPrintReport(
                                                "pdf"
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.$t(
                                                  "timesheets.summary.printing.details"
                                                )
                                              ) +
                                              "\n                  " +
                                              _vm._s(
                                                _vm.$t(
                                                  "timesheets.summary.printing.pdf"
                                                )
                                              ) +
                                              "\n                "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-list-item",
                                        {
                                          on: {
                                            click: function($event) {
                                              return _vm.downloadAndPrintReport(
                                                "xls"
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.$t(
                                                  "timesheets.summary.printing.details"
                                                )
                                              ) +
                                              "\n                  " +
                                              _vm._s(
                                                _vm.$t(
                                                  "timesheets.summary.printing.excel"
                                                )
                                              ) +
                                              "\n                "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { staticClass: "grow" },
                        [
                          _c(
                            "v-row",
                            { staticClass: "fd-inspection-history-dialogue" },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", sm: "6" } },
                                [
                                  _c("fd-date-range-picker", {
                                    staticClass: "pt-3",
                                    staticStyle: { "margin-top": "6px" },
                                    attrs: {
                                      dense: "",
                                      min: _vm.minDate,
                                      max: _vm.maxDate,
                                      loading: _vm.processing,
                                      disabled: _vm.processing,
                                      "hide-details": "",
                                      outlined: false,
                                      presets: _vm.dateRangePresetOptions
                                    },
                                    on: {
                                      "input:fromDate": _vm.fromDateChanged,
                                      "input:toDate": _vm.toDateChanged
                                    },
                                    model: {
                                      value: _vm.showArchivedDateRange,
                                      callback: function($$v) {
                                        _vm.showArchivedDateRange = $$v
                                      },
                                      expression: "showArchivedDateRange"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", sm: "6" } },
                                [
                                  _c("v-text-field", {
                                    staticClass: "pl-0 mt-1",
                                    attrs: {
                                      "append-icon": "search",
                                      label: _vm.$t("common.search"),
                                      "single-line": "",
                                      "hide-details": "",
                                      clearable: ""
                                    },
                                    model: {
                                      value: _vm.inspectionstablesearch,
                                      callback: function($$v) {
                                        _vm.inspectionstablesearch = $$v
                                      },
                                      expression: "inspectionstablesearch"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("v-data-table", {
                directives: [
                  {
                    name: "fd-column",
                    rawName:
                      "v-fd-column:empty.no-sort.class_fd-table-icon-cell",
                    value: "",
                    expression: "''",
                    arg: "empty",
                    modifiers: {
                      "no-sort": true,
                      "class_fd-table-icon-cell": true
                    }
                  },
                  {
                    name: "fd-column",
                    rawName: "v-fd-column:timeSegmentName",
                    value: _vm.$t("inspections.inspection-segment-label"),
                    expression: "$t('inspections.inspection-segment-label')",
                    arg: "timeSegmentName"
                  },
                  {
                    name: "fd-column",
                    rawName:
                      "v-fd-column:inspectionDateTime.hide-when-extra-small",
                    value: _vm.$t("inspections.inspection-date-time-label"),
                    expression:
                      "\n          $t('inspections.inspection-date-time-label')\n        ",
                    arg: "inspectionDateTime",
                    modifiers: { "hide-when-extra-small": true }
                  },
                  {
                    name: "fd-column",
                    rawName: "v-fd-column:inspectedBy.hide-when-medium",
                    value: _vm.$t("inspections.inspected-by-label"),
                    expression: "$t('inspections.inspected-by-label')",
                    arg: "inspectedBy",
                    modifiers: { "hide-when-medium": true }
                  },
                  {
                    name: "fd-column",
                    rawName: "v-fd-column:inspectionDidPass",
                    value: _vm.$t("inspections.inspection-result-label"),
                    expression: "$t('inspections.inspection-result-label')",
                    arg: "inspectionDidPass"
                  },
                  {
                    name: "fd-column",
                    rawName: "v-fd-column:action.no-sort.class_fd-action-cell",
                    arg: "action",
                    modifiers: { "no-sort": true, "class_fd-action-cell": true }
                  }
                ],
                ref: "datatable",
                class: ["fd-inspections-list"],
                attrs: {
                  "data-cy": "standinginspectionslist",
                  items: _vm.inspections,
                  search: _vm.inspectionstablesearch,
                  loading: _vm.processing,
                  "loading-text": _vm.$t("common.table-loading-message"),
                  "mobile-breakpoint": "0",
                  "sort-by": ["inspectionDateTime"],
                  "sort-desc": true,
                  "group-by": "inspectionDay",
                  "group-desc": "",
                  "items-per-page": -1,
                  "footer-props": { "items-per-page-options": [-1] },
                  "hide-default-footer": ""
                },
                scopedSlots: _vm._u([
                  {
                    key: "header.empty",
                    fn: function() {
                      return [
                        !!_vm.inspections && _vm.inspections.length > 0
                          ? _c(
                              "div",
                              [
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: {
                                      disabled:
                                        !_vm.$vuetify.breakpoint.lgAndUp ||
                                        _vm.processing,
                                      left: ""
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function({ on }) {
                                            return [
                                              _c(
                                                "v-btn",
                                                _vm._g(
                                                  {
                                                    attrs: {
                                                      icon: "",
                                                      tile: "",
                                                      color: "primary"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        _vm.toggleTableGroups(
                                                          _vm.allGroupsExpanded()
                                                        )
                                                      }
                                                    }
                                                  },
                                                  on
                                                ),
                                                [
                                                  _c("v-icon", {
                                                    domProps: {
                                                      textContent: _vm._s(
                                                        !_vm.allGroupsExpanded()
                                                          ? "mdi-plus-box-multiple-outline"
                                                          : "mdi-minus-box-multiple-outline"
                                                      )
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      3792361227
                                    )
                                  },
                                  [
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          !_vm.allGroupsExpanded("workOrder")
                                            ? _vm.$t("common.expand-all")
                                            : _vm.$t("common.collapse-all")
                                        )
                                      )
                                    ])
                                  ]
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ]
                    },
                    proxy: true
                  },
                  {
                    key: "group.header",
                    fn: function({ group, isOpen, toggle }) {
                      return [
                        _c(
                          "td",
                          { attrs: { colspan: "99" } },
                          [
                            _c(
                              "v-row",
                              {
                                attrs: { "no-gutters": "", align: "center" },
                                on: { click: toggle }
                              },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    ref: `grouptoggle${group}`,
                                    attrs: { icon: "" },
                                    on: {
                                      click: e => {
                                        e.stopImmediatePropagation()
                                        toggle(e)
                                      }
                                    }
                                  },
                                  [
                                    _c("v-icon", [
                                      _vm._v(
                                        _vm._s(
                                          isOpen ? "mdi-minus" : "mdi-plus"
                                        )
                                      )
                                    ])
                                  ],
                                  1
                                ),
                                _vm._v(
                                  "\n              " +
                                    _vm._s(group) +
                                    "\n            "
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    }
                  },
                  {
                    key: "item.fd-nav",
                    fn: function() {
                      return [_c("v-icon", [_vm._v("mdi-chevron-right")])]
                    },
                    proxy: true
                  },
                  {
                    key: "item.inspectionDateTime",
                    fn: function({ item }) {
                      return [
                        _c(
                          "v-row",
                          {
                            staticStyle: { "flex-wrap": "nowrap !important" },
                            attrs: { "no-gutters": "" }
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$format.datetime(item.inspectionDateTime)
                                ) +
                                "\n            "
                            ),
                            _c(
                              "v-tooltip",
                              {
                                attrs: { top: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function({ on }) {
                                        return [
                                          _c(
                                            "v-icon",
                                            _vm._g(
                                              {
                                                staticClass: "ml-1",
                                                class: {
                                                  "d-none": !_vm.inspectionCreatedAtDifferentTime(
                                                    item
                                                  )
                                                },
                                                attrs: {
                                                  small: "",
                                                  color: "black",
                                                  dark: ""
                                                }
                                              },
                                              on
                                            ),
                                            [_vm._v("info")]
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t(
                                        "inspections.created-on-different-day-message",
                                        [_vm.$format.datetime(item.created)]
                                      )
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ]
                    }
                  },
                  {
                    key: "item.inspectedBy",
                    fn: function({ item }) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$lookup.person(item.inspectedBy)) +
                            "\n        "
                        )
                      ]
                    }
                  },
                  {
                    key: "item.inspectionDidPass",
                    fn: function({ item }) {
                      return [
                        _c("fd-checkbox", {
                          attrs: {
                            value: item.inspectionDidPass,
                            readonly: true,
                            "on-text": _vm.$t(
                              "inspections.inspection-result-pass-label"
                            ),
                            "on-color": "fd-inspection-pass",
                            "off-text": _vm.$t(
                              "inspections.inspection-result-fail-label"
                            ),
                            "off-color": "fd-inspection-fail"
                          }
                        })
                      ]
                    }
                  },
                  {
                    key: "item.action",
                    fn: function({ item }) {
                      return [
                        _c(
                          "div",
                          { staticClass: "table-1-action-column-min-width" },
                          [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { top: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function({ on }) {
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              {
                                                attrs: {
                                                  icon: "",
                                                  link: "",
                                                  disabled: _vm.processing
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.inspectionClicked(
                                                      item
                                                    )
                                                  }
                                                }
                                              },
                                              on
                                            ),
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { small: "" } },
                                                [
                                                  _vm._v(
                                                    "\n                    mdi-eye\n                  "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              },
                              [
                                _vm._v(" "),
                                _c("span", [
                                  _vm._v(_vm._s(_vm.$t("common.view")))
                                ])
                              ]
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _vm.inlineMessage.message
                ? _c(
                    "v-row",
                    { staticClass: "mx-2", attrs: { align: "center" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _vm.inlineMessage.message
                            ? _c(
                                "v-alert",
                                { attrs: { type: _vm.inlineMessage.type } },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: { "white-space": "pre-line" }
                                    },
                                    [_vm._v(_vm._s(_vm.inlineMessage.message))]
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        text: "",
                        small: _vm.$vuetify.breakpoint.xsOnly
                      },
                      on: { click: _vm.cancelDialog }
                    },
                    [_vm._v(_vm._s(_vm.$t("common.close")))]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }