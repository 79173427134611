import Vue from "vue";
import { mapMutations, mapActions } from "vuex";

import { FDColumnDirective, FDRowNavigateDirective } from "@fd/lib/vue/utility/dataTable";
import { createNewEstimate } from "./components/EstimateNewDialog.vue";

export default Vue.extend({
  name: "fd-Estimates",

  components: {
    "fd-estimate-new-dialog": () => import("./components/EstimateNewDialog.vue")
  },

  directives: {
    fdColumn: FDColumnDirective,
    fdRowNavigate: FDRowNavigateDirective
  },

  data: () => ({
    // The following is responsible for the inline messages that may be presented to the user.
    inlineMessage: {
      message: null as string | null,
      type: "error"
    },

    // The following will control whether the controls on screen are disabled while we are processing.
    processing: false,

    // The following is used to define the breadcrumbs navigation for the view.
    breadcrumbs: [
      {
        text: "Estimates",
        disabled: true
      }
    ],

    // These objects are used for the view specific "Tags" filtering.
    tagsSelectedForFiltering: [],

    // These objects are used for the view specific "Clients" filtering.
    // clientsSelectedForFiltering: [],

    // These objects are used for the view specific "Clients" filtering.
    projectsSelectedForFiltering: [],

    // Datatable related objects
    tablesearch: "",
    tableData: [
      {
        id: "1",
        name: "Baxter Wilson Chemical Boiler - A",
        clientalias: "Turner",
        project: "POTBA",
        tagnumber: "3092",
        createdby: "TJK",
        validfrom: "July 20, 2019",
        unresolvedparts: false,
        enabled: true
      },
      {
        id: "2",
        name: "Baxter Wilson Chemical Boiler - B",
        clientalias: "Turner",
        project: "POTBA",
        tagnumber: "3093",
        createdby: "TJK",
        validfrom: "July 27, 2019",
        unresolvedparts: false,
        enabled: true
      },
      {
        id: "3",
        name: "Baxter Wilson Chemical Boiler - C",
        clientalias: "Turner",
        project: "POTBA",
        tagnumber: "3094",
        createdby: "TJK",
        validfrom: "July 29, 2019",
        unresolvedparts: true,
        enabled: true
      },
      {
        id: "4",
        name: "Baxter Wilson Chemical Boiler - D",
        clientalias: "Turner",
        project: "POTBA",
        tagnumber: "3095",
        createdby: "TJK",
        validfrom: "August, 4, 2019",
        unresolvedparts: false,
        enabled: true
      }
    ]
  }),

  methods: {
    async openNewDialog() {
      await createNewEstimate();
    },

    // the following works with the delete "Action" button in the Datatable.
    async deleteTableItem(item: any) {
      this.inlineMessage.message = null;
      this.processing = true;
      try {
        await this.deleteEstimate({ id: item.id, name: item.name });
      } catch (error) {
        this.inlineMessage.message = this.$t("unexpected-network-error") as string;
        this.inlineMessage.type = "error";
      } finally {
        this.processing = false;
      }
    },
    async flipEnabled(item: any) {
      this.inlineMessage.message = null;
      this.processing = true;
      try {
        await this.updateEstimate({ id: item.id, enabled: !item.enabled, name: item.name });
      } catch (error) {
        this.inlineMessage.message = this.$t("unexpected-network-error") as string;
        this.inlineMessage.type = "error";
      } finally {
        this.processing = false;
      }
    },
    ...mapMutations({
      notifyNewBreadcrumb: "NOTIFY_NEW_BREADCRUMB"
    }),
    ...mapActions({
      loadEstimates: "LOAD_ESTIMATES",
      updateEstimate: "UPDATE_ESTIMATE",
      deleteEstimate: "DELETE_ESTIMATE"
    })
  },

  created: async function() {
    this.notifyNewBreadcrumb({
      text: "Estimates",
      to: "/estimates",
      resetHistory: true
    });
    this.processing = true;
    try {
      await this.loadEstimates();
    } catch (error) {
      this.inlineMessage.message = this.$t("unexpected-network-error") as string;
      this.inlineMessage.type = "error";
    } finally {
      this.processing = false;
    }
  }
});
