import FDItemList from "@fd/lib/vue/components/FDItemList";
import { mapActions } from "vuex";
import { createNewWorkPackageDialog } from "./components/WorkPackageNewDialog.vue";
import { WorkPackage, workPackageService } from "../services";
import * as DateUtil from "@fd/lib/client-util/datetime";
import store from "../store";
import { LoadWorkPackagesWithStatusesParameter } from "../store/workpackages";

export default FDItemList.extend({
  name: "fd-work-packages-list",

  mixins: [],

  components: {},

  data: () => ({
    // Overrides from FDItemList
    listRouteUrl: "iwps",
    title: "IWPs",
    defaultStatusesForFiltering: ["Initialized", "Issued", "Not Started", "In Progress"],
    suppressInitialDataLoad: true,
    // Table Footer page size options
    itemsPerPageOptions: [5, 10, 15, 50],
    statusItems: [] as string[]
  }),

  computed: {
    statusesForFiltering: {
      get(): string[] {
        var statusesForFiltering = this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )?.statusesForFiltering;
        return statusesForFiltering ?? [];
      },
      set(val: string[]) {
        this.$store.commit("SET_STATUSES_FOR_FILTERING", val);
        this.refreshItemList();
      }
    },
    items() {
      let workPackages: WorkPackage[] = this.$store.state.workPackages.fullList.map(
        (x: WorkPackage) => {
          return {
            ...x,
            archived: !!x.archivedDate
          };
        }
      );
      let formattedWorkPackages = workPackages.map(function(x) {
        return {
          ...x,
          formattedStartDate: DateUtil.stripTimeFromLocalizedDateTime(x.startDate),
          formattedFinishDate: DateUtil.stripTimeFromLocalizedDateTime(x.finishDate)
        };
      });
      return formattedWorkPackages;
    }
  },

  methods: {
    async openNewDialog() {
      this.optOutOfErrorHandling();
      let result = await createNewWorkPackageDialog();
      console.log(`dialog result: ${result}`);
      if (!result) return;

      // Loading the statuses will update them in the store, which automatically triggers a refresh.  No need to load items.
      this.loadWorkPackageStatuses();
    },

    async reloadTableData() {
      this.processing = true;
      try {
        await this.refreshItemList();
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },

    async refreshItemList() {
      try {
        await this.loadWorkPackages({
          forcedArchivedState: this.showArchived,
          archivedFromDate: this.showArchivedFromDate,
          archivedToDate: this.showArchivedToDate,
          statuses: this.statusesForFiltering
        } as LoadWorkPackagesWithStatusesParameter);
      } catch (error) {
        this.handleError(error as Error);
      }
    },

    async deleteItem(workPackage: WorkPackage) {
      try {
        await store.dispatch("DELETE_WORK_PACKAGE", workPackage);
      } catch (error) {
        this.handleError(error as Error);
      }
    },

    async flipArchived(item: any) {
      this.inlineMessage.message = null;
      this.processing = true;
      try {
        // We want to use the opposite value for archived, since we're flipping it
        var archivedDate = item.archived ? null : new Date(new Date().toUTCString());
        await this.updateWorkPackage({ ...item, archivedDate: archivedDate });
        if (this.displayedItems.find(x => x.id == item.id))
          this.displayedItems.find(x => x.id == item.id)!.archived = !!archivedDate;
      } catch (error) {
        this.handleError(error as Error, "workpackages.save-network-error");
      } finally {
        this.processing = false;
      }
    },

    filterByStatuses(items: WorkPackage[]): WorkPackage[] {
      if (this.statusesForFiltering != null && this.statusesForFiltering.length) {
        let returnValueItems = items.filter(x => {
          if (!x.workStatus) return false;
          for (let status of this.statusesForFiltering) {
            if (x.workStatus == status) {
              return true;
            }
          }
          return false;
        });
        return returnValueItems;
      } else {
        return items;
      }
    },

    async loadWorkPackageStatuses() {
      this.processing = true;
      try {
        this.statusItems = await workPackageService.getUsedWorkStatuses();

        this.statusesForFiltering = this.statusesForFiltering.filter(x => {
          return this.statusItems.includes(x);
        });

        // Loading the statuses will update them in the store, which automatically triggers a refresh.  No need to load items.
      } catch (error) {
        this.handleError(error as Error);
        console.log(
          `loadWorkPackageStatuses inlineMessage.message = ${this.inlineMessage.message}`
        );
      } finally {
        this.processing = false;
      }
    },

    ...mapActions({
      loadWorkPackages: "LOAD_WORK_PACKAGES_WITH_STATUSES",
      updateWorkPackage: "UPDATE_WORK_PACKAGE"
    })
  },

  created: async function() {
    // Loading the statuses will update them in the store, which automatically triggers a refresh.  No need to load items.
    await this.loadWorkPackageStatuses();
  }
});

