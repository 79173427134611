import FDVue from "@fd/lib/vue";
import { mapActions, mapMutations } from "vuex";
import serviceErrorHandling from "@fd/lib/vue/mixins/serviceErrorHandling";
import {
  Classification,
  ContractorWithTags,
  CrewWithEmployees,
  EmployeeTimeSummary,
  personService,
  PersonWithDetails,
  TimesheetEntryWithDetails,
  timesheetService,
  TimesheetStatus,
  TimesheetType,
  WorkSubType,
  WorkType
} from "../services";
import { SortTimesheetRows, UpdatableTimesheetWithTimesheetRows } from "../utils/timesheet";
import { stripTimeFromLocalizedDateTime } from "@fd/lib/client-util/datetime";
import { showTimesheetStatusHistoryDialog } from "./components/dialogs/TimesheetStatusHistoryDialog.vue";
import { GetPersonName, SortItemsWithName } from "../utils/person";
import { showItemSelectionDialog } from "./components/ItemSelectionDialog.vue";
import { createNewCrew, updateExistingCrew } from "./components/dialogs/CrewDetailsDialog.vue";
import userAccess from "../dataMixins/userAccess";
import rules from "@fd/lib/vue/rules";
import { showTimesheetEditDateDialog } from "./components/dialogs/TimesheetEditDateDialog.vue";
import { showAdditionalDetailsDialog } from "../../../common/client/views/components/AdditionalDetailsDialog.vue";

export default FDVue.extend({
  name: "fd-foreman-timesheet-existing",
  mixins: [serviceErrorHandling, userAccess, rules],
  components: {
    "fd-foreman-timesheet-form": () => import("./components/forms/ForemanTimesheetForm.vue")
  },
  data: function() {
    return {
      makeCorrections: false,
      loadAsCorrection: false,
      slidein: false,
      isReadonly: true,
      timeSummaries: [] as EmployeeTimeSummary[],
      timesheet: {} as UpdatableTimesheetWithTimesheetRows,
      submitting: false,
      cancelling: false,

      ownerHasTimesheetOnDay: false
    };
  },
  computed: {
    allWorkSubTypes(): WorkSubType[] {
      return (this.$store.state.workSubTypes.fullList as WorkSubType[]).filter(
        x => !!this.workSubTypeIsConfiguredCorrectly(x)
      );
    },
    canMakeCorrections(): boolean {
      return (
        this.timesheet.isLocked &&
        this.timesheet.timesheetStatusID == TimesheetStatus.Approved &&
        this.currentUserCanCorrectApprovedTimesheets
      );
    },
    contractor(): ContractorWithTags | undefined {
      let allContractors = this.$store.state.contractors.fullList as ContractorWithTags[];
      let contractor = allContractors.find(x => x.id == this.timesheet?.contractorID);
      return contractor;
    },
    perDiemSubType(): WorkSubType | undefined {
      if (!this.contractor?.employeesReceivePerDiem) return undefined;
      if (this.timesheetIsEquipment) return undefined;

      let perDiemTypeID = (this.$store.state.workTypes.fullList as WorkType[]).find(
        x => !!x.isPerDiem
      )?.id;
      let perDiemSubType = this.allWorkSubTypes.find(x => x.workTypeID == perDiemTypeID);
      return perDiemSubType;
    },
    equipmentSubType(): WorkSubType | undefined {
      if (!this.timesheetIsEquipment) return undefined;

      let equipmentTypeID = (this.$store.state.workTypes.fullList as WorkType[]).find(
        x => !!x.isEquipment
      )?.id;
      let equipmentSubType = this.allWorkSubTypes.find(x => x.workTypeID == equipmentTypeID);
      console.log(`Equipment Sub Type: ${equipmentSubType}`);
      return equipmentSubType;
    },
    formattedDay(): string {
      return stripTimeFromLocalizedDateTime(this.timesheet.day);
    },
    includeEmptyEntriesOnSave(): boolean {
      return !this.loadAsCorrection && !this.makeCorrections;
    },
    canSave(): boolean {
      if (!this.timesheet) return false;

      let allPeople = this.$store.state.users.fullList as PersonWithDetails[];
      return this.timesheet.checkIsDirty(
        this.perDiemSubType,
        this.equipmentSubType,
        this.allWorkSubTypes,
        allPeople,
        this.includeEmptyEntriesOnSave
      );
    },
    timesheetIsEquipment(): boolean {
      return (this.timesheet?.timesheetTypeID ?? 0) == TimesheetType.Equipment;
    },
    timesheetIsSubmitted(): boolean {
      return this.timesheet.timesheetStatusID == TimesheetStatus.Submitted;
    },
    timesheetIsApproved(): boolean {
      return this.timesheet.timesheetStatusID == TimesheetStatus.Approved;
    },
    timesheetIsCancelled(): boolean {
      return this.timesheet.timesheetStatusID == TimesheetStatus.Cancelled;
    },
    timesheetCanBeSubmitted(): boolean {
      return (
        (this.timesheet.timesheetStatusID == TimesheetStatus.New ||
          this.timesheet.timesheetStatusID == TimesheetStatus.Declined) &&
        this.timesheet.currentUserPermissions.canSubmit
      );
    },
    timesheetCanBeCancelled(): boolean {
      return (
        (this.timesheet.timesheetStatusID == TimesheetStatus.New ||
          this.timesheet.timesheetStatusID == TimesheetStatus.Declined) &&
        this.timesheet.currentUserPermissions.canCancel
      );
    }
  },
  watch: {
    timesheet(newValue) {
      if (this.loadAsCorrection) {
        if ((this.$store.state.lastBreadcrumbs[0]?.to || "") != "/timesheetcorrections") {
          this.notifyNewBreadcrumb({
            text: this.$t("timesheets.corrections.title"),
            to: "/timesheetcorrections",
            resetHistory: true
          });
          // This is needed in order to salvage the "last breadcrumbs" in the store.
          this.$store.commit("NOTIFY_NAVIGATION_STARTED");
        }
        let timesheetNumberString = `00000${this.timesheet.timesheetNumber}`.slice(-5);
        this.notifyNewBreadcrumb({
          text: !!this.timesheet.id
            ? this.$t("timesheets.existing.title", [
                this.timesheet.ownerName,
                this.formattedDay,
                timesheetNumberString
              ])
            : this.$t("timesheets.existing.new-timesheet"),
          to: `/timesheetcorrections/${this.$route.params.id}`
        });
      } else {
        if ((this.$store.state.lastBreadcrumbs[0]?.to || "") != "/foremantimesheets") {
          this.notifyNewBreadcrumb({
            text: this.$t("timesheets.list.title"),
            to: "/foremantimesheets",
            resetHistory: true
          });
          // This is needed in order to salvage the "last breadcrumbs" in the store.
          this.$store.commit("NOTIFY_NAVIGATION_STARTED");
        }
        let timesheetNumberString = `00000${this.timesheet.timesheetNumber}`.slice(-5);
        this.notifyNewBreadcrumb({
          text: !!this.timesheet.id
            ? this.$t("timesheets.existing.title", [
                this.timesheet.ownerName,
                this.formattedDay,
                timesheetNumberString
              ])
            : this.$t("timesheets.existing.new-timesheet"),
          to: `/foremantimesheets/${this.$route.params.id}`
        });
      }
    }
  },
  methods: {
    ...mapActions({
      loadContractors: "LOAD_CONTRACTORS",
      loadWorkTypes: "LOAD_WORK_TYPES",
      loadWorkSubTypes: "LOAD_WORK_SUB_TYPES"
    }),
    ...mapMutations({
      notifyNewBreadcrumb: "NOTIFY_NEW_BREADCRUMB",
      setFilteringContext: "SET_FILTERING_CONTEXT"
    }),
    async loadTimesheetSummaries() {
      this.timeSummaries = await timesheetService.getEmployeeTimeSummariesForTimesheetWithID(
        this.$route.params.id,
        true,
        null,
        true
      );
    },
    async rowsAdded() {
      if (!this.timesheet.day || !this.timesheet.id) return;

      let employeeIDs = [...new Set(this.timesheet.timesheetRows?.map(x => x.employeeID))];
      if (!!employeeIDs?.length) {
        this.timeSummaries = await timesheetService.getEmployeeTimeSummariesForEmployeesOnDay(
          employeeIDs,
          this.timesheet.day,
          true,
          this.timesheet.id,
          null
        );
      }
    },
    async loadTimesheet() {
      let timesheet = await timesheetService.getByID(this.$route.params.id);

      let entries = [] as TimesheetEntryWithDetails[];
      if (!!timesheet.id) {
        entries = await timesheetService.getEntriesForTimesheetID(timesheet.id);
      }

      let allWorkTypes = this.$store.state.workTypes.fullList as WorkType[];
      let timesheetWithEntries = new UpdatableTimesheetWithTimesheetRows(
        timesheet,
        entries,
        allWorkTypes,
        this.allWorkSubTypes
      );
      timesheetWithEntries.timesheetRows = SortTimesheetRows(timesheetWithEntries.timesheetRows);

      this.timesheet = timesheetWithEntries;
      this.isReadonly =
        this.timesheet.isLocked ||
        (!this.currentUserCanCreateIndirectTimesheets &&
          (this.timesheet.timesheetTypeID == TimesheetType.Indirect ||
            this.timesheet.timesheetTypeID == TimesheetType.Equipment));
    },

    cancel() {
      if (this.loadAsCorrection) this.$router.push("/timesheetcorrections");
      else this.$router.push("/foremantimesheets");
    },
    async showStatusLogDialog() {
      await showTimesheetStatusHistoryDialog(this.timesheet?.statusLogs ?? []);
    },

    // *** CREWS ***
    async addCrew() {
      if (await createNewCrew(this.timesheet.contractorID!, this.curUserID)) {
      }
    },
    async editCrew(crew: CrewWithEmployees | undefined) {
      if (!crew) return;
      if (await updateExistingCrew(crew)) {
      }
    },
    async deleteCrew(crew: CrewWithEmployees | undefined) {
      if (!crew?.id) return;
      this.processing = true;
      try {
        await this.$store.dispatch("DELETE_CREW", crew);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },

    // *** ACTIONS ***
    async deleteTimesheet() {},
    async editTimesheetDay() {
      let newDay = await showTimesheetEditDateDialog(this.timesheet);
      if (!newDay) return;

      this.processing = true;
      try {
        await timesheetService.updateDayForTimesheet(this.timesheet.id!, newDay);
        await this.loadTimesheet();

        let timesheetNumberString = `00000${this.timesheet.timesheetNumber}`.slice(-5);
        var snackbarPayload = {
          text: this.$t("timesheets.snack-bar-date-updated-message", [timesheetNumberString]),
          type: "success",
          undoCallback: null
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    async saveTimesheet(includeEmptyEntries: boolean): Promise<boolean> {
      let allPeople = this.$store.state.users.fullList as PersonWithDetails[];

      let timesheet = this.timesheet;
      if (!timesheet) return false;

      if (timesheet.isNew) {
        let newID = await timesheetService.addItem(timesheet);
        timesheet.id = newID;
        timesheet
          .getNewEntries(
            this.perDiemSubType,
            this.equipmentSubType,
            this.allWorkSubTypes,
            allPeople,
            includeEmptyEntries
          )
          .forEach(x => (x.timesheetID = newID));
      } else if (timesheet.isModified) {
        await timesheetService.updateItem(timesheet.id!, timesheet.modifiedData);
      }

      let entries = timesheet.getSanitizedEntries(
        this.perDiemSubType,
        this.equipmentSubType,
        this.allWorkSubTypes,
        allPeople,
        includeEmptyEntries
      );
      await timesheetService.saveTimesheet(timesheet.id!, timesheet, entries, this.makeCorrections);

      // if (
      //   timesheet.checkHasNewEntries(
      //     this.perDiemSubType,
      //     this.equipmentSubType,
      //     this.allWorkSubTypes,
      //     allPeople,
      //     includeEmptyEntries
      //   )
      // ) {
      //   await timesheetService.addEntriesToTimesheetWithID(
      //     timesheet.id!,
      //     timesheet.getSanitizedNewEntries(
      //       this.perDiemSubType,
      //       this.equipmentSubType,
      //       this.allWorkSubTypes,
      //       allPeople,
      //       includeEmptyEntries
      //     )
      //   );
      // }
      // if (
      //   timesheet.checkHasModifiedEntries(
      //     this.perDiemSubType,
      //     this.equipmentSubType,
      //     this.allWorkSubTypes,
      //     allPeople,
      //     includeEmptyEntries
      //   )
      // ) {
      //   await timesheetService.updateEntriesForTimesheetWithID(
      //     timesheet.id!,
      //     timesheet.getModifiedExistingEntryData(
      //       this.perDiemSubType,
      //       this.equipmentSubType,
      //       this.allWorkSubTypes,
      //       allPeople,
      //       includeEmptyEntries
      //     )
      //   );
      // }
      // if (
      //   timesheet.checkHasRemovedEntries(
      //     this.perDiemSubType,
      //     this.equipmentSubType,
      //     this.allWorkSubTypes,
      //     allPeople,
      //     includeEmptyEntries
      //   )
      // ) {
      //   await timesheetService.removeEntriesFromTimesheetWithID(
      //     timesheet.id!,
      //     timesheet.getRemovedEntryIDs(
      //       this.perDiemSubType,
      //       this.equipmentSubType,
      //       this.allWorkSubTypes,
      //       allPeople,
      //       includeEmptyEntries
      //     )
      //   );
      // }

      if (timesheet.explanationsModified) {
        await timesheetService.updateExplanationsForTimesheetWithID(
          timesheet.id!,
          timesheet.explanations ?? []
        );
      }

      return true;
    },
    async save(closeOnComplete: boolean) {
      // First reset the inline message if there are any.
      this.inlineMessage.message = "";

      // We DON'T validate here (unless making corrections) because this is a "working copy".  The validation happens when submitted.
      if (this.makeCorrections && !(this.$refs.timesheetform as any).validate()) {
        this.inlineMessage.message = this.$t("timesheets.existing.form-errors-message");
        return;
      }

      this.processing = true;
      try {
        let timesheet = this.timesheet;
        if (!timesheet || !this.canSave) {
          this.processing = false;
          var snackbarPayload = {
            text: this.$t("timesheets.entries.no-changes-to-save-message"),
            type: "info",
            undoCallback: null
          };
          this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
          return false;
        }

        if (!(await this.saveTimesheet(this.includeEmptyEntriesOnSave))) {
          return;
        }

        var snackbarPayload = {
          text: this.$t("timesheets.snack-bar-updated-message", [
            this.timesheet.ownerName,
            this.formattedDay
          ]),
          type: "success",
          undoCallback: null
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);

        if (closeOnComplete) {
          if (this.loadAsCorrection) this.$router.push("/timesheetcorrections");
          else this.$router.push("/foremantimesheets");
        } else {
          await this.loadTimesheetSummaries();
          await this.loadTimesheet();
        }
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    async submitTimesheet() {
      // First reset the inline message if there are any.
      this.inlineMessage.message = "";
      if (!(this.$refs.timesheetform as any).validate()) {
        this.inlineMessage.message = this.$t("timesheets.existing.form-errors-message");
        return;
      }
      if (!this.timesheet) return;
      if (!this.timesheet.currentUserPermissions.canSubmit) return;
      this.processing = true;
      this.submitting = true;
      try {
        let allPeople = this.$store.state.users.fullList as PersonWithDetails[];
        if (
          this.timesheet.checkIsDirty(
            this.perDiemSubType,
            this.equipmentSubType,
            this.allWorkSubTypes,
            allPeople,
            false // submit never includes empty timesheets
          )
        ) {
          if (!(await this.saveTimesheet(false))) {
            this.processing = false;
            this.submitting = false;
            return;
          }
          let allWorkTypes = this.$store.state.workTypes.fullList as WorkType[];
          this.timesheet.synchronizeTimesheetRows(
            this.perDiemSubType,
            allWorkTypes,
            this.allWorkSubTypes,
            allPeople,
            false // submit never includes empty timesheets
          );
        }

        let submittedToID = undefined as string | undefined;

        if (
          this.timesheet.timesheetTypeID == TimesheetType.Indirect ||
          this.timesheet.timesheetTypeID == TimesheetType.Equipment
        ) {
          let visibleTimeManagers = SortItemsWithName(
            (await personService.getVisibleTimeManagers())
              .filter(x => x.contractorID == this.timesheet.contractorID)
              .map(x => ({
                ...x,
                name: GetPersonName(x)
              }))
          );

          var title = this.$t("timesheets.entries.submit-to-time-manager-label");
          submittedToID = await showItemSelectionDialog(
            title,
            this.$t("timesheets.entries.time-manager-label"),
            [this.rules.required],
            visibleTimeManagers,
            "name",
            "id"
          );
          // If details is undefined the dialog was cancelled, if empty we somehow bypassed selecting a TM
          if (!submittedToID) {
            return false;
          }
        } else {
          let visibleGeneralForeman = SortItemsWithName(
            (await personService.getVisibleGeneralForemen())
              .filter(x => x.contractorID == this.timesheet.contractorID)
              .map(x => ({
                ...x,
                name: GetPersonName(x)
              }))
          );

          var title = this.$t("timesheets.entries.submit-to-gen-foreman-label");
          submittedToID = await showItemSelectionDialog(
            title,
            this.$t("timesheets.entries.general-foreman-label"),
            [this.rules.required],
            visibleGeneralForeman,
            "name",
            "id"
          );
        }

        // If details is undefined the dialog was cancelled
        if (!submittedToID) {
          return false;
        }

        this.timesheet.submittedTo = submittedToID;

        await timesheetService.submitTimesheet(this.timesheet.id!, submittedToID);
        this.timesheet.timesheetStatusID = TimesheetStatus.Submitted;
        this.isReadonly = this.timesheet.isLocked;

        var snackbarPayload = {
          text: this.$t("timesheets.list.submit-success", [
            this.timesheet.ownerName,
            this.formattedDay,
            this.formattedDay
          ]),
          type: "success",
          undoCallback: null
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);

        this.$router.push("/foremantimesheets");
      } catch (error) {
        if ((error as any).statusCode == 422) {
          var snackbarPayload = {
            text: this.$t("timesheets.list.submit-validation-failed", [
              this.timesheet.ownerName,
              this.formattedDay,
              this.formattedDay
            ]),
            type: "error",
            undoCallback: null
          };
          this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
        } else {
          this.handleError(error as Error);
        }
      } finally {
        this.submitting = false;
        this.processing = false;
      }
    },
    async cancelTimesheet() {
      this.processing = true;
      this.cancelling = true;
      try {
        if (!(await this.saveTimesheet(false))) {
          this.cancelling = false;
          this.processing = false;
          return;
        }
        // get reason
        var title = this.$t("scaffold-request-approvals.cancel-reason");
        var reason = await showAdditionalDetailsDialog(title, this.$t("common.reason"), [
          this.rules.required
        ]);

        // If details is undefined the dialog was cancelled
        if (!reason) {
          // Change the value to something else and then back to its current to force a rebind
          this.cancelling = false;
          this.processing = false;
          return false;
        }

        await timesheetService.cancelPendingTimesheet(this.timesheet.id!, reason);

        let timesheetNumberString = `00000${this.timesheet.timesheetNumber}`.slice(-5);
        var snackbarPayload = {
          text: this.$t("timesheets.existing.cancel-success", [timesheetNumberString]),
          type: "success",
          undoCallback: null
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);

        if (this.loadAsCorrection)
          this.$router.push(this.$store.getters.backBreadcrumb?.to || "/timesheetcorrections");
        else this.$router.push(this.$store.getters.backBreadcrumb?.to || "/foremantimesheets");
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.cancelling = false;
        this.processing = false;
      }
    },
    workSubTypeIsConfiguredCorrectly(workSubType: WorkSubType | undefined): boolean {
      if (!workSubType) return false;

      return (
        (!!workSubType.isWorkOrderRelated && !!workSubType.useWorkOrderCostCode) ||
        !!workSubType.defaultCostCodeID
      );
    }
  },
  created: async function() {
    // Add a small delay of time before the view comes in so that the "slide in" animation will be seen by the user.
    setInterval(() => {
      this.slidein = true;
    }, 100);
    this.loadAsCorrection = this.$route.name == "TimesheetCorrectionExisting";

    if (this.loadAsCorrection) {
      if ((this.$store.state.lastBreadcrumbs[0]?.to || "") != "/timesheetcorrections") {
        this.notifyNewBreadcrumb({
          text: this.$t("timesheets.corrections.title"),
          to: "/timesheetcorrections",
          resetHistory: true
        });
        // This is needed in order to salvage the "last breadcrumbs" in the store.
        this.$store.commit("NOTIFY_NAVIGATION_STARTED");

        this.notifyNewBreadcrumb({
          text: ``,
          to: `/timesheetcorrections/${this.$route.params.id}`
        });
      }
      this.makeCorrections = true;
    } else {
      if ((this.$store.state.lastBreadcrumbs[0]?.to || "") != "/foremantimesheets") {
        this.notifyNewBreadcrumb({
          text: this.$t("timesheets.list.title"),
          to: "/foremantimesheets",
          resetHistory: true
        });
        // This is needed in order to salvage the "last breadcrumbs" in the store.
        this.$store.commit("NOTIFY_NAVIGATION_STARTED");

        this.notifyNewBreadcrumb({
          text: ``,
          to: `/foremantimesheets/${this.$route.params.id}`
        });
      }
    }

    // Set the context for the User Filtering in the store so that if the user navigates to a screen that is
    // a sub screen of something that is currently filtered by their choices that those choices will be
    // preserved as they move between the two screens.
    this.setFilteringContext({
      context: "timesheets-existing",
      parentalContext: "foremantimesheets",
      searchStringForFiltering: ""
    });

    this.processing = true;
    try {
      await Promise.all([this.loadContractors(), this.loadWorkTypes(), this.loadWorkSubTypes()]);
      await this.loadTimesheetSummaries();
      await this.loadTimesheet();
    } catch (error) {
      this.handleError(error as Error);
    } finally {
      this.processing = false;
    }
  }
});

