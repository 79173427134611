import { Person, personBySecurityIDService2 } from "../../services";
import { Record, BasicReferenceDataCrudService } from "../baseTypes";
import { createReferenceDataStore } from "./register";

// TODO: Revisit the fact that person's ID field is undefined-able - this forces us to add additional qualifiers
// TODO: Revisit the fact that person's name fields are undefined-able

const personBySecurityIDDataStore = createReferenceDataStore<Person & Record>(
  "personBySecurityID",
  personBySecurityIDService2 as BasicReferenceDataCrudService<Person & Record>,
  x => `${x.firstName} ${x.lastName}`
);
export default personBySecurityIDDataStore;
