export function parseBCountString(bCountString: string): { code: string; quantity: number }[] {
  // console.log(`parseBCountString bCountString:\n\t${bCountString.replace(/\n/g, "\n\t")}`);
  let lines = bCountString.split("\n");
  // console.log(` lines: ${lines}`);
  let partCounts = [];
  for (let line of lines) {
    let partCount = parseBCountLine(line);
    if (!partCount) continue;
    partCounts.push(partCount);
  }
  return partCounts;
}
export function parseBCountLine(
  bCountLine: string
): { code: string; quantity: number } | undefined {
  if (!bCountLine) return undefined;
  // There needs to be an even number of dots between the code and the quantity for this to work.
  let lineParts = bCountLine.replace(/…/g, "...").split("..");
  // console.log(`   line parts: ${lineParts}`);

  // We need at least 2 pieces to this string: the code and the quantity
  if (!lineParts.length || lineParts.length <= 1) {
    // console.log(`    line could not split`);
    return undefined;
  }

  let partCode = lineParts[0];
  // Remove all periods contained in the quantity string
  // Generally this occurs if there is an odd number of periods between the part code and the quantity
  // But quantities with decimal points are invalid and this allows us to not lose any information
  let rawQuantity = lineParts[lineParts.length - 1].replace(/\./g, "");
  let quantity = Number(rawQuantity);
  if (isNaN(quantity)) {
    // console.log(`    quantity not valid: ${rawQuantity}`);
    return undefined;
  }

  return { code: partCode, quantity: quantity };
}

