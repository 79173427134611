var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { fluid: "", "data-cy": "timesheetsummary" } },
    [
      _vm.inlineMessage.message
        ? _c(
            "v-alert",
            {
              staticClass: "mt-0 mb-3",
              attrs: { type: _vm.inlineMessage.type }
            },
            [_vm._v("\n    " + _vm._s(_vm.inlineMessage.message) + "\n  ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-card",
        { staticClass: "fd-view no-tag-searching" },
        [
          _c(
            "fd-toolbar",
            { attrs: { loading: _vm.processing } },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("v-breadcrumbs", {
                        class: [
                          _vm.processing ? "breadcrumb-processing-opacity" : "",
                          "pl-0"
                        ],
                        attrs: {
                          items: _vm.$store.state.currentBreadcrumbs,
                          large: ""
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "divider",
                            fn: function() {
                              return [
                                _c("v-icon", [_vm._v("fa-chevron-right")])
                              ]
                            },
                            proxy: true
                          }
                        ])
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-tabs",
            {
              ref: "tabs",
              staticClass: "mt-2",
              attrs: { "icons-and-text": "" },
              model: {
                value: _vm.active_tab,
                callback: function($$v) {
                  _vm.active_tab = $$v
                },
                expression: "active_tab"
              }
            },
            [
              _c(
                "v-tab",
                {
                  key: _vm.peopleTab.key,
                  ref: "tab",
                  attrs: { href: `#tab-${_vm.peopleTab.key}` },
                  on: {
                    click: function($event) {
                      return _vm.tabSelected(_vm.peopleTab)
                    }
                  }
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.peopleTab.tabname) + "\n        "
                  ),
                  _c("v-icon", [_vm._v("fas fa-users")])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-tab-item",
                {
                  key: _vm.peopleTab.key,
                  attrs: { value: `tab-${_vm.peopleTab.key}` }
                },
                [
                  _c(
                    "v-row",
                    {
                      staticClass:
                        "ml-0 mr-0 fd-primary-context-qualifier-background",
                      attrs: { align: "center" }
                    },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "py-0 my-0",
                          attrs: { cols: "5", sm: "6" }
                        },
                        [
                          _c("v-row", { attrs: { align: "center" } }, [
                            _c(
                              "div",
                              {
                                class: [
                                  _vm.$vuetify.breakpoint.smAndUp
                                    ? "fd-timesheet-summary-date-select-wrapper"
                                    : "fd-timesheet-summary-date-select-wrapper-mobile",
                                  "fd-position-relative",
                                  "my-2"
                                ]
                              },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass:
                                      "d-none d-sm-flex fd-timesheet-summary-date-selector-button-position-left",
                                    attrs: {
                                      icon: "",
                                      fab: "",
                                      disabled: _vm.processing
                                    },
                                    on: { click: _vm.selectPreviousDay }
                                  },
                                  [
                                    _c("v-icon", [
                                      _vm._v("fa-circle-caret-left")
                                    ])
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("fd-date-picker", {
                                  attrs: {
                                    "hide-details": "",
                                    dense: "",
                                    max: new Date()
                                  },
                                  model: {
                                    value: _vm.selectedDay,
                                    callback: function($$v) {
                                      _vm.selectedDay = $$v
                                    },
                                    expression: "selectedDay"
                                  }
                                }),
                                _vm._v(" "),
                                _c(
                                  "v-btn",
                                  {
                                    staticClass:
                                      "d-none d-sm-flex fd-timesheet-summary-date-selector-button-position-right",
                                    attrs: {
                                      icon: "",
                                      fab: "",
                                      disabled: _vm.processing
                                    },
                                    on: { click: _vm.selectNextDay }
                                  },
                                  [
                                    _c("v-icon", [
                                      _vm._v("fa-circle-caret-right")
                                    ])
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        {
                          staticClass: "py-0 my-0",
                          attrs: { cols: "7", sm: "6" }
                        },
                        [
                          _c(
                            "v-row",
                            { attrs: { align: "center", justify: "end" } },
                            [
                              _c("v-switch", {
                                staticClass: "pl-3 pr-2 pr-sm-5",
                                attrs: {
                                  "data-cy": "showdayshiftswitch",
                                  disabled: _vm.processing,
                                  label: _vm.$vuetify.breakpoint.smAndUp
                                    ? _vm.$t(
                                        "timesheets.is-day-shift-plural-label"
                                      )
                                    : _vm.$t(
                                        "timesheets.is-day-shift-plural-label-short"
                                      )
                                },
                                model: {
                                  value: _vm.showDayShift,
                                  callback: function($$v) {
                                    _vm.showDayShift = $$v
                                  },
                                  expression: "showDayShift"
                                }
                              }),
                              _vm._v(" "),
                              _c("v-switch", {
                                staticClass: "pr-3",
                                attrs: {
                                  "data-cy": "shownightshiftswitch",
                                  disabled: _vm.processing,
                                  label: _vm.$vuetify.breakpoint.smAndUp
                                    ? _vm.$t(
                                        "timesheets.is-night-shift-plural-label"
                                      )
                                    : _vm.$t(
                                        "timesheets.is-night-shift-plural-label-short"
                                      )
                                },
                                model: {
                                  value: _vm.showNightShift,
                                  callback: function($$v) {
                                    _vm.showNightShift = $$v
                                  },
                                  expression: "showNightShift"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    {
                      staticClass:
                        "ml-0 mr-0 fd-secondary-context-qualifier-background",
                      attrs: { align: "center", justify: "end" }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "pl-3 mb-1 d-none d-sm-flex",
                          staticStyle: { color: "rgba(0,0,0,0.6)" }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.$t("timesheets.existing.group-by-label")
                              ) +
                              "\n          "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "ml-4 mr-3 mb-1 d-none d-sm-flex fd-button-context-separator"
                        },
                        [_vm._v("\n            |\n          ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-radio-group",
                        {
                          attrs: {
                            row: "",
                            dense: _vm.$vuetify.breakpoint.xsOnly
                          },
                          model: {
                            value: _vm.selectedGroupingType,
                            callback: function($$v) {
                              _vm.selectedGroupingType = $$v
                            },
                            expression: "selectedGroupingType"
                          }
                        },
                        [
                          _c("v-radio", {
                            attrs: {
                              label: _vm.$t(
                                "timesheets.summary.group-by-none-radio"
                              ),
                              value: "groupnone",
                              "data-cy": "radionone"
                            }
                          }),
                          _vm._v(" "),
                          _c("v-radio", {
                            attrs: {
                              label: _vm.$t(
                                "timesheets.summary.group-by-person-radio"
                              ),
                              value: "groupperson",
                              "data-cy": "radioperson"
                            }
                          }),
                          _vm._v(" "),
                          _c("v-radio", {
                            attrs: {
                              label: _vm.$t(
                                "timesheets.summary.group-by-transaction-type-radio"
                              ),
                              value: "grouptransactiontype",
                              "data-cy": "radioperson"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card-subtitle",
                    { staticStyle: { "margin-top": "15px" } },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "pt-0 pb-0 order-2 order-sm-1",
                              attrs: { cols: "12", sm: "6" }
                            },
                            [
                              _c(
                                "v-row",
                                {
                                  staticClass: "pa-0 ma-0",
                                  attrs: { align: "center", justify: "center" }
                                },
                                [
                                  _vm.$vuetify.breakpoint.lgAndUp
                                    ? _c(
                                        "v-menu",
                                        {
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function({ on }) {
                                                  return [
                                                    _c(
                                                      "v-btn",
                                                      _vm._g(
                                                        {
                                                          staticClass: "mr-3",
                                                          staticStyle: {
                                                            "min-width": "110px"
                                                          },
                                                          attrs: {
                                                            small: "",
                                                            rounded: "",
                                                            outlined: "",
                                                            disabled:
                                                              _vm.processing,
                                                            color: "#7a7a7a"
                                                          }
                                                        },
                                                        on
                                                      ),
                                                      [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            attrs: {
                                                              small: "",
                                                              left:
                                                                _vm.$vuetify
                                                                  .breakpoint
                                                                  .smAndUp
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                        mdi-printer\n                      "
                                                            )
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _vm.$vuetify.breakpoint
                                                          .smAndUp
                                                          ? _c("span", [
                                                              _vm._v(
                                                                "\n                        " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "common.print"
                                                                    )
                                                                  ) +
                                                                  "\n                      "
                                                              )
                                                            ])
                                                          : _vm._e(),
                                                        _vm._v(" "),
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            staticStyle: {
                                                              "margin-left":
                                                                "2px !important"
                                                            },
                                                            attrs: { right: "" }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "mdi-menu-down"
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            false,
                                            4095783442
                                          )
                                        },
                                        [
                                          _vm._v(" "),
                                          _c(
                                            "v-list",
                                            { attrs: { subheader: "" } },
                                            [
                                              _c(
                                                "v-subheader",
                                                {
                                                  staticClass:
                                                    "fd-drop-down-button-badges"
                                                },
                                                [
                                                  _c(
                                                    "v-badge",
                                                    {
                                                      attrs: {
                                                        inline: "",
                                                        color: "primary",
                                                        content: `${_vm.visiblePeopleTimesheetRowsForDay.length}`
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "timesheets.summary.printing.visible-timesheet-rows"
                                                            )
                                                          ) +
                                                          "\n                      "
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-list-item-group",
                                                {
                                                  staticClass:
                                                    "fd-drop-down-item-group"
                                                },
                                                [
                                                  _c(
                                                    "v-list-item",
                                                    {
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.downloadAndPrintPeopleTimesheetSummaryReport(
                                                            true,
                                                            "pdf",
                                                            false
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "timesheets.summary.printing.details"
                                                            )
                                                          ) +
                                                          "\n                        " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "timesheets.summary.printing.pdf"
                                                            )
                                                          ) +
                                                          "\n                      "
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-list-item",
                                                    {
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.downloadAndPrintPeopleTimesheetSummaryReport(
                                                            true,
                                                            "xls",
                                                            false
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "timesheets.summary.printing.details"
                                                            )
                                                          ) +
                                                          "\n                        " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "timesheets.summary.printing.excel"
                                                            )
                                                          ) +
                                                          "\n                      "
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-list-item-group",
                                                {
                                                  staticClass:
                                                    "fd-drop-down-item-group"
                                                },
                                                [
                                                  _c(
                                                    "v-list-item",
                                                    {
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.downloadAndPrintPeopleTimesheetSummaryReport(
                                                            true,
                                                            "pdf",
                                                            true
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "timesheets.summary.printing.summary"
                                                            )
                                                          ) +
                                                          "\n                        " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "timesheets.summary.printing.pdf"
                                                            )
                                                          ) +
                                                          "\n                      "
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-list-item",
                                                    {
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.downloadAndPrintPeopleTimesheetSummaryReport(
                                                            true,
                                                            "xls",
                                                            true
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "timesheets.summary.printing.summary"
                                                            )
                                                          ) +
                                                          "\n                        " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "timesheets.summary.printing.excel"
                                                            )
                                                          ) +
                                                          "\n                      "
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c("v-divider", {
                                                staticClass: "my-3"
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "v-subheader",
                                                {
                                                  staticClass:
                                                    "fd-drop-down-button-badges"
                                                },
                                                [
                                                  _c(
                                                    "v-badge",
                                                    {
                                                      attrs: {
                                                        inline: "",
                                                        color: "primary",
                                                        content: `${_vm.allPeopleTimesheetRowsForDay.length}`
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "timesheets.summary.printing.all-timesheet-rows"
                                                            )
                                                          ) +
                                                          "\n                      "
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-list-item-group",
                                                {
                                                  staticClass:
                                                    "fd-drop-down-item-group"
                                                },
                                                [
                                                  _c(
                                                    "v-list-item",
                                                    {
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.downloadAndPrintPeopleTimesheetSummaryReport(
                                                            false,
                                                            "pdf",
                                                            false
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "timesheets.summary.printing.details"
                                                            )
                                                          ) +
                                                          "\n                        " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "timesheets.summary.printing.pdf"
                                                            )
                                                          ) +
                                                          "\n                      "
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-list-item",
                                                    {
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.downloadAndPrintPeopleTimesheetSummaryReport(
                                                            false,
                                                            "xls",
                                                            false
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "timesheets.summary.printing.details"
                                                            )
                                                          ) +
                                                          "\n                        " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "timesheets.summary.printing.excel"
                                                            )
                                                          ) +
                                                          "\n                      "
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-list-item-group",
                                                {
                                                  staticClass:
                                                    "fd-drop-down-item-group"
                                                },
                                                [
                                                  _c(
                                                    "v-list-item",
                                                    {
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.downloadAndPrintPeopleTimesheetSummaryReport(
                                                            false,
                                                            "pdf",
                                                            true
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "timesheets.summary.printing.summary"
                                                            )
                                                          ) +
                                                          "\n                        " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "timesheets.summary.printing.pdf"
                                                            )
                                                          ) +
                                                          "\n                      "
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-list-item",
                                                    {
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.downloadAndPrintPeopleTimesheetSummaryReport(
                                                            false,
                                                            "xls",
                                                            true
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "timesheets.summary.printing.summary"
                                                            )
                                                          ) +
                                                          "\n                        " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "timesheets.summary.printing.excel"
                                                            )
                                                          ) +
                                                          "\n                      "
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "mr-5",
                                      attrs: {
                                        small: "",
                                        rounded: "",
                                        outlined: "",
                                        disabled: _vm.processing,
                                        color: "#7a7a7a"
                                      },
                                      on: { click: _vm.reloadTableData }
                                    },
                                    [
                                      !_vm.$vuetify.breakpoint.sm
                                        ? _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                small: "",
                                                left:
                                                  _vm.$vuetify.breakpoint
                                                    .smAndUp
                                              }
                                            },
                                            [_vm._v("mdi-reload")]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.$vuetify.breakpoint.smAndUp
                                        ? _c("span", [
                                            _vm._v(
                                              _vm._s(_vm.$t("common.reload"))
                                            )
                                          ])
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("v-text-field", {
                                    class: [
                                      _vm.$vuetify.breakpoint.sm
                                        ? "fd-restrict-clearable-position"
                                        : "",
                                      "pt-0",
                                      "mt-0",
                                      "fd-table-search-input"
                                    ],
                                    attrs: {
                                      "data-cy": "tablesearch",
                                      "append-icon": "search",
                                      label: _vm.$t("common.search"),
                                      "single-line": "",
                                      "hide-details": "",
                                      clearable: ""
                                    },
                                    model: {
                                      value: _vm.tablesearch,
                                      callback: function($$v) {
                                        _vm.tablesearch = $$v
                                      },
                                      expression: "tablesearch"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            {
                              staticClass: "pt-0 pb-0 order-1 order-sm-2",
                              attrs: { cols: "12", sm: "6" }
                            },
                            [
                              _c("fd-select", {
                                staticClass: "mt-0 pt-0 pt-sm-0 pb-3 pb-sm-0",
                                attrs: {
                                  items: _vm.contractorsInUse,
                                  label: _vm.$t(
                                    "users.filter-by-contractor-label"
                                  ),
                                  "item-text": "alias",
                                  "item-value": "id",
                                  multiple: "",
                                  "small-chips": "",
                                  clearable: "",
                                  "hide-details": ""
                                },
                                model: {
                                  value: _vm.contractorsSelectedForFiltering,
                                  callback: function($$v) {
                                    _vm.contractorsSelectedForFiltering = $$v
                                  },
                                  expression: "contractorsSelectedForFiltering"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-data-table", {
                    ref: "summarydatatable",
                    staticClass: "fd-timesheet-entries-table",
                    attrs: {
                      loading: _vm.processing,
                      "loading-text": _vm.$t("common.table-loading-message"),
                      "mobile-breakpoint": "0",
                      "items-per-page": -1,
                      "footer-props": { "items-per-page-options": [-1] },
                      "hide-default-footer": "",
                      "group-by": _vm.groupColumn,
                      items: _vm.visiblePeopleTimesheetRowsForDay,
                      headers: _vm.summaryTableHeaders
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "header.empty",
                          fn: function() {
                            return [
                              _vm.visiblePeopleTimesheetRowsForDay.length > 0
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: {
                                            disabled:
                                              !_vm.$vuetify.breakpoint
                                                .lgAndUp || _vm.processing,
                                            left: ""
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function({ on }) {
                                                  return [
                                                    _c(
                                                      "v-btn",
                                                      _vm._g(
                                                        {
                                                          attrs: {
                                                            icon: "",
                                                            tile: "",
                                                            color: "primary"
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.toggleTableGroups(
                                                                "summary",
                                                                _vm.allGroupsExpanded
                                                              )
                                                            }
                                                          }
                                                        },
                                                        on
                                                      ),
                                                      [
                                                        _c("v-icon", {
                                                          domProps: {
                                                            textContent: _vm._s(
                                                              !_vm.allGroupsExpanded
                                                                ? "mdi-plus-box-multiple-outline"
                                                                : "mdi-minus-box-multiple-outline"
                                                            )
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            false,
                                            474613601
                                          )
                                        },
                                        [
                                          _vm._v(" "),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                !_vm.allGroupsExpanded
                                                  ? _vm.$t("common.expand-all")
                                                  : _vm.$t(
                                                      "common.collapse-all"
                                                    )
                                              )
                                            )
                                          ])
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ]
                          },
                          proxy: true
                        },
                        {
                          key: "item.empty",
                          fn: function() {
                            return [_vm._v(" ")]
                          },
                          proxy: true
                        },
                        {
                          key: "item.workOrderNumber",
                          fn: function({ item }) {
                            return [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.workOrderNumberTextForRow(item)) +
                                  "\n          "
                              )
                            ]
                          }
                        },
                        {
                          key: "item.perdiem",
                          fn: function({ item }) {
                            return [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "fd-table-column-text-end-override"
                                },
                                [
                                  _vm.isPerDiemApplicable(item) &&
                                  item.removePerDiem
                                    ? _c(
                                        "v-chip",
                                        {
                                          staticClass: "white--text",
                                          attrs: {
                                            color: "rgba(0, 0, 0, 0.87)"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "timesheets.existing.correction-remove-per-diem"
                                              )
                                            )
                                          )
                                        ]
                                      )
                                    : _vm.isPerDiemApplicable(item)
                                    ? _c("fd-checkbox", {
                                        attrs: {
                                          value: item.hasPerDiem,
                                          disabled: _vm.processing,
                                          readonly: true
                                        }
                                      })
                                    : _c("fd-value-display", {
                                        attrs: { value: undefined }
                                      })
                                ],
                                1
                              )
                            ]
                          }
                        },
                        {
                          key: "group.header",
                          fn: function({ group, items, isOpen, toggle }) {
                            return [
                              _c(
                                "td",
                                {
                                  attrs: {
                                    colspan: isOpen
                                      ? 999
                                      : _vm.selectedGroupingType ==
                                        "groupperson"
                                      ? 8
                                      : 9
                                  }
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      ref: `summarygrouptoggle${group}`,
                                      attrs: { icon: "" },
                                      on: { click: toggle }
                                    },
                                    [
                                      _c("v-icon", [
                                        _vm._v(
                                          _vm._s(
                                            isOpen ? "mdi-minus" : "mdi-plus"
                                          )
                                        )
                                      ])
                                    ],
                                    1
                                  ),
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.labelForGroup(group)) +
                                      "\n            "
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm._l(
                                _vm.usedWorkSubTypes.filter(x => !isOpen),
                                function(wst) {
                                  return _c(
                                    "td",
                                    {
                                      key: wst.id,
                                      staticClass:
                                        "fd-restrict-table-entry-column-width"
                                    },
                                    [
                                      _c("fd-value-display", {
                                        staticStyle: { display: "inline" },
                                        attrs: {
                                          value: _vm.sum(items, wst.id),
                                          "no-value-key":
                                            "timesheets.list.no-time-value"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                }
                              ),
                              _vm._v(" "),
                              !isOpen
                                ? _c(
                                    "td",
                                    {
                                      staticClass:
                                        "fd-restrict-table-entry-column-width-total fd-table-column-text-end-override",
                                      attrs: { colspan: 1 }
                                    },
                                    [
                                      _c("fd-value-display", {
                                        staticStyle: { display: "inline" },
                                        attrs: {
                                          value: _vm.calculateTotalForItems(
                                            items
                                          ),
                                          "no-value-key":
                                            "timesheets.list.no-time-value"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              !isOpen
                                ? _c(
                                    "td",
                                    {
                                      staticClass:
                                        "fd-restrict-table-entry-column-width-total",
                                      attrs: { colspan: 1 }
                                    },
                                    [_vm._v(" \n            ")]
                                  )
                                : _vm._e()
                            ]
                          }
                        },
                        _vm._l(_vm.usedWorkSubTypes, function(wst) {
                          return {
                            key: `item.${wst.id}`,
                            fn: function({ item }) {
                              return [
                                _c("fd-value-display", {
                                  key: wst.id,
                                  attrs: {
                                    value: _vm.formatNumber(item[`${wst.id}`]),
                                    "no-value-key":
                                      "timesheets.list.no-time-entry-value",
                                    "no-value-style": "text-align: center;"
                                  }
                                })
                              ]
                            }
                          }
                        }),
                        {
                          key: "item.total",
                          fn: function({ item }) {
                            return [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "fd-table-column-text-end-override"
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.calculateTotal(item)) +
                                      "\n            "
                                  )
                                ]
                              )
                            ]
                          }
                        },
                        {
                          key: "body.append",
                          fn: function({ items }) {
                            return [
                              _c(
                                "tr",
                                { staticClass: "fd-table-summary-row" },
                                [
                                  _c(
                                    "td",
                                    {
                                      staticClass: "text-start",
                                      attrs: {
                                        colspan:
                                          _vm.selectedGroupingType ==
                                          "grouptransactiontype"
                                            ? 9
                                            : 8
                                      }
                                    },
                                    [
                                      _c("fd-alert", {
                                        staticClass: "pa-0 mt-0",
                                        attrs: {
                                          dense: true,
                                          label: _vm.$t(
                                            "timesheets.existing.grand-total-label"
                                          ),
                                          type: "hint",
                                          white: ""
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _vm._l(_vm.usedWorkSubTypes, function(wst) {
                                    return _c(
                                      "td",
                                      {
                                        key: wst.id,
                                        staticClass:
                                          "fd-restrict-table-entry-column-width"
                                      },
                                      [
                                        _c("fd-value-display", {
                                          staticStyle: { display: "inline" },
                                          attrs: {
                                            value: _vm.sum(items, wst.id),
                                            "no-value-key":
                                              "timesheets.list.no-time-value",
                                            "no-value-class": "white--text"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    {
                                      staticClass:
                                        "fd-table-column-text-end-override",
                                      attrs: { colspan: 1 }
                                    },
                                    [
                                      _c("fd-value-display", {
                                        staticStyle: { display: "inline" },
                                        attrs: {
                                          value: _vm.calculateTotalForItems(
                                            items
                                          ),
                                          "no-value-key":
                                            "timesheets.list.no-time-value",
                                          "no-value-class": "white--text"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    {
                                      staticClass:
                                        "fd-restrict-table-entry-column-width-total fd-table-column-text-end-override",
                                      attrs: { colspan: 1 }
                                    },
                                    [
                                      _c("fd-value-display", {
                                        staticStyle: { display: "inline" },
                                        attrs: {
                                          value:
                                            _vm.countOn(items, "hasPerDiem") -
                                            _vm.countOn(items, "removePerDiem"),
                                          "no-value-key":
                                            "timesheets.list.no-entries-value"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                2
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-tab",
                {
                  key: _vm.equipmentTab.key,
                  attrs: { href: `#tab-${_vm.equipmentTab.key}` },
                  on: {
                    click: function($event) {
                      return _vm.tabSelected(_vm.equipmentTab)
                    }
                  }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.equipmentTab.tabname) +
                      "\n        "
                  ),
                  _c("v-icon", [_vm._v("fas fa-edit")])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-tab-item",
                {
                  key: _vm.equipmentTab.key,
                  attrs: { value: `tab-${_vm.equipmentTab.key}` }
                },
                [
                  _c(
                    "v-row",
                    {
                      staticClass:
                        "ml-0 mr-0 fd-primary-context-qualifier-background",
                      attrs: { align: "center" }
                    },
                    [
                      _c(
                        "v-col",
                        { staticClass: "py-0 my-0", attrs: { cols: "12" } },
                        [
                          _c("v-row", { attrs: { align: "center" } }, [
                            _c(
                              "div",
                              {
                                class: [
                                  _vm.$vuetify.breakpoint.smAndUp
                                    ? "fd-timesheet-summary-date-select-wrapper"
                                    : "fd-timesheet-summary-date-select-wrapper-mobile",
                                  "fd-position-relative",
                                  "my-2"
                                ]
                              },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass:
                                      "d-none d-sm-flex fd-timesheet-summary-date-selector-button-position-left",
                                    attrs: {
                                      icon: "",
                                      fab: "",
                                      disabled: _vm.processing
                                    },
                                    on: { click: _vm.selectPreviousDay }
                                  },
                                  [
                                    _c("v-icon", [
                                      _vm._v("fa-circle-caret-left")
                                    ])
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("fd-date-picker", {
                                  attrs: {
                                    "hide-details": "",
                                    dense: "",
                                    max: new Date()
                                  },
                                  model: {
                                    value: _vm.selectedDay,
                                    callback: function($$v) {
                                      _vm.selectedDay = $$v
                                    },
                                    expression: "selectedDay"
                                  }
                                }),
                                _vm._v(" "),
                                _c(
                                  "v-btn",
                                  {
                                    staticClass:
                                      "d-none d-sm-flex fd-timesheet-summary-date-selector-button-position-right",
                                    attrs: {
                                      icon: "",
                                      fab: "",
                                      disabled: _vm.processing
                                    },
                                    on: { click: _vm.selectNextDay }
                                  },
                                  [
                                    _c("v-icon", [
                                      _vm._v("fa-circle-caret-right")
                                    ])
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card-subtitle",
                    { staticStyle: { "margin-top": "15px" } },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "pt-0 pb-0 order-2 order-sm-1",
                              attrs: { cols: "12", sm: "6" }
                            },
                            [
                              _c(
                                "v-row",
                                {
                                  staticClass: "pa-0 ma-0",
                                  attrs: { align: "center", justify: "center" }
                                },
                                [
                                  _vm.$vuetify.breakpoint.lgAndUp
                                    ? _c(
                                        "v-menu",
                                        {
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function({ on }) {
                                                  return [
                                                    _c(
                                                      "v-btn",
                                                      _vm._g(
                                                        {
                                                          staticClass: "mr-3",
                                                          staticStyle: {
                                                            "min-width": "110px"
                                                          },
                                                          attrs: {
                                                            small: "",
                                                            rounded: "",
                                                            outlined: "",
                                                            disabled:
                                                              _vm.processing,
                                                            color: "#7a7a7a"
                                                          }
                                                        },
                                                        on
                                                      ),
                                                      [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            attrs: {
                                                              small: "",
                                                              left:
                                                                _vm.$vuetify
                                                                  .breakpoint
                                                                  .smAndUp
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                        mdi-printer\n                      "
                                                            )
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _vm.$vuetify.breakpoint
                                                          .smAndUp
                                                          ? _c("span", [
                                                              _vm._v(
                                                                "\n                        " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "common.print"
                                                                    )
                                                                  ) +
                                                                  "\n                      "
                                                              )
                                                            ])
                                                          : _vm._e(),
                                                        _vm._v(" "),
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            staticStyle: {
                                                              "margin-left":
                                                                "2px !important"
                                                            },
                                                            attrs: { right: "" }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "mdi-menu-down"
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            false,
                                            4095783442
                                          )
                                        },
                                        [
                                          _vm._v(" "),
                                          _c(
                                            "v-list",
                                            { attrs: { subheader: "" } },
                                            [
                                              _c(
                                                "v-subheader",
                                                {
                                                  staticClass:
                                                    "fd-drop-down-button-badges"
                                                },
                                                [
                                                  _c(
                                                    "v-badge",
                                                    {
                                                      attrs: {
                                                        inline: "",
                                                        color: "primary",
                                                        content: `${_vm.visibleEquipmentTimesheetRowsForDay.length}`
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "timesheets.summary.printing.visible-timesheet-rows"
                                                            )
                                                          ) +
                                                          "\n                      "
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-list-item-group",
                                                {
                                                  staticClass:
                                                    "fd-drop-down-item-group"
                                                },
                                                [
                                                  _c(
                                                    "v-list-item",
                                                    {
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.downloadAndPrintEquipmentTimesheetSummaryReport(
                                                            true,
                                                            "pdf"
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "timesheets.summary.printing.pdf"
                                                            )
                                                          ) +
                                                          "\n                      "
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-list-item",
                                                    {
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.downloadAndPrintEquipmentTimesheetSummaryReport(
                                                            true,
                                                            "xls"
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "timesheets.summary.printing.excel"
                                                            )
                                                          ) +
                                                          "\n                      "
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c("v-divider", {
                                                staticClass: "my-3"
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "v-subheader",
                                                {
                                                  staticClass:
                                                    "fd-drop-down-button-badges"
                                                },
                                                [
                                                  _c(
                                                    "v-badge",
                                                    {
                                                      attrs: {
                                                        inline: "",
                                                        color: "primary",
                                                        content: `${_vm.allEquipmentTimesheetRowsForDay.length}`
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "timesheets.summary.printing.all-timesheet-rows"
                                                            )
                                                          ) +
                                                          "\n                      "
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-list-item-group",
                                                {
                                                  staticClass:
                                                    "fd-drop-down-item-group"
                                                },
                                                [
                                                  _c(
                                                    "v-list-item",
                                                    {
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.downloadAndPrintEquipmentTimesheetSummaryReport(
                                                            false,
                                                            "pdf"
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "timesheets.summary.printing.pdf"
                                                            )
                                                          ) +
                                                          "\n                      "
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-list-item",
                                                    {
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.downloadAndPrintEquipmentTimesheetSummaryReport(
                                                            false,
                                                            "xls"
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "timesheets.summary.printing.excel"
                                                            )
                                                          ) +
                                                          "\n                      "
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "mr-5",
                                      attrs: {
                                        small: "",
                                        rounded: "",
                                        outlined: "",
                                        disabled: _vm.processing,
                                        color: "#7a7a7a"
                                      },
                                      on: { click: _vm.reloadTableData }
                                    },
                                    [
                                      !_vm.$vuetify.breakpoint.sm
                                        ? _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                small: "",
                                                left:
                                                  _vm.$vuetify.breakpoint
                                                    .smAndUp
                                              }
                                            },
                                            [_vm._v("mdi-reload")]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.$vuetify.breakpoint.smAndUp
                                        ? _c("span", [
                                            _vm._v(
                                              _vm._s(_vm.$t("common.reload"))
                                            )
                                          ])
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("v-text-field", {
                                    class: [
                                      _vm.$vuetify.breakpoint.sm
                                        ? "fd-restrict-clearable-position"
                                        : "",
                                      "pt-0",
                                      "mt-0",
                                      "fd-table-search-input"
                                    ],
                                    attrs: {
                                      "data-cy": "tablesearch",
                                      "append-icon": "search",
                                      label: _vm.$t("common.search"),
                                      "single-line": "",
                                      "hide-details": "",
                                      clearable: ""
                                    },
                                    model: {
                                      value: _vm.tablesearch,
                                      callback: function($$v) {
                                        _vm.tablesearch = $$v
                                      },
                                      expression: "tablesearch"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            {
                              staticClass: "pt-0 pb-0 order-1 order-sm-2",
                              attrs: { cols: "12", sm: "6" }
                            },
                            [
                              _c("fd-select", {
                                staticClass: "mt-0 pt-0 pt-sm-0 pb-3 pb-sm-0",
                                attrs: {
                                  items: _vm.contractorsInUse,
                                  label: _vm.$t(
                                    "users.filter-by-contractor-label"
                                  ),
                                  "item-text": "alias",
                                  "item-value": "id",
                                  multiple: "",
                                  "small-chips": "",
                                  clearable: "",
                                  "hide-details": ""
                                },
                                model: {
                                  value: _vm.contractorsSelectedForFiltering,
                                  callback: function($$v) {
                                    _vm.contractorsSelectedForFiltering = $$v
                                  },
                                  expression: "contractorsSelectedForFiltering"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-data-table", {
                    ref: "equipmentdatatable",
                    staticClass: "fd-timesheet-entries-table",
                    attrs: {
                      loading: _vm.processing,
                      "loading-text": _vm.$t("common.table-loading-message"),
                      "mobile-breakpoint": "0",
                      "items-per-page": -1,
                      "footer-props": { "items-per-page-options": [-1] },
                      "hide-default-footer": "",
                      "group-by": _vm.groupColumn,
                      items: _vm.visibleEquipmentTimesheetRowsForDay,
                      headers: _vm.equipmentTableHeaders
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "header.empty",
                        fn: function() {
                          return [
                            _vm.visibleEquipmentTimesheetRowsForDay.length > 0
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "v-tooltip",
                                      {
                                        attrs: {
                                          disabled:
                                            !_vm.$vuetify.breakpoint.lgAndUp ||
                                            _vm.processing,
                                          left: ""
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function({ on }) {
                                                return [
                                                  _c(
                                                    "v-btn",
                                                    _vm._g(
                                                      {
                                                        attrs: {
                                                          icon: "",
                                                          tile: "",
                                                          color: "primary"
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.toggleTableGroups(
                                                              "equipment",
                                                              _vm.allGroupsExpanded
                                                            )
                                                          }
                                                        }
                                                      },
                                                      on
                                                    ),
                                                    [
                                                      _c("v-icon", {
                                                        domProps: {
                                                          textContent: _vm._s(
                                                            !_vm.allGroupsExpanded
                                                              ? "mdi-plus-box-multiple-outline"
                                                              : "mdi-minus-box-multiple-outline"
                                                          )
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          false,
                                          549485575
                                        )
                                      },
                                      [
                                        _vm._v(" "),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              !_vm.allGroupsExpanded
                                                ? _vm.$t("common.expand-all")
                                                : _vm.$t("common.collapse-all")
                                            )
                                          )
                                        ])
                                      ]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ]
                        },
                        proxy: true
                      },
                      {
                        key: "item.empty",
                        fn: function() {
                          return [_vm._v(" ")]
                        },
                        proxy: true
                      },
                      {
                        key: "group.header",
                        fn: function({ group, items, isOpen, toggle }) {
                          return [
                            _c(
                              "td",
                              { attrs: { colspan: isOpen ? 999 : 4 } },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    ref: `equipmentgrouptoggle${group}`,
                                    attrs: { icon: "" },
                                    on: { click: toggle }
                                  },
                                  [
                                    _c("v-icon", [
                                      _vm._v(
                                        _vm._s(
                                          isOpen ? "mdi-minus" : "mdi-plus"
                                        )
                                      )
                                    ])
                                  ],
                                  1
                                ),
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.labelForGroup(group)) +
                                    "\n            "
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            !isOpen
                              ? _c(
                                  "td",
                                  {
                                    staticClass:
                                      "fd-restrict-table-entry-column-width"
                                  },
                                  [
                                    _c("fd-value-display", {
                                      staticStyle: { display: "inline" },
                                      attrs: {
                                        value: _vm.sum(items, "equipmentDays"),
                                        "no-value-key":
                                          "timesheets.list.no-time-value"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            !isOpen
                              ? _c(
                                  "td",
                                  {
                                    staticClass:
                                      "fd-restrict-table-entry-column-width"
                                  },
                                  [
                                    _c("fd-value-display", {
                                      staticStyle: { display: "inline" },
                                      attrs: {
                                        value: _vm.sum(
                                          items,
                                          "equipmentQuantity"
                                        ),
                                        "no-value-key":
                                          "timesheets.list.no-time-value"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            !isOpen
                              ? _c(
                                  "td",
                                  {
                                    staticClass:
                                      "fd-restrict-table-entry-column-width-total fd-table-column-text-end-override",
                                    attrs: { colspan: 1 }
                                  },
                                  [
                                    _c("fd-value-display", {
                                      staticStyle: { display: "inline" },
                                      attrs: {
                                        value: _vm.calculateTotalForItems(
                                          items
                                        ),
                                        "no-value-key":
                                          "timesheets.list.no-time-value"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e()
                          ]
                        }
                      },
                      {
                        key: "item.equipmentDays",
                        fn: function({ item }) {
                          return [
                            _c("fd-value-display", {
                              attrs: {
                                value: _vm.formatNumber(item.equipmentDays),
                                "no-value-key":
                                  "timesheets.list.no-time-entry-value",
                                "no-value-style": "text-align: center;"
                              }
                            })
                          ]
                        }
                      },
                      {
                        key: "item.equipmentQuantity",
                        fn: function({ item }) {
                          return [
                            _c("fd-value-display", {
                              attrs: {
                                value: _vm.formatNumber(item.equipmentQuantity),
                                "no-value-key":
                                  "timesheets.list.no-time-entry-value",
                                "no-value-style": "text-align: center;"
                              }
                            })
                          ]
                        }
                      },
                      {
                        key: "item.total",
                        fn: function({ item }) {
                          return [
                            _c(
                              "div",
                              {
                                staticClass: "fd-table-column-text-end-override"
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.calculateTotal(item)) +
                                    "\n            "
                                )
                              ]
                            )
                          ]
                        }
                      },
                      {
                        key: "body.append",
                        fn: function({ items }) {
                          return [
                            _c("tr", { staticClass: "fd-table-summary-row" }, [
                              _c(
                                "td",
                                {
                                  staticClass: "text-start",
                                  attrs: { colspan: "4" }
                                },
                                [
                                  _c("fd-alert", {
                                    staticClass: "pa-0 mt-0",
                                    attrs: {
                                      dense: true,
                                      label: _vm.$t(
                                        "timesheets.existing.grand-total-label"
                                      ),
                                      type: "hint",
                                      white: ""
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  staticClass:
                                    "fd-restrict-table-entry-column-width"
                                },
                                [
                                  _c("fd-value-display", {
                                    staticStyle: { display: "inline" },
                                    attrs: {
                                      value: _vm.sum(items, "equipmentDays"),
                                      "no-value-key":
                                        "timesheets.list.no-time-value",
                                      "no-value-class": "white--text"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  staticClass:
                                    "fd-restrict-table-entry-column-width"
                                },
                                [
                                  _c("fd-value-display", {
                                    staticStyle: { display: "inline" },
                                    attrs: {
                                      value: _vm.sum(
                                        items,
                                        "equipmentQuantity"
                                      ),
                                      "no-value-key":
                                        "timesheets.list.no-time-value",
                                      "no-value-class": "white--text"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  staticClass:
                                    "fd-table-column-text-end-override",
                                  attrs: { colspan: 1 }
                                },
                                [
                                  _c("fd-value-display", {
                                    staticStyle: { display: "inline" },
                                    attrs: {
                                      value: _vm.calculateTotalForItems(items),
                                      "no-value-key":
                                        "timesheets.list.no-time-value",
                                      "no-value-class": "white--text"
                                    }
                                  })
                                ],
                                1
                              )
                            ])
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }