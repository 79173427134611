import { render, staticRenderFns } from "./WorkPackages.vue?vue&type=template&id=018812c4"
import script from "./WorkPackages.vue.ts?vue&type=script&lang=ts&external"
export * from "./WorkPackages.vue.ts?vue&type=script&lang=ts&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports