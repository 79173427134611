var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      attrs: { width: "800px", persistent: "" },
      model: {
        value: _vm.twoWayProps.show,
        callback: function($$v) {
          _vm.$set(_vm.twoWayProps, "show", $$v)
        },
        expression: "twoWayProps.show"
      }
    },
    [
      _c("fd-image-viewer", {
        attrs: { name: _vm.imageName },
        model: {
          value: _vm.imageSource,
          callback: function($$v) {
            _vm.imageSource = $$v
          },
          expression: "imageSource"
        }
      }),
      _vm._v(" "),
      _c("fd-image-editor", {
        attrs: {
          "complete-button-label-key": "common.done",
          name: _vm.imageName
        },
        on: { edit: _vm.handleEdit },
        model: {
          value: _vm.editImageSource,
          callback: function($$v) {
            _vm.editImageSource = $$v
          },
          expression: "editImageSource"
        }
      }),
      _vm._v(" "),
      _c(
        "v-form",
        { ref: "form", on: { submit: _vm.onSubmit } },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                {
                  class: [
                    _vm.processing
                      ? "dialog-new-top-color-processing"
                      : "dialog-new-top-color"
                  ]
                },
                [
                  _c(
                    "span",
                    {
                      class: [
                        _vm.processing ? "breadcrumb-processing-opacity" : ""
                      ]
                    },
                    [_vm._v(_vm._s(_vm.$t("inspections.new-dialog-title")))]
                  ),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _vm.processing
                    ? _c("v-progress-circular", {
                        attrs: {
                          indeterminate: true,
                          rotate: 0,
                          size: 32,
                          width: 4,
                          color: "white"
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-stepper",
                {
                  attrs: { "non-linear": "" },
                  model: {
                    value: _vm.step,
                    callback: function($$v) {
                      _vm.step = $$v
                    },
                    expression: "step"
                  }
                },
                [
                  _c(
                    "v-stepper-header",
                    { staticClass: "fd-responsive-stepper" },
                    [
                      _c(
                        "v-stepper-step",
                        {
                          attrs: {
                            "data-cy": "selectscaffold",
                            complete: _vm.step > _vm.selectScaffoldStep,
                            step: _vm.selectScaffoldStep,
                            editable: ""
                          }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.showShortMessages
                                  ? _vm.$t(`${_vm.scaffoldStepLabelKey}-short`)
                                  : _vm.$t(`${_vm.scaffoldStepLabelKey}`)
                              ) +
                              "\n            "
                          ),
                          _vm.selectscaffolderror
                            ? _c("small", { staticClass: "mt-1" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.showShortMessages
                                      ? _vm.$t(
                                          "inspections.new.steps.select-scaffold-error-message-short"
                                        )
                                      : _vm.$t(
                                          "inspections.new.steps.select-scaffold-error-message"
                                        )
                                  )
                                )
                              ])
                            : _vm._e()
                        ]
                      ),
                      _vm._v(" "),
                      _c("v-divider"),
                      _vm._v(" "),
                      _vm.allowDateSelection
                        ? _c(
                            "v-stepper-step",
                            {
                              attrs: {
                                "data-cy": "selectdate",
                                complete: _vm.step > _vm.selectDateStep,
                                step: _vm.selectDateStep,
                                editable: ""
                              }
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.showShortMessages
                                      ? _vm.$t(
                                          "inspections.new.steps.select-date-short"
                                        )
                                      : _vm.$t(
                                          "inspections.new.steps.select-date"
                                        )
                                  ) +
                                  "\n            "
                              ),
                              _vm.selectdateerror
                                ? _c("small", { staticClass: "mt-1" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.showShortMessages
                                          ? _vm.$t(
                                              "inspections.new.steps.select-date-error-message-short"
                                            )
                                          : _vm.$t(
                                              "inspections.new.steps.select-date-error-message"
                                            )
                                      )
                                    )
                                  ])
                                : _vm._e()
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.allowDateSelection ? _c("v-divider") : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "v-stepper-step",
                        {
                          attrs: {
                            "data-cy": "answerquestions",
                            complete: _vm.step > _vm.answerQuestionsStep,
                            step: _vm.answerQuestionsStep,
                            editable: ""
                          }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.showShortMessages
                                  ? _vm.$t(
                                      "inspections.new.steps.answer-questions-short"
                                    )
                                  : _vm.$t(
                                      "inspections.new.steps.answer-questions"
                                    )
                              ) +
                              "\n            "
                          ),
                          _vm.answerquestionserror
                            ? _c("small", { staticClass: "mt-1" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.showShortMessages
                                      ? _vm.$t(
                                          "inspections.new.steps.answer-questions-error-message-short"
                                        )
                                      : _vm.$t(
                                          "inspections.new.steps.answer-questions-error-message"
                                        )
                                  )
                                )
                              ])
                            : _vm._e()
                        ]
                      ),
                      _vm._v(" "),
                      _c("v-divider"),
                      _vm._v(" "),
                      _c(
                        "v-stepper-step",
                        {
                          attrs: {
                            "data-cy": "takephoto",
                            complete: _vm.step > _vm.takePhotoStep,
                            step: _vm.takePhotoStep,
                            editable: ""
                          }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.showShortMessages
                                  ? _vm.$t(
                                      "inspections.new.steps.take-photo-short"
                                    )
                                  : _vm.$t("inspections.new.steps.take-photo")
                              ) +
                              "\n            "
                          ),
                          _vm.takephotoerror
                            ? _c("small", { staticClass: "mt-1" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.showShortMessages
                                      ? _vm.$t(
                                          "inspections.new.steps.take-photo-error-message-short"
                                        )
                                      : _vm.$t(
                                          "inspections.new.steps.take-photo-error-message"
                                        )
                                  )
                                )
                              ])
                            : _vm._e()
                        ]
                      ),
                      _vm._v(" "),
                      _c("v-divider"),
                      _vm._v(" "),
                      _c(
                        "v-stepper-step",
                        {
                          attrs: {
                            "data-cy": "result",
                            complete: _vm.step > _vm.resultStep,
                            step: _vm.resultStep,
                            editable: ""
                          }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.showShortMessages
                                  ? _vm.$t("inspections.new.steps.result-short")
                                  : _vm.$t("inspections.new.steps.result")
                              ) +
                              "\n            "
                          ),
                          _vm.resulterror
                            ? _c("small", { staticClass: "mt-1" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.showShortMessages
                                      ? _vm.$t(
                                          "inspections.new.steps.result-error-message-short"
                                        )
                                      : _vm.$t(
                                          "inspections.new.steps.result-error-message"
                                        )
                                  )
                                )
                              ])
                            : _vm._e()
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-stepper-items",
                    [
                      _c(
                        "v-stepper-content",
                        {
                          staticClass: "pl-0 pr-0 pb-0",
                          staticStyle: { "padding-top": "2px" },
                          attrs: { step: _vm.selectScaffoldStep }
                        },
                        [
                          _c(
                            "v-form",
                            {
                              ref: "selectscaffoldform",
                              on: { submit: _vm.preventSubmit }
                            },
                            [
                              _c(
                                "v-card",
                                [
                                  _c(
                                    "v-row",
                                    {
                                      staticClass:
                                        "px-2 mb-3 fd-inline-request-tags-qualifier",
                                      attrs: { align: "center" }
                                    },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "py-0",
                                          attrs: { cols: "12" }
                                        },
                                        [
                                          _c("fd-chip-selector", {
                                            attrs: {
                                              "available-items":
                                                _vm.availableTags,
                                              "selected-items":
                                                _vm.selectedTags,
                                              "item-label": "name",
                                              disabled: _vm.processing,
                                              "control-label": _vm.$t(
                                                "common.add-tags"
                                              ),
                                              "chip-class":
                                                "keywords-selection-chip-colors",
                                              outlined: false
                                            },
                                            on: {
                                              "update:selectedItems": function(
                                                $event
                                              ) {
                                                _vm.selectedTags = $event
                                              },
                                              "update:selected-items": function(
                                                $event
                                              ) {
                                                _vm.selectedTags = $event
                                              }
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-row",
                                    { staticClass: "px-2 pt-3 pb-5" },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "align-center justify-space-between pb-1",
                                          attrs: { cols: "12" }
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                position: "relative !important"
                                              }
                                            },
                                            [
                                              _c("fd-async-search-box", {
                                                class: [
                                                  _vm.$vuetify.breakpoint.xsOnly
                                                    ? "fd-inspection-scaffold-search-xs-margin"
                                                    : "fd-inspection-scaffold-search-margin",
                                                  !_vm.$vuetify.breakpoint
                                                    .xsOnly
                                                    ? "fd-inspection-scaffold-search-square-sides"
                                                    : "",
                                                  "mt-4",
                                                  "ml-4",
                                                  "mb-4",
                                                  "mr-md-4",
                                                  "fd-inspection-scaffold-search",
                                                  "fd-position-relative"
                                                ],
                                                attrs: {
                                                  "cache-items": false,
                                                  "data-cy": "scaffold-search",
                                                  flat: "",
                                                  "hide-details": "",
                                                  clearable: false,
                                                  label: _vm.showShortMessages
                                                    ? _vm.$t(
                                                        "inspections.new.scaffold-search-prompt-short"
                                                      )
                                                    : _vm.$t(
                                                        "inspections.new.scaffold-search-prompt"
                                                      ),
                                                  "solo-inverted": "",
                                                  "no-filter": "",
                                                  itemText: "description",
                                                  itemDetail: "details",
                                                  selectedItems:
                                                    _vm.selectedScaffold,
                                                  multiple: false,
                                                  items: _vm.availableScaffolds,
                                                  refreshItems:
                                                    _vm.loadScaffolds,
                                                  outlined: false,
                                                  disabled:
                                                    _vm.processing ||
                                                    !!_vm.selectedScaffold
                                                },
                                                on: {
                                                  "update:selectedItems": function(
                                                    $event
                                                  ) {
                                                    _vm.selectedScaffold = $event
                                                  },
                                                  "update:selected-items": function(
                                                    $event
                                                  ) {
                                                    _vm.selectedScaffold = $event
                                                  }
                                                },
                                                scopedSlots: _vm._u([
                                                  {
                                                    key: "append-inner",
                                                    fn: function() {
                                                      return undefined
                                                    },
                                                    proxy: true
                                                  }
                                                ])
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "v-btn",
                                                {
                                                  class: [
                                                    _vm.$vuetify.breakpoint
                                                      .smAndUp
                                                      ? ""
                                                      : "hidden",
                                                    "fd-scan-qr-code-button"
                                                  ],
                                                  attrs: {
                                                    "data-cy":
                                                      "scanqrcodescaffoldsection",
                                                    color: "success",
                                                    elevation: "1",
                                                    disabled:
                                                      _vm.processing ||
                                                      !!_vm.selectedScaffold
                                                  },
                                                  on: {
                                                    click: _vm.startQrScanner
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      staticClass: "mr-2",
                                                      attrs: { dark: "" }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                          mdi-qrcode\n                        "
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(
                                                    "\n                        " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "common.scan-qr-code"
                                                        )
                                                      ) +
                                                      "\n                      "
                                                  )
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              !!_vm.selectedScaffold &&
                                              !!_vm.canChangeScaffold
                                                ? _c(
                                                    "v-btn",
                                                    {
                                                      class: [
                                                        _vm.$vuetify.breakpoint
                                                          .smAndUp
                                                          ? ""
                                                          : "fd-small-history",
                                                        "white--text"
                                                      ],
                                                      staticStyle: {
                                                        position: "absolute",
                                                        top: "11px",
                                                        right: "249px"
                                                      },
                                                      attrs: {
                                                        color: "blue-grey",
                                                        small: "",
                                                        disabled:
                                                          !_vm.selectedScaffold ||
                                                          !_vm.canChangeScaffold ||
                                                          _vm.processing
                                                      },
                                                      on: {
                                                        click:
                                                          _vm.clearScaffoldSelection
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          class: [
                                                            _vm.$vuetify
                                                              .breakpoint
                                                              .smAndUp
                                                              ? ""
                                                              : "mr-0"
                                                          ],
                                                          attrs: { left: "" }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                          mdi-close-circle\n                        "
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _vm.$vuetify.breakpoint
                                                        .smAndUp
                                                        ? _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "common.clear"
                                                                )
                                                              )
                                                            )
                                                          ])
                                                        : _vm._e()
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-expand-transition",
                                        [
                                          (!!_vm.isScanningQR ||
                                            !!_vm.isLoadingQRScaffold) &&
                                          !_vm.selectedScaffold
                                            ? _c(
                                                "v-col",
                                                {
                                                  staticClass:
                                                    "align-center justify-space-between pb-1",
                                                  attrs: { cols: "12" }
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "mx-3",
                                                      staticStyle: {
                                                        height: "30vh",
                                                        "border-radius": "4px",
                                                        background:
                                                          "rgba(0,0,0,0.06)"
                                                      },
                                                      attrs: {
                                                        id: "video-container"
                                                      }
                                                    },
                                                    [
                                                      _vm.isLoadingQRScaffold
                                                        ? _c(
                                                            "v-container",
                                                            {
                                                              staticClass:
                                                                "pa-0 d-flex align-center",
                                                              staticStyle: {
                                                                height: "100%"
                                                              },
                                                              attrs: {
                                                                fluid: ""
                                                              }
                                                            },
                                                            [
                                                              _c("v-spacer"),
                                                              _vm._v(" "),
                                                              _c(
                                                                "v-progress-circular",
                                                                {
                                                                  attrs: {
                                                                    indeterminate:
                                                                      "",
                                                                    color:
                                                                      "secondary",
                                                                    size: 64,
                                                                    width: 8
                                                                  }
                                                                }
                                                              ),
                                                              _vm._v(" "),
                                                              _c("v-spacer")
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      _c("video", {
                                                        ref: "video",
                                                        staticStyle: {
                                                          width: "100%"
                                                        },
                                                        attrs: {
                                                          eager: "",
                                                          autoplay: "",
                                                          muted: "",
                                                          playsinline: "",
                                                          id: "video",
                                                          name: "video"
                                                        },
                                                        domProps: {
                                                          muted: true
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-expand-transition",
                                        [
                                          !!_vm.selectedScaffold
                                            ? _c(
                                                "v-col",
                                                {
                                                  staticClass:
                                                    "align-center justify-space-between pb-1",
                                                  attrs: { cols: "12" }
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "fd-details-section pt-3"
                                                    },
                                                    [
                                                      _c(
                                                        "v-row",
                                                        {
                                                          staticClass:
                                                            "mx-0 pl-0 pt-2 pb-3",
                                                          attrs: {
                                                            align: "center"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "6",
                                                                sm: "3"
                                                              }
                                                            },
                                                            [
                                                              _c("strong", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "scaffolds.tag-number-label"
                                                                    )
                                                                  )
                                                                )
                                                              ])
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "6",
                                                                sm: "3"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "fd-value-display",
                                                                {
                                                                  attrs: {
                                                                    value:
                                                                      _vm
                                                                        .selectedScaffold
                                                                        .tagNumber
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "6",
                                                                sm: "3"
                                                              }
                                                            },
                                                            [
                                                              _c("strong", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "scaffolds.status-label"
                                                                    )
                                                                  )
                                                                )
                                                              ])
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "6",
                                                                sm: "3"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "fd-value-display",
                                                                {
                                                                  attrs: {
                                                                    value: _vm.$t(
                                                                      `scaffolds.status.${_vm.selectedScaffold.scaffoldStatus}`
                                                                    )
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "6",
                                                                sm: "3"
                                                              }
                                                            },
                                                            [
                                                              _c("strong", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "scaffolds.planned-erect-date-label"
                                                                    )
                                                                  )
                                                                )
                                                              ])
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "6",
                                                                sm: "3"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "fd-value-display",
                                                                {
                                                                  attrs: {
                                                                    value: _vm.$format.date(
                                                                      _vm
                                                                        .selectedScaffold
                                                                        .plannedErectDate
                                                                    )
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "6",
                                                                sm: "3"
                                                              }
                                                            },
                                                            [
                                                              _c("strong", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "scaffolds.planned-dismantle-date-label"
                                                                    )
                                                                  )
                                                                )
                                                              ])
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "6",
                                                                sm: "3"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "fd-value-display",
                                                                {
                                                                  attrs: {
                                                                    value: _vm.$format.date(
                                                                      _vm
                                                                        .selectedScaffold
                                                                        .plannedDismantleDate
                                                                    )
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "6",
                                                                sm: "3"
                                                              }
                                                            },
                                                            [
                                                              _c("strong", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "scaffolds.actual-erect-date-label"
                                                                    )
                                                                  )
                                                                )
                                                              ])
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "6",
                                                                sm: "3"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "fd-value-display",
                                                                {
                                                                  attrs: {
                                                                    value: _vm.$format.date(
                                                                      _vm
                                                                        .selectedScaffold
                                                                        .actualErectDate
                                                                    )
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "6",
                                                                sm: "3"
                                                              }
                                                            },
                                                            [
                                                              _c("strong", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "scaffolds.actual-dismantle-date-label"
                                                                    )
                                                                  )
                                                                )
                                                              ])
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "6",
                                                                sm: "3"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "fd-value-display",
                                                                {
                                                                  attrs: {
                                                                    value: _vm.$format.date(
                                                                      _vm
                                                                        .selectedScaffold
                                                                        .actualDismantleDate
                                                                    )
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-row",
                                                        {
                                                          staticClass:
                                                            "mx-0 pl-0",
                                                          staticStyle: {
                                                            width: "100%"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              staticClass:
                                                                "pl-0",
                                                              attrs: {
                                                                cols: "12"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-row",
                                                                {
                                                                  staticClass:
                                                                    "mx-0 px-0",
                                                                  attrs: {
                                                                    "no-gutters":
                                                                      "",
                                                                    align:
                                                                      "center"
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-col",
                                                                    {
                                                                      staticClass:
                                                                        "pa-0",
                                                                      attrs: {
                                                                        align:
                                                                          "center",
                                                                        cols:
                                                                          "12"
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-subheader",
                                                                        {
                                                                          staticClass:
                                                                            "pa-0 mt-3",
                                                                          staticStyle: {
                                                                            "font-size":
                                                                              "1em",
                                                                            height:
                                                                              "32px"
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.$t(
                                                                                "scaffolds.location-sub-header"
                                                                              )
                                                                            )
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ],
                                                                1
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "v-row",
                                                                {
                                                                  staticClass:
                                                                    "mx-0 pl-0",
                                                                  attrs: {
                                                                    "no-gutters":
                                                                      "",
                                                                    align:
                                                                      "center"
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-divider",
                                                                    {
                                                                      staticClass:
                                                                        "mx-0 mt-0 px-0"
                                                                    }
                                                                  )
                                                                ],
                                                                1
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "v-row",
                                                                {
                                                                  staticClass:
                                                                    "mx-0 pl-0 pt-2 pb-3",
                                                                  attrs: {
                                                                    align:
                                                                      "center"
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-col",
                                                                    {
                                                                      attrs: {
                                                                        cols:
                                                                          "6",
                                                                        sm: "3"
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "strong",
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.$t(
                                                                                "scaffolds.area-label"
                                                                              )
                                                                            )
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "v-col",
                                                                    {
                                                                      attrs: {
                                                                        cols:
                                                                          "6",
                                                                        sm: "9"
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "fd-value-display",
                                                                        {
                                                                          attrs: {
                                                                            value: _vm.$lookup.location(
                                                                              _vm
                                                                                .selectedScaffold
                                                                                .areaID
                                                                            )
                                                                          }
                                                                        }
                                                                      )
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "v-col",
                                                                    {
                                                                      attrs: {
                                                                        cols:
                                                                          "6",
                                                                        sm: "3"
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "strong",
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.$t(
                                                                                "scaffolds.sub-area-label"
                                                                              )
                                                                            )
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "v-col",
                                                                    {
                                                                      attrs: {
                                                                        cols:
                                                                          "6",
                                                                        sm: "9"
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "fd-value-display",
                                                                        {
                                                                          attrs: {
                                                                            value: _vm.$lookup.location(
                                                                              _vm
                                                                                .selectedScaffold
                                                                                .subAreaID
                                                                            )
                                                                          }
                                                                        }
                                                                      )
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "v-col",
                                                                    {
                                                                      attrs: {
                                                                        cols:
                                                                          "6",
                                                                        sm: "3"
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "strong",
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.$t(
                                                                                "scaffolds.location-label"
                                                                              )
                                                                            )
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "v-col",
                                                                    {
                                                                      attrs: {
                                                                        cols:
                                                                          "6",
                                                                        sm: "9"
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "fd-value-display",
                                                                        {
                                                                          attrs: {
                                                                            value:
                                                                              _vm
                                                                                .selectedScaffold
                                                                                .specificWorkLocation
                                                                          }
                                                                        }
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-stepper-content",
                        {
                          staticClass: "pl-0 pr-0 pb-0",
                          staticStyle: { "padding-top": "2px" },
                          attrs: { step: _vm.selectDateStep }
                        },
                        [
                          _c(
                            "v-form",
                            {
                              ref: "selectdateform",
                              on: { submit: _vm.preventSubmit }
                            },
                            [
                              _c(
                                "v-card",
                                { attrs: { flat: "" } },
                                [
                                  _c(
                                    "v-row",
                                    { staticClass: "px-2 pt-3 pb-5" },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c(
                                            "sp-scaffold-inspection-time-segment-select",
                                            {
                                              attrs: {
                                                selectableTimeSegments:
                                                  _vm.selectableTimeSegments
                                              },
                                              model: {
                                                value: _vm.selectedTimeSegment,
                                                callback: function($$v) {
                                                  _vm.selectedTimeSegment = $$v
                                                },
                                                expression:
                                                  "selectedTimeSegment"
                                              }
                                            }
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  false
                                    ? _c(
                                        "v-row",
                                        { staticClass: "px-2 pt-3 pb-5" },
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12" } },
                                            [
                                              _c("fd-date-picker", {
                                                staticClass: "fd-readonly",
                                                attrs: {
                                                  label: _vm.$t("common.date"),
                                                  value:
                                                    _vm.inspection
                                                      .inspectionDateTime,
                                                  disabled: true,
                                                  readonly: true
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12" } },
                                            [
                                              _c("v-select", {
                                                attrs: {
                                                  outlined: "",
                                                  label: _vm.$t(
                                                    "inspections.inspection-segment-label"
                                                  ),
                                                  items: _vm.timeRanges,
                                                  "item-text": "description",
                                                  "return-object": ""
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "selection",
                                                      fn: function({ item }) {
                                                        return [
                                                          _c(
                                                            "v-row",
                                                            {
                                                              staticStyle: {
                                                                "padding-top":
                                                                  "10px"
                                                              },
                                                              attrs: {
                                                                "no-gutters": ""
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                          " +
                                                                  _vm._s(
                                                                    item.description
                                                                  ) +
                                                                  "\n                          "
                                                              ),
                                                              item.lastInspectionStatus !=
                                                              undefined
                                                                ? _c(
                                                                    "v-chip",
                                                                    {
                                                                      staticClass:
                                                                        "ml-3",
                                                                      staticStyle: {
                                                                        "margin-top":
                                                                          "-7px !important"
                                                                      },
                                                                      attrs: {
                                                                        color: _vm.inspectionColorForStatus(
                                                                          item.lastInspectionStatus
                                                                        )
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                            " +
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              `inspections.status.${item.lastInspectionStatus}`
                                                                            )
                                                                          ) +
                                                                          "\n                          "
                                                                      )
                                                                    ]
                                                                  )
                                                                : _vm._e()
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      }
                                                    },
                                                    {
                                                      key: "item",
                                                      fn: function({
                                                        item,
                                                        on,
                                                        attrs
                                                      }) {
                                                        return [
                                                          _c(
                                                            "v-list-item",
                                                            _vm._g(
                                                              _vm._b(
                                                                {},
                                                                "v-list-item",
                                                                attrs,
                                                                false
                                                              ),
                                                              on
                                                            ),
                                                            [
                                                              _c(
                                                                "v-list-item-content",
                                                                [
                                                                  _c(
                                                                    "v-row",
                                                                    {
                                                                      attrs: {
                                                                        "no-gutters":
                                                                          ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                              " +
                                                                          _vm._s(
                                                                            item.description
                                                                          ) +
                                                                          "\n                              "
                                                                      ),
                                                                      item.lastInspectionStatus !=
                                                                      undefined
                                                                        ? _c(
                                                                            "v-chip",
                                                                            {
                                                                              staticClass:
                                                                                "ml-3",
                                                                              staticStyle: {
                                                                                "margin-top":
                                                                                  "-2px !important"
                                                                              },
                                                                              attrs: {
                                                                                small:
                                                                                  "",
                                                                                color: _vm.inspectionColorForStatus(
                                                                                  item.lastInspectionStatus
                                                                                )
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n                                " +
                                                                                  _vm._s(
                                                                                    _vm.$t(
                                                                                      `inspections.status.${item.lastInspectionStatus}`
                                                                                    )
                                                                                  ) +
                                                                                  "\n                              "
                                                                              )
                                                                            ]
                                                                          )
                                                                        : _vm._e()
                                                                    ],
                                                                    1
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  false,
                                                  883259804
                                                ),
                                                model: {
                                                  value: _vm.selectedTimeRange,
                                                  callback: function($$v) {
                                                    _vm.selectedTimeRange = $$v
                                                  },
                                                  expression:
                                                    "selectedTimeRange"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-stepper-content",
                        {
                          staticClass: "pl-0 pr-0 pb-0",
                          staticStyle: { "padding-top": "2px" },
                          attrs: { step: _vm.answerQuestionsStep }
                        },
                        [
                          _c(
                            "v-form",
                            {
                              ref: "answerquestionsform",
                              on: { submit: _vm.preventSubmit }
                            },
                            [
                              _c(
                                "v-card",
                                { staticClass: "fd-inspection-new-dialogue" },
                                [
                                  _c(
                                    "v-row",
                                    {
                                      staticClass:
                                        "justify-end ml-0 mr-0 fd-inline-radio-button-qualifier-background fd-headerbar-filters fd-inspection-all-questions-yes"
                                    },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "d-flex align-center",
                                          attrs: { cols: "10", sm: "8" }
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "ml-3",
                                              staticStyle: {
                                                "font-weight": "bold"
                                              },
                                              style: _vm.showShortMessages
                                                ? "font-size: 1.2rem"
                                                : "font-size:1.5rem"
                                            },
                                            [
                                              _vm._v(
                                                "\n                      " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "inspections.inspection-new-dialogue-complies-with-all-checks"
                                                    )
                                                  ) +
                                                  "\n                    "
                                              )
                                            ]
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "pl-0 pl-sm-1",
                                          attrs: { cols: "2", sm: "4" }
                                        },
                                        [
                                          _c("v-switch", {
                                            staticClass: "mb-2 mt-2",
                                            attrs: { "hide-details": "" },
                                            model: {
                                              value:
                                                _vm.compliesWithAllRequirements,
                                              callback: function($$v) {
                                                _vm.compliesWithAllRequirements = $$v
                                              },
                                              expression:
                                                "compliesWithAllRequirements"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-expansion-panels",
                                    {
                                      attrs: { flat: "" },
                                      model: {
                                        value: _vm.questionsPanels,
                                        callback: function($$v) {
                                          _vm.questionsPanels = $$v
                                        },
                                        expression: "questionsPanels"
                                      }
                                    },
                                    [
                                      _c(
                                        "v-expansion-panel",
                                        [
                                          _c("v-expansion-panel-header", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "inspections.inspection-new-dialogue-questions-label"
                                                )
                                              )
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "v-expansion-panel-content",
                                            [
                                              _c(
                                                "v-row",
                                                { staticClass: "px-2 pt-3" },
                                                [
                                                  _vm._l(
                                                    _vm.questions,
                                                    function(question) {
                                                      return [
                                                        _c(
                                                          "v-col",
                                                          {
                                                            key: `text_${question.id}`,
                                                            staticClass:
                                                              "d-flex align-center pl-1 pl-sm-3",
                                                            attrs: {
                                                              cols: "10",
                                                              sm: "8"
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "ml-sm-3 ml-0",
                                                                staticStyle: {
                                                                  "font-weight":
                                                                    "bold"
                                                                },
                                                                style: _vm.showShortMessages
                                                                  ? "font-size: 1rem"
                                                                  : "font-size:1.3rem"
                                                              },
                                                              [
                                                                _c(
                                                                  "label",
                                                                  {
                                                                    attrs: {
                                                                      for: `switch_${question.id}`
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                                " +
                                                                        _vm._s(
                                                                          _vm.getQuestionText(
                                                                            question
                                                                          )
                                                                        ) +
                                                                        "\n                              "
                                                                    )
                                                                  ]
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "v-col",
                                                          {
                                                            key: `switch_${question.id}`,
                                                            attrs: {
                                                              cols: "2",
                                                              sm: "4"
                                                            }
                                                          },
                                                          [
                                                            _c("v-switch", {
                                                              staticClass:
                                                                "mb-2 mt-2",
                                                              attrs: {
                                                                id: `switch_${question.id}`,
                                                                "hide-details":
                                                                  ""
                                                              },
                                                              model: {
                                                                value:
                                                                  question.value,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    question,
                                                                    "value",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "question.value"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    }
                                                  )
                                                ],
                                                2
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-row",
                                    { staticClass: "px-2 pt-3" },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("fd-rich-textarea", {
                                            attrs: {
                                              label: _vm.$t(
                                                "common.additional-information"
                                              ),
                                              disabled: _vm.processing
                                            },
                                            model: {
                                              value:
                                                _vm.inspection
                                                  .additionalInformation,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.inspection,
                                                  "additionalInformation",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "inspection.additionalInformation"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-stepper-content",
                        {
                          staticClass: "pl-0 pr-0 pb-0",
                          staticStyle: { "padding-top": "2px" },
                          attrs: { step: _vm.takePhotoStep }
                        },
                        [
                          _c(
                            "v-form",
                            {
                              ref: "takephotoform",
                              on: { submit: _vm.preventSubmit }
                            },
                            [
                              _c(
                                "v-card",
                                { attrs: { flat: "" } },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pa-0",
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _c(
                                        "v-card-title",
                                        [
                                          _c("fd-add-file-button", {
                                            attrs: { disabled: _vm.processing },
                                            on: { change: _vm.selectNewFile }
                                          }),
                                          _vm._v(" "),
                                          _c("v-divider", {
                                            staticClass: "mx-4",
                                            attrs: { inset: "", vertical: "" }
                                          }),
                                          _vm._v(" "),
                                          _c("v-spacer"),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            {
                                              staticClass: "ml-3",
                                              staticStyle: {
                                                "font-weight": "bold"
                                              },
                                              style: _vm.showShortMessages
                                                ? "font-size: 0.85rem"
                                                : "font-size:1.3rem"
                                            },
                                            [
                                              _vm._v(
                                                "\n                      " +
                                                  _vm._s(
                                                    _vm.$vuetify.breakpoint
                                                      .xsOnly
                                                      ? _vm.$t(
                                                          "inspections.new.inspection-card-photo-instructions-short"
                                                        )
                                                      : _vm.$t(
                                                          "inspections.new.inspection-card-photo-instructions"
                                                        )
                                                  ) +
                                                  "\n                    "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("v-data-table", {
                                        directives: [
                                          {
                                            name: "fd-column",
                                            rawName: "v-fd-column:name",
                                            value: _vm.$t("common.name"),
                                            expression: "$t('common.name')",
                                            arg: "name"
                                          },
                                          {
                                            name: "fd-column",
                                            rawName:
                                              "v-fd-column:actions.no-sort",
                                            arg: "actions",
                                            modifiers: { "no-sort": true }
                                          }
                                        ],
                                        staticClass: "fd-actions-table",
                                        attrs: {
                                          items: _vm.files,
                                          search: _vm.tablesearchfiles,
                                          loading: _vm.processing,
                                          "loading-text": _vm.$t(
                                            "common.table-loading-message"
                                          ),
                                          "sort-by": ["name"],
                                          "mobile-breakpoint": "0"
                                        },
                                        on: {
                                          "dblclick:row": _vm.fileRowClicked
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "item.actions",
                                            fn: function({ item }) {
                                              return [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "table-3-actions-column-min-width"
                                                  },
                                                  [
                                                    _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: {
                                                          disabled: !_vm
                                                            .$vuetify.breakpoint
                                                            .lgAndUp,
                                                          left: ""
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function({
                                                                on
                                                              }) {
                                                                return [
                                                                  _c(
                                                                    "v-btn",
                                                                    _vm._g(
                                                                      {
                                                                        attrs: {
                                                                          icon:
                                                                            "",
                                                                          disabled:
                                                                            !item.isPreviewable ||
                                                                            _vm.processing
                                                                        }
                                                                      },
                                                                      on
                                                                    ),
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        {
                                                                          attrs: {
                                                                            small:
                                                                              ""
                                                                          },
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.viewFile(
                                                                                item
                                                                              )
                                                                            }
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                                mdi-eye\n                              "
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ]
                                                              }
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        )
                                                      },
                                                      [
                                                        _vm._v(" "),
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "common.view"
                                                              )
                                                            )
                                                          )
                                                        ])
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: {
                                                          disabled: !_vm
                                                            .$vuetify.breakpoint
                                                            .lgAndUp,
                                                          top: ""
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function({
                                                                on
                                                              }) {
                                                                return [
                                                                  _c(
                                                                    "v-btn",
                                                                    _vm._g(
                                                                      {
                                                                        attrs: {
                                                                          icon:
                                                                            "",
                                                                          disabled:
                                                                            !item.isPreviewable ||
                                                                            _vm.processing
                                                                        }
                                                                      },
                                                                      on
                                                                    ),
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        {
                                                                          attrs: {
                                                                            small:
                                                                              ""
                                                                          },
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.editFile(
                                                                                item
                                                                              )
                                                                            }
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                                mdi-pencil\n                              "
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ]
                                                              }
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        )
                                                      },
                                                      [
                                                        _vm._v(" "),
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "common.edit"
                                                              )
                                                            )
                                                          )
                                                        ])
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: {
                                                          disabled: !_vm
                                                            .$vuetify.breakpoint
                                                            .lgAndUp,
                                                          top: ""
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function({
                                                                on
                                                              }) {
                                                                return [
                                                                  _c(
                                                                    "v-btn",
                                                                    _vm._g(
                                                                      {
                                                                        attrs: {
                                                                          icon:
                                                                            "",
                                                                          disabled:
                                                                            _vm.processing
                                                                        }
                                                                      },
                                                                      on
                                                                    ),
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        {
                                                                          attrs: {
                                                                            small:
                                                                              ""
                                                                          },
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.removeFile(
                                                                                item
                                                                              )
                                                                            }
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                                mdi-delete\n                              "
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ]
                                                              }
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        )
                                                      },
                                                      [
                                                        _vm._v(" "),
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "common.remove"
                                                              )
                                                            )
                                                          )
                                                        ])
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ])
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-stepper-content",
                        {
                          staticClass: "pl-0 pr-0 pb-0",
                          staticStyle: { "padding-top": "2px" },
                          attrs: { step: _vm.resultStep }
                        },
                        [
                          _c(
                            "v-form",
                            {
                              ref: "resultform",
                              on: { submit: _vm.preventSubmit }
                            },
                            [
                              _c(
                                "v-card",
                                [
                                  _c(
                                    "v-row",
                                    {
                                      staticClass:
                                        "px-2 pt-3 d-flex justify-center"
                                    },
                                    [
                                      _c(
                                        "v-radio-group",
                                        {
                                          staticClass:
                                            "fd-scaffold-inspection-radio-group",
                                          model: {
                                            value: _vm.inspectionResult,
                                            callback: function($$v) {
                                              _vm.inspectionResult = $$v
                                            },
                                            expression: "inspectionResult"
                                          }
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                position: "relative"
                                              }
                                            },
                                            [
                                              _c("v-radio", {
                                                class: [
                                                  _vm.inspectionResult == "pass"
                                                    ? "fd-radio-inspection-pass"
                                                    : ""
                                                ],
                                                attrs: {
                                                  disabled: !_vm.canPassInspection,
                                                  label: _vm.$t(
                                                    "inspections.inspection-result-pass-label"
                                                  ),
                                                  value: "pass",
                                                  "data-cy": "inspectionpass"
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "v-icon",
                                                {
                                                  staticClass:
                                                    "fd-scaffold-inspection-radio-button-icon"
                                                },
                                                [_vm._v("fa-check")]
                                              )
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "mt-3",
                                              staticStyle: {
                                                position: "relative"
                                              }
                                            },
                                            [
                                              _c("v-radio", {
                                                class: [
                                                  _vm.inspectionResult == "fail"
                                                    ? "fd-radio-inspection-fail"
                                                    : ""
                                                ],
                                                attrs: {
                                                  label: _vm.$t(
                                                    "inspections.inspection-result-fail-label"
                                                  ),
                                                  value: "fail",
                                                  "data-cy": "inspectionfail"
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "v-icon",
                                                {
                                                  staticClass:
                                                    "fd-scaffold-inspection-radio-button-icon"
                                                },
                                                [_vm._v("fa-xmark")]
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.inlineMessage.message
                    ? _c(
                        "v-row",
                        { staticClass: "mx-2", attrs: { align: "center" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _vm.inlineMessage.message
                                ? _c(
                                    "v-alert",
                                    { attrs: { type: _vm.inlineMessage.type } },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "white-space": "pre-line"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.inlineMessage.message)
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    {
                      staticClass:
                        "mx-0 mb-3 d-flex d-sm-none justify-end align-center v-card__actions"
                    },
                    [
                      _vm.step == 1
                        ? _c(
                            "v-btn",
                            {
                              attrs: {
                                "data-cy": "scanqrcodescaffoldsectionsmall",
                                color: "success",
                                disabled:
                                  _vm.processing || !!_vm.selectedScaffold,
                                small: ""
                              },
                              on: { click: _vm.startQrScanner }
                            },
                            [
                              _c(
                                "v-icon",
                                { staticClass: "mr-2", attrs: { dark: "" } },
                                [
                                  _vm._v(
                                    "\n              mdi-qrcode\n            "
                                  )
                                ]
                              ),
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.$t("common.scan-qr-code")) +
                                  "\n          "
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          staticClass: "d-flex d-sm-none",
                          attrs: {
                            "data-cy": "save-small",
                            outlined: _vm.step != _vm.lastStep,
                            color: "primary",
                            loading: _vm.saving,
                            disabled: _vm.processing,
                            small: ""
                          },
                          on: { click: _vm.saveDialog }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("common.save-and-close")) +
                              "\n          "
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          staticClass: "d-none d-sm-flex",
                          attrs: {
                            "data-cy": "save",
                            outlined: _vm.step != _vm.lastStep,
                            color: "primary",
                            loading: _vm.saving,
                            disabled: _vm.processing,
                            small: _vm.$vuetify.breakpoint.xsOnly
                          },
                          on: { click: _vm.saveDialog }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("common.save-and-close")) +
                              "\n          "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "ml-4 mr-3 fd-button-context-separator-hide-when-mobile"
                        },
                        [_vm._v("|")]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            text: "",
                            small: _vm.$vuetify.breakpoint.xsOnly
                          },
                          on: { click: _vm.cancelDialog }
                        },
                        [_vm._v(_vm._s(_vm.$t("common.cancel")))]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            "data-cy": "continue",
                            disabled:
                              _vm.step == _vm.lastStep ||
                              _vm.processing ||
                              _vm.saving,
                            color: "primary",
                            small: _vm.$vuetify.breakpoint.xsOnly
                          },
                          on: {
                            click: function($event) {
                              _vm.step = Number(_vm.step) + 1
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("common.continue")) +
                              "\n          "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }