var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { cols: "2", sm: "2", md: "3" } }, [
            _vm._v(
              "\n      " +
                _vm._s(
                  _vm.$vuetify.breakpoint.mdAndUp
                    ? _vm.$t(
                        "scheduler.estimates.summary-panel.part-generation-type-label"
                      )
                    : _vm.$t(
                        "scheduler.estimates.summary-panel.part-generation-type-label-short"
                      )
                ) +
                "\n    "
            )
          ]),
          _vm._v(" "),
          _c("v-col", { attrs: { cols: "4", sm: "4", md: "3" } }, [
            _vm._v(
              "\n      " +
                _vm._s(_vm.currentEstimate.generationTypeName) +
                "\n      "
            ),
            _vm.currentEstimateIsLWH
              ? _c("div", { staticClass: "fd-item-detail-light" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.currentEstimateLWHSummary) +
                      "\n      "
                  )
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("v-col", {
            staticClass: "d-none d-md-flex",
            attrs: { cols: "0", sm: "0", md: "3" }
          }),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "6", sm: "6", md: "3" } },
            [
              _c("v-switch", {
                staticClass: "mt-0",
                attrs: {
                  readonly: true,
                  label: _vm.$t("scheduler.walkdown-hard-barricade"),
                  value: _vm.currentEstimate.isHardBarricade,
                  "hide-details": ""
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("v-divider"),
      _vm._v(" "),
      _c("v-simple-table", [
        _c("thead", [
          _c("tr", [
            _c(
              "th",
              { staticClass: "py-1", staticStyle: { border: "none" } },
              [
                _c(
                  "v-row",
                  {
                    attrs: {
                      "no-gutters": "",
                      justify: "start",
                      align: "center"
                    }
                  },
                  [
                    _c(
                      "v-radio-group",
                      {
                        attrs: { row: "" },
                        model: {
                          value: _vm.summaryPanelTimeUnitDivider,
                          callback: function($$v) {
                            _vm.summaryPanelTimeUnitDivider = $$v
                          },
                          expression: "summaryPanelTimeUnitDivider"
                        }
                      },
                      [
                        _c("v-radio", {
                          attrs: {
                            value: 1,
                            label: _vm.$t("common.minutes-short")
                          }
                        }),
                        _vm._v(" "),
                        _c("v-radio", {
                          attrs: { value: 60, label: _vm.$t("common.hours") }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c("th", { staticClass: "py-0", staticStyle: { border: "none" } }, [
              _c("div", { staticClass: "fd-estimate-summary-header-label" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(
                      _vm.$t(
                        "scheduler.estimates.summary-panel.walkdown-column-header"
                      )
                    ) +
                    "\n          "
                )
              ])
            ]),
            _vm._v(" "),
            _c("th", { staticClass: "py-0", staticStyle: { border: "none" } }, [
              _c("div", { staticClass: "fd-estimate-summary-header-label" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(
                      _vm.$t(
                        "scheduler.estimates.summary-panel.actuals-material-column-header"
                      )
                    ) +
                    "\n          "
                )
              ])
            ]),
            _vm._v(" "),
            _c("th", { staticClass: "py-0", staticStyle: { border: "none" } }, [
              _c("div", { staticClass: "fd-estimate-summary-header-label" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(
                      _vm.$t(
                        "scheduler.estimates.summary-panel.actuals-time-material-column-header"
                      )
                    ) +
                    "\n          "
                )
              ])
            ])
          ]),
          _vm._v(" "),
          _c("tr", [
            _c(
              "th",
              {
                staticStyle: { "border-top": "none" },
                attrs: { scope: "col" }
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.$t(
                        "scheduler.estimates.summary-panel.labour-summary-column-label"
                      )
                    ) +
                    "\n        "
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "th",
              {
                staticStyle: { "border-top": "none" },
                attrs: { scope: "col" }
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.$t(
                        "scheduler.estimates.summary-panel.time-column-label",
                        [_vm.summaryPanelTimeUnit]
                      )
                    ) +
                    "\n        "
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "th",
              {
                staticStyle: { "border-top": "none" },
                attrs: { scope: "col" }
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.$t(
                        "scheduler.estimates.summary-panel.time-column-label",
                        [_vm.summaryPanelTimeUnit]
                      )
                    ) +
                    "\n        "
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "th",
              {
                staticStyle: { "border-top": "none" },
                attrs: { scope: "col" }
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.$t(
                        "scheduler.estimates.summary-panel.time-column-label",
                        [_vm.summaryPanelTimeUnit]
                      )
                    ) +
                    "\n        "
                )
              ]
            )
          ])
        ]),
        _vm._v(" "),
        _c("tbody", [
          _c("tr", [
            _c("th", { attrs: { scope: "row" } }, [
              _vm._v(
                "\n          " +
                  _vm._s(
                    _vm.$t(
                      "scheduler.estimates.summary-panel.mobilization-label"
                    )
                  ) +
                  "\n        "
              )
            ]),
            _vm._v(" "),
            _c(
              "td",
              [
                _c("fd-value-display", {
                  staticStyle: { width: "fit-content" },
                  attrs: {
                    loading: _vm.loading,
                    value: _vm.getTimeDisplay(
                      _vm.currentEstimateTimeTotals
                        .estimatedTotalMobilizationMinutes
                    ),
                    "no-value-text": "0.00"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "td",
              [
                _c("fd-value-display", {
                  staticStyle: { width: "fit-content" },
                  attrs: {
                    value: _vm.getTimeDisplay(
                      _vm.workOrderActualsEstimatedTimes
                        .estimatedTotalMobilizationMinutes
                    ),
                    "no-value-text": "0.00"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c("td", [_c("fd-value-display")], 1)
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("th", { attrs: { scope: "row" } }, [
              _vm._v(
                "\n          " +
                  _vm._s(
                    _vm.$t("scheduler.estimates.summary-panel.erect-label")
                  ) +
                  "\n        "
              )
            ]),
            _vm._v(" "),
            _c(
              "td",
              [
                _c("fd-value-display", {
                  staticStyle: { width: "fit-content" },
                  attrs: {
                    loading: _vm.loading,
                    value: _vm.getTimeDisplay(
                      _vm.currentEstimateTimeTotals.estimatedTotalErectMinutes
                    ),
                    "no-value-text": "0.00"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "td",
              [
                _c("fd-value-display", {
                  staticStyle: { width: "fit-content" },
                  attrs: {
                    value: _vm.getTimeDisplay(
                      _vm.workOrderActualsEstimatedTimes
                        .estimatedTotalErectMinutes
                    ),
                    "no-value-text": "0.00"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "td",
              [
                _c("fd-value-display", {
                  staticStyle: { width: "fit-content" },
                  attrs: {
                    value: _vm.getTimeDisplay(
                      _vm.workOrderActualWorkedTimes.workedTotalErectMinutes,
                      2,
                      true
                    )
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("th", { attrs: { scope: "row" } }, [
              _vm._v(
                "\n          " +
                  _vm._s(
                    _vm.$t("scheduler.estimates.summary-panel.dismantle-label")
                  ) +
                  "\n        "
              )
            ]),
            _vm._v(" "),
            _c(
              "td",
              [
                _c("fd-value-display", {
                  staticStyle: { width: "fit-content" },
                  attrs: {
                    loading: _vm.loading,
                    value: _vm.getTimeDisplay(
                      _vm.currentEstimateTimeTotals
                        .estimatedTotalDismantleMinutes
                    ),
                    "no-value-text": "0.00"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "td",
              [
                _c("fd-value-display", {
                  staticStyle: { width: "fit-content" },
                  attrs: {
                    value: _vm.getTimeDisplay(
                      _vm.workOrderActualsEstimatedTimes
                        .estimatedTotalDismantleMinutes
                    ),
                    "no-value-text": "0.00"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "td",
              [
                _c("fd-value-display", {
                  staticStyle: { width: "fit-content" },
                  attrs: {
                    value: _vm.getTimeDisplay(
                      _vm.workOrderActualWorkedTimes
                        .workedTotalDismantleMinutes,
                      2,
                      true
                    )
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("th", { attrs: { scope: "row" } }, [
              _vm._v(
                "\n          " +
                  _vm._s(
                    _vm.$t(
                      "scheduler.estimates.summary-panel.demobilization-label"
                    )
                  ) +
                  "\n        "
              )
            ]),
            _vm._v(" "),
            _c(
              "td",
              [
                _c("fd-value-display", {
                  staticStyle: { width: "fit-content" },
                  attrs: {
                    loading: _vm.loading,
                    value: _vm.getTimeDisplay(
                      _vm.currentEstimateTimeTotals
                        .estimatedTotalDemobilizationMinutes
                    ),
                    "no-value-text": "0.00"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "td",
              [
                _c("fd-value-display", {
                  staticStyle: { width: "fit-content" },
                  attrs: {
                    value: _vm.getTimeDisplay(
                      _vm.workOrderActualsEstimatedTimes
                        .estimatedTotalDemobilizationMinutes
                    ),
                    "no-value-text": "0.00"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c("td", [_c("fd-value-display")], 1)
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("th", { attrs: { scope: "row" } }, [
              _vm._v(
                "\n          " +
                  _vm._s(
                    _vm.$t("scheduler.estimates.summary-panel.hoarding-label")
                  ) +
                  "\n        "
              )
            ]),
            _vm._v(" "),
            _c(
              "td",
              [
                _c("fd-value-display", {
                  staticStyle: { width: "fit-content" },
                  attrs: {
                    loading: _vm.loading,
                    value: _vm.getTimeDisplay(
                      _vm.currentEstimateTimeTotals
                        .estimatedTotalHoardingMinutes
                    ),
                    "no-value-text": "0.00"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "td",
              [
                _c("fd-value-display", {
                  staticStyle: { width: "fit-content" },
                  attrs: {
                    value: _vm.getTimeDisplay(
                      _vm.workOrderActualsEstimatedTimes
                        .estimatedTotalHoardingMinutes
                    ),
                    "no-value-text": "0.00"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c("td", [_c("fd-value-display")], 1)
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("th", { attrs: { scope: "row" } }, [
              _vm._v(
                "\n          " +
                  _vm._s(
                    _vm.$t("scheduler.estimates.summary-panel.modify-label")
                  ) +
                  "\n        "
              )
            ]),
            _vm._v(" "),
            _c(
              "td",
              [
                _c("fd-value-display", {
                  staticStyle: { width: "fit-content" },
                  attrs: {
                    loading: _vm.loading,
                    value: _vm.getTimeDisplay(
                      _vm.currentEstimateTimeTotals.estimatedTotalModifyMinutes
                    ),
                    "no-value-text": "0.00"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "td",
              [
                _c("fd-value-display", {
                  staticStyle: { width: "fit-content" },
                  attrs: {
                    value: _vm.getTimeDisplay(
                      _vm.workOrderActualsEstimatedTimes
                        .estimatedTotalModifyMinutes
                    ),
                    "no-value-text": "0.00"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "td",
              [
                _c("fd-value-display", {
                  staticStyle: { width: "fit-content" },
                  attrs: {
                    value: _vm.getTimeDisplay(
                      _vm.workOrderActualWorkedTimes.workedTotalModifyMinutes,
                      2,
                      true
                    )
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("th", { attrs: { scope: "row" } }, [
              _vm._v(
                "\n          " +
                  _vm._s(
                    _vm.$t("scheduler.estimates.summary-panel.total-label")
                  ) +
                  "\n        "
              )
            ]),
            _vm._v(" "),
            _c("td", [
              _c(
                "span",
                { staticClass: "fd-estimate-summary-estimated-time-callout" },
                [
                  _c("fd-value-display", {
                    staticStyle: { width: "fit-content" },
                    attrs: {
                      loading: _vm.loading,
                      value: _vm.getTimeDisplay(
                        _vm.currentEstimateTimeTotals.estimatedTotalTime
                      ),
                      "no-value-text": "0.00"
                    }
                  })
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("td", [
              _c(
                "span",
                { staticClass: "fd-estimate-summary-estimated-time-callout" },
                [
                  _c("fd-value-display", {
                    staticStyle: { width: "fit-content" },
                    attrs: {
                      value: _vm.getTimeDisplay(
                        _vm.workOrderActualsEstimatedTimes.estimatedTotalMinutes
                      ),
                      "no-value-text": "0.00"
                    }
                  })
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("td", [
              _c(
                "span",
                { staticClass: "fd-estimate-summary-estimated-time-callout" },
                [
                  _c("fd-value-display", {
                    staticStyle: { width: "fit-content" },
                    attrs: {
                      value: _vm.getTimeDisplay(
                        _vm.workOrderActualWorkedTimes.workedTotalMinutes,
                        2,
                        true
                      )
                    }
                  })
                ],
                1
              )
            ])
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("th", { attrs: { scope: "row" } }, [
              _vm._v(
                "\n          " +
                  _vm._s(
                    _vm.$t("scheduler.estimates.summary-panel.weight-label")
                  ) +
                  "\n        "
              )
            ]),
            _vm._v(" "),
            _c(
              "td",
              [
                _c("fd-value-display", {
                  staticStyle: { width: "fit-content" },
                  attrs: {
                    loading: _vm.loading,
                    value: _vm.formatNumber(
                      _vm.currentEstimateTimeTotals.estimatedTotalWeight,
                      0
                    ),
                    "no-value-text": "0"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "td",
              [
                _c("fd-value-display", {
                  staticStyle: { width: "fit-content" },
                  attrs: {
                    value: _vm.formatNumber(
                      _vm.workOrderActualsEstimatedTimes.actualTotalWeight,
                      0
                    ),
                    "no-value-text": "0"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "td",
              [
                _c("fd-value-display", {
                  staticStyle: { width: "fit-content" },
                  attrs: {
                    value: _vm.formatNumber(
                      _vm.workOrderActualWorkedTimes.totalWeight,
                      0,
                      true
                    )
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("th", { attrs: { scope: "row" } }, [
              _vm._v(
                "\n          " +
                  _vm._s(
                    _vm.$t("scheduler.estimates.summary-panel.pieces-label")
                  ) +
                  "\n        "
              )
            ]),
            _vm._v(" "),
            _c(
              "td",
              [
                _c("fd-value-display", {
                  staticStyle: { width: "fit-content" },
                  attrs: {
                    loading: _vm.loading,
                    value: _vm.formatNumber(
                      _vm.currentEstimateTimeTotals.estimatedTotalPartCount,
                      0
                    ),
                    "no-value-text": "0"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "td",
              [
                _c("fd-value-display", {
                  staticStyle: { width: "fit-content" },
                  attrs: {
                    value: _vm.formatNumber(
                      _vm.workOrderActualsEstimatedTimes.actualTotalPartCount,
                      0
                    ),
                    "no-value-text": "0"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "td",
              [
                _c("fd-value-display", {
                  staticStyle: { width: "fit-content" },
                  attrs: {
                    value: _vm.formatNumber(
                      _vm.workOrderActualWorkedTimes.totalPartCount,
                      0,
                      true
                    )
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("th", { attrs: { scope: "row" } }, [
              _vm._v(
                "\n          " +
                  _vm._s(
                    _vm.$t("scheduler.estimates.summary-panel.erect-mpp-label")
                  ) +
                  "\n        "
              )
            ]),
            _vm._v(" "),
            _c("td", [
              _c(
                "span",
                { staticClass: "fd-estimate-summary-estimated-time-callout" },
                [
                  _c("fd-value-display", {
                    staticStyle: { width: "fit-content" },
                    attrs: {
                      loading: _vm.loading,
                      value: _vm.formatNumber(
                        _vm.currentEstimateTimeTotals.estimatedErectMPP
                      ),
                      "no-value-text": "0.00"
                    }
                  })
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("td", [
              _c(
                "span",
                { staticClass: "fd-estimate-summary-estimated-time-callout" },
                [
                  _c("fd-value-display", {
                    staticStyle: { width: "fit-content" },
                    attrs: {
                      value: _vm.formatNumber(
                        _vm.workOrderActualsEstimatedTimes.erectMinsPerPiece
                      ),
                      "no-value-text": "0.00"
                    }
                  })
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("td", [
              _c(
                "span",
                { staticClass: "fd-estimate-summary-estimated-time-callout" },
                [
                  _c("fd-value-display", {
                    staticStyle: { width: "fit-content" },
                    attrs: {
                      value: _vm.formatNumber(
                        _vm.workOrderActualWorkedTimes.erectMinsPerPiece,
                        2,
                        true
                      )
                    }
                  })
                ],
                1
              )
            ])
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("th", { attrs: { scope: "row" } }, [
              _vm._v(
                "\n          " +
                  _vm._s(
                    _vm.$t(
                      "scheduler.estimates.summary-panel.dismantle-mpp-label"
                    )
                  ) +
                  "\n        "
              )
            ]),
            _vm._v(" "),
            _c("td", [
              _c(
                "span",
                { staticClass: "fd-estimate-summary-estimated-time-callout" },
                [
                  _c("fd-value-display", {
                    staticStyle: { width: "fit-content" },
                    attrs: {
                      loading: _vm.loading,
                      value: _vm.formatNumber(
                        _vm.currentEstimateTimeTotals.estimatedDismantleMPP
                      ),
                      "no-value-text": "0.00"
                    }
                  })
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("td", [
              _c(
                "span",
                { staticClass: "fd-estimate-summary-estimated-time-callout" },
                [
                  _c("fd-value-display", {
                    staticStyle: { width: "fit-content" },
                    attrs: {
                      value: _vm.formatNumber(
                        _vm.workOrderActualsEstimatedTimes.dismantleMinsPerPiece
                      ),
                      "no-value-text": "0.00"
                    }
                  })
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("td", [
              _c(
                "span",
                { staticClass: "fd-estimate-summary-estimated-time-callout" },
                [
                  _c("fd-value-display", {
                    staticStyle: { width: "fit-content" },
                    attrs: {
                      value: _vm.formatNumber(
                        _vm.workOrderActualWorkedTimes.dismantleMinsPerPiece,
                        2,
                        true
                      )
                    }
                  })
                ],
                1
              )
            ])
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }