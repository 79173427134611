var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c("fd-image-viewer", {
        attrs: { name: _vm.imageName },
        model: {
          value: _vm.imageSource,
          callback: function($$v) {
            _vm.imageSource = $$v
          },
          expression: "imageSource"
        }
      }),
      _vm._v(" "),
      _c("fd-image-editor", {
        attrs: { name: _vm.imageName },
        on: { edit: _vm.handleEdit },
        model: {
          value: _vm.editImageSource,
          callback: function($$v) {
            _vm.editImageSource = $$v
          },
          expression: "editImageSource"
        }
      }),
      _vm._v(" "),
      _c(
        "v-form",
        { ref: "form", on: { submit: _vm.onSubmit } },
        [
          _c(
            "v-slide-x-reverse-transition",
            [
              _c(
                "v-card",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.slidein,
                      expression: "slidein"
                    }
                  ]
                },
                [
                  _c(
                    "v-toolbar",
                    {
                      class: [
                        _vm.processing
                          ? "view-card-top-color-processing"
                          : "view-card-top-color"
                      ]
                    },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c(
                                "v-row",
                                { attrs: { align: "center" } },
                                [
                                  _c("v-breadcrumbs", {
                                    staticClass: "d-none d-sm-flex",
                                    class: [
                                      _vm.processing
                                        ? "breadcrumb-processing-opacity"
                                        : "",
                                      "pl-3"
                                    ],
                                    attrs: {
                                      items:
                                        _vm.$store.state.currentBreadcrumbs,
                                      large: ""
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "divider",
                                        fn: function() {
                                          return [
                                            _c("v-icon", [
                                              _vm._v("fa-chevron-right")
                                            ])
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ])
                                  }),
                                  _vm._v(" "),
                                  _c("fd-back-button", {
                                    attrs: {
                                      route: item =>
                                        _vm.$router.push(`/inspections/`)
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      class: [
                                        {
                                          "ml-2": _vm.$vuetify.breakpoint.xsOnly
                                        },
                                        "py-1",
                                        "px-3",
                                        "fd-status-indicator"
                                      ]
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            !_vm.scaffoldInspection
                                              .scaffoldActualDismantleDate
                                              ? _vm.$t(
                                                  "scaffolds.existing-scaffold.currently-standing-days-message",
                                                  [
                                                    _vm.scaffoldInspection
                                                      .daysStanding
                                                  ]
                                                )
                                              : _vm.$t(
                                                  "scaffolds.existing-scaffold.no-longer-standing-days-message",
                                                  [
                                                    _vm.scaffoldInspection
                                                      .daysStanding
                                                  ]
                                                )
                                          ) +
                                          "\n                "
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("v-spacer"),
                                  _vm._v(" "),
                                  _vm.processing
                                    ? _c("v-progress-circular", {
                                        staticClass: "mr-3",
                                        attrs: {
                                          indeterminate: true,
                                          rotate: 0,
                                          size: 32,
                                          width: 4,
                                          color: "white"
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-container",
                    { staticClass: "py-0 px-0", attrs: { fluid: "" } },
                    [
                      _c(
                        "v-tabs",
                        {
                          ref: "tabs",
                          staticClass: "pt-3",
                          attrs: { "icons-and-text": "", "show-arrows": "" },
                          model: {
                            value: _vm.active_tab,
                            callback: function($$v) {
                              _vm.active_tab = $$v
                            },
                            expression: "active_tab"
                          }
                        },
                        [
                          _c(
                            "v-tab",
                            {
                              key: _vm.detailsTab.key,
                              ref: "tab",
                              attrs: { href: `#tab-${_vm.detailsTab.key}` },
                              on: {
                                click: function($event) {
                                  return _vm.tabSelected(_vm.detailsTab)
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.$t("inspections.existing.tabs.details")
                                  ) +
                                  "\n              "
                              ),
                              _c("v-icon", [_vm._v("fas fa-edit")])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.questionsTab.visible ||
                          _vm.$vuetify.breakpoint.smAndUp
                            ? _c(
                                "v-tab",
                                {
                                  key: _vm.questionsTab.key,
                                  attrs: {
                                    "data-cy": "questions",
                                    href: `#tab-${_vm.questionsTab.key}`
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.tabSelected(_vm.questionsTab)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.$t(
                                          "inspections.existing.tabs.questions"
                                        )
                                      ) +
                                      "\n              "
                                  ),
                                  _c("v-icon", [
                                    _vm._v("fas fa-clipboard-question")
                                  ])
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.photosTab.visible ||
                          _vm.$vuetify.breakpoint.smAndUp
                            ? _c(
                                "v-tab",
                                {
                                  key: _vm.photosTab.key,
                                  attrs: {
                                    "data-cy": "photos",
                                    href: `#tab-${_vm.photosTab.key}`
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.tabSelected(_vm.photosTab)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.$t(
                                          "inspections.existing.tabs.photos"
                                        )
                                      ) +
                                      "\n              "
                                  ),
                                  _c("v-icon", [_vm._v("fas fa-camera")])
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.locationTab.visible ||
                          _vm.$vuetify.breakpoint.smAndUp
                            ? _c(
                                "v-tab",
                                {
                                  key: _vm.locationTab.key,
                                  attrs: {
                                    "data-cy": "location",
                                    href: `#tab-${_vm.locationTab.key}`
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.tabSelected(_vm.locationTab)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.$t(
                                          "inspections.existing.tabs.location"
                                        )
                                      ) +
                                      "\n              "
                                  ),
                                  _c("v-icon", [_vm._v("fas fa-map-location")])
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "v-menu",
                            {
                              attrs: { bottom: "", left: "" },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function({ on, attrs }) {
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass:
                                                "align-self-center mr-4 d-flex d-sm-none more-tab-button",
                                              staticStyle: {
                                                position: "relative"
                                              },
                                              attrs: { text: "" }
                                            },
                                            "v-btn",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(_vm.$t("common.more")) +
                                              "\n\n                  "
                                          ),
                                          _c(
                                            "div",
                                            [
                                              _c("v-icon", [
                                                _vm._v("fas fa-ellipsis-h")
                                              ])
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    ]
                                  }
                                }
                              ])
                            },
                            [
                              _vm._v(" "),
                              _c(
                                "v-list",
                                { staticClass: "grey lighten-3" },
                                _vm._l(_vm.hiddenTabDefinitions, function(
                                  item
                                ) {
                                  return _c(
                                    "v-list-item",
                                    {
                                      key: item.tabname,
                                      on: {
                                        click: function($event) {
                                          return _vm.showNewTabFromMoreMenu(
                                            item
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(item.tabname) +
                                          "\n                "
                                      )
                                    ]
                                  )
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-tabs-items",
                        {
                          model: {
                            value: _vm.active_tab,
                            callback: function($$v) {
                              _vm.active_tab = $$v
                            },
                            expression: "active_tab"
                          }
                        },
                        [
                          _c(
                            "v-tab-item",
                            {
                              key: _vm.detailsTab.key,
                              attrs: { value: `tab-${_vm.detailsTab.key}` }
                            },
                            [
                              _c(
                                "v-form",
                                {
                                  ref: "detailsform",
                                  staticClass: "fd-readonly",
                                  on: { submit: _vm.preventSubmit }
                                },
                                [
                                  _c(
                                    "v-row",
                                    {
                                      staticClass:
                                        "mx-0 px-2 mb-3 fd-inline-request-tags-qualifier",
                                      attrs: { align: "center" }
                                    },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "py-0",
                                          attrs: { cols: "12" }
                                        },
                                        [
                                          _c("fd-chip-selector", {
                                            attrs: {
                                              "available-items":
                                                _vm.availableTags,
                                              "selected-items":
                                                _vm.selectedTags,
                                              "item-label": "name",
                                              disabled: _vm.processing,
                                              "control-label": _vm.$t(
                                                "common.add-tags"
                                              ),
                                              "chip-class":
                                                "keywords-selection-chip-colors",
                                              outlined: false
                                            },
                                            on: {
                                              "update:selectedItems": function(
                                                $event
                                              ) {
                                                _vm.selectedTags = $event
                                              },
                                              "update:selected-items": function(
                                                $event
                                              ) {
                                                _vm.selectedTags = $event
                                              }
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-row",
                                    {
                                      staticClass: "mx-2 pt-5",
                                      attrs: { align: "center" }
                                    },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "pa-0 ma-0 order-1 order-sm-0",
                                          attrs: { cols: "12", sm: "6" }
                                        },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "justify-space-between pt-1 pb-1",
                                              attrs: {
                                                align: "center",
                                                cols: "12",
                                                sm: "12"
                                              }
                                            },
                                            [
                                              _c("fd-text-field", {
                                                attrs: {
                                                  "data-cy": "tag-number",
                                                  label: _vm.$t(
                                                    "scaffolds.tag-number-label"
                                                  ),
                                                  value:
                                                    _vm.scaffoldInspection
                                                      .tagNumber,
                                                  disabled: true,
                                                  readonly: true
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "justify-space-between pt-1 pb-1",
                                              attrs: {
                                                align: "center",
                                                cols: "12",
                                                sm: "12"
                                              }
                                            },
                                            [
                                              _c(
                                                "v-row",
                                                { staticClass: "my-0 py-0" },
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass:
                                                        "justify-space-between pt-1 pb-1",
                                                      attrs: {
                                                        align: "center",
                                                        cols: "6",
                                                        sm: "6"
                                                      }
                                                    },
                                                    [
                                                      _c("fd-date-picker", {
                                                        attrs: {
                                                          outlined: "",
                                                          cy: "inspecteddate",
                                                          disabled: true,
                                                          readonly: true,
                                                          label: _vm.$t(
                                                            "inspections.inspection-date-label"
                                                          ),
                                                          value:
                                                            _vm
                                                              .scaffoldInspection
                                                              .inspectionDateTime
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass:
                                                        "justify-space-between pt-1 pb-1",
                                                      attrs: {
                                                        align: "center",
                                                        cols: "6",
                                                        sm: "6"
                                                      }
                                                    },
                                                    [
                                                      _c("fd-time-picker", {
                                                        attrs: {
                                                          outlined: "",
                                                          cy: "inspectedtime",
                                                          disabled: true,
                                                          readonly: true,
                                                          label: _vm.$t(
                                                            "inspections.inspection-time-label"
                                                          ),
                                                          value:
                                                            _vm
                                                              .scaffoldInspection
                                                              .inspectionDateTime
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "justify-space-between pt-1 pb-1",
                                              attrs: {
                                                align: "center",
                                                cols: "12",
                                                sm: "12"
                                              }
                                            },
                                            [
                                              _c("fd-text-field", {
                                                attrs: {
                                                  "data-cy": "status",
                                                  label: _vm.$t(
                                                    "inspections.inspected-by-label"
                                                  ),
                                                  disabled: true,
                                                  readonly: true,
                                                  value: _vm.$lookup.person(
                                                    _vm.scaffoldInspection
                                                      .inspectedBy
                                                  )
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "fd-scaffold-inspection-result order-0 order-sm-1 mb-3",
                                          attrs: {
                                            cols: "12",
                                            sm: "6",
                                            align: "center"
                                          }
                                        },
                                        [
                                          !_vm.scaffoldInspection ||
                                          _vm.scaffoldInspection
                                            .inspectionDidPass === undefined
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "fd-inspection-placeholder",
                                                  staticStyle: {
                                                    position: "relative"
                                                  }
                                                },
                                                [_c("span", [_vm._v(" ")])]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          !!_vm.scaffoldInspection
                                            .inspectionDidPass
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "fd-inspection-pass",
                                                  staticStyle: {
                                                    position: "relative"
                                                  }
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "inspections.inspection-result-pass-label"
                                                        )
                                                      )
                                                    )
                                                  ]),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      staticClass:
                                                        "fd-scaffold-inspection-result-icon"
                                                    },
                                                    [_vm._v("fa-check")]
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          !!_vm.scaffoldInspection.id &&
                                          !_vm.scaffoldInspection
                                            .inspectionDidPass
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "fd-inspection-fail",
                                                  staticStyle: {
                                                    position: "relative"
                                                  }
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "inspections.inspection-result-fail-label"
                                                        )
                                                      )
                                                    )
                                                  ]),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      staticClass:
                                                        "fd-scaffold-inspection-result-icon"
                                                    },
                                                    [_vm._v("fa-xmark")]
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-row",
                                    {
                                      staticClass: "mx-3",
                                      attrs: { align: "center" }
                                    },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "pa-0",
                                          attrs: { align: "center", cols: "12" }
                                        },
                                        [
                                          _c(
                                            "v-subheader",
                                            {
                                              staticClass: "pa-0 mt-3",
                                              staticStyle: {
                                                "font-size": "1em",
                                                height: "32px"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "inspections.existing.location-and-work-info-section-label"
                                                  )
                                                )
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-row",
                                    {
                                      staticClass: "mx-2 pt-5",
                                      attrs: { align: "center" }
                                    },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "justify-space-between pt-1 pb-1",
                                          attrs: {
                                            align: "center",
                                            cols: "12",
                                            md: "6"
                                          }
                                        },
                                        [
                                          _c("fd-text-field", {
                                            attrs: {
                                              "data-cy": "area",
                                              label: _vm.$t(
                                                "scaffolds.area-label"
                                              ),
                                              disabled: true,
                                              readonly: true,
                                              value: _vm.$lookup.location(
                                                _vm.scaffoldInspection
                                                  .scaffoldAreaID
                                              )
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "justify-space-between pt-1 pb-1",
                                          attrs: {
                                            align: "center",
                                            cols: "12",
                                            md: "6"
                                          }
                                        },
                                        [
                                          _c("fd-text-field", {
                                            attrs: {
                                              "data-cy": "subarea",
                                              label: _vm.$t(
                                                "scaffolds.sub-area-label"
                                              ),
                                              disabled: true,
                                              readonly: true,
                                              value: _vm.$lookup.location(
                                                _vm.scaffoldInspection
                                                  .scaffoldSubAreaID
                                              )
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _vm.curUserCanViewAllContractors ||
                                      _vm.curUserContractorIDs.length != 1
                                        ? _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "justify-space-between pt-1 pb-1",
                                              attrs: {
                                                align: "center",
                                                cols: "12",
                                                md: "6"
                                              }
                                            },
                                            [
                                              _c("fd-text-field", {
                                                attrs: {
                                                  "data-cy": "contractor",
                                                  label: _vm.$t(
                                                    "scaffolds.contractor-label"
                                                  ),
                                                  disabled: true,
                                                  readonly: true,
                                                  value: _vm.$lookup.contractor(
                                                    _vm.scaffoldInspection
                                                      .scaffoldContractorID
                                                  )
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "justify-space-between pt-1 pb-1",
                                          attrs: {
                                            align: "center",
                                            cols: "12",
                                            sm: "6"
                                          }
                                        },
                                        [
                                          _c("fd-text-field", {
                                            attrs: {
                                              "data-cy": "status",
                                              label: _vm.$t(
                                                "scaffolds.status-label"
                                              ),
                                              disabled: true,
                                              readonly: true,
                                              value: !!_vm.scaffoldInspection
                                                .scaffoldStatus
                                                ? _vm.$t(
                                                    `scaffolds.status.${_vm.scaffoldInspection.scaffoldStatus}`
                                                  )
                                                : undefined
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "justify-space-between pt-0 pb-2",
                                          attrs: {
                                            align: "center",
                                            cols: "12",
                                            sm: "12"
                                          }
                                        },
                                        [
                                          _c("v-switch", {
                                            attrs: {
                                              "data-cy": "archived",
                                              label: _vm.$t(
                                                "common.archived-label"
                                              ),
                                              disabled: _vm.processing
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-tab-item",
                            {
                              key: _vm.questionsTab.key,
                              attrs: { value: `tab-${_vm.questionsTab.key}` }
                            },
                            [
                              _c(
                                "v-card",
                                { attrs: { flat: "", tile: "" } },
                                [
                                  _c(
                                    "v-row",
                                    { staticClass: "px-2 pt-3" },
                                    [
                                      _vm._l(
                                        _vm.scaffoldInspection.questionAnswers,
                                        function(questionWithAnswer) {
                                          return [
                                            _c(
                                              "v-col",
                                              {
                                                key: `text_${questionWithAnswer.id}`,
                                                staticClass:
                                                  "d-flex align-center",
                                                attrs: { cols: "9", sm: "8" }
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "ml-3",
                                                    staticStyle: {
                                                      "font-weight": "bold"
                                                    },
                                                    style: !_vm.$vuetify
                                                      .breakpoint.smAndUp
                                                      ? "font-size: 1rem"
                                                      : "font-size:1.3rem"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                        " +
                                                        _vm._s(
                                                          _vm.getQuestionText(
                                                            questionWithAnswer
                                                          )
                                                        ) +
                                                        "\n                      "
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "v-col",
                                              {
                                                key: `switch_${questionWithAnswer.id}`,
                                                attrs: { cols: "3", sm: "4" }
                                              },
                                              [
                                                _c("v-switch", {
                                                  staticClass: "ml-3",
                                                  attrs: {
                                                    "input-value": _vm.parseBooleanAnswer(
                                                      questionWithAnswer.answer
                                                    ),
                                                    readonly: true
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      )
                                    ],
                                    2
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-row",
                                    { staticClass: "px-2 pt-3" },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("fd-rich-textarea", {
                                            attrs: {
                                              label: _vm.$t(
                                                "common.additional-information"
                                              ),
                                              disabled: _vm.processing,
                                              value:
                                                _vm.scaffoldInspection
                                                  .additionalInformation,
                                              readonly: true
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-tab-item",
                            {
                              key: _vm.photosTab.key,
                              attrs: { value: `tab-${_vm.photosTab.key}` }
                            },
                            [
                              _c(
                                "v-form",
                                {
                                  ref: "filesform",
                                  on: { submit: _vm.preventSubmit }
                                },
                                [
                                  _c(
                                    "v-row",
                                    {
                                      staticClass: "mx-0",
                                      attrs: { align: "center" }
                                    },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "pa-0",
                                          attrs: { cols: "12" }
                                        },
                                        [
                                          _c(
                                            "v-card-title",
                                            [
                                              _c("fd-add-file-button", {
                                                attrs: {
                                                  disabled:
                                                    _vm.detailsReadonly ||
                                                    _vm.processing
                                                },
                                                on: {
                                                  change: _vm.selectNewFile
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c("v-divider", {
                                                staticClass: "mx-4",
                                                attrs: {
                                                  inset: "",
                                                  vertical: ""
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "v-tooltip",
                                                {
                                                  attrs: { top: "" },
                                                  scopedSlots: _vm._u([
                                                    {
                                                      key: "activator",
                                                      fn: function({ on }) {
                                                        return [
                                                          _c(
                                                            "v-icon",
                                                            _vm._g(
                                                              {
                                                                staticClass:
                                                                  "ml-1 mr-3",
                                                                attrs: {
                                                                  color:
                                                                    "black",
                                                                  dark: ""
                                                                }
                                                              },
                                                              on
                                                            ),
                                                            [_vm._v("info")]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ])
                                                },
                                                [
                                                  _vm._v(" "),
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "scaffold-requests.photos-table-listing-tooltip"
                                                        )
                                                      )
                                                    )
                                                  ])
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c("v-spacer"),
                                              _vm._v(" "),
                                              _c("v-text-field", {
                                                staticClass: "pl-0",
                                                attrs: {
                                                  "append-icon": "search",
                                                  label: _vm.$t(
                                                    "common.search"
                                                  ),
                                                  "single-line": "",
                                                  "hide-details": "",
                                                  clearable: ""
                                                },
                                                model: {
                                                  value: _vm.tablesearchfiles,
                                                  callback: function($$v) {
                                                    _vm.tablesearchfiles = $$v
                                                  },
                                                  expression: "tablesearchfiles"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c("v-data-table", {
                                            directives: [
                                              {
                                                name: "fd-column",
                                                rawName: "v-fd-column:name",
                                                value: _vm.$t("common.name"),
                                                expression: "$t('common.name')",
                                                arg: "name"
                                              },
                                              {
                                                name: "fd-column",
                                                rawName:
                                                  "v-fd-column:actions.no-sort.hide-when-extra-small.class_fd-actions-cell",
                                                arg: "actions",
                                                modifiers: {
                                                  "no-sort": true,
                                                  "hide-when-extra-small": true,
                                                  "class_fd-actions-cell": true
                                                }
                                              },
                                              {
                                                name: "fd-column",
                                                rawName:
                                                  "v-fd-column:action.no-sort.show-when-extra-small.class_fd-actions-cell-edit-only",
                                                arg: "action",
                                                modifiers: {
                                                  "no-sort": true,
                                                  "show-when-extra-small": true,
                                                  "class_fd-actions-cell-edit-only": true
                                                }
                                              }
                                            ],
                                            class: _vm.$vuetify.breakpoint
                                              .xsOnly
                                              ? "fd-action-table"
                                              : "fd-actions-table",
                                            attrs: {
                                              items: _vm.photoFiles,
                                              search: _vm.tablesearchfiles,
                                              loading: _vm.processing,
                                              "loading-text": _vm.$t(
                                                "common.table-loading-message"
                                              ),
                                              "sort-by": ["name"],
                                              "item-class": item =>
                                                item.name == _vm.touchedFileName
                                                  ? "fd-selected-table-row-background"
                                                  : "",
                                              "mobile-breakpoint": "0"
                                            },
                                            on: {
                                              "dblclick:row": (e, data) =>
                                                _vm.viewFile(data.item)
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "item.action",
                                                fn: function({ item }) {
                                                  return [
                                                    _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: {
                                                          disabled:
                                                            !_vm.$vuetify
                                                              .breakpoint
                                                              .lgAndUp ||
                                                            _vm.processing,
                                                          left: ""
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function({
                                                                on
                                                              }) {
                                                                return [
                                                                  _c(
                                                                    "v-btn",
                                                                    _vm._g(
                                                                      {
                                                                        attrs: {
                                                                          icon:
                                                                            "",
                                                                          disabled:
                                                                            !item.isPreviewable ||
                                                                            _vm.processing
                                                                        }
                                                                      },
                                                                      on
                                                                    ),
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        {
                                                                          attrs: {
                                                                            small:
                                                                              ""
                                                                          },
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.viewFile(
                                                                                item
                                                                              )
                                                                            }
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                                mdi-eye\n                              "
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ]
                                                              }
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        )
                                                      },
                                                      [
                                                        _vm._v(" "),
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "common.view"
                                                              )
                                                            )
                                                          )
                                                        ])
                                                      ]
                                                    )
                                                  ]
                                                }
                                              },
                                              {
                                                key: "item.actions",
                                                fn: function({ item }) {
                                                  return [
                                                    _c(
                                                      "div",
                                                      {
                                                        class: [
                                                          {
                                                            "table-5-actions-column-min-width":
                                                              _vm.$vuetify
                                                                .breakpoint
                                                                .mdAndUp,
                                                            "table-4-actions-column-min-width": !_vm
                                                              .$vuetify
                                                              .breakpoint
                                                              .mdAndUp
                                                          }
                                                        ]
                                                      },
                                                      [
                                                        _c(
                                                          "v-tooltip",
                                                          {
                                                            attrs: {
                                                              disabled:
                                                                !_vm.$vuetify
                                                                  .breakpoint
                                                                  .lgAndUp ||
                                                                _vm.processing,
                                                              left: ""
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key:
                                                                    "activator",
                                                                  fn: function({
                                                                    on
                                                                  }) {
                                                                    return [
                                                                      _c(
                                                                        "v-btn",
                                                                        _vm._g(
                                                                          {
                                                                            attrs: {
                                                                              icon:
                                                                                "",
                                                                              disabled:
                                                                                _vm.processing
                                                                            }
                                                                          },
                                                                          on
                                                                        ),
                                                                        [
                                                                          _c(
                                                                            "v-icon",
                                                                            {
                                                                              attrs: {
                                                                                small:
                                                                                  ""
                                                                              },
                                                                              on: {
                                                                                click: function(
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.openFileInNewWindow(
                                                                                    item
                                                                                  )
                                                                                }
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n                                  mdi-open-in-new\n                                "
                                                                              )
                                                                            ]
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ]
                                                                  }
                                                                }
                                                              ],
                                                              null,
                                                              true
                                                            )
                                                          },
                                                          [
                                                            _vm._v(" "),
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "common.open-in-new-tab"
                                                                  )
                                                                )
                                                              )
                                                            ])
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "v-tooltip",
                                                          {
                                                            attrs: {
                                                              disabled:
                                                                !_vm.$vuetify
                                                                  .breakpoint
                                                                  .lgAndUp ||
                                                                _vm.processing,
                                                              left: ""
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key:
                                                                    "activator",
                                                                  fn: function({
                                                                    on
                                                                  }) {
                                                                    return [
                                                                      _c(
                                                                        "v-btn",
                                                                        _vm._g(
                                                                          {
                                                                            attrs: {
                                                                              icon:
                                                                                "",
                                                                              disabled:
                                                                                !item.isPreviewable ||
                                                                                _vm.processing
                                                                            }
                                                                          },
                                                                          on
                                                                        ),
                                                                        [
                                                                          _c(
                                                                            "v-icon",
                                                                            {
                                                                              attrs: {
                                                                                small:
                                                                                  ""
                                                                              },
                                                                              on: {
                                                                                click: function(
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.viewFile(
                                                                                    item
                                                                                  )
                                                                                }
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n                                  mdi-eye\n                                "
                                                                              )
                                                                            ]
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ]
                                                                  }
                                                                }
                                                              ],
                                                              null,
                                                              true
                                                            )
                                                          },
                                                          [
                                                            _vm._v(" "),
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "common.view"
                                                                  )
                                                                )
                                                              )
                                                            ])
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "v-tooltip",
                                                          {
                                                            attrs: {
                                                              disabled:
                                                                !_vm.$vuetify
                                                                  .breakpoint
                                                                  .lgAndUp ||
                                                                _vm.processing,
                                                              top: ""
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key:
                                                                    "activator",
                                                                  fn: function({
                                                                    on
                                                                  }) {
                                                                    return [
                                                                      _c(
                                                                        "v-btn",
                                                                        _vm._g(
                                                                          {
                                                                            attrs: {
                                                                              icon:
                                                                                "",
                                                                              disabled:
                                                                                !item.isPreviewable ||
                                                                                _vm.processing
                                                                            }
                                                                          },
                                                                          on
                                                                        ),
                                                                        [
                                                                          _c(
                                                                            "v-icon",
                                                                            {
                                                                              attrs: {
                                                                                small:
                                                                                  ""
                                                                              },
                                                                              on: {
                                                                                click: function(
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.editFile(
                                                                                    item
                                                                                  )
                                                                                }
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n                                  mdi-pencil\n                                "
                                                                              )
                                                                            ]
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ]
                                                                  }
                                                                }
                                                              ],
                                                              null,
                                                              true
                                                            )
                                                          },
                                                          [
                                                            _vm._v(" "),
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "common.edit"
                                                                  )
                                                                )
                                                              )
                                                            ])
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _vm.$vuetify.breakpoint
                                                          .mdAndUp
                                                          ? _c(
                                                              "v-tooltip",
                                                              {
                                                                attrs: {
                                                                  disabled:
                                                                    !_vm
                                                                      .$vuetify
                                                                      .breakpoint
                                                                      .lgAndUp ||
                                                                    _vm.processing,
                                                                  top: ""
                                                                },
                                                                scopedSlots: _vm._u(
                                                                  [
                                                                    {
                                                                      key:
                                                                        "activator",
                                                                      fn: function({
                                                                        on
                                                                      }) {
                                                                        return [
                                                                          _c(
                                                                            "v-btn",
                                                                            _vm._g(
                                                                              {
                                                                                attrs: {
                                                                                  icon:
                                                                                    "",
                                                                                  link:
                                                                                    "",
                                                                                  disabled:
                                                                                    _vm.processing
                                                                                }
                                                                              },
                                                                              on
                                                                            ),
                                                                            [
                                                                              _c(
                                                                                "v-icon",
                                                                                {
                                                                                  attrs: {
                                                                                    small:
                                                                                      ""
                                                                                  },
                                                                                  on: {
                                                                                    click: function(
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.downloadFile(
                                                                                        item
                                                                                      )
                                                                                    }
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "\n                                  mdi-cloud-download\n                                "
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ],
                                                                            1
                                                                          )
                                                                        ]
                                                                      }
                                                                    }
                                                                  ],
                                                                  null,
                                                                  true
                                                                )
                                                              },
                                                              [
                                                                _vm._v(" "),
                                                                _c("span", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "common.download"
                                                                      )
                                                                    )
                                                                  )
                                                                ])
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        _vm._v(" "),
                                                        _c(
                                                          "v-tooltip",
                                                          {
                                                            attrs: {
                                                              disabled:
                                                                !_vm.$vuetify
                                                                  .breakpoint
                                                                  .lgAndUp ||
                                                                _vm.processing,
                                                              top: ""
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key:
                                                                    "activator",
                                                                  fn: function({
                                                                    on
                                                                  }) {
                                                                    return [
                                                                      _c(
                                                                        "v-btn",
                                                                        _vm._g(
                                                                          {
                                                                            attrs: {
                                                                              icon:
                                                                                "",
                                                                              disabled:
                                                                                _vm.processing
                                                                            }
                                                                          },
                                                                          on
                                                                        ),
                                                                        [
                                                                          _c(
                                                                            "v-icon",
                                                                            {
                                                                              attrs: {
                                                                                small:
                                                                                  ""
                                                                              },
                                                                              on: {
                                                                                click: function(
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.deleteFile(
                                                                                    item
                                                                                  )
                                                                                }
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n                                  mdi-delete\n                                "
                                                                              )
                                                                            ]
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ]
                                                                  }
                                                                }
                                                              ],
                                                              null,
                                                              true
                                                            )
                                                          },
                                                          [
                                                            _vm._v(" "),
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "common.remove"
                                                                  )
                                                                )
                                                              )
                                                            ])
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                }
                                              }
                                            ])
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-tab-item",
                            {
                              key: _vm.locationTab.key,
                              attrs: { value: `tab-${_vm.locationTab.key}` }
                            },
                            [
                              _c(
                                "v-card",
                                {
                                  staticClass: "fd-inpection-map",
                                  attrs: { flat: "" }
                                },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pa-0",
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _c("fd-scaffold-inspection-location", {
                                        attrs: {
                                          latitude:
                                            _vm.scaffoldInspection.latitude,
                                          longitude:
                                            _vm.scaffoldInspection.longitude
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.inlineMessage.message
                        ? _c(
                            "v-row",
                            { staticClass: "mx-0", attrs: { align: "center" } },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _vm.inlineMessage.message
                                    ? _c(
                                        "v-alert",
                                        {
                                          staticClass: "mr-0",
                                          attrs: {
                                            type: _vm.inlineMessage.type
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm.inlineMessage.message
                                              ) +
                                              "\n              "
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "v-card-actions",
                        { staticClass: "mx-1" },
                        [
                          _c("v-spacer"),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              staticClass: "mr-2",
                              attrs: {
                                text: "",
                                link: "",
                                disabled: _vm.processing,
                                small: _vm.$vuetify.breakpoint.name == "xs"
                              },
                              on: { click: _vm.cancel }
                            },
                            [_vm._v(_vm._s(_vm.$t("common.cancel")))]
                          ),
                          _vm._v(" "),
                          _c("fd-menu-save", {
                            attrs: {
                              disabled: _vm.processing,
                              loading: _vm.saving,
                              small: _vm.$vuetify.breakpoint.xsOnly
                            },
                            on: {
                              "click:save": function($event) {
                                return _vm.save(false)
                              },
                              "click:save-and-close": function($event) {
                                return _vm.save(true)
                              }
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }