import FDVue from "@fd/lib/vue";
import rules from "@fd/lib/vue/rules";
import store from "../store";
import { Tag, WorkPackage } from "../services";

type WorkPackageWithExtraDetails = WorkPackage & {
  tags: Tag[];
  archived: boolean;
};

// TODO: Review this code - why a mixin? Replace with configuration method use?
export default FDVue.extend({
  mixins: [rules],
  data() {
    return {
      workPackageID: "",
      workPackage: {
        activityID: "",
        name: "",
        startDate: null as Date | null | undefined,
        finishDate: null as Date | null | undefined,
        completionPercentage: null as number | null | undefined,
        workStatus: "",
        tags: [] as Tag[],
        archived: false
      } as WorkPackageWithExtraDetails
    };
  },
  computed: {
    workPackageRules() {
      return {
        activityID: [],
        name: [this.rules.required],
        startDate: [],
        finishDate: [],
        completionPercentage: [this.rules.numeric],
        status: [this.rules.required]
      };
    },
    allWorkPackages() {
      return store.state.workPackages.fullList;
    }
  },
  methods: {},
  created() {}
});
