export default {
  maintenance: "fas fa-toolbox",
  paint: "fas fa-brush",
  insulation: "fas fa-circle-dot",
  scaffolds: "fas fa-layer-group",
  inspections: "fas fa-clipboard-check",
  "scaffold-designs": "fas fa-compass-drafting",
  "inspection-time-sunrise": "mdi-weather-sunset-up",
  "inspection-time-day": "mdi-white-balance-sunny",
  "inspection-time-sunset": "mdi-weather-sunset-down",
  "inspection-time-night": "mdi-moon-waning-crescent"
} as { [key: string]: string };

