<template>
    <v-container fluid >
        <v-form ref="form" v-on:submit="onSubmit">
            <v-slide-x-reverse-transition>
                <v-card v-show="slidein">
                    <v-toolbar v-bind:class="[processing ? 'view-card-top-color-processing' : 'view-card-top-color']">
                        <!--View Title-->
                        <v-row>
                            <v-col cols="12">
                                <v-row align="center">
                                    <v-breadcrumbs
                                            :items="$store.state.currentBreadcrumbs"
                                            large
                                            v-bind:class="[processing ? 'breadcrumb-processing-opacity' : '', 'pl-3']"
                                    >
                                        <template v-slot:divider>
                                            <v-icon>fa-chevron-right</v-icon>
                                        </template>
                                    </v-breadcrumbs>
                                    <v-spacer></v-spacer>
                                    <v-progress-circular
                                            :indeterminate="true"
                                            :rotate="0"
                                            :size="32"
                                            :width="4"
                                            color="white"
                                            class="mr-3"
                                            v-if="processing"
                                    ></v-progress-circular>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-toolbar>

                    <v-container fluid>
                        <v-row>
                            <!--Estimate Summary-->
                            <!--This estimate summary panel is meant to be visible on the left side of this view when the browser is-->
                            <!--large enough in width to support it. Otherwise it will disappear to be replaced by an additional-->
                            <!--expander that will contain its contents-->
                            <v-col cols="12" sm="12" md="12" lg="3" class="hide-when-medium">
                                <v-card class="estimate-summation">
                                    <v-card-title class="subheading font-weight-bold">{{ $t('estimates.summation-title') }}</v-card-title>
                                    <v-divider></v-divider>
                                    <v-row
                                            v-for="item in estimatesummation"
                                            :key="item.name"
                                    >
                                        <v-col cols="8">
                                            <v-row>
                                                {{ $t('estimates.' + item.name) }}
                                            </v-row>
                                        </v-col>
                                        <v-col cols="4">
                                            <v-row justify="end">
                                                <span :class="{ 'card-positive-highlight-coloring' : item.hightlight}">{{ item.value }}</span>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-card>
                            </v-col>
                            <!--Expansion Panels for the View.-->
                            <v-col cols="12" sm="12" md="12" lg="9">
                                <v-expansion-panels multiple>
                                    <!--This expansion panel holds to the summary information for the estimate.-->
                                    <!--It is ONLY meant to be visible at responsive sizes to give the user a better interface at those sizes.-->
                                    <v-expansion-panel class="hide-when-large">
                                        <v-expansion-panel-header>{{ $t('estimates.summation-title') }}</v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <v-col cols="12" class="pt-0 pb-0 pl-7">
                                                <v-row
                                                        v-for="item in estimatesummation"
                                                        :key="item.name"
                                                >
                                                    <v-col cols="8">
                                                        <v-row>
                                                            {{ $t('estimates.' + item.name) }}
                                                        </v-row>
                                                    </v-col>
                                                    <v-col cols="4">
                                                        <v-row justify="end">
                                                            <span :class="{ 'card-positive-highlight-coloring' : item.hightlight}">{{ item.value }}</span>
                                                        </v-row>
                                                    </v-col>
                                                </v-row>
                                            </v-col>

                                        </v-expansion-panel-content>
                                    </v-expansion-panel>

                                    <!--Basic Details for the Estimate-->
                                    <v-expansion-panel class="mt-lg-0">
                                        <v-expansion-panel-header
                                                :disable-icon-rotate="simulatederror"
                                        >
                                            {{ $t('estimates.enter-details') }}
                                        <template v-slot:actions>
                                            <v-icon color="error" v-if="simulatederror">mdi-alert-circle</v-icon>
                                        </template>
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <v-col cols="12" class="pt-0">
                                                <!--Tag Selection-->
                                                <v-row class="pt-1 pb-0">
                                                    <v-col cols="12">
                                                        <fd-chip-selector
                                                                v-bind:available-items="availableTagsForOverallEstimate"
                                                                v-bind:selected-items.sync="selectedTagsForOverallEstimate"
                                                                item-label="name"
                                                                v-bind:disabled="processing"
                                                                v-bind:control-label="$t('common.add-tags')"
                                                                chip-class="keywords-selection-chip-colors"
                                                        />
                                                    </v-col>
                                                </v-row>
                                                <v-row class="pt-0 pb-0 pl-0 pr-0">
                                                    <v-col cols="12" sm="12" md="6">
                                                        <v-text-field
                                                                v-bind:label="$t('estimates.tagnumberlong')"
                                                                v-model="estimate.tagnumber"
                                                                v-bind:disabled="processing"
                                                                v-bind:rules="[rules.required]"
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" sm="12" md="6">
                                                        <v-text-field
                                                                v-bind:label="$t('estimates.estimated-by')"
                                                                v-model="estimate.estimatedby"
                                                                v-bind:disabled="processing"
                                                        ></v-text-field>
                                                    </v-col>
                                                </v-row>

                                                <v-row class="pt-0">
                                                    <v-col cols="12" sm="12" md="6" class="pt-0">
                                                        <v-select
                                                                v-model="estimate.clientID"
                                                                item-text="name"
                                                                item-value="id"
                                                                v-bind:items="clients"
                                                                v-bind:label="$t('estimates.client')"
                                                                v-bind:disabled="processing"
                                                                v-bind:rules="[rules.required]"
                                                        ></v-select>
                                                    </v-col>
                                                    <v-col cols="12" sm="12" md="6" class="pt-0">
                                                        <v-select
                                                                v-model="estimate.projectID"
                                                                item-text="name"
                                                                item-value="id"
                                                                v-bind:items="projects"
                                                                v-bind:label="$t('estimates.project')"
                                                                v-bind:disabled="processing"
                                                                v-bind:rules="[rules.required]"
                                                        ></v-select>
                                                    </v-col>
                                                </v-row>

                                                <v-row class="pt-0">
                                                    <v-col cols="12" sm="12" md="6" class="pt-0">
                                                        <v-select
                                                                v-model="estimate.areaID"
                                                                item-text="name"
                                                                item-value="id"
                                                                v-bind:items="areas"
                                                                v-bind:label="$t('estimates.area')"
                                                                v-bind:disabled="processing"
                                                        ></v-select>
                                                    </v-col>
                                                    <v-col cols="12" sm="12" md="6" class="pt-0">
                                                        <v-select
                                                                v-model="estimate.subareaID"
                                                                item-text="name"
                                                                item-value="id"
                                                                v-bind:items="subareas"
                                                                v-bind:label="$t('estimates.subarea')"
                                                                v-bind:disabled="processing"
                                                        ></v-select>
                                                    </v-col>
                                                </v-row>

                                                <v-row class="pt-0">
                                                    <v-col cols="12" sm="12" md="6" class="pt-0">
                                                        <v-menu
                                                                v-model="validfrommenu"
                                                                :close-on-content-click="false"
                                                                :nudge-right="40"
                                                                transition="scale-transition"
                                                                offset-y
                                                                min-width="290px"
                                                        >
                                                            <template v-slot:activator="{ on }">
                                                                <v-text-field
                                                                        v-bind:label="$t('estimates.validfrom')"
                                                                        v-bind:value="formatedValidFromDate"
                                                                        prepend-icon="event"
                                                                        readonly
                                                                        v-on="on"
                                                                ></v-text-field>
                                                            </template>
                                                            <v-date-picker v-model="estimate.validfrom" @input="validfrommenu = false" :locale="i18nLocale"></v-date-picker>
                                                        </v-menu>
                                                    </v-col>
                                                    <v-col cols="12" sm="12" md="6" class="pt-0">
                                                        <v-menu
                                                                v-model="validuntilmenu"
                                                                :close-on-content-click="false"
                                                                :nudge-right="40"
                                                                transition="scale-transition"
                                                                offset-y
                                                                min-width="290px"
                                                        >
                                                            <template v-slot:activator="{ on }">
                                                                <v-text-field
                                                                        v-bind:label="$t('estimates.validuntil')"
                                                                        v-bind:value="formatedValidUntilDate"
                                                                        prepend-icon="event"
                                                                        readonly
                                                                        v-on="on"
                                                                ></v-text-field>
                                                            </template>
                                                            <v-date-picker v-model="estimate.validuntil" @input="validuntilmenu = false" :locale="i18nLocale"></v-date-picker>
                                                        </v-menu>
                                                    </v-col>
                                                </v-row>

                                                <v-row class="pt-0">
                                                    <v-col cols="12" sm="12" md="6" class="pt-0">
                                                        <v-select
                                                                v-model="estimate.regionID"
                                                                item-text="name"
                                                                item-value="id"
                                                                v-bind:items="regions"
                                                                v-bind:label="$t('clients.projects.region')"
                                                                v-bind:disabled="processing"
                                                                clearable
                                                        ></v-select>
                                                    </v-col>
                                                </v-row>

                                                <v-row class="pt-0">
                                                    <v-col cols="12" sm="12" md="6" class="pt-0">
                                                        <v-text-field
                                                                :label="$t('clients.projects.productivity')"
                                                                v-model.number="estimate.productivity"
                                                                :disabled="processing"
                                                                :rules="[rules.numbersonly]"
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" sm="12" md="6" class="pt-0">
                                                        <v-text-field
                                                                :label="$t('clients.projects.labourrate')"
                                                                v-model.number="estimate.labourrate"
                                                                :disabled="processing"
                                                                :rules="[rules.numbersonly]"
                                                        ></v-text-field>
                                                    </v-col>
                                                </v-row>

                                            </v-col>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>

                                    <!--Parts selected for the Estimate-->
                                    <v-expansion-panel>
                                        <v-expansion-panel-header
                                                :disable-icon-rotate="simulatederror"
                                        >
                                            {{ $t('estimates.parts-selection') }}
                                            <template v-slot:actions>
                                                <v-icon color="error" v-if="simulatederror">mdi-alert-circle</v-icon>
                                            </template>
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content class="v-expansion-panel-padding-override">
                                            <!--<v-card>-->
                                                <v-col cols="12" class="secondary-stepper-section pt-5">
                                                    <v-textarea
                                                            :label="$t('estimates.parts-selection-bcounts-paste-placeholder')"
                                                            outlined
                                                            rows="1"
                                                            persistent-hint
                                                            :hint="$t('estimates.parts-selection-bcounts-paste-hint')"
                                                            :disabled="processing"
                                                    ></v-textarea>
                                                </v-col>
                                                <v-col v-if="simulatederror && !allErroredPartsThatRemainHaveBeenIgnored && erroredparts.length > 0" cols="12" class="secondary-stepper-section pb-1">
                                                    <v-alert  type="error">
                                                        {{ $t('estimates.parts-selection-inline-error-message') }}
                                                    </v-alert>
                                                </v-col>
                                                <v-col v-if="simulatederror && allErroredPartsThatRemainHaveBeenIgnored" cols="12" class="secondary-stepper-section pb-1">
                                                    <v-alert  type="info">
                                                        {{ $t('estimates.parts-selection-inline-all-remaining-parts-ignored-message') }}
                                                    </v-alert>
                                                </v-col>
                                                <v-col v-if="simulatederror && erroredparts.length == 0" cols="12" class="secondary-stepper-section pb-1">
                                                    <v-alert  type="success">
                                                        {{ $t('estimates.parts-selection-inline-all-errored-parts-added-to-catalog') }}
                                                    </v-alert>
                                                </v-col>
                                                <v-col v-if="simulatederror && erroredparts.length > 0" cols="12" class="secondary-stepper-section pb-7 pt-0">
                                                    <!--Datatable for the parts errors-->
                                                    <v-data-table
                                                            :headers="computedHeadersForErroredParts"
                                                            :items="erroredparts"
                                                            :search="tablesearch"
                                                            :single-expand="singleExpand"
                                                            :expanded.sync="expanded"
                                                            item-key="name"
                                                            :show-expand="showExpand"
                                                            :footer-props="{'items-per-page-options': [1, 5]}"
                                                            :items-per-page="5"
                                                            mobile-breakpoint="0"
                                                            class="fd-actions-table fd-warnings-table"
                                                    >
                                                        <!--    The following will change the headers for the table so they are internationalized.-->
                                                        <template v-slot:header.name="{ header }">
                                                            {{ $t('common.' + header.value) }}
                                                        </template>
                                                        <template v-slot:header.quantity="{ header }">
                                                            {{ $t('common.' + header.value) }}
                                                        </template>
                                                        <template v-slot:header.data-table-expand>
                                                            {{ $t('common.actions') }}
                                                        </template>

                                                        <!--The following is the template for the expanded row for an errored part added by bcount.-->
                                                        <template v-slot:item.data-table-expand="{ item, isExpanded, expand }">
                                                            <div class="table-actions-column-min-width">
                                                                <v-tooltip top>
                                                                    <template v-slot:activator="{ on }">
                                                                        <v-btn
                                                                                icon
                                                                                link
                                                                                v-on="on"
                                                                                @click="expand(!isExpanded)"
                                                                                v-bind:loading="saving"
                                                                                v-bind:disabled="processing"
                                                                        >
                                                                            <v-icon
                                                                                    small
                                                                                    v-if="!isExpanded"
                                                                            >
                                                                                fa-plus-circle
                                                                            </v-icon>
                                                                            <v-icon
                                                                                    small
                                                                                    v-else
                                                                            >
                                                                                fa-times-circle
                                                                            </v-icon>
                                                                        </v-btn>
                                                                    </template>
                                                                    <span v-if="!isExpanded">{{ $t('estimates.parts-selection-individual-part-actions-add-part-to-catalog') }}</span>
                                                                    <span v-else>{{ $t('estimates.parts-selection-individual-part-actions-cancel-adding-part-to-catalog') }}</span>
                                                                </v-tooltip>
                                                                <v-tooltip top>
                                                                    <template v-slot:activator="{ on }">
                                                                        <v-btn
                                                                                icon
                                                                                v-bind:disabled="processing"
                                                                                v-on="on"
                                                                        >
                                                                            <v-icon
                                                                                    small
                                                                                    @click="ignorePart(item)"
                                                                            >
                                                                                fa-ban
                                                                            </v-icon>
                                                                        </v-btn>
                                                                    </template>
                                                                    <span>{{ $t('estimates.parts-selection-individual-part-actions-ignore-part') }}</span>
                                                                </v-tooltip>
                                                            </div>
                                                        </template>

                                                        <!--The following is the template for the expanded row.-->
                                                        <template v-slot:expanded-item="{ headers, item }">
                                                            <td
                                                                    :colspan="headers.length"
                                                                    class="table-expanded-row-background-color"
                                                            >
                                                                <v-container style="padding: 0px;">
                                                                    <v-row>
                                                                        <v-col
                                                                                class="align-center justify-space-between"
                                                                                cols="12"
                                                                        >
                                                                            <v-row
                                                                                    align="center"
                                                                                    class="mr-0 ml-0 mt-3"
                                                                            >
                                                                            </v-row>
                                                                            <fd-chip-selector
                                                                                    v-bind:available-items="availableTagsForPartsSelection"
                                                                                    v-bind:selected-items.sync="selectedTagsForPartsSelection"
                                                                                    item-label="name"
                                                                                    v-bind:disabled="processing"
                                                                                    v-bind:control-label="$t('common.add-tags')"
                                                                                    chip-class="keywords-selection-chip-colors"
                                                                            />
                                                                        </v-col>
                                                                        <v-col cols="12">
                                                                            <v-row align="center" class="mr-0 ml-0">
                                                                                <fd-chip-selector
                                                                                        v-bind:available-items="availableSuppliersForPartsSelection"
                                                                                        v-bind:selected-items.sync="selectedSuppliersForPartsSelection"
                                                                                        item-label="alias"
                                                                                        v-bind:disabled="processing"
                                                                                        v-bind:control-label="$t('common.add-suppliers')"
                                                                                        chip-class="keywords-selection-chip-colors-secondary"
                                                                                />
                                                                            </v-row>
                                                                        </v-col>
                                                                        <v-col
                                                                                class="align-center justify-space-between"
                                                                                cols="12" sm="12" md="6"
                                                                        >
                                                                            <v-row
                                                                                    align="center"
                                                                                    class="mr-0 ml-0"
                                                                            >
                                                                                <v-text-field
                                                                                        v-bind:label="$t('common.name')"
                                                                                        v-model="part.name"
                                                                                        :rules="[rules.required]"
                                                                                        v-bind:disabled="processing"
                                                                                ></v-text-field>
                                                                            </v-row>
                                                                        </v-col>
                                                                        <v-col cols="12" sm="12" md="6">
                                                                            <v-row
                                                                                    align="center"
                                                                                    class="mr-0 ml-0"
                                                                            >
                                                                                <v-text-field
                                                                                        v-bind:label="$t('common.description')"
                                                                                        v-model="part.description"
                                                                                        v-bind:disabled="processing"
                                                                                        counter
                                                                                        maxlength="30"
                                                                                ></v-text-field>
                                                                            </v-row>
                                                                        </v-col>
                                                                        <v-col
                                                                                class="align-center justify-space-between"
                                                                                cols="12" sm="12" md="6"
                                                                        >
                                                                            <v-row
                                                                                    align="center"
                                                                                    class="mr-0 ml-0"
                                                                            >
                                                                                <v-text-field
                                                                                        v-bind:label="$t('parts.mpp')"
                                                                                        v-model.number="part.mpp"
                                                                                        :rules="[rules.required, rules.numbersonly]"
                                                                                        v-bind:disabled="processing"
                                                                                ></v-text-field>
                                                                            </v-row>
                                                                        </v-col>
                                                                        <v-col cols="12" sm="12" md="6">
                                                                            <v-row
                                                                                    align="center"
                                                                                    class="mr-0 ml-0"
                                                                            >
                                                                                <v-text-field
                                                                                        v-bind:label="$t('parts.weightkg')"
                                                                                        v-model.number="part.weight"
                                                                                        :rules="[rules.required, rules.numbersonly]"
                                                                                        v-bind:disabled="processing"
                                                                                ></v-text-field>
                                                                            </v-row>
                                                                        </v-col>
                                                                        <v-col
                                                                                class="align-center justify-space-between"
                                                                                cols="12" sm="12" md="3"
                                                                        >
                                                                            <v-row
                                                                                    align="center"
                                                                                    class="mr-0 ml-0"
                                                                            >
                                                                                <v-text-field
                                                                                        v-bind:label="$t('parts.cleating')"
                                                                                        v-model.number="part.cleatingMPP"
                                                                                        :rules="[rules.numbersonly]"
                                                                                        v-bind:disabled="processing"
                                                                                ></v-text-field>
                                                                            </v-row>
                                                                        </v-col>
                                                                        <v-col
                                                                                class="align-center justify-space-between"
                                                                                cols="12" sm="12" md="3"
                                                                        >
                                                                            <v-row
                                                                                    align="center"
                                                                                    class="mr-0 ml-0"
                                                                            >
                                                                                <v-text-field
                                                                                        v-bind:label="$t('parts.lashing')"
                                                                                        v-model.number="part.lashingMPP"
                                                                                        :rules="[rules.numbersonly]"
                                                                                        v-bind:disabled="processing"
                                                                                ></v-text-field>
                                                                            </v-row>
                                                                        </v-col>
                                                                        <v-col
                                                                                class="align-center justify-space-between"
                                                                                cols="12" sm="12" md="3"
                                                                        >
                                                                            <v-row
                                                                                    align="center"
                                                                                    class="mr-0 ml-0"
                                                                            >
                                                                                <v-text-field
                                                                                        v-bind:label="$t('parts.carpentry')"
                                                                                        v-model.number="part.carpentryMPP"
                                                                                        :rules="[rules.numbersonly]"
                                                                                        v-bind:disabled="processing"
                                                                                ></v-text-field>
                                                                            </v-row>
                                                                        </v-col>
                                                                        <v-col
                                                                                class="align-center justify-space-between"
                                                                                cols="12" sm="12" md="3"
                                                                        >
                                                                            <v-row
                                                                                    align="center"
                                                                                    class="mr-0 ml-0"
                                                                            >
                                                                                <v-text-field
                                                                                        v-bind:label="$t('parts.other')"
                                                                                        v-model.number="part.otherMPP"
                                                                                        :rules="[rules.numbersonly]"
                                                                                        v-bind:disabled="processing"
                                                                                ></v-text-field>
                                                                            </v-row>
                                                                        </v-col>
                                                                        <v-col cols="12">
                                                                            <v-alert v-if="inlineMessage.message" v-bind:type="inlineMessage.type">
                                                                                {{ inlineMessage.message }}
                                                                            </v-alert>
                                                                        </v-col>
                                                                    </v-row>

                                                                    <v-row justify="end">
                                                                        <div class="pr-3 pb-3">
                                                                            <v-btn
                                                                                    text
                                                                                    link
                                                                                    v-bind:loading="processing"
                                                                                    v-bind:disabled="processing"
                                                                                    @click="cancelItemAdd(item)"
                                                                            >{{ $t('common.cancel') }}</v-btn>
                                                                            <v-btn
                                                                                    color="primary"
                                                                                    v-bind:loading="processing"
                                                                                    v-bind:disabled="processing"
                                                                                    @click="mockSavePartToCatalog(item)"
                                                                            >{{ $t('common.save') }}</v-btn>
                                                                        </div>
                                                                    </v-row>
                                                                </v-container>
                                                            </td>
                                                        </template>
                                                        <!-- Template for the "Unresolved" parts marker.-->
                                                        <template v-slot:item.ignored="{ item }">
                                                            <div v-if="!item.ignored">
                                                                <v-tooltip top>
                                                                    <template v-slot:activator="{ on }">
                                                                        <v-icon  color="red" dark v-on="on">fa-exclamation-triangle</v-icon>
                                                                    </template>
                                                                    <span>{{ $t('estimates.parts-selection-individual-part-needs-to-be-addressed')}}</span>
                                                                </v-tooltip>
                                                            </div>
                                                            <div v-else>
                                                                <v-tooltip top>
                                                                    <template v-slot:activator="{ on }">
                                                                        <v-icon  color="blue" dark v-on="on">fa-ban</v-icon>
                                                                    </template>
                                                                    <span>{{ $t('estimates.parts-selection-individual-part-ignored')}}</span>
                                                                </v-tooltip>
                                                            </div>
                                                        </template>
                                                    </v-data-table>
                                                </v-col>

                                                <!-- Manual Parts Selection section-->
                                                <v-card
                                                        flat
                                                        tile
                                                >
                                                    <!--Datatable for Parts Selection-->
                                                    <v-col cols="12" class="pa-0">
                                                        <!--Search bar for the view table-->
                                                        <v-row class="ml-0 mr-0">
                                                            <v-col cols="12" sm="12" md="7" class="pb-sm-0">
                                                                <v-card-title class="pa-0">
                                                                    <v-switch v-model="showOnlyIncluded" :label="$t('clients.projects.only-included-label')" ></v-switch>
                                                                    
                                                                    <v-tooltip top>
                                                                        <template v-slot:activator="{ on }">
                                                                            <v-icon color="black" dark v-on="on" class="ml-4">info</v-icon>
                                                                        </template>
                                                                        <span>{{ $t('clients.projects.parts-selection-sub-menu-information') }}</span>
                                                                    </v-tooltip>
                                                                </v-card-title>
                                                            </v-col>
                                                            <v-col cols="12" sm="12" md="5" class="pt-sm-1 pt-md-3 pb-0">
                                                                <v-combobox
                                                                        v-model="tagsSelectedForFiltering"
                                                                        :items="tagsInUse"
                                                                        :label="$t('common.filter-by-tags')"
                                                                        item-text="name"
                                                                        item-value="id"
                                                                        multiple
                                                                        small-chips
                                                                        clearable
                                                                        v-bind:class="[tagsSelectedForFiltering.length > 0 ? 'fd-combobox-control-item-selected-small' : 'fd-combobox-control-item-not-selected']"
                                                                />
                                                            </v-col>
                                                        </v-row>

                                                        <v-row class="mr-0 ml-0">
                                                            <v-col cols="12" sm="12" md="5" class="order-md-1 order-0 pb-0">
                                                                <v-select
                                                                        v-model="suppliersSelectedForFiltering"
                                                                        item-text="alias"
                                                                        item-value="id"
                                                                        v-bind:items="suppliersInUse"
                                                                        v-bind:label="$t('clients.projects.parts-selection-supplier-filter-label')"
                                                                        multiple
                                                                        small-chips
                                                                        clearable
                                                                        v-bind:class="[suppliersSelectedForFiltering.length > 0 ? 'fd-select-control-item-selected' : '', 'pt-3']"

                                                                ></v-select>
                                                            </v-col>

                                                            <v-col cols="12" sm="12" md="7" class="order-0">
                                                                <v-text-field
                                                                        v-model="tablesearchparts"
                                                                        append-icon="search"
                                                                        label="Search"
                                                                        single-line
                                                                        hide-details
                                                                        clearable
                                                                        
                                                                ></v-text-field>
                                                            </v-col>
                                                        </v-row>

                                                        <!--Datatable for the parts manually selected by the user.-->
                                                        <v-data-table
                                                                :headers="computedPartsHeaders"
                                                                :items="mockPartsSelection"
                                                                :search="tablesearchparts"
                                                                :loading="processing"
                                                                :loading-text="$t('common.table-loading-message')"
                                                                :sort-by="sortPartsTableBy"
                                                                class="fixed-header v-table__overflow"
                                                                style="max-height: calc(60vh - 10px); backface-visibility: hidden;"
                                                        >
                                                            <template v-slot:header.name="{ header }">
                                                                {{ $t('common.' + header.value) }}
                                                            </template>
                                                            <template v-slot:header.description="{ header }">
                                                                {{ $t('common.' + header.value) }}
                                                            </template>
                                                            <template v-slot:header.quantity="{ header }">
                                                                {{ $t('common.' + header.value) }}
                                                            </template>
                                                            <template v-slot:item.quantity="{ item }">
                                                                <fd-inline-edit-dialog
                                                                        v-bind:value="item.quantity != null ? item.quantity : '0'"
                                                                        v-bind:rules="[rules.numbersonly]"
                                                                        label="Edit"
                                                                        @update:value="value => saveQuantity(item, 'quantity', value)"
                                                                        numeric
                                                                ></fd-inline-edit-dialog>
                                                            </template>
                                                        </v-data-table>
                                                    </v-col>
                                                </v-card>

                                                <!-- Ignored Parts section-->
                                                <v-card
                                                    flat
                                                    tile
                                                    class="ignored-section-override"
                                                >
                                                    <v-col cols="12">
                                                        <v-row>
                                                            <v-col cols="12" sm="12" md="7">
                                                                <v-card-title class="pt-2 pl-sm-0 pl-md-4">
                                                                    {{ $t('estimates.parts-selection-existing-ignored-parts-title')}}
                                                                    <v-tooltip top>
                                                                        <template v-slot:activator="{ on }">
                                                                            <v-icon color="black" dark v-on="on" class="ml-4">info</v-icon>
                                                                        </template>
                                                                        <span>{{ $t('estimates.parts-selection-existing-ignored-parts-info-text') }}</span>
                                                                    </v-tooltip>
                                                                </v-card-title>
                                                            </v-col>
                                                        </v-row>

                                                        <v-row>
                                                            <v-col cols="12">
                                                                <v-row class="pl-3 pr-3">
                                                                    <v-text-field
                                                                            v-model="tablesearchIgnoredParts"
                                                                            append-icon="search"
                                                                            label="Search"
                                                                            single-line
                                                                            hide-details
                                                                    ></v-text-field>
                                                                </v-row>
                                                            </v-col>
                                                        </v-row>

                                                        <!--Datatable for the Ignored Parts List-->
                                                        <v-data-table
                                                                :headers="headersIgnoredParts"
                                                                :items="ignoredparts"
                                                                :search="tablesearchIgnoredParts"
                                                                :single-expand="singleExpand"
                                                                :expanded.sync="expandedIgnoredParts"
                                                                item-key="name"
                                                                :show-expand="showExpand"
                                                                :footer-props="{'items-per-page-options': [1, 5]}"
                                                                :items-per-page="5"
                                                                class="fd-actions-table"
                                                        >
                                                            <template v-slot:header.name="{ header }">
                                                                {{ $t('common.' + header.value) }}
                                                            </template>
                                                            <template v-slot:header.quantity="{ header }">
                                                                {{ $t('common.' + header.value) }}
                                                            </template>
                                                            <template v-slot:header.data-table-expand>
                                                                {{ $t('common.actions') }}
                                                            </template>

                                                            <!--The following is the template for the expanded row.-->
                                                            <template v-slot:item.data-table-expand="{ item, isExpanded, expand }">
                                                                <div class="table-actions-column-min-width">
                                                                    <v-tooltip top>
                                                                        <template v-slot:activator="{ on }">
                                                                            <v-btn
                                                                                    icon
                                                                                    link
                                                                                    v-on="on"
                                                                                    @click="expand(!isExpanded)"
                                                                                    v-bind:loading="saving"
                                                                                    v-bind:disabled="processing"
                                                                            >
                                                                                <v-icon
                                                                                        small
                                                                                        v-if="!isExpanded"
                                                                                >
                                                                                    fa-plus-circle
                                                                                </v-icon>
                                                                                <v-icon
                                                                                        small
                                                                                        v-else
                                                                                >
                                                                                    fa-times-circle
                                                                                </v-icon>
                                                                            </v-btn>
                                                                        </template>
                                                                        <span v-if="!isExpanded">{{ $t('estimates.parts-selection-individual-part-actions-add-part-to-catalog') }}</span>
                                                                        <span v-else>{{ $t('estimates.parts-selection-individual-part-actions-cancel-adding-part-to-catalog') }}</span>
                                                                    </v-tooltip>
                                                                    <v-tooltip top>
                                                                        <template v-slot:activator="{ on }">
                                                                            <v-btn
                                                                                    icon
                                                                                    v-bind:disabled="processing"
                                                                                    v-on="on"
                                                                            >
                                                                                <v-icon
                                                                                        small
                                                                                        @click="ignorePart(item)"
                                                                                >
                                                                                    mdi-delete
                                                                                </v-icon>
                                                                            </v-btn>
                                                                        </template>
                                                                        <span>{{ $t('common.delete') }}</span>
                                                                    </v-tooltip>
                                                                </div>
                                                            </template>

                                                            <!--The following is the template for the expanded row within the Ignored Parts table.-->
                                                            <template v-slot:expanded-item="{ headers, item }">
                                                                <td
                                                                        :colspan="headers.length"
                                                                        class="table-expanded-row-background-color"
                                                                >
                                                                    <v-container style="padding: 0px;">
                                                                        <v-row>
                                                                            <v-col
                                                                                    class="align-center justify-space-between"
                                                                                    cols="12"
                                                                            >
                                                                                <v-row
                                                                                        align="center"
                                                                                        class="mr-0 ml-0 mt-3"
                                                                                >
                                                                                </v-row>
                                                                                <fd-chip-selector
                                                                                        v-bind:available-items="availableTagsForPartsSelection"
                                                                                        v-bind:selected-items.sync="selectedTagsForPartsSelection"
                                                                                        item-label="name"
                                                                                        v-bind:disabled="processing"
                                                                                        v-bind:control-label="$t('common.add-tags')"
                                                                                        chip-class="keywords-selection-chip-colors"
                                                                                />
                                                                            </v-col>
                                                                            <v-col cols="12">
                                                                                <v-row align="center" class="mr-0 ml-0">
                                                                                    <fd-chip-selector
                                                                                            v-bind:available-items="availableSuppliersForPartsSelection"
                                                                                            v-bind:selected-items.sync="selectedSuppliersForPartsSelection"
                                                                                            item-label="alias"
                                                                                            v-bind:disabled="processing"
                                                                                            v-bind:control-label="$t('common.add-suppliers')"
                                                                                            chip-class="keywords-selection-chip-colors-secondary"
                                                                                    />
                                                                                </v-row>
                                                                            </v-col>
                                                                            <v-col
                                                                                    class="align-center justify-space-between"
                                                                                    cols="6"
                                                                            >
                                                                                <v-row
                                                                                        align="center"
                                                                                        class="mr-0 ml-0"
                                                                                >
                                                                                    <v-text-field
                                                                                            v-bind:label="$t('common.name')"
                                                                                            v-model="part.name"
                                                                                            :rules="[rules.required]"
                                                                                            v-bind:disabled="processing"
                                                                                    ></v-text-field>
                                                                                </v-row>
                                                                            </v-col>
                                                                            <v-col cols="6">
                                                                                <v-row
                                                                                        align="center"
                                                                                        class="mr-0 ml-0"
                                                                                >
                                                                                    <v-text-field
                                                                                            v-bind:label="$t('common.description')"
                                                                                            v-model="part.description"
                                                                                            v-bind:disabled="processing"
                                                                                            counter
                                                                                            maxlength="30"
                                                                                    ></v-text-field>
                                                                                </v-row>
                                                                            </v-col>
                                                                            <v-col
                                                                                    cols="12"
                                                                            >
                                                                                <v-row
                                                                                        align="center"
                                                                                        class="mr-0 ml-0"
                                                                                >
                                                                                    <v-col cols="6" class="pl-0">
                                                                                        <v-text-field
                                                                                                v-bind:label="$t('parts.mpp')"
                                                                                                v-model.number="part.mpp"
                                                                                                :rules="[rules.required, rules.numbersonly]"
                                                                                                v-bind:disabled="processing"
                                                                                        ></v-text-field>
                                                                                    </v-col>
                                                                                    <v-col
                                                                                            class="align-center justify-space-between"
                                                                                            cols="6"
                                                                                    >
                                                                                        <v-row
                                                                                                align="center"
                                                                                                class="mr-0 ml-0"
                                                                                        >
                                                                                            <v-text-field
                                                                                                    v-bind:label="$t('parts.weightkg')"
                                                                                                    v-model.number="part.weight"
                                                                                                    :rules="[rules.required, rules.numbersonly]"
                                                                                                    v-bind:disabled="processing"
                                                                                            ></v-text-field>
                                                                                        </v-row>
                                                                                    </v-col>
                                                                                </v-row>
                                                                            </v-col>
                                                                            <v-col
                                                                                    class="align-center justify-space-between"
                                                                                    cols="3"
                                                                            >
                                                                                <v-row
                                                                                        align="center"
                                                                                        class="mr-0 ml-0"
                                                                                >
                                                                                    <v-text-field
                                                                                            v-bind:label="$t('parts.cleating')"
                                                                                            v-model.number="part.cleatingMPP"
                                                                                            :rules="[rules.numbersonly]"
                                                                                            v-bind:disabled="processing"
                                                                                    ></v-text-field>
                                                                                </v-row>
                                                                            </v-col>
                                                                            <v-col
                                                                                    class="align-center justify-space-between"
                                                                                    cols="3"
                                                                            >
                                                                                <v-row
                                                                                        align="center"
                                                                                        class="mr-0 ml-0"
                                                                                >
                                                                                    <v-text-field
                                                                                            v-bind:label="$t('parts.lashing')"
                                                                                            v-model.number="part.lashingMPP"
                                                                                            :rules="[rules.numbersonly]"
                                                                                            v-bind:disabled="processing"
                                                                                    ></v-text-field>
                                                                                </v-row>
                                                                            </v-col>
                                                                            <v-col
                                                                                    class="align-center justify-space-between"
                                                                                    cols="3"
                                                                            >
                                                                                <v-row
                                                                                        align="center"
                                                                                        class="mr-0 ml-0"
                                                                                >
                                                                                    <v-text-field
                                                                                            v-bind:label="$t('parts.carpentry')"
                                                                                            v-model.number="part.carpentryMPP"
                                                                                            :rules="[rules.numbersonly]"
                                                                                            v-bind:disabled="processing"
                                                                                    ></v-text-field>
                                                                                </v-row>
                                                                            </v-col>
                                                                            <v-col
                                                                                    class="align-center justify-space-between"
                                                                                    cols="3"
                                                                            >
                                                                                <v-row
                                                                                        align="center"
                                                                                        class="mr-0 ml-0"
                                                                                >
                                                                                    <v-text-field
                                                                                            v-bind:label="$t('parts.other')"
                                                                                            v-model.number="part.otherMPP"
                                                                                            :rules="[rules.numbersonly]"
                                                                                            v-bind:disabled="processing"
                                                                                    ></v-text-field>
                                                                                </v-row>
                                                                            </v-col>
                                                                            <v-col cols="12">
                                                                                <v-alert v-if="inlineMessage.message" v-bind:type="inlineMessage.type">
                                                                                    {{ inlineMessage.message }}
                                                                                </v-alert>
                                                                            </v-col>
                                                                        </v-row>

                                                                        <v-row justify="end">
                                                                            <div class="pr-3 pb-3">
                                                                                <v-btn
                                                                                        text
                                                                                        link
                                                                                        v-bind:loading="processing"
                                                                                        v-bind:disabled="processing"
                                                                                        @click="cancelItemAddIgnoreParts(item)"
                                                                                >{{ $t('common.cancel') }}</v-btn>
                                                                                <v-btn
                                                                                        color="primary"
                                                                                        v-bind:loading="processing"
                                                                                        v-bind:disabled="processing"
                                                                                        @click="mockIgnorePart(item)"
                                                                                >{{ $t('common.save') }}</v-btn>
                                                                            </div>
                                                                        </v-row>
                                                                    </v-container>


                                                                </td>
                                                            </template>
                                                        </v-data-table>
                                                    </v-col>
                                                </v-card>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>

                                    <!--Modifiers for the Estimate-->
                                    <v-expansion-panel>
                                        <v-expansion-panel-header
                                                :disable-icon-rotate="simulatederror"
                                        >
                                            {{ $t('estimates.add-modifiers') }}
                                            <template v-slot:actions>
                                                <v-icon color="error" v-if="simulatederror">mdi-alert-circle</v-icon>
                                            </template>
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <v-row v-for="modifier in modifiers" class="pl-3 pr-3 pt-0" v-bind:key="modifier">
                                                <v-col cols="12" sm="12" class="pt-0">
                                                    <v-select
                                                            v-model="estimate.clientID"
                                                            item-text="name"
                                                            item-value="id"
                                                            v-bind:items="modifiervalues"
                                                            v-bind:label="modifier.name"
                                                            v-bind:disabled="processing"
                                                    ></v-select>
                                                </v-col>
                                            </v-row>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>

                                    <!--Adjustments for the Estimate-->
                                    <v-expansion-panel>
                                        <v-expansion-panel-header
                                                :disable-icon-rotate="simulatederror"
                                        >
                                            {{ $t('estimates.make-adjustments') }}
                                            <template v-slot:actions>
                                                <v-icon color="error" v-if="simulatederror">mdi-alert-circle</v-icon>
                                            </template>
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <v-row class="pl-3 pr-3 pt-0">
                                                <v-col cols="12" class="pt-0">
                                                    <v-text-field
                                                            v-bind:label="$t('estimates.adjustments-risk-adder')"
                                                            v-model="estimate.riskadder"
                                                            v-bind:disabled="processing"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="12" class="pt-0">
                                                    <v-switch v-bind:label="$t('estimates.adjustments-lashing')" v-model="estimate.uselashing" v-bind:disabled="processing" persistent-hint :hint="$t('estimates.adjustments-lashing-hint')"></v-switch>
                                                </v-col>
                                                <v-col cols="12">
                                                    <v-switch v-bind:label="$t('estimates.adjustments-cleating')" v-model="estimate.usecleating" v-bind:disabled="processing" persistent-hint :hint="$t('estimates.adjustments-cleating-hint')"></v-switch>
                                                </v-col>
                                                <v-col cols="12">
                                                    <v-switch v-bind:label="$t('estimates.adjustments-carpentry')" v-model="estimate.usecarpentry" v-bind:disabled="processing" persistent-hint :hint="$t('estimates.adjustments-carpentry-hint')"></v-switch>
                                                </v-col>
                                                <v-col cols="12">
                                                    <v-switch v-bind:label="$t('estimates.adjustments-other')" v-model="estimate.usecarpentry" v-bind:disabled="processing" persistent-hint :hint="$t('estimates.adjustments-other-hint')"></v-switch>
                                                </v-col>
                                            </v-row>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>

                                    <!--Attachments to the Estimate-->
                                    <v-expansion-panel>
                                        <v-expansion-panel-header
                                                :disable-icon-rotate="simulatederror"
                                        >
                                            {{ $t('estimates.add-attachments') }}
                                            <template v-slot:actions>
                                                <v-icon color="error" v-if="simulatederror">mdi-alert-circle</v-icon>
                                            </template>
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <!--To be developed.-->
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>


                                </v-expansion-panels>
                            </v-col>
                        </v-row>



                        <v-row>

                            <v-col cols="12" class="pb-0">
                                <v-alert v-if="inlineMessage.message" v-bind:type="inlineMessage.type" class="mr-0">
                                    {{ inlineMessage.message }}
                                </v-alert>
                                <v-row class="ml-0 mr-0 pb-0">
                                    <v-btn
                                            link
                                            v-bind:disabled="processing"
                                            color="error"
                                            outlined
                                            @click="deleteItem"
                                    >{{ $t('common.delete') }}</v-btn>
                                    <v-checkbox
                                            label="Simulate Error"
                                            v-model="simulatederror"
                                            class="ml-4 mt-0"
                                    />
                                    <v-spacer></v-spacer>
                                    <v-btn
                                            text
                                            link
                                            v-bind:disabled="processing"
                                            @click="cancel"
                                    >{{ $t('common.cancel') }}</v-btn>
                                    <v-btn
                                            color="primary"
                                            v-bind:loading="saving"
                                            v-bind:disabled="processing"
                                            @click="save"
                                    >{{ $t('common.save') }}</v-btn>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-slide-x-reverse-transition>
        </v-form>
    </v-container>
</template>

<!--script lang="ts" src="./EstimateExisting.vue.ts" /-->
<script lang="ts">
import Vue from "vue";
import { mapMutations, mapState, mapActions } from "vuex";
import i18n from "../i18n";
import { stripTimeFromLocalizedDateTime } from "@fd/lib/client-util/datetime";

export default Vue.extend({
  name: "fd-Supplier-Existing",

  components: {
    "fd-chip-selector": () => import("@fd/lib/vue/components/ChipItemSelector.vue"),
    "fd-inline-edit-dialog": () => import("@fd/lib/vue/components/InlineEditDialog.vue")
  },

  data: function() {
    return {
      // The following tracks the current width of the browser window. It works in conjunction with a EventListener
      // setup in the "created" hook.
      windowWidth: 0,

      // The following is responsible for the inline messages that may be presented to the user.
      inlineMessage: {
        message: null,
        type: "error"
      },

      // This holds the selected tags for the Estimate Overall
      selectedTagsForOverallEstimate: [],

      // This holds the selected tags and suppliers used in the filtering of manually selected parts.
      selectedTagsForPartsSelection: [],
      selectedSuppliersForPartsSelection: [],

      // The following will control whether the controls on screen are disabled while we are conducting any screen
      // wide actions.
      processing: false,

      // The following will control whether or not the save button shows the processing/loading indicator
      saving: false,

      // Used to control an animation for the view coming into visibility to the user.
      slidein: false,

      // These objects are used for the view specific "Tags" filtering.
      tagsSelectedForFiltering: [],

      // These objects are used for the view specific "Suppliers" filtering.
      suppliersSelectedForFiltering: [],

      //The following object is used in conjunction with the breadcrumbs that are presented to the user for sub-view navigation.
      breadcrumbs: [
        {
          text: "Estimates",
          disabled: false,
          to: "/estimates"
        },
        {
          text: this.$t("loading-dot-dot-dot"),
          disabled: true
        }
      ],

      validfrommenu: false,
      validuntilmenu: false,

      simulatederror: false,

      estimate: {
        tagnumber: "",
        clientID: "",
        projectID: "",
        areaID: "",
        subareaID: "",
        estimatedby: "",
        validfrom: null,
        validuntil: null,
        regionID: "",
        productivity: "",
        labourrate: "",
        riskadder: "",
        usecleating: false,
        uselashing: false,
        usecarpentry: false,
        useother: false
      },

      part: {
        publicID: "",
        name: "",
        description: "",
        weight: "",
        supplierID: null,
        mpp: "",
        cleatingMPP: "",
        lashingMPP: "",
        carpentryMPP: "",
        otherMPP: "",
        enabled: true
      },

      // column headers for the table used for manually selecting parts.
      headersparts: [
        {
          text: "Name",
          value: "name",
          sortable: true,
          hideWhenSmall: false,
          hideWhenMedium: false,
          hideWhenLarge: false,
          hideWhenLargest: false
        },
        {
          text: "Description",
          value: "description",
          sortable: true,
          hideWhenSmall: true,
          hideWhenMedium: false,
          hideWhenLarge: false,
          hideWhenLargest: false
        },
        {
          text: "Quantity",
          value: "quantity",
          sortable: false,
          hideWhenSmall: false,
          hideWhenMedium: false,
          hideWhenLarge: false,
          hideWhenLargest: false
        }
      ],

      // The following is mockup data for the potential summation object for the estimate
      estimatesummation: [
        {
          name: "summation-mobilization",
          value: 41.11,
          hightlight: false
        },
        {
          name: "summation-erection",
          value: 332.97,
          hightlight: false
        },
        {
          name: "summation-dismantle",
          value: 221.98,
          hightlight: false
        },
        {
          name: "summation-demobilization",
          value: 41.11,
          hightlight: false
        },
        {
          name: "summation-hoarding",
          value: 0.0,
          hightlight: false
        },
        {
          name: "summation-modify",
          value: 49.95,
          hightlight: false
        },
        {
          name: "summation-total",
          value: 687.12,
          hightlight: true
        },
        {
          name: "summation-weight-kg",
          value: 41522.4,
          hightlight: false
        },
        {
          name: "summation-pieces",
          value: 2966,
          hightlight: false
        },
        {
          name: "summation-erection-mpp",
          value: 6.74,
          hightlight: true
        },
        {
          name: "summation-dismantle-mpp",
          value: 4.49,
          hightlight: true
        }
      ],

      // mock objects used to display parts in the manually selected parts section.
      mockPartsSelection: [
        {
          name: "RL.IFPL.0880.01",
          description: "FILLER BOARD 0.88M/2'10 5/8\" (COVER PLATE)",
          quantity: 15
        },
        {
          name: "RL.IFPL.0880.02",
          description: "FILLER BOARD 0.90M/2'10 5/8\" (COVER PLATE)",
          quantity: 8
        },
        {
          name: "RL.IFPL.0880.03",
          description: "FILLER BOARD 0.95M/2'10 5/8\" (COVER PLATE)",
          quantity: 2
        },
        {
          name: "RL.IFPL.0880.04",
          description: "FILLER BOARD 0.88M/2'10 5/8\" (COVER PLATE)",
          quantity: 1
        },
        {
          name: "RL.IFPL.0880.05",
          description: "FILLER BOARD 0.90M/2'10 5/8\" (COVER PLATE)",
          quantity: 15
        },
        {
          name: "RL.IFPL.0880.06",
          description: "FILLER BOARD 0.95M/2'10 5/8\" (COVER PLATE)",
          quantity: 127
        },
        {
          name: "RL.IFPL.0880.07",
          description: "FILLER BOARD 0.88M/2'10 5/8\" (COVER PLATE)",
          quantity: 34
        },
        {
          name: "RL.IFPL.0880.08",
          description: "FILLER BOARD 0.90M/2'10 5/8\" (COVER PLATE)",
          quantity: 19
        },
        {
          name: "RL.IFPL.0880.09",
          description: "FILLER BOARD 0.95M/2'10 5/8\" (COVER PLATE)",
          quantity: 3
        },
        {
          name: "RL.IFPL.0880.10",
          description: "FILLER BOARD 0.88M/2'10 5/8\" (COVER PLATE)",
          quantity: 227
        },
        {
          name: "RL.IFPL.0880.11",
          description: "FILLER BOARD 0.90M/2'10 5/8\" (COVER PLATE)",
          quantity: 16
        },
        {
          name: "RL.IFPL.0880.12",
          description: "FILLER BOARD 0.95M/2'10 5/8\" (COVER PLATE)",
          quantity: 29
        }
      ],

      // object used in the mockup of how parts that error out in the bcounts submission will appear.
      erroredparts: [
        {
          name: "RL.ICPF.0736.01",
          quantity: 15,
          ignored: false,
          errormessage: null
        },
        {
          name: "RL.ICPF.0990.01",
          quantity: 7,
          ignored: false,
          errormessage: null
        },
        {
          name: "RL.ICPF.1286.01",
          quantity: 10,
          ignored: false,
          errormessage: "ID needs to be filled in."
        },
        {
          name: "RL.ICPF.1570.01",
          quantity: 16,
          ignored: false,
          errormessage: null
        },
        {
          name: "RL.ICPF.1577.02",
          quantity: 8,
          ignored: false,
          errormessage: null
        }
      ],

      // object used to mockup how ignored parts will appear to the user.
      ignoredparts: [
        {
          name: "RL.ICPF.0736.01",
          quantity: 15,
          ignored: false,
          errormessage: null
        },
        {
          name: "RL.ICPF.0990.01",
          quantity: 7,
          ignored: false,
          errormessage: null
        },
        {
          name: "RL.ICPF.1286.01",
          quantity: 10,
          ignored: false,
          errormessage: "ID needs to be filled in."
        }
      ],

      // The following objects are used in the table shown to users when there are errors
      // based on what they pasted into the bcounts processing.
      expanded: [],
      singleExpand: true,
      showExpand: true,
      tablesearch: "",
      headersForErroredParts: [
        {
          text: "",
          value: "ignored",
          hideWhenSmall: false,
          hideWhenMedium: false,
          hideWhenLarge: false,
          hideWhenLargest: false
        },
        {
          text: "Name",
          value: "name",
          hideWhenSmall: false,
          hideWhenMedium: false,
          hideWhenLarge: false,
          hideWhenLargest: false
        },
        {
          text: "Quantity",
          value: "quantity",
          hideWhenSmall: true,
          hideWhenMedium: false,
          hideWhenLarge: false,
          hideWhenLargest: false
        },
        {
          text: "Actions",
          value: "data-table-expand",
          sortable: false,
          hideWhenSmall: false,
          hideWhenMedium: false,
          hideWhenLarge: false,
          hideWhenLargest: false
        }
      ],

      //The following objects are related to the "ignored" parts list
      expandedIgnoredParts: [],
      tablesearchIgnoredParts: "",
      headersIgnoredParts: [
        {
          text: "Name",
          value: "name",
          hideWhenSmall: false,
          hideWhenMedium: false,
          hideWhenLarge: false,
          hideWhenLargest: false
        },
        {
          text: "Quantity",
          value: "quantity",
          hideWhenSmall: true,
          hideWhenMedium: false,
          hideWhenLarge: false,
          hideWhenLargest: false
        },
        {
          text: "Actions",
          value: "data-table-expand",
          sortable: false,
          hideWhenSmall: false,
          hideWhenMedium: false,
          hideWhenLarge: false,
          hideWhenLargest: false
        }
      ],

      // variables to hold potential error states.
      allErroredPartsThatRemainHaveBeenIgnored: false,
      partserror: false,
      detailserror: false,

      selectedTags: [],

      rules: {
        required: value => !!value || i18n.t("common.rule-required"),
        numbersonly: value => !(value && isNaN(value)) || i18n.t("common.rule-numbers-only")
      }
    };
  },

  computed: {
    availableTagsForOverallEstimate() {
      return this.$store.getters.getSortedEnabledInUseTags(this.selectedTags);
    },
    formatedValidFromDate() {
      return this.estimate.validfrom ? stripTimeFromLocalizedDateTime(this.estimate.validfrom) : "";
    },
    formatedValidUntilDate() {
      return this.estimate.validuntil
        ? stripTimeFromLocalizedDateTime(this.estimate.validuntil)
        : "";
    },

    i18nLocale() { return this.$store.state.datepickerLanguage; },

    modifiers() {
      return this.$store.state.modifiers.fullList;
    },
    availableSuppliersForPartsSelection() {
      return this.$store.state.suppliers.fullList;
    },
    availableTagsForPartsSelection() {
      return this.$store.getters.sortedEnabledTags;
    },
    computedPartsHeaders() {
      if (this.windowWidth >= 1264) {
        return this.headersparts.filter(h => !h.hideWhenLargest);
      } else if (this.windowWidth < 1264 && this.windowWidth >= 960) {
        return this.headersparts.filter(h => !h.hideWhenLarge);
      } else if (this.windowWidth < 960 && this.windowWidth >= 600) {
        return this.headersparts.filter(h => !h.hideWhenMedium);
      } else if (this.windowWidth < 600) {
        return this.headersparts.filter(h => !h.hideWhenSmall);
      } else {
        return this.headersparts;
      }
    },

    computedHeadersForErroredParts() {
      if (this.windowWidth >= 1264) {
        return this.headersForErroredParts.filter(h => !h.hideWhenLargest);
      } else if (this.windowWidth < 1264 && this.windowWidth >= 960) {
        return this.headersForErroredParts.filter(h => !h.hideWhenLarge);
      } else if (this.windowWidth < 960 && this.windowWidth >= 600) {
        return this.headersForErroredParts.filter(h => !h.hideWhenMedium);
      } else if (this.windowWidth < 600) {
        return this.headersForErroredParts.filter(h => !h.hideWhenSmall);
      } else {
        return this.headersForErroredParts;
      }
    }
  },

  methods: {
    onSubmit(e) {
      e.preventDefault();
      this.save();
    },
    // Method used in conjunction with the Save button.
    async save() {
      // First reset the inline message if there are any.
      this.inlineMessage.message = "";

      if (!this.$refs.form.validate()) {
        return;
      }

      this.processing = true;
      this.saving = true;
      try {
        await this.updateSupplier({
          ...this.supplier,
          tagIDs: this.selectedTags.length > 0 ? this.selectedTags.map(x => x.id) : null
        });
        this.$router.push("/suppliers");
      } catch (error) {
        this.inlineMessage.message = this.$t("suppliers.save-network-error");
        this.inlineMessage.type = "error";
      } finally {
        this.processing = false;
        this.saving = false;
      }
    },
    // the following works with the delete "Action" button in the Datatable.
    async deleteItem() {
      this.inlineMessage.message = null;
      this.processing = true;
      try {
        await this.deleteSupplier({ id: this.$route.params.id, name: this.supplier.name });
      } catch (error) {
        this.inlineMessage.message = this.$t("unexpected-network-error");
        this.inlineMessage.type = "error";
      } finally {
        this.processing = false;
        this.$router.push("/suppliers");
      }
    },

    mockSavePartToCatalog(item) {
      this.erroredparts = this.erroredparts.filter(x => x != item);
    },

    mockIgnorePart(item: any) {
      this.erroredparts = this.erroredparts.filter(x => x != item);
    },

    // This function is used to "Cancel" the adding of a new item to the parts catalog. Essentially it just
    // closes the "expanded" item screen for the row in question. This screen only allows one row to be open
    // so this function could have just emptied out the entire array, but instead it has been coded so that it
    // could work with a different table that allows multiple expanded rows.
    cancelItemAdd(item: any) {
      this.expanded = this.expanded.filter(x => x != item);
    },
    // This function is used to close any expanded rows within the Ignored Parts table.
    cancelItemAddIgnoreParts(item: any) {
      this.expandedIgnoredParts = this.expandedIgnoredParts.filter(x => x != item);
    },

    // Method used in conjunction with the Cancel button.
    cancel() {
      // TODO: Should this roll back state rather than rely on requerying?
      this.$router.push("/estimates");
    },
    ...mapMutations({
      setSupplier: "SET_SUPPLIER",
      notifyNewBreadcrumb: "NOTIFY_NEW_BREADCRUMB",
      setFilteringContext: "SET_FILTERING_CONTEXT"
    }),
    ...mapActions({
      loadSupplier: "LOAD_SUPPLIER",
      loadTags: "LOAD_TAGS",
      updateSupplier: "UPDATE_SUPPLIER",
      deleteSupplier: "DELETE_SUPPLIER"
    })
  },

  created: async function() {
    this.processing = true;

    // Add a small delay of time before the view comes in so that the "slide in" animation will be seen by the user.
    setInterval(() => {
      this.slidein = true;
    }, 100);

    // Listen to the "resize" even for the browser so we always know the width and can use that
    // knowledge for various responsive layout reasons.
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });
    this.windowWidth = window.innerWidth;

    try {
      await Promise.all([this.loadSupplier(this.$route.params.id), this.loadTags()]);
      let supplier = this.$store.state.suppliers.fullList.find(x => x.id == this.$route.params.id);
      this.supplier = { ...supplier };
      if (supplier.tagIDs) {
        this.selectedTags = supplier.tagIDs
          .map(x => this.$store.state.tags.fullList.find(y => y.id == x))
          .filter(x => x);
      } else {
        this.selectedTags = [];
      }
      this.notifyNewBreadcrumb({
        text: supplier.name,
        to: `/suppliers/${this.$route.params.id}`
      });
    } catch (error) {
      this.inlineMessage.message = this.$t("unexpected-network-error");
      this.inlineMessage.type = "error";
    } finally {
      this.processing = false;
    }
  }
});
</script>