import moment from "moment";

//moment.locale('es-us');

export function minimumDate(): Date {
  return new Date(1900, 1, 1);
}
export function maximumDate(): Date {
  return new Date(2999, 12, 31);
}

export function getNameOfDayFromNumber(dayNumber: number): string {
  return moment()
    .day(dayNumber)
    .format("dddd");
}

export function getYearsDifference(
  birthDate: Date | null | undefined,
  comparisonDate: Date | null | undefined = undefined
): number | undefined {
  if (!birthDate) return undefined;
  if (!comparisonDate) comparisonDate = new Date();
  let month_diff = comparisonDate.getTime() - birthDate.getTime();
  let age_dt = new Date(month_diff);
  let year = age_dt.getUTCFullYear();
  // let diff = Math.abs(year - 1970);
  let diff = year - 1970;
  return diff;
}

export function getDaysDifference(
  earlyDate: Date | null | undefined,
  comparisonDate: Date | null | undefined = undefined
): number | undefined {
  if (!earlyDate) return undefined;
  if (!comparisonDate) comparisonDate = new Date();

  const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
  const diffDays = Math.round(Math.abs((comparisonDate.getTime() - earlyDate.getTime()) / oneDay));
  return diffDays;
}

const localizationFormat = "llll";

export function addDaysToDate(dateTime: string | Date | null | undefined, days: number): Date {
  var date = !dateTime ? new Date() : new Date(dateTime);
  if (days == 0) return date;
  date.setDate(date.getDate() + days);
  return date;
}

export function addMonthsToDate(dateTime: string | Date | null | undefined, months: number): Date {
  var date = !dateTime ? new Date() : new Date(dateTime);
  if (months == 0) return date;
  date.setMonth(date.getMonth() + months);
  return date;
}

export function getFromDateForRangeUsingToDate(
  dateTime: string | Date | null | undefined,
  durationMonths: number,
  addDayOffset: boolean = false
): Date {
  var toDate = !dateTime ? new Date() : new Date(dateTime);
  if (durationMonths <= 0) return toDate;

  let startDate = addDaysToDate(addMonthsToDate(toDate, -durationMonths), addDayOffset ? 1 : 0);
  return startDate;
}
export function getToDateForRangeUsingFromDate(
  dateTime: string | Date | null | undefined,
  durationMonths: number,
  addDayOffset: boolean = false
): Date {
  var fromDate = !dateTime ? new Date() : new Date(dateTime);
  if (durationMonths <= 0) return fromDate;

  let startDate = addDaysToDate(addMonthsToDate(fromDate, durationMonths), addDayOffset ? -1 : 0);
  return startDate;
}

/// Returns a string with at format of "YYYY-MM-DDTHH:mm:ss" for use in data manipulation
/// To format for display purposes, use `localizedDateTimeString` instead.
export function isoString(dateTime: string | Date | null | undefined): string {
  if (dateTime == undefined) {
    return "";
  }
  return moment(dateTime).format("YYYY-MM-DDTHH:mm:ss");
}

/// Returns a string with at format of "YYYY-MM-DD" for use in data manipulation
/// To format for display purposes, use `localizedDateTimeString` instead.
export function isoDateString(dateTime: string | Date | null | undefined): string {
  if (dateTime == undefined) {
    return "";
  }
  return moment(dateTime).format("YYYY-MM-DD");
}

/// Returns a string with at format of "HH:mm" for use in data manipulation
/// To format for display purposes, use `localizedDateTimeString` instead.
export function isoTimeString(dateTime: string | Date | null | undefined): string {
  if (dateTime == undefined) {
    return "";
  }
  return moment(dateTime).format("HH:mm");
}

/// Returns a string with at format of "YYYY-MM-DDTHH:mm" for use in data manipulation
/// To format for display purposes, use `localizedDateTimeString` instead.
export function isoDateTimeString(dateTime: string | Date | null | undefined): string {
  if (dateTime == undefined) {
    return "";
  }
  return moment(dateTime).format("YYYY-MM-DDTHH:mm");
}

/// Returns a string with at format of "YYYY-MM-DDZ" for use in data manipulation
/// To format for display purposes, use `localizedDateString` instead.
export function isoDateWithOffsetString(dateTime: string | Date | null | undefined): string {
  if (dateTime == undefined) {
    return "";
  }
  return moment(dateTime).format("YYYY-MM-DDT00:00ZZ");
}

/// Returns a string with at format of "HH:mm:ssZZ" for use in data manipulation
/// To format for display purposes, use `localizedDateString` instead.
export function isoTimeWithOffsetString(dateTime: string | Date | null | undefined): string {
  if (dateTime == undefined) {
    return "";
  }
  return moment(dateTime).format("HH:mm:ssZZ");
}

/// Returns a localized version of the passed in date containing full Date (excluding day of week)
export function localizedShortDateString(
  dateTime: string | Date | null | undefined,
  format: string = "ll"
): string {
  if (dateTime == undefined) {
    dateTime = "";
  }
  return moment(dateTime).format(format);
}

/// Returns a localized version of the passed in date containing full Date (including day of week) and time
export function localizedDateTimeString(
  dateTime: string | Date | null | undefined,
  format: string = "llll"
): string {
  if (dateTime == undefined) {
    dateTime = "";
  }
  return moment(dateTime).format(format);
}

/// Returns a localized version of the passed in date containing full Date (including day of week), EXCLUDING time
export function stripTimeFromLocalizedDateTime(
  dateTimeToBeStripped: string | Date | null | undefined,
  format: string = "llll"
): string {
  // First, use the moment.js library to format the date with localization based on the locale it is
  // set to so that the format is appropriate to the user based on the language locale selected.
  // moment.js uses the "llll" formate property to allow the developer to gain access to formated date
  // stings based on the selected locale by the user. Unfortunately in order to get the day of the week,
  // you must use the "llll" which ALSO includes the current time. Therefore this routine simply strips
  // off the time from the returned formatted date/time string.
  let dateTimeString = localizedDateTimeString(dateTimeToBeStripped, format);
  let splitdate = dateTimeString.split(" ");

  // IF "AM" or "PM" is found (which isn't always the case for the various "en" (English) locales it must
  // also be stripped off as well as the time.
  if (splitdate.findIndex(x => x == ("AM" || "PM"))) {
    splitdate.pop();
    splitdate.pop();
    return splitdate.join(" ");
  } else {
    splitdate.pop();
    return splitdate.join(" ");
  }
}

/// Returns a localized version of the passed in date containing full Date (including day of week), EXCLUDING time
export function stripDateFromLocalizedDateTime(
  dateTimeToBeStripped: string | Date | null | undefined,
  format: string = "llll"
): string {
  // First, use the moment.js library to format the date with localization based on the locale it is
  // set to so that the format is appropriate to the user based on the language locale selected.
  // moment.js uses the "llll" formate property to allow the developer to gain access to formated date
  // stings based on the selected locale by the user. Unfortunately in order to get the day of the week,
  // you must use the "llll" which ALSO includes the current time. Therefore this routine simply strips
  // off the time from the returned formatted date/time string.
  let dateTimeString = localizedDateTimeString(dateTimeToBeStripped, format);
  let splitdate = dateTimeString.split(" ");

  let timeValues = [] as (string | undefined)[];
  // IF "AM" or "PM" is found (which isn't always the case for the various "en" (English) locales it must
  // also be stripped off as well as the time.
  if (splitdate.findIndex(x => x == ("AM" || "PM"))) {
    timeValues.unshift(splitdate.pop());
    timeValues.unshift(splitdate.pop());
    return timeValues.join(" ");
  } else {
    timeValues.unshift(splitdate.pop());
    return timeValues.join(" ");
  }
}

