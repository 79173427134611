var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { fluid: "", "data-cy": "jobexisting" } },
    [
      _c(
        "v-form",
        { ref: "form", on: { submit: _vm.onSubmit } },
        [
          _c(
            "v-slide-x-reverse-transition",
            [
              _c(
                "v-card",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.slidein,
                      expression: "slidein"
                    }
                  ]
                },
                [
                  _c(
                    "v-toolbar",
                    {
                      class: [
                        _vm.processing
                          ? "view-card-top-color-processing"
                          : "view-card-top-color"
                      ]
                    },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c(
                                "v-row",
                                { attrs: { align: "center" } },
                                [
                                  _c("v-breadcrumbs", {
                                    staticClass: "d-none d-sm-flex",
                                    class: [
                                      _vm.processing
                                        ? "breadcrumb-processing-opacity"
                                        : "",
                                      "pl-3"
                                    ],
                                    attrs: {
                                      items:
                                        _vm.$store.state.currentBreadcrumbs,
                                      large: ""
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "divider",
                                        fn: function() {
                                          return [
                                            _c("v-icon", [
                                              _vm._v("fa-chevron-right")
                                            ])
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ])
                                  }),
                                  _vm._v(" "),
                                  _c("fd-back-button", {
                                    attrs: {
                                      route: item =>
                                        _vm.$router.push(
                                          _vm.$store.getters.backBreadcrumb
                                            .to || `/maintenance/`
                                        )
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("v-spacer"),
                                  _vm._v(" "),
                                  _vm.processing
                                    ? _c("v-progress-circular", {
                                        staticClass: "mr-3",
                                        attrs: {
                                          indeterminate: true,
                                          rotate: 0,
                                          size: 32,
                                          width: 4,
                                          color: "white"
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-container",
                    { staticClass: "pa-0", attrs: { fluid: "" } },
                    [
                      _c(
                        "v-tabs",
                        {
                          ref: "tabs",
                          staticClass: "pt-3",
                          attrs: {
                            "icons-and-text": "",
                            "show-arrows": _vm.$vuetify.breakpoint.smAndUp,
                            "next-icon": "fa-arrow-circle-right",
                            "prev-icon": "fa-arrow-circle-left"
                          },
                          model: {
                            value: _vm.active_tab,
                            callback: function($$v) {
                              _vm.active_tab = $$v
                            },
                            expression: "active_tab"
                          }
                        },
                        [
                          _c(
                            "v-tab",
                            {
                              key: _vm.detailsTab.key,
                              ref: "tab",
                              attrs: { href: `#tab-${_vm.detailsTab.key}` },
                              on: {
                                click: function($event) {
                                  return _vm.tabSelected(_vm.detailsTab)
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.detailsTab.tabname) +
                                  "\n              "
                              ),
                              _c("v-icon", [_vm._v("fas fa-edit")])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.workOrdersTab.visible ||
                          _vm.$vuetify.breakpoint.smAndUp
                            ? _c(
                                "v-tab",
                                {
                                  key: _vm.workOrdersTab.key,
                                  ref: "tab",
                                  attrs: {
                                    href: `#tab-${_vm.workOrdersTab.key}`
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.tabSelected(_vm.workOrdersTab)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.workOrdersTab.tabname) +
                                      "\n              "
                                  ),
                                  _c("v-icon", [_vm._v("fas fa-file-alt")])
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "v-menu",
                            {
                              attrs: { bottom: "", left: "" },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function({ on, attrs }) {
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass:
                                                "align-self-center mr-4 d-flex d-sm-none more-tab-button",
                                              staticStyle: {
                                                position: "relative"
                                              },
                                              attrs: { text: "" }
                                            },
                                            "v-btn",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(_vm.$t("common.more")) +
                                              "\n\n                  "
                                          ),
                                          _c(
                                            "div",
                                            [
                                              _c("v-icon", [
                                                _vm._v("fas fa-ellipsis-h")
                                              ])
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    ]
                                  }
                                }
                              ])
                            },
                            [
                              _vm._v(" "),
                              _c(
                                "v-list",
                                { staticClass: "grey lighten-3" },
                                _vm._l(_vm.hiddenTabDefinitions, function(
                                  item
                                ) {
                                  return _c(
                                    "v-list-item",
                                    {
                                      key: item.tabname,
                                      on: {
                                        click: function($event) {
                                          return _vm.showNewTabFromMoreMenu(
                                            item
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(item.tabname) +
                                          "\n                "
                                      )
                                    ]
                                  )
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-tabs-items",
                        {
                          model: {
                            value: _vm.active_tab,
                            callback: function($$v) {
                              _vm.active_tab = $$v
                            },
                            expression: "active_tab"
                          }
                        },
                        [
                          _c(
                            "v-tab-item",
                            {
                              key: _vm.detailsTab.key,
                              attrs: { value: `tab-${_vm.detailsTab.key}` }
                            },
                            [
                              _c(
                                "v-row",
                                {
                                  staticClass:
                                    "mx-0 px-2 fd-inline-request-tags-qualifier",
                                  attrs: { align: "center" }
                                },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "py-0",
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _c("fd-chip-selector", {
                                        attrs: {
                                          "available-items":
                                            _vm.availableKeywords,
                                          "selected-items":
                                            _vm.selectedKeywords,
                                          "item-label": "name",
                                          disabled: _vm.processing,
                                          "control-label": _vm.$t(
                                            "common.add-tags"
                                          ),
                                          "chip-class":
                                            "keywords-selection-chip-colors",
                                          outlined: false
                                        },
                                        on: {
                                          "update:selectedItems": function(
                                            $event
                                          ) {
                                            _vm.selectedKeywords = $event
                                          },
                                          "update:selected-items": function(
                                            $event
                                          ) {
                                            _vm.selectedKeywords = $event
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-row",
                                {
                                  staticClass: "mx-2 pt-5",
                                  attrs: { align: "center" }
                                },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "justify-space-between pt-1 pb-1",
                                      attrs: {
                                        align: "center",
                                        cols: "12",
                                        sm: "6"
                                      }
                                    },
                                    [
                                      _c("fd-text-field", {
                                        attrs: {
                                          "data-cy": "number",
                                          label: _vm.$t("job.job-number"),
                                          disabled: true,
                                          readonly: true
                                        },
                                        model: {
                                          value:
                                            _vm.job.formattedInternalNumber,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.job,
                                              "formattedInternalNumber",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "job.formattedInternalNumber"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "justify-space-between pt-0 pb-2",
                                      attrs: {
                                        align: "center",
                                        cols: "12",
                                        sm: "12"
                                      }
                                    },
                                    [
                                      _c("v-switch", {
                                        attrs: {
                                          "data-cy": "archived",
                                          label: _vm.$t(
                                            "common.archived-label"
                                          ),
                                          disabled: _vm.processing
                                        },
                                        model: {
                                          value: _vm.job.archived,
                                          callback: function($$v) {
                                            _vm.$set(_vm.job, "archived", $$v)
                                          },
                                          expression: "job.archived"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-tab-item",
                            {
                              key: _vm.workOrdersTab.key,
                              attrs: { value: `tab-${_vm.workOrdersTab.key}` }
                            },
                            [
                              _c(
                                "v-form",
                                {
                                  ref: "workordersform",
                                  on: { submit: _vm.preventSubmit }
                                },
                                [
                                  _c(
                                    "v-card",
                                    { attrs: { flat: "", tile: "" } },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "pa-0",
                                          attrs: { cols: "12" }
                                        },
                                        [
                                          _c(
                                            "v-card-title",
                                            [
                                              _c(
                                                "v-tooltip",
                                                {
                                                  attrs: { top: "" },
                                                  scopedSlots: _vm._u([
                                                    {
                                                      key: "activator",
                                                      fn: function({ on }) {
                                                        return [
                                                          _c(
                                                            "v-icon",
                                                            _vm._g(
                                                              {
                                                                staticClass:
                                                                  "ml-1",
                                                                attrs: {
                                                                  color:
                                                                    "black",
                                                                  dark: ""
                                                                }
                                                              },
                                                              on
                                                            ),
                                                            [_vm._v("info")]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ])
                                                },
                                                [
                                                  _vm._v(" "),
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "job.maintenance.existing.work-orders.table-listing-information"
                                                        )
                                                      )
                                                    )
                                                  ])
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c("v-spacer"),
                                              _vm._v(" "),
                                              _c("v-text-field", {
                                                staticClass: "pl-0",
                                                attrs: {
                                                  "append-icon": "search",
                                                  label: _vm.$t(
                                                    "common.search"
                                                  ),
                                                  "single-line": "",
                                                  "hide-details": "",
                                                  clearable: ""
                                                },
                                                model: {
                                                  value:
                                                    _vm.workOrderTableSearch,
                                                  callback: function($$v) {
                                                    _vm.workOrderTableSearch = $$v
                                                  },
                                                  expression:
                                                    "workOrderTableSearch"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c("v-data-table", {
                                            directives: [
                                              {
                                                name: "fd-column",
                                                rawName: "v-fd-column:legacyID",
                                                value: _vm.$vuetify.breakpoint
                                                  .xs
                                                  ? _vm.$t(
                                                      "scaffolds.existing-scaffold.work-orders.number-label-short"
                                                    )
                                                  : _vm.$t(
                                                      "scaffolds.existing-scaffold.work-orders.number-label"
                                                    ),
                                                expression:
                                                  "\n                        $vuetify.breakpoint.xs\n                          ? $t('scaffolds.existing-scaffold.work-orders.number-label-short')\n                          : $t('scaffolds.existing-scaffold.work-orders.number-label')\n                      ",
                                                arg: "legacyID"
                                              },
                                              {
                                                name: "fd-column",
                                                rawName:
                                                  "v-fd-column:workOrderStatusName",
                                                value: _vm.$t(
                                                  "scaffolds.existing-scaffold.work-orders.status-label"
                                                ),
                                                expression:
                                                  "\n                        $t('scaffolds.existing-scaffold.work-orders.status-label')\n                      ",
                                                arg: "workOrderStatusName"
                                              },
                                              {
                                                name: "fd-column",
                                                rawName:
                                                  "v-fd-column:assignedContractorName.hide-when-extra-small",
                                                value: _vm.$t(
                                                  "scaffolds.existing-scaffold.work-orders.contractor-label"
                                                ),
                                                expression:
                                                  "\n                        $t('scaffolds.existing-scaffold.work-orders.contractor-label')\n                      ",
                                                arg: "assignedContractorName",
                                                modifiers: {
                                                  "hide-when-extra-small": true
                                                }
                                              },
                                              {
                                                name: "fd-column",
                                                rawName:
                                                  "v-fd-column:areaName.hide-when-medium",
                                                value: _vm.$t(
                                                  "scaffolds.existing-scaffold.work-orders.area-label"
                                                ),
                                                expression:
                                                  "\n                        $t('scaffolds.existing-scaffold.work-orders.area-label')\n                      ",
                                                arg: "areaName",
                                                modifiers: {
                                                  "hide-when-medium": true
                                                }
                                              },
                                              {
                                                name: "fd-column",
                                                rawName:
                                                  "v-fd-column:subAreaName.hide-when-medium",
                                                value: _vm.$t(
                                                  "scaffolds.existing-scaffold.work-orders.sub-area-label"
                                                ),
                                                expression:
                                                  "\n                        $t('scaffolds.existing-scaffold.work-orders.sub-area-label')\n                      ",
                                                arg: "subAreaName",
                                                modifiers: {
                                                  "hide-when-medium": true
                                                }
                                              },
                                              {
                                                name: "fd-column",
                                                rawName:
                                                  "v-fd-column:requestTypeName",
                                                value: _vm.$t(
                                                  "scaffolds.existing-scaffold.work-orders.type-label"
                                                ),
                                                expression:
                                                  "\n                        $t('scaffolds.existing-scaffold.work-orders.type-label')\n                      ",
                                                arg: "requestTypeName"
                                              },
                                              {
                                                name: "fd-column",
                                                rawName:
                                                  "v-fd-column:formattedRequestDate.hide-when-medium",
                                                value: _vm.$t(
                                                  "scaffolds.existing-scaffold.work-orders.requested-on-label"
                                                ),
                                                expression:
                                                  "\n                        $t('scaffolds.existing-scaffold.work-orders.requested-on-label')\n                      ",
                                                arg: "formattedRequestDate",
                                                modifiers: {
                                                  "hide-when-medium": true
                                                }
                                              },
                                              {
                                                name: "fd-column",
                                                rawName:
                                                  "v-fd-column:formattedCompletedDate.hide-when-small",
                                                value: _vm.$t(
                                                  "scaffolds.existing-scaffold.work-orders.completed-on-label"
                                                ),
                                                expression:
                                                  "\n                        $t('scaffolds.existing-scaffold.work-orders.completed-on-label')\n                      ",
                                                arg: "formattedCompletedDate",
                                                modifiers: {
                                                  "hide-when-small": true
                                                }
                                              }
                                            ],
                                            attrs: {
                                              items: _vm.job.relatedWorkOrders,
                                              search: _vm.workOrderTableSearch,
                                              loading: _vm.processing,
                                              "loading-text": _vm.$t(
                                                "common.table-loading-message"
                                              ),
                                              "sort-by": [
                                                "formattedCompletedDate"
                                              ],
                                              "sort-desc": true,
                                              "mobile-breakpoint": "0",
                                              "show-expand": false
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "item.workOrderStatusName",
                                                fn: function({ item }) {
                                                  return [
                                                    _c("fd-value-display", {
                                                      attrs: {
                                                        value: _vm.$t(
                                                          `workorders.status.${item.workOrderStatus}`
                                                        )
                                                      }
                                                    })
                                                  ]
                                                }
                                              },
                                              {
                                                key:
                                                  "item.assignedContractorName",
                                                fn: function({ item }) {
                                                  return [
                                                    _c("fd-value-display", {
                                                      attrs: {
                                                        value:
                                                          item.assignedContractorName
                                                      }
                                                    })
                                                  ]
                                                }
                                              },
                                              {
                                                key: "item.areaName",
                                                fn: function({ item }) {
                                                  return [
                                                    _c("fd-value-display", {
                                                      attrs: {
                                                        value: item.areaName
                                                      }
                                                    })
                                                  ]
                                                }
                                              },
                                              {
                                                key: "item.subAreaName",
                                                fn: function({ item }) {
                                                  return [
                                                    _c("fd-value-display", {
                                                      attrs: {
                                                        value: item.subAreaName
                                                      }
                                                    })
                                                  ]
                                                }
                                              },
                                              {
                                                key: "item.requestTypeName",
                                                fn: function({ item }) {
                                                  return [
                                                    _c("fd-value-display", {
                                                      attrs: {
                                                        value: _vm.$t(
                                                          `scaffold-requests.types.${item.scaffoldRequestType}`
                                                        )
                                                      }
                                                    })
                                                  ]
                                                }
                                              },
                                              {
                                                key: "expanded-item",
                                                fn: function({ item }) {
                                                  return [
                                                    _c(
                                                      "td",
                                                      {
                                                        staticClass:
                                                          "fd-expanding-table-single-details-section",
                                                        attrs: {
                                                          colspan:
                                                            _vm.expanderColSpan
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "fd-work-order-details",
                                                          {
                                                            staticClass:
                                                              "fd-expanding-table-single-details-section",
                                                            attrs: {
                                                              workOrder: item
                                                            }
                                                          }
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                }
                                              }
                                            ])
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    { staticClass: "mx-2" },
                    [
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-2",
                          attrs: {
                            text: "",
                            link: "",
                            disabled: _vm.processing,
                            small: _vm.$vuetify.breakpoint.xsOnly
                          },
                          on: { click: _vm.cancel }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("common.cancel")) +
                              "\n          "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c("fd-menu-save", {
                        attrs: {
                          disabled: _vm.processing,
                          loading: _vm.saving,
                          small: _vm.$vuetify.breakpoint.xsOnly
                        },
                        on: {
                          "click:save": function($event) {
                            return _vm.save(false)
                          },
                          "click:save-and-close": function($event) {
                            return _vm.save(true)
                          }
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }