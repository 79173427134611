var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      attrs: { width: "1024px", persistent: "" },
      model: {
        value: _vm.twoWayProps.show,
        callback: function($$v) {
          _vm.$set(_vm.twoWayProps, "show", $$v)
        },
        expression: "twoWayProps.show"
      }
    },
    [
      _c(
        "v-container",
        { staticClass: "py-0 px-0", attrs: { fluid: "" } },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                {
                  class: [
                    _vm.processing
                      ? "dialog-new-top-color-processing"
                      : "dialog-new-top-color"
                  ]
                },
                [
                  _c(
                    "span",
                    {
                      class: [
                        _vm.processing ? "breadcrumb-processing-opacity" : ""
                      ]
                    },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "scaffold-requests.existing-scaffold-request.tabs.notes"
                            )
                          ) +
                            " -\n            " +
                            _vm._s(
                              _vm.$t("to-do-list.work-order-number", [
                                _vm.workOrder.legacyID
                              ])
                            )
                        )
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _vm.processing
                    ? _c("v-progress-circular", {
                        attrs: {
                          indeterminate: true,
                          rotate: 0,
                          size: 32,
                          width: 4,
                          color: "white"
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-row",
                { staticClass: "mx-2", attrs: { align: "center" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "pa-0", attrs: { cols: "12" } },
                    [
                      _vm.currentUserCanAddNote ||
                      (!!_vm.notes && _vm.notes.length > 0)
                        ? _c(
                            "v-timeline",
                            { attrs: { dense: "" } },
                            [
                              _c("fd-new-note-timeline-item", {
                                attrs: {
                                  loading: _vm.saving,
                                  disabled:
                                    !_vm.currentUserCanAddNote || _vm.processing
                                },
                                on: { addNewNote: _vm.addNewNote },
                                model: {
                                  value: _vm.newNoteText,
                                  callback: function($$v) {
                                    _vm.newNoteText = $$v
                                  },
                                  expression: "newNoteText"
                                }
                              }),
                              _vm._v(" "),
                              _vm._l(_vm.notes, function(item, i) {
                                return _c("fd-history-note-timeline-item", {
                                  key: i,
                                  attrs: { note: item }
                                })
                              })
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "v-card-actions",
                        [
                          _c("v-spacer"),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                text: "",
                                small: _vm.$vuetify.breakpoint.xsOnly
                              },
                              on: { click: _vm.cancelDialog }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$t("common.close")) +
                                  "\n            "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }