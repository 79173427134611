import FDVue from "@fd/lib/vue";
import { FDColumnDirective } from "@fd/lib/vue/utility/dataTable";
import Vue from "vue";
import { WorkOrderTakeoffPart } from "../../../services";

export default FDVue.extend({
  name: "sp-work-order-estimate-takeoff-parts-list",
  directives: {
    fdColumn: FDColumnDirective
  },
  props: {
    takeoffParts: {},
    processing: {},
    canClearParts: { type: Boolean, default: true },
    showPrint: { type: Boolean, default: true },
    canPrintTemplate: { type: Boolean, default: true }
  },
  data: function() {
    return {
      search: "",
      showBCount: false,
      bCountCopied: false
    };
  },
  computed: {
    partsBCount(): string {
      var bCount = "";
      (this.takeoffParts as WorkOrderTakeoffPart[]).forEach(part => {
        if (!!bCount.length) bCount += "\n";
        let partBCount = `${part.partName}........${part.quantity ?? 0}`;
        bCount += partBCount;
      });
      return bCount;
    }
  },
  methods: {
    formatNumber(
      number: string | number | undefined | null,
      digits: number = 2
    ): string | undefined {
      if (number == undefined || number == null) return undefined;
      let val = Number(number);
      if (isNaN(val) || val == 0) return undefined;
      return val.toFixed(digits);
    },
    copyBCount() {
      try {
        if (!!navigator.clipboard) {
          navigator.clipboard.writeText(this.partsBCount);
        } else {
          let bCountField = this.$refs.bcountfield as Vue;
          let bCountElement = bCountField.$el as HTMLInputElement;
          bCountElement.select();
          bCountElement.setSelectionRange(0, 99999); /* For mobile devices */
          /* Copy the text inside the text field */
          document.execCommand("copy");
        }

        this.bCountCopied = true;
        setTimeout(() => {
          this.bCountCopied = false;
        }, 2500);
      } catch (error) {
        console.error(`Unable to copy BCount to clipboard: ${error}`);
        this.bCountCopied = false;
      }
    }
  }
});

