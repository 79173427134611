var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      attrs: { width: "1000px" },
      model: {
        value: _vm.twoWayProps.show,
        callback: function($$v) {
          _vm.$set(_vm.twoWayProps, "show", $$v)
        },
        expression: "twoWayProps.show"
      }
    },
    [
      _c(
        "v-card",
        { ref: "content", attrs: { "data-cy": "workorderestimatenewdialog" } },
        [
          _c(
            "v-card-title",
            {
              class: [
                _vm.processing
                  ? "dialog-new-top-color-processing"
                  : "dialog-new-top-color"
              ]
            },
            [
              _c(
                "span",
                {
                  class: [_vm.processing ? "breadcrumb-processing-opacity" : ""]
                },
                [
                  _vm._v(
                    _vm._s(_vm.$t("work-order-estimates.new-estimate.title"))
                  )
                ]
              ),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _vm.processing
                ? _c("v-progress-circular", {
                    attrs: {
                      indeterminate: true,
                      rotate: 0,
                      size: 32,
                      width: 4,
                      color: "white"
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c("sp-lwh-estimate-form", {
            ref: "lwhform",
            attrs: {
              estimate: _vm.estimate,
              isModifyRequest: _vm.workOrder.isModifyRequest
            },
            on: {
              close: _vm.closeDialog,
              "update:processing": p => (_vm.processing = p)
            }
          }),
          _vm._v(" "),
          _c("sp-estimate-modifiers-form", {
            attrs: {
              processing: _vm.processing,
              scaffoldIsHardBarricade: _vm.scaffoldIsHardBarricade,
              scaffoldTypeModifierID: _vm.estimate.scaffoldTypeModifierID,
              scaffoldDistanceModifierID:
                _vm.estimate.scaffoldDistanceModifierID,
              scaffoldElevationModifierID:
                _vm.estimate.scaffoldElevationModifierID,
              scaffoldHeightModifierID: _vm.estimate.scaffoldHeightModifierID,
              buildDismantleRatioID: _vm.estimate.buildDismantleRatioID,
              scaffoldCongestionFactorID:
                _vm.estimate.scaffoldCongestionFactorID,
              internalModifierID: _vm.estimate.internalModifierID,
              hoardingModifierID: _vm.estimate.hoardingModifierID,
              allScaffoldTypes: _vm.allScaffoldTypes,
              allScaffoldDistances: _vm.allScaffoldDistances,
              allScaffoldElevations: _vm.allScaffoldElevations,
              allScaffoldHeights: _vm.allScaffoldHeights,
              allBuildDismantleRatios: _vm.allBuildDismantleRatios,
              allScaffoldCongestionFactors: _vm.allScaffoldCongestionFactors,
              allInternalModifiers: _vm.allInternalModifiers,
              allHoardingModifiers: _vm.allHoardingModifiers
            },
            on: {
              "update:scaffoldTypeModifierID": function($event) {
                return _vm.$set(_vm.estimate, "scaffoldTypeModifierID", $event)
              },
              "update:scaffold-type-modifier-i-d": function($event) {
                return _vm.$set(_vm.estimate, "scaffoldTypeModifierID", $event)
              },
              "update:scaffoldDistanceModifierID": function($event) {
                return _vm.$set(
                  _vm.estimate,
                  "scaffoldDistanceModifierID",
                  $event
                )
              },
              "update:scaffold-distance-modifier-i-d": function($event) {
                return _vm.$set(
                  _vm.estimate,
                  "scaffoldDistanceModifierID",
                  $event
                )
              },
              "update:scaffoldElevationModifierID": function($event) {
                return _vm.$set(
                  _vm.estimate,
                  "scaffoldElevationModifierID",
                  $event
                )
              },
              "update:scaffold-elevation-modifier-i-d": function($event) {
                return _vm.$set(
                  _vm.estimate,
                  "scaffoldElevationModifierID",
                  $event
                )
              },
              "update:scaffoldHeightModifierID": function($event) {
                return _vm.$set(
                  _vm.estimate,
                  "scaffoldHeightModifierID",
                  $event
                )
              },
              "update:scaffold-height-modifier-i-d": function($event) {
                return _vm.$set(
                  _vm.estimate,
                  "scaffoldHeightModifierID",
                  $event
                )
              },
              "update:buildDismantleRatioID": function($event) {
                return _vm.$set(_vm.estimate, "buildDismantleRatioID", $event)
              },
              "update:build-dismantle-ratio-i-d": function($event) {
                return _vm.$set(_vm.estimate, "buildDismantleRatioID", $event)
              },
              "update:scaffoldCongestionFactorID": function($event) {
                return _vm.$set(
                  _vm.estimate,
                  "scaffoldCongestionFactorID",
                  $event
                )
              },
              "update:scaffold-congestion-factor-i-d": function($event) {
                return _vm.$set(
                  _vm.estimate,
                  "scaffoldCongestionFactorID",
                  $event
                )
              },
              "update:internalModifierID": function($event) {
                return _vm.$set(_vm.estimate, "internalModifierID", $event)
              },
              "update:internal-modifier-i-d": function($event) {
                return _vm.$set(_vm.estimate, "internalModifierID", $event)
              },
              "update:hoardingModifierID": function($event) {
                return _vm.$set(_vm.estimate, "hoardingModifierID", $event)
              },
              "update:hoarding-modifier-i-d": function($event) {
                return _vm.$set(_vm.estimate, "hoardingModifierID", $event)
              },
              "update:allScaffoldTypes": function($event) {
                _vm.allScaffoldTypes = $event
              },
              "update:all-scaffold-types": function($event) {
                _vm.allScaffoldTypes = $event
              },
              "update:allScaffoldDistances": function($event) {
                _vm.allScaffoldDistances = $event
              },
              "update:all-scaffold-distances": function($event) {
                _vm.allScaffoldDistances = $event
              },
              "update:allScaffoldElevations": function($event) {
                _vm.allScaffoldElevations = $event
              },
              "update:all-scaffold-elevations": function($event) {
                _vm.allScaffoldElevations = $event
              },
              "update:allScaffoldHeights": function($event) {
                _vm.allScaffoldHeights = $event
              },
              "update:all-scaffold-heights": function($event) {
                _vm.allScaffoldHeights = $event
              },
              "update:allBuildDismantleRatios": function($event) {
                _vm.allBuildDismantleRatios = $event
              },
              "update:all-build-dismantle-ratios": function($event) {
                _vm.allBuildDismantleRatios = $event
              },
              "update:allScaffoldCongestionFactors": function($event) {
                _vm.allScaffoldCongestionFactors = $event
              },
              "update:all-scaffold-congestion-factors": function($event) {
                _vm.allScaffoldCongestionFactors = $event
              },
              "update:allInternalModifiers": function($event) {
                _vm.allInternalModifiers = $event
              },
              "update:all-internal-modifiers": function($event) {
                _vm.allInternalModifiers = $event
              },
              "update:allHoardingModifiers": function($event) {
                _vm.allHoardingModifiers = $event
              },
              "update:all-hoarding-modifiers": function($event) {
                _vm.allHoardingModifiers = $event
              }
            }
          }),
          _vm._v(" "),
          _c("v-form", { ref: "modifiersform" }),
          _vm._v(" "),
          _vm.inlineMessage.message
            ? _c(
                "v-row",
                { staticClass: "mx-2", attrs: { align: "center" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _vm.inlineMessage.message
                        ? _c(
                            "v-alert",
                            { attrs: { type: _vm.inlineMessage.type } },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.inlineMessage.message) +
                                  "\n          "
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "v-row",
            {
              staticClass: "mx-4 mb-3 d-flex d-sm-none justify-end align-center"
            },
            [
              _c(
                "v-btn",
                {
                  attrs: {
                    "data-cy": "xs-save",
                    outlined: _vm.step != _vm.lastStep,
                    color: "primary",
                    loading: _vm.saving,
                    disabled: _vm.saving || _vm.processing,
                    small: _vm.$vuetify.breakpoint.xsOnly
                  },
                  on: { click: _vm.saveDialog }
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("common.save")) +
                      "\n        "
                  )
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-actions",
            { staticClass: "pb-3" },
            [
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  staticClass: "d-none d-sm-flex",
                  attrs: {
                    "data-cy": "savebutton",
                    outlined: _vm.step != _vm.lastStep,
                    color: "primary",
                    loading: _vm.saving,
                    disabled: _vm.processing,
                    small: _vm.$vuetify.breakpoint.xsOnly
                  },
                  on: { click: _vm.saveDialog }
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("common.save-and-close")) +
                      "\n        "
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "ml-4 mr-3 fd-button-context-separator-hide-when-mobile"
                },
                [_vm._v("|")]
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: {
                    text: "",
                    disabled: _vm.processing,
                    small:
                      _vm.$vuetify.breakpoint.xsOnly &&
                      _vm.$vuetify.breakpoint.width >= 360,
                    "x-small": _vm.$vuetify.breakpoint.width < 360
                  },
                  on: { click: _vm.cancelDialog }
                },
                [_vm._v(_vm._s(_vm.$t("common.cancel")))]
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: {
                    disabled:
                      _vm.step == _vm.lastStep || _vm.processing || _vm.saving,
                    color: "primary",
                    small: _vm.$vuetify.breakpoint.xsOnly
                  },
                  on: {
                    click: function($event) {
                      _vm.step = Number(_vm.step) + 1
                    }
                  }
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("common.continue")) +
                      "\n        "
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }