import FDVue from "@fd/lib/vue";
import { mapMutations, mapActions } from "vuex";
import userAccess from "../dataMixins/userAccess";
import serviceErrorHandling from "@fd/lib/vue/mixins/serviceErrorHandling";
import tabbedView, { Tab } from "@fd/lib/vue/mixins/tabbedView";
import fileHandling, {
  FileData,
  canOpenFileInNewWindow,
  componentsFromFileName,
  confirmUniqueName,
  isFilePhoto,
  isFilePreviewable
} from "@fd/lib/vue/mixins/fileHandling";
import { FDColumnDirective } from "@fd/lib/vue/utility/dataTable";
import {
  BuildDismantleRatio,
  ExternalLink,
  HoardingModifier,
  InternalModifier,
  EstimateTotalTimes,
  PartGenerationType,
  Person,
  ProjectLocation,
  ScaffoldBayHeight,
  ScaffoldBayLength,
  ScaffoldBayWidth,
  ScaffoldCongestionFactor,
  ScaffoldDesignEstimateWithLatest,
  ScaffoldDesignStatus,
  ScaffoldDesignTakeoffPart,
  ScaffoldDesignWithDetails,
  ScaffoldDistanceModifier,
  ScaffoldElevationModifier,
  ScaffoldHeightModifier,
  ScaffoldTypeModifier,
  SummarizedScaffoldDesignPermissions,
  Tag,
  externalLinkService,
  noteService,
  personService,
  projectLocationService,
  scaffoldDesignEstimateService,
  scaffoldDesignService,
  scaffoldDesignTakeoffService,
  walkdownReferenceDataService,
  TakeoffPrintoutPart,
  reportService
} from "../services";
import { Attachment } from "../dataMixins/attachment";
import { openExternalLinkDetails } from "./components/ExternalLinkDialog.vue";
import { showTextPromptDialog } from "../../../common/client/views/components/TextPromptDialog.vue";
import downloadBlob from "@fd/lib/client-util/downloadBlob";
import printBlob from "../../../lib/client-util/printBlob";
import rules from "@fd/lib/vue/rules";
import { createScaffoldDesignEstimateDesignedNewDialog } from "./components/dialogs/SP.ScaffoldDesignEstimateDesignedNewDialog.vue";
import * as DateUtil from "@fd/lib/client-util/datetime";
import { createScaffoldDesignEstimateLWHNewDialog } from "./components/dialogs/SP.ScaffoldDesignEstimateLWHNewDialog.vue";
import notes from "../dataMixins/notes";
import { ParseNoteWithSenderDetails } from "../dataMixins/notes";
import { SortNotesArray } from "../dataMixins/notes";
import { GetPersonName, HasName, SortItemsWithName } from "../utils/person";

type Keyword = Tag;
type EstimateTotalTimesWithTotals = EstimateTotalTimes & {
  estimatedTotalTime: number;
  estimatedErectMPP: number;
  estimatedDismantleMPP: number;
};
type ScaffoldDesignEstimateWithDetails = ScaffoldDesignEstimateWithLatest & {
  generationTypeName: string;
  generationMethodName: string;
  dateString: string;
  bayLengthName: string;
  lengthBayCount: number;
  bayWidthName: string;
  widthBayCount: number;
  bayHeightName: string;

  estimatedTotalTime: number;
  estimatedErectMPP: number;
  estimatedDismantleMPP: number;

  scaffoldTypeModifierName: string;
  scaffoldDistanceModifierName: string;
  scaffoldElevationModifierName: string;
  scaffoldHeightModifierName: string;
  scaffoldCongestionFactorName: string;
  buildDismantleRatioName: string;
  internalModifierName: string;
  hoardingModifierName: string;
};

export default FDVue.extend({
  name: "fd-scaffold-design-existing",

  mixins: [userAccess, serviceErrorHandling, tabbedView, fileHandling, rules, notes],

  directives: {
    fdColumn: FDColumnDirective
  },

  components: {
    "fd-chip-selector": () => import("@fd/lib/vue/components/ChipItemSelector.vue"),
    "fd-async-search-box": () => import("@fd/lib/vue/components/AsyncSearchBox.vue"),
    "fd-add-file-button": () => import("@fd/lib/vue/components/AddFileButton.vue"),
    "sp-work-order-estimate-takeoff-parts-form": () =>
      import("./components/forms/SP.WorkOrderEstimateTakeoffPartsForm.vue")
  },

  data: function() {
    return {
      // *** GLOBAL ***
      slidein: false,

      firstTabKey: `1`,
      detailsTab: {
        tabname: this.$t("scaffold-designs.existing.tabs.details"),
        key: `1`,
        visible: true,
        error: false
      } as Tab,
      photosTab: {
        tabname: this.$t("scaffold-designs.existing.tabs.photos"),
        key: `2`,
        visible: false,
        error: false
      } as Tab,
      attachmentsTab: {
        tabname: this.$t("scaffold-designs.existing.tabs.attachments"),
        key: "3",
        visible: false,
        error: false
      } as Tab,
      estimatesTab: {
        tabname: this.$t("scaffold-designs.existing.tabs.estimate"),
        key: "4",
        visible: false,
        error: false
      } as Tab,
      notesTab: {
        tabname: this.$t("scaffold-designs.existing.tabs.notes"),
        key: `5`,
        visible: false
      } as Tab,
      designToolTab: {
        tabname: this.$t("Design Tool"),
        key: `6`,
        visible: false
      } as Tab,

      // The following will control whether or not the save button shows the processing/loading indicator
      saving: false,
      cancelling: false,

      /*** DATA ***/
      scaffoldDesignID: "",
      scaffoldDesign: {
        currentUserPermissions: {} as SummarizedScaffoldDesignPermissions,
        scaffoldDesignStatusID: 0
      } as ScaffoldDesignWithDetails,
      scaffoldDesignDetailsAreEditable: true,

      /*** DETAILS ***/
      // KEYWORDS
      selectedKeywords: [] as Keyword[],

      // REFERENCE DATA
      allAreas: [] as ProjectLocation[],
      allSubAreas: [] as ProjectLocation[],
      allDesigners: [] as Person[],
      allReviewers: [] as Person[],
      allManagers: [] as Person[],
      priorityValues: Array.from(Array(5).keys()).map(x => x + 1),
      progressValues: Array.from(Array(101).keys()).filter(x => x % 5 == 0),

      /*** ESTIMATES ****/
      tablesearchestimates: "",
      scaffoldDesignEstimates: [] as ScaffoldDesignEstimateWithDetails[],
      loadingEstimateTimeSummary: false,
      takeoffParts: [] as ScaffoldDesignTakeoffPart[],
      estimatePanel: [0, 1, 2],
      summaryPanelTimeUnitDivider: 1,
      latestEstimateTimeTotals: undefined as EstimateTotalTimesWithTotals | undefined,

      allScaffoldTypes: [] as ScaffoldTypeModifier[],
      allScaffoldDistances: [] as ScaffoldDistanceModifier[],
      allScaffoldElevations: [] as ScaffoldElevationModifier[],
      allScaffoldHeights: [] as ScaffoldHeightModifier[],
      allBuildDismantleRatios: [] as BuildDismantleRatio[],
      allScaffoldCongestionFactors: [] as ScaffoldCongestionFactor[],
      allInternalModifiers: [] as InternalModifier[],
      allHoardingModifiers: [] as HoardingModifier[],

      // *** ATTACHMENTS ***
      touchedFileName: "",
      showPhotoTabAttachmentAlert: false,
      showAttachmentTabPhotoAlert: false,
      tablesearchfiles: "",
      allFiles: [] as FileData[],
      externalLinks: [] as ExternalLink[],

      /*** IMAGE EDIT ****/
      newFileData: undefined as FileData | undefined,
      editingFileData: undefined as FileData | undefined
    };
  },

  computed: {
    // *** GLOBAL ***
    tabDefinitions(): Tab[] {
      // Details is not included since it's the first tab and is always visible
      return [this.photosTab, this.attachmentsTab, this.estimatesTab, this.notesTab] as Tab[];
    },
    scaffoldDesignRules(): any {
      return {
        areaID: [this.rules.required],
        subAreaID: [this.rules.required],
        siteContact: [],
        specificWorkLocation: [],
        detailedWorkDescription: []
      };
    },
    selectableStatuses(): any[] {
      return (Object.keys(ScaffoldDesignStatus)
        .filter(x => !isNaN(Number(x)))
        .map(x => Number(x)) as number[]).map(x => {
        return {
          value: x,
          text: this.$t(`scaffold-designs.status.${x}`)
        };
      });
    },
    scaffoldDesignIsCancelled(): boolean {
      return this.scaffoldDesign.scaffoldDesignStatusID == ScaffoldDesignStatus.Cancelled;
    },
    scaffoldDesignIsOnHold(): boolean {
      return this.scaffoldDesign.scaffoldDesignStatusID == ScaffoldDesignStatus.OnHold;
    },
    currentUserCanEditDetails(): boolean {
      return this.scaffoldDesign.currentUserPermissions.canEditDetails;
    },
    scaffoldDesignIsEditableAndUserHasPermissions(): boolean {
      return this.currentUserCanEditDetails && this.scaffoldDesignDetailsAreEditable;
    },
    // *** DETAILS ***
    availableKeywords(): Keyword[] {
      return this.$store.getters.sortedEnabledTags;
    },
    allKeywords(): Keyword[] {
      return this.$store.state.tags.fullList as Keyword[];
    },
    subAreas(): ProjectLocation[] {
      let areaID = this.scaffoldDesign.areaID;
      if (!areaID) return [];

      let subAreas = this.allSubAreas.filter(
        x => !!x.parentLocationID && x.parentLocationID == areaID
      );
      // if (
      //   this.scaffoldDesign.currentUserPermissions.canEditArea &&
      //   !this.readonly &&
      //   subAreas.length == 1 &&
      //   !this.scaffoldDesign.subAreaID
      // ) {
      //   this.$nextTick(() => {
      //     this.scaffoldDesign.subAreaID = subAreas[0].id;
      //   });
      // }
      return subAreas;
    },

    // *** PHOTOS ***
    photoFiles(): FileData[] {
      return this.allFiles.filter(x => x.isPhoto);
    },

    // *** ATTACHMENTS ***
    nonPhotoAttachments(): Attachment[] {
      let attachments = [] as Attachment[];

      this.allFiles.forEach(file => {
        attachments.push({
          type: "file",
          name: file.name,
          isPhoto: file.isPreviewable ?? false,
          isPreviewable: file.isPreviewable ?? false,
          canOpenInNew: canOpenFileInNewWindow(file.name),
          file: file
        });
      });

      this.externalLinks.forEach(link => {
        attachments.push({
          type: "link",
          name: link.name!,
          isPhoto: false,
          isPreviewable: false,
          canOpenInNew: true,
          link: link
        });
      });

      return attachments.filter(x => !x.isPhoto);
    },

    // *** ESTIMATE ***
    summaryPanelTimeUnit() {
      if (this.summaryPanelTimeUnitDivider == 60) return this.$t("common.hours");

      return this.$t("common.minutes-short");
    },
    currentEstimate(): ScaffoldDesignEstimateWithDetails {
      let estimate = this.scaffoldDesignEstimates.find(x => !!x.isLatest);
      if (!estimate && !!this.scaffoldDesignEstimates?.length)
        estimate = this.scaffoldDesignEstimates[0];
      return estimate ?? ({} as ScaffoldDesignEstimateWithDetails);
    },

    currentEstimateIsLWH(): boolean {
      return this.currentEstimate.generationTypeID == PartGenerationType.LWH;
    },
    currentEstimateLWHSummary(): string {
      var parts = [] as string[];
      parts.push((this.currentEstimate?.length ?? 0).toString());
      parts.push((this.currentEstimate?.width ?? 0).toString());
      parts.push((this.currentEstimate?.height ?? 0).toString());
      return parts.join(" x ");
    },
    currentEstimateTimesArePreview(): boolean {
      return (
        !!this.latestEstimateTimeTotals &&
        (this.currentEstimate.estimatedTotalErectMinutes !=
          this.currentEstimateTimeTotals.estimatedTotalErectMinutes ||
          this.currentEstimate.estimatedTotalDismantleMinutes !=
            this.currentEstimateTimeTotals.estimatedTotalDismantleMinutes ||
          this.currentEstimate.estimatedTotalModifyMinutes !=
            this.currentEstimateTimeTotals.estimatedTotalModifyMinutes ||
          this.currentEstimate.estimatedTotalMobilizationMinutes !=
            this.currentEstimateTimeTotals.estimatedTotalMobilizationMinutes ||
          this.currentEstimate.estimatedTotalDemobilizationMinutes !=
            this.currentEstimateTimeTotals.estimatedTotalDemobilizationMinutes ||
          this.currentEstimate.estimatedTotalHoardingMinutes !=
            this.currentEstimateTimeTotals.estimatedTotalHoardingMinutes ||
          this.currentEstimate.estimatedTotalWeight !=
            this.currentEstimateTimeTotals.estimatedTotalWeight ||
          this.currentEstimate.estimatedTotalPartCount !=
            this.currentEstimateTimeTotals.estimatedTotalPartCount)
      );
    },
    currentEstimateTimeTotals(): EstimateTotalTimes {
      return (
        this.latestEstimateTimeTotals ??
        ({
          estimatedTotalErectMinutes: this.currentEstimate.estimatedTotalErectMinutes,
          estimatedTotalDismantleMinutes: this.currentEstimate.estimatedTotalDismantleMinutes,
          estimatedTotalModifyMinutes: this.currentEstimate.estimatedTotalModifyMinutes,
          estimatedTotalMobilizationMinutes: this.currentEstimate.estimatedTotalMobilizationMinutes,
          estimatedTotalDemobilizationMinutes: this.currentEstimate
            .estimatedTotalDemobilizationMinutes,
          estimatedTotalHoardingMinutes: this.currentEstimate.estimatedTotalHoardingMinutes,
          estimatedTotalWeight: this.currentEstimate.estimatedTotalWeight,
          estimatedTotalPartCount: this.currentEstimate.estimatedTotalPartCount,
          estimatedTotalTime:
            (this.currentEstimate.estimatedTotalDemobilizationMinutes ?? 0.0) +
            (this.currentEstimate.estimatedTotalDismantleMinutes ?? 0.0) +
            (this.currentEstimate.estimatedTotalErectMinutes ?? 0.0) +
            (this.currentEstimate.estimatedTotalHoardingMinutes ?? 0.0) +
            (this.currentEstimate.estimatedTotalMobilizationMinutes ?? 0.0) +
            (this.currentEstimate.estimatedTotalModifyMinutes ?? 0.0),
          estimatedErectMPP:
            (this.currentEstimate.estimatedTotalErectMinutes ?? 0.0) /
            (this.currentEstimate.estimatedTotalPartCount ?? 1),
          estimatedDismantleMPP:
            (this.currentEstimate.estimatedTotalDismantleMinutes ?? 0.0) /
            (this.currentEstimate.estimatedTotalPartCount ?? 1)
        } as EstimateTotalTimesWithTotals)
      );
    }
  },

  methods: {
    ...mapMutations({
      notifyNewBreadcrumb: "NOTIFY_NEW_BREADCRUMB",
      setFilteringContext: "SET_FILTERING_CONTEXT"
    }),

    getTimeDisplay(
      minutes: string | number | undefined | null,
      digits: number = 2,
      allowZero: boolean = false
    ) {
      if (minutes == undefined || minutes == null) return undefined;
      let val = Number(minutes);
      if (isNaN(val)) return undefined;

      let time = val / this.summaryPanelTimeUnitDivider;
      return this.formatNumber(time, digits, allowZero);
    },
    formatNumber(
      number: string | number | undefined | null,
      digits: number = 2,
      allowZero: boolean = false
    ): string | undefined {
      if (number == undefined || number == null) return undefined;
      let val = Number(number);
      if (isNaN(val)) return undefined;
      if (!allowZero && val == 0) return undefined;
      return val.toFixed(digits);
    },

    onSubmit(e: Event) {
      e.preventDefault();
      this.save(false);
    },

    preventSubmit(e: Event) {
      e.preventDefault();
      return false;
    },
    cancel() {
      this.$router.back();
    },

    async save(closeOnComplete: boolean) {
      this.processing = true;
      this.saving = true;
      try {
        var saveEstimateModifiers =
          this.scaffoldDesign.currentUserPermissions.canEditEstimateDetails &&
          !!this.currentEstimate &&
          this.currentEstimateTimesArePreview;

        this.scaffoldDesign.coordX = isNaN(Number(this.scaffoldDesign.coordX))
          ? null
          : Number(this.scaffoldDesign.coordX);
        this.scaffoldDesign.coordY = isNaN(Number(this.scaffoldDesign.coordY))
          ? null
          : Number(this.scaffoldDesign.coordY);
        this.scaffoldDesign.coordZ = isNaN(Number(this.scaffoldDesign.coordZ))
          ? null
          : Number(this.scaffoldDesign.coordZ);
        let tagIDs =
          this.selectedKeywords.length > 0 ? this.selectedKeywords.map(x => x.id!) : undefined;

        await scaffoldDesignService.updateItem(
          this.scaffoldDesign.id!,
          {
            ...this.scaffoldDesign,
            tagIDs: tagIDs
          } as ScaffoldDesignWithDetails,
          "ScaffoldDesignExisting.save"
        );

        if (saveEstimateModifiers) {
          this.currentEstimate.modificationPercent = !this.currentEstimate.modificationPercent
            ? 0
            : +this.currentEstimate.modificationPercent;
          this.currentEstimate.factor1 = !this.currentEstimate.factor1
            ? 0
            : +this.currentEstimate.factor1;
          this.currentEstimate.factor2 = !this.currentEstimate.factor2
            ? 0
            : +this.currentEstimate.factor2;
          this.currentEstimate.crewSize = !this.currentEstimate.crewSize
            ? 0
            : +this.currentEstimate.crewSize;
          await scaffoldDesignEstimateService.updateScaffoldDesignEstimateModifiers(
            this.currentEstimate.id!,
            this.currentEstimate
          );
        }

        var snackbarPayload = {
          text: this.$t("scaffold-designs.save-success", [this.scaffoldDesign.scaffoldNumber]),
          type: "success",
          undoCallback: null
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);

        if (closeOnComplete) {
          this.$router.push("/scaffolddesigns");
        } else {
          await this.loadScaffoldDesignDetails();
          if (saveEstimateModifiers) {
            await this.loadScaffoldDesignEstimateData();
          }
        }
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.saving = false;
      }
    },

    // *** LOADING ***
    async loadScaffoldDesignDetails() {
      var scaffoldDesign = await scaffoldDesignService.getByID(this.scaffoldDesignID);
      this.scaffoldDesign = scaffoldDesign;
      this.scaffoldDesignDetailsAreEditable =
        scaffoldDesign.scaffoldDesignStatusID == ScaffoldDesignStatus.New ||
        scaffoldDesign.scaffoldDesignStatusID == ScaffoldDesignStatus.Started ||
        scaffoldDesign.scaffoldDesignStatusID == ScaffoldDesignStatus.OnHold ||
        scaffoldDesign.scaffoldDesignStatusID == ScaffoldDesignStatus.Declined;
    },

    async loadPeople() {
      var allPeople = SortItemsWithName(
        (await personService.getAll(false, null, null)).map(
          p =>
            ({
              ...p,
              name: GetPersonName(p)
            } as Person & HasName)
        )
      );
      this.allDesigners = allPeople.filter(x => !!x.isDesigner);
      this.allReviewers = allPeople.filter(x => !!x.isDesigner || !!x.isDesignManager);
      this.allManagers = allPeople.filter(x => !!x.isDesignManager);
    },

    // DOES NOT manage processing or error message logic
    async loadAreas(): Promise<void> {
      let areas = await projectLocationService.getVisibleAreas();
      this.allAreas = SortItemsWithName(areas);
    },

    // DOES NOT manage processing or error message logic
    async loadSubAreas(): Promise<void> {
      let subAreas = await projectLocationService.getVisibleSubAreas();
      this.allSubAreas = SortItemsWithName(subAreas);
    },

    // DOES NOT manage processing or error message logic
    async loadScaffoldTypes(): Promise<void> {
      this.allScaffoldTypes = (
        await walkdownReferenceDataService.getAllScaffoldTypeModifiers()
      ).sort((a, b) => (a.legacyID ?? 0) - (b.legacyID ?? 0));
    },

    // DOES NOT manage processing or error message logic
    async loadScaffoldDistances(): Promise<void> {
      this.allScaffoldDistances = (
        await walkdownReferenceDataService.getAllScaffoldDistanceModifiers()
      ).sort((a, b) => (a.legacyID ?? 0) - (b.legacyID ?? 0));
    },

    // DOES NOT manage processing or error message logic
    async loadScaffoldHeights(): Promise<void> {
      this.allScaffoldHeights = (
        await walkdownReferenceDataService.getAllScaffoldHeightModifiers()
      ).sort((a, b) => (a.legacyID ?? 0) - (b.legacyID ?? 0));
    },

    // DOES NOT manage processing or error message logic
    async loadScaffoldElevations(): Promise<void> {
      this.allScaffoldElevations = (
        await walkdownReferenceDataService.getAllScaffoldElevationModifiers()
      ).sort((a, b) => (a.legacyID ?? 0) - (b.legacyID ?? 0));
    },

    // DOES NOT manage processing or error message logic
    async loadScaffoldCongestionFactors(): Promise<void> {
      this.allScaffoldCongestionFactors = (
        await walkdownReferenceDataService.getAllScaffoldCongestionFactors()
      ).sort((a, b) => (a.legacyID ?? 0) - (b.legacyID ?? 0));
    },

    // DOES NOT manage processing or error message logic
    async loadBuildDismantleRatios(): Promise<void> {
      this.allBuildDismantleRatios = (
        await walkdownReferenceDataService.getAllBuildDismantleRatios()
      ).sort((a, b) => (a.legacyID ?? 0) - (b.legacyID ?? 0));
    },

    // DOES NOT manage processing or error message logic
    async loadInternalModifiers(): Promise<void> {
      this.allInternalModifiers = (
        await walkdownReferenceDataService.getAllInternalModifiers()
      ).sort((a, b) => (a.legacyID ?? 0) - (b.legacyID ?? 0));
    },

    // DOES NOT manage processing or error message logic
    async loadHoardingModifiers(): Promise<void> {
      this.allHoardingModifiers = (
        await walkdownReferenceDataService.getAllHoardingModifiers()
      ).sort((a, b) => (a.legacyID ?? 0) - (b.legacyID ?? 0));
    },

    // *** ATTACHMENTS ***
    // Attachments - Catch the generic "Attachment" objects and pass along to link or file-specific actions
    async openAttachment(item: Attachment) {
      if (!item.canOpenInNew) return;

      if (!!item.file && item.canOpenInNew) {
        await this.openFileInNewWindow(item.file);
      } else if (!!item.link) {
        let url = item.link.address;
        window.open(url, "_blank");
      }
    },
    async editAttachment(item: Attachment) {
      if (!!item.link) {
        await this.editLink(item.link);
      } else if (!!item.file && item.file.isPreviewable) {
        await this.editFile(item.file);
      } else if (!!item.file) {
        await this.editNameForFile(item.file);
      }
    },
    async deleteAttachment(item: Attachment) {
      if (!!item.link) {
        await this.deleteLink(item.link);
      } else if (!!item.file) {
        await this.deleteFile(item.file);
      }
    },

    // Links
    async loadLinks() {
      let currentProcessing = this.processing;
      this.processing = true;
      try {
        var links = await externalLinkService.getByScaffoldDesignID(this.scaffoldDesign.id!);
        this.externalLinks = links;
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = currentProcessing;
      }
    },
    // Method to open the dialog for when the user wishes to add a new External Link.
    async openNewExternalLinkDialog() {
      let newLink = await openExternalLinkDetails();
      if (!!newLink) {
        await this.saveNewExternalLink(newLink);
      }
    },
    async saveNewExternalLink(newLink: ExternalLink) {
      let currentProcessing = this.processing;
      this.processing = true;
      try {
        newLink.scaffoldDesignID = this.scaffoldDesign.id;
        await externalLinkService.addItem(newLink);

        if (!this.externalLinks) this.externalLinks = [];
        this.externalLinks.push(newLink);

        this.showAttachmentTabPhotoAlert = false;
        this.showPhotoTabAttachmentAlert = false;

        var snackbarPayload = {
          text: this.$t("scaffold-designs.existing.save-link-success", [newLink.name]),
          type: "success"
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
        this.touchedFileName = newLink.name ?? "";
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = currentProcessing;
      }
    },
    async editLink(link: ExternalLink) {
      let editedLink = await openExternalLinkDetails(link);
      if (!!editedLink) {
        let currentProcessing = this.processing;
        this.processing = true;
        try {
          await externalLinkService.updateItem(link.id!, {
            ...link,
            name: editedLink.name,
            address: editedLink.address
          });
          link.name = editedLink.name;
          link.address = editedLink.address;

          var snackbarPayload = {
            text: this.$t("scaffold-designs.existing.update-link-success", [link.name]),
            type: "success"
          };
          this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
          this.touchedFileName = link.name ?? "";
        } catch (error) {
          this.handleError(error as Error);
        } finally {
          this.processing = currentProcessing;
        }
      }
    },
    async deleteLink(link: ExternalLink) {
      let currentProcessing = this.processing;
      this.processing = true;
      try {
        await externalLinkService.deleteItem(link.id!);
        this.externalLinks.splice(this.externalLinks.indexOf(link), 1);

        var snackbarPayload = {
          text: this.$t("scaffold-designs.existing.delete-link-success", [link.name]),
          type: "info",
          undoCallback: async () => {
            await this.saveNewExternalLink(link);
          }
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
        this.touchedFileName = link.name ?? "";
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = currentProcessing;
      }
    },

    // Files & Photos
    async loadFiles() {
      if (!this.scaffoldDesign.id) return;

      var fileNames = await scaffoldDesignService.getScaffoldDesignFileList(this.scaffoldDesign.id);
      this.allFiles = fileNames.map(function(fileName) {
        return {
          name: fileName,
          isPreviewable: isFilePreviewable(fileName),
          isPhoto: isFilePhoto(fileName)
        } as FileData;
      });
    },
    async selectFile() {
      (this.$refs.addFileButton as any).click();
    },
    async selectNewFile(originalFile: File) {
      var fileData = await this.optimizedFileDataForUpload(originalFile, this.allFiles);
      if (!fileData) return;

      // GIF files with animations will lose their animation during this process
      // Both due to the quality compression done above (resizing the dimensions of an animated GIF does nothing), and also going through the edit image process
      // This is OK as we shouldn't need animations for any reason
      if (fileData.isPreviewable) {
        this.newFileData = fileData;
        this.imageName = fileData.name;
        this.editImageSource = this.covertFileToDataURL(fileData.file);
      } else {
        await this.saveNewFileData(fileData);
      }
    },
    async handleEdit(res: File, fileName: string | undefined) {
      this.editImageSource = undefined;
      this.imageName = "";

      if (!!this.newFileData) {
        this.newFileData.file = res;
        if (!!fileName) this.newFileData.name = confirmUniqueName(fileName, this.allFiles);

        await this.saveNewFileData(this.newFileData);

        this.newFileData = undefined;
      } else if (!!this.editingFileData) {
        var originalFileName = this.editingFileData.name;

        var allFilesWithoutEditedFileData = this.allFiles.slice();
        allFilesWithoutEditedFileData.splice(
          allFilesWithoutEditedFileData.indexOf(this.editingFileData),
          1
        );
        var uniqueFileName = confirmUniqueName(
          fileName ?? originalFileName,
          allFilesWithoutEditedFileData
        );

        this.editingFileData.name = uniqueFileName;
        this.editingFileData.file = res;

        this.saveEditedFileData(this.editingFileData, originalFileName);

        this.editingFileData = undefined;
      }
    },
    async saveEditedFileData(fileData: FileData, originalFileName: string) {
      if (!fileData) return;
      if (!this.scaffoldDesign.id) return;

      this.processing = true;
      try {
        if (!fileData.file) {
          // If we're only renaming the file, the data may not be downloaded yet
          let fileNameToDownload = originalFileName ?? fileData.name;
          fileData.file = await scaffoldDesignService.downloadScaffoldDesignFile(
            this.scaffoldDesign.id,
            fileNameToDownload
          );
        }
        await scaffoldDesignService.uploadScaffoldDesignFile(
          this.scaffoldDesign.id,
          fileData.name,
          fileData.file as Blob
        );

        if (!!originalFileName && originalFileName != fileData.name) {
          // File has been renamed.  The file in the list has already been updated with all relevant data, but we need to delete the file with the old name
          // We don't call the delete method here because we don't care about its data, an undo, or a delete snackbar
          await scaffoldDesignService.deleteScaffoldDesignFile(
            this.scaffoldDesign.id,
            originalFileName
          );
        }
        let snackbarText = fileData.isPhoto
          ? this.$t("scaffold-designs.existing.update-photo-success", [fileData.name])
          : this.$t("scaffold-designs.existing.update-file-success", [fileData.name]);
        let snackbarPayload = {
          text: snackbarText,
          type: "success"
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
        this.touchedFileName = fileData.name;
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    async saveNewFileData(fileData: FileData | undefined) {
      if (!fileData) return;
      if (!this.scaffoldDesign.id) return;

      this.processing = true;
      try {
        await scaffoldDesignService.uploadScaffoldDesignFile(
          this.scaffoldDesign.id,
          fileData.name,
          fileData.file as Blob
        );

        this.allFiles.push(fileData);

        let snackbarText = fileData.isPhoto
          ? this.$t("scaffold-designs.existing.save-photo-success", [fileData.name])
          : this.$t("scaffold-designs.existing.save-file-success", [fileData.name]);
        let snackbarPayload = {
          text: snackbarText,
          type: "success",
          undoCallback: null
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);

        this.touchedFileName = fileData.name;
        this.showPhotoTabAttachmentAlert = this.selectedTab == this.photosTab && !fileData.isPhoto;
        this.showAttachmentTabPhotoAlert =
          this.selectedTab == this.attachmentsTab && fileData.isPhoto == true;
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    async editNameForFile(fileData: FileData) {
      let components = componentsFromFileName(fileData.name);
      let newName = await showTextPromptDialog({
        title: this.$t("attachments.edit-file-name-title"),
        label: this.$t("common.name"),
        rules: [this.rules.required],
        text: components.name
      });
      if (!!newName?.length && newName.toLowerCase() != components.name.toLowerCase()) {
        let newFileName = `${newName}.${components.extension}`;
        var originalFileName = fileData.name;
        if (newFileName.toLowerCase() == originalFileName.toLowerCase()) return;

        var uniqueFileName = confirmUniqueName(newFileName, this.allFiles);

        fileData.name = uniqueFileName;
        this.saveEditedFileData(fileData, originalFileName);

        this.editingFileData = undefined;
      }
    },
    editFile(fileData: FileData) {
      if (!fileData.isPhoto) return;

      this.editingFileData = fileData;
      this.imageName = fileData.name;
      if (!!fileData.file) {
        this.editImageSource = this.covertFileToDataURL(fileData.file);
      } else {
        this.editImageSource = `/services/FormidableDesigns.Services.V1.ScaffoldDesignService.DownloadScaffoldDesignFile?designId=${this.scaffoldDesign.id}&fileName=${fileData.name}`;
      }
    },
    async downloadFile(fileData: FileData) {
      if (!!fileData.file) {
        downloadBlob(fileData.file, fileData.name);
        return;
      }
      if (!this.scaffoldDesign.id) return;

      let fileName = fileData.name;
      this.processing = true;
      try {
        var file = await scaffoldDesignService.downloadScaffoldDesignFile(
          this.scaffoldDesign.id!,
          fileName
        );
        downloadBlob(file, fileName);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    async openFileInNewWindow(fileData: FileData) {
      if (!this.scaffoldDesign.id) return;

      let currentProcessing = this.processing;
      this.processing = true;
      if (!fileData.file) {
        // the data probably hasn't been downloaded yet
        fileData.file = await scaffoldDesignService.downloadScaffoldDesignFile(
          this.scaffoldDesign.id,
          fileData.name
        );
      }
      let url = URL.createObjectURL(fileData.file);
      window.open(url, "_blank");
      this.processing = currentProcessing;
    },
    async viewFile(fileData: FileData) {
      if (!fileData.isPreviewable) return;
      if (!this.scaffoldDesign.id) return;

      this.imageName = fileData.name;
      if (!fileData.file) {
        // Cache the file data to avoid having to download it multiple times
        var file = await scaffoldDesignService.downloadScaffoldDesignFile(
          this.scaffoldDesign.id!,
          fileData.name
        );
        fileData.file = file;
      }
      if (!!fileData.file) {
        this.imageSource = this.covertFileToDataURL(fileData.file);
      } else {
        this.imageSource = `/services/FormidableDesigns.Services.V1.ScaffoldDesignService.DownloadScaffoldDesignFile?designId=${this.scaffoldDesign.id}&fileName=${fileData.name}`;
      }
    },
    async deleteFile(fileData: FileData) {
      if (!this.scaffoldDesign.id) return;

      this.processing = true;
      try {
        if (!fileData.file) {
          // When deleting from the table, the data probably hasn't been downloaded yet
          // So we can't do an undo unless we get the file data to re-save first
          fileData.file = await scaffoldDesignService.downloadScaffoldDesignFile(
            this.scaffoldDesign.id!,
            fileData.name
          );
        }
        await scaffoldDesignService.deleteScaffoldDesignFile(
          this.scaffoldDesign.id!,
          fileData.name
        );

        this.allFiles.splice(this.allFiles.indexOf(fileData), 1);

        let snackbarText = fileData.isPhoto
          ? this.$t("scaffold-designs.existing.delete-photo-success", [fileData.name])
          : this.$t("scaffold-designs.existing.delete-file-success", [fileData.name]);
        var snackbarPayload = {
          text: snackbarText,
          type: "info",
          undoCallback: async () => {
            await this.saveNewFileData(fileData);
          }
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
        this.touchedFileName = fileData.name;
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },

    /*** ESTIMATES ***/
    parseScaffoldDesignEstimateDetails(
      e: ScaffoldDesignEstimateWithLatest
    ): ScaffoldDesignEstimateWithDetails {
      let bayLength = (this.$store.state.scaffoldBayLengths.fullList as ScaffoldBayLength[]).find(
        l => l.id == e.scaffoldBayLengthID
      );
      let lengthBayCount =
        !!bayLength?.inches && bayLength.inches > 0
          ? Math.floor(((e.width ?? 0) * 12) / bayLength.inches)
          : 0;
      let bayWidth = (this.$store.state.scaffoldBayWidths.fullList as ScaffoldBayWidth[]).find(
        l => l.id == e.scaffoldBayWidthID
      );
      let widthBayCount =
        !!bayWidth?.inches && bayWidth.inches > 0
          ? Math.floor(((e.width ?? 0) * 12) / bayWidth.inches)
          : 0;
      let bayHeight = (this.$store.state.scaffoldBayHeights.fullList as ScaffoldBayHeight[]).find(
        l => l.id == e.scaffoldBayHeightID
      );

      let typeModifier = this.allScaffoldTypes.find(mod => mod.id == e.scaffoldTypeModifierID);
      let heightModifier = this.allScaffoldHeights.find(
        mod => mod.id == e.scaffoldHeightModifierID
      );
      let distanceModifier = this.allScaffoldDistances.find(
        mod => mod.id == e.scaffoldDistanceModifierID
      );
      let elevationModifier = this.allScaffoldElevations.find(
        mod => mod.id == e.scaffoldElevationModifierID
      );
      let congestionFactor = this.allScaffoldCongestionFactors.find(
        mod => mod.id == e.scaffoldCongestionFactorID
      );
      let buildDismantleRatio = this.allBuildDismantleRatios.find(
        mod => mod.id == e.buildDismantleRatioID
      );
      let internalModifier = this.allInternalModifiers.find(mod => mod.id == e.internalModifierID);
      let hoardingModifier = this.allHoardingModifiers.find(mod => mod.id == e.hoardingModifierID);
      return {
        ...e,
        generationTypeName: this.$t(
          `work-order-estimates.generation-types.${e.generationTypeID ?? 0}`
        ),
        generationMethodName: this.$t("scheduler.estimates.generation-methods.manual"),
        dateTimeString: DateUtil.localizedDateTimeString(
          new Date(DateUtil.isoDateTimeString(e.created))
        ),
        dateString: DateUtil.stripTimeFromLocalizedDateTime(
          new Date(DateUtil.isoDateTimeString(e.created))
        ),
        bayLengthName: bayLength?.name,
        lengthBayCount: lengthBayCount,
        bayWidthName: bayWidth?.name,
        widthBayCount: widthBayCount,
        bayHeightName: bayHeight?.name,

        estimatedTotalTime:
          (e.estimatedTotalDemobilizationMinutes ?? 0.0) +
          (e.estimatedTotalDismantleMinutes ?? 0.0) +
          (e.estimatedTotalErectMinutes ?? 0.0) +
          (e.estimatedTotalHoardingMinutes ?? 0.0) +
          (e.estimatedTotalMobilizationMinutes ?? 0.0) +
          (e.estimatedTotalModifyMinutes ?? 0.0),
        estimatedErectMPP: (e.estimatedTotalErectMinutes ?? 0.0) / (e.estimatedTotalPartCount ?? 1),
        estimatedDismantleMPP:
          (e.estimatedTotalDismantleMinutes ?? 0.0) / (e.estimatedTotalPartCount ?? 1),

        scaffoldTypeModifierName: typeModifier?.name,
        scaffoldHeightModifierName: heightModifier?.name,
        scaffoldDistanceModifierName: distanceModifier?.name,
        scaffoldElevationModifierName: elevationModifier?.name,
        scaffoldCongestionFactorName: congestionFactor?.name,
        buildDismantleRatioName: buildDismantleRatio?.ratio,
        internalModifierName: internalModifier?.name,
        hoardingModifierName: hoardingModifier?.name
      } as ScaffoldDesignEstimateWithDetails;
    },

    async loadScaffoldDesignEstimateData() {
      this.loadingEstimateTimeSummary = true;
      try {
        let estimates = await scaffoldDesignEstimateService.getEstimatesForScaffoldDesignWithID(
          this.scaffoldDesign.id!
        );
        this.scaffoldDesignEstimates = estimates
          .sort((a, b) => {
            // Sort with newest at top
            let aCreated = new Date(a.created!);
            let bCreated = new Date(b.created!);
            return bCreated.getTime() - aCreated.getTime();
          })
          .map(this.parseScaffoldDesignEstimateDetails);
        this.latestEstimateTimeTotals = undefined;

        this.takeoffParts = await scaffoldDesignTakeoffService.getTakeoffForScaffoldDesignWithID(
          this.scaffoldDesign.id!
        );
      } catch (error) {
        throw error;
      } finally {
        this.loadingEstimateTimeSummary = false;
      }
    },
    canDownloadEstimateExcelFile(estimate: ScaffoldDesignEstimateWithDetails | null | undefined) {
      if (!estimate?.id?.length) return false;
      if (!estimate.isLatest && estimate.generationTypeID != PartGenerationType.LWH) return false;
      return true;
    },
    async downloadEstimateExcelFile(estimateID: string | null | undefined) {
      if (!estimateID?.length) estimateID = this.currentEstimate.id!;
      this.processing = true;
      try {
        var blob = await scaffoldDesignEstimateService.downloadPopulatedScaffoldDesignEstimateFileByID(
          estimateID!
        );
        let tag = `T${`00000${this.scaffoldDesign.scaffoldNumber}`.slice(-5)}`;
        downloadBlob(blob, `scaffold-design-estimate-${tag}.xlsx`);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },

    async openNewEstimateDialog(designed: boolean) {
      // Grab the latest estimate to pass in to the dialog to default the modifier values
      let estimate = this.currentEstimate;
      let created = false;
      if (designed) {
        created = await createScaffoldDesignEstimateDesignedNewDialog(
          this.scaffoldDesign,
          estimate
        );
      } else {
        // We're opening a new LWH dialog, so we need more than modifier values
        // If the estimate is a designed estimate, grab the latest LWH dialog for default estimate values
        if (estimate?.generationTypeID == PartGenerationType.Designed) {
          let lwhEstimates = this.scaffoldDesignEstimates?.filter(
            e =>
              e.generationTypeID == PartGenerationType.LWH ||
              e.generationTypeID == PartGenerationType.Modify
          );
          if (!!lwhEstimates?.length) {
            estimate = lwhEstimates.find(x => !!x.isLatest) ?? lwhEstimates[0];
            console.log(`estimate replace with LWH estimate: ${JSON.stringify(estimate)}`);
          }
        }

        created = await createScaffoldDesignEstimateLWHNewDialog(this.scaffoldDesign, estimate);
      }
      if (created) {
        this.processing = true;
        try {
          await this.loadScaffoldDesignEstimateData();
        } catch (error) {
          this.handleError(error as Error);
        } finally {
          this.processing = false;
        }
      }
    },

    async downloadAndPrintTakeoffReport(reportType: string) {
      let parts = this.takeoffParts.map(
        x =>
          ({
            ...x,
            partID: x.partID!,
            name: x.partName,
            code: x.partPublicID,
            description: x.partDescription
          } as TakeoffPrintoutPart)
      );
      let areaName = this.allAreas.find(x => x.id == this.scaffoldDesign.areaID)?.name;
      let subAreaName = this.allSubAreas.find(x => x.id == this.scaffoldDesign.subAreaID)?.name;
      this.processing = true;
      try {
        var blob = await reportService.getScaffoldDesignTakeoffPrintoutReportContentWithData(
          parts,
          this.currentEstimate?.id ?? null,
          this.scaffoldDesign.scaffoldID ?? null,
          this.scaffoldDesign.scaffoldNumber ?? "",
          areaName ?? "",
          subAreaName ?? "",
          reportType,
          DateUtil.localizedDateTimeString(new Date())
        );
        if (reportType == "xls") {
          downloadBlob(blob, "takeoff-printout.xlsx");
        } else {
          printBlob(blob, "takeoff-printout.pdf", "application/pdf");
        }
      } catch (error) {
      } finally {
        this.processing = false;
      }
    },

    async clearTakeoffParts() {
      this.processing = true;
      try {
        // Clearing the parts is the equivalent to creating a new, empty designed estimate
        // This tracks the fact that the user changed something, and also gives a new record to associate the new time calculations too
        // without putting new times to old estimate data.
        let latestEstimate = {} as ScaffoldDesignEstimateWithDetails;
        if (!!this.scaffoldDesignEstimates?.length)
          latestEstimate =
            this.scaffoldDesignEstimates.find(x => !!x.isLatest) ?? this.scaffoldDesignEstimates[0];
        await scaffoldDesignEstimateService.generateNewDesignedEstimateForScaffoldDesignWithID(
          this.scaffoldDesign.id!,
          [],
          latestEstimate
        );

        await this.loadScaffoldDesignEstimateData();
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },

    async reloadCurrentEstimateTimeSummary() {
      this.processing = true;
      this.loadingEstimateTimeSummary = true;
      try {
        this.currentEstimate.modificationPercent = !this.currentEstimate.modificationPercent
          ? 0
          : +this.currentEstimate.modificationPercent;
        this.currentEstimate.factor1 = !this.currentEstimate.factor1
          ? 0
          : +this.currentEstimate.factor1;
        this.currentEstimate.factor2 = !this.currentEstimate.factor2
          ? 0
          : +this.currentEstimate.factor2;
        this.currentEstimate.crewSize = !this.currentEstimate.crewSize
          ? 0
          : +this.currentEstimate.crewSize;
        let latestEstimateTimeTotals = await scaffoldDesignEstimateService.getScaffoldDesignEstimateTotalTimesWithNewModifiers(
          this.scaffoldDesign.id!,
          this.currentEstimate
        );
        this.latestEstimateTimeTotals = {
          ...latestEstimateTimeTotals,
          estimatedTotalTime:
            (latestEstimateTimeTotals.estimatedTotalDemobilizationMinutes ?? 0.0) +
            (latestEstimateTimeTotals.estimatedTotalDismantleMinutes ?? 0.0) +
            (latestEstimateTimeTotals.estimatedTotalErectMinutes ?? 0.0) +
            (latestEstimateTimeTotals.estimatedTotalHoardingMinutes ?? 0.0) +
            (latestEstimateTimeTotals.estimatedTotalMobilizationMinutes ?? 0.0) +
            (latestEstimateTimeTotals.estimatedTotalModifyMinutes ?? 0.0),
          estimatedErectMPP:
            (latestEstimateTimeTotals.estimatedTotalErectMinutes ?? 0.0) /
            (latestEstimateTimeTotals.estimatedTotalPartCount ?? 1),
          estimatedDismantleMPP:
            (latestEstimateTimeTotals.estimatedTotalDismantleMinutes ?? 0.0) /
            (latestEstimateTimeTotals.estimatedTotalPartCount ?? 1)
        };
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.loadingEstimateTimeSummary = false;
      }
    },

    /*** NOTES ***/
    async addNewNote() {
      if (!this.newNoteText.length) return;

      this.processing = true;
      this.saving = true;
      try {
        var newNote = await noteService.addNewNoteForScaffoldDesign(
          this.newNoteText,
          this.scaffoldDesign.id!
        );
        this.inlineMessage.message = "";
        let noteToAdd = ParseNoteWithSenderDetails(newNote);
        noteToAdd.isNew = true;
        this.notes.push(noteToAdd);
        this.notes = SortNotesArray(this.notes);
        this.newNoteText = "";
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.saving = false;
      }
    }
  },

  watch: {
    scaffoldDesign: function(newValue) {
      if ((this.$store.state.lastBreadcrumbs[0]?.to || "") != "/scaffolddesigns") {
        this.notifyNewBreadcrumb({
          text: this.$t("scaffold-designs.list.title"),
          to: "/scaffolddesigns",
          resetHistory: true
        });
        // This is needed in order to salvage the "last breadcrumbs" in the store.
        this.$store.commit("NOTIFY_NAVIGATION_STARTED");
      }
      this.notifyNewBreadcrumb({
        text: `T-${`00000${this.scaffoldDesign.scaffoldNumber}`.slice(-5)}`,
        to: `/scaffolddesigns/${this.$route.params.id}`
      });
    }
  },

  created: async function() {
    this.setFilteringContext({
      context: "scaffold-design-existing",
      parentalContext: "scaffold-designs",
      selectedTab: this.firstTabKey
    });

    // Add a small delay of time before the view comes in so that the "slide in" animation will be seen by the user.
    setInterval(() => {
      this.slidein = true;
    }, 100);

    if ((this.$store.state.lastBreadcrumbs[0]?.to || "") != "/scaffolddesigns") {
      this.notifyNewBreadcrumb({
        text: this.$t("scaffold-designs.list.title"),
        to: "/scaffolddesigns",
        resetHistory: true
      });
      // This is needed in order to salvage the "last breadcrumbs" in the store.
      this.$store.commit("NOTIFY_NAVIGATION_STARTED");
    }

    this.notifyNewBreadcrumb({
      text: this.$t("loading-dot-dot-dot"),
      disabled: true
    });

    this.scaffoldDesignID = this.$route.params.id;

    this.processing = true;
    try {
      await this.$store.dispatch("LOAD_TAGS");
      await Promise.all([
        // this.loadCoordinators(),
        // this.loadGeneralForemen(),
        // this.loadForemen(),
        this.loadPeople(),
        this.loadAreas(),
        this.loadSubAreas(),
        // this.loadCostCodes(),
        // this.loadScaffoldBayHeights(),
        // this.loadScaffoldBayLengths(),
        // this.loadScaffoldBayWidths(),
        this.loadScaffoldTypes(),
        this.loadScaffoldHeights(),
        this.loadScaffoldElevations(),
        this.loadScaffoldDistances(),
        this.loadScaffoldCongestionFactors(),
        this.loadBuildDismantleRatios(),
        this.loadInternalModifiers(),
        this.loadHoardingModifiers()
      ]);
      await Promise.all([this.loadAreas(), this.loadSubAreas()]);
      // processing has been set to false after the reference data loaded.
      this.processing = true;
      await this.loadScaffoldDesignDetails();
      this.processing = true;

      await this.loadScaffoldDesignEstimateData();
      // Processing has automatically been set to false after this load happens
      this.processing = true;

      let notes = await noteService.getForScaffoldDesign(this.scaffoldDesign.id!);
      this.notes = notes.map(x => ParseNoteWithSenderDetails(x));
      if (!!this.scaffoldDesign.notes?.length) {
        this.notes.push({
          isPinned: true,
          isNew: false,
          initials: "",
          name: `${this.$t("scaffold-requests.notes")}`,
          role: "",
          date: "",
          time: "",
          text: this.scaffoldDesign.notes,
          sender: undefined,
          id: undefined,
          noteThreadID: undefined,
          personID: undefined,
          sentTime: new Date(0),
          archivedDate: undefined,
          created: undefined,
          createdBy: undefined,
          updated: undefined,
          updatedBy: undefined
        });
      }
      this.notes = SortNotesArray(this.notes);

      await this.loadFiles();
      await this.loadLinks();
    } catch (error) {
      this.handleError(error as Error);
    } finally {
      this.processing = false;
    }
  }
});

