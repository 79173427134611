import FDVue from "..";

export default FDVue.extend({
  name: "fd-progress-badge",
  props: {
    cy: { type: String, default: undefined },
    dataCy: { type: String, default: "fd-progress-badge" },
    bordered: { type: Boolean, default: true },
    disabled: { type: Boolean, default: false },
    hideIcon: { type: Boolean, default: false },
    error: { type: Boolean, default: false },
    success: { type: Boolean, default: false },
    edited: { type: Boolean, default: false }
  },
  computed: {
    dot(): boolean {
      return !this.icon?.length;
    },
    dense(): boolean {
      return this.error || this.success;
    },
    icon(): string | undefined {
      if (this.hideIcon) return undefined;

      if (this.error) return "mdi-exclamation-thick";
      if (this.success) return "mdi-check-bold";
      if (this.edited) return "mdi-lead-pencil";
      return undefined;
    },
    color(): string {
      if (this.disabled) return "grey";
      if (this.error) return "error";
      if (this.success) return "success";
      return "primary";
    }
  }
});

