<template>
    <v-container fluid >
        <v-card>
            <v-toolbar v-bind:class="[processing ? 'view-card-top-color-processing' : 'view-card-top-color']">
                <!--View Title-->
                <v-row>
                    <v-col cols="12">
                        <v-row align="center">
                            <v-breadcrumbs
                                    :items="$store.state.currentBreadcrumbs"
                                    large
                                    v-bind:class="[processing ? 'breadcrumb-processing-opacity' : '', 'pl-3']"
                            >
                                <template v-slot:divider>
                                    <v-icon>fa-chevron-right</v-icon>
                                </template>
                            </v-breadcrumbs>
                            <v-spacer></v-spacer>
                            <v-progress-circular
                                    :indeterminate="true"
                                    :rotate="0"
                                    :size="32"
                                    :width="4"
                                    color="white"
                                    class="mr-3"
                                    v-if="processing"
                            ></v-progress-circular>
                        </v-row>
                    </v-col>
                </v-row>
            </v-toolbar>

            <v-container fluid>
                <v-row>
                    <v-col
                            cols="12">
                        <fd-chip-selector
                                v-bind:available-items="availableTags"
                                v-bind:selected-items.sync="selectedTags"
                                item-label="name"
                                v-bind:disabled="processing"
                        />
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                        <v-text-field
                                v-bind:label="$t('common.name')"
                                v-model="mapping.name"
                                v-bind:disabled="processing"
                                :rules="[rules.required]"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                        <v-text-field
                                v-bind:label="$t('common.name')"
                                v-model="mapping.courseurl"
                                v-bind:disabled="processing"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                        <v-text-field
                                v-bind:label="$t('mappings.valid-for')"
                                v-model.number="mapping.validfor"
                                v-bind:disabled="processing"
                                :rules="[rules.numbersonly]"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                        <v-text-field
                                v-bind:label="$t('mappings.course-url')"
                                v-model="mapping.courseurl"
                                v-bind:disabled="processing"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                        <v-text-field
                                v-bind:label="$t('mappings.course-description')"
                                v-model="mapping.coursedescription"
                                v-bind:disabled="processing"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-switch v-bind:label="$t('common.enabled')" v-model="mapping.enabled" v-bind:disabled="processing"></v-switch>
                    </v-col>

                    <v-col cols="12">
                        <v-alert v-if="inlineMessage.message" v-bind:type="inlineMessage.type" class="mr-0">
                            {{ inlineMessage.message }}
                        </v-alert>
                        <v-row justify="end" class="mr-0">
                            <v-btn
                                    text
                                    link
                                    v-bind:disabled="processing"
                                    @click="cancel"
                            >{{ $t('common.cancel') }}</v-btn>
                            <v-btn
                                    color="primary"
                                    v-bind:loading="saving"
                                    v-bind:disabled="processing"
                                    @click="save"
                            >{{ $t('common.save') }}</v-btn>
                        </v-row>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
    </v-container>
</template>

<script lang="ts">
    import {mapMutations, mapState, mapActions} from "vuex";
    import i18n from '../i18n'

    export default {

        name: 'fd-Mapping-Existing',

        components: {
            "fd-chip-selector": () => import('@fd/lib/vue/components/ChipItemSelector.vue')
        },

        data: function () { return {
            // The following is responsible for the inline messages that may be presented to the user.
            inlineMessage: {
                message: null,
                type: 'error',
            },

            // The following will control whether the controls on screen are disabled while we are conducting any screen
            // wide actions.
            processing: false,

            // The following will control whether or not the save button shows the processing/loading indicator
            saving: false,

            //The following object is used in conjunction with the breadcrumbs that are presented to the user for sub-view navigation.
            breadcrumbs: [
                {
                    text: 'Mappings',
                    disabled: false,
                    to: '/mappings',
                },
                {
                    text: this.$t("loading-dot-dot-dot"),
                    disabled: true,
                },
            ],

            mapping : {
                name: "",
                description: "",
                validfor: "",
                courseurl: "",
                coursedescription: "",
                enabled: false
            },

            selectedTags: [],

            rules: {
                required: value => !!value || i18n.t('common.rule-required'),
                numbersonly: value => !(value && isNaN(value)) || i18n.t('common.rule-numbers-only')
            }
        };},

        computed: {
            availableTags() {
                return this.$store.state.tags.fullList.filter(x => x.enabled || this.selectedTags.map(x => x.id).findIndex(x => x.id) !== -1);
            }
        },

        methods: {
            // Method used in conjunction with the Save button.
            async save() {
                // First reset the inline message if there are any.
                this.inlineMessage.message = '';

                if (!this.$refs.form.validate()) {
                    return;
                }

                this.processing = true;
                this.saving = true;
                try {
                    await this.updateMapping({
                        ...this.mapping,
                        tagIDs: this.selectedTags.length > 0 ? this.selectedTags.map(x => x.id) : null
                    });
                    this.$router.push('/mappings');
                } catch (error) {
                    this.inlineMessage.message = this.$t("mappings.save-network-error");
                    this.inlineMessage.type = "error";
                } finally {
                    this.processing = false;
                    this.saving = false;
                }
            },

            // Method used in conjunction with the Cancel button.
            cancel() {
                // TODO: Should this roll back state rather than rely on requerying?
                this.$router.push('/mappings');
            },
            ...mapMutations({
                setMapping: 'SET_MAPPING',
                notifyNewBreadcrumb: 'NOTIFY_NEW_BREADCRUMB'
            }),
            ...mapActions({
                loadMapping: 'LOAD_MAPPING',
                loadTags: 'LOAD_TAGS',
                updateMapping: 'UPDATE_MAPPING'
            }),
        },

        created: async function () {
            this.processing = true;
            try {
                await Promise.all([
                    this.loadMapping(this.$route.params.id),
                    this.loadTags()
                ]);
                let mapping = this.$store.state.mappings.fullList.find(x => x.id == this.$route.params.id);
                this.mapping = { ...mapping };
                if (mapping.tagIDs) {
                    this.selectedTags = mapping.tagIDs.map(x => this.$store.state.tags.fullList.find(y => y.id == x)).filter(x => x);
                } else {
                    this.selectedTags = [];
                }
                this.notifyNewBreadcrumb({
                    text: mapping.name,
                    to: `/mappings/${this.$route.params.id}`
                });

            } catch (error) {
                this.inlineMessage.message = this.$t("unexpected-network-error");
                this.inlineMessage.type = "error";
            } finally {
                this.processing = false;
            }

        }

    }
</script>
