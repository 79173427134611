import { Supplier, supplierService } from "../../services";
import { Record, BasicReferenceDataCrudService } from "../baseTypes";
import { createReferenceDataStore } from "./register";

// TODO: Revisit the fact that Supplier's ID field is undefined-able - this forces us to add additional qualifiers
// TODO: Revisit the fact that Supplier's name field is undefined-able

const supplierDataStore = createReferenceDataStore<Supplier & Record>(
  "supplier",
  supplierService as BasicReferenceDataCrudService<Supplier & Record>,
  x => x.name!
);
export default supplierDataStore;

