import { ProjectLocation, projectLocationService2 } from "../../services";
import { Record, BasicReferenceDataCrudService } from "../baseTypes";
import { createReferenceDataStore } from "./register";

// TODO: Revisit the fact that location's ID field is undefined-able - this forces us to add additional qualifiers
// TODO: Revisit the fact that location's name field is undefined-able
// TODO: This should be re-interpreted to area/subarea selections

const locationDataStore = createReferenceDataStore<ProjectLocation & Record>(
  "projectLocation",
  projectLocationService2 as BasicReferenceDataCrudService<ProjectLocation & Record>,
  x => x.name!
);
export default locationDataStore;
