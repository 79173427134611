export default class ServiceError extends Error {
  public statusCode: Number;
  public details: string;

  constructor(message?: string, details?: string, statusCode?: Number) {
    super(message);
    this.statusCode = statusCode ?? 500;
    this.details = details ?? "";
  }
}
